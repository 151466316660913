<sq-tests-card-list [selectItemEnabled]="true"
                    [loadStatus]="_pageLoadingStatus"
                    [entities]="_tests"
                    [selectedEntities]="_selectedTests"
                    [statisticsEnabled]="false"
                    [editEnabled]="false"
                    [unlinkEnabled]="false"
                    [deleteEnabled]="false"
                    [chartEnabled]="false"
                    [expandEnabled]="false"
                    [pagingEnabled]="true"
                    [totalCount]="pagingConfig.totalCount"
                    [pageSize]="pagingConfig.pageSize"
                    [pageSizeOptions]="pagingConfig.pageSizeOptions"
                    [currentPage]="pagingConfig.currentPage"
                    (onPageChange)="_onPageChange($event)"
                    (onPageSizeChange)="_onPageSizeChange($event)"
                    (onCardChecked)="_onCardChecked($event)"
                    (onExpandFolderCard)="_onExpandFolderCard($event)"
>
</sq-tests-card-list>
