<div class="ux-paging__navigation">

    <ng-container *ngIf="totalCount > 0">

        <button (tap)="_onFirstPage()"
                [disabled]="disabled"
                class="ux-paging__arrow"
                [ngClass]="{_disabled : currentPage === 1}">
            <svg width="0" height="0" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="m14.927015,4.517184c0.446,0.436 0.481,1.043 0,1.576l-3.747,3.908l3.747,3.908c0.481,0.533 0.446,1.141 0,1.574c-0.445,0.436 -1.197,0.408 -1.615,0c-0.418,-0.406 -4.502,-4.695 -4.502,-4.695c-0.223,-0.217 -0.335,-0.502 -0.335,-0.787s0.112,-0.57 0.335,-0.789c0,0 4.084,-4.287 4.502,-4.695c0.418,-0.409 1.17,-0.436 1.615,0z"></path>
                <rect rx="1.1" height="11.673004" width="2.260266" y="4.163498" x="4.591984" stroke-width="null"></rect>
            </svg>
        </button>

        <button (tap)="_onPrevPage()"
                [disabled]="disabled"
                class="ux-paging__arrow"
                [ngClass]="{_disabled : currentPage === 1}">
            <svg width="0" height="0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <path d="M12.452,4.516c0.446,0.436,0.481,1.043,0,1.576L8.705,10l3.747,3.908c0.481,0.533,0.446,1.141,0,1.574
                c-0.445,0.436-1.197,0.408-1.615,0c-0.418-0.406-4.502-4.695-4.502-4.695C6.112,10.57,6,10.285,6,10s0.112-0.57,0.335-0.789
                c0,0,4.084-4.287,4.502-4.695C11.255,4.107,12.007,4.08,12.452,4.516z"></path>
            </svg>
        </button>

        <button *ngFor="let link of _pageLinksArray; trackBy: _trackByFn"
                (tap)="_onPageByIndex(link)"
                [disabled]="disabled"
                class="ux-paging__link {{link === currentPage ? '_selected' : ''}}">{{link}}
        </button>

        <button (tap)="_onNextPage()"
                [disabled]="disabled"
                class="ux-paging__arrow"
                [ngClass]="{_disabled : currentPage === _amountOfPages}">
            <svg width="0" height="0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <path d="M9.163,4.516c0.418,0.408,4.502,4.695,4.502,4.695C13.888,9.43,14,9.715,14,10s-0.112,0.57-0.335,0.787
                c0,0-4.084,4.289-4.502,4.695c-0.418,0.408-1.17,0.436-1.615,0c-0.446-0.434-0.481-1.041,0-1.574L11.295,10L7.548,6.092
                c-0.481-0.533-0.446-1.141,0-1.576C7.993,4.08,8.745,4.107,9.163,4.516z"></path>
            </svg>
        </button>

        <button (tap)="_onLastPage()"
                [disabled]="disabled"
                class="ux-paging__arrow"
                [ngClass]="{_disabled : currentPage === _amountOfPages}">
            <svg width="0" height="0" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path transform="rotate(180 8.01103687286377,10.0003662109375)"
                      d="m11.063163,4.51755c0.446,0.436 0.481,1.043 0,1.576l-3.747,3.908l3.747,3.908c0.481,0.533 0.446,1.141 0,1.574c-0.445,0.436 -1.197,0.408 -1.615,0c-0.418,-0.406 -4.502,-4.695 -4.502,-4.695c-0.223,-0.217 -0.335,-0.502 -0.335,-0.787s0.112,-0.57 0.335,-0.789c0,0 4.084,-4.287 4.502,-4.695c0.418,-0.409 1.17,-0.436 1.615,0z"></path>
                <rect transform="rotate(180 14.391951560974121,10.0003662109375) " rx="1.1" height="11.673004"
                      width="2.260266"
                      y="4.163865" x="13.261814" stroke-width="null"></rect>
            </svg>
        </button>

    </ng-container>

</div>

<div class="ux-paging__info">
    <div class="ux-paging__info-items-shown">
        {{_info}}
    </div>
    <ux-dropdown-field #noInput
                       class="ux-paging__dropdown"
                       [value]="_pageSizeValue"
                       [items]="pageSizeOptions"
                       [itemLabelKey]="'label'"
                       [maxListHeight]="'200px'"
                       [disabled]="disabled"
                       [listContainer]="listContainer"
                       [listStyleClass]="listStyleClass"
                       (onValueChange)="_onItemsPerPageChange($event)"
    ></ux-dropdown-field>
    <div class="ux-paging__info-per-page">
        {{infoMessage.perPage}}
    </div>
</div>
