import {NgModule} from "@angular/core";
import {UxButtonComponent} from "./button.component";
import {SharedModule} from "../../shared/shared.module";

@NgModule({
    imports: [SharedModule],
    declarations: [UxButtonComponent],
    exports: [UxButtonComponent]
})
export class UxButtonModule {
}
