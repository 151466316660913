import {NgModule} from "@angular/core";
import {UxMemoFieldComponent} from "./memo-field.component";
import {SharedModule} from "../../../shared/shared.module";
import {FormsModule} from "@angular/forms";
import {UxScrollModule} from "../../scroll/scroll.module";

@NgModule({
    imports: [SharedModule, FormsModule, UxScrollModule],
    exports: [UxMemoFieldComponent],
    declarations: [UxMemoFieldComponent]
})
export class UxMemoFieldModule {
}
