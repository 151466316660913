import {NgModule} from "@angular/core";
import {UxCardTreeComponent} from "./card-tree.component";
import {SharedModule} from "../../shared/shared.module";
import {UxSlideToggleModule} from "../slide-toggle/slide-toggle.module";
import {UxCheckboxFieldModule} from "../fields/checkbox/checkbox-field.module";

export * from './card-tree.model';

@NgModule({
    imports: [
        SharedModule,
        UxSlideToggleModule,
        UxCheckboxFieldModule
    ],
    declarations: [UxCardTreeComponent],
    exports: [UxCardTreeComponent]
})
export class UxCardTreeModule {
}
