import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ContentChild,
    ElementRef,
    EventEmitter,
    Input,
    NgZone,
    OnDestroy,
    Output,
    TemplateRef,
    ViewChild,
    HostBinding
} from "@angular/core";
import {UxDomHelper} from "../../shared/dom/dom-helper";
import {UxContentHeaderComponent} from "../common/content-header.component";
import {UxPropertyConverter} from "../../common/decorator/ux-property-converter";
import {UxPropertyHandler} from "../../common/decorator/ux-property-handler";



export type AttachPosition =
    "top" | "top center" | "top left" | "top right" |
    "left" | "left center" | "left top" | "left bottom" |
    "right" | "right center" | "right top" | "right bottom" |
    "bottom" | "bottom center" | "bottom left" | "bottom right" |
      "center" | "auto" | "view center";

export type TransitionType =
    "fade" | "slide" | "bottom-to-top" | "right-to-left";

const ESC_KEY_CODE: number = 27;

@Component({
    selector: "ux-popover",
    templateUrl: "./popover.component.html",
})
export class UxPopoverComponent implements AfterViewInit, OnDestroy {

    @HostBinding("class.ux-popover")
    @HostBinding("class.taPopup")
    private hostClass: boolean = true;

    @Input()
    public targetIndent: number = 15;

    @Input()
    public zIndex: number;

    @Input()
    public caption: string;

    @UxPropertyConverter("string", "")
    @UxPropertyHandler({
        afterChange: afterChangeAttachTo
    })
    @Input()
    public attachTo: string;

    @UxPropertyHandler({
        afterChange: afterChangeAttachToEl
    })
    @Input()
    public attachToEl: Element;

    @UxPropertyConverter("string", "auto")
    @Input()
    public attachPosition: AttachPosition;

    @Input()
    public attachPositionsSequence: AttachPosition[] = [
        "bottom",
        "bottom left",
        "bottom right",
        "top",
        "top left",
        "top right",
        "right",
        "right top",
        "right bottom",
        "left",
        "left top",
        "left bottom"
    ];

    @HostBinding("class._visible")
    @UxPropertyHandler({
        afterChange: afterChangeVisible
    })
    @Input()
    public visible: boolean;

    @Input()
    public closable: boolean;

    @UxPropertyConverter("string", "")
    @UxPropertyHandler({
        afterChange: afterChangeContainer
    })
    @Input()
    public container: string = "";

    @UxPropertyHandler({
        afterChange: afterChangeContainerEl
    })
    @Input()
    public containerEl: Element;

    @Input()
    public template: TemplateRef<any>;

    @Input()
    public headerTemplate: TemplateRef<any>;

    @Input()
    public customData: any;

    @HostBinding("class._mobile")
    @Input()
    public mobile: boolean = false;

    @Input()
    public set closeOnEsc(value: boolean) {
        this.closeOnEscSet = true;
        this._closeOnEsc = value;
        this.afterChangeCloseOnEsc(value);
    }

    private _closeOnEsc: boolean = false;
    private closeOnEscSet: boolean = false;

    @Input()
    public closeOnOutsideClick: boolean = false;

    @Input()
    public disablePositionUpdateOnScroll: boolean = false;

    @Input()
    public transitionType: TransitionType = "fade";

    @Output()
    public visibleChange = new EventEmitter<boolean>();

    @Output()
    public onVisibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Output()
    public onClose: EventEmitter<Event> = new EventEmitter<Event>();

    @Output()
    public onOpen: EventEmitter<boolean> = new EventEmitter<boolean>();

    /* deprecated use instead headerTemplate. todo remove after 1.0.34 version */
    @ContentChild(UxContentHeaderComponent)
    /** @internal */
    public _customHeader: UxContentHeaderComponent;
    /********************************************/

    private positionStyleModifier: string;

    private positionAndAlignStyleModifier: string;

    /** @internal */
    public _contentVisible: boolean = false;

    @ViewChild("arrowEl")
    private arrowElementRef: ElementRef;

    private escCallback = (event: KeyboardEvent) => {
        if (event.which === ESC_KEY_CODE) {
            this.onEscClose(event);
        }
    };

    public constructor(private elementRef: ElementRef,
                       private cdr: ChangeDetectorRef,
                       private zone: NgZone) {
    }

    /** @internal */
    public _onCloseClick(event: MouseEvent): void {
        this.visible = false;
        this.onClose.emit(event);
    }

    public ngAfterViewInit(): void {
    }

    public ngOnDestroy(): void {
        if (this.elementRef.nativeElement && this.elementRef.nativeElement.parentNode) {
            UxDomHelper.removeChildFromParent(this.elementRef.nativeElement);
        }

        document.body.removeEventListener("keyup", this.escCallback);
        document.removeEventListener("click", this.clickOutsideCb);
        window.removeEventListener("scroll", this.scrollOutsideCb, true);
    }

    private isBodyContainer(): boolean {
        return this.container === "body" || this.containerEl === document.body;
    }

    private updatePosition(): void {
        let self = this;
        let initialAttachPosition: AttachPosition;

        if (!self.attachToEl && self.attachTo) {
            self.attachToEl = document.querySelector(self.attachTo);
            if (self.attachToEl) { // if new value - method updatePosition will be called again in setter of attachToEl
                return;
            }
        }

        if (!self.attachPosition || self.attachPosition === "auto") {
            initialAttachPosition = "bottom" as AttachPosition;
        } else {
            initialAttachPosition = self.attachPosition;
        }

        let popoverElement = self.elementRef.nativeElement,
            popoverStyle = popoverElement.style,
            arrowStyle = self.arrowElementRef.nativeElement.style,
            clientSize, popoverClientRect, targetClientRect, containerClientRect,
            previousSlideHight, popoverInnerElement;

        if (self.transitionType === "slide") {
            popoverInnerElement = popoverElement.querySelector(".ux-popover__inner");
            previousSlideHight = popoverInnerElement.style.height;
            popoverInnerElement.style.height = "auto";
        }

        popoverStyle.left = "0";
        popoverStyle.top = "0";

        clientSize = UxDomHelper.getClientSize();
        popoverClientRect = UxDomHelper.getDocumentRelativePosition(popoverElement);
        targetClientRect = UxDomHelper.getDocumentRelativePosition(self.attachToEl || document.body);
        containerClientRect = UxDomHelper.getDocumentRelativePosition(self.containerEl || document.body);

        if (self.transitionType === "slide") {
            popoverInnerElement.style.height = previousSlideHight;
        }

        let targetClientRectLeft = targetClientRect.left,
            targetClientRectRight = targetClientRect.right,
            targetClientRectTop = targetClientRect.top,
            targetClientRectBottom = targetClientRect.bottom,
            targetClientRectWidth = targetClientRect.width,
            targetClientRectHeight = targetClientRect.height;

        let popoverClientRectWidth = popoverClientRect.width,
            popoverClientRectHeight = popoverClientRect.height;

        let containerClientRectLeft = containerClientRect.left,
            containerClientRectTop = containerClientRect.top;

        let pageXScroll = pageXOffset,
            pageYScroll = pageYOffset;

        let clientSizeWidth = clientSize.width,
            clientSizeHeight = clientSize.height;

        let isBodyContainer = self.isBodyContainer();

        let horizontalDistance = (targetClientRectLeft - containerClientRectLeft),
            verticalDistance = (targetClientRectTop - containerClientRectTop);

        let initialCoordinates;

        let arrowClientRectWidth = 20,
            minArrowOffset = 1 + arrowClientRectWidth / 2;

        if (initialAttachPosition === "center") {
            self.positionStyleModifier = "center";
            if (isBodyContainer) {
                popoverStyle.left = `${targetClientRectLeft + (targetClientRectWidth - popoverClientRectWidth) / 2}px`;
                popoverStyle.top = `${targetClientRectTop + (targetClientRectHeight - popoverClientRectHeight) / 2}px`;
            } else {
                popoverStyle.left = `${horizontalDistance + (targetClientRectWidth - popoverClientRectWidth) / 2}px`;
                popoverStyle.top = `${verticalDistance + (targetClientRectHeight - popoverClientRectHeight) / 2}px`;
            }
        } else if (initialAttachPosition === "view center") {
          self.positionStyleModifier = "view center";
          popoverStyle.left = `${0 + (clientSize.width - popoverClientRectWidth) / 2}px`;
          popoverStyle.top = `${0 + (clientSize.height - popoverClientRectHeight) / 2}px`;
        } else {
            if (self.attachPositionsSequence.indexOf(initialAttachPosition) === -1) {
                self.attachPositionsSequence.unshift(initialAttachPosition);
            }

            let suitablePositions = this.attachPositionsSequence.map((attachPosition) => {
                let leftOffset: number,
                    rightOffset: number,
                    bottomOffset: number,
                    topOffset: number,
                    popoverLeft: string,
                    popoverTop: string,
                    arrowLeft: string,
                    arrowTop: string,
                    arrowBottom: string;

                switch (attachPosition) {
                    case "bottom left":
                        if (isBodyContainer) {
                            popoverLeft = `${targetClientRectLeft}px`;
                            popoverTop = `${targetClientRectBottom + self.targetIndent}px`;
                        } else {
                            popoverLeft = `${horizontalDistance}px`;
                            popoverTop = `${verticalDistance + targetClientRectHeight + self.targetIndent}px`;
                        }
                        arrowLeft = `${targetClientRectWidth / 2}px`;
                        arrowTop = `0`;

                        if (parseFloat(arrowLeft) - arrowClientRectWidth / 2 < 0) {
                            arrowLeft = `${minArrowOffset}px`;
                        }
                        break;

                    case "bottom":
                    case "bottom center":
                        if (isBodyContainer) {
                            popoverLeft = `${targetClientRectLeft + (targetClientRectWidth - popoverClientRectWidth) / 2}px`;
                            popoverTop = `${targetClientRectBottom + self.targetIndent}px`;
                        } else {
                            popoverLeft = `${horizontalDistance + (targetClientRectWidth - popoverClientRectWidth) / 2}px`;
                            popoverTop = `${verticalDistance + targetClientRectHeight + self.targetIndent}px`;
                        }
                        arrowLeft = `50%`;
                        arrowTop = `0`;
                        break;

                    case "bottom right":
                        if (isBodyContainer) {
                            popoverLeft = `${targetClientRectRight - popoverClientRectWidth}px`;
                            popoverTop = `${targetClientRectBottom + self.targetIndent}px`;
                        } else {
                            popoverLeft = `${horizontalDistance + targetClientRectWidth - popoverClientRectWidth}px`;
                            popoverTop = `${verticalDistance + targetClientRectHeight + self.targetIndent}px`;
                        }
                        arrowLeft = `${popoverClientRectWidth - targetClientRectWidth / 2}px`;
                        arrowTop = `0`;

                        if (parseFloat(arrowLeft) + arrowClientRectWidth / 2 > popoverClientRectWidth) {
                            arrowLeft = `${popoverClientRectWidth - minArrowOffset + 2}px`;
                        }
                        break;

                    case "top left":
                        if (isBodyContainer) {
                            popoverLeft = `${targetClientRectLeft}px`;
                            popoverTop = `${targetClientRectTop - popoverClientRectHeight - self.targetIndent}px`;
                        } else {
                            popoverLeft = `${horizontalDistance}px`;
                            popoverTop = `${verticalDistance - popoverClientRectHeight - self.targetIndent}px`;
                        }
                        arrowLeft = `${targetClientRectWidth / 2}px`;
                        arrowTop = "unset";
                        arrowBottom = `-${minArrowOffset}px`;

                        if (parseFloat(arrowLeft) - arrowClientRectWidth / 2 < 0) {
                            arrowLeft = `${minArrowOffset}px`;
                        }
                        break;

                    case "top":
                    case "top center":
                        if (isBodyContainer) {
                            popoverLeft = `${targetClientRectLeft + (targetClientRectWidth - popoverClientRectWidth) / 2}px`;
                            popoverTop = `${targetClientRectTop - popoverClientRectHeight - self.targetIndent}px`;
                        } else {
                            popoverLeft = `${horizontalDistance + (targetClientRectWidth - popoverClientRectWidth) / 2}px`;
                            popoverTop = `${verticalDistance - popoverClientRectHeight - self.targetIndent}px`;
                        }
                        arrowLeft = `50%`;
                        arrowTop = "unset";
                        arrowBottom = `-${minArrowOffset}px`;
                        break;

                    case "top right":
                        if (isBodyContainer) {
                            popoverLeft = `${targetClientRectRight - popoverClientRectWidth}px`;
                            popoverTop = `${targetClientRectTop - popoverClientRectHeight - self.targetIndent}px`;
                        } else {
                            popoverLeft = `${horizontalDistance + targetClientRectWidth - popoverClientRectWidth}px`;
                            popoverTop = `${verticalDistance - popoverClientRectHeight - self.targetIndent}px`;
                        }
                        arrowLeft = `${popoverClientRectWidth - targetClientRectWidth / 2}px`;
                        arrowTop = "unset";
                        arrowBottom = `-${minArrowOffset}px`;

                        if (parseFloat(arrowLeft) + arrowClientRectWidth / 2 > popoverClientRectWidth) {
                            arrowLeft = `${popoverClientRectWidth - minArrowOffset + 2}px`;
                        }
                        break;

                    case "left top":
                        if (isBodyContainer) {
                            popoverLeft = `${targetClientRectLeft - popoverClientRectWidth - self.targetIndent}px`;
                            popoverTop = `${targetClientRectTop}px`;
                        } else {
                            popoverLeft = `${horizontalDistance - popoverClientRectWidth - self.targetIndent}px`;
                            popoverTop = `${verticalDistance}px`;
                        }
                        arrowLeft = `${popoverClientRectWidth}px`;
                        arrowTop = `${targetClientRectHeight / 2}px`;

                        if (parseFloat(arrowTop) - arrowClientRectWidth / 2 < 0) {
                            arrowTop = `${minArrowOffset}px`;
                        }
                        break;

                    case "left":
                    case "left center":
                        if (isBodyContainer) {
                            popoverLeft = `${targetClientRectLeft - popoverClientRectWidth - self.targetIndent}px`;
                            popoverTop = `${targetClientRectTop + (targetClientRectHeight - popoverClientRectHeight) / 2}px`;
                        } else {
                            popoverLeft = `${horizontalDistance - popoverClientRectWidth - self.targetIndent}px`;
                            popoverTop = `${verticalDistance + (targetClientRectHeight - popoverClientRectHeight) / 2}px`;
                        }
                        arrowLeft = `${popoverClientRectWidth}px`;
                        arrowTop = `50%`;
                        break;

                    case "left bottom":
                        if (isBodyContainer) {
                            popoverLeft = `${targetClientRectLeft - popoverClientRectWidth - self.targetIndent}px`;
                            popoverTop = `${targetClientRectBottom - popoverClientRectHeight}px`;
                        } else {
                            popoverLeft = `${horizontalDistance - popoverClientRectWidth - self.targetIndent}px`;
                            popoverTop = `${verticalDistance + targetClientRectHeight - popoverClientRectHeight}px`;
                        }
                        arrowLeft = `${popoverClientRectWidth}px`;
                        arrowTop = `${popoverClientRectHeight - targetClientRectHeight / 2}px`;

                        if (parseFloat(arrowTop) + arrowClientRectWidth / 2 > popoverClientRectHeight) {
                            arrowTop = `${popoverClientRectHeight - minArrowOffset + 2}px`;
                        }
                        break;

                    case "right top":
                        if (isBodyContainer) {
                            popoverLeft = `${targetClientRectRight + self.targetIndent}px`;
                            popoverTop = `${targetClientRectTop}px`;
                        } else {
                            popoverLeft = `${horizontalDistance + targetClientRectWidth + self.targetIndent}px`;
                            popoverTop = `${verticalDistance}px`;
                        }
                        arrowLeft = `0`;
                        arrowTop = `${targetClientRectHeight / 2}px`;

                        if (parseFloat(arrowTop) - arrowClientRectWidth / 2 < 0) {
                            arrowTop = `${minArrowOffset}px`;
                        }
                        break;

                    case "right":
                    case "right center":
                        if (isBodyContainer) {
                            popoverLeft = `${targetClientRectRight + self.targetIndent}px`;
                            popoverTop = `${targetClientRectTop + (targetClientRectHeight - popoverClientRectHeight) / 2}px`;
                        } else {
                            popoverLeft = `${horizontalDistance + targetClientRectWidth + self.targetIndent}px`;
                            popoverTop = `${verticalDistance + (targetClientRectHeight - popoverClientRectHeight) / 2}px`;
                        }
                        arrowLeft = `0`;
                        arrowTop = `50%`;
                        break;

                    case "right bottom":
                        if (isBodyContainer) {
                            popoverLeft = `${targetClientRectRight + self.targetIndent}px`;
                            popoverTop = `${targetClientRectBottom - popoverClientRectHeight}px`;
                        } else {
                            popoverLeft = `${horizontalDistance + targetClientRectWidth + self.targetIndent}px`;
                            popoverTop = `${verticalDistance + targetClientRectHeight - popoverClientRectHeight}px`;
                        }
                        arrowLeft = `0`;
                        arrowTop = `${popoverClientRectHeight - targetClientRectHeight / 2}px`;

                        if (parseFloat(arrowTop) + arrowClientRectWidth / 2 > popoverClientRectHeight) {
                            arrowTop = `${popoverClientRectHeight - minArrowOffset + 2}px`;
                        }
                        break;
                }

                // Remember these coordinates for the case, when there will be no suitable position (then we will use these coordinates).
                if (attachPosition === initialAttachPosition) {
                    initialCoordinates = {
                        "attachPosition": initialAttachPosition,
                        "popoverLeft": popoverLeft,
                        "popoverTop": popoverTop,
                        "arrowLeft": arrowLeft,
                        "arrowTop": arrowTop,
                        "arrowBottom": arrowBottom
                    };
                }

                // Search offsets for the popover.
                if (isBodyContainer) {
                    leftOffset = pageXScroll - parseFloat(popoverLeft);
                    rightOffset = (parseFloat(popoverLeft) + popoverClientRectWidth) - (clientSizeWidth + pageXScroll);
                    topOffset = pageYScroll - parseFloat(popoverTop);
                    bottomOffset = parseFloat(popoverTop) + popoverClientRectHeight - (pageYScroll + clientSizeHeight);
                } else {
                    leftOffset = horizontalDistance + pageXScroll - (targetClientRectLeft + parseFloat(popoverLeft));
                    rightOffset = containerClientRectLeft + parseFloat(popoverLeft) + popoverClientRectWidth - ((clientSizeWidth + pageXScroll));
                    topOffset = verticalDistance + pageYScroll - (targetClientRectTop + parseFloat(popoverTop));
                    bottomOffset = containerClientRectTop + parseFloat(popoverTop) + popoverClientRectHeight - ((clientSizeHeight + pageYScroll));
                }

                if (!(leftOffset > 0 || rightOffset > 0 || topOffset > 0 || bottomOffset > 0)) {
                    return {
                        "attachPosition": attachPosition,
                        "popoverLeft": popoverLeft,
                        "popoverTop": popoverTop,
                        "arrowLeft": arrowLeft,
                        "arrowTop": arrowTop,
                        "arrowBottom": arrowBottom
                    };
                }
            });

            if (self.attachPositionsSequence.indexOf(initialAttachPosition) === 0) {
                self.attachPositionsSequence.shift();
            }

            let attachPositionFirstAlign: AttachPosition,
                attachPositionSecondAlign: AttachPosition,
                attachPositionThirdAlign: AttachPosition,
                index: number;

            if (this.attachPositionsSequence.length === 12) {// if default positions sequence
                index = this.attachPositionsSequence.indexOf(initialAttachPosition);

                if (initialAttachPosition.split(" ")[1] === "center") {
                    attachPositionFirstAlign = initialAttachPosition.split(" ")[0] as AttachPosition;
                } else {
                    attachPositionFirstAlign = initialAttachPosition;
                }

                if (index % 3 === 0) {
                    attachPositionSecondAlign = this.attachPositionsSequence[index + 1];
                    attachPositionThirdAlign = this.attachPositionsSequence[index + 2];
                } else if (index % 3 === 1) {
                    attachPositionSecondAlign = this.attachPositionsSequence[index - 1];
                    attachPositionThirdAlign = this.attachPositionsSequence[index + 1];
                } else {
                    attachPositionSecondAlign = this.attachPositionsSequence[index - 2];
                    attachPositionThirdAlign = this.attachPositionsSequence[index - 1];
                }
            }

            let coordinates = suitablePositions.reduce((selectedPosition, item) => {
                if (item &&
                    (
                        !selectedPosition
                        || item.attachPosition === attachPositionFirstAlign
                        || (item.attachPosition === attachPositionSecondAlign && selectedPosition.attachPosition !== attachPositionFirstAlign)
                        || (item.attachPosition === attachPositionThirdAlign && selectedPosition.attachPosition !== attachPositionFirstAlign)
                    )
                ) {
                    selectedPosition = item;
                }
                return selectedPosition;
            }, suitablePositions[0]);

            if (!coordinates) {
                coordinates = initialCoordinates;
            }

            let [position, align] = coordinates.attachPosition.split(" ");
            if (!align) {
                align = "center";
            }
            let positionAndAlign = `${position}-${align}`;

            if ((self.positionStyleModifier !== position) || (self.positionAndAlignStyleModifier !== positionAndAlign)) {
                self.positionStyleModifier = position;
                self.positionAndAlignStyleModifier = positionAndAlign;
                self.cdr.detectChanges();
            }

            popoverStyle.left = coordinates.popoverLeft;
            popoverStyle.top = coordinates.popoverTop;
            arrowStyle.left = coordinates.arrowLeft;
            arrowStyle.top = coordinates.arrowTop;
            arrowStyle.bottom = coordinates.arrowBottom;
        }
    }

    /** @internal */
    public _onAnimationStart(): void {
        this.elementRef.nativeElement.style.display = "block";
    }

    /** @internal */
    public _onAnimationEnd(): void {
        let self = this;

        if (self.visible) {
            self.onOpen.emit(true);

            if (self.closeOnOutsideClick) {
                self.handleClickOutside();
            }

            if (!self.disablePositionUpdateOnScroll) {
                this.handleScrollOutside();
            }
        } else {
            self._contentVisible = false;
            self.elementRef.nativeElement.style.display = "none";

            if (self.elementRef.nativeElement &&
                (self.transitionType === "bottom-to-top" ||
                    self.transitionType === "right-to-left")
            ) {
                document.body.style.overflow = "initial";
            }
        }
    }

    public update(): void {
        if (this.visible) {
            this.updatePosition();
        }
    }

    private onEscClose(event: KeyboardEvent): void {
        this.visible = false;
        event.stopPropagation();
        this.onClose.emit(event);
    }

    private handleClickOutside(): void {
        const self = this,
            popover = self.elementRef.nativeElement;

        if (!popover) {
            return;
        }

        self.zone.runOutsideAngular(() => {
            document.addEventListener("click", self.clickOutsideCb);
        });
    }

    private clickOutsideCb = (e: Event) => {
        const self = this,
            popover = self.elementRef.nativeElement;

        if (!popover.contains(e.target as Node)) {

            document.removeEventListener("click", self.clickOutsideCb);

            self.zone.run(() => {
                self.visible = false;
                self.onClose.emit(e);
            });
        }
    }

    private handleScrollOutside(): void {
        let popover = this.elementRef.nativeElement;

        if (!popover) {
            return;
        }

        this.zone.runOutsideAngular(() => {
            window.addEventListener("scroll", this.scrollOutsideCb, true);
        });
    }

    private scrollOutsideCb = (e: Event) => {
        const self = this,
            popover = self.elementRef.nativeElement as HTMLElement;

        if (!popover.contains(e.target as Node)) {
            self.updatePosition();
        }
    }

    private afterChangeCloseOnEsc(newValue: boolean): void {
        let self = this;
        if (newValue) {
            document.body.addEventListener("keyup", self.escCallback);
        } else {
            document.body.removeEventListener("keyup", self.escCallback);
        }
    }
}

/*Helpers*/
export function afterChangeContainer(newValue: string, oldValue: string): void {
    this.containerEl = document.querySelector(newValue || "body");
}

export function afterChangeContainerEl(newValue: Element, oldValue: Element): void {
    this.containerEl = newValue || document.body;
}

export function afterChangeAttachTo(newValue: string, oldValue: string): void {
    this.attachToEl = document.querySelector(newValue || "body");
}

export function afterChangeAttachToEl(newValue: string, oldValue: string): void {
    this.attachToEl = newValue;
    this.update();
}

export function afterChangeVisible(value: boolean): void {
    let self = this;
    let element, style;

    if (value) {
        self._contentVisible = true;

        if (self.container) {
            self.containerEl = document.querySelector(self.container);
        }

        if (self.mobile) {
            self.containerEl = document.querySelector("body");
        }

        if (self.containerEl) {
            UxDomHelper.appendChild(self.elementRef.nativeElement, self.containerEl);
        }

        if (self.elementRef.nativeElement) {

            element = self.elementRef.nativeElement;
            style = element.style;
            style.visibility = "hidden";
            style.display = "block";

            removeTransitionClasses(element);

            if (self.elementRef.nativeElement &&
                (self.transitionType === "bottom-to-top" ||
                    self.transitionType === "right-to-left")
            ) {
                document.body.style.overflow = "hidden";

                switch (self.transitionType) {
                    case "bottom-to-top":
                        element.classList.add("_initial-bottom-to-top");
                        break;

                    case "right-to-left":
                        element.classList.add("_initial-right-to-left");
                        break;
                }

                window.requestAnimationFrame(() => {
                    switch (self.transitionType) {
                        case "bottom-to-top":
                            removeClasses(element, ["_initial-right-to-left", "_right-to-left", "_transition"]);
                            addClasses(element, ["_initial-bottom-to-top", "_bottom-to-top", "_transition"]);
                            break;

                        case "right-to-left":
                            removeClasses(element, ["_initial-bottom-to-top", "_bottom-to-top", "_transition"]);
                            addClasses(element, ["_initial-right-to-left", "_right-to-left", "_transition"]);
                            break;
                    }
                });

            }

            setTimeout(() => {

                style.zIndex = self.zIndex ? self.zIndex : UxDomHelper.nextZIndex();

                if (!self.mobile) {
                    self.updatePosition();
                } else {
                    style.top = 0;
                    style.left = 0;
                }

                style.visibility = "visible";

            }, 0);

        }
    } else {
        document.removeEventListener("click", self.clickOutsideCb);
        window.removeEventListener("scroll", self.scrollOutsideCb, true);

        if (self.elementRef.nativeElement &&
           (self.transitionType === "bottom-to-top" ||
            self.transitionType === "right-to-left")
        ) {

            document.body.style.overflow = "hidden";

            element = self.elementRef.nativeElement;

            switch (self.transitionType) {
                case "bottom-to-top":
                    element.classList.remove("_bottom-to-top");
                    break;

                case "right-to-left":
                    element.classList.remove("_right-to-left");
                    break;
            }
        }
    }

    if ((self.closeOnEscSet && self._closeOnEsc) || (!self.closeOnEscSet && (self.closable || self.closeOnOutsideClick))) {
        self.afterChangeCloseOnEsc(value);
    }

    self.onVisibleChange.emit(value);
    self.visibleChange.emit(value);
}



/*Helpers*/
function addClasses(element: HTMLElement, classes: string[]): void {
    classes.forEach((item) => element.classList.add(item));
}

function removeClasses(element: HTMLElement, classes: string[]): void {
    classes.forEach((item) => element.classList.remove(item));
}

function removeTransitionClasses(element: HTMLElement): void {
    removeClasses(element, [
        "_initial-bottom-to-top",
        "_initial-right-to-left",
        "_bottom-to-top",
        "_right-to-left",
        "_transition"]
    );
}

