import {Component, EventEmitter, Input, Output, TemplateRef} from '@angular/core';
import {
    UxTableHeaderInfoCrossEvent,
    UxTableHeaderInfoItemEvent,
    UxTableHeaderInfoModel,
    UxTableHeaderModel
} from "./table-header.model";

@Component({
    selector: 'ux-table-header',
    templateUrl: 'table-header.component.html',
    host: {
        "[class.ux-table-header]": "true"
    }
})

export class UxTableHeaderComponent {

    @Input()
    public mobile: boolean = false;


    @Output()
    public onInfoItemCrossClick: EventEmitter<UxTableHeaderInfoCrossEvent> = new EventEmitter<UxTableHeaderInfoCrossEvent>();

    @Output()
    public onInfoItemClick: EventEmitter<UxTableHeaderInfoItemEvent> = new EventEmitter<UxTableHeaderInfoItemEvent>();


    @Input()
    public set tableHeaderControls(value: TemplateRef<any>) {
        if (value !== undefined) {
            this.controlElements = value;
        }
    }

    public get tableHeaderControls(): TemplateRef<any> {
        return this.controlElements;
    }

    @Input()
    public model: UxTableHeaderModel;


    /** @internal **/
    public _infoItemCrossClick(item: UxTableHeaderInfoModel, event: Event): void {
        let self = this;

        if (self.model && self.model.info) {
            self.model.info.splice(self.model.info.indexOf(item), 1);
        }

        self.onInfoItemCrossClick.emit({
            item,
            originalEvent: event
        });
    }

    /** @internal **/
    public _infoItemClick(item: UxTableHeaderInfoModel, event: Event): void {
        let self = this;

        self.onInfoItemClick.emit({
            item,
            originalEvent: event
        });
    }


    private controlElements: TemplateRef<any>;
}
