import {NgModule} from "@angular/core";
import {SharedModule} from "../../../shared/shared.module";
import {UxMultipleFieldComponent} from "./multiple-field.component";
import {UxButtonModule} from "../../button/button.module";
import {UxMultipleSelectorFieldPopoverContentComponent} from "./components/popover-content/selector/multiple-selector-field-popover-content.component";
import {UxPopoverModule} from "../../popover/popover.module";
import {UxScrollModule} from "../../scroll/scroll.module";
import {UxCheckboxGroupFieldModule} from "../checkbox-group/checkbox-group-field.module";
import {UxMultipleTextFieldPopoverContentComponent} from "./components/popover-content/text/multiple-text-field-popover-content.component";
import {UxTextFieldModule} from "../text/text-field.module";
import {UxLabelModule} from "../../label/label.module";
import {UxToolbarModule} from "../../toolbar/toolbar.module";
import {UxCheckboxFieldModule} from "../checkbox/checkbox-field.module";

@NgModule({
    imports: [
        SharedModule,
        UxButtonModule,
        UxPopoverModule,
        UxScrollModule,
        UxCheckboxFieldModule,
        UxCheckboxGroupFieldModule,
        UxTextFieldModule,
        UxLabelModule,
        UxToolbarModule
    ],
    exports: [UxMultipleFieldComponent, UxMultipleSelectorFieldPopoverContentComponent, UxMultipleTextFieldPopoverContentComponent],
    declarations: [UxMultipleFieldComponent, UxMultipleSelectorFieldPopoverContentComponent, UxMultipleTextFieldPopoverContentComponent]
})
export class UxMultipleFieldModule {
}
