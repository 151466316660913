<input #uxInput
       class="ux-text-field__input taValue"
       [placeholder]="placeholder"
       [attr.name]="inputName"
       [disabled]="disabled"
       [attr.maxlength]="maxLength"
       [(ngModel)]="value"
       (focus)="_onFocus($event)"
       (blur)="_onBlur($event)"
       (change)="_onSubmitValueChange($event)"
       (keydown)="_onKeyDown($event)"
       (keyup.enter)="_onEnter($event)"
/>
