import {Injectable} from "@angular/core";

@Injectable()
export class ModelService {

    public deepCopy<T>(model: T): T {
        return JSON.parse(JSON.stringify(model));
    }

    public isEqual<T>(model: T, modelCopy: T): boolean {
        return JSON.stringify(model) == JSON.stringify(modelCopy);
    }

    public revert(model: any, modelBackup: any): void {
        return Object.assign(model, modelBackup);
    }

    public findInArray(data: any[], cb: (item: any) => boolean): number {
      for (let i=0; i < data.length; i++) {
        if (cb(data[i])) {
          return i;
        }
      }
      return -1;
    }

    public findEntityInArray(data: any[], cb: (item: any) => boolean): any | undefined {
      for (let i=0; i < data.length; i++) {
        if (cb(data[i])) {
          return data[i];
        }
      }
      return undefined;
    }

    public isEmptyString(value: string): boolean {
      return value === undefined ||
          value === null ||
          value === "";
    }

    public listToMap<T>(values: T[], idField: string): Map<number|string, T> {
      let result: Map<number|string, T> = new Map<number|string, T>();
      values.forEach((value: T) => {
        let id = value[idField];
        if (!result.has(id)) {
          result.set(id, value);
        }
      });
      return result;
    }

    public formatSeconds(value: number): string {
      let hours: number = Math.floor(value / 3600) % 24,
        mins: number = Math.floor(value / 60) % 60,
        secs: number = value % 60;

      return `${hours > 9 ? hours : '0' + hours}:${mins > 9 ? mins : '0' + mins}:${secs > 9 ? secs : '0' + secs}`;
    }
}
