<div class="ux-table__column {{column?.styleClass}}"
     [ngClass]="{
                '_checkbox': column?.type === 'checkbox',
                '_link': column?.type === 'link',
                '_sort': column?.sort,
                '_asc': column?.sort === 'asc',
                '_desc': column?.sort === 'desc',
                'taCell': !header,
                'taHeaderCell': header
             }"
     [ngStyle]="column?.inlineStyles"
     *ngFor="let column of row?.columns; let columnIndex = index; trackBy: _trackByFn"
     (tap)="_onColumnClick(column, columnIndex, row, $event, header)"
>

    <ng-template
        *ngIf="column?.type === 'content'"
        [ngTemplateOutlet]="column.value"
        [ngTemplateOutletContext]="{
                contentModel: column?.contentModel,
                column: column,
                columnIndex: columnIndex,
                row: row
            }"
    ></ng-template>

    <ux-checkbox-field
        *ngIf="column?.type === 'checkbox'"
        [disabled]="column?.unclickable"
        [(value)]="column.value"
        [ngClass]="{
                    '_pseudo-selected': header && _partiallySelectedRows
                }"
    ></ux-checkbox-field>


    <a *ngIf="column?.type === 'link'"
       class="ux-table__link taLink"
       [attr.href]="column?.url"
       (click)="$event.preventDefault()"
       (tap)="_onLinkClick(column, columnIndex, row, $event)"
       rel="noopener noreferrer">
        {{column?.value}}
    </a>

    <div class="ux-table__column-text"
         *ngIf="!column?.type || column?.type === 'text'"
    >
        {{column?.value}}
    </div>

    <div class="ux-table__column-text"
         *ngIf="column?.type === 'pinned'"
    >
        {{column?.value}}
    </div>

    <div class="ux-table__sort">
        <svg *ngIf="column?.sort"
             class="ux-table__sort-svg"
             width="0"
             height="0"
             x="0px"
             y="0px"
             viewBox="0 0 20 20">
            <path
                d="m3.221981,11.477382c0.536104,-0.5484 1.28247,-0.591436 1.937845,0l4.805266,4.607301l4.805266,-4.607301c0.655375,-0.591436 1.40297,-0.5484 1.935386,0c0.536104,0.547171 0.501676,1.471828 0,1.9858c-0.499216,0.513972 -5.772959,5.535647 -5.772959,5.535647c-0.266823,0.2742 -0.617258,0.411915 -0.967693,0.411915s-0.70087,-0.137715 -0.970152,-0.411915c0,0 -5.271284,-5.021675 -5.772959,-5.535647c-0.502905,-0.513972 -0.536104,-1.438629 0,-1.9858z"/>
            <path transform="matrix(0,0.3135004382877268,-0.37380331771130315,0,16.891310221162385,3.3174275487110823) "
                  d="m47.894228,18.521405c0,2.288802 -0.198666,4.138883 -2.487469,4.138883l-44.691656,0c-2.284663,0 -2.487469,-1.850081 -2.487469,-4.138883c0,-2.288802 0.202805,-4.138883 2.487469,-4.138883l44.695795,0c2.284663,0 2.48333,1.850081 2.48333,4.138883z"/>
        </svg>
    </div>

    <div class="ux-table__pin"
         (tap)="_onPinClick(column, columnIndex, row, $event)"
         [ngClass]="{
                '_pinned': column?.pin,
                '_pinnable': column?.pin !== undefined
             }"
    >
        <svg class="ux-table__pin-svg"
             width="0"
             height="0"
             version="1.1"
             viewBox="0 0 20 20">
            <path d="M4.774,15.287l-2.105,3.25L2.893,19.6l1.06-0.227l2.104-3.248c-0.224-0.12-0.446-0.248-0.661-0.39
C5.178,15.594,4.97,15.443,4.774,15.287z M13.686,14.152c0.014-0.029,0.023-0.061,0.036-0.092c0.053-0.117,0.1-0.234,0.138-0.357
c0.006-0.022,0.009-0.044,0.016-0.064c0.039-0.136,0.072-0.27,0.098-0.408c0-0.007,0-0.012,0-0.021
c0.195-1.169-0.145-2.473-0.923-3.651l1.11-1.714c1.279,0.163,2.385-0.159,2.917-0.982c0.923-1.423-0.2-3.792-2.505-5.293
c-2.307-1.502-4.923-1.565-5.844-0.144C8.195,2.25,8.351,3.393,9.022,4.499L7.91,6.213c-1.389-0.233-2.716-0.016-3.703,0.64
C4.201,6.855,4.194,6.857,4.19,6.861C4.075,6.939,3.963,7.025,3.858,7.115C3.841,7.129,3.821,7.142,3.807,7.156
C3.709,7.24,3.621,7.334,3.536,7.428c-0.02,0.024-0.048,0.045-0.067,0.07C3.363,7.619,3.265,7.747,3.179,7.883
c-1.384,2.133-0.203,5.361,2.633,7.209c2.838,1.848,6.26,1.614,7.641-0.519C13.54,14.438,13.62,14.297,13.686,14.152z M12.871,4.194
c-0.887-0.577-1.32-1.487-0.965-2.036c0.354-0.547,1.361-0.522,2.246,0.055c0.889,0.577,1.318,1.489,0.965,2.036
C14.764,4.796,13.759,4.771,12.871,4.194z"/>
        </svg>
    </div>

    <div class="ux-table__filter"
         *ngIf="column?.filter"
         (tap)="_onFilterClick(column, columnIndex, row, $event)"
         [ngClass]="{
                '_filter': column?.filter
             }"
    >
        <svg class="ux-table__filter-svg"
             width="0"
             height="0"
             viewBox="0 0 20 20">
            <path d="M10,1C5.092,1,2,2.512,2,4.001v2c0,0.918,6,6,6,6v6c-0.001,0.684,1,1,2,1s2.001-0.316,2-1v-6c0,0,6-5.082,6-6v-2
		C18,2.512,14.908,1,10,1z M10,6.123C6.409,6.122,3.862,4.79,3.862,4.292C3.86,3.797,6.41,2.461,10,2.463
		c3.59-0.002,6.14,1.334,6.138,1.828C16.138,4.79,13.591,6.122,10,6.123z"></path>
        </svg>
    </div>

    <div *ngIf="header && column?.resizable"
         class="_resizer"
         (mousedown)="_onMD($event)"
    >
    </div>
</div>
