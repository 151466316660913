<sq-overlay-popup [title]="_creatingMode ? 'Create new folder' : 'Folder details'"
                  [visible]="_visible"
                  [contentTemplate]="contentTemplate"
                  [controlsTemplate]="controlsTemplate"
                  [statusEnable]="_operationErrorText"
                  [statusTemplate]="statusTemplate"
                  (onClose)="_onCloseClick()"
>

</sq-overlay-popup>


<ng-template #contentTemplate>
  <form novalidate
        #deviceFolderEditForm
        class="sq-test-folder-edit__form"
        [formGroup]="_formGroup">
    <ux-label [text]="'Name'"
              [required]="true"
    >
      <ux-text-field #testFolderName
                     formControlName="testFolderName"
                     [(value)]="_testModel.name"
                     [placeholder]="'Enter folder name'"
                     [uxTooltip]="!testFolderName.focused && !_formGroup.controls.testFolderName.untouched && _getErrorText('testFolderName')"
                     uxTooltipAppendTo="target"
                     [ngClass]="{
                          '_error': !testFolderName.focused && !_formGroup.controls.testFolderName.untouched && _getErrorText('testFolderName')
                       }"
      >
      </ux-text-field>
    </ux-label>
  </form>
</ng-template>

<ng-template #controlsTemplate>
  <ux-button (tap)="_onEditConfirm()"
             [text]="creatingMode ? 'Create' : 'Update'"
             [kind]="'primary'"
             [disabled]="!_formGroup.valid"
  >
  </ux-button>
</ng-template>


<ng-template #statusTemplate>
  <ux-label [text]="_operationErrorText"
            [ngClass]="'_error'"
  >
  </ux-label>
</ng-template>
