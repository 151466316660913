<div class="ux-label__text taLabel">
    {{text}}
    <ng-template
            [ngTemplateOutlet]="template"
    ></ng-template>
    <span *ngIf="optional"
          class="ux-label__optional"
    >
        ({{optionalText}})
    </span>
</div>
<div class="ux-label__content">
    <ng-content></ng-content>
</div>
