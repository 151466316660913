import {Component, NgZone, ViewChild} from "@angular/core";
import {UxTextFieldComponent} from "../../../../ux-lib/components/fields/text/text-field.component";
import {UxPasswordFieldComponent} from "../../../../ux-lib/components/fields/password/password-field.component";
import {UxAbstractViewValueFieldComponent} from "../../../../ux-lib/components/fields/abstract-view-value-field.component";
import {LoginService} from "../login.service";
import {Subject, Subscription} from "rxjs";
import {takeUntil, tap} from "rxjs/operators";
import {TokenModel} from "../model/token.model";
import {Router} from "@angular/router";

@Component({
  selector: "sq-login",
  templateUrl: "login.component.html",
  host: {"[class.sq-login]": "true"}
})
export class LoginComponent {
  _username: string;
  _password: string;

  _error: string = "";
  _authInProgress: boolean = false;

  private stopSubscription$ = new Subject<boolean>();
  private authSubscription$: Subscription;

  @ViewChild("userName", { static: true })
  private userNameControl: UxTextFieldComponent;

  @ViewChild("password", { static: true })
  private passwordControl: UxPasswordFieldComponent;

  constructor(private loginService: LoginService,
              private router: Router) {
  }

  _onEnter(control: UxAbstractViewValueFieldComponent<string, string>, event: KeyboardEvent) {
    if (control === this.userNameControl) {
      this.passwordControl.focus();
    }
    else {
      this.authorize();
    }
  }

  authorize(): void {
    this._error = undefined;
    let errorMessage = "Please check your login and password";

    if (!this._username) {
      this._error = errorMessage;
    }
    else {
      let userName = this._username;
      this._authInProgress = true;
      this.authSubscription$ && this.authSubscription$.unsubscribe();
      this.authSubscription$ = this.loginService
        .authorize(userName, this._password)
        .pipe(
          takeUntil(this.stopSubscription$),
          tap((token: TokenModel) => {
            this._authInProgress = false;

            if (token.errormsg) {
              this._error = token.errormsg;
            }
            else {
              this.router.navigate([""]);
            }
          })
        )
        .subscribe();
    }
  }
}
