import {Entity} from "./entity.model";
import {TestResultEntity} from "./test-result-entity.model";

export class StatisticsDataEntryBase<T> {
  Sum?: T;
  Count?: T;
  average?: T;
  min?: T;
  min_timestamp?: number;
  min_probe_id?: number|string;
  max?: T;
  max_timestamp?: number;
  max_probe_id?: number|string;
  last?: T;
  last_timestamp?: number;
  last_probe_id?: number|string;

  initiated: boolean;

  public constructor() {
    this.initiated = false;
  }
}

export class StatisticsDataEntry extends StatisticsDataEntryBase<number> {
}

export class StatisticsDataVisualEntry extends StatisticsDataEntryBase<string> {
}

export class StatisticsDataStyledVisualEntry extends StatisticsDataVisualEntry{
  avgLevel?: string;
  minLevel?: string;
  maxLevel?: string;
  lastLevel?: string;
}

export class StatisticsEntityBase<T> extends Entity {
  mos_pvqa?: T;
  mos_aqua?: T;
  r_factor?: T;
  percents_aqua?: T;
  mos_network?: T;

  private id: string;

  public constructor(id?: number) {
    super(id);
    this.mos_pvqa = <T>{};
    this.mos_aqua = <T>{};
    this.r_factor = <T>{};
    this.percents_aqua = <T>{};
    this.mos_network = <T>{};
  }

  getId(): number | string {
    return this.id;
  }

  getIdField(): string {
    return "id";
  }

  setId(value: number | string) {
    if (value !== undefined && value !== null) {
      this.id = value.toString();
    }
  }
}

export class StatisticsEntityBaseWithLastValueTimestamp<T> extends StatisticsEntityBase<T> {
  lastValueTimestampStart?: number;
  lastValueTimestampEnd?: number;

  public constructor(id?: number) {
    super(id);
    this.lastValueTimestampStart = 0;
    this.lastValueTimestampEnd = 0;
  }
}

export class StatisticsStyledVisualEntity extends StatisticsEntityBase<StatisticsDataStyledVisualEntry> {

  public constructor(id?: number) {
    super(id);
  }
}

export class StatisticsEntity extends StatisticsEntityBase<StatisticsDataEntry> {
  start_timestamp: number;
  start_time: string;
  end_timestamp: number;
  end_time: string;
  phone_id: string;
  nr_of_errors: number;
  nr_of_attempts: number;

  public constructor(id?: number) {
    super(id);
  }
}

export interface SummaryStatisticsItemEntry {
  average: number,
  min: number,
  min_probe: TestResultEntity,
  max: number,
  max_probe: TestResultEntity,
  last: number,
  last_probe: TestResultEntity
}

export interface SummaryStatisticsItem {
  mos_aqua: SummaryStatisticsItemEntry,
  mos_pvqa: SummaryStatisticsItemEntry,
  mos_network: SummaryStatisticsItemEntry,
  r_factor: SummaryStatisticsItemEntry,
  percents_aqua: SummaryStatisticsItemEntry,
}

export type KpiStats24 = {
  average: number,
  min: number,
  min_probe: TestResultEntity,
  max: number,
  max_probe: TestResultEntity,
  last: number,
  last_probe: TestResultEntity
};

export type DeviceStats24 = Map<string, KpiStats24>;

export type DeviceStats24Map = Map<string, DeviceStats24>;

export class KpiItem {
  public static readonly kpiMosPvqa: string = "0";
  public static readonly kpiMosAqua: string = "1";
  public static readonly kpiMosNetwork: string = "2";
  public static readonly kpiRfactor: string = "3";
  public static readonly kpiPercents: string = "4";
};