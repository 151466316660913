<ux-popover
    #popover
    [ngClass]="'ux-field-suggestion-popup__inner _suggestion _no-padding _no-arrow ' + styleClass"
    (tap)="_onSuggestionPopupClick($event)"
    [attachPosition]="attachPosition"
    [attachPositionsSequence]="attachPositionsSequence"
    [container]="_popupContainerString"
    [containerEl]="_popupContainerElement?.nativeElement"
    [attachTo]="_attachToString"
    [attachToEl]="_attachToElement?.nativeElement"
    [targetIndent]="8"
    [closeOnOutsideClick]="true"
    [disablePositionUpdateOnScroll]="disablePositionUpdateOnScroll"
    [(visible)]="opened"
    [transitionType]="transitionType"
    [closable]="false"
    [mobile]="mobile"
    (onOpen)="onOpen.emit($event)"
>

    <ng-content select="[nonScrollableBlock]"></ng-content>

    <ux-scroll #scroll class="_full-height _no-padding" tabindex="-1">
        <div class="ux-field-suggestion-popup__wrapper" [style.max-height]="maxListHeight || 'auto'">
            <div class="ux-field-suggestion-popup__content">
                <ng-content>
                </ng-content>
            </div>
        </div>
    </ux-scroll>

</ux-popover>
