<div class="sq-report-wizard__wrapper">
  <div class="sq-report-wizard__header">
   <div class="sq-report-wizard__header-head">
     <div class="sq-report-wizard__header-head _title">
       Report Wizard
     </div>
     <div class="sq-report-wizard__header-head _subtitle">
       <ux-wizard-navigation #wizardNavigation
                             [steps]="wizardSteps"
                             [stepIndex]="_stepIndex"
                             (onStepChange)="_onStepChange($event)"
       >
       </ux-wizard-navigation>
     </div>
    </div>
    <div class="sq-report-wizard__header-navigation">
      <div class="sq-report-wizard__header-navigation-item">
        <ux-navigation-button *ngIf="wizardNavigation.hasPrevious()"
                              [text]="'Back'"
                              [type]="'back'"
                              (onClick)="_onBackClick()"
        >
        </ux-navigation-button>
      </div>

      <div class="sq-report-wizard__header-navigation-item">
        <ux-navigation-button *ngIf="wizardNavigation.hasNext()"
                              [text]="'Next'"
                              [type]="'next'"
                              [kind]="'primary'"
                              (onClick)="_onNextClick(wizardNavigation.hasNext())"
        >
        </ux-navigation-button>

        <ux-button *ngIf="!wizardNavigation.hasNext()"
                   [text]="'Get Report'"
                   [kind]="'primary'"
                   (onClick)="_onGetReport()"
        >
        </ux-button>
      </div>
    </div>
  </div>

  <div class="sq-report-wizard__content"
       [ngSwitch]="wizardNavigation.stepIndex"
  >
    <div class="sq-report-wizard__page"
         *ngSwitchCase="0"
    >
      <sq-devices-card-list #devicesCardList
                            [selectAllEnabled]="true"
                            [selectAllCaption]="'Select all devices'"
                            [selectItemEnabled]="true"
                            [loadStatus]="_devicesLoadingStatus"
                            [entities]="_devices"
                            [selectedEntities]="_selectedDevices"
                            [pagingEnabled]="true"
                            [totalCount]="devicesPagingConfig.totalCount"
                            [pageSize]="devicesPagingConfig.pageSize"
                            [pageSizeOptions]="devicesPagingConfig.pageSizeOptions"
                            [currentPage]="devicesPagingConfig.currentPage"
                            [addEnabled]="false"
                            [editEnabled]="false"
                            [deleteEnabled]="false"
                            [chartEnabled]="false"
                            [expandEnabled]="false"
                            [statisticsEnabled]="false"
                            (onExpandFolderCard)="_onExpandDeviceFolderCard($event)"
                            (onLoadInitialData)="_onLoadDevicesInitialData()"
                            (onPageChange)="_onPageChange($event)"
                            (onPageSizeChange)="_onPageSizeChange($event)"
                            (onCardChecked)="_onCardChecked($event)"
                            (onCardCheckedAll)="_onCardCheckedAll($event)"
      >
      </sq-devices-card-list>
    </div>
    <div class="sq-report-wizard__page"
         *ngSwitchCase="1"
    >
      <sq-tests-card-list #testsCardList
                          [selectAllEnabled]="true"
                          [selectAllCaption]="'Select all tests'"
                          [selectItemEnabled]="true"
                          [loadStatus]="_testsLoadingStatus"
                          [entities]="_tests"
                          [selectedEntities]="_selectedTests"
                          [pagingEnabled]="true"
                          [totalCount]="testsPagingConfig.totalCount"
                          [pageSize]="testsPagingConfig.pageSize"
                          [pageSizeOptions]="testsPagingConfig.pageSizeOptions"
                          [currentPage]="testsPagingConfig.currentPage"
                          [addEnabled]="false"
                          [editEnabled]="false"
                          [deleteEnabled]="false"
                          [chartEnabled]="false"
                          [expandEnabled]="false"
                          [statisticsEnabled]="false"
                          (onExpandFolderCard)="_onExpandTestFolderCard($event)"
                          (onLoadInitialData)="_onLoadTestInitialData()"
                          (onPageChange)="_onPageChange($event)"
                          (onPageSizeChange)="_onPageSizeChange($event)"
                          (onCardChecked)="_onCardChecked($event)"
                          (onCardCheckedAll)="_onCardCheckedAll($event)"
      >
      </sq-tests-card-list>
    </div>
    <div class="sq-report-wizard__page"
         *ngSwitchCase="2"
    >
      <ux-label [text]="'Report period:'">
        <ux-switch-selector-field [items]="_periodItemsModel"
                                  [value]="_selectedPeriod"
                                  (valueChange)="_onPeriodSelected($event)"
        >
        </ux-switch-selector-field>
      </ux-label>

      <ux-label [text]="'Report range:'">
        <ux-range-date-field [(value)]="_selectedRange"
                             [placeholder]="_rangePlaceHolder"
                             [viewValue]="_selectedViewRange"
                             [attachToBody]="false"
                             [disabled]="false"
                             [dateFormat]="null"
                             [beginAvailableDate]="null"
                             [endAvailableDate]="_endAvailableDate"
                             (onCalendarVisibleChange)="_onCalendarVisibleChange($event)"
                             [uxTooltip]="_rangeError"
                             [mobile]="false"
                             [ngClass]="{_error: _rangeError}"
        >
        </ux-range-date-field>
      </ux-label>
    </div>
  </div>

</div>
