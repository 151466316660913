import {Component, ViewChild} from "@angular/core";
import {AppConfigService} from "../../../common/services/app-config.service";
import {Subject, Subscription} from "rxjs";
import {takeUntil, tap} from "rxjs/operators";
import {NotificationService} from "../../../common/services/notification.service";
import {PagedComponent} from "../../../components/base/paged.component";
import {ConfirmPopupComponent} from "../../../components/confirm-popup/confirm-popup.component";
import {Router} from "@angular/router";
import {Progress} from "../../../common/models/progress.model";
import {AudioEntity, UpdateAudioEntity} from "../../../common/models/entity/audio-entity.model";
import {AudioCardListComponent} from "./audio-card-list.component";
import {AudioCardModel} from "../model/audio-card.model";
import {AudioEditComponent} from "./audio-edit.component";
import {AttachFileResult, AudioService, DownloadFileResult} from "../audio.service";
import {ModelService} from "../../../common/services/model.service";
import {DeleteOperationResult} from "../../../common/models/result.type";
import {FileItem, UxAttachmentComponent} from "../../../../ux-lib/components/attachment/attachment.component";

@Component({
  selector: "sq-audio-full-list",
  templateUrl: "audio-full-list.component.html",
  host: {"[class.sq-audio-full-list]": "true"}
})
export class AudioFullListComponent extends PagedComponent {

  _audioFiles: AudioEntity[] = [];
  private _audioFilesMap: Map<number|string, AudioEntity> = new Map<number|string, AudioEntity>();

  _audioLoading: Progress = 'loading';

  _audioDeleteEntity: AudioEntity;

  private stopSubscription$ = new Subject<boolean>();
  private audioListSubscription$: Subscription;
  private audioDeleteSubscription$: Subscription;
  private audioDownloadSubscription$: Subscription;
  private audioUploadSubscription$: Subscription;
  _audioUploading: boolean = false;

  @ViewChild("attachmentAudio", { static: true })
  attachmentAudio: UxAttachmentComponent;

  @ViewChild("audioCardList", { static: true })
  audioCardList: AudioCardListComponent;

  @ViewChild("audioEditPopup", { static: true })
  audioEditPopup: AudioEditComponent;

  audioEditCard: AudioCardModel;

  @ViewChild("audioDeleteConfirmPopup", { static: true })
  audioDeleteConfirmPopup: ConfirmPopupComponent<AudioEntity>;

  constructor(protected configService: AppConfigService,
              private audioService: AudioService,
              private modelService: ModelService,
              private notificationService: NotificationService,
              private router: Router) {
    super(configService)
  }

  public loadPageData(currentPage: number, pageSize: number): void {
    this.retrieveAudioList(currentPage, pageSize, false);
  }

  public destroyComponent() {
    this.stopSubscription$.next(true);
    this.stopSubscription$.complete();
  }

  _onUploadAudio(files: FileItem[]) {
    this._audioUploading = true;
    let fileName = files[0].name;
    this.audioUploadSubscription$ && this.audioUploadSubscription$.unsubscribe();
    this.audioUploadSubscription$ = this.audioService
      .uploadAudio(files[0])
      .pipe(
        takeUntil(this.stopSubscription$),
        tap((attachResult: AttachFileResult) => {
          this._audioUploading = false;

          if (attachResult.opResult) {
            let audio = new AudioEntity(attachResult.audio_id);
            audio.name = fileName;

            this.mergeAudio([audio], true);
            this.attachmentAudio.resetFilesList();

            this.notificationService.pushNotification({
              type: "success",
              content: `Audio ${fileName} uploaded successfully`
            });
          }
        })
      )
      .subscribe();
  }

  _onDownloadCard(audioCard: AudioCardModel) {
    let audioID = audioCard.originalEntity.audio_id;
    let audioName = audioCard.originalEntity.name;
    this.audioDownloadSubscription$ && this.audioDownloadSubscription$.unsubscribe();
    this.audioDownloadSubscription$ = this.audioService
      .downloadAudio(audioID)
      .pipe(
        takeUntil(this.stopSubscription$),
        tap((downloadResult: DownloadFileResult) => {
          var downloadURL = window.URL.createObjectURL(downloadResult.blob);
          var link = document.createElement('a');
          link.href = downloadURL;
          link.download = audioName;
          link.click();

        })
      )
      .subscribe();
  }

  _onEditCard(audioCard: AudioCardModel) {
    this.audioEditCard = audioCard;
    this.audioEditPopup.audioModel = audioCard.originalEntity;
    this.audioEditPopup.show();
  }

  _onDeleteCard(audioCard: AudioCardModel) {
    this._audioDeleteEntity = audioCard.originalEntity;
    this.audioDeleteConfirmPopup.userData = audioCard.originalEntity;
    this.audioDeleteConfirmPopup.show();
  }

  _onAudioEditComplete(audioData: UpdateAudioEntity) {
    if (this._audioFilesMap.has(audioData.entityID)) {
      this._audioFilesMap.get(audioData.entityID).copyFrom(audioData.value);
    }
  }

  _onAudioCancel(audioData: AudioEntity) {
    this._audioDeleteEntity = undefined;
  }

  _onAudioDelete(audio: AudioEntity) {
    let audioID = audio.audio_id;
    let audioName = audio.name;
    this.audioDeleteSubscription$ && this.audioDeleteSubscription$.unsubscribe();
    this.audioDeleteSubscription$ = this.audioService
      .deleteAudio(audioID)
      .pipe(
        takeUntil(this.stopSubscription$),
        tap((data: DeleteOperationResult) => {
          if (data.opResult) {
            //delete entity
            let deletedIndex: number = this.modelService.findInArray(this._audioFiles,
              (item: AudioEntity) => {
              return (item.audio_id === audioID);
            });
            if (deletedIndex >= 0) {
              this._audioFiles.splice(deletedIndex, 1);
            }

          } else {
            this.notificationService.pushNotification({
              type: "error",
              caption: "Deletion error",
              content: `Fail to delete audio '{audioName}'`
            })
          }
        })
      )
      .subscribe();

    this._audioDeleteEntity = undefined;
  }

  private retrieveAudioList(currentPage: number, pageSize: number, merge: boolean): void {
    this.audioListSubscription$ && this.audioListSubscription$.unsubscribe();
    this.audioListSubscription$ = this.audioService
      .listAudio()
      .pipe(
        takeUntil(this.stopSubscription$),
        tap((results: AudioEntity[]) => {
          this.pagingConfig.totalCount = results.length;
          this._pageLoadingStatus = 'loaded';

          let startIndex = (currentPage - 1) * pageSize;
          let endIndex = startIndex + pageSize;

          this.mergeAudio(results.slice(startIndex, endIndex > results.length ? results.length : endIndex), false);
        })
      )
      .subscribe();
  }

  private mergeAudio(audioEntities: AudioEntity[], merge: boolean) {
    if (!merge) {
      this._audioFiles.length = 0;
      this._audioFilesMap.clear();
    }

    for (let audio of audioEntities) {
      if (this._audioFilesMap.has(audio.audio_id)) {
        this._audioFilesMap.get(audio.audio_id).copyFrom(audio);
      }
      else {
        let d = new AudioEntity();
        d.copyFrom(audio);
        this._audioFiles.push(d);
        this._audioFilesMap.set(audio.audio_id, d);
      }
    }
  }
}
