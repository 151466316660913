import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output, ViewChild
} from "@angular/core";
import {DeviceEntity, UpdateDeviceEntity} from "../../../common/models/entity/device-entity.model";
import {UxDropdownListItem} from "../../../../ux-lib/components/fields/dropdown/dropdown-field.component";
import {UxValueChangeEvent} from "../../../../ux-lib/components/fields/abstract-field.component";
import {AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators} from "@angular/forms";
import {DictionaryService} from "../../../common/services/dictionary.service";
import {Subject, Subscription} from "rxjs";
import {DictionaryType} from "../../../common/models/entity/dictionary-type.model";
import {takeUntil, tap} from "rxjs/operators";
import {DevicesService} from "../devices.service";
import {ModelService} from "../../../common/services/model.service";
import {DeviceLinkedTestComponent} from "./device-linked-test.component";
import {PopupComponent} from "../../../components/base/popup.component";
import {UxRadioItemModel} from "../../../../ux-lib/components/fields/radio-group/radio/radio-field.component";
import {SipConfigField} from "../../../common/models/sip-config.type";
import {UxTabChangeEvent} from "../../../../ux-lib/components/tab-panel/tab-panel-event.model";
import {AudioService} from "../../audio/audio.service";
import {AudioEntity} from "../../../common/models/entity/audio-entity.model";
import {ValidatorService} from "../../../common/services/validator.service";
import {PostOperationResult, PutOperationResult} from "../../../common/models/result.type";
import {ReferenceSelectorItem} from "../../../../ux-lib/components/fields/reference/reference-item.model";
import {UxSubmitValueChangeEvent} from "../../../../ux-lib/components/fields/abstract-view-value-field.component";
import {StatisticPeriodType} from "../../../common/models/statistic-period.type";
import {DeviceTypeField} from "../../../common/models/device.type";

@Component({
  selector: "sq-device-edit",
  templateUrl: "device-edit.component.html",
  host: {"[class.sq-device-edit]": "true"}
})
export class DeviceEditComponent extends PopupComponent<DeviceEntity> {

  public static readonly LABEL_NUMBER: string = 'Phone number';
  public static readonly LABEL_SIP: string = 'SIP address';
  public static readonly LABEL_SKYPE: string = 'Skype login';

  public static readonly MASK_NUMBER: string = 'X (XXX) XXX XXXX';

  public static readonly PATTERS_NUMBER: string = '[(][0-9]{3}[)] [0-9]{3} [0-9]{4}';
  public static readonly PATTERS_SIP: string = 'SIP address';
  public static readonly PATTERS_SKYPE: string = 'Skype login';


  _formGroup: FormGroup;
  _selectedTabIndex = 0;
  public _deviceDirectionItems: UxRadioItemModel[] = [
    {id: 'caller', label: 'Calling party', name: 'caller'},
    {id: 'answerer', label: 'Called party', name: 'answerer'}
  ];
  _deviceDirectionValue: UxRadioItemModel = this._deviceDirectionItems[0];

  _typesItemsModel: UxDropdownListItem[] = [
    {id: DeviceTypeField.MOBILE, label: 'Mobile', value: DeviceTypeField.MOBILE},
    {id: DeviceTypeField.SIP, label: 'SIP', value: DeviceTypeField.SIP},
    {id: DeviceTypeField.SKYPE, label: 'Skype', value: DeviceTypeField.SKYPE}
  ];
  _typesItemsValue: UxDropdownListItem = this._typesItemsModel[0];
  _deviceNumberLabel: string = DeviceEditComponent.LABEL_NUMBER;
  private _deviceNumberMask: string = DeviceEditComponent.MASK_NUMBER;

  _operationErrorText: string = "";

  _sipTransportItemsModel: UxDropdownListItem[] = [
    {id: 'udp', label: 'UDP', value: 'udp'},
    {id: 'tcp', label: 'TCP', value: 'tcp'},
    {id: 'tls', label: 'TLS', value: 'tls'}
  ];
  _sipTransportItemsValue: UxDropdownListItem = this._sipTransportItemsModel[0];
  private _sipTransportItemsValueOld: UxDropdownListItem;

  _selectedAudio: ReferenceSelectorItem[];
  _availableAudio: ReferenceSelectorItem[] = [];

  private stopSubscription$ = new Subject<boolean>();
  private dictionarySubscription$: Subscription;
  private deviceCreateSubscription$: Subscription;
  private deviceUpdateSubscription$: Subscription;
  private audioUpdateSubscription$: Subscription;
  private listAudioSubscription$: Subscription;

  @ViewChild("linkedTestsPopup", { static: true })
  linkedTestsPopup: DeviceLinkedTestComponent;

  _creatingMode: boolean = false;
  @Input()
  public set creatingMode(value: boolean) {
    this._creatingMode = value;
    if (this._creatingMode) {
      this.deviceModel = undefined;
    }
  }

  public get creatingMode(): boolean {
    return this._creatingMode;
  }

  private _deviceID: number|string;

  _attrValuesMap: Map<string, string> = new Map();
  private _attrValuesMapOld: Map<string, string> = new Map();
  private _attrKeys: Array<string> = new Array();
  _jsonAttributes: string;
  _deviceModel: DeviceEntity = new DeviceEntity();

  @Input()
  public set deviceModel(value: DeviceEntity) {
    if (value) {
      this._deviceID = value.id;
      this._deviceModel.copyFrom(value);

      this.extractDeviceTypeDirection(this._deviceModel.type);
      this.extractDeviceAttributes(this._deviceModel.attributes, true);

      let audio_id: string = (this._deviceModel.audio_id === undefined || this._deviceModel.audio_id === null) ? null : this._deviceModel.audio_id.toString();
      let item: ReferenceSelectorItem = this._availableAudio
        .find((value: ReferenceSelectorItem) => {
          return value.id === audio_id;
        });
      if (item !== undefined) {
        this._selectedAudio = [item];
      }
      else {
        this._selectedAudio = undefined;
      }

      this._selectedTabIndex = 0;

      this._formGroup.patchValue({
        "deviceName": this.deviceModel.instance,
        "deviceType": this._typesItemsValue,
        "deviceDirection": this._deviceDirectionValue,
        "deviceNumber": this.deviceModel.number,
      });
    }
    else {
      this._deviceModel = new DeviceEntity();
      this.resetFormData();
    }
  }

  public get deviceModel(): DeviceEntity {
    return this._deviceModel;
  }

  @Input()
  public parentEntity: DeviceEntity;

  @Output()
  public onCreateComplete: EventEmitter<DeviceEntity> = new EventEmitter<DeviceEntity>();

  @Output()
  public onEditComplete: EventEmitter<UpdateDeviceEntity> = new EventEmitter<UpdateDeviceEntity>();

  constructor(private audioService: AudioService,
              private devicesService: DevicesService,
              private modelService: ModelService,
              private dictionaryService: DictionaryService,
              private validatorService: ValidatorService,
              private formBuilder: FormBuilder,
              private cdRef: ChangeDetectorRef) {
    super();
  }

  public initComponent() {
    super.initComponent();
    this.initForm();
    this.retrieveAudio();
  }

  public loadInitialData() {
    super.loadInitialData();
    this.cdRef.detectChanges();
  }

  public destroyComponent() {
    this.stopSubscription$.next(true);
    this.stopSubscription$.complete();
  }

  public getUserData(): DeviceEntity {
    return this._deviceModel;
  }

  private extractDeviceTypeDirection(value: string) {
    let devDirection = 'caller';
    let devType = 'sip';

    if (value.indexOf('/') >= 0) {
      devType = value.split('/')[0];
      devDirection = value.split('/')[1];
    }
    else {
      devType = value;
    }
    this._typesItemsValue =
      this.dictionaryService.findDictionaryItem(<DictionaryType[]>(this._typesItemsModel), devType);

    let dirIdx = this.modelService.findInArray(this._deviceDirectionItems, (item: UxRadioItemModel) => {
      return item.id === devDirection;
    });
    if (dirIdx >= 0) {
      this._deviceDirectionValue = this._deviceDirectionItems[dirIdx];
    }
    else {
      this._deviceDirectionValue = this._deviceDirectionItems[0];
    }
  }

  private extractDeviceAttributes(attrValue: string, bInitial: boolean) {
    if (bInitial) {
      this._attrValuesMap.clear();
      this._attrValuesMapOld.clear();
      this._attrKeys = [];
    }

    let attrParams: string[] = [];

    if (attrValue != undefined) {
      attrParams = attrValue.split('\n');
      if (attrParams != undefined) {
        for (let param of attrParams) {
          let pair = param.split('=');
          if (pair !== undefined) {
            let key = pair[0];
            if (this._attrKeys.indexOf(key) < 0) {
              this._attrKeys.push(key);
            }
            if (pair.length === 2) {
              this._attrValuesMap.set(key, pair[1]);
              if (bInitial) {
                this._attrValuesMapOld.set(key, pair[1]);
              }
            }

            if (key === SipConfigField.TRANSPORT && pair.length === 2) {
              let idx = this.modelService.findInArray(this._sipTransportItemsModel, (item: UxDropdownListItem) => {
                return item.id === pair[1];
              });
              if (idx >= 0) {
                this._sipTransportItemsValue = this._sipTransportItemsModel[idx];
              }
              else {
                this._sipTransportItemsValue = this._sipTransportItemsModel[0];
              }
              if (bInitial) {
                this._sipTransportItemsValueOld = this._sipTransportItemsValue;
              }
            }
          }
        }
      }
    }
  }

  private retrieveAudio() {
    this.listAudioSubscription$ && this.listAudioSubscription$.unsubscribe();
    this.listAudioSubscription$ = this.audioService
      .listAudio()
      .pipe(
        takeUntil(this.stopSubscription$),
        tap((audioItems: AudioEntity[]) => {
          this._availableAudio = audioItems.map((value: AudioEntity) => {
            return {
              id: value.audio_id.toString(),
              caption: value.name,
              type:'token'
            };
          });
        })
      )
      .subscribe();
  }

  onTabChange(event: UxTabChangeEvent): void {
    this._selectedTabIndex = event.index;
  }

  _onDeviceDirectionChange(event: UxValueChangeEvent<UxRadioItemModel>) {
    if (event && event.newValue) {
      let idx = this.modelService.findInArray(this._deviceDirectionItems, (item: UxRadioItemModel) => {
        return item.id === event.newValue.id;
      });
      if (idx >= 0) {
        this._deviceDirectionValue = this._deviceDirectionItems[idx];
      }
      else {
        this._deviceDirectionValue = this._deviceDirectionItems[0];
      }
    }
  }

  _onSipAttrChanged(control: string, event: UxValueChangeEvent<string|UxDropdownListItem>): void {

    if (this._attrKeys.indexOf(control) < 0) {
      this._attrKeys.push(control);
    }

    if (control === 'transport') {
      if (event && event.newValue) {
        let ddEvent: UxValueChangeEvent<UxDropdownListItem> = <UxValueChangeEvent<UxDropdownListItem>>(event);
        this._attrValuesMap.set(control, ddEvent.newValue.value);
      }
    }
    else {
      let strEvent: UxValueChangeEvent<string> = <UxValueChangeEvent<string>>(event);
      this._attrValuesMap.set(control, strEvent.newValue);
    }
    this.compileAttributesValue();
  }

  _onAttributesChanged(event: UxSubmitValueChangeEvent) {
    this.extractDeviceAttributes(event.value, false);
  }

  private compileAttributesValue(): void {
    let attrValue: string = "";

    for (let key of this._attrKeys) {
      let oldVal = null;
      let newVal = null;

      if (this._attrValuesMap.has(key)) {
        newVal = this._attrValuesMap.get(key);
        oldVal = this._attrValuesMapOld.get(key);

        if ((this.modelService.isEmptyString(newVal) && oldVal !== null) ||
            !this.modelService.isEmptyString(newVal)) {

          attrValue = this.appendAttr(key, this._attrValuesMap.get(key), attrValue);
        }
      }
      else {
        attrValue = this.appendAttr(key, undefined, attrValue);
      }
    }

    this._jsonAttributes = attrValue;
  }

  private appendAttr(key: string, value: string, result: string): string {
    if (result !== undefined && result.length > 0) {
      result += "\n";
      result += key;
    }
    else {
      result = key;
    }

    if (value !== undefined && value !== null) {
      result += "=";
      result += value;
    }

    return result;
  }

  _onDeviceTypeChange(event: UxValueChangeEvent<UxDropdownListItem>) {
    if (event && event.newValue) {
      this._deviceModel.type = event.newValue.value;

      let deviceNumber: AbstractControl = this._formGroup.get("deviceNumber");

      if (event.newValue.value === DeviceTypeField.MOBILE) {
        this._deviceNumberLabel = DeviceEditComponent.LABEL_NUMBER;
        deviceNumber.setValidators([Validators.required,
          Validators.pattern(this.validatorService.getNumberValidationPattern())]);
      }
      else if (event.newValue.value === DeviceTypeField.SIP) {
        this._deviceNumberLabel = DeviceEditComponent.LABEL_SIP;
        deviceNumber.setValidators([Validators.required,
          Validators.pattern(this.validatorService.getSipValidationPattern())]);
      }
      else if (event.newValue.value === DeviceTypeField.SKYPE) {
        this._deviceNumberLabel = DeviceEditComponent.LABEL_SKYPE;
        deviceNumber.setValidators([Validators.required,
          Validators.pattern(this.validatorService.getSkypeValidationPattern())]);
      }
      if (!this.modelService.isEmptyString(this._deviceModel.number)) {
        deviceNumber.updateValueAndValidity();
      }
    }
  }

  _onDeviceNameChange(event: UxValueChangeEvent<string>) {
    if (event && event.newValue) {
      this._deviceModel.instance = event.newValue;
    }
  }

  _onDeviceNumberChange(event: UxValueChangeEvent<string>) {
    if (event && event.newValue) {
      this._deviceModel.number = event.newValue;
    }
  }

  public _getErrorText(controlName: string): string {
    let errorText = "",
      control = this._formGroup.controls[controlName];

    if (control.errors) {
      errorText = "!";
      let errors: ValidationErrors = control.errors;

      if (controlName === "deviceName") {
        if (errors["required"] !== undefined) {
          errorText = "Device name is required";
        }
      } else if (controlName === "deviceNumber") {
        if (errors["required"] !== undefined) {
          if (this._typesItemsValue.id == DeviceTypeField.MOBILE) {
            errorText = "Phone number is required";
          }
          else if (this._typesItemsValue.id == DeviceTypeField.SIP) {
            errorText = "Valid SIP address is required";
          }
          else if (this._typesItemsValue.id == DeviceTypeField.SKYPE) {
            errorText = "Valid skype login is required";
          }
        } else if (errors["pattern"] !== undefined) {
          if (this._typesItemsValue.id == DeviceTypeField.MOBILE) {
            errorText = "Invalid phone number used";
          }
          else if (this._typesItemsValue.id == DeviceTypeField.SIP) {
            errorText = "Invalid SIP address used";
          }
          else if (this._typesItemsValue.id == DeviceTypeField.SKYPE) {
            errorText = "Invalid skype login used";
          }
        }
      }
    }
    return errorText;
  }

  _onLinkedTest(): void {
    this.linkedTestsPopup.device = this.deviceModel;
    this.linkedTestsPopup.show();
  }

  _onEditConfirm() {
    this._deviceModel.type = this._typesItemsValue.value + '/' + this._deviceDirectionValue.id;
    let attrPairs: string[] = [];

    this._deviceModel.attributes = this._jsonAttributes;

    if (this._deviceDirectionValue.name === 'answerer'
      && this._selectedAudio !== undefined
      && this._selectedAudio.length > 0
      && this._selectedAudio[0]
      && this._selectedAudio[0].id !== undefined) {
      this._deviceModel.audio_id = Number(this._selectedAudio[0].id);
    }

    if (this.creatingMode) {
      if (this.parentEntity) {
        this._deviceModel.parent_id = this.parentEntity.id;
      }
      else {
        this._deviceModel.parent_id = 0;
      }

      this.deviceCreateSubscription$ && this.deviceCreateSubscription$.unsubscribe();
      this.deviceCreateSubscription$ = this.devicesService
        .createEntity(this._deviceModel)
        .pipe(
          takeUntil(this.stopSubscription$),
          tap((data: PostOperationResult<DeviceEntity>) => {
            if (data.opResult) {
              let createdDevice = new DeviceEntity(data.opEntity.parent_id, data.opEntity.id);
              createdDevice.copyFrom(data.opEntity);

              let updateDevice: UpdateDeviceEntity = {
                entityID: createdDevice.id,
                value: createdDevice
              }

              this.updateCompleted(updateDevice);
            } else {
              this._operationErrorText = "Failed to create device";
            }
          })
        )
        .subscribe()
    }
    else {
      this.deviceUpdateSubscription$ && this.deviceUpdateSubscription$.unsubscribe();
      this.deviceUpdateSubscription$ = this.devicesService
        .updateEntityById(this._deviceID, this._deviceModel)
        .pipe(
          takeUntil(this.stopSubscription$),
          tap((data: PutOperationResult<DeviceEntity>) => {
            if (data.opResult) {
              let updateDevice: UpdateDeviceEntity = {
                entityID: this._deviceID,
                value: this._deviceModel
              }
              this.updateCompleted(updateDevice);
            }
            else {
              this._operationErrorText = "Failed o update device information";
            }
          })
        )
        .subscribe();
    }
  }

  private updateCompleted(updateDevice: UpdateDeviceEntity) {
    if (this.creatingMode) {
      this.onCreateComplete.emit(updateDevice.value);
    }
    else {
      this.onEditComplete.emit(updateDevice);
    }
    this._close();
  }

  protected _close() {
    this.parentEntity = undefined;
    this.resetFormData();
    super._close();
  }

  private initForm(): void {
    this._selectedTabIndex = 0;
    this._formGroup = this.formBuilder.group({
      "deviceName": ["", Validators.compose([Validators.required])],
      "deviceType": [this._typesItemsValue],
      "deviceDirection": [this._deviceDirectionValue],
      "deviceNumber": ["", Validators.compose([
        Validators.required,
        Validators.pattern(this.validatorService.getNumberValidationPattern())])
      ]
    });
  }

  private resetFormData() {
    this._formGroup.updateValueAndValidity();
    this._formGroup.reset();
    this._formGroup.markAsUntouched();

    this._selectedTabIndex = 0;
    this._deviceDirectionValue = this._deviceDirectionItems[0];
    this._typesItemsValue = this._typesItemsModel[0];

    this._formGroup.patchValue({
      "deviceType": this._typesItemsValue,
      "deviceDirection": this._deviceDirectionValue
    });

  }
}
