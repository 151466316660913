import {NgModule} from "@angular/core";
import {SharedModule} from "../../../shared/shared.module";
import {FormsModule} from "@angular/forms";
import {UxPasswordFieldComponent} from "./password-field.component";

@NgModule({
    imports: [SharedModule, FormsModule],
    declarations: [UxPasswordFieldComponent],
    exports: [UxPasswordFieldComponent]
})
export class UxPasswordFieldModule {
}