import {NgModule} from "@angular/core";

import {SharedModule} from "../../shared/shared.module";
import {UxPagingComponent} from "./paging.component";
import {UxDropdownFieldModule} from "../fields/dropdown/dropdown-field.module";

@NgModule({
    imports: [SharedModule, UxDropdownFieldModule],
    declarations: [UxPagingComponent],
    exports: [UxPagingComponent]
})
export class UxPagingModule {
}
