import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    HostListener,
    Input,
    Output,
    TemplateRef
} from "@angular/core";
import {UxPropertyValidator} from "../../common/decorator/ux-property-validator";
import {UxPropertyConverter} from "../../common/decorator/ux-property-converter";
import {UxPropertyHandler} from "../../common/decorator/ux-property-handler";

export const COMMON_TIMEOUT = 4200;

/* deprecated use instead UxNotificationType. todo remove in 2.0 version */
export type NotificationType = keyof NotificationTypes;
export type UxNotificationType = keyof UxNotificationTypes;

/* deprecated use instead UxNotificationTypes. todo remove in 2.0 version */
export class NotificationTypes {
    'none' = true;
    'info' = true;
    'warn' = true;
    'error' = true;
    'success' = true;
}

export class UxNotificationTypes extends NotificationTypes {
}

const NOTIFICATION_TYPES = new NotificationTypes();

@Component({
    selector: "ux-notification",
    templateUrl: "./notification.component.html",
    host: {
        "[class.ux-notification]": "true",
        "[class._none]": "type === 'none'",
        "[class._info]": "type === 'info'",
        "[class._warn]": "type === 'warn'",
        "[class._error]": "type === 'error'",
        "[class._success]": "type === 'success'"
    }
})
export class UxNotificationComponent implements AfterViewInit {

    @UxPropertyValidator({
        isValid: isValidType,
        message: "Property should be instanceof NotificationType"
    }, "custom")
    @Input()
    public type: NotificationType = "info";

    @Input()
    public caption: string;

    @Input()
    public content: string;

    @Input()
    public template: TemplateRef<any>;

    @Input()
    public customData: any;

    @HostBinding("class._closable")
    @UxPropertyConverter("boolean", false)
    @Input()
    public closable: boolean;

    @UxPropertyConverter("number", 0)
    @UxPropertyHandler({
        afterChange: afterChangeTimeout
    })
    @Input()
    public timeout: number;

    @UxPropertyHandler({
        afterChange: afterChangeVisible
    })
    @Input()
    public visible: boolean = false;

    @HostBinding("class._visible")
    private visibleStyleClass: boolean = false;

    @HostBinding("class._mobile")
    @Input()
    public mobile: boolean = false;

    @Output()
    public onClose: EventEmitter<MouseEvent | {}> = new EventEmitter<MouseEvent | {}>();

    /* DEPRECATED use visibleChange instead. TODO remove after 2.0.0 version */
    @Output()
    public onVisibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Output()
    public visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    private timeoutTimerId: number;

    private viewInited: boolean = false;

    constructor(private elementRef: ElementRef) {
    }

    public ngAfterViewInit(): void {
        this.viewInited = true;
    }

    /** @internal */
    public _onCloseClick(event: any): void {
        let self = this;
        self.visible = false;
        self.onClose.emit(event);
    }

    private runTimeout(): void {
        let self = this;
        if (self.timeoutTimerId) {
            window.clearTimeout(self.timeoutTimerId);
        }
        if (self.timeout && self.visible) {
            self.timeoutTimerId = window.setTimeout(() => {
                self.visible = false;
                self.timeoutTimerId = null;
            }, self.timeout);
        }
    }

    @HostListener("swipeup", ["$event"])
    @HostListener("swiperight", ["$event"])
    private swipe(event: any): void {
        this.mobile && this._onCloseClick(event);
    }

    @HostListener("transitionend", ["$event"])
    private onTransitionEnd(event: any) {
        // As we have several animation properties we need to check it in order
        // to prevent multiple function execution on the same visibility change
        if (event.propertyName === "visibility" && !this.visibleStyleClass) {
            this.elementRef.nativeElement.style.display = "none";
        }
    }
}


/*Helpers*/
export function isValidType(value: string): void {
    return NOTIFICATION_TYPES[value];
}

export function afterChangeTimeout(value: number): void {
    this.runTimeout();
}

export function afterChangeVisible(value: boolean): void {
    if (this.viewInited) {
        this.onVisibleChange.emit(value);
        this.visibleChange.emit(value);
    }

    if (value) {
        this.elementRef.nativeElement.style.display = "block";

        // Need to wait till display: 'block' will be applied to enable animation
        requestAnimationFrame(() => {
            this.visibleStyleClass = true;
        });
    } else {
        this.visibleStyleClass = false;
    }

    this.runTimeout();
}
