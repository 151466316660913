import {Component, ContentChild, ElementRef, Input, HostBinding} from "@angular/core";
import {UxAbstractButtonComponent} from "./abstract-button.component";
import {UxPropertyValidator} from "../../common/decorator/ux-property-validator";
import {UxPropertyHandler} from "../../common/decorator/ux-property-handler";
import {UxPropertyConverter} from "../../common/decorator/ux-property-converter";

export type UxButtonType = keyof UxButtonTypes;
export type UxButtonIcon = keyof UxButtonIcons;
export type UxButtonKind = keyof UxButtonKinds;

/* deprecated use instead UxButtonTypes. todo remove in 2.0 version */
export class UxButtonTypes {
  'button' = true;
  'reset' = true;
  'submit' = true;
}

const BUTTON_TYPES = new UxButtonTypes();

/* deprecated use instead UxButtonIcons. todo remove in 2.0 version */
export class UxButtonIcons {
  'none' = true;
  'search-white' = true;
  'plus-black' = true;
  'minus-black' = true;
  'funnel-black' = true;
  'trash-black' = true;
  'add-link' = true;
  'add-folder' = true;
  'add-device' = true;
  'add-task' = true;
}

const BUTTON_ICONS = new UxButtonIcons();

/* deprecated use instead UxButtonKinds. todo remove in 2.0 version */
export class UxButtonKinds {
  'primary' = true;
  'default' = true;
}

const BUTTON_KINDS = new UxButtonKinds();

@Component({
  selector: "ux-button",
  templateUrl: "button.component.html"
})
export class UxButtonComponent extends UxAbstractButtonComponent {

  @HostBinding("class.ux-button")
  private hostClass: boolean = true;

  @UxPropertyValidator({
    isValid: isValidType,
    message: "Property should be instanceof ButtonType"
  }, "custom")
  @Input()
  public type: UxButtonType = "button";

  @Input()
  public name: string = "";

  @UxPropertyValidator({
    isValid: isValidIcon,
    message: "Property should be instanceof ButtonIcon"
  }, "custom")
  @UxPropertyHandler({
    beforeChange: beforeChangeIcon
  })
  @Input()
  public icon: UxButtonIcon = "none";

  @UxPropertyValidator({
    isValid: isValidKind,
    message: "Property should be instanceof ButtonKind"
  }, "custom")
  @UxPropertyHandler({
    beforeChange: beforeChangeKind
  })
  @Input()
  public kind: UxButtonKind = "default";

  @UxPropertyConverter("string[]", undefined, {map: uxPropertyConverterMapModifiers})
  @UxPropertyHandler({
    beforeChange: beforeChangeModifiers
  })
  @Input()
  public modifiers: string[];


  @ContentChild('customIcon', /* TODO: add static flag */ {static: true})
  private set customIcon(iconElement: ElementRef) {
    if (iconElement === undefined) {
      this._customIcon = false;

      if (this.icon === "none") {
        this._setStyleClass('_with-icon', false);
      }
    } else {
      this._setStyleClass('_with-icon', true);
      this._customIcon = true;
    }
  }

  /** @internal */
  public _customIcon: boolean = false;
}

/*Helpers*/
export function beforeChangeModifiers(newValue: string[], oldValue: string[]) {
  let addValues: string[];
  let removeValues: string[];
  if (newValue && oldValue) {
    addValues = newValue.filter((x: string) => oldValue.indexOf(x) === -1);
    removeValues = oldValue.filter((x: string) => newValue.indexOf(x) === -1);
  } else if (newValue) {
    addValues = newValue;
  } else if (oldValue) {
    removeValues = oldValue;
  }
  // add new items
  if (addValues) {
    addValues.forEach(x => this._setStyleClass(x, true));
  }
  // remove old values
  if (removeValues) {
    removeValues.forEach(x => this._setStyleClass(x, false));
  }
}

export function uxPropertyConverterMapModifiers(x: string) {
  return x.startsWith("_") ? x : "_" + x;
}

export function isValidType(value: string) {
  return BUTTON_TYPES[value];
}

export function isValidIcon(value: string) {
  return value === undefined || BUTTON_ICONS[value];
}

export function beforeChangeIcon(newValue: string, oldValue: string): void {
  if ((newValue === undefined || newValue === "none") && this._customIcon === false) {
    this._setStyleClass('_with-icon', false);
  } else {
    this._setStyleClass('_with-icon', true);
  }
}

export function isValidKind(value: string): void {
  return BUTTON_KINDS[value];
}

export function beforeChangeKind(newValue: string, oldValue: string): void {
  oldValue = oldValue || "default";
  newValue = newValue || "default";
  this._setStyleClass(`_kind-${oldValue}`, false);
  this._setStyleClass(`_kind-${newValue}`, true);
}
