
import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {TestsRoutingModule} from "src/app/modules/tests/tests-routing.module";
import {TestsService} from "./tests.service";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {UxButtonModule} from "../../../ux-lib/components/button/button.module";
import {UxSearchFieldModule} from "../../../ux-lib/components/fields/search/search-field.module";
import {UxTableModule} from "../../../ux-lib/components/table/table.module";
import {UxLabelModule} from "../../../ux-lib/components/label/label.module";
import {UxTextFieldModule} from "../../../ux-lib/components/fields/text/text-field.module";
import {ModelService} from "../../common/services/model.service";
import {NotificationService} from "../../common/services/notification.service";
import {UxConfirmDialogModule} from "../../../ux-lib/components/confirm-dialog/confirm-dialog.module";
import {UxToolbarModule} from "../../../ux-lib/components/toolbar/toolbar.module";
import {UxEditableModule} from "../../../ux-lib/components/editable/editable.module";
import {UxFormFieldModule} from "../../../ux-lib/components/fields/form/form-field.module";
import {UxPopoverModule} from "../../../ux-lib/components/popover/popover.module";
import {UxOverlayModule} from "../../../ux-lib/components/overlay/overlay.module";
import {UxTooltipModule} from "../../../ux-lib/components/tooltip/tooltip.module";
import {UxBreadcrumbsModule} from "../../../ux-lib/components/breadcrumbs/breadcrumbs.module";
import {ResultsModule} from "../results/results.module";
import {ResultsService} from "../results/results.service";
import {UxPagingModule} from "../../../ux-lib/components/paging/paging.module";
import {UxAttachmentModule} from "../../../ux-lib/components/attachment/attachment.module";
import {UxAttachedFileModule} from "../../../ux-lib/components/attached-file/attached-file.module";
import {UxRoundDotsLoaderModule} from "../../../ux-lib/components/loader/round-dots-loader/round-dots-loader.module";
import {UxDropdownFieldModule} from "../../../ux-lib/components/fields/dropdown/dropdown-field.module";
import {UxCheckboxFieldModule} from "../../../ux-lib/components/fields/checkbox/checkbox-field.module";
import {UxEditableDropDownModule} from "../../../ux-lib/components/editable-dropdown/editable-dropdown.module";
import {DictionaryService} from "../../common/services/dictionary.service";
import {UxReferenceFieldModule} from "../../../ux-lib/components/fields/reference/reference-field.module";
import {DevicesService} from "../devices/devices.service";
import {UxEditableReferenceModule} from "../../../ux-lib/components/editable-reference/editable-reference.module";
import {UxMenuModule} from "../../../ux-lib/components/menu/menu.module";
import {TestsCardListComponent} from "./components/tests-card-list.component";
import {UxCardTreeModule} from "../../../ux-lib/components/card-tree/card-tree.module";
import {TestsFullListComponent} from "./components/tests-full-list.component";
import {TestEditComponent} from "./components/test-edit.component";
import {OverlayPopupModule} from "../../components/overlay-popup/overlay-popup.module";
import {SharedModule} from "../../components/shared.module";
import {CardListModule} from "../../components/card-list/card-list.module";
import {SevanaQualtestAuthGuard} from "../../sevana-qualtest-auth.guard";
import {ReportsService} from "../reports/reports.service";
import {TestFolderEditComponent} from "./components/test-folder-edit.component";
import {UxTabPanelModule} from "../../../ux-lib/components/tab-panel/tab-panel.module";
import {UxMemoFieldModule} from "../../../ux-lib/components/fields/memo/memo-field.module";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        SharedModule,
        ReactiveFormsModule,
        TestsRoutingModule,
        ResultsModule,
        UxButtonModule,
        UxSearchFieldModule,
        UxTableModule,
        UxLabelModule,
        UxTextFieldModule,
        UxDropdownFieldModule,
        UxCheckboxFieldModule,
        UxReferenceFieldModule,
        UxBreadcrumbsModule,
        UxOverlayModule,
        UxPopoverModule,
        UxFormFieldModule,
        UxEditableModule,
        UxEditableDropDownModule,
        UxEditableReferenceModule,
        UxToolbarModule,
        UxTooltipModule,
        UxPagingModule,
        UxMenuModule,
        UxAttachmentModule,
        UxAttachedFileModule,
        UxRoundDotsLoaderModule,
        UxConfirmDialogModule,
        UxCardTreeModule,
        OverlayPopupModule,
        CardListModule,
        UxTabPanelModule,
        UxMemoFieldModule
    ],
  declarations: [
    TestsCardListComponent,
    TestsFullListComponent,
    TestEditComponent,
    TestFolderEditComponent
  ],
  entryComponents: [
    TestsFullListComponent
  ],
  exports: [
    TestsCardListComponent
  ],
  providers: [
    SevanaQualtestAuthGuard,
    TestsService,
    DevicesService,
    ResultsService,
    ModelService,
    ReportsService,
    NotificationService,
    DictionaryService
  ]
})
export class TestsModule {

}
