import {
    Component,
    Input,
    Output,
    EventEmitter,
    ViewChild,
    ElementRef,
    AfterViewInit,
    ChangeDetectionStrategy,
    OnDestroy
} from "@angular/core";
import {UxAbstractViewValueFieldComponent} from "../abstract-view-value-field.component";
import {NG_VALUE_ACCESSOR} from "@angular/forms";
import {UxPropertyHandler} from "../../../common/decorator/ux-property-handler";
import {UxPropertyConverter} from "../../../common/decorator/ux-property-converter";

@Component({
    selector: "ux-mask-field",
    templateUrl: "mask-field.component.html",
    host: {"[class.ux-mask-field]": "true"},
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: UxMaskFieldComponent,
            multi: true
        }
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UxMaskFieldComponent extends UxAbstractViewValueFieldComponent<string, string> implements AfterViewInit, OnDestroy {

    @Input()
    public prefix: string;

    @Input()
    public suffix: string;

    @UxPropertyHandler({
        afterChange: afterChange
    })
    @Input()
    public mask: string;

    @UxPropertyHandler({
        afterChange: afterChange
    })
    @Input()
    public maskPattern: string;

    @UxPropertyHandler({
        afterChange: afterChange
    })
    @Input()
    public validValueExample: string;

    @UxPropertyConverter("boolean", false)
    @Input()
    public valueIncludeMask: boolean;

    @UxPropertyConverter("boolean", true)
    @Input()
    public showMask: boolean;

    @UxPropertyConverter("string", "X")
    @Input()
    public allowedMaskSymbols: string;

    @Input()
    public autofocus: boolean = false;

    @Output()
    public onValueError: EventEmitter<UxMaskValueChangeEvent> = new EventEmitter<UxMaskValueChangeEvent>();

    @ViewChild("maskInput", { static: true })
    private maskInputElementReference: ElementRef;

    private lastCorrectViewValue: string = null;
    private focusTimeoutID: number;

    public ngAfterViewInit(): void {
        if (this.autofocus) {
            this.focus();
        }
    }

    public ngOnDestroy(): void {
        clearTimeout(this.focusTimeoutID);
    }

    public focus(): void {
        clearTimeout(this.focusTimeoutID);
        this.focusTimeoutID = window.setTimeout(() => {
            this.focusTimeoutID = null;
            this.maskInputElementReference.nativeElement.focus();
        }, 0);
    }

    /** @internal */
    public _onInput(event: Event): void {
        let self = this;
        // Transform value to mask
        let resultValue = self.getViewValueConverter()(self.viewValue);
        // Validate mask pattern
        if (self.maskPattern && self.validValueExample
            && !((resultValue + self.validValueExample.substring(resultValue.length)).match(self.maskPattern))) {
            self.onValueError.emit({
                value: resultValue,
                originalEvent: event
            });
            resultValue = self.lastCorrectViewValue || "";
        } else {
            self.lastCorrectViewValue = resultValue;
        }
        // Apply new value
        this.maskInputElementReference.nativeElement.value = resultValue; // This line is required
        self.viewValue = resultValue;
        self.value = resultValue;
    }

    /** @internal */
    public _cutMask(): string {
        return this._stringToHtml(this.mask && this.viewValue ? this.mask.substring(this.viewValue.length, this.mask.length) : this.mask);
    }

    /** @internal */
    public _stringToHtml(value: string): string {
        if (value) {
            return value.split(" ").join("&nbsp;"); // Add other symbols if needed
        }
        return "";
    }

    protected getDefaultValue(): string {
        return undefined;
    }

    protected getViewValueConverter(): (value: string) => string {
        let self = this;
        return (value: string) => {
            if (self.mask) {
                let result = "";
                let fullLength = self.mask.length;
                for (let i = 0, j = 0; value && i < fullLength && j < fullLength && j < value.length; i++) {
                    let maskChar = self.mask[i];
                    result += self.allowedMaskSymbols.indexOf(maskChar) >= 0 || value[j] === maskChar ? value[j++] : maskChar;
                }
                return result;
            }
            return value;
        };
    }

    protected getValueConverter(): (value: string) => string {
        let self = this;
        return (value: string) => {
            if (self.mask) {
                value = self.getViewValueConverter()(value);
                if (self.valueIncludeMask) {
                    return value;
                }
                let result = "";
                for (let i = 0; i < value.length; i++) {
                    if (self.allowedMaskSymbols.indexOf(this.mask[i]) >= 0) {
                        result += value[i];
                    }
                }
                return result;
            }
            return value;
        };
    }
}

export function afterChange(): void {
    // Required if you set value and then mask
    this._onValueChange({oldValue: this.value, newValue: this.value});
}

export interface UxMaskValueChangeEvent {
    originalEvent: Event;
    value: string;
}
