<ux-overlay [visible]="_visible">
  <div class="sq-confirm-popup__wrapper">
    <div class="sq-confirm-popup__header">
      <div class="sq-confirm-popup__header-inner">
        {{title}}
      </div>
      <div class="sq-confirm-popup__header__close-icon"
           (tap)="_onCloseClick()"
      >
        <svg class="sq-confirm-popup__header__close-icon-svg"
             width="0" height="0"
             viewBox="0 0 20 20">
          <path d="M14.348,14.849c-0.469,0.469-1.229,0.469-1.697,0L10,11.819l-2.651,3.029c-0.469,0.469-1.229,0.469-1.697,0
              c-0.469-0.469-0.469-1.229,0-1.697l2.758-3.15L5.651,6.849c-0.469-0.469-0.469-1.228,0-1.697c0.469-0.469,1.228-0.469,1.697,0
              L10,8.183l2.651-3.031c0.469-0.469,1.228-0.469,1.697,0c0.469,0.469,0.469,1.229,0,1.697l-2.758,3.152l2.758,3.15
              C14.817,13.62,14.817,14.38,14.348,14.849z"/>
        </svg>
      </div>
    </div>
    <div class="sq-confirm-popup__content">
      {{message}}
    </div>
    <div class="sq-confirm-popup__footer">
      <ux-button (tap)="_onCancel()"
                 [text]="cancelCaption"
      >
      </ux-button>
      <ux-button (tap)="_onAccept()"
                 [text]="acceptCaption"
                 [kind]="'primary'"
      >
      </ux-button>
    </div>
  </div>
</ux-overlay>
