import {Component, EventEmitter, Input, OnChanges, Output, TemplateRef} from "@angular/core";
import {MenuItem} from "./model/menu-item.model";
import {MenuItemChangeEvent} from "./model/menu-item-change-event.model";

@Component({
    selector: "ux-menu",
    templateUrl: "./menu.component.html",
    host: {"[class.ux-menu]": "true"}
})
export class UxMenuComponent implements OnChanges {

    @Input()
    public caption: string;

    @Input()
    public items: MenuItem[];

    @Input()
    public template: TemplateRef<any>;

    @Input()
    public mobile: boolean = false;

    @Output()
    public onItemSelect: EventEmitter<MenuItemChangeEvent> = new EventEmitter<MenuItemChangeEvent>();

    @Output()
    public onItemClose: EventEmitter<MenuItemChangeEvent> = new EventEmitter<MenuItemChangeEvent>();

    public ngOnChanges(): void {
        let self = this;
        if (self.items && self.items.length) {
            let selectedItem: MenuItem = self.findSelectedItem();
            if (selectedItem) {
                self._itemClick(null, selectedItem);
            }
        }
    }

    /** @internal */
    public _itemClick(event: Event, item: MenuItem): void {
        if (!item.disabled) {
            let selectedItem = this.findSelectedItem();
            if (selectedItem !== item) {
                if (selectedItem) {
                    selectedItem.selected = false;
                }
                item.selected = true;

                this.onItemSelect.emit({
                    item: item,
                    originalEvent: event
                });
            }
        }
    }

    /** @internal */
    public _onItemClose(event: any, item: MenuItem): void {
        event.stopPropagation();
        this.onItemClose.emit({
            item: item,
            originalEvent: event
        });
    }

    private findSelectedItem(): MenuItem {
        let self = this;
        if (!self.items) {
            return null;
        }
        for (let item of self.items) {
            if (item.selected) {
                return item;
            }
        }
        return null;
    }

    /** @internal */
    public _trackByFn(index: number, item: MenuItem): string {
        return item && item.id;
    }
}
