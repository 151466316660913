import {UxRouteTabPanelComponent} from "./route-tab-panel.component";
import {NgModule} from "@angular/core";
import {RouterModule} from "@angular/router";
import {SharedModule} from "../../shared/shared.module";

@NgModule({
    imports: [
        SharedModule,
        RouterModule
    ],
    exports: [UxRouteTabPanelComponent],
    declarations: [UxRouteTabPanelComponent]
})
export class UxRouteTabPanelModule {
}