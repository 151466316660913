import {Injectable} from "@angular/core";

@Injectable()
export class ValidatorService {

  validate(value: string, pattern: string | RegExp): boolean {
    if (!pattern)
      return true;

    var regex;
    var regexStr;
    if (typeof pattern === 'string') {
      regexStr = '';
      if (pattern.charAt(0) !== '^')
        regexStr += '^';
      regexStr += pattern;
      if (pattern.charAt(pattern.length - 1) !== '$')
        regexStr += '$';
      regex = new RegExp(regexStr);
    }
    else {
      regexStr = pattern.toString();
      regex = pattern;
    }

    return regex.test(value);
  }

  public getNumberValidationPattern(): string | RegExp {
    return /(^[+][1-9]{1,3}[0-9]{5,12})|(^[0-9]{5,12})$/;
  }

  public getSipValidationPattern(): string | RegExp {
    return /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;
  }

  public getSkypeValidationPattern(): string | RegExp {
    return /[a-zA-Z][a-zA-Z0-9\.,\-_]{5,31}/;
  }
}
