import {
  Component,
  EventEmitter,
  Input,
  Output
} from "@angular/core";
import {PopupComponent} from "../base/popup.component";

@Component({
  selector: "sq-confirm-popup",
  templateUrl: "confirm-popup.component.html",
  host: {"[class.sq-confirm-popup]": "true"}
})
export class ConfirmPopupComponent<T> extends PopupComponent<T> {
  @Input()
  public userData: T;

  @Input()
  public title: string = "Please confirm";

  @Input()
  public message: string = "Are you sure?";

  @Input()
  public cancelCaption: string = "Cancel";

  @Input()
  public acceptCaption: string = "OK";

  @Output()
  public onAccepted: EventEmitter<T> = new EventEmitter<T>();

  constructor() {
    super();
  }

  _onAccept(): void {
    this.onAccepted.emit(this.getUserData());
    this._close();
  }

  public getUserData(): T {
    return this.userData;
  }
}
