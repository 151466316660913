import {
  ChangeDetectorRef, Component, ContentChild, ElementRef, TemplateRef,
  ViewChild
} from "@angular/core";
import {
  UxInlineNgStyles,
  UxSortTypes,
  UxTable, UxTableColumn,
  UxTableRow
} from "../../../../ux-lib/components/table/table.model";
import {Router} from "@angular/router";
import {forkJoin, Subject, Subscription} from "rxjs";
import {ResultsService} from "../results.service";
import {TestResultEntity} from "../../../common/models/entity/test-result-entity.model";
import {ModelService} from "../../../common/services/model.service";
import {NotificationService} from "../../../common/services/notification.service";
import {AppConfigService} from "../../../common/services/app-config.service";
import {PagedComponent} from "../../../components/base/paged.component";
import {ApiPath} from "../../../common/services/api.path";
import {takeUntil, tap} from "rxjs/operators";
import {QueryParam} from "../../../common/models/query-param.type";
import {ConfirmPopupComponent} from "../../../components/confirm-popup/confirm-popup.component";
import {CountResult, DeleteOperationResult} from "../../../common/models/result.type";
import {DevicesService} from "../../devices/devices.service";
import {TestsService} from "../../tests/tests.service";
import {TestEntity} from "../../../common/models/entity/test-entity.model";
import {DeviceEntity} from "../../../common/models/entity/device-entity.model";
import {AudioService, DownloadFileResult} from "../../audio/audio.service";
import {AudioEntity} from "../../../common/models/entity/audio-entity.model";
import {UxTableComponent} from "../../../../ux-lib/components/table/table.component";

export interface ResultColumnSortEvent {
  columnIndex: number;
  columnName: string;
  sortOrder: UxSortTypes;
}

@Component({
  selector: "sq-results-full-list",
  templateUrl: "results-full-list.component.html",
  host: {"[class.sq-results-full-list]": "true"}
})
export class ResultsFullListComponent extends PagedComponent {
  private _results: TestResultEntity[] = [];
  private _devices: Map<number|string, DeviceEntity> = new Map<number|string, DeviceEntity>();
  private _tests: Map<number|string, TestEntity> = new Map<number|string, TestEntity>();
  _resultDeleteID: number|string;

  private resultsAdvancedTable: UxTableComponent;
  @ViewChild("resultsAdvancedTable", { static: false }) set content(content: UxTableComponent) {
    if (content) { // initially setter gets called with undefined
      this.resultsAdvancedTable = content;
      this.configureTestResultsTable();
    }
  }

  _tableDataModel: UxTable = {
    header: {
      rows: [
        {
          styleClass: "_header",
          columns: [
            {
              id: "Device",
              value: "Device",
              styleClass: "_header",
              contentModel: {
                "id": "name"
              }
            },
            {
              id: "Test",
              value: "Test",
              styleClass: "_header",
              contentModel: {
                "id": "name"
              }
            },
            //{
            //  id: "Target",
            //  value: "Target",
            //  styleClass: "_header",
            //  contentModel: {
            //    "id": "name"
            //  },
            //},
            {
              id: "Time",
              value: "Time",
              styleClass: "_header",
              contentModel: {
                "id": "name"
              },
            },
            {
              id: "Duration",
              value: "Duration",
              styleClass: "_header",
              contentModel: {
                "id": "name"
              }
            },
            {
              id: "AQUA",
              value: "AQUA",
              styleClass: "_header",
              contentModel: {
                "id": "name"
              }
            },
            {
              id: "PVQA",
              value: "PVQA",
              styleClass: "_header",
              contentModel: {
                "id": "name"
              }
            },
            {
              id: "MOS Network",
              value: "MOS Network",
              styleClass: "_header",
              contentModel: {
                "id": "name"
              }
            },
            {
              id: "Sevana R-factor",
              value: "R-factor",
              styleClass: "_header",
              contentModel: {
                "id": "name"
              }
            },
            {
              id: "Sevana Percentage",
              value: "Percentage",
              styleClass: "_header",
              contentModel: {
                "id": "name"
              }
            },
            {
              id: "functions",
              value: "",
              styleClass: "_header"
            }
          ]
        }
      ]
    },
    body: {rows: []}
  };

  private stopSubscription$ = new Subject<boolean>();
  private forkSubscription$: Subscription;
  private resultsCountSubscription$: Subscription;
  private resultsSubscription$: Subscription;
  private resultsDeleteSubscription$: Subscription;

  @ViewChild("tableEmptyContent", { static: true })
  tableEmptyContent: TemplateRef<any>;

  @ViewChild("testColumn", { static: true })
  testColumn: TemplateRef<any>;

  @ViewChild("timeColumn", { static: true })
  timeColumn: TemplateRef<any>;

  @ViewChild("durationColumn", { static: true })
  durationColumn: TemplateRef<any>;

  @ViewChild("functionsColumn", { static: true })
  functionsColumn: TemplateRef<any>;

  @ViewChild("resultDeleteConfirmPopup", { static: true })
  resultDeleteConfirmPopup: ConfirmPopupComponent<TestResultEntity>;

  private audioTag: HTMLMediaElement = null;
  private audioSupported: boolean = undefined;
  playAudio: number|string;

  constructor(private resultsService: ResultsService,
              private devicesService: DevicesService,
              private testsService: TestsService,
              private audioService: AudioService,
              private notificationService: NotificationService,
              private modelService: ModelService,
              private router: Router,
              private cdr: ChangeDetectorRef,
              protected configService: AppConfigService) {
    super(configService);
  }

  public initComponent() {
    super.initComponent();
    this._tableDataModel.emptyTableContent = this.tableEmptyContent;

    if (null === this.audioTag && this.audioSupported === undefined) {
      this.audioTag = document.createElement('audio');
      this.audioSupported = !!(this.audioTag.canPlayType);

      if (this.audioSupported) {
        document.getElementById("tableContainer").appendChild(this.audioTag);

        let self = this;
        this.audioTag.addEventListener('ended', (event) => {
          self.playAudio = undefined;
        });
      }
    }
  }

  private configureTestResultsTable() {
    let fixedWidth = this.calculateFixedWidth();

    this._tableDataModel.header.rows[0].columns.forEach((col: UxTableColumn) => {
      this.updateColumn(col, fixedWidth);
    });
    this._tableDataModel.body.rows.forEach((row: UxTableRow) => {
      row.columns.forEach((col: UxTableColumn) => {
        this.updateColumn(col, fixedWidth);
      });
    })

    if (this.resultsAdvancedTable) {
      this.resultsAdvancedTable.updateTableData();
    }
    this.cdr.detectChanges();
  }

  private updateColumn(col: UxTableColumn, fixedWidth: number) {
    if (col.id === "Device" || col.id === "Test" || col.id === "Time" || col.id === "Duration") {
      col.inlineStyles = {
        'flex': `0 1 calc((100% - ${fixedWidth}px)/4`,
        'min-width': `calc((100% - ${fixedWidth}px)/4`,
        'width': `calc((100% - ${fixedWidth}px)/4`
      }
    }
    if ( (col.id === "AQUA" && this.configService.report().mos_aqua === false) ||
        (col.id === "PVQA" && this.configService.report().mos_pvqa === false) ||
        (col.id === "MOS Network" && this.configService.report().mos_network === false) ||
        (col.id === "Sevana R-factor" && this.configService.report().r_factor === false) ||
        (col.id === "Sevana Percentage" && this.configService.report().percents_aqua === false)
    ) {
      col.inlineStyles = {
        'flex': `0 0 0`,
        'min-width': `0`,
        'width': `0`
      }
    }
  }

  private calculateFixedWidth(): number {
    let fixedWidth = 678;

    if (this.configService.report().mos_pvqa === false) {
      fixedWidth -= 110;
    }

    if (this.configService.report().mos_aqua === false) {
      fixedWidth -= 110;
    }

    if (this.configService.report().mos_network === false) {
      fixedWidth -= 110;
    }

    if (this.configService.report().r_factor === false) {
      fixedWidth -= 110;
    }

    if (this.configService.report().percents_aqua === false) {
      fixedWidth -= 110;
    }
    return fixedWidth;
  }


  public destroyComponent() {
    this.stopSubscription$.next(true);
    this.stopSubscription$.complete();
  }

  public loadPageData(currentPage: number, pageSize: number): void {
    this._tableDataModel.body.rows = [];
    this._pageLoadingStatus = 'loading';
    let queryParams = [
      {
        key: ApiPath.OFFSET,
        value: (currentPage-1) * pageSize
      },
      {
        key: ApiPath.LIMIT,
        value: pageSize
      }
    ];

    this.forkSubscription$ && this.forkSubscription$.unsubscribe();
    this.forkSubscription$ = forkJoin([
      this.resultsService.getEntitiesCount(),
      this.resultsService.getEntityList(queryParams),
      this.devicesService.getEntityList(),
      this.testsService.getEntityList()
    ])
      .pipe(
        takeUntil(this.stopSubscription$),
        tap((data: [CountResult, TestResultEntity[], DeviceEntity[], TestEntity[]]) => {
          this.pagingConfig.totalCount = data[0].count;
          if (this.pagingConfig.totalCount > 0) {
            this._results = data[1];
            this._devices = this.modelService.listToMap(data[2], "id");
            this._tests = this.modelService.listToMap(data[3], "id");

            let fixedWidth = this.calculateFixedWidth();
            this._tableDataModel.body.rows =
              this._results
                .map((result: TestResultEntity) => {
                  return this.getTableRow(result, fixedWidth);
                });

            this._pageLoadingStatus = 'loaded';

            // load devices and tests for loaded results
          }
          else {
            this._pageLoadingStatus = 'no_data';
            this._results = [];
          }
        })
      )
      .subscribe();

    if (this.resultsAdvancedTable) {
      this.resultsAdvancedTable.update();
    }
    this.cdr.detectChanges();
  }

  private loadResultsCount(queryParams: QueryParam[]): void {
    this._pageLoadingStatus = 'loading';

    this.resultsCountSubscription$ && this.resultsCountSubscription$.unsubscribe();
    this.resultsCountSubscription$ = this.resultsService
      .getEntitiesCount()
      .pipe(
        takeUntil(this.stopSubscription$),
        tap((value: CountResult) => {
          this.pagingConfig.totalCount = value.count;
          if (this.pagingConfig.totalCount > 0) {
            this._pageLoadingStatus = 'loading';
            this.loadResultsPage(queryParams);
          }
          else {
            this._pageLoadingStatus = 'no_data';
          }
        })
      )
      .subscribe();
  }

  private loadResultsPage(queryParams: QueryParam[]): void {
    this.resultsSubscription$ && this.resultsSubscription$.unsubscribe();
    this.resultsSubscription$ = this.resultsService
      .getEntityList(queryParams)
      .pipe(
        takeUntil(this.stopSubscription$),
        tap((values: TestResultEntity[]) => {
          if (values) {
            this._results = values;

            let fixedWidth = this.calculateFixedWidth();
            this._tableDataModel.body.rows =
              this._results
                .map((result: TestResultEntity) => {
                  return this.getTableRow(result, fixedWidth);
                });
            this._pageLoadingStatus = 'loaded';
          }
          else {
            this._results = [];
            this._pageLoadingStatus = 'no_data';
          }
        })
      )
      .subscribe();
  }

  _onDeleteIcon(result: TestResultEntity) {
    this._resultDeleteID = result.id;
    this.resultDeleteConfirmPopup.userData = result;
    this.resultDeleteConfirmPopup.show();
  }

  _onPlayAudio(result: TestResultEntity) {
    let audioID = result.audio_id;
    if (this.playAudio === audioID) { // playing this. Need to stop
      this.audioTag.pause();
      this.audioTag.currentTime = 0;
      this.playAudio = undefined;

    } else { // play new
      this.playAudio = audioID;

      if (result['audioBlob'] === undefined) {
        let queryParams = [
          {
            key: ApiPath.AUDIO_ID,
            value: audioID
          }
        ];

        result['playDownloadProgress'] = true;
        result['playDownloadSubscription$'] && result['playDownloadSubscription$'].unsubscribe();
        result['playDownloadSubscription$'] = forkJoin([
          this.audioService.listAudio(queryParams),
          this.audioService.downloadAudio(audioID)
        ])
            .pipe(
                takeUntil(this.stopSubscription$),
                tap((data: [AudioEntity[], DownloadFileResult]) => {
                  result['playDownloadProgress'] = false;

                  let audio: AudioEntity[] = data[0];
                  if (audio.length > 0) {
                    result['audioName'] = audio[0].name;
                    result['audioBlob'] = data[1].blob;
                    this._playData(result);
                  }
                })
            )
            .subscribe();
      }
      else {
        this._playData(result);
      }
    }
  }

  _playData(result: TestResultEntity) {
    if (result['audioBlob'] !== undefined) {
      let playUrl = window.URL.createObjectURL(result['audioBlob']);
      this.audioTag.src = playUrl;
      this.audioTag.play();
    }
  }

  _onDownloadAudio(result: TestResultEntity) {
    let audioID = result.audio_id;

    if (result['audioBlob'] === undefined) {
      let queryParams = [
        {
          key: ApiPath.AUDIO_ID,
          value: audioID
        }
      ];

      result['downloadSubscription$'] && result['downloadSubscription$'].unsubscribe();
      result['downloadProgress'] = true;
      result['downloadSubscription$'] = forkJoin([
        this.audioService.listAudio(queryParams),
        this.audioService.downloadAudio(audioID)
      ])
          .pipe(
              takeUntil(this.stopSubscription$),
              tap((data: [AudioEntity[], DownloadFileResult]) => {
                result['downloadProgress'] = false;

                let audio: AudioEntity[] = data[0];
                if (audio.length > 0) {
                  result['audioName'] = audio[0].name;
                  result['audioBlob'] = data[1].blob;
                  this._downloadData(result);
                }
              })
          )
          .subscribe();
    }
    else {
      this._downloadData(result);
    }
  }

  private _downloadData(result: TestResultEntity) {
    if (result['audioBlob'] !== undefined) {
      let audioName = result['audioName'];
      let downloadURL = window.URL.createObjectURL(result['audioBlob']);
      let link = document.createElement('a');
      link.href = downloadURL;
      link.download = audioName;
      link.click();
    }
  }

  _onChartIcon(result: TestResultEntity) {
    this.router.navigate(['/reports/view'], {
      queryParams: {
        probe: result.id
      }
    });
  }

  _onDeleteCancel(result: TestResultEntity) {
    this._resultDeleteID = undefined;
  }

  _onResultDelete(result: TestResultEntity) {
    let resultID = result.id;
    this.resultsDeleteSubscription$ && this.resultsDeleteSubscription$.unsubscribe();
    this.resultsDeleteSubscription$ = this.resultsService
      .deleteEntity(resultID)
      .pipe(
        takeUntil(this.stopSubscription$),
        tap((data: DeleteOperationResult) => {
          if (data.opResult) {
            let deletedIndex: number = this.modelService.findInArray(this._tableDataModel.body.rows, (item: UxTableRow) => {
              return (item.id === resultID);
            });
            if (deletedIndex >= 0) {
              this._tableDataModel.body.rows.splice(deletedIndex, 1);
            }
          }
          else {
            this.notificationService.pushNotification({
              type: "error",
              caption: "Deletion error",
              content: `Fail to delete test result ${resultID}`
            })
          }
        })
      )
      .subscribe();

    this._resultDeleteID = undefined;
  }

  private getTableRow(result: TestResultEntity, fixedWidth: number): UxTableRow {

    let inlineStyles: UxInlineNgStyles = {
      'flex': `0 1 calc((100% - ${fixedWidth}px)/4`,
      'min-width': `calc((100% - ${fixedWidth}px)/4`,
      'width': `calc((100% - ${fixedWidth}px)/4`
    };
    let inlineStylesHidden: UxInlineNgStyles = {
      'flex': `0 0 0`,
      'min-width': `0`,
      'width': `0`
    };

    let columns: UxTableColumn[]  = [
      {
        // "Device"
        value: this._devices.has(result.phone_id) ? this._devices.get(result.phone_id).instance : result.phone_name,
        inlineStyles: inlineStyles
      },
      {
        // "Test"
        type: 'content',
        value: this.testColumn,
        contentModel: {
          testName: this._tests.has(result.task_id) ? this._tests.get(result.task_id).name : result.task_name,
          tooltip: 'Target: ' + result.target
        },
        inlineStyles: inlineStyles
      },
      //{
      //    value: result.target
      //},
      {
        // "Time"
        type: 'content',
        value: this.timeColumn,
        contentModel: {
          endtime: result.endtime * 1000
        },
        inlineStyles: inlineStyles
      },
      {
        // "Duration"
        type: 'content',
        value: this.durationColumn,
        contentModel: {
          duration: result.duration
        },
        inlineStyles: inlineStyles
      },
      {
        // "AQUA"
        value: result.mos_aqua === 0 ? result.mos_aqua : result.mos_aqua.toFixed(3),
        inlineStyles: this.configService.report().mos_aqua === false ? inlineStylesHidden : undefined
      },
      {
        // "PVQA"
        value: result.mos_pvqa === 0 ? result.mos_pvqa : result.mos_pvqa.toFixed(3),
        inlineStyles: this.configService.report().mos_pvqa === false ? inlineStylesHidden : undefined
      },
      {
        // "MOS Network"
         value: result.mos_network === 0 ? result.mos_network : result.mos_network.toFixed(3),
        inlineStyles: this.configService.report().mos_network === false ? inlineStylesHidden : undefined
      },
      {
        // "R-Factor"
        value: result.r_factor,
        inlineStyles: this.configService.report().r_factor === false ? inlineStylesHidden : undefined
      },
      {
        // "Percentage Aqua"
        value: result.percents_aqua ? result.percents_aqua : 0,
        inlineStyles: this.configService.report().percents_aqua === false ? inlineStylesHidden : undefined
      },
      {
        // "functions"
        type: 'content',
        value: this.functionsColumn,
        contentModel: {
          result: result
        }
      }
    ];

    return {
      id: result.id,
      columns: columns
    };
  }
}
