import { Injectable, Inject } from '@angular/core';
import {WINDOW} from "./window.provider";

@Injectable()
export class WindowService {

  constructor(@Inject(WINDOW) private window: Window) {
  }

  getHostname() : string {
    return this.window.location.hostname;
  }

  getPort(): string {
    return this.window.location.port;
  }

  getFullHostname(): string {
    return `${this.window.location.protocol}//${this.window.location.hostname}:${this.window.location.port}`;
  }
}
