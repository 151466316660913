import {NgModule} from "@angular/core";
import {Routes, RouterModule} from "@angular/router";
import {AudioFullListComponent} from "./components/audio-full-list.component";
import {SevanaQualtestAuthGuard} from "../../sevana-qualtest-auth.guard";

const routes: Routes = [
  {
    path: 'audio',
    canActivate: [SevanaQualtestAuthGuard],
    canActivateChild: [SevanaQualtestAuthGuard],
    children: [
      {path: '', redirectTo: 'list', pathMatch: 'full'},
      {path: 'list', component: AudioFullListComponent}
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AudioRoutingModule {
}
