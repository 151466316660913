<sq-overlay-popup [title]="'Linked tests'"
                  [visible]="_visible"
                  [contentTemplate]="contentTemplate"
                  [controlsTemplate]="controlsTemplate"
                  [statusEnable]="false"
                  (onClose)="_onClose()"
>

</sq-overlay-popup>


<ng-template #contentTemplate>
  <sq-device-linked-test-list #linkedTestsList
                              [device]="_device">
  </sq-device-linked-test-list>
</ng-template>

<ng-template #controlsTemplate>
  <ux-button (tap)="_onLinkNew()"
             [text]="'Link new...'"
             [icon]="'add-link'"
  >
  </ux-button>
  <ux-button (tap)="_onClose()"
             [text]="'Close'"
             [kind]="'primary'"
  >
  </ux-button>
</ng-template>

<sq-device-link-new-test-popup #linkNewTestsPopup
                               (onClose)="_onLinkNewClosed()"
>
</sq-device-link-new-test-popup>
