import { APP_INITIALIZER, NgModule, Injectable } from "@angular/core";
import {
  BrowserModule,
  EVENT_MANAGER_PLUGINS,
  HAMMER_GESTURE_CONFIG,
  HammerGestureConfig
} from "@angular/platform-browser";
import { SevanaQualtestRoutingModule } from "./sevana-qualtest-routing.module";
import { SevanaQualtestComponent } from "./sevana-qualtest.component";
import {UxHeaderModule} from "../ux-lib/components/header/header.module";
import {SevanaQualtestService} from "./sevana-qualtest.service";
import {DevicesModule} from "./modules/devices/devices.module";
import {UxRouteTabPanelModule} from "../ux-lib/components/route-tab-panel/route-tab-panel.module";
import {ResultsModule} from "./modules/results/results.module";
import {TestsModule} from "./modules/tests/tests.module";
import {HttpClientModule} from "@angular/common/http";
import {UxHammerPluginPatch} from "../ux-lib/services/hammer-plugin-a6.patch";
import {FormsModule} from "@angular/forms";
import {UxNotificationModule} from "../ux-lib/components/notification/notification.module";
import {NotificationService} from "./common/services/notification.service";
import {ErrorHandlerService} from "./common/services/error-handler.service";
import {ModelService} from "./common/services/model.service";
import {UxEditableModule} from "../ux-lib/components/editable/editable.module";
import {AppConfigService, appConfigFactory} from "./common/services/app-config.service";
import {WINDOW_PROVIDERS} from "./common/services/window.provider";
import {WindowService} from "./common/services/window.service";
import {ReportsModule} from "./modules/reports/reports.module";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {SevanaQualtestAuthGuard} from "./sevana-qualtest-auth.guard";
import {LoginModule} from "./modules/login/login.module";
import {LoginService} from "./modules/login/login.service";
import {AudioModule} from "./modules/audio/audio.module";

@Injectable()
export class UxHammerConfig extends HammerGestureConfig {
  overrides = <any>{
    "swipe": {velocity: 0.4, threshold: 20} // override default settings
  };
}

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    SevanaQualtestRoutingModule,
    UxHeaderModule,
    UxRouteTabPanelModule,
    UxNotificationModule,
    LoginModule,
    DevicesModule,
    ResultsModule,
    TestsModule,
    AudioModule,
    ReportsModule
  ],
  declarations: [
    SevanaQualtestComponent
  ],
  providers: [
    AppConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: appConfigFactory,
      deps: [AppConfigService],
      multi: true
    },
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: UxHammerConfig
    },

    /**
     * Hot fix of built-in HammerGesturesPlugin to avoid memory leaks,
     * Added proper events queue and event.stopPropagation() support
     * https://github.com/angular/angular/issues/22155
     * https://github.com/angular/angular/pull/22156
     * @todo update after fix in angular
     */
    {
      provide: EVENT_MANAGER_PLUGINS,
      useClass: UxHammerPluginPatch,
      multi: true
    },
    SevanaQualtestService,
    SevanaQualtestAuthGuard,
    NotificationService,
    ErrorHandlerService,
    ModelService,
    LoginService,
    WINDOW_PROVIDERS,
    WindowService
  ],
  bootstrap: [
    SevanaQualtestComponent
  ]
})
export class SevanaQualtestModule { }
