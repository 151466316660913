import {AfterViewInit, Component, ElementRef, Input, NgZone} from "@angular/core";
import * as Highcharts from 'highcharts';
const HighchartsMore = require("highcharts/highcharts-more");
const HighchartsHeatmap = require("highcharts/modules/heatmap");
HighchartsMore(Highcharts);
HighchartsHeatmap(Highcharts);

@Component({
  selector: 'ux-highchart',
  templateUrl: 'highchart.component.html',
  host: {"[class.ux-highchart]": "true"}
})
export class UxChartComponent implements AfterViewInit {
  private viewInited: boolean = false;
  private _model: Highcharts.Options;
  private el: HTMLElement;

  constructor(private elRef: ElementRef,
              private zone: NgZone) {
  }

  @Input()
  public get model(): Highcharts.Options {
    return this._model;
  }

  public set model(value: Highcharts.Options) {
    this._model = value;
    this.updateGraph(this._model);
  }

  ngAfterViewInit(): void {
    let self = this;

    self.viewInited = true;
    self.el = self.elRef.nativeElement;
    self.updateGraph(self._model);
  }

  private updateGraph(value: Highcharts.Options): void {
    let self = this;

    if (value && self.viewInited) {
      self.zone.runOutsideAngular(() => {
        Highcharts.chart(self.el, value as any);
      });
    }
  }
}
