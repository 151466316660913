import {NgModule} from "@angular/core";
import {CommonModule, DatePipe} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {ReportsRoutingModule} from "./reports-routing.module";
import {ReportsService} from "./reports.service";
import {UxSwitchSelectorFieldModule} from "../../../ux-lib/components/fields/switch-selector/switch-selector-field.module";
import {UxRadioGroupFieldModule} from "../../../ux-lib/components/fields/radio-group/radio-group-field.module";
import {UxRadioFieldModule} from "../../../ux-lib/components/fields/radio-group/radio/radio-field.module";
import {UxLabelModule} from "../../../ux-lib/components/label/label.module";
import {UxDateFieldModule} from "../../../ux-lib/components/fields/date/date-field.module";
import {UxRangeDateFieldModule} from "../../../ux-lib/components/fields/date/range/range-date-field.module";
import {UxMultipleFieldModule} from "../../../ux-lib/components/fields/multiple/multiple-field.module";
import {UxButtonModule} from "../../../ux-lib/components/button/button.module";
import {AppConfigService} from "../../common/services/app-config.service";
import {HighchartService} from "./charts-services/highchart.service";
import {UxChartModule} from "../../../ux-lib/components/charts/highchart/highchart.module";
import {UxOverlayModule} from "../../../ux-lib/components/overlay/overlay.module";
import {UxRoundDotsLoaderModule} from "../../../ux-lib/components/loader/round-dots-loader/round-dots-loader.module";
import {UxAccordionModule} from "../../../ux-lib/components/accordion/accordion.module";
import {UxDropdownFieldModule} from "../../../ux-lib/components/fields/dropdown/dropdown-field.module";
import {UxTabPanelModule} from "../../../ux-lib/components/tab-panel/tab-panel.module";
import {DevicesService} from "../devices/devices.service";
import {TestsService} from "../tests/tests.service";
import {UxCardTreeModule} from "../../../ux-lib/components/card-tree/card-tree.module";
import {ResultsService} from "../results/results.service";
import {UxLinearDotsLoaderModule} from "../../../ux-lib/components/loader/linear-dots-loader/linear-dots-loader.module";
import {ModelService} from "../../common/services/model.service";
import {SatisfactionChartComponent} from "./components/satisfaction-chart.component";
import {UxTableModule} from "../../../ux-lib/components/table/table.module";
import {ReportWizardComponent} from "./components/report-wizard.component";
import {UxWizardNavigationModule} from "../../../ux-lib/components/wizard-navigation/wizard-navigation.module";
import {UxNavigationButtonModule} from "../../../ux-lib/components/button/navigation-button/navigation-button.module";
import {DevicesModule} from "../devices/devices.module";
import {CardListModule} from "../../components/card-list/card-list.module";
import {TestsModule} from "../tests/tests.module";
import {UxTooltipModule} from "../../../ux-lib/components/tooltip/tooltip.module";
import {ReportViewComponent} from "./components/report-view.component";
import {SummaryChartComponent} from "./components/summary-chart.component";
import {UxPagingModule} from "../../../ux-lib/components/paging/paging.module";
import {SharedModule} from "../../components/shared.module";
import {PipesModule} from "../../common/pipes/pipes.module";
import {TestcaseChartComponent} from "./components/testcase-chart.component";
import {SevanaQualtestAuthGuard} from "../../sevana-qualtest-auth.guard";

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    ReportsRoutingModule,
    UxSwitchSelectorFieldModule,
    UxRadioGroupFieldModule,
    UxRadioFieldModule,
    UxLabelModule,
    UxDateFieldModule,
    UxRangeDateFieldModule,
    UxMultipleFieldModule,
    UxButtonModule,
    UxNavigationButtonModule,
    UxChartModule,
    UxOverlayModule,
    UxRoundDotsLoaderModule,
    UxLinearDotsLoaderModule,
    UxAccordionModule,
    UxDropdownFieldModule,
    UxTabPanelModule,
    UxCardTreeModule,
    UxTableModule,
    UxPagingModule,
    UxTooltipModule,
    UxWizardNavigationModule,
    CardListModule,
    DevicesModule,
    TestsModule,
    PipesModule
  ],
  declarations: [
    SatisfactionChartComponent,
    SummaryChartComponent,
    TestcaseChartComponent,
    ReportWizardComponent,
    ReportViewComponent
  ],
  entryComponents: [
    ReportWizardComponent,
    ReportViewComponent
  ],
  exports: [
  ],
  providers: [
    SevanaQualtestAuthGuard,
    ReportsService,
    ResultsService,
    DevicesService,
    TestsService,
    ModelService,
    AppConfigService,
    DatePipe,
    HighchartService
  ]
})
export class ReportsModule {

}
