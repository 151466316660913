import {Injectable} from "@angular/core";

@Injectable()
export class CssService {

  public getLevel(value: number): string {
    if (value === undefined) {
      return "_level_u";
    } else if (value < 2.6) {
      return "_level_0";
    } else if (value < 3.1) {
      return "_level_1";
    } else if (value < 3.6) {
      return "_level_2";
    } else if (value < 4.0) {
      return "_level_3";
    } else if (value < 4.3) {
      return "_level_4";
    } else {
      return "_level_5";
    }
  }
}
