import {Component, Input, HostBinding} from "@angular/core";

@Component({
    selector: "ux-toolbar",
    templateUrl: "toolbar.component.html",
    host: { "[class.ux-toolbar]": "true" }
})
export class UxToolbarComponent {

    @HostBinding("class._mobile")
    @Input()
    public mobile: boolean = false;

}
