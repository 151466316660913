import {ApiService} from "./api.service";
import {Injectable, Injector} from "@angular/core";
import {Entity} from "../models/entity/entity.model";
import {Observable, Subscriber} from "rxjs";
import {NotificationService} from "./notification.service";
import {ModelService} from "./model.service";
import {HttpResponse} from "@angular/common/http";
import {QueryParam} from "../models/query-param.type";
import {ApiPath} from "./api.path";
import {CountResult, DeleteOperationResult, PostOperationResult, PutOperationResult} from "../models/result.type";

@Injectable()
export abstract class EntityService<E extends Entity> extends ApiService {
  protected abstract readonly entityID: string;
  protected abstract readonly entityUrl: string[];
  protected abstract readonly entityListUrl: string[];
  protected abstract readonly entityCountUrl: string[];
  protected abstract readonly entityCountPerParentUrl: string[];
  protected abstract readonly entityCreateUrl: string[];
  protected abstract readonly entityUpdateUrl: string[];
  protected abstract readonly entityDeleteUrl: string[];

  protected notificationService: NotificationService;
  protected modelService: ModelService;

  constructor(protected injector: Injector) {
    super(injector);

    this.notificationService = this.injector.get(NotificationService);
    this.modelService = this.injector.get(ModelService);
  }

  protected findInArray(items: E[], id: number|string): number {
    return this.modelService.findInArray(items, (el: E) => {
      return el.getId() === id;
    });
  }

  protected abstract convertToObjects(entities: E[]): E[];

  getEntitiesCount(queryParams?: QueryParam[]): Observable<CountResult> {
    let o = Observable.create((subscriber: Subscriber<CountResult>) => {
      let get = this.get(this.entityCountUrl, {
        queryParams: queryParams
      }).subscribe(async (result: CountResult) => {
        subscriber.next(result);
        subscriber.complete();
      });
      return () => {
        get && get.unsubscribe();
      };
    });
    return o;
  }

  getEntitiesCountPerParent(queryParams?: QueryParam[]): Observable<CountResult[]> {
    let o = Observable.create((subscriber: Subscriber<CountResult[]>) => {
      let get = this.get(this.entityCountPerParentUrl, {
        queryParams: queryParams
      }).subscribe(async (result: CountResult[]) => {
        subscriber.next(result);
        subscriber.complete();
      });
      return () => {
        get && get.unsubscribe();
      };
    });
    return o;
  }

  getEntityList(queryParams?: QueryParam[]): Observable<E[]> {
    let o = Observable.create((subscriber: Subscriber<E[]>) => {
      let get = this.get_collection(this.entityListUrl, {
        queryParams: queryParams
      }).subscribe(async (items: E[]) => {
        let parent_id = undefined;
        if (queryParams) {
          for (let param of queryParams) {
            if (param.key === ApiPath.PARENT_ID) {
              parent_id = param.value;
              break;
            }
          }
        }
        let filteredItems: E[] = (parent_id === undefined ? items : items.filter((item: E) => {
          return item["parent_id"] === parent_id;
        }));
        filteredItems = this.convertToObjects(filteredItems);

        subscriber.next(filteredItems);
        subscriber.complete();
      });
      return () => {
        get && get.unsubscribe();
      };
    });
    return o;
  }

  getEntityById(id: string, queryParams?: QueryParam[]): Observable<E> {
    //noinspection UnnecessaryLocalVariableJS
    let o = Observable.create((subscriber: Subscriber<E>) => {
      queryParams = queryParams || [];
      queryParams.push({
        key: this.entityID,
        value: id
      })

      let get = this.get(this.entityUrl, {
        queryParams: queryParams
      }).subscribe(async (item: E) => {
        let passedItem: E;
        if (item instanceof Array) {
          let itemToAny: any = item;
          let elements: E[] = itemToAny;
          let index = this.findInArray(elements, id);
          if (index >= 0) {
            passedItem = elements[index];
          }
        }
        else {
          passedItem = item;
        }

        let converted: E[] = this.convertToObjects([passedItem]);

        subscriber.next(converted[0]);
        subscriber.complete();
      });
      return () => {
        get && get.unsubscribe();
      };
    });
    return o;
  }

  createEntity(entity: E): Observable<PostOperationResult<E>> {
    let o = Observable.create((subscriber: Subscriber<PostOperationResult<E>>) => {
      let post = this.post(this.entityCreateUrl, {
        body: JSON.stringify(entity),
        dataExtracter: (data) => this.operationResultExtractData(data, entity)
      }).subscribe(async (res: any) => {

        let result: PostOperationResult<E> = res;
        if (result.opResult) {
          result.opEntity.setId(result.opEntityID);
        }

        subscriber.next(result);
        subscriber.complete();
      });

      return () => {
        post && post.unsubscribe();
      };
    });
    return o;
  }

  updateEntityById(entityID: number|string, entity: E): Observable<PutOperationResult<E>> {
    let queryParams = [
      {
        key: this.entityID,
        value: entityID
      }
    ];

    let o = Observable.create((subscriber: Subscriber<PutOperationResult<E>>) => {
      let put = this.put(this.entityUpdateUrl, {
        queryParams: queryParams,
        body: JSON.stringify(entity),
        dataExtracter: (data) => this.operationResultExtractData(data, entity)
      }).subscribe(async (res: any) => {

        let result: PutOperationResult<E> = res;

        subscriber.next(result);
        subscriber.complete();
      });

      return () => {
        put && put.unsubscribe();
      };
    });
    return o;
  }

  deleteEntity(entityID: number|string): Observable<DeleteOperationResult> {
    let queryParams = [
      {
        key: this.entityID,
        value: entityID
      }
    ];

    let o = Observable.create((subscriber: Subscriber<DeleteOperationResult>) => {
      let del = this.delete(this.entityDeleteUrl, {
        queryParams: queryParams,
        dataExtracter: (data) => this.operationDeleteResultExtractData(data, entityID)
      }).subscribe(async (res: any) => {

        let result: DeleteOperationResult = res;

        subscriber.next(result);
        subscriber.complete();
      });

      return () => {
        del && del.unsubscribe();
      };
    });
    return o;
  }
  protected operationResultExtractData(res: HttpResponse<Object>, data: E): Object {
    let result = {
      opEntity: data
    };

    if (res.body) {
      result["opResult"] = (res.status == 200);
      if (!isNaN(Number(res.body))) {
        result["opEntityID"] = parseInt(res.body.toString());
      }
    }

    Object.defineProperty(result, "__initialResponse", {value: res});
    return result;
  }

  protected operationDeleteResultExtractData(res: HttpResponse<Object>, itemID: number|string): Object {
    let result: DeleteOperationResult = {
      opEntityID: itemID,
      opResult: (res.status == 200)
    };

    Object.defineProperty(result, "__initialResponse", {value: res});
    return result;
  }
}
