import {NgModule} from "@angular/core";
import {SharedModule} from "../../shared/shared.module";
import {UxHeaderComponent} from "./header.component";
import {UxHeaderGroupComponent} from "./header-group/header-group.component";
import {UxRouterLinkComponent} from "./router-link/router-link.component";
import {RouterModule} from "@angular/router";
import {UxHeaderButtonComponent} from "./header-button/header-button.component";
import {UxRouteTabPanelModule} from "../route-tab-panel/route-tab-panel.module";

const COMPONENTS = [
    UxHeaderComponent,
    UxHeaderGroupComponent,
    UxRouterLinkComponent,
    UxHeaderButtonComponent
];

@NgModule({
    imports: [SharedModule, RouterModule, UxRouteTabPanelModule],
    exports: COMPONENTS,
    declarations: COMPONENTS
})
export class UxHeaderModule {
}
