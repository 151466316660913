/**
 * @author maku0817
 * @since 01.01.1970 00:00
 */
import {Component, ElementRef, EventEmitter, Input, Output, ViewChild} from "@angular/core";
import {UxDomHelper} from "../../shared/dom/dom-helper";
import {UxPropertyHandler} from "../../common/decorator/ux-property-handler";
import {UxPropertyConverter} from "../../common/decorator/ux-property-converter";

export interface FileItem extends File {
    isUploaded: boolean;
    uploadProgress: number;
    uploadError?: string;
    recognizedType?: string;
    isVirtual?: boolean;
}

export type UxAttachmentErrorType = "type" | "size" | "amount";

export interface UxAttachmentError {
    type: UxAttachmentErrorType;
    files: File[];
}

@Component({
    selector: "ux-attachment",
    templateUrl: "./attachment.component.html",
    host: {"[class.ux-attachment]": "true"}
})
export class UxAttachmentComponent {
    @UxPropertyHandler({
        beforeChange: beforeFilesChange
    })
    @Input()
    public files: FileItem[] = [];

    @UxPropertyConverter("boolean", false)
    @Input()
    public auto: boolean;

    @UxPropertyConverter("boolean", true)
    @Input()
    public multiple: boolean;

    @UxPropertyConverter("number", null)
    @Input()
    public maxFileSize: number;

    @Input()
    public accept: string = undefined;

    @Input()
    public extensionsMappings: {[key: string]: string} = {
        ".7z": "application/x-7z-compressed",
        ".AAC": "audio/aac",
        ".apk": "application/vnd.android.package-archive",
        ".avi": "video/x-msvideo",
        ".bmp": "image/bmp",
        ".cfg": "text/plain",
        ".config": "application/xml",
        ".css": "text/css",
        ".csv": "text/csv",
        ".doc": "application/msword",
        ".docm": "application/vnd.ms-word.document.macroEnabled.12",
        ".docx": "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        ".exe": "application/octet-stream",
        ".flac": "audio/flac",
        ".gif": "image/gif",
        ".gz": "application/x-gzip",
        ".html": "text/html",
        ".jar": "application/java-archive",
        ".java": "application/octet-stream",
        ".jpeg": "image/jpeg",
        ".jpg": "image/jpeg",
        ".js": "application/javascript",
        ".json": "application/json",
        ".mp3": "audio/mpeg",
        ".mp4": "video/mp4",
        ".pdf": "application/pdf",
        ".png": "image/png",
        ".ppt": "application/vnd.ms-powerpoint",
        ".pptm": "application/vnd.ms-powerpoint.presentation.macroEnabled.12",
        ".pptx": "application/vnd.openxmlformats-officedocument.presentationml.presentation",
        ".rar": "application/x-rar-compressed",
        ".svg": "image/svg+xml",
        ".tar": "application/x-tar",
        ".tgz": "application/x-compressed",
        ".txt": "text/plain",
        ".wav": "audio/wav",
        ".xls": "application/vnd.ms-excel",
        ".xlsx": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        ".xml": "text/xml",
        ".zip": "application/zip"
    };

    @UxPropertyConverter("boolean", false)
    @Input()
    public disabled: boolean;

    @UxPropertyConverter("boolean", false)
    @Input()
    public hideList: boolean;

    @UxPropertyHandler({
        afterChange: afterMaxVisibleFilesInListChange
    })
    @UxPropertyConverter("number", null)
    @Input()
    public maxVisibleFilesInList: number;

    @Input()
    public invalidFileSizeErrorMessage: string = "{0}: invalid file size, maximum allowed size is {1}";

    @Input()
    public invalidFileTypeErrorMessage: string = "{0}: invalid file type, allowed types are: {1}";

    @Input()
    public invalidFilesAmountErrorMessage: string = "Multiple file selection is not allowed";

    @Input()
    public dropAreaTooltip: string = "Drop files or click here to select files for uploading";

    @Input()
    public uploadButtonText: string = "Upload files";

    @Input()
    public clearButtonText: string = "Remove all files";

    @Input()
    public errorText: string = "Error";

    @Input()
    public mobile: boolean = false;


    @Output()
    public onUpload: EventEmitter<FileItem[]> = new EventEmitter<FileItem[]>();

    @Output()
    public onSelect: EventEmitter<FileItem[]> = new EventEmitter<FileItem[]>();

    @Output()
    public onClear: EventEmitter<UxAttachmentComponent> = new EventEmitter<this>();

    @Output()
    public onRemove: EventEmitter<FileItem> = new EventEmitter<FileItem>();

    @Output()
    public onError: EventEmitter<UxAttachmentError[]> = new EventEmitter<UxAttachmentError[]>();


    @ViewChild("fileInput", { static: true })
    private fileInput: ElementRef;

    @UxPropertyHandler({
        afterChange: afterFileListElemChange
    })
    @ViewChild("fileList")
    private fileList: ElementRef;

    @ViewChild("fileListItems")
    private fileListItems: ElementRef;

    @ViewChild("fileListItem")
    private fileListItem: ElementRef;

    public resetFilesList() {
      this._removeAllFiles();
    }

    /** @internal */
    public _errors: string[] = [];

    /** @internal */
    public _hasFiles(): boolean {
        return this.files && this.files.length > 0;
    }

    /** @internal */
    public _hasUnuploadedFiles(): boolean {
        let isThereUnuploadedFiles = false;

        this.files.forEach((file) => {
            isThereUnuploadedFiles = isThereUnuploadedFiles || file.uploadProgress !== 100;
        });

        return isThereUnuploadedFiles;
    }

    /** @internal */
    public _hasUploadingFiles(): boolean {
        let thereIsUploadingFiles: boolean = false;

        this.files.forEach((file) => {
            thereIsUploadingFiles = thereIsUploadingFiles ||
                                    (file.uploadProgress > 0 &&
                                     file.uploadProgress !== 100 &&
                                     file.isUploaded !== true);
        });

        return thereIsUploadingFiles;
    }

    /** @internal */
    public _hasErrors(): boolean {
        return this._errors && this._errors.length > 0;
    }

    /** @internal */
    public _clearErrorsList(): void {
        this._errors = [];
    }

    private limitFileListElemHeight(): void {
        let fileList = this.fileList;

        if (!fileList || (this.maxVisibleFilesInList == null) || !this.fileListItem || !this.fileListItems) {
            return;
        }

        let fileItemElement = this.fileListItems.nativeElement.children[0],
            fileItemElementHeight = getComputedStyle(fileItemElement).height,
            ulElement = this.fileListItems.nativeElement;

        let height = Number(fileItemElementHeight.substring(0, fileItemElementHeight.indexOf("px")));

        ulElement.style.maxHeight = this.maxVisibleFilesInList * height + "px";
    }

    /** @internal */
    public _formatSize(bytes: number): string {
        if (bytes == 0) {
            return "0 B";
        }

        let k = 1000,
            dm = 3,
            sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
            i = Math.floor(Math.log(bytes) / Math.log(k));

        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    }

    private getTypeClass(fileType: string): string {
        return fileType.substring(0, fileType.indexOf("/"));
    }

    private isWildcard(fileType: string): boolean {
        return fileType.indexOf("*") !== -1;
    }

    private getFileExtension(file: File): string {
        return "." + file.name.split(".").pop();
    }

    private isFileTypeValid(file: FileItem): boolean {
        let self = this;

        if (!self.accept) {
            return true;
        }

        let acceptableTypes = self.accept.split(",").map(type => type.trim());
        let fileType = file.type || file.recognizedType;

        for (let acceptableType of acceptableTypes) {
            let acceptable = self.isWildcard(acceptableType) ? self.getTypeClass(fileType) === self.getTypeClass(acceptableType)
                                                             : fileType === acceptableType || self.getFileExtension(file) === acceptableType;

            if (acceptable) {
                return true;
            }
        }

        return false;
    }

    private isFileSizeValid(file: File): boolean {
        if (!this.maxFileSize) {
            return true;
        }

        return file.size < this.maxFileSize;
    }

    /** @internal */
    public _removeFile(index: number): void {
        let removedFile: FileItem = this.files.splice(index, 1)[0];
        this.onRemove.emit(removedFile);
    }

    /** @internal */
    public _removeAllFiles(): void {
        let self = this;

        let removedFiles: FileItem[] = self.files.splice(0, self.files.length);
        self.onClear.emit(self);
    }

    /** @internal */
    public _uploadAllFiles(): void {
        if (this._hasUnuploadedFiles()) {
            this.onUpload.emit(this.files);
        }
    }

    /** @internal */
    public _onDropAreaClick(event: MouseEvent): void {
        this.fileInput.nativeElement.click();
    }

    /** @internal */
    public _onDragEnter(event: DragEvent): void {
        UxDomHelper.addClass(<HTMLElement>event.currentTarget, "_dragover");
    }

    /** @internal */
    public _onDragLeave(event: DragEvent): void {
        UxDomHelper.removeClass(<HTMLElement>event.currentTarget, "_dragover");
    }

    /** @internal */
    public _onDrop(event: DragEvent) {
        UxDomHelper.removeClass(<HTMLElement>event.currentTarget, "_dragover");
        this._onFileSelect(event);
    }

    /** @internal */
    public _onFileSelect(event: any): void {
        let self = this;

        self._clearErrorsList();

        let amountErrors: UxAttachmentError = {
            type: "amount",
            files: []
        };
        let sizeErrors: UxAttachmentError = {
            type: "size",
            files: []
        };
        let typeErrors: UxAttachmentError = {
            type: "type",
            files: []
        };

        let files = event.dataTransfer ? event.dataTransfer.files : event.target.files;
        let validFiles: FileItem[] = [];

        for (let i = 0; i < files.length; i++) {
            let file = files[i];
            let isFileValid: boolean = true;

            file.recognizedType = file.type || this.extensionsMappings[self.getFileExtension(file)] || "";

            if (!self.isFileSizeValid(file)) {
                sizeErrors.files.push(file);
                self._errors.push(
                    self.invalidFileSizeErrorMessage
                        .replace("{0}", file.name)
                        .replace("{1}", self._formatSize(self.maxFileSize))
                );
                isFileValid = false;
            }

            if (!self.isFileTypeValid(file)) {
                typeErrors.files.push(file);
                self._errors.push(
                    self.invalidFileTypeErrorMessage
                        .replace("{0}", file.name)
                        .replace("{1}", self.accept)
                );
                isFileValid = false;
            }

            if (isFileValid) {
                file.isUploaded = false;
                file.uploadProgress = null;
                file.uploadError = undefined;
                validFiles.push(files[i]);
            }
        }

        // In case if user tries to load more than one file when #multiple is false,
        // we won't show size and type errors, and only will show amount error.
        // However in onError event we should include full validation info.
        if (!self.multiple && files.length > 1) {
            validFiles = [];
            self._clearErrorsList();
            self._errors.push(this.invalidFilesAmountErrorMessage);
            amountErrors.files = amountErrors.files.concat(Array.from(files));
        }

        if (!self.multiple && validFiles.length === 1 && self.files.length > 0) {
            self.files = [];
        }

        if (validFiles.length) {
            validFiles.forEach((file) => {
                self.files.push(file);
            });

            self.onSelect.emit(self.files);

            if (self.auto) {
                self._uploadAllFiles();
            }
        }


        let errors: UxAttachmentError[] = [];

        if (amountErrors.files.length) {
            errors.push(amountErrors);
        }
        if (sizeErrors.files.length) {
            errors.push(sizeErrors);
        }
        if (typeErrors.files.length) {
            errors.push(typeErrors);
        }

        if (errors.length) {
            self.onError.emit(errors);
        }


        if (event.target.files) {
            event.target.value = "";
        }
    }
}

/* Helpers */
export function beforeFilesChange(newValue: FileItem[]) {
    if (!this.multiple && newValue.length > 1) {
        newValue = [newValue[0]];
        console.warn("Multiple file input is not allowed in single file mode!");
    }
}

export function afterFileListElemChange() {
    this.limitFileListElemHeight();
}

export function afterMaxVisibleFilesInListChange() {
    this.limitFileListElemHeight();
}
