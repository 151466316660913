import {Component, OnDestroy, OnInit} from "@angular/core";
import {UxHeaderLink} from "../ux-lib/components/header/header.component";
import {NotificationItem} from "./common/models/notification-item";
import {NotificationService} from "./common/services/notification.service";
import {Subject, Subscription} from "rxjs";
import {takeUntil, tap} from "rxjs/operators";
import {Event, NavigationEnd, NavigationStart, Router} from "@angular/router";
import {LoginService} from "./modules/login/login.service";

@Component({
  selector: "sevana-qualtest",
  templateUrl: "./sevana-qualtest.component.html",
  host: {"[class.sevana-qualtest]": "true"}
})
export class SevanaQualtestComponent implements OnInit, OnDestroy {
  _menuItems: UxHeaderLink[] = [
    {
      id: "devices",
      title: "Devices",
      url: "/devices"
    },
    {
      id: "tests",
      title: "Tests",
      url: "/tests"
    },
    {
      id: "audio",
      title: "Audio files",
      url: "/audio"
    },
    {
      id: "results",
      title: "Results",
      url: "/results"
    },
    {
      id: "reports",
      title: "Reports",
      url: "/reports"
    }
  ];

  _notification: NotificationItem;
  _authorized: boolean = false;
  _username: string;

  private stopSubscription$ = new Subject<boolean>();
  private notificationsSubscription$: Subscription;
  private routerSubscription$: Subscription;
  private isAuthenticatedSubscription$: Subscription;

  constructor(public notificationService: NotificationService,
              private loginService: LoginService,
              private router: Router) {
  }

  ngOnInit(): void {
    this.initNotifications();
    this.initAuthSubscription();
  }

  ngOnDestroy(): void {
    this.stopSubscription$.next(true);
    this.stopSubscription$.complete();
  }

  private initNotifications() {
    this.notificationsSubscription$ = this.notificationService
      .getUpdates()
      .pipe(
        takeUntil(this.stopSubscription$),
        tap(() => {
          this._getLastNotification();
        })
      )
      .subscribe();
  }

  private initAuthSubscription() {
    this.routerSubscription$ = this.router.events
      .pipe(
        takeUntil(this.stopSubscription$),
        tap((event: Event) => {
          if (event instanceof NavigationEnd) {
            this.isAuthenticatedSubscription$ && this.isAuthenticatedSubscription$.unsubscribe();
            this.isAuthenticatedSubscription$ = this.loginService
              .isAuthenticated$()
              .pipe(
                takeUntil(this.stopSubscription$),
                tap((isAuthorized: boolean) => {
                  this._authorized = isAuthorized;
                  if (this._authorized) {
                    this._username = this.loginService.getToken().user;
                  }
                })
              )
              .subscribe();
          }
        })
      )
      .subscribe();
  }

  _logout() {
    this.loginService.logout();
    this.router.navigate(["/login"]);
  }

  _getLastNotification() {
    let notification = this.notificationService.getNotification();
    if (notification) {
      this._notification = notification;
    }
  }

  _onNotificationClose() {
    this.notificationService.popNotification();
    this._getLastNotification();
  }
}

