import {Component, ContentChild, ElementRef, EventEmitter, Input, Output, TemplateRef} from "@angular/core";
import {UxAccordionItemHeaderComponent} from "./accordion-item-header.component";
import {UxAccordionToggleEvent} from "../accordion.component";
import {UxPropertyHandler} from "../../../common/decorator/ux-property-handler";
import {UxPropertyConverter} from "../../../common/decorator/ux-property-converter";

@Component({
    selector: "ux-accordion-item",
    templateUrl: "accordion-item.component.html",
    host: {"[class.ux-accordion-item]": "true"}
})
export class UxAccordionItemComponent {

    @Input()
    public caption: string;

    @Input()
    public template: TemplateRef<any>;

    @Input()
    public headerTemplate: TemplateRef<any>;

    @Input()
    public customData: any;

    @UxPropertyHandler({
        afterChange: afterChangeOpened
    })
    @UxPropertyConverter("boolean", false)
    @Input()
    public opened: boolean;

    @UxPropertyConverter("boolean", false)
    @Input()
    public disabled: boolean;

    @Output()
    public onToggle: EventEmitter<UxAccordionToggleEvent> = new EventEmitter<UxAccordionToggleEvent>();

    /** @internal */
    public _initialized: boolean;

    @ContentChild('customIcon', /* TODO: add static flag */ {static: true})
    private set customIcon(iconElement: ElementRef) {
        this._customIcon = !!iconElement;
    }

    /** @internal */
    public _customIcon: boolean = false;

    /* deprecated use instead headerTemplate. todo remove after 1.0.38 version */
    @ContentChild(UxAccordionItemHeaderComponent, /* TODO: add static flag */ {static: true})
    /** @internal */
    public _customHeader: UxAccordionItemHeaderComponent;
    /*** End. remove**/


    /** @internal */
    public _toggleOpened: boolean;

    /** @internal */
    public _toggle(event: Event): void {
        let self = this;
        if (!self.disabled) {
            if (!self._initialized) {
                self._initialized = true;
            }
            self.onToggle.emit({
                originalEvent: event,
                item: self,
                opened: !self.opened,
                index: null,
                original: true
            });
        }
    }
}

export function afterChangeOpened(newValue: boolean, oldValue: boolean) {
    let self = this;
    if (!self._initialized && newValue) {
        self._initialized = true;
    }
    setTimeout(() => self._toggleOpened = newValue);
}
