import { EventEmitter, Input, Output, Directive } from "@angular/core";
import {UxAbstractFieldComponent, UxValueChangeEvent} from "./abstract-field.component";
import {UxPropertyConverter} from "../../common/decorator/ux-property-converter";
import {UxPropertyHandler} from "../../common/decorator/ux-property-handler";

@Directive()
export abstract class UxAbstractViewValueFieldComponent<T, V> extends UxAbstractFieldComponent<T> {

  @UxPropertyConverter("string", "")
  @Input()
  public placeholder: string;

  @UxPropertyConverter("string", "")
  @Input()
  public inputName: string;

  @UxPropertyHandler({
    afterChange: afterChangeViewValue
  })
  public viewValue: V;

  public viewValueChange: EventEmitter<UxValueChangeEvent<T>> = new EventEmitter<UxValueChangeEvent<T>>();

  @Output()
  public onKeyDown: EventEmitter<KeyboardEvent> = new EventEmitter<KeyboardEvent>();

  @Output()
  public onSubmitValueChange: EventEmitter<UxSubmitValueChangeEvent> = new EventEmitter<UxSubmitValueChangeEvent>();

  /**
   * Will be called after value is changed.
   *
   * @internal
   */
  public _onValueChange(event: UxValueChangeEvent<T>): void {
    let self = this;
    self.viewValue = self.getViewValueConverter().apply(self, [self.value]);
    if (event.oldValue !== event.newValue && self.onValueChange) {
      let newValue: string = event.newValue as any;

      self.onValueChange.emit(event);
      self.valueChange.emit(newValue);

      this.propagateChange && this.propagateChange(newValue);
      this.propagateChange && this.propagateTouch(newValue);
    }
  }

  /**
   * Function will be used to convert value for input value.
   */
  protected getViewValueConverter(): { (value: T): any } {
    return function (fromValue: T): any {
      return fromValue;
    };
  }

  /**
   * Will be called to submit value by 'enter' key press or 'blur' event. View value will be propagated to value.
   * @private
   * @internal
   */
  public _onSubmitValueChange(event: Event): void {
    let self = this;

    self.value = self.getValueConverter().apply(self, [self.viewValue]);

    self.onSubmitValueChange.emit({
      value: self.value,
      originalEvent: event
    });
  }

  /**
   * Will be called on view value - binding to 'change' event
   * @param event
   * @private
   * @internal
   */
  public _onViewValueChange(event: UxValueChangeEvent<T>): void {
    if (this.viewValueChange) {
      this.viewValueChange.emit(event);
    }
  }

  /** @internal */
  public _onKeyDown(event: KeyboardEvent): void {
    if (this.onKeyDown) {
      this.onKeyDown.emit(event);
    }
  }
}


/*Helpers*/
export interface UxSubmitValueChangeEvent {
  value: any;
  originalEvent: any;
}

export function afterChangeViewValue<T>(newValue: T, oldValue: T): void {
  this._onViewValueChange({oldValue: oldValue, newValue: newValue});
}
