<button class="ux-navigation-button__button"
        [ngClass]="_styleClasses"
        [disabled]="disabled || locked"
        (tap)="_onClick($event)">
    <i class="ux-navigation-button__border-image">
        <svg width="0" height="0" *ngIf="type=='back'"
             class="ux-navigation-button__border-image-part _nose" xmlns="http://www.w3.org/2000/svg" version="1.1"
             viewBox="0 0 19 32"
             fill-rule="evenodd">

             <path d="M19,0.5 L18,0.5 L18,0.5 C15.4434146,0.5 12.9962185,1.53724085 11.2183143,3.37440857 L3.03793087,11.8274714 C0.786748004,14.1536937 0.786748004,17.8463063 3.03793087,20.1725286 L11.2183143,28.6255914 C12.9962185,30.4627592 15.4434146,31.5 18,31.5 L19,31.5"></path>
        </svg>

        <svg width="0" height="0" *ngIf="type=='next'"
             class="ux-navigation-button__border-image-part _tail" xmlns="http://www.w3.org/2000/svg" version="1.1"
             viewBox="0 0 6 32"
             fill-rule="evenodd">

            <path d="M6,0.5 L5,0.5 L4.5,0.5 C2.290861,0.5 0.5,2.290861 0.5,4.5 L0.5,27.5 C0.5,29.709139 2.290861,31.5 4.5,31.5 L5,31.5 L6,31.5"></path>
        </svg>

        <div class="ux-navigation-button__border-image-part _body"></div>

        <svg width="0" height="0" *ngIf="type=='back'"
             class="ux-navigation-button__border-image-part _tail" xmlns="http://www.w3.org/2000/svg" version="1.1"
             viewBox="0 0 6 32"
             fill-rule="evenodd">

            <path d="M0,0.5 L1,0.5 L1.5,0.5 C3.709139,0.5 5.5,2.290861 5.5,4.5 L5.5,27.5 C5.5,29.709139 3.709139,31.5 1.5,31.5 L1,31.5 L0,31.5"></path>
        </svg>

        <svg width="0" height="0" *ngIf="type=='next'"
             class="ux-navigation-button__border-image-part _nose" xmlns="http://www.w3.org/2000/svg" version="1.1"
             viewBox="0 0 19 32"
             fill-rule="evenodd">

             <path d="M0,31.5 L1,31.5 L1,31.5 C3.55658537,31.5 6.00378147,30.4627592 7.78168571,28.6255914 L15.9620691,20.1725286 C18.213252,17.8463063 18.213252,14.1536937 15.9620691,11.8274714 L7.78168571,3.37440857 C6.00378147,1.53724085 3.55658537,0.5 1,0.5 L0,0.5"></path>
        </svg>
    </i>

    <i class="ux-navigation-button__outline-image">
        <svg width="0" height="0" *ngIf="type=='back'"
             class="ux-navigation-button__outline-image-part _nose" xmlns="http://www.w3.org/2000/svg" version="1.1"
             viewBox="0 0 23 40"
             fill-rule="evenodd">

             <path d="M23,1 L22.0021973,1 L20.3013182,1 C17.5553941,1 14.930286,2.12914254 13.0417914,4.12255352 L3.86392805,13.8102982 C0.575360257,17.2815642 0.575360257,22.7184358 3.86392805,26.1897018 L13.0417914,35.8774465 C14.930286,37.8708575 17.5553941,39 20.3013182,39 L22.0021973,39 L23,39"></path>
        </svg>

        <svg width="0" height="0" width="0" height="0" *ngIf="type=='next'"
             class="ux-navigation-button__outline-image-part _tail" xmlns="http://www.w3.org/2000/svg" version="1.1"
             viewBox="0 0 10 40"
             fill-rule="evenodd">

            <path d="M10,39 L9,39 L7,39 C3.6862915,39 1,36.3137085 1,33 L1,7 C1,3.6862915 3.6862915,1 7,1 L9,1 L10,1"></path>
        </svg>

        <div class="ux-navigation-button__outline-image-part _body"></div>

        <svg width="0" height="0" *ngIf="type=='back'"
             class="ux-navigation-button__outline-image-part _tail" xmlns="http://www.w3.org/2000/svg" version="1.1"
             viewBox="0 0 10 40"
             fill-rule="evenodd">

             <path d="M-0.00134277344,1 L1,1 L3,1 C6.3137085,1 9,3.6862915 9,7 L9,33 C9,36.3137085 6.3137085,39 3,39 L1,39 L-0.00134277344,39"></path>
        </svg>

        <svg width="0" height="0" *ngIf="type=='next'"
             class="ux-navigation-button__outline-image-part _nose" xmlns="http://www.w3.org/2000/svg" version="1.1"
             viewBox="0 0 23 40"
             fill-rule="evenodd">

            <path d="M0,1 L1,1 L2.69868176,1 C5.44460588,1 8.06971398,2.12914254 9.9582086,4.12255352 L19.1360719,13.8102982 C22.4246397,17.2815642 22.4246397,22.7184358 19.1360719,26.1897018 L9.9582086,35.8774465 C8.06971398,37.8708575 5.44460588,39 2.69868176,39 L1,39 L0,39"></path>
        </svg>
    </i>

    <span class="ux-navigation-button__text">{{text}}</span>
</button>