import {Component, EventEmitter, Input, Output, ViewChild} from "@angular/core";
import {UxRouteTabItem, UxRouteTabPanelComponent} from "../route-tab-panel/route-tab-panel.component";
import {UxPropertyConverter} from "../../common/decorator/ux-property-converter";

@Component({
    selector: "ux-header",
    templateUrl: "./header.component.html",
    host: {"[class.ux-header]": "true"}
})
export class UxHeaderComponent {

    @UxPropertyConverter("boolean", true)
    @Input()
    public logo: boolean;

    @Input()
    public links: UxHeaderLink[] = [];

    @Input()
    public mobile: boolean = false;


    @Output()
    public onLogoClick: EventEmitter<MouseEvent> = new EventEmitter();

    @ViewChild(UxRouteTabPanelComponent)
    private routeTabPanel: UxRouteTabPanelComponent;

    /** @internal */
    public _logoClickHandler(event: MouseEvent): void {
        this.onLogoClick.emit(event);
    }

    public update(): void {
        if (this.routeTabPanel) {
            this.routeTabPanel.update();
        }
    }
}

export interface UxHeaderLink extends UxRouteTabItem {
}
