<div class="ux-table-header__content">
    <div class="ux-table-header__title"
        *ngIf="model?.title"
    >{{model?.title}}</div>

    <div class="ux-table-header__block">
        <div class="ux-table-header__info" *ngIf="model?.info?.length">

            <ng-container *ngFor="let item of model?.info; trackBy: _trackByFn">
                <div class="ux-table-header__info-item {{item?.styleClass}}"
                     (tap)="_infoItemClick(item, $event)">
                    <div class="ux-table-header__info-text">
                        {{item?.number}} {{item?.text}}
                    </div>
                    <div class="ux-table-header__info-cross"
                        (tap)="_infoItemCrossClick(item, $event)"
                    >
                        <svg width="0" height="0"
                            class="ux-table-header__info-svg"
                             version="1.1" xmlns="http://www.w3.org/2000/svg"
                             viewBox="0 0 20 20">
                            <path d="M14.348,14.849c-0.469,0.469-1.229,0.469-1.697,0L10,11.819l-2.651,3.029c-0.469,0.469-1.229,0.469-1.697,0
            c-0.469-0.469-0.469-1.229,0-1.697l2.758-3.15L5.651,6.849c-0.469-0.469-0.469-1.228,0-1.697c0.469-0.469,1.228-0.469,1.697,0
            L10,8.183l2.651-3.031c0.469-0.469,1.228-0.469,1.697,0c0.469,0.469,0.469,1.229,0,1.697l-2.758,3.152l2.758,3.15
            C14.817,13.62,14.817,14.38,14.348,14.849z"/>
                        </svg>
                    </div>
                </div>
            </ng-container>

        </div>
        <div class="ux-table-header__controls" *ngIf="tableHeaderControls">
            <ng-template
                    [ngTemplateOutlet]="tableHeaderControls"
            ></ng-template>
        </div>
    </div>
</div>
