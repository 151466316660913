<div class="ux-accordion-item__header"
     [ngClass]="{'_active': opened,'_disabled':disabled}"
     (tap)="_toggle($event)">

    <i *ngIf="_customIcon" class="ux-accordion-item__header-icon">
        <ng-content select="svg"></ng-content>
    </i>
    <i *ngIf="!_customIcon" class="ux-accordion-item__header-icon">
        <svg width="0" height="0" version="1.1" xmlns="http://www.w3.org/2000/svg"
             viewBox="0 0 20 20">
            <path d="M11,10L7.859,6.58c-0.268-0.27-0.268-0.707,0-0.978c0.268-0.27,0.701-0.27,0.969,0l3.83,3.908
		    c0.268,0.271,0.268,0.709,0,0.979l-3.83,3.908c-0.267,0.272-0.701,0.27-0.969,0c-0.268-0.269-0.268-0.707,0-0.978L11,10z"></path>
        </svg>
    </i>

    <ng-container *ngIf="(caption || _customHeader) && !headerTemplate">
        <div *ngIf="!_customHeader"
             class="ux-accordion-item__header-caption">{{caption}}
        </div>
        <div *ngIf="_customHeader"
             class="ux-accordion-item__header-content">
            <ng-content select="ux-accordion-item-header"></ng-content>
        </div>
    </ng-container>

    <ng-template *ngIf="headerTemplate"
                 [ngTemplateOutlet]="headerTemplate"
                 [ngTemplateOutletContext]="{model: customData}"
    ></ng-template>
</div>

<div [ngClass]="{'ux-accordion-item__content': true}"
     [uxSlideToggle]="_toggleOpened">
    <div class="ux-accordion-item__content-inner" *ngIf="!template">
        <ng-content></ng-content>
    </div>

    <div class="ux-accordion-item__content-inner" *ngIf="template && _initialized">
        <ng-template [ngTemplateOutlet]="template"
                     [ngTemplateOutletContext]="{model: customData}">
        </ng-template>
    </div>
</div>
