<div class="ux-memo-field__input-wrapper">
    <ux-scroll class="_border">
        <textarea #customScrollableBlock
                  scrollableBlock
                  class="ux-memo-field__input taValue"
                  [placeholder]="placeholder"
                  [attr.name]="inputName"
                  [attr.maxlength]="maxLength"
                  [disabled]="disabled"
                  [(ngModel)]="value"
                  (focus)="_onFocus($event)"
                  (change)="_onSubmitValueChange($event)"
                  (keydown)="_onKeyDown($event)"
                  (blur)="_onBlur($event)"
        ></textarea>
    </ux-scroll>
    <div class="ux-memo-field__resize" #draggableArea></div>
</div>

