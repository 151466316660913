import {Injectable, Injector} from "@angular/core";
import {ApiService} from "./api.service";
import {DictionaryType} from "../models/entity/dictionary-type.model";
import {Observable, Subscriber} from "rxjs";

@Injectable()
export class DictionaryService extends ApiService {
  protected readonly dictionaryTestTypesUrl: string[] = ["/json/test-types.model.json", "./json/test-types.model.json"];
  protected readonly dictionaryDeviceTypesUrl: string[] = ["/json/device-types.model.json", "./json/device-types.model.json"];

  constructor(protected injector: Injector) {
    super(injector);
  }

  public getTestTypes(): Observable<DictionaryType[]> {
    return this.getDictionary(this.dictionaryTestTypesUrl);
  }

  public getDevicesTypes(): Observable<DictionaryType[]> {
    return this.getDictionary(this.dictionaryDeviceTypesUrl);
  }

  public findDictionaryItem(items: DictionaryType[], value: string): DictionaryType {
    let item:DictionaryType = items.find(item => item.value === value);
    if (item === undefined) {
      return items[0];
    }
    return item;
  }

  private getDictionary(dictionaryUrl: string[]): Observable<DictionaryType[]> {
    let o = Observable.create((subscriber: Subscriber<DictionaryType[]>) => {
      let get = this.get_collection(dictionaryUrl, {}, true)
        .subscribe(async (items: DictionaryType[]) => {
          subscriber.next(items);
          subscriber.complete();
        });
      return () => {
        get && get.unsubscribe();
      };
    });
    return o;
  }
}
