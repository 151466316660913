<div [formGroup]="group"
     #tooltip=uxTooltip
     [uxTooltip]="_errorMessages"
     [uxTooltipAppendTo]="validationTooltipContainer || 'target'"
     [uxTooltipEvent]="(!(_fieldComponent?.focused && validateOn === 'blur') && _hasError) ? tooltipEvent || ['hover','focus'] : 'none'"
     [uxTooltipPosition]="'bottom'"
     [uxTooltipAlign]="'left'">

    <input class="ux-form-field__input" type="text"
           [(ngModel)]="value"
           (ngModelChange)="_onValueChange($event)"
           [formControlName]="controlName"/>
    <ng-content></ng-content>
</div>
