<ul class="ux-card-tree__node-list"
    *ngIf="_model?.children?.length">
  <li *ngIf="selectAllEnabled && _selectionAvailable"
      [attr.data-level]="level"
      class="ux-card-tree__node _leaf _root _full-selector"
  >

    <div class="ux-card-tree__node-content _with-checkbox taPanel">

      <div class="ux-card-tree__node-checkbox-wrapper">
        <ux-checkbox-field class="ux-card-tree__node-checkbox"
                           [value]="_allSelected"
                           (valueChange)="_onCheckedAll($event)"
        ></ux-checkbox-field>
      </div>

      <div class="ux-card-tree__node-content-wrapper"
           (tap)="_onCheckedAll(!_allSelected)"
      >
        {{selectAllCaption}}
      </div>
    </div>
  </li>

  <ng-container *ngFor="let node of _model?.children; let i = index; trackBy: _trackByFn;">
    <li *ngIf="node?.children?.length || node?.hasChildren"
        class="ux-card-tree__node _branch"
        [attr.data-level]="level"
        [ngClass]="{
                _root : _model === rootModel,
                _last: i === _model?.children?.length - 1
            }">

      <div class="ux-card-tree__node-content taPanel"
           [ngClass]="{_opened: node.opened}">

        <div *ngIf="node?.selected !== undefined"
             class="ux-card-tree__node-content-control">
          <ux-checkbox-field class="ux-card-tree__node-checkbox _round"
                             [value]="node?.selected"
                             (valueChange)="_onChecked({value: $event, model: node})"
          ></ux-checkbox-field>
        </div>

        <div class="ux-card-tree__node-content-control"
             (tap)="_toggleNode(node, $event)">
          <ng-template [ngTemplateOutlet]="rootModel.controlTemplate || defaultControlTemplate"
                       [ngTemplateOutletContext]="{
                                    level: level,
                                    parentNode: _model,
                                    node: node
                                 }">
          </ng-template>
        </div>

        <div class="ux-card-tree__node-content-wrapper"
             [ngClass]="{'_with-checkbox': node?.selected !== undefined}"
        >
          <ng-template [ngTemplateOutlet]="rootModel.template"
                       [ngTemplateOutletContext]="{
                                level: level,
                                parentNode: _model,
                                node: node
                             }">
          </ng-template>

        </div>
      </div>

      <i class="ux-card-tree__node-horizontal-line"></i>
      <i class="ux-card-tree__node-vertical-line"></i>

      <ux-card-tree #childTree
                    class="ux-card-tree__node-subtree"
                    [innerTree]="true"
                    [model]="node"
                    [level]="level + 1"
                    [rootModel]="rootModel"
                    [toggleDuration]="toggleDuration"
                    [uxSlideToggle]="node.opened"
                    [uxSlideToggleDuration]="toggleDuration"
                    (onSlideStart)="_isAnimating = true"
                    (onSlideEnd)="_isAnimating = false"
                    (onToggle)="onToggle.emit($event)"
                    (onChecked)="_onChecked($event)"
      ></ux-card-tree>
    </li>


    <li *ngIf="!node?.children?.length && !node?.hasChildren && (node?.visible === undefined || node?.visible === true)"
        class="ux-card-tree__node _leaf"
        [attr.data-level]="level"
        [ngClass]="{
            _root : _model === rootModel,
            _last: i === _model?.children?.length - 1
            }">

      <div class="ux-card-tree__node-content taPanel">

        <div *ngIf="node?.selected !== undefined"
             class="ux-card-tree__node-checkbox2">
          <ux-checkbox-field class="ux-card-tree__node-checkbox"
                             [value]="node?.selected"
                             (valueChange)="_onChecked({value: $event, model: node})"
          ></ux-checkbox-field>
        </div>

        <div class="ux-card-tree__node-content-wrapper">
          <ng-template [ngTemplateOutlet]="rootModel.template"
                       [ngTemplateOutletContext]="{
                             level: level,
                             node: node,
                             parentNode: _model
                }">
          </ng-template>
        </div>
      </div>

      <i class="ux-card-tree__node-horizontal-line"></i>
      <i class="ux-card-tree__node-vertical-line"></i>
    </li>

  </ng-container>
</ul>


<ng-template #defaultControlTemplate>
  <div class="ux-card-tree__node-content-arrow">
    <svg class="ux-card-tree__node-content-arrow-svg"
         width="0" height="0"
         viewBox="0 0 20 20">
      <path d="M11,10L7.859,6.58c-0.268-0.27-0.268-0.707,0-0.978c0.268-0.27,0.701-0.27,0.969,0l3.83,3.908
		    c0.268,0.271,0.268,0.709,0,0.979l-3.83,3.908c-0.267,0.272-0.701,0.27-0.969,0c-0.268-0.269-0.268-0.707,0-0.978L11,10z"></path>
    </svg>
  </div>
</ng-template>
