import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {StatisticDataType} from "../models/statistic-data.type";
import {StatisticPeriodType} from "../models/statistic-period.type";

@Injectable()
export class AppConfigService {

    appConfigPromise: Promise<AppConfig>;
    private appConfig: AppConfig;

    constructor(protected http: HttpClient) {
    }

    public routes(): RoutesConfig {
      return this.appConfig.routes;
    }

    public paging(): PagingConfig {
      return this.appConfig.paging;
    }

    public report(): ReportConfig {
      return this.appConfig.report;
    }

    public devices(): DevicesConfig {
      return this.appConfig.devices;
    }

    public tests(): TestsConfig {
      return this.appConfig.tests;
    }

    init(): Promise<AppConfig> {
        let o: Promise<AppConfig> = this.http.get<AppConfig>("./app-config.json").toPromise();
        o.then(appConfig => {
          this.appConfig = appConfig;
        });
        this.appConfigPromise = o;
        return o;
    }
}

export interface RoutesConfig {
  server?: string;
  api?: string;
  proxy?: string;
}

export interface PagingConfig {
  pageSize?: number;
  pageSizeOptions?: number[];
}

export interface ReportConfig {
  periods?: number;
  defaultDuration?: number;
  mos_pvqa?: boolean;
  mos_aqua?: boolean;
  r_factor?: boolean;
  percents_aqua?: boolean;
  mos_network?: boolean;
}

export interface DevicesConfig {
  defaultStatistics?: StatisticDataType;
  defaultPeriod?: StatisticPeriodType;
  defaultDuration?: number;
}

export interface TestsConfig {
  defaultStatistics?: StatisticDataType;
  defaultPeriod?: StatisticPeriodType;
  defaultDuration?: number;
}

export interface AppConfig {
  routes?: RoutesConfig;
  paging?: PagingConfig;
  report?: ReportConfig;
  devices?: DevicesConfig;
  tests?: TestsConfig;
}

export function appConfigFactory(appConfig: AppConfigService): () => Promise<AppConfig> {
    return () => appConfig.init();
}
