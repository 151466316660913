import {NgModule} from "@angular/core";
import {Routes, RouterModule} from "@angular/router";
import {ReportWizardComponent} from "./components/report-wizard.component";
import {ReportViewComponent} from "./components/report-view.component";
import {SevanaQualtestAuthGuard} from "../../sevana-qualtest-auth.guard";

const routes: Routes = [
  {
    path: 'reports',
    canActivate: [SevanaQualtestAuthGuard],
    canActivateChild: [SevanaQualtestAuthGuard],
    children: [
      {path: '', redirectTo: 'wizard', pathMatch: 'full'},
      {path: 'wizard', component: ReportWizardComponent},
      {path: 'view', component: ReportViewComponent}
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ReportsRoutingModule {
}
