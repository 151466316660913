<input
    #mobileInput
    *ngIf="mobile"
    class="ux-date-field__input _mobile taValue"
    [placeholder]="placeholder"
    [disabled]="disabled"
    [(ngModel)]="viewValue"
    (focus)="_onFocus($event)"
    (blur)="_onBlur($event)"
    (change)="_onSubmitValueChange($event)"
    type="date"
    [min]="_mobileDateBoundaries?.min"
    [max]="_mobileDateBoundaries?.max"
    maxlength="10"
/>

<div
    #contentWrapper
    *ngIf="!mobile"
    class="ux-date-field__content-wrapper"
    [ngClass]="{ '_visible': _visibleCalendar }"
    tabindex="0"
>
    <div class="ux-date-field__calendar-wrapper" #calendarIcon>
        <div
            name="Calendar"
            class="ux-date-field__calendar-icon-wrapper"
            [ngClass]="{_visible: _visibleCalendar}"
            (tap)="_calendarIconClick($event)"
        >

            <i *ngIf="_customIcon" class="ux-date-field__icon">
                <ng-content select="svg"></ng-content>
            </i>
            <i *ngIf="!_customIcon" class="ux-date-field__icon">
                <svg width="0" height="0"
                     #customIcon
                     version="1.1" xmlns="http://www.w3.org/2000/svg"
                     viewBox="0 0 20 20"
                     class="ux-date-field__icon-svg"
                >
                    <path d="M17,3h-1v2h-3V3H7v2H4V3H3C1.899,3,1,3.9,1,5v12c0,1.1,0.899,2,2,2h14c1.1,0,2-0.9,2-2V5C19,3.9,18.1,3,17,3z M17,17H3V9h14
	                    V17z M6.5,1h-2v3.5h2V1z M15.5,1h-2v3.5h2V1z"
                    >
                    </path>
                </svg>
            </i>
        </div>
    </div>
    <input
        #input
        class="ux-date-field__input _date taTextValue taEditElement taValue"
        [placeholder]="placeholder"
        [attr.name]="inputName"
        [disabled]="disabled"
        [readonly]=" _visibleCalendar"
        [(ngModel)]="viewValue"
        (focus)="_onFocus($event)"
        (blur)="_onBlur($event)"
        (change)="_onSubmitValueChange($event)"
        (keydown)="_onKeyDown($event)"
        type="text"
    />
</div>

<ux-popover
    #popover
    [visible]="_visibleCalendar"
    [closable]="true"
    class="ux-date-field__calendar _no-padding _calendar"
>
    <div class="ux-date-field__calendar" #calendar></div>
</ux-popover>
