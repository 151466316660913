<!-- ux-overlay-inner class is deprecated, use ux-overlay__inner instead -->
<!-- TODO remove ux-overlay-inner class in 1.0.35 -->
<div #inner
     class="ux-overlay__inner ux-overlay-inner"
     [uxFadeToggle]="visible"
     [style.zIndex]="zIndex">
    <ng-container *ngIf="visible">
        <ng-content></ng-content>
    </ng-container>
</div>
