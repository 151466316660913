import {Progress} from "../progress.model";

export abstract class Entity {
  public progress?: Progress;

  public constructor(id?: number|string) {
    this.setId(id);
    this.progress = 'initial';
  }

  public abstract getId(): number|string;
  public abstract setId(value: number|string);
  public abstract getIdField(): string;

  public getCopy(): Entity {
    return <Entity>JSON.parse(JSON.stringify(this));
  }

  public copyFrom(value: Entity) {
    this.progress = value.progress;
  }
}
