import {Component, EventEmitter, HostBinding, Input, Output, TemplateRef} from "@angular/core";

export interface UxBreadcrumbItemModel {
  id?: string;
  url?: string;
  label: string;
}

@Component({
    selector: 'ux-breadcrumbs',
    templateUrl: './breadcrumbs.component.html',
    host: {'[class.ux-breadcrumbs]': 'true'}
})
export class UxBreadcrumbsComponent {

    @Input()
    public items: UxBreadcrumbItemModel[];

    @Input()
    public delimiterTemplate: TemplateRef<any>;

    @Output()
    public onClick: EventEmitter<UxBreadcrumbsClickEvent> = new EventEmitter<UxBreadcrumbsClickEvent>();


    /** @internal */
    public _itemClick(event: Event, item: UxBreadcrumbItemModel, index: number): void {
        this.onClick.emit({
            item: item,
            index: index,
            originalEvent: event
        });
    }

    /** @internal */
    public _onKeyDown(event: KeyboardEvent, item: UxBreadcrumbItemModel, index: number): void {
        if (event.keyCode === 13) { // Enter
            this._itemClick(event, item, index)
        }
    }

    /** @internal */
    public _trackByFn(index: number, item: UxBreadcrumbItemModel): string {
        return item && item.id;
    }
}

export interface UxBreadcrumbsClickEvent {
    item: UxBreadcrumbItemModel;
    originalEvent: Event;
    index: number;
}
