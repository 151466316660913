import {NgModule} from "@angular/core";
import {TimeDurationPipe} from "./time-duration.pipe";
import {TimeAgoPipe} from "./time-ago.pipe";

@NgModule({
  declarations: [
    TimeDurationPipe,
    TimeAgoPipe
  ],
  imports: [],
  exports: [
    TimeDurationPipe,
    TimeAgoPipe
  ]
})
export class PipesModule {
}
