<ul class="ux-breadcrumbs__list">
    <ng-container *ngFor="let item of items; let i = index; trackBy: _trackByFn">

        <li class="ux-breadcrumbs__item _label"
            tabindex="0"
            (tap)="_itemClick($event, item, i)"
            (keydown)="_onKeyDown($event, item, i)"
        >
            {{item.label}}
        </li>

        <ng-template *ngIf="i !== items.length - 1"
                     [ngTemplateOutlet]="delimiterTemplate || defaultDelimiterTemplate"
        ></ng-template>

    </ng-container>
</ul>

<ng-template #defaultDelimiterTemplate>
    <svg class="ux-breadcrumbs__item _separator"
         width="0" height="0"
         viewBox="0 0 20 20">
        <path d="M11,10L7.859,6.58c-0.268-0.27-0.268-0.707,0-0.978c0.268-0.27,0.701-0.27,0.969,0l3.83,3.908
		    c0.268,0.271,0.268,0.709,0,0.979l-3.83,3.908c-0.267,0.272-0.701,0.27-0.969,0c-0.268-0.269-0.268-0.707,0-0.978L11,10z"></path>
    </svg>
</ng-template>
