import {AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild} from "@angular/core";


@Component({
    selector: "ux-multiple-text-field-popover-content",
    templateUrl: "multiple-text-field-popover-content.component.html",
    host: {"[class.ux-multiple-text-field-popover-content]": "true"},
})
export class UxMultipleTextFieldPopoverContentComponent implements AfterViewInit {

    @Input()
    public newValueText: string = "New Value";

    @Input()
    public saveButtonText: string = "Save";

    @Input()
    public cancelButtonText: string = "Cancel";


    @Output()
    public onSaveClick: EventEmitter<{ value: string }> = new EventEmitter<{ value: string }>();

    @Output()
    public onCancelClick: EventEmitter<void> = new EventEmitter<void>();


    /** @internal */
    public _value: string = "";

    @ViewChild("focusedElement", { read: ElementRef, static: true })
    private focusedElement: ElementRef;

    public ngAfterViewInit(): void {
        this.focus();
    }

    private focus(): void {
        let element = this.focusedElement.nativeElement.querySelector(".ux-text-field__input");
        window.setTimeout(() => {   //after popover is shown
            element && (element.focus());
        });
    }

    /** @internal */
    public _onSaveClick(): void {
        this.onSaveClick.emit({value: this._value});
        this._value = "";
    }

    /** @internal */
    public _onCancelClick(): void {
        this.onCancelClick.emit();
        this._value = "";
    }
}
