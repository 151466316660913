import {AfterViewInit, Component, OnDestroy, ViewChild} from "@angular/core";
import {ResultsService} from "../results.service";
import {Router} from "@angular/router";
import {UxTableLinkEvent} from "../../../../ux-lib/components/table/table.model";
import {ResultColumnSortEvent, ResultsListAdvancedComponent} from "./results-list-advanced.component";
import {TestResultEntity} from "../../../common/models/entity/test-result-entity.model";
import {ModelService} from "../../../common/services/model.service";
import {NotificationService} from "../../../common/services/notification.service";
import {Subscription} from "rxjs";
import {ApiPath} from "../../../common/services/api.path";
import {UxPageChangeEvent} from "../../../../ux-lib/components/paging/paging.component";
import {CountResult} from "../../../common/models/result.type";

const RESULT_LOADING: string = "Loading results...";
const RESULT_EMPTY: string = "No results found";

@Component({
  selector: "sq-results-list",
  templateUrl: "results-list.component.html",
  host: {"[class.sq-results-list]": "true"}
})
export class ResultsListComponent implements AfterViewInit, OnDestroy {

  @ViewChild("resultsListAdvanced", { static: true })
  resultsListAdvanced: ResultsListAdvancedComponent;

  _resultsData: TestResultEntity[] = [];

  _totalTestsCount: number;
  _emptyTableContent: string;

  private resultsCountSubscription: Subscription;
  private resultsSubscription: Subscription;

  constructor(private resultsService: ResultsService,
              private notificationService: NotificationService,
              private modelService: ModelService,
              private router: Router) {
  }

  ngAfterViewInit(): void {
    this.updateResultsList(this.resultsListAdvanced.paging.currentPage, this.resultsListAdvanced.paging.pageSize);
  }

  ngOnDestroy(): void {
    this.resultsCountSubscription && this.resultsCountSubscription.unsubscribe();
    this.resultsSubscription && this.resultsSubscription.unsubscribe();
  }

  _onRemoveResultsClicked() {
    this.resultsListAdvanced.deleteSelectedResults();
  }

  _onTableLinkClicked(eventData: UxTableLinkEvent) {
    if (!eventData.header) {
      if (eventData.column.url !== undefined) {
        this.router.navigate([eventData.column.url]);
      }
    }
  }

  _onDynamicSortClick(event: ResultColumnSortEvent) {
    this.updateResultsList(this.resultsListAdvanced.paging.currentPage, this.resultsListAdvanced.paging.pageSize, event);
  }

  _onTablePageChange(event: UxPageChangeEvent) {
    this.updateResultsList(event.page, event.items, this.resultsListAdvanced.lastSortEvent);
  }

  _onTablePageSizeChange(event: UxPageChangeEvent) {
    this.updateResultsList(event.page, event.items, this.resultsListAdvanced.lastSortEvent);
  }

  private updateResultsList(currentPage: number, pageSize: number, event?: ResultColumnSortEvent) {
    let queryParams = [
      {
        key: ApiPath.OFFSET,
        value: (currentPage-1) * pageSize
      },
      {
        key: ApiPath.LIMIT,
        value: pageSize
      }
    ];

    queryParams[ApiPath.OFFSET] = (currentPage-1) * pageSize;
    queryParams[ApiPath.LIMIT] = pageSize;

    if (event && event.sortOrder !== "default" && event.sortOrder !== true) {
      queryParams[ApiPath.ORDER_COLUMN] = event.columnName;
      queryParams[ApiPath.ORDER_DIR] = event.sortOrder;
    }

    this._emptyTableContent = RESULT_LOADING;
    this._resultsData = [];

    this.resultsCountSubscription && this.resultsCountSubscription.unsubscribe();
    this.resultsCountSubscription = this.resultsService.getEntitiesCount().subscribe((value: CountResult) => {
      this._totalTestsCount = value.count;

      this.resultsSubscription && this.resultsSubscription.unsubscribe();
      this.resultsService.getEntityList(queryParams).subscribe((values: TestResultEntity[]) => {
        this._resultsData = values;
        if (this._resultsData.length === 0) {
          this._emptyTableContent = RESULT_EMPTY;
        }
        this.resultsListAdvanced.resetHeader(event);
      });
    })
  }
}
