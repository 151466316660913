import {
    AfterContentInit,
    ChangeDetectorRef,
    Component,
    ContentChildren,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    Output,
    QueryList,
    ViewChild,
    TemplateRef
} from "@angular/core";
import {UxTabPanelItem} from "./item/tab-panel-item.component";
import {UxTabPanelHeader} from "./header/tab-panel-header.component";
import {TabChangeEvent, UxTabChangeEvent} from "./tab-panel-event.model";
import {Subscription} from "rxjs";
import {UxPropertyHandler} from "../../common/decorator/ux-property-handler";

@Component({
    selector: "ux-tab-panel",
    templateUrl: "./tab-panel.component.html",
    host: {"[class.ux-tab-panel]": "true"}
})
export class UxTabPanel implements AfterContentInit, OnDestroy {

    @Input()
    public headerStyleClass: string;

    @Input()
    public headerItemTemplate: TemplateRef<any>;

    @UxPropertyHandler({
        afterChange: afterMobileChange
    })
    @Input()
    public mobile: boolean = false;


    private _externalHeader: UxTabPanelHeader;

    @Input()
    public set externalHeader(header: UxTabPanelHeader) {
        if (this._externalHeader) {
            this._externalHeader.tabs = [];
        }
        this._externalHeader = header;
        if (this._externalHeader) {
            this._externalHeader.tabs = this._tabs;
            if (this.subscription) {
                this.subscription.unsubscribe();
            }
            this.subscription = this._externalHeader.onChange.subscribe(($event: TabChangeEvent) => this._handleChange($event));
        }
    }

    public get externalHeader(): UxTabPanelHeader {
        return this._externalHeader;
    }

    @Input()
    public get selectedTab(): UxTabPanelItem {
        let selectedTabIndex = this.selectedTabIndex;
        if (selectedTabIndex != null) {
            return this._tabs[selectedTabIndex];
        }
        return null;
    }

    public set selectedTabIndex(index: number) {
        if (!this.contentInited) {
            this.initSelectedTabIndex = index;
        }

        this.setSelectedTabIndex(index);
    }

    @Input()
    public get selectedTabIndex(): number {
        if (!this.contentInited) {
            return this.initSelectedTabIndex;
        }

        return this.getSelectedTabIndex(this._tabs);
    }


    @Output()
    public onChange: EventEmitter<TabChangeEvent> = new EventEmitter<TabChangeEvent>();


    @ContentChildren(UxTabPanelItem)
    private tabItems: QueryList<UxTabPanelItem>;


    @ViewChild("innerHeader")
    private innerHeader: UxTabPanelHeader;

    /** @internal */
    public _tabs: UxTabPanelItem[] = [];

    private contentInited: boolean = false;
    private initSelectedTabIndex: number;

    private subscription: Subscription;
    private tabItemsSubscribtion: Subscription;
    private tabChangesSubscription: Subscription[] = [];

    constructor(private cdr: ChangeDetectorRef) {
    }

    public ngAfterContentInit(): void {
        this.contentInited = true;

        this.updateTabs(this.tabItems);

        this.tabItemsSubscribtion = this.tabItems.changes.subscribe((currentTabs: QueryList<UxTabPanelItem>) => {
            this.updateTabs(currentTabs);
        });
    }

    private updateTabs(currentTabs: QueryList<UxTabPanelItem>): void {
        this._tabs = currentTabs.toArray();

        if (this._externalHeader) {
            this._externalHeader.tabs = this._tabs;
        }

        let currentSelectedTabIndex = this.getSelectedTabIndex(this._tabs);

        if (this._tabs && this._tabs.length) {
            if (currentSelectedTabIndex < 0) {
                currentSelectedTabIndex = this.initSelectedTabIndex;

                // set 0 by default
                if (!currentSelectedTabIndex
                    || currentSelectedTabIndex < 0
                    || currentSelectedTabIndex >= this._tabs.length
                ) {
                    currentSelectedTabIndex = 0;
                }

                this.setSelectedTabIndex(currentSelectedTabIndex);

            } else {
                this.onChange.emit({
                    index: currentSelectedTabIndex,
                    selectedTab: this._tabs[currentSelectedTabIndex],
                    originalEvent: null
                });
            }

            this._tabs.forEach((tab: UxTabPanelItem) => {

                // For binding single tab, header and tab-panel
                const tabChangeSubscription = tab.onChanges.subscribe(() => {
                    this._tabs = [...this._tabs];
                    this.cdr.detectChanges();

                    if (this._externalHeader) {
                        this._externalHeader.tabs = this._tabs;
                        this._externalHeader.cdr.detectChanges();
                    }
                });

                this.tabChangesSubscription.push(tabChangeSubscription);
            });
        }

        this.cdr.detectChanges();
    }

    public ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }

        if (this.tabItemsSubscribtion) {
            this.tabItemsSubscribtion.unsubscribe();
        }

        if (this.tabChangesSubscription.length) {
            this.tabChangesSubscription.forEach((subscription) => {
                subscription.unsubscribe();
            });
        }
    }

    /** @internal */
    public _handleChange(event: UxTabChangeEvent): void {
        this.selectNewTab(event);
    }

    private setSelectedTabIndex(index: number) {
        if (index < this._tabs.length) {
            this.selectNewTab({index: index, selectedTab: this._tabs[index], originalEvent: null});
        } else if (this.contentInited) {
            console.error("index is incorrect. Count of tabs is " + this._tabs.length);
            //throw new Error("index is incorrect. Count of tabs is " + this._tabs.length);
        }
    }

    private selectNewTab(event: UxTabChangeEvent): void {
        if (!event.selectedTab.selected && !event.selectedTab.disabled) {

            let oldSelectedTabIndex: number = this.selectedTabIndex;
            if (oldSelectedTabIndex >= 0) {
                this._tabs[oldSelectedTabIndex].selected = false;
            }
            event.selectedTab.selected = true;

            this.onChange.emit(event);
        }
    }

    private getHeader(): UxTabPanelHeader {
        if (this.innerHeader) {
            return this.innerHeader;
        } else if (this.externalHeader) {
            return this.externalHeader;
        }
        return null;
    }

    private getSelectedTabIndex(tabs: UxTabPanelItem[]): number {
        for (let i = 0; i < tabs.length; i++) {
            if (tabs[i].selected) {
                return i;
            }
        }
        return -1;
    }
}


/** Helpers */
export function afterMobileChange(): void {
    if (this.innerHeader) {
        this.innerHeader.mobile = this.mobile;
    }
}
