import {Injectable} from "@angular/core";
import * as csvtojson from 'csvtojson';
import {Observable, Subscriber} from "rxjs";

@Injectable()
export class CsvToJsonService {

  constructor() {
  }

  public csv2json(data: string): Observable<Object> {
    let o = Observable.create((subscriber: Subscriber<Object>) => {

      csvtojson({
        delimiter: ";"
      })
        .fromString(data)
        .then((jsonObj)=>{
          subscriber.next(jsonObj);
          subscriber.complete();
        });

      return () => {
        //some cleanup logic
      };
    });

    return o;
  }
}
