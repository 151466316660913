import {AfterViewInit, Component, ContentChild, ElementRef, HostBinding, Input, ViewChild} from "@angular/core";
import {UxAbstractViewValueFieldComponent} from "../abstract-view-value-field.component";
import {UxValueChangeEvent} from "../abstract-field.component";
import {UxDomHelper} from "../../../shared/dom/dom-helper";
import {NG_VALUE_ACCESSOR} from "@angular/forms";
import {convertToString, UxPropertyConverter} from "../../../common/decorator/ux-property-converter";

@Component({
  selector: "ux-search-field",
  templateUrl: "search-field.component.html",
  host: {"[class.ux-search-field]": "true"},
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: UxSearchFieldComponent,
      multi: true
    }
  ]
})
export class UxSearchFieldComponent extends UxAbstractViewValueFieldComponent<string, string> implements AfterViewInit {

  @HostBinding("class._wide")
  @UxPropertyConverter("boolean", false)
  @Input()
  public fillParentWidth: boolean;

  @UxPropertyConverter("number", 0)
  @Input()
  public searchMinSymbols: number;

  @UxPropertyConverter("number", 0)
  @Input()
  public searchDelay: number;

  @Input()
  public autofocus: boolean = false;


  @ContentChild("customIcon", /* TODO: add static flag */ {static: true})
  private set customIcon(iconElement: ElementRef) {
    this._customIcon = !!iconElement;
  }

  /** @internal */
  public _customIcon: boolean = false;

  @ContentChild("customClearIcon", /* TODO: add static flag */ {static: true})
  private set customClearIcon(iconElement: ElementRef) {
    this._customClearIcon = !!iconElement;
  }

  /** @internal */
  public _customClearIcon: boolean = false;

  @HostBinding("class._empty")
  private isEmpty: boolean = true;

  private timerId: number;
  private oldViewValue: string = "";

  @ViewChild("searchInput", { static: true })
  private searchInputElementRef: ElementRef;

  public ngAfterViewInit(): void {
    if (this.autofocus) {
      this.focus();
    }
  }

  public focus(): void {
    setTimeout(() => {
      this.searchInputElementRef.nativeElement.focus();
    }, 0);
  }

  protected getDefaultValue(): string {
    return "";
  }

  protected getValueConverter(): { (value: any): string } {
    return convertToString;
  }

  /** @internal */
  public _onViewValueChange(event: UxValueChangeEvent<string>): void {
    let self = this;
    let viewValue = event.newValue;

    if (viewValue.length >= self.searchMinSymbols && viewValue !== self.oldViewValue) {
      clearTimeout(self.timerId);
      self.timerId = window.setTimeout(() => {
        self.value = viewValue;
        clearTimeout(self.timerId);
      }, self.searchDelay);
    }

    self.oldViewValue = viewValue;
    if (self.viewValueChange) {
      self.viewValueChange.emit(event);
    }

    self.isEmpty = viewValue.trim().length === 0;
  }

  /** @internal */
  public _onKeyUp(event: KeyboardEvent): void {
    if (UxDomHelper.checkIfIE() && event.which === 13 /* enter */) {
      this._onSubmitValueChange(event);
    }
  }

  /** @internal */
  public _onClearIconClick(): void {
    this.viewValue = this.value = "";
  }
}
