<div class="ux-attachment__drop-area"
     [ngClass]="{'_disabled': disabled}"
     uxDroppable
     (onDragEnter)="_onDragEnter($event)"
     (onDragLeave)="_onDragLeave($event)"
     (onDrop)="_onDrop($event)"
     (tap)="_onDropAreaClick($event)">
    <span class="ux-attachment__drop-area-text">{{dropAreaTooltip}}</span>
</div>

<div class="ux-attachment__errors" *ngIf="_hasErrors()">
    <span class="ux-attachment__errors-title">{{errorText}}</span>

    <ul class="ux-attachment__errors-list">
        <li class="ux-attachment__errors-list-item"
            *ngFor="let error of _errors">
            {{error}}
        </li>
    </ul>

    <i class="ux-attachment__errors-close-icon" (tap)="_clearErrorsList()"></i>
</div>

<div #fileList
     class="ux-attachment__files-list-wrapper"
     [ngClass]="{'_disabled': disabled}"
     *ngIf="_hasFiles() && !hideList"
>
    <ux-scroll [showSliders]="true">
        <ul #fileListItems class="ux-attachment__files-list">
            <li #fileListItem class="ux-attachment__files-list-item" *ngFor="let file of files; let i = index">
                <svg width="0" height="0" class="ux-attachment__file-upload-progress-icon"
                     *ngIf="file.uploadProgress !== 100"
                     viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
                    <circle class="ux-attachment__file-upload-progress-icon-background-circle"
                            r="10" cx="12" cy="12"></circle>
                    <circle class="ux-attachment__file-upload-progress-icon-foreground-circle"
                            r="10" cx="12" cy="12"
                            [attr.stroke-dashoffset]="62.8*(100 - file.uploadProgress)/100"></circle>
                </svg>

                <i *ngIf="file.isUploaded" class="ux-attachment__uploaded-file-icon"></i>

                <span class="ux-attachment__file-name">{{file.name}}</span>
                <span class="ux-attachment__file-size"
                      *ngIf="file && (!file.isVirtual)"
                >
                  {{this._formatSize(file.size)}}
                </span>

                <div class="ux-attachment__file-remove-icon-wrapper" (tap)="_removeFile(i)">
                    <i class="ux-attachment__file-remove-icon"></i>
                </div>
            </li>
        </ul>
    </ux-scroll>
</div>

<input #fileInput class="ux-attachment__input" type="file" [multiple]="multiple" (change)="_onFileSelect($event)"/>

<div class="ux-attachment__buttons-wrapper" *ngIf="_hasFiles()">

    <ux-button *ngIf="!auto"
               [text]="uploadButtonText"
               [type]="'button'"
               [kind]="'primary'"
               [disabled]="!_hasUnuploadedFiles() || _hasUploadingFiles() || disabled"
               (onClick)="_uploadAllFiles()">
    </ux-button>

    <ux-button *ngIf="multiple && !hideList"
               [text]="clearButtonText"
               [type]="'button'"
               [kind]="'default'"
               [disabled]="disabled"
               (onClick)="_removeAllFiles()">
    </ux-button>
</div>
