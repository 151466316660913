import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {LoginService} from "./login.service";
import {LoginComponent} from "./components/login.component";
import {UxHeaderModule} from "../../../ux-lib/components/header/header.module";
import {UxLabelModule} from "../../../ux-lib/components/label/label.module";
import {UxTextFieldModule} from "../../../ux-lib/components/fields/text/text-field.module";
import {UxPasswordFieldModule} from "../../../ux-lib/components/fields/password/password-field.module";
import {UxButtonModule} from "../../../ux-lib/components/button/button.module";
import {UxTooltipModule} from "../../../ux-lib/components/tooltip/tooltip.module";
import {UxLinearDotsLoaderModule} from "../../../ux-lib/components/loader/linear-dots-loader/linear-dots-loader.module";
import {CryptoService} from "./crypto.service";
import {LoginRoutingModule} from "./login-routing.module";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    LoginRoutingModule,
    UxHeaderModule,
    UxLabelModule,
    UxTextFieldModule,
    UxPasswordFieldModule,
    UxButtonModule,
    UxTooltipModule,
    UxLinearDotsLoaderModule
  ],
  declarations: [
    LoginComponent
  ],
  entryComponents: [
    LoginComponent
  ],
  exports: [
    LoginComponent
  ],
  providers: [
    LoginService,
    CryptoService
  ]
})
export class LoginModule {
}
