import {
    AfterViewInit,
    Component,
    ContentChild,
    ElementRef,
    EventEmitter,
    HostBinding,
    Input,
    OnInit,
    Output,
    ViewChild
} from "@angular/core";
import * as moment from "moment";
import {MomentCalendar} from "../moment-calendar";
import {UxPopoverComponent} from "../../../popover/popover.component";
import {DateUtils} from "../date-utils";
import {UxSubmitValueChangeEvent} from "../../abstract-view-value-field.component";
import {UxRangeDateFieldModel, UxRangeDateFieldViewEventModel, UxRangeDateFieldPlaceholderModel} from "./range-date-field.model";
import {NG_VALUE_ACCESSOR} from "@angular/forms";
import {UxPropertyHandler} from "../../../../common/decorator/ux-property-handler";
import {UxPropertyConverter} from "../../../../common/decorator/ux-property-converter";


const DEFAULT_DATE_FORMAT: string = moment.localeData().longDateFormat("L");
const MOBILE_DATE_FORMAT: string = "YYYY-MM-DD";
const INVALID_DATE: string = "Invalid date";

@Component({
    selector: "ux-range-date-field",
    templateUrl: "./range-date-field.component.html",
    host: {"[class.ux-range-date-field]": "true"},
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: UxRangeDateFieldComponent,
            multi: true
        }
    ]
})
export class UxRangeDateFieldComponent implements OnInit, AfterViewInit {
    @HostBinding("class._focused")
    private focused: boolean;

    @HostBinding("class._disabled")
    @Input()
    public disabled: boolean;

    @Input()
    public placeholder: UxRangeDateFieldPlaceholderModel = {};

    @Input()
    public set value(value: UxRangeDateFieldModel) {
        if (!value) {
            value = {};
        }

        this._value = value;

        this.valueChange.emit(value);

        // user input mode
        if (this.focused && !this._visibleCalendar) {
            this.viewValueChange.emit({
                from: DateUtils.convertToDateFormat(value.from, this.dateFormat),
                to: DateUtils.convertToDateFormat(value.to, this.dateFormat)
            });
        } else {
            this.viewValue = {
                from: DateUtils.convertToDateFormat(value.from, this.dateFormat),
                to: DateUtils.convertToDateFormat(value.to, this.dateFormat)
            };
        }

        this.propagateChange && this.propagateChange(value);
        this.propagateChange && this.propagateTouch(value);
    }

    public get value(): UxRangeDateFieldModel {
        return this._value;
    }

    private _value: UxRangeDateFieldModel = {};

    @Input()
    public set viewValue(value: UxRangeDateFieldViewEventModel) {
        if (!value) {
            value = {};
        }

        if (this._fromViewValue !== value.from || this._toViewValue !== value.to) {
            this._viewValue = value;

            this._fromViewValue = value.from;
            this._toViewValue = value.to;

            this.viewValueChange.emit({
                from: value.from,
                to: value.to
            });

            this.value = Object.assign({}, this.value, {
                from: DateUtils.convertToDate(this._fromViewValue, this.dateFormat),
                to: DateUtils.convertToDate(this._toViewValue, this.dateFormat),
            });
        }
    }

    public get viewValue(): UxRangeDateFieldViewEventModel {
        return this._viewValue;
    }

    private _viewValue: UxRangeDateFieldViewEventModel = {};

    @Input()
    public set dateFormat(value: string) {
        if (value) {
            this._dateFormat = value;
        }
    }

    public get dateFormat(): string {
        if (this.mobile) {
            return MOBILE_DATE_FORMAT;
        } else {
            return this._dateFormat;
        }
    }

    @UxPropertyHandler({
        afterChange: afterChangeDateFormat
    })
    @UxPropertyConverter("custom", DEFAULT_DATE_FORMAT, {
        convert: convertDateFormat
    })
    private _dateFormat: string;

    @Input()
    @UxPropertyHandler({
        afterChange: updateMobileDateBoundaries
    })
    public beginAvailableDate: Date | string;

    @Input()
    @UxPropertyHandler({
        afterChange: updateMobileDateBoundaries
    })
    public endAvailableDate: Date | string;

    @Input()
    public attachToBody: boolean = false;

    @HostBinding("class._mobile")
    @Input()
    public mobile: boolean = false;

    @UxPropertyConverter("string", "")
    @Input()
    public autofocusInputSelector: string;


    @Output()
    public onBlur: EventEmitter<Event>  = new EventEmitter<Event>();

    @Output()
    public valueChange: EventEmitter<UxRangeDateFieldModel> = new EventEmitter<UxRangeDateFieldModel>();

    @Output()
    public viewValueChange: EventEmitter<UxRangeDateFieldViewEventModel> = new EventEmitter<UxRangeDateFieldViewEventModel>();

    @Output()
    public onFocus: EventEmitter<Event> = new EventEmitter<Event>();

    @Output()
    public onCalendarVisibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Output()
    public onSubmitValueChange: EventEmitter<UxSubmitValueChangeEvent> = new EventEmitter<UxSubmitValueChangeEvent>();

    @Output()
    public onKeyDown: EventEmitter<KeyboardEvent> = new EventEmitter<KeyboardEvent>();


    @ContentChild("customIcon", /* TODO: add static flag */ {static: true})
    private set customIcon(iconElement: ElementRef) {
        this._customIcon = !!iconElement;
    }

    @ViewChild("calendar", { static: true })
    private calendar: ElementRef;

    @ViewChild("inputRangeFrom", { static: true })
    private inputRangeFromRef: ElementRef;

    @ViewChild("inputRangeTo", { static: true })
    private inputRangeToRef: ElementRef;

    @ViewChild("contentWrapper", { static: true })
    private contentWrapperElement: ElementRef;

    @ViewChild("popover", { static: true })
    private popover: UxPopoverComponent;

    @ViewChild("calendarIcon", { static: true })
    private iconWrapperElement: ElementRef;


    /** @internal */
    public _visibleCalendar: boolean = false;

    /** @internal */
    public _customIcon: boolean = false;

    /** @internal */
    public _mobileDateBoundaries: {min: string, max: string} = {min: "", max: ""};

    @Input()
    public set invalidDateMessage(value: string) {
        if (value) {
            this._invalidDateMessage = value;
        }
    };

    public get invalidDateMessage(): string {
        return this._invalidDateMessage;
    };

    private _invalidDateMessage = INVALID_DATE;

    /** @internal */
    public _fromViewValue: string;

    /** @internal */
    public _toViewValue: string;

    private eventType: string = ("ontouchend" in document.documentElement) ? "touchend" : "mousedown";
    private calendarApi: MomentCalendar;
    private calendarInited: boolean = false;
    private element: HTMLElement;
    private inputRangeFromElement: HTMLElement;
    private inputRangeToElement: HTMLElement;

    constructor(
        private elementRef: ElementRef
    ) {}

    public ngOnInit(): void {
        this.element = this.elementRef.nativeElement;
        this.inputRangeFromElement = this.inputRangeFromRef.nativeElement;
        this.inputRangeToElement = this.inputRangeToRef.nativeElement;
    }

    public ngAfterViewInit(): void {
        if (this.autofocusInputSelector) {
            this.focus(this.autofocusInputSelector);
        }
    }

    public focus(inputSelector: string): void {
        if (inputSelector === "from") {
            setTimeout(() => {
                this.inputRangeFromElement.focus();
            }, 0);
        } else if (inputSelector === "to") {
            setTimeout(() => {
                this.inputRangeToElement.focus();
            }, 0);
        }
    }

    public propagateChange: Function;
    public propagateTouch: Function;

    public registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }

    public registerOnTouched(fn: any): void {
        this.propagateTouch = fn;
    }

    public writeValue(value: any): void {
        this.value = value;
    }

    /** @internal */
    public _onBlur(event: Event): void {
        this.focused = false;
        this.onBlur.emit(event);
    }

    /** @internal */
    public _onRangeFromBlur(event: Event): void {
        this._onBlur(event);
    }

    /** @internal */
    public _onRangeToBlur(event: Event): void {
        this._onBlur(event);
    }

    /** @internal */
    public _onFocus(event: Event): void {
        this.focused = true;
        this.onFocus.emit(event);
    }

    /** @internal */
    public _onRangeToFocus(event: Event): void {
        this._onFocus(event);
    }

    /** @internal */
    public _onRangeFromFocus(event: Event): void {
        this._onFocus(event);
    }

    /** @internal */
    public _onClosePopover(): void {
        this.focused = true;
        this._visibleCalendar = false;
        this.onCalendarVisibleChange.emit(this._visibleCalendar);
    }

    /** @internal */
    public _calendarIconClick(): void {
        let self = this;

        self.popover.attachToEl = self.iconWrapperElement.nativeElement;
        self.popover.containerEl = self.attachToBody ? document.body : self.iconWrapperElement.nativeElement;
        self.popover.disablePositionUpdateOnScroll = !self.attachToBody;

        if (!self.disabled) {
            if (self.calendar && !self.calendarInited) {
                self.calendarInited = true;
                self.calendarApi = self.createMomentCalendar();
            }

            if (!self._visibleCalendar) {
                // init moment calendar with new date
                self.initCalendarWithNewDate();
            }

            self.bindEvents();
            self._visibleCalendar = !self._visibleCalendar;
            this.focused = self._visibleCalendar;
            self.onCalendarVisibleChange.emit(self._visibleCalendar);
        }
    }

    private mobileDate(date: Date | string): string {
        if (typeof date === "string") {
            let dateTmp = DateUtils.convertToDate(date, this._dateFormat);
            return DateUtils.convertToDateFormat(dateTmp, MOBILE_DATE_FORMAT);
        } else {
            return DateUtils.convertToDateFormat(date, MOBILE_DATE_FORMAT);
        }
    }

    private createMomentCalendar(): MomentCalendar {
        let self = this;
        return new MomentCalendar(self.calendar.nativeElement, {
            clickableDays: true,
            controlsPanel: true,
            onDayClick: function(element: HTMLElement, date: Date): void {
                if (self.value) {
                    if (!self.value.from) {

                        if (self.value.to) {
                            if (date > self.value.to) {
                                self.value.from = self.value.to;
                                self.value.to = date;
                                element.classList.add("_range");
                                self._visibleCalendar = false;
                                self.focused = false;
                                self.onCalendarVisibleChange.emit(self._visibleCalendar);
                            } else {
                                self.value.from = date;
                                element.classList.add("_range");
                                self._visibleCalendar = false;
                                self.focused = false;
                                self.onCalendarVisibleChange.emit(self._visibleCalendar);
                            }
                        } else {
                            self.value.from = date;
                            self.value.to = null;
                            element.classList.add("_range");
                            self.setFromValueInCalendarInfoPanel(element);
                        }

                    } else if (self.value.from && !self.value.to) {

                        if (date < self.value.from) {
                            self.value.to = self.value.from;
                            self.value.from = date;
                        } else {
                            self.value.to = date;
                        }

                        element.classList.add("_range");
                        self._visibleCalendar = false;
                        self.focused = false;
                        self.onCalendarVisibleChange.emit(self._visibleCalendar);

                    } else if (self.value.from && self.value.to) {
                        let rangeDays = self.calendar.nativeElement.querySelectorAll(".calendar__day");

                        for (let i = 0; i < rangeDays.length; i++) {
                            rangeDays[i].classList.remove("_range", "_range-middle");
                        }

                        self.value.from = date;
                        self.value.to = null;
                        self._toViewValue = "";
                        element.classList.add("_range");
                        self.setFromValueInCalendarInfoPanel(element);
                    }
                }

                self.value = Object.assign({}, self.value);
            },
            range: self.value
        });
    }

    private setFromValueInCalendarInfoPanel(element: HTMLElement): void {
        let calendarPanelCurrentInfoElement = this.calendar.nativeElement.querySelector(".calendar__panel-current-info"),
            calendarPanelCurrentInfoFromElement = this.calendar.nativeElement.querySelector(".calendar__panel-current-info-from");

        if (calendarPanelCurrentInfoFromElement && element) {
            if (this.value.from && element.dataset) {
                calendarPanelCurrentInfoFromElement.innerHTML = `${element.dataset.dayOfWeek.slice(0, 3)}, ${element.dataset.monthName.slice(0, 3)} ${element.dataset.dayNumber} -`;
            }
        }

        if (calendarPanelCurrentInfoElement) {
            calendarPanelCurrentInfoElement.innerHTML = "";
        }
    }

    private bindEvents(): void {
        let self = this;
        // noinspection JSUnusedLocalSymbols
        let cb = (e: Event) => {
            self._visibleCalendar = false;
            window.removeEventListener("scroll", cb);
            self.onCalendarVisibleChange.emit(self._visibleCalendar);
        };

        window.addEventListener("scroll", cb);

        // noinspection JSUnusedLocalSymbols
        let cbResize = (e: Event) => {
            self._visibleCalendar = false;
            window.removeEventListener("resize", cbResize);
            self.onCalendarVisibleChange.emit(self._visibleCalendar);
        };

        window.addEventListener("resize", cbResize);

        let cbClick = (e: Event) => {
            if (!(<HTMLElement>e.target).classList.contains("defaultInputCalendarWrapper")
                && !self.contentWrapperElement.nativeElement.contains(e.target)
                && !self.calendar.nativeElement.contains(e.target)
            ) {
                self._visibleCalendar = false;
                self.focused = false;
                document.removeEventListener(self.eventType, cbClick);
                document.removeEventListener("mousedown", cbClick);
                self.onCalendarVisibleChange.emit(self._visibleCalendar);
            }
        };

        if (!self._visibleCalendar) {
            document.addEventListener(self.eventType, cbClick);
        }

        if (self.eventType === "touchend") {
            document.addEventListener("mousedown", cbClick);
        }
    }

    /** @internal */
    public _onRangeFromChange(value: string): void {
        this.value.from = DateUtils.convertToDate(value, this.dateFormat);
        this.value = Object.assign({}, this.value);
    }

    /** @internal */
    public _onRangeToChange(value: string): void {
        this.value.to = DateUtils.convertToDate(value, this.dateFormat);
        this.value = Object.assign({}, this.value);
    }

    private updateViewValue(): void {
        this.viewValue = {
            from: this._toViewValue,
            to: this._toViewValue
        }
    }

    private initCalendarWithNewDate(): void {
        const self = this;
        let year, month, setClassToDate, dateValue;

        if (self.value) {
            if (!setClassToDate) {
                setClassToDate = {};
            }

            self.setRangeClasses(setClassToDate);

            if (self.value.from) {
                dateValue = self.value.from && self.value.from.toDateString && self.value.from.toDateString() !== INVALID_DATE ? self.value.from : new Date();

                year = dateValue.getFullYear();
                month = dateValue.getMonth();
            }

        }

        if (self.calendarApi) {
            self.calendarApi.refresh({
                setClassToDate: setClassToDate,
                month: month,
                year: year,
                beginAvailableDate: DateUtils.convertToDate(self.beginAvailableDate, self.dateFormat),
                endAvailableDate: DateUtils.convertToDate(self.endAvailableDate, self.dateFormat),
                range: self.value
            });
        }
    }

    private setRangeClasses(setClassToDate: Object): void {

        if (this.value.from && this.value.to && this.value.from > this.value.to) {
            this.value.from = this.value.to;
            this.value = Object.assign({}, this.value);
        }

        if (this.value.from) {
            setClassToDate[DateUtils.convertToCalendarModel(this.value.from)] = "_range";
        }

        if (this.value.from && this.value.to) {
            let start = new Date(this.value.from),
                end = this.value.to;

            while (start < end) {
                let newDate = start.setDate(start.getDate() + 1);
                start = new Date(newDate);
                setClassToDate[DateUtils.convertToCalendarModel(start)] = "_range-middle";
            }
        }

        if (this.value.to) {
            setClassToDate[DateUtils.convertToCalendarModel(this.value.to)] = "_range";
        }
    }

    /** @internal */
    public _onRangeFromKeyDown(event: KeyboardEvent): void {
        this.onKeyDown.emit(event);
    }

    /** @internal */
    public _onKeyDown(event: KeyboardEvent): void {
        if (this.onKeyDown) {
            this.onKeyDown.emit(event);
        }
    }

    /** @internal */
    public _onRangeToKeyDown(event: KeyboardEvent): void {
        this.onKeyDown.emit(event);
    }

    /** @internal */
    public _onRangeFromSubmitValueChange(event: Event): void {

        let fromDate = DateUtils.convertToDateFormat(this.value.from, this.dateFormat);

        if (fromDate === INVALID_DATE) {
            this._fromViewValue = this.invalidDateMessage;
        } else {
            this._fromViewValue = fromDate;
        }

        this.value = Object.assign({}, this.value);

        this.onSubmitValueChange.emit({
            value: this.value,
            originalEvent: event
        });
    }

    /** @internal */
    public _onRangeToSubmitValueChange(event: Event): void {
        let toDate = DateUtils.convertToDateFormat(this.value.to, this.dateFormat);

        if (toDate === INVALID_DATE) {
            this._toViewValue = this.invalidDateMessage;
        } else {
            this._toViewValue = toDate;
        }

        this.value = Object.assign({}, this.value);

        this.onSubmitValueChange.emit({
            value: this.value,
            originalEvent: event
        });
    }

    /** @internal */
    public _onSeparatorTap(event: Event): void {
        let inputElement = this.inputRangeFromRef.nativeElement;

        if (inputElement.createTextRange) {
            let part = inputElement.createTextRange();
            part.move("character", 0);
            part.select();
        } else if (inputElement.setSelectionRange) {
            inputElement.setSelectionRange(0, 0);
        }

        inputElement.focus();
    }

}


/*Helpers*/
export function convertDateFormat(value: any): any {
    return value ? value : DEFAULT_DATE_FORMAT;
}

export function afterChangeDateFormat(newValue: string, oldValue: string): void {
    let self = this;

    self.dateFormat = newValue;
    self.value = Object.assign({}, this.value);
}

export function updateMobileDateBoundaries(): void {
    let self = this;

    self._mobileDateBoundaries = {
        min: self.mobileDate(self.beginAvailableDate),
        max: self.mobileDate(self.endAvailableDate),
    };
}
