import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output
} from "@angular/core";
import {FormBuilder, FormGroup, ValidationErrors, Validators} from "@angular/forms";
import {Subject, Subscription} from "rxjs";
import {takeUntil, tap} from "rxjs/operators";
import {ModelService} from "../../../common/services/model.service";
import {PopupComponent} from "../../../components/base/popup.component";
import {TestEntity, UpdateTestEntity} from "../../../common/models/entity/test-entity.model";
import {TestsService} from "../tests.service";
import {PostOperationResult, PutOperationResult} from "../../../common/models/result.type";

@Component({
  selector: "sq-test-folder-edit",
  templateUrl: "test-folder-edit.component.html",
  host: {"[class.sq-test-folder-edit]": "true"}
})
export class TestFolderEditComponent extends PopupComponent<TestEntity> {

  _formGroup: FormGroup;
  _operationErrorText: string = "";

  private stopSubscription$ = new Subject<boolean>();
  private folderCreateSubscription$: Subscription;
  private folderUpdateSubscription$: Subscription;

  _creatingMode: boolean = false;
  @Input()
  public set creatingMode(value: boolean) {
    this._creatingMode = value;
    if (this._creatingMode) {
      this.testModel = undefined;
    }
  }

  public get creatingMode(): boolean {
    return this._creatingMode;
  }

  private _testID: number|string;
  _testModel: TestEntity = new TestEntity();
  @Input()
  public set testModel(value: TestEntity) {
    if (value) {
      this._testID = value.id;
      this._testModel.copyFrom(value);

      this._formGroup.patchValue({
        "testFolderName": this.testModel.name
      });
    }
    else {
      this._testModel = new TestEntity();
      this._testModel.command = 'folder';
      this.resetFormData();
    }
  }

  public get testModel(): TestEntity {
    return this._testModel;
  }

  @Input()
  public parentEntity: TestEntity;

  @Output()
  public onCreateComplete: EventEmitter<TestEntity> = new EventEmitter<TestEntity>();

  @Output()
  public onEditComplete: EventEmitter<UpdateTestEntity> = new EventEmitter<UpdateTestEntity>();

  constructor(private testService: TestsService,
              private modelService: ModelService,
              private formBuilder: FormBuilder,
              private cdRef: ChangeDetectorRef) {
    super();
  }

  public initComponent() {
    super.initComponent();
    this.initForm();
  }

  public loadInitialData() {
    super.loadInitialData();
    this.cdRef.detectChanges();
  }

  public destroyComponent() {
    this.stopSubscription$.next(true);
    this.stopSubscription$.complete();
  }

  public getUserData(): TestEntity {
    return this._testModel;
  }

  public _getErrorText(controlName: string): string {
    let errorText = "",
      control = this._formGroup.controls[controlName];

    if (control.errors) {
      errorText = "!";
      let errors: ValidationErrors = control.errors;

      if (controlName === "testFolderName") {
        if (errors["required"] !== undefined) {
          errorText = "Folder name is required";
        }
      }
    }
    return errorText;
  }

  _onEditConfirm() {
    if (this.creatingMode) {
      if (this.parentEntity) {
        this._testModel.parent_id = this.parentEntity.id;
      }
      else {
        this._testModel.parent_id = 0;
      }
      this._testModel.command = 'folder';

      this.folderCreateSubscription$ && this.folderCreateSubscription$.unsubscribe();
      this.folderCreateSubscription$ = this.testService
        .createEntity(this._testModel)
        .pipe(
          takeUntil(this.stopSubscription$),
          tap((data: PostOperationResult<TestEntity>) => {
            if (data.opResult) {
              let createdTest = new TestEntity(data.opEntity.parent_id, data.opEntity.id);
              createdTest.copyFrom(data.opEntity);
              createdTest.children_count = 0;
              this.onCreateComplete.emit(createdTest);
              this._close();
            } else {
              this._operationErrorText = "Failed to create folder";
            }
          })
        )
        .subscribe()
    }
    else {
      this.folderUpdateSubscription$ && this.folderUpdateSubscription$.unsubscribe();
      this.folderUpdateSubscription$ = this.testService
        .updateEntityById(this._testID, this._testModel)
        .pipe(
          takeUntil(this.stopSubscription$),
          tap((data: PutOperationResult<TestEntity>) => {
            if (data.opResult) {
              let updateDevice: UpdateTestEntity = {
                entityID: this._testID,
                value: this._testModel
              }
              this.onEditComplete.emit(updateDevice);
              this._close();
            }
            else {
              this._operationErrorText = "Failed o update folder information";
            }
          })
        )
        .subscribe();
    }
  }

  protected _close() {
    this.parentEntity = undefined;
    this.resetFormData();
    super._close();
  }

  private initForm(): void {
    this._formGroup = this.formBuilder.group({
      "testFolderName": ["", Validators.compose([Validators.required])]
    });
  }

  private resetFormData() {
    this._formGroup.updateValueAndValidity();
    this._formGroup.reset();
    this._formGroup.markAsUntouched();
  }
}
