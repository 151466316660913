<div class="ux-header-button__inner"
     [ngClass]="_styleClasses">
    <i *ngIf="icon == 'search'"
       class="ux-header-button__icon _search taButton"
       name="search">
        <svg width="0" height="0" version="1.1" xmlns="http://www.w3.org/2000/svg"
             viewBox="2 2 16 16">
            <path d="M17.545,15.467l-3.779-3.779c0.57-0.935,0.898-2.035,0.898-3.21c0-3.417-2.961-6.377-6.378-6.377
		C4.869,2.1,2.1,4.87,2.1,8.287c0,3.416,2.961,6.377,6.377,6.377c1.137,0,2.2-0.309,3.115-0.844l3.799,3.801
		c0.372,0.371,0.975,0.371,1.346,0l0.943-0.943C18.051,16.307,17.916,15.838,17.545,15.467z M4.004,8.287
		c0-2.366,1.917-4.283,4.282-4.283c2.366,0,4.474,2.107,4.474,4.474c0,2.365-1.918,4.283-4.283,4.283
		C6.111,12.76,4.004,10.652,4.004,8.287z"></path>
        </svg>
    </i>
    <i *ngIf="icon == 'close'"
       class="ux-header-button__icon taButton"
       name="close">
        <svg width="0" height="0" version="1.1" xmlns="http://www.w3.org/2000/svg"
             viewBox="0 0 20 20">
            <path d="M14.348,14.849c-0.469,0.469-1.229,0.469-1.697,0L10,11.819l-2.651,3.029c-0.469,0.469-1.229,0.469-1.697,0
		c-0.469-0.469-0.469-1.229,0-1.697l2.758-3.15L5.651,6.849c-0.469-0.469-0.469-1.228,0-1.697c0.469-0.469,1.228-0.469,1.697,0
		L10,8.183l2.651-3.031c0.469-0.469,1.228-0.469,1.697,0c0.469,0.469,0.469,1.229,0,1.697l-2.758,3.152l2.758,3.15
		C14.817,13.62,14.817,14.38,14.348,14.849z"></path>
        </svg>
    </i>
    <i *ngIf="icon == 'plus'"
       class="ux-header-button__icon _plus taButton"
       name="plus">
        <svg width="0" height="0" version="1.1" xmlns="http://www.w3.org/2000/svg"
             viewBox="0 0 20 20">
            <path d="M16,10c0,0.553-0.048,1-0.601,1H11v4.399C11,15.951,10.553,16,10,16c-0.553,0-1-0.049-1-0.601V11H4.601
		C4.049,11,4,10.553,4,10c0-0.553,0.049-1,0.601-1H9V4.601C9,4.048,9.447,4,10,4c0.553,0,1,0.048,1,0.601V9h4.399
		C15.952,9,16,9.447,16,10z"></path>
        </svg>
    </i>
    <i *ngIf="icon == 'bell'"
       class="ux-header-button__icon _bell taButton"
       name="bell">
        <svg width="0" height="0" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 1 16 15">
            <path
                d="M12.6500271,7.36499351 C10.3700271,2.45799351 9.18402715,0.593993512 5.45902715,0.671993512 C4.13202715,0.698993512 4.45002715,-0.290006488 3.43802715,0.0849935124 C2.42802715,0.459993512 3.29502715,1.00899351 2.26102715,1.85799351 C-0.640972853,4.24099351 -0.373972853,6.44499351 0.972027147,11.6979935 C1.53902715,13.9109935 -0.394972853,14.0189935 0.370027147,16.1629935 C0.929027147,17.7269935 5.04902715,18.3819935 9.39502715,16.7699935 C13.7420271,15.1569935 16.4810271,11.9559935 15.9220271,10.3919935 C15.1570271,8.24699351 13.6110271,9.43099351 12.6500271,7.36499351 Z M8.92402715,15.4479935 C5.04202715,16.8879935 1.85202715,16.0419935 1.71702715,15.6649935 C1.48502715,15.0149935 2.97002715,12.8489935 7.40802715,11.2019935 C11.8460271,9.55499351 14.3230271,10.1659935 14.5820271,10.8909935 C14.7350271,11.3199935 12.8070271,14.0069935 8.92402715,15.4479935 Z M7.67602715,11.9539935 C5.64702715,12.7069935 4.23702715,13.5679935 3.32302715,14.3429935 C3.96602715,14.9269935 5.17002715,15.0689935 6.36902715,14.6239935 C7.89602715,14.0589935 8.83502715,12.7579935 8.46402715,11.7199935 C8.45902715,11.7069935 8.45302715,11.6969935 8.44802715,11.6839935 C8.19702715,11.7659935 7.94002715,11.8549935 7.67602715,11.9539935 Z"></path>
        </svg>
    </i>
    <i *ngIf="icon == 'active-bell'"
       class="ux-header-button__icon _active-bell taButton"
       name="active-bell">
        <svg width="0" height="0" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 18">
            <path
                d="M6.64997196,0.724012488 C6.64134389,0.85030873 6.63697455,0.977769853 6.63697455,1.10625094 C6.63697455,4.16107432 9.11319664,6.63750548 12.1677868,6.63750548 C12.2139138,6.63750548 12.2598195,6.63694129 12.3056147,6.63582398 C12.416784,6.87059254 12.5302763,7.11314912 12.6467552,7.36364857 C13.116653,8.37408709 13.726591,8.60698609 14.316618,8.83228515 C14.9327505,9.0675626 15.5272022,9.29455422 15.9178988,10.3900966 C16.4767321,11.9538155 13.7384269,15.1541993 9.39255797,16.7668919 C5.04769564,18.3785889 0.928784693,17.723799 0.369930403,16.1600027 C-0.0206084378,15.0653674 0.292458103,14.5016219 0.616899976,13.9173002 C0.927805739,13.357316 1.24915589,12.7786361 0.971774591,11.6958378 C-0.373879781,6.44381201 -0.640798994,4.24021551 2.26043173,1.85766081 C2.77191019,1.43766159 2.81811459,1.09108535 2.8565648,0.802690159 C2.89583357,0.50821723 2.92700523,0.274395613 3.4371231,0.084975589 C3.94855731,-0.104545215 4.1202116,0.0543973758 4.33628938,0.254451016 C4.54766596,0.450166714 4.80155237,0.6852218 5.4575952,0.671869352 C5.8869411,0.662881063 6.28253797,0.679701608 6.64997196,0.724012488 Z M1.7165759,15.6620791 C1.85153878,16.0390894 5.04070469,16.8849289 8.92167567,15.4451433 C12.803609,14.0044728 14.7312077,11.3179424 14.5782254,10.8890047 C14.3192728,10.1641338 11.8429069,9.553251 7.4060893,11.1999055 C2.96924957,12.8466706 1.48463575,15.0122674 1.7165759,15.6620791 Z M3.32214858,14.3403305 C4.2359162,13.5655124 5.6455322,12.704628 7.67400185,11.9518242 C7.93793221,11.8528148 8.19488269,11.7638722 8.44579352,11.681899 C8.4508155,11.6948421 8.45679984,11.704909 8.46179969,11.7178521 C8.83270702,12.7556261 7.89395119,14.0564666 6.36735851,14.6213183 C5.16866557,15.0662524 3.96499489,14.9242098 3.32214858,14.3403305 Z"></path>
        </svg>
    </i>
    <i *ngIf="icon == 'language'"
       class="ux-header-button__icon _language taButton"
       name="language">
        <svg width="0" height="0" version="1.1" xmlns="http://www.w3.org/2000/svg"
             viewBox="0 2 20 15">
            <path d="M19.753,10.909c-0.624-1.707-2.366-2.726-4.661-2.726c-0.09,0-0.176,0.002-0.262,0.006l-0.016-2.063
	    c0,0,3.41-0.588,3.525-0.607s0.133-0.119,0.109-0.231c-0.023-0.111-0.167-0.883-0.188-0.976c-0.027-0.131-0.102-0.127-0.207-0.109
	    c-0.104,0.018-3.25,0.461-3.25,0.461s-0.012-1.953-0.013-2.078c-0.001-0.125-0.069-0.158-0.194-0.156
	    c-0.125,0.002-0.92,0.014-1.025,0.016c-0.105,0.002-0.164,0.049-0.162,0.148c0.002,0.1,0.033,2.307,0.033,2.307
	    s-3.061,0.527-3.144,0.543c-0.084,0.014-0.17,0.053-0.151,0.143c0.019,0.09,0.19,1.094,0.208,1.172
	    c0.018,0.08,0.072,0.129,0.188,0.107c0.115-0.019,2.924-0.504,2.924-0.504l0.035,2.018c-1.077,0.281-1.801,0.824-2.256,1.303
	    c-0.768,0.807-1.207,1.887-1.207,2.963c0,1.586,0.971,2.529,2.328,2.695c3.162,0.387,5.119-3.06,5.769-4.715
	    c1.097,1.506,0.256,4.354-2.094,5.98c-0.043,0.029-0.098,0.129-0.033,0.207c0.065,0.078,0.541,0.662,0.619,0.756
	    c0.08,0.096,0.206,0.059,0.256,0.023C19.394,15.862,20.545,13.077,19.753,10.909z M12.367,14.097
	    c-0.966-0.121-0.944-0.914-0.944-1.453c0-0.773,0.327-1.58,0.876-2.156c0.335-0.354,0.75-0.621,1.229-0.799l0.082,4.277
	    C13.225,14.097,12.811,14.151,12.367,14.097z M14.794,13.544l0.046-4.109c0.084-0.004,0.166-0.01,0.252-0.01
	    c0.773,0,1.494,0.145,1.885,0.361C17.368,10.003,15.954,12.499,14.794,13.544z M5.844,5.876C5.814,5.782,5.741,5.731,5.648,5.731
	    h-1.95c-0.093,0-0.165,0.051-0.194,0.144c-0.412,1.299-3.48,10.99-3.496,11.041c-0.017,0.051-0.011,0.076,0.062,0.076h1.733
	    c0.075,0,0.099-0.023,0.114-0.072c0.015-0.051,1.008-3.318,1.008-3.318h3.496c0,0,0.992,3.268,1.008,3.318
	    c0.016,0.049,0.039,0.072,0.113,0.072h1.734c0.072,0,0.078-0.025,0.062-0.076C9.324,16.866,6.255,7.175,5.844,5.876z M3.226,12.194
	    l1.447-5.25l1.447,5.25H3.226z"></path>
        </svg>
    </i>
    <i *ngIf="icon == 'location'"
       class="ux-header-button__icon _location taButton"
       name="location">
        <svg width="0" height="0" version="1.1" xmlns="http://www.w3.org/2000/svg"
             viewBox="0 1 19 18">
            <path d="M19.367,18.102L18,14h-1.5l0.833,4H2.667L3.5,14H2l-1.368,4.102C0.285,19.146,0.9,20,2,20h16
		C19.1,20,19.715,19.146,19.367,18.102z M15,5c0-2.761-2.238-5-5-5S5,2.239,5,5c0,4.775,5,10,5,10S15,9.775,15,5z M7.3,5.06
		c0-1.491,1.208-2.699,2.7-2.699s2.7,1.208,2.7,2.699c0,1.492-1.209,2.7-2.7,2.7S7.3,6.551,7.3,5.06z"></path>
        </svg>
    </i>
    <i *ngIf="icon == 'user'"
       class="ux-header-button__icon _user taButton"
       name="user">
        <svg width="0" height="0" version="1.1" xmlns="http://www.w3.org/2000/svg"
             viewBox="1 1 19 19">
            <path d="M7.725,2.146c-1.016,0.756-1.289,1.953-1.239,2.59C6.55,5.515,6.708,6.529,6.708,6.529s-0.313,0.17-0.313,0.854
		C6.504,9.1,7.078,8.359,7.196,9.112c0.284,1.814,0.933,1.491,0.933,2.481c0,1.649-0.68,2.42-2.803,3.334C3.196,15.845,1,17,1,19v1
		h18v-1c0-2-2.197-3.155-4.328-4.072c-2.123-0.914-2.801-1.684-2.801-3.334c0-0.99,0.647-0.667,0.932-2.481
		c0.119-0.753,0.692-0.012,0.803-1.729c0-0.684-0.314-0.854-0.314-0.854s0.158-1.014,0.221-1.793c0.065-0.817-0.398-2.561-2.3-3.096
		c-0.333-0.34-0.558-0.881,0.466-1.424C9.439,0.112,8.918,1.284,7.725,2.146z"></path>
        </svg>
    </i>
</div>
