<ng-container *ngIf="compact; else commonView">
  <ux-dropdown-field [items]=items
                     [(value)]="value"
                     (onValueChange)="onValueChange.emit($event)"
                     [itemLabelKey]="'label'"
                     [placeholder]="placeholder"
                     [disabled]="false"
                     [opened]="false"
                     [mobile]="false"
  >
  </ux-dropdown-field>
</ng-container>
<ng-template #commonView>
  <ul class="ux-switch-selector-field__items">
    <li class="ux-switch-selector-field__item"
        *ngFor="let item of items; let i=index; trackBy: _trackByFn"
        [ngClass]="{'selected': (value && (value.id === item.id))}"
        (tap)="_onButtonClicked(item)"
    >
      <div class="ux-switch-selector-field__item-content">
        {{item.name}}
      </div>
    </li>
  </ul>
</ng-template>
