<div
   #contentWrapper
   class="ux-range-date-field__content-wrapper"
   [ngClass]="{ '_visible': _visibleCalendar }"
   tabindex="0"
>
    <div class="ux-range-date-field__calendar-wrapper" #calendarIcon>
        <div
            name="Calendar"
            class="ux-range-date-field__calendar-icon-wrapper"
            [ngClass]="{_visible: _visibleCalendar}"
            (tap)="_calendarIconClick()"
        >

            <i *ngIf="_customIcon" class="ux-range-date-field__icon">
                <ng-content select="svg"></ng-content>
            </i>
            <i *ngIf="!_customIcon" class="ux-range-date-field__icon">
                <svg width="0" height="0"
                    #customIcon
                    version="1.1" xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    class="ux-range-date-field__icon-svg"
                >
                    <path d="M17,3h-1v2h-3V3H7v2H4V3H3C1.899,3,1,3.9,1,5v12c0,1.1,0.899,2,2,2h14c1.1,0,2-0.9,2-2V5C19,3.9,18.1,3,17,3z M17,17H3V9h14
	                    V17z M6.5,1h-2v3.5h2V1z M15.5,1h-2v3.5h2V1z"
                    >
                    </path>
                </svg>
            </i>
        </div>
    </div>

    <div class="ux-range-date-field__range">
        <input
            #inputRangeFrom
            class="ux-range-date-field__input _left _date taTextValue taEditElement taValue"
            [attr.placeholder]="placeholder?.from || value?.fromInputPlaceholder"
            [attr.name]="value?.fromInputName"
            [disabled]="disabled"
            [readonly]=" _visibleCalendar"
            [(ngModel)]="_fromViewValue"
            (ngModelChange)="_onRangeFromChange($event)"
            (focus)="_onRangeFromFocus($event)"
            (blur)="_onRangeFromBlur($event)"
            (change)="_onRangeFromSubmitValueChange($event)"
            (keydown)="_onRangeFromKeyDown($event)"
            type="text"
        />

        <div class="ux-range-date-field__range-separator"
             (tap)="_onSeparatorTap($event)"
        >-</div>

        <input
            #inputRangeTo
            class="ux-range-date-field__input _right _date taTextValue taEditElement taValue"
            [attr.placeholder]="placeholder?.to || value?.toInputPlaceholder"
            [attr.name]="value?.toInputName"
            [disabled]="disabled"
            [readonly]=" _visibleCalendar"
            [(ngModel)]="_toViewValue"
            (ngModelChange)="_onRangeToChange($event)"
            (focus)="_onRangeToFocus($event)"
            (blur)="_onRangeToBlur($event)"
            (change)="_onRangeToSubmitValueChange($event)"
            (keydown)="_onRangeToKeyDown($event)"
            type="text"
        />
    </div>

</div>

<ux-popover
    #popover
    [visible]="_visibleCalendar"
    [closable]="true"
    (onClose)="_onClosePopover()"
    class="ux-range-date-field__calendar-popover _no-padding _calendar"
>
    <div class="ux-range-date-field__calendar" #calendar></div>
</ux-popover>
