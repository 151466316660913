import {Component, ContentChild, ElementRef, Input, ViewChild, AfterViewInit, TemplateRef} from "@angular/core";
import {UxAbstractBooleanFieldComponent} from "../abstract-boolean-field.component";
import {NG_VALUE_ACCESSOR} from "@angular/forms";
import {UxPropertyConverter} from "../../../common/decorator/ux-property-converter";

@Component({
    selector: "ux-checkbox-field",
    templateUrl: "./checkbox-field.component.html",
    host: {"[class.ux-checkbox-field]": "true"},
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: UxCheckboxFieldComponent,
            multi: true
        }
    ]
})
export class UxCheckboxFieldComponent extends UxAbstractBooleanFieldComponent implements AfterViewInit {

    @Input()
    public name: string;

    @Input()
    public set inputElementId(value: string) {
        this._inputElementId = value;
        this.setInputElelemntId();
    }

    public get inputElementId(): string {
        return this._inputElementId;
    }

    @Input()
    public template: TemplateRef<{
        disabled: boolean;
        label: string;
        value: boolean,
        name: string,
        inputElementId: string
    }>;

    @ViewChild("checkbox", { static: true })
    private checkboxRef: ElementRef;

    @ContentChild('customIcon', /* TODO: add static flag */ {static: true})
    private set customIcon(iconElement: ElementRef) {
        this._customIcon = !!iconElement;
    }

    /** @internal */
    public _customIcon: boolean = false;

    @UxPropertyConverter("string", "0")
    @Input()
    private public: string;

    private _inputElementId: string = "";

    public ngAfterViewInit(): void {
        this.setInputElelemntId();
    }

    private setInputElelemntId(): void {
        let self = this;

        if (self.checkboxRef && self.checkboxRef.nativeElement && self.inputElementId) {
            self.checkboxRef.nativeElement.id = self.inputElementId;
        }
    }
}
