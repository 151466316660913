import {Entity} from "./entity.model";

export abstract class HierarchyEntityModel extends Entity {
  public id?: number|string;
  public parent_id?: number|string;

  public children_count?: number;

  public constructor(parent_id?: number|string, id?: number|string) {
    super(id);
    this.parent_id = parent_id;
  }

  public getId(): number|string {
    return this.id;
  }

  public setId(value: number|string) {
    this.id = value;
  }

  public getIdField(): string {
    return "id";
  }

  public copyFrom(value: HierarchyEntityModel) {
    super.copyFrom(value);
    this.id = value.id;
    this.parent_id = value.parent_id;
    this.children_count = value.children_count;
  }
}

