import {Component, EventEmitter, Input, Output} from "@angular/core";

@Component({
  selector: "ux-attached-file",
  templateUrl: "./attached-file.component.html",
  host: {"[class.ux-attached-file]": "true"}
})
export class UxAttachedFileComponent {
  @Input()
  public attachedFile: string = "";

  @Output()
  public onRemove: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  public onFileClick: EventEmitter<string> = new EventEmitter<string>();

  _onFileClick(fileName: string): void {
    this.onFileClick.emit(fileName);
  }

  _removeFile(fileName: string): void {
    this.onRemove.emit(fileName);
  }
}
