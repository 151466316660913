<div class="sq-login__wrapper">
  <div class="sq-login__panel">
    <ux-header></ux-header>
    <div class="sq-login__panel-content">
      <ux-label [text]="'Username'">
        <ux-text-field #userName
                       [(value)]="_username"
                       [placeholder]="'Your user name'"
                       (onEnter)="_onEnter(userName, $event)"
        >
        </ux-text-field>
      </ux-label>
      <ux-label [text]="'Password'">
        <ux-password-field #password
                           [(value)]="_password"
                           [placeholder]="'Your password'"
                           (onEnter)="_onEnter(password, $event)"
        >
        </ux-password-field>
      </ux-label>
      <div class="sq-login__panel-content-error">
        {{_error}}
      </div>
    </div>
    <div class="sq-login__panel-footer">
      <ux-button *ngIf="!_authInProgress"
                 [text]="'SIGN IN'"
                 [kind]="'primary'"
                 (onClick)="authorize()"
      >
      </ux-button>
      <ux-linear-dots-loader *ngIf="_authInProgress"
                             [visible]="_authInProgress">
      </ux-linear-dots-loader>
    </div>
  </div>
</div>
