import {Injectable, Injector} from "@angular/core";
import {
  EntityService
  } from "../../common/services/entity.service";
import {DeviceEntity} from "../../common/models/entity/device-entity.model";
import {ApiPath} from "../../common/services/api.path";
import {Observable, Subscriber} from "rxjs";
import {FileItem} from "../../../ux-lib/components/attachment/attachment.component";
import {AttachFileResult, AudioService} from "../audio/audio.service";

@Injectable()
export class DevicesService extends EntityService<DeviceEntity> {
  protected readonly baseUrl: string = ApiPath.PHONES_PATH;
  protected readonly entityID: string = ApiPath.PHONE_ID;
  protected readonly entityUrl: string[] = [this.baseUrl + "/", "./json/device-by-id.json"];
  protected readonly entityCountUrl: string[] = [this.baseUrl + ApiPath.COUNT_PATH + "/", "./json/devices-count.json"];
  protected readonly entityCountPerParentUrl: string[] = [this.baseUrl + ApiPath.COUNT_PATH + "/", "./json/devices-count-per-parent.json"];
  protected readonly entityListUrl: string[] = [this.baseUrl + "/", "./json/devices-list.json"];
  protected readonly entityCreateUrl: string[] = [this.baseUrl + "/", "./json/device-create.json"];
  protected readonly entityUpdateUrl: string[] = [this.baseUrl + "/", "./json/device-create.json"];
  protected readonly entityDeleteUrl: string[] = [this.baseUrl + "/", "./json/device-create.json"];
  protected readonly upload_audio: string[] = [ApiPath.UPLOAD_AUDIO_PATH + "/", "./json/device-upload-audio.json"];

  protected audioService: AudioService;

  constructor(protected injector: Injector) {
    super(injector);
    this.audioService = this.injector.get(AudioService);
  }

  public attachReferenceAudio(deviceID: number|string, file: FileItem): Observable<AttachFileResult> {
    return this.audioService.uploadAudio(file, ApiPath.PHONE_ID, deviceID);
  }

  protected convertToObjects(entities: DeviceEntity[]): DeviceEntity[] {
    if (entities !== undefined && entities !== null) {
      return entities.map((value: DeviceEntity) => {
        let entity = new DeviceEntity(value.parent_id, value.id);
        entity.copyFrom(value);
        return entity;
      });
    }
    return entities;
  }
}
