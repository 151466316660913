import {AfterViewInit, Component, EventEmitter, HostBinding, Input, OnInit, Output} from "@angular/core";
import {UxCardTreeModel, UxCardTreeNodeModel} from "./card-tree.model";
import {UxValueChangeEvent} from "../fields/abstract-field.component";

@Component({
    selector: "ux-card-tree",
    templateUrl: "./card-tree.component.html",
    host: {"[class.ux-card-tree]": "true"}
})
export class UxCardTreeComponent implements OnInit, AfterViewInit {
    @Input()
    public set model(value: UxCardTreeModel | UxCardTreeNodeModel) {
        let self = this;

        self._model = value;

        if (self.isComponentInitiated && !self.innerTree) {
            self.rootModel = value as UxCardTreeModel;
            self.checkSelectedCards();
        }
    }

    public get model(): UxCardTreeModel | UxCardTreeNodeModel {
        return this._model;
    }

    /** @internal */
    public _model: UxCardTreeModel | UxCardTreeNodeModel = undefined;

    @Input()
    public selectAllEnabled: boolean;

    @Input()
    public selectAllCaption: string;

    @Input()
    public innerTree: boolean = false;

    @Input()
    public rootModel: UxCardTreeModel = undefined;

    @Input()
    public mobile: boolean = false;

    @Input()
    public level: number;

    @Input()
    public toggleDuration: number = 200;

    @Output()
    public onToggle: EventEmitter<UxCardTreeToggleEvent> = new EventEmitter<UxCardTreeToggleEvent>();

    @Output()
    public onChecked: EventEmitter<UxCardTreeCheckedEvent> = new EventEmitter<UxCardTreeCheckedEvent>();

    @Output()
    public onCheckedAll: EventEmitter<UxAllCardsTreeCheckedEvent> = new EventEmitter<UxAllCardsTreeCheckedEvent>();


    /** @internal */
    public _isAnimating: boolean = false;

    private isComponentInitiated: boolean = false;
    private viewInited: boolean = false;

    /**** Used in root element ***/

    _selectionAvailable: boolean;
    _allSelected: boolean;
    _allManual: boolean;

    private selectedModels: UxCardTreeNodeModel[] = [];

    /*******/

    public ngOnInit(): void {
        let self = this;

        self.isComponentInitiated = true;

        if (!self.innerTree) {
            self.rootModel = self._model as UxCardTreeModel;
            self.checkSelectedCards();
        }

        if (!this.level) {
            this.level = 0;
        }
    }

    public ngAfterViewInit(): void {
        this.viewInited = true;
    }

    /** @internal */
    public _trackByFn(index: number, item: UxCardTreeNodeModel): string {
        return item && item.id;
    }

    private nodeHasChildren(node: UxCardTreeModel | UxCardTreeNodeModel): boolean {
        return !!node && Array.isArray(node.children) && node.children.length > 0;
    }

    /** @internal */
    public _toggleNode(node: UxCardTreeNodeModel, event: Event): void {

        if (!this._isAnimating) {

            node.opened = !node.opened;

            this.onToggle.emit(<UxCardTreeToggleEvent>{
                originalEvent: event,
                model: node,
                selectedModels: this.selectedModels
            });
        }
    }

    /** deprecated. Remove in 2.0.0 */
    public toggleNode(node: UxCardTreeNodeModel, event: Event): void {
        this._toggleNode(node, event);
    }

    public clearCheckedCards(): void {
        let self = this;
        self.selectedModels = [];
        self.rootModel && self.unselectSelectedCards(self.rootModel.children);
    }

    private unselectSelectedCards(models: UxCardTreeNodeModel[]): void {
        Array.isArray(models) && models.forEach((node: UxCardTreeNodeModel) => {
            if (node.selected === true) {
                node.manualOperation = true;
                node.selected = false;
            }
            node.children && this.unselectSelectedCards(node.children);
        });
    }

    private selectAllCards(models: UxCardTreeNodeModel[]): void {
      Array.isArray(models) && models.forEach((node: UxCardTreeNodeModel) => {
        if (node.selected === false) {
          node.manualOperation = true;
          node.selected = true;
        }
        node.children && this.selectAllCards(node.children);
      });
    }

    private _onCheckedAll(value: boolean) {
      let self = this;

      if (!self.viewInited) {
        return;
      }

      if (!self.innerTree) {// Root
        if (this._allManual) {
          this._allManual = false;
          return;
        }

        this._allSelected = value;

        if (value === true) {
          self.rootModel && self.selectAllCards(self.rootModel.children);
        }
        else {
          self.rootModel && self.unselectSelectedCards(self.rootModel.children);
        }
        self.rootModel && self.findSelectedCards(self.rootModel.children);
      }

      let emitEvent: UxAllCardsTreeCheckedEvent = {
        value: value,
        selectedModels: self.selectedModels
      }
      self.onCheckedAll.emit(emitEvent);
    }

    /** @internal */
    public _onChecked(event: UxCardTreeCheckedEvent): void {
        let self = this;

        if (!self.viewInited) {
            return;
        }

        if (!self.innerTree) {// Root

            if (event.model.manualOperation) {
              event.model.manualOperation = false;
              return;
            }

            event.model.selected = event.value;

            if (!event.value) {
              if (self._allSelected != event.value) {
                this._allManual = true;
                this._allSelected = false;
              }
            }
            else {
              let allSelected = self.isAllSelected(self.rootModel.children);
              if (self._allSelected != allSelected) {
                this._allManual = true;
                this._allSelected = allSelected;
              }
            }

            if (event.value) {
                if (self.selectedModels.indexOf(event.model) === -1) {
                    self.selectedModels.push(event.model);
                }
            } else {
                let index = self.selectedModels.indexOf(event.model);
                if (index >= 0) {
                    self.selectedModels.splice(index, 1);

                    if (self.selectedModels.length === 0) {
                    }
                }
            }

            event.selectedModels = self.selectedModels;
        }

        self.onChecked.emit(event);
    }

    private checkSelectedCards(): void {
        let self = this;
        self.selectedModels = [];
        self._allSelected = true;
        self._selectionAvailable = false;
        self.rootModel && self.findSelectedCards(self.rootModel.children);
        if (!self._selectionAvailable) {
          self._allSelected = false;
        }
    }

    private findSelectedCards(models: UxCardTreeNodeModel[]): void {
        Array.isArray(models) && models.forEach((node: UxCardTreeNodeModel) => {
            if (node.selected !== undefined) {
              this._selectionAvailable = true;
              if (!node.selected) {
                this._allSelected = false;
              }
            }
            node.selected && this.selectedModels.push(node);
            node.children && this.findSelectedCards(node.children);
        });
    }

    private isAllSelected(models: UxCardTreeNodeModel[]): boolean {
      if (Array.isArray(models)) {
        for (let i=0; i < models.length; i++) {
          let node: UxCardTreeNodeModel = models[i];
          if (!node.selected) {
            return false;
          }
          if (node.children) {
            let res = this.isAllSelected(node.children);
            if (!res) {
              return false;
            }
          }
        }
      }
      return true;
    }
}


export interface UxCardTreeToggleEvent {
    originalEvent: Event;
    model: UxCardTreeNodeModel;
}

export interface UxCardTreeCheckedEvent {
    value: boolean;
    model: UxCardTreeNodeModel;
    selectedModels?: UxCardTreeNodeModel[];
}

export interface UxAllCardsTreeCheckedEvent {
  value: boolean;
  selectedModels?: UxCardTreeNodeModel[];
}
