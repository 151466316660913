import {NgModule} from "@angular/core";
import {SharedModule} from "../../../shared/shared.module";
import {UxChartComponent} from "./highchart.component";

@NgModule({
  imports: [SharedModule],
  exports: [UxChartComponent],
  declarations: [UxChartComponent]
})
export class UxChartModule {
}
