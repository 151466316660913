import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output
} from "@angular/core";
import {FormBuilder, FormGroup, ValidationErrors, Validators} from "@angular/forms";
import {Subject, Subscription} from "rxjs";
import {ModelService} from "../../../common/services/model.service";
import {PopupComponent} from "../../../components/base/popup.component";
import {AudioEntity, UpdateAudioEntity} from "../../../common/models/entity/audio-entity.model";
import {AudioService} from "../audio.service";
import {takeUntil, tap} from "rxjs/operators";
import {PostOperationResult} from "../../../common/models/result.type";

@Component({
  selector: "sq-audio-edit",
  templateUrl: "audio-edit.component.html",
  host: {"[class.sq-audio-edit]": "true"}
})
export class AudioEditComponent extends PopupComponent<AudioEntity> {

  _formGroup: FormGroup;
  _operationErrorText: string = "";

  private stopSubscription$ = new Subject<boolean>();
  private updateSubscription$: Subscription;

  private _audioID: number|string;
  _audioModel: AudioEntity = new AudioEntity();
  @Input()
  public set audioModel(value: AudioEntity) {
    if (value) {
      this._audioID = value.audio_id;
      this._audioModel.copyFrom(value);

      this._formGroup.patchValue({
        "audioName": this.audioModel.name
      });
    }
    else {
      this._audioModel = new AudioEntity();
      this.resetFormData();
    }
  }

  public get audioModel(): AudioEntity {
    return this._audioModel;
  }

  @Output()
  public onEditComplete: EventEmitter<UpdateAudioEntity> = new EventEmitter<UpdateAudioEntity>();

  constructor(private audioService: AudioService,
              private modelService: ModelService,
              private formBuilder: FormBuilder,
              private cdRef: ChangeDetectorRef) {
    super();
  }

  public initComponent() {
    super.initComponent();
    this.initForm();
  }

  public loadInitialData() {
    super.loadInitialData();
    this.cdRef.detectChanges();
  }

  public destroyComponent() {
    this.stopSubscription$.next(true);
    this.stopSubscription$.complete();
  }

  public getUserData(): AudioEntity {
    return this._audioModel;
  }

  public _getErrorText(controlName: string): string {
    let errorText = "",
      control = this._formGroup.controls[controlName];

    if (control.errors) {
      errorText = "!";
      let errors: ValidationErrors = control.errors;

      if (controlName === "audioName") {
        if (errors["required"] !== undefined) {
          errorText = "Audio file name is required";
        }
      }
    }
    return errorText;
  }

  _onEditConfirm() {
    this.updateSubscription$ && this.updateSubscription$.unsubscribe();
    this.updateSubscription$ = this.audioService
      .updateAudio(this._audioID, this._audioModel)
      .pipe(
        takeUntil(this.stopSubscription$),
        tap((data: PostOperationResult<AudioEntity>) => {
          if (data.opResult) {
            let updateAudio: UpdateAudioEntity = {
              entityID: this._audioID,
              value: this._audioModel
            }
            this.onEditComplete.emit(updateAudio);
            this._close();
          }
          else {
            this._operationErrorText = "Failed o update audio information";
          }
        })
      )
      .subscribe();
  }

  protected _close() {
    this.resetFormData();
    super._close();
  }

  private initForm(): void {
    this._formGroup = this.formBuilder.group({
      "audioName": ["", Validators.compose([Validators.required])]
    });
  }

  private resetFormData() {
    this._formGroup.updateValueAndValidity();
    this._formGroup.reset();
    this._formGroup.markAsUntouched();
  }
}
