import {NgModule} from "@angular/core";
import {UxMenuComponent} from "./menu.component";
import {SharedModule} from "../../shared/shared.module";
import {UxScrollModule} from "../scroll/scroll.module";

export {MenuItem} from "./model/menu-item.model";
export {MenuItemChangeEvent} from "./model/menu-item-change-event.model";

@NgModule({
    imports: [SharedModule, UxScrollModule],
    exports: [UxMenuComponent],
    declarations: [UxMenuComponent]
})
export class UxMenuModule {
}