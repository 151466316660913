/**
 * @author makuo817
 * @since 01.01.1970 00:00
 */
import {NgModule} from "@angular/core";
import {SharedModule} from "../../shared/shared.module";
import {UxAttachmentComponent} from "./attachment.component";
import {UxButtonModule} from "../button/button.module";
import {UxScrollModule} from "../scroll/scroll.module";
import {UxDragDropModule} from "../dragdrop/dragdrop.module";

@NgModule({
    declarations: [UxAttachmentComponent],
    exports: [UxAttachmentComponent],
    imports: [
        SharedModule,
        UxButtonModule,
        UxScrollModule,
        UxDragDropModule
    ]
})
export class UxAttachmentModule {
}
