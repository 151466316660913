import {
  Component,
  Input,
  ViewChild
} from "@angular/core";
import {DeviceEntity} from "../../../common/models/entity/device-entity.model";
import {Subject, Subscription} from "rxjs";
import {takeUntil, tap} from "rxjs/operators";
import {ModelService} from "../../../common/services/model.service";
import {NotificationService} from "../../../common/services/notification.service";
import {TestEntity} from "../../../common/models/entity/test-entity.model";
import {TestCardModel} from "../../tests/model/test-card.model";
import {AppConfigService} from "../../../common/services/app-config.service";
import {LinkOperationResult, TestsService} from "../../tests/tests.service";
import {PagedComponent} from "../../../components/base/paged.component";
import {ConfirmPopupComponent} from "../../../components/confirm-popup/confirm-popup.component";
import * as moment from "moment";
import {Router} from "@angular/router";
import {Progress} from "../../../common/models/progress.model";
import {
  DeviceStats24Map,
  StatisticsDataEntry, StatisticsDataStyledVisualEntry,
  StatisticsEntityBase
} from "../../../common/models/entity/statistics-entity.model";
import {ReportRequestParametersModel} from "../../reports/model/report-request-parameters.model";
import {ReportsService} from "../../reports/reports.service";

@Component({
  selector: "sq-device-linked-test-list",
  templateUrl: "device-linked-test-list.component.html",
  host: {"[class.sq-device-linked-test-list]": "true"}
})
export class DeviceLinkedTestListComponent extends PagedComponent {
  _unlinkTestName: string = "";
  _tests: TestEntity[] = [];

  _testsStatisticsLoading: Progress = 'loading';
  private _testsFullStatistics: DeviceStats24Map = new Map();
  private _testsSummaryStatistics: Map<string, StatisticsEntityBase<StatisticsDataEntry>>;
  _testsSummaryVisualStatistics: Map<string, StatisticsEntityBase<StatisticsDataStyledVisualEntry>>;

  private stopSubscription$ = new Subject<boolean>();
  private testsListSubscription$: Subscription;
  private testUnlinkSubscription$: Subscription;
  private testsStatisticsSubscription$: Subscription;

  @ViewChild("unlinkTestConfirmPopup", { static: true })
  unlinkTestConfirmPopup: ConfirmPopupComponent<TestEntity>;

  private _device: DeviceEntity;
  @Input()
  public set device(value: DeviceEntity) {
    this._device = value;
    if (this._device && this.viewInitiated) {
      this.refresh();
    }
  }

  public get device(): DeviceEntity {
    return this._device;
  }

  constructor(private notificationService: NotificationService,
              private testsService: TestsService,
              private reportsService: ReportsService,
              private modelService: ModelService,
              protected configService: AppConfigService,
              private router: Router) {
    super(configService);
  }

  public destroyComponent() {
    this.stopSubscription$.next(true);
    this.stopSubscription$.complete();
  }

  public loadPageData(currentPage: number, pageSize: number): void {
    this.retrieveTestsList(currentPage, pageSize);
  }

  protected retrieveTestsList(currentPage: number, pageSize: number): void {
    if (this._device) {
      let deviceID = this._device.id;
      this._pageLoadingStatus = 'loading';
      this.testsListSubscription$ && this.testsListSubscription$.unsubscribe();
      this.testsListSubscription$ = this.testsService
        .getLinkedTests(deviceID)
        .pipe(
          takeUntil(this.stopSubscription$),
          tap((tests: TestEntity[]) => {
            this.pagingConfig.totalCount = tests.length;
            this._pageLoadingStatus = 'loaded';

            let startIndex = (currentPage - 1) * pageSize;
            let endIndex = startIndex + pageSize;
            this._tests = tests.slice(startIndex, endIndex > tests.length ? tests.length : endIndex);

            this.retrieveTestsStatistics();
          })
        )
        .subscribe();
    }
  }

  private retrieveTestsStatistics() {
    this._testsStatisticsLoading = 'loading';

    let period: moment.unitOfTime.DurationConstructor = 'h';
    if (this.testsConfig.defaultPeriod === 'hour') {
      period = 'h';
    }
    else if (this.testsConfig.defaultPeriod === 'day') {
      period = 'd';
    }
    else if (this.testsConfig.defaultPeriod === 'month') {
      period = 'M';
    }
    let dateFrom: Date = moment().subtract(this.testsConfig.defaultDuration, period).toDate();
    let dateTo: Date = new Date();
    let testsIds: number[] = this._tests.map((test: TestEntity) => {
      return parseInt(test.id.toString());
    });
    let testsIdsStr: string[] = testsIds.map((val: number) => {return val.toString()})

    let reportParams: ReportRequestParametersModel = {
      startDate: dateFrom,
      endDate: dateTo,
      scale: this.testsConfig.defaultPeriod,
      united: false,
      phonesIds: [],
      testsIds: testsIds
    };

    this.testsStatisticsSubscription$ && this.testsStatisticsSubscription$.unsubscribe();
    this.testsStatisticsSubscription$ = this.reportsService
      .getTasksStatistics(reportParams)
      .pipe(
        takeUntil(this.stopSubscription$),
        tap((statistics: DeviceStats24Map) => {
          if (statistics) {
            this._testsFullStatistics = statistics;
          } else {
            this._testsFullStatistics = new Map();
          }

          this._testsSummaryStatistics = this.reportsService.convertSummaryStatisticsPerItem(testsIdsStr, this._testsFullStatistics);
          this._testsSummaryVisualStatistics = this.reportsService.calculateSummaryVisualStatisticsPerItem(this._testsSummaryStatistics);

          this._testsStatisticsLoading = 'loaded';
        })
      )
      .subscribe();

  }

  _onChartCard(testCard: TestCardModel): void {
    let period: moment.unitOfTime.DurationConstructor = 'h';
    if (this.devicesConfig.defaultPeriod === 'hour') {
      period = 'h';
    }
    else if (this.devicesConfig.defaultPeriod === 'day') {
      period = 'd';
    }
    else if (this.devicesConfig.defaultPeriod === 'month') {
      period = 'M';
    }
    let dateFrom: Date = moment().subtract(this.devicesConfig.defaultDuration, period).toDate();
    let dateTo: Date = new Date();

    this.router.navigate(['/reports/view'], {
      queryParams: {
        period: this.devicesConfig.defaultPeriod,
        from: dateFrom.getTime(),
        to: dateTo.getTime(),
        devices: [this._device.instance],
        tests: [testCard.originalEntity.name]
      }
    });
  }

  _onUnlinkCard(testCard: TestCardModel): void {
    this._unlinkTestName = testCard.originalEntity.name;
    this.unlinkTestConfirmPopup.userData = testCard.originalEntity;
    this.unlinkTestConfirmPopup.show();
  }

  _onUnlinkCancel(test: TestEntity): void {
    this._unlinkTestName = undefined;
  }

  _onUnlinkAccept(test: TestEntity): void {
    let unlinkTestID = test.id;
    let deviceID = this._device.id;

    this.testUnlinkSubscription$ && this.testUnlinkSubscription$.unsubscribe();
    this.testUnlinkSubscription$ = this.testsService
      .unlinkTestFromDevice(unlinkTestID, deviceID)
      .pipe(
        takeUntil(this.stopSubscription$),
        tap((unlinkResult: LinkOperationResult) => {
          if (unlinkResult.opResult) {
            //delete entity
            let deletedIndex: number = this.modelService.findInArray(this._tests,
              (item: TestEntity) => {
                return (item.id === unlinkTestID);
              });
            if (deletedIndex >= 0) {
              this._tests.splice(deletedIndex, 1);
            }
          }
          else {
            this.notificationService.pushNotification({
              type: "error",
              caption: "Unlink error",
              content: `Fail to unlink test ${unlinkResult.testID} from device ${unlinkResult.deviceID}: error: ${unlinkResult['__initialResponse'].status}`
            });
          }
        })
      )
      .subscribe();

    this._unlinkTestName = undefined;
  }
}
