<div class="ux-header__inner">

    <ux-header-group *ngIf="logo">
        <i class="ux-header__logo taButton"
           name="logo"
           (tap)="_logoClickHandler($event)">
        </i>
    </ux-header-group>

    <ux-route-tab-panel *ngIf="links && links.length"
                        class="_light-font"
                        [mobile]="mobile"
                        [items]="links">
    </ux-route-tab-panel>

    <ng-content></ng-content>
</div>
