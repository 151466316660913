import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output
} from "@angular/core";
import {DeviceEntity, UpdateDeviceEntity} from "../../../common/models/entity/device-entity.model";
import {FormBuilder, FormGroup, ValidationErrors, Validators} from "@angular/forms";
import {Subject, Subscription} from "rxjs";
import {takeUntil, tap} from "rxjs/operators";
import {DevicesService} from "../devices.service";
import {ModelService} from "../../../common/services/model.service";
import {PopupComponent} from "../../../components/base/popup.component";
import {PostOperationResult, PutOperationResult} from "../../../common/models/result.type";

@Component({
  selector: "sq-device-folder-edit",
  templateUrl: "device-folder-edit.component.html",
  host: {"[class.sq-device-folder-edit]": "true"}
})
export class DeviceFolderEditComponent extends PopupComponent<DeviceEntity> {

  _formGroup: FormGroup;
  _operationErrorText: string = "";

  private stopSubscription$ = new Subject<boolean>();
  private folderCreateSubscription$: Subscription;
  private folderUpdateSubscription$: Subscription;

  _creatingMode: boolean = false;
  @Input()
  public set creatingMode(value: boolean) {
    this._creatingMode = value;
    if (this._creatingMode) {
      this.deviceModel = undefined;
    }
  }

  public get creatingMode(): boolean {
    return this._creatingMode;
  }

  private _deviceID: number|string;
  _deviceModel: DeviceEntity = new DeviceEntity();
  @Input()
  public set deviceModel(value: DeviceEntity) {
    if (value) {
      this._deviceID = value.id;
      this._deviceModel.copyFrom(value);

      this._formGroup.patchValue({
        "deviceFolderName": this.deviceModel.instance
      });
    }
    else {
      this._deviceModel = new DeviceEntity();
      this._deviceModel.type = 'folder';
      this.resetFormData();
    }
  }

  public get deviceModel(): DeviceEntity {
    return this._deviceModel;
  }

  @Input()
  public parentEntity: DeviceEntity;

  @Output()
  public onCreateComplete: EventEmitter<DeviceEntity> = new EventEmitter<DeviceEntity>();

  @Output()
  public onEditComplete: EventEmitter<UpdateDeviceEntity> = new EventEmitter<UpdateDeviceEntity>();

  constructor(private devicesService: DevicesService,
              private modelService: ModelService,
              private formBuilder: FormBuilder,
              private cdRef: ChangeDetectorRef) {
    super();
  }

  public initComponent() {
    super.initComponent();
    this.initForm();
  }

  public loadInitialData() {
    super.loadInitialData();
    this.cdRef.detectChanges();
  }

  public destroyComponent() {
    this.stopSubscription$.next(true);
    this.stopSubscription$.complete();
  }

  public getUserData(): DeviceEntity {
    return this._deviceModel;
  }

  public _getErrorText(controlName: string): string {
    let errorText = "",
      control = this._formGroup.controls[controlName];

    if (control.errors) {
      errorText = "!";
      let errors: ValidationErrors = control.errors;

      if (controlName === "deviceFolderName") {
        if (errors["required"] !== undefined) {
          errorText = "Folder name is required";
        }
      }
    }
    return errorText;
  }

  _onEditConfirm() {
    if (this.creatingMode) {
      if (this.parentEntity) {
        this._deviceModel.parent_id = this.parentEntity.id;
      }
      else {
        this._deviceModel.parent_id = 0;
      }
      this._deviceModel.type = 'folder';

      this.folderCreateSubscription$ && this.folderCreateSubscription$.unsubscribe();
      this.folderCreateSubscription$ = this.devicesService
        .createEntity(this._deviceModel)
        .pipe(
          takeUntil(this.stopSubscription$),
          tap((data: PostOperationResult<DeviceEntity>) => {
            if (data.opResult) {
              let createdDevice = new DeviceEntity(data.opEntity.parent_id, data.opEntity.id);
              createdDevice.copyFrom(data.opEntity);
              this.onCreateComplete.emit(createdDevice);
              this._close();
            } else {
              this._operationErrorText = "Failed to create folder";
            }
          })
        )
        .subscribe()
    }
    else {
      this.folderUpdateSubscription$ && this.folderUpdateSubscription$.unsubscribe();
      this.folderUpdateSubscription$ = this.devicesService
        .updateEntityById(this._deviceID, this._deviceModel)
        .pipe(
          takeUntil(this.stopSubscription$),
          tap((data: PutOperationResult<DeviceEntity>) => {
            if (data.opResult) {
              let updateDevice: UpdateDeviceEntity = {
                entityID: this._deviceID,
                value: this._deviceModel
              }
              this.onEditComplete.emit(updateDevice);
              this._close();
            }
            else {
              this._operationErrorText = "Failed o update folder information";
            }
          })
        )
        .subscribe();
    }
  }

  protected _close() {
    this.parentEntity = undefined;
    this.resetFormData();
    super._close();
  }

  private initForm(): void {
    this._formGroup = this.formBuilder.group({
      "deviceFolderName": ["", Validators.compose([Validators.required])]
    });
  }

  private resetFormData() {
    this._formGroup.updateValueAndValidity();
    this._formGroup.reset();
    this._formGroup.markAsUntouched();
  }
}
