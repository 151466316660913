import { Component, ElementRef, HostBinding, Input, ViewChild, Directive } from "@angular/core";
import {UxAbstractFieldComponent, UxValueChangeEvent} from "./abstract-field.component";
import {convertToBoolean} from "../../common/decorator/ux-property-converter";

const STYLE_MODIFIERS = {
    active: "_active",
};

@Directive()
export abstract class UxAbstractBooleanFieldComponent extends UxAbstractFieldComponent<boolean> {

    @Input()
    public label: string;

    @HostBinding('class._active')
    private activeStyle: boolean;

    protected getDefaultValue(): boolean {
        return false;
    }

    protected getValueConverter(): { (value: any): boolean } {
        return convertToBoolean;
    }

    /** @internal */
    public _onValueChange(event: UxValueChangeEvent<boolean>): void {
        if (event.newValue !== event.oldValue) {
            this.activeStyle = this.value;
            super._onValueChange(event);
        }
    }

    /** @internal */
    public _toggleState(type?: string): void {

        if (this.disabled || (type === "boolean" && this.activeStyle)) {
            return;
        }
        this.value = !this.value;
    }
}
