import {NgModule} from "@angular/core";
import {SharedModule} from "../../../shared/shared.module";
import {UxCheckboxFieldComponent} from "./checkbox-field.component";

@NgModule({
    imports: [SharedModule],
    exports: [UxCheckboxFieldComponent],
    declarations: [UxCheckboxFieldComponent]
})
export class UxCheckboxFieldModule {}
