import {NgModule} from "@angular/core";
import {SharedModule} from "../../../../shared/shared.module";
import {UxRadioFieldComponent} from "./radio-field.component";

@NgModule({
    imports: [SharedModule],
    exports: [UxRadioFieldComponent],
    declarations: [UxRadioFieldComponent]
})
export class UxRadioFieldModule {
}
