import {
  AfterViewInit, Component, ContentChild, ElementRef, EventEmitter, Input, OnDestroy, Output,
  ViewChild
} from "@angular/core";
import {UxAbstractViewValueFieldComponent} from "../abstract-view-value-field.component";
import {UxValueChangeEvent} from "../abstract-field.component";
import {NG_VALUE_ACCESSOR} from "@angular/forms";
import {UxPropertyConverter} from "../../../common/decorator/ux-property-converter";

const INPUT_TYPES = {
    text: "text",
    password: "password"
};

@Component({
    selector: "ux-password-field",
    templateUrl: "./password-field.component.html",
    host: {"[class.ux-password-field]": "true"},
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: UxPasswordFieldComponent,
            multi: true
        }
    ]
})
export class UxPasswordFieldComponent extends UxAbstractViewValueFieldComponent<string, string> implements AfterViewInit, OnDestroy {

    @UxPropertyConverter("boolean", true)
    @Input()
    public showEyeIcon: boolean;

    @Input()
    public autofocus: boolean = false;

    @Output()
    public onEnter: EventEmitter<KeyboardEvent> = new EventEmitter<KeyboardEvent>();

    @ViewChild("passwordInput", { static: true })
    private passwordInputElementRef: ElementRef;

    @ContentChild("customIcon", /* TODO: add static flag */ {static: true})
    private set customIcon(iconElement: ElementRef) {
        this._customIcon = !!iconElement;
    }

    /** @internal */
    public _customIcon: boolean = false;

    public type: string = INPUT_TYPES.password;

    private mouseUpCallBack = () => {
        if (!this.disabled) {
            this.type = INPUT_TYPES.password;
        }

        document.removeEventListener("mouseup", this.mouseUpCallBack);
    };

    public ngAfterViewInit(): void {
        if (this.autofocus) {
            this.focus();
        }
    }

    public ngOnDestroy(): void {
        document.removeEventListener("mouseup", this.mouseUpCallBack);
    }

    public focus(): void {
        setTimeout(() => {
            this.passwordInputElementRef.nativeElement.focus();
        }, 0);
    }

    /** @internal */
    public _onUnmaskValue(): void {
        this.type = INPUT_TYPES.text;
        document.addEventListener("mouseup", this.mouseUpCallBack);
    }

    /** @internal */
    public _onMaskValue(): void {
        this.type = INPUT_TYPES.password;
    }

    public _onEnter(event: KeyboardEvent): void {
      if (this.onEnter) {
        this.onEnter.emit(event);
      }
    }

    protected getDefaultValue(): string {
        return "";
    }

    protected getValueConverter(): { (value: string): string } {
        return (value) => value;
    }

    /**
     * Will be called after value is changed.
     *
     * @internal
     */
    public _onValueChange(event: UxValueChangeEvent<string>): void {
        if (event.oldValue !== event.newValue && this.onValueChange) {
            let newValue: string = event.newValue as any;

            this.onValueChange.emit(event);
            this.valueChange.emit(newValue);
            this.propagateChange && this.propagateChange(newValue);
            this.propagateChange && this.propagateTouch(newValue);
        }
    }

    /**
     * Will be called to submit value by 'enter' key press or 'blur' event. View value will be propagated to value.
     * @private
     * @internal
     */
    public _onSubmitValueChange(event: Event): void {
        this.onSubmitValueChange.emit({
            value: this.value,
            originalEvent: event
        });
    }
}
