export class ApiPath {
  public static readonly PHONES = "phones";
  public static readonly PROBES = "probes";
  public static readonly TASKS = "tasks";
  public static readonly STATISTICS = "statistics";
  public static readonly STATISTICS_PHONES = "statistics_phones_24";
  public static readonly STATISTICS_TASKS = "statistics_tasks_24";
  public static readonly AUTH = "auth";

  public static readonly LINK_TASK = "add_task_2_phone";
  public static readonly UNLINK_TASK = "delete_task_from_phone";

  public static readonly UPLOAD_AUDIO = "upload_audio";
  public static readonly LIST_AUDIO = "list_audio";
  public static readonly UPDATE_AUDIO = "update_audio";
  public static readonly PLAY_AUDIO = "play_audio";
  public static readonly DELETE_AUDIO = "delete_audio";

  public static readonly AUDIO_NAME = "audio_name";
  public static readonly FILE = "file";

  public static readonly PHONE_ID = "phone_id";
  public static readonly TASK_ID = "task_id";
  public static readonly PROBE_ID = "probe_id";
  public static readonly PARENT_ID = "parent_id";
  public static readonly AUDIO_ID = "audio_id";

  public static readonly USERNAME = "username";
  public static readonly PASSHASH = "passhash";

  public static readonly OFFSET = "offset";
  public static readonly LIMIT = "limit";

  public static readonly DURATION = "duration";

  public static readonly COUNT = "count";

  public static readonly ORDER_COLUMN = "order_column";
  public static readonly ORDER_DIR = "order_dir";

  public static readonly START_DATE = "start_date";
  public static readonly END_DATE = "end_date";
  public static readonly SCALE = "scale";
  public static readonly UNITED = "united";

  public static readonly PARAM_START = "/?";
  public static readonly PARAM_DELIMITER = "&";

  public static readonly PHONES_PATH = "/" + ApiPath.PHONES;
  public static readonly PROBES_PATH = "/" + ApiPath.PROBES;
  public static readonly TASKS_PATH = "/" + ApiPath.TASKS;
  public static readonly STATISTICS_PATH = "/" + ApiPath.STATISTICS;
  public static readonly STATISTICS_PHONES_PATH = "/" + ApiPath.STATISTICS_PHONES;
  public static readonly STATISTICS_TASKS_PATH = "/" + ApiPath.STATISTICS_TASKS;
  public static readonly AUTH_PATH = "/" + ApiPath.AUTH;

  public static readonly LINK_TASK_PATH = "/" + ApiPath.LINK_TASK;
  public static readonly UNLINK_TASK_PATH = "/" + ApiPath.UNLINK_TASK;

  public static readonly COUNT_PATH = "/" + ApiPath.COUNT;

  public static readonly UPLOAD_AUDIO_PATH = "/" + ApiPath.UPLOAD_AUDIO;
  public static readonly LIST_AUDIO_PATH = "/" + ApiPath.LIST_AUDIO;
  public static readonly UPDATE_AUDIO_PATH = "/" + ApiPath.UPDATE_AUDIO;
  public static readonly DOWNLOAD_AUDIO_PATH = "/" + ApiPath.PLAY_AUDIO;
  public static readonly DELETE_AUDIO_PATH = "/" + ApiPath.DELETE_AUDIO;

  public static readonly PROBE_ID_PATH_VARIABLE = "{" + ApiPath.PROBE_ID + "}";

  public static readonly PROBE_ID_PATH_PART = ApiPath.PROBE_ID + "=" + ApiPath.PROBE_ID_PATH_VARIABLE;

  public static readonly PROBE_ID_PATH = ApiPath.PARAM_START + ApiPath.PROBE_ID_PATH_PART;
}
