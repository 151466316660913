import {Injectable, Injector} from "@angular/core";
import {TestEntity} from "../../common/models/entity/test-entity.model";
import {ApiPath} from "../../common/services/api.path";
import {Observable, Subscriber} from "rxjs";
import {HttpResponse} from "@angular/common/http";
import {FileItem} from "../../../ux-lib/components/attachment/attachment.component";
import {DictionaryType} from "../../common/models/entity/dictionary-type.model";
import {OperationResult} from "../../common/models/result.type";
import {EntityService} from "../../common/services/entity.service";
import {AttachFileResult, AudioService} from "../audio/audio.service";
import {DeviceEntity} from "../../common/models/entity/device-entity.model";
import {TestResultEntity} from "../../common/models/entity/test-result-entity.model";


export interface LinkOperationResult extends OperationResult {
  testID: number|string;
  deviceID: number|string;
}

export interface DeleteFileResult extends OperationResult {
  entityID: number|string;
  fileName: string;
}

@Injectable()
export class TestsService extends EntityService<TestEntity> {
  protected readonly baseUrl: string = ApiPath.TASKS_PATH;
  protected readonly entityID: string = ApiPath.TASK_ID;
  protected readonly entityUrl: string[] = [this.baseUrl + "/", "./json/task-by-id.json"];
  protected readonly entityCountUrl: string[] = [this.baseUrl + ApiPath.COUNT_PATH + "/", "./json/tasks-count.json"];
  protected readonly entityCountPerParentUrl: string[] = [this.baseUrl + ApiPath.COUNT_PATH + "/", "./json/tasks-count-per-parent.json"];
  protected readonly entityListUrl: string[] = [this.baseUrl + "/", "./json/tasks-list.json"];
  protected readonly entityCreateUrl: string[] = [this.baseUrl + "/", "./json/task-create.json"];
  protected readonly entityUpdateUrl: string[] = [this.baseUrl + "/", "./json/task-create.json"];
  protected readonly entityDeleteUrl: string[] = [this.baseUrl + "/", "./json/task-create.json"];
  protected readonly linkedTestsUrl: string[] =  [this.baseUrl + "/", "./json/tasks-linked-list.json"];
  protected readonly add_task_2_phone: string[] = [ApiPath.LINK_TASK_PATH + "/", "./json/add-task-to-phone.json"];
  protected readonly delete_task_from_phone: string[] = [ApiPath.UNLINK_TASK_PATH + "/", "./json/delete-task-from-phone.json"];
  protected readonly get_audio: string[] = [ApiPath.DOWNLOAD_AUDIO_PATH + "/", "./sample-data/sample.m4a"];
  protected readonly delete_audio: string[] = [ApiPath.DELETE_AUDIO_PATH + "/", "./json/test-delete-audio.json"];

  protected audioService: AudioService;

  constructor(protected injector: Injector) {
    super(injector);
    this.audioService = this.injector.get(AudioService);
  }

  protected convertToObjects(entities: TestEntity[]): TestEntity[] {
    if (entities !== undefined && entities !== null) {
      return entities.map((value: TestEntity) => {
        let entity = new TestEntity(value.parent_id, value.id);
        entity.copyFrom(value);
        return entity;
      });
    }
    return entities;
  }

  public getLinkedTests(deviceID: number|string): Observable<TestEntity[]> {
    let o = Observable.create((subscriber: Subscriber<TestEntity[]>) => {
      let queryParams = [{
        key: ApiPath.PHONE_ID,
        value: deviceID
      }];

      let get = this.get_collection(this.linkedTestsUrl, {
        queryParams: queryParams
      }).subscribe(async (items: TestEntity[]) => {
          let converted: TestEntity[] = this.convertToObjects(items);

          subscriber.next(converted);
          subscriber.complete();
        });

      return () => {
        get && get.unsubscribe();
      };
    });
    return o;
  }

  public linkTestToDevice(testID: number|string, deviceID: number|string): Observable<LinkOperationResult> {
    let o = Observable.create((subscriber: Subscriber<LinkOperationResult>) => {
      let queryParams = [
        {
          key: ApiPath.PHONE_ID,
          value: deviceID
        },
        {
          key: ApiPath.TASK_ID,
          value: testID
        }
      ];

      let get = this.get_string(this.add_task_2_phone, {
        queryParams: queryParams,
        dataExtracter: (res: HttpResponse<Object>) => this.linkOperationResultDataExtracter(res, testID, deviceID)
      }).subscribe(async (data: any) => {
        let opResult: LinkOperationResult = data as LinkOperationResult;
        subscriber.next(opResult);
        subscriber.complete();
      });
      return () => {
        get && get.unsubscribe();
      };
    });
    return o;
  }

  public unlinkTestFromDevice(testID: number|string, deviceID: number|string): Observable<LinkOperationResult> {
    let o = Observable.create((subscriber: Subscriber<LinkOperationResult>) => {
      let queryParams = [
        {
          key: ApiPath.PHONE_ID,
          value: deviceID
        },
        {
          key: ApiPath.TASK_ID,
          value: testID
        }
      ];

      let get = this.get_string(this.delete_task_from_phone, {
        queryParams: queryParams,
        dataExtracter: (res: HttpResponse<Object>) => this.linkOperationResultDataExtracter(res, testID, deviceID)
      }).subscribe(async (data: any) => {
        let opResult: LinkOperationResult = data as LinkOperationResult;
        subscriber.next(opResult);
        subscriber.complete();
      });
      return () => {
        get && get.unsubscribe();
      };
    });
    return o;
  }

  // public attachFileToTest(testID: string, file: FileItem): Observable<AttachFileResult> {
  //   return this.audioService.attachReferenceAudio(ApiPath.TASK_ID, testID, file);
  // }

  // public downloadFileFromTest(testID: string, fileName: string): Observable<DownloadFileResult> {
  //   let queryParams = [
  //     {
  //       key: ApiPath.TASK_ID,
  //       value: testID
  //     }
  //   ];
  //
  //   let o = Observable.create((subscriber: Subscriber<DownloadFileResult>) => {
  //     let get = this.getFile(this.get_audio, {
  //       queryParams: queryParams
  //     }).subscribe(async (blobResponse: Blob) => {
  //
  //       let result: DownloadFileResult = {
  //         entityID: testID,
  //         fileName: fileName,
  //         blob: blobResponse
  //       };
  //
  //       subscriber.next(result);
  //       subscriber.complete();
  //     });
  //
  //     return () => {
  //       get && get.unsubscribe();
  //     };
  //   });
  //   return o;
  // }
  //
  // public deleteFileFromTest(testID: string): Observable<DeleteOperationResult> {
  //   let queryParams = [
  //     {
  //       key: ApiPath.TASK_ID,
  //       value: testID
  //     }
  //   ];
  //
  //   let o = Observable.create((subscriber: Subscriber<DeleteOperationResult>) => {
  //     let del = this.delete(this.delete_audio, {
  //       queryParams: queryParams,
  //       dataExtracter: (data) => this.operationDeleteResultExtractData(data, testID)
  //     }).subscribe(async (res: any) => {
  //
  //       let result: DeleteOperationResult = res;
  //
  //       subscriber.next(result);
  //       subscriber.complete();
  //     });
  //
  //     return () => {
  //       del && del.unsubscribe();
  //     };
  //   });
  //   return o;
  //
  // }

  private linkOperationResultDataExtracter(res: HttpResponse<Object>, testID: number|string, deviceID: number|string) {
    let body: LinkOperationResult = {
      opResult: (res.status === 200),
      testID: testID,
      deviceID: deviceID
    };
    Object.defineProperty(body, "__initialResponse", {value: res});
    return body;
  }
}
