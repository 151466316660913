import {Component, EventEmitter, HostBinding, Input, Output} from "@angular/core";
import {UxPropertyConverter} from "../../common/decorator/ux-property-converter";
import {UxPropertyHandler} from "../../common/decorator/ux-property-handler";
import {AttachPosition} from "../popover/popover.component";

@Component({
    selector: "ux-confirm-dialog",
    templateUrl: "./confirm-dialog.component.html",
    host: {"[class.ux-confirm-dialog]": "true"}
})
export class UxConfirmDialogComponent {
    @UxPropertyConverter("string", "Confirm")
    @Input()
    public caption: string;

    @UxPropertyConverter("string", "No")
    @Input()
    public cancelButtonText: string;

    @UxPropertyConverter("string", "Yes")
    @Input()
    public okButtonText: string;

    @HostBinding("class._visible")
    @UxPropertyConverter("boolean", "false")
    @UxPropertyHandler({
        afterChange: afterChangeVisible
    })
    @Input()
    public visible: boolean;

    @Input()
    public closable: boolean;

    @Input()
    public mobile: boolean = false;

    @Input()
    public popoverStyleClass: string;

    @Input()
    public get container(): Element | string {
        return this._popupContainerString || this._popupContainerElement;
    }

    public set container(value: Element | string) {
        if (typeof value === "string") {
            this._popupContainerElement = undefined;
            this._popupContainerString = value;
        } else {
            this._popupContainerString = undefined;
            this._popupContainerElement = value;
        }
    }

    @UxPropertyConverter("string", "auto")
    @Input()
    public attachPosition: AttachPosition;

    /** @internal */
    public _popupContainerElement: Element;

    /** @internal */
    public _popupContainerString: string;


    @Output()
    public onAccept: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Output()
    public visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    /** @internal */
    public _onAccept(event: boolean): void {
        this.visible = false;
        this.onAccept.emit(event);
    }

    public getAccept(): EventEmitter<boolean> {
      return this.onAccept;
    }
}

export function afterChangeVisible(value: boolean) {
    this.visibleChange.emit(value);
}
