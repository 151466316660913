import {NgModule} from "@angular/core";
import {SharedModule} from "../../shared/shared.module";
import {UxContentHeaderComponent} from "./content-header.component";

const COMPONENTS = [
    UxContentHeaderComponent
];

@NgModule({
    imports: [SharedModule],
    exports: COMPONENTS,
    declarations: COMPONENTS
})
export class UxCommonModule {
}
