
import { EventEmitter, OnDestroy, OnInit, Output, Directive } from "@angular/core";
import {Subject} from "rxjs";

@Directive()
export abstract class AbstractComponent implements OnInit, OnDestroy {
  protected viewInitiated: boolean = false;

  @Output()
  public onLoadInitialData: EventEmitter<any> = new EventEmitter<any>();

  public abstract loadInitialConfig();
  public abstract initComponent();
  public abstract destroyComponent();

  public isViewInitiated(): boolean {
    return this.viewInitiated;
  }

  public loadInitialData() {
    this.onLoadInitialData.emit();
  }

  ngOnInit(): void {
    this.loadInitialConfig();
    this.initComponent();
    this.loadInitialData();
    this.viewInitiated = true;
  }

  ngOnDestroy(): void {
    this.destroyComponent();
    this.viewInitiated = false;
  }

}
