import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ContentChild,
    ElementRef,
    EventEmitter,
    Input,
    NgZone,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from "@angular/core";
import {UxDomHelper, uxIsMacLike, uxIsMobileBrowser} from "../../shared/dom/dom-helper";
import {UxPropertyConverter} from "../../common/decorator/ux-property-converter";
import {UxPropertyHandler} from "../../common/decorator/ux-property-handler";

const SCROLL_EVENTS = {
    touchstart: "touchstart",
    touchend: "touchend",
    touchmove: "touchmove",

    mousedown: "mousedown",
    mouseup: "mouseup",
    mousemove: "mousemove",
    mouseenter: "mouseenter",
    mouseleave: "mouseleave",
    dragover: "dragover",

    scroll: "scroll",
    domMouseScroll: "DOMMouseScroll",
    mozMousePixelScroll: "MozMousePixelScroll",

    resize: "resize",
};

const SCROLL_DIRECTIONS = {
    up: "up",
    down: "down",
};

@Component({
    selector: "ux-scroll",
    templateUrl: "./scroll.component.html",
    host: {"[class.ux-scroll]": "true"},
    providers: []
})

export class UxScrollComponent implements OnInit, AfterViewInit, OnDestroy {

    /*deprecated use instead headerTemplate. todo after 1.0.34 version*/
    @Output()
    public onScrollEndPosition: EventEmitter<any> = new EventEmitter<null>();
    /******************************/

    @Output()
    public onScrollEndPositionChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    @UxPropertyConverter("number", 25)
    @UxPropertyHandler({
        afterChange: afterChangeMinVerticalSliderSize
    })
    @Input()
    public minVerticalSliderSize: number;

    @UxPropertyConverter("number", 25)
    @UxPropertyHandler({
        afterChange: afterChangeMinHorizontalSliderSize
    })
    @Input()
    public minHorizontalSliderSize: number;

    @UxPropertyConverter("boolean", false)
    @Input()
    public showSliders: boolean;

    @Input()
    public mobile: boolean = false;


    public isEndPosition: boolean;

    /** @internal */
    public _sliderVerticalWrapVisible: boolean = false;

    /** @internal */
    public _sliderHorizontalWrapVisible: boolean = false;

    /** @internal */
    public _mobile: boolean;

    /** @internal */
    public _customScrollableBlock: boolean = false;

    @ContentChild("customScrollableBlock")
    public set customScrollableBlock(customScrollableBlockElement: ElementRef) {
        this._customScrollableBlock = !!customScrollableBlockElement;
    }

    @ContentChild("customScrollableBlock")
    private customScrollableBlockElementReference: ElementRef;

    @ViewChild('scrollInner', { static: true })
    public scrollInnerElementReference: ElementRef;

    @ViewChild("scrollContent")
    private scrollContentElementReference: ElementRef;

    @ViewChild("scrollSliderVertical", { static: true })
    private sliderVerticalElementReference: ElementRef;

    @ViewChild("scrollSliderVerticalWrap", { static: true })
    private sliderVerticalWrapElementReference: ElementRef;

    @ViewChild("scrollSliderHorizontal", { static: true })
    private sliderHorizontalElementReference: ElementRef;

    @ViewChild("scrollSliderHorizontalWrap", { static: true })
    private sliderHorizontalWrapElementReference: ElementRef;

    private object: HTMLElement;
    public scroll: HTMLElement;
    private sliderVertical: HTMLElement;
    private sliderVerticalWrap: HTMLElement;
    private sliderHorizontal: HTMLElement;
    private sliderHorizontalWrap: HTMLElement;

    private objectHeight: number = 0;
    private barHeight: number = 0;
    private barWidth: number = 0;
    private scrollHeight: number = 0;
    private scrollWidth: number = 0;
    private sliderVerticalHeight: number = 0;
    private sliderHorizontalWidth: number = 0;
    private sliderVerticalHeightFull: number = 0;
    private sliderHorizontalWidthFull: number = 0;
    private edgeBottom: number = 0;
    private edgeRight: number = 0;
    private deltaVertical: number = 0;
    private deltaHorizontal: number = 0;
    private startPointY: number = 0;
    private startPointX: number = 0;
    private canDragY: boolean = true;
    private canDragX: boolean = true;
    private startPositionY: number = 0;
    private startPositionX: number = 0;
    private scrollRatioY: number = 0;
    private scrollRatioX: number = 0;
    private scrollScrollTop: number = 0;
    private scrollScrollLeft: number = 0;
    private scrollbarWidth: number = 0;
    private windowResizeTimeoutID: number;
    private autoResizeFlag: boolean;
    private timeoutID: number;

    private scrollStartYBind: () => void;
    private scrollStartXBind: () => void;
    private mouseMoveBind: () => void;
    private mouseUpBind: () => void;
    private mouseScrollBind: () => void;
    private windowResizeBind: () => void;
    private clickVerticalBind: () => void;
    private clickHorizontalBind: () => void;
    private autoResizeStartBind: () => void;
    private autoResizeEndBind: () => void;
    private dragOverBind: () => void;
    private destroyed = false;

    private throttledScrollToBottom: Function;
    private throttledScrollToTop: Function;

    constructor(private scrollElementReference: ElementRef, private zone: NgZone, private cdr: ChangeDetectorRef) {
    }

    public ngOnInit(): void {
        this._mobile = uxIsMobileBrowser() || (uxIsMacLike() && UxDomHelper.getScrollbarWidth() === 0);

        this.throttledScrollToTop = this.throttle(this.scrollToTop, 50);
        this.throttledScrollToBottom = this.throttle(this.scrollToBottom, 50);
    }

    public ngAfterViewInit(): void {
        let self = this;

        self.object = self.scrollElementReference.nativeElement;
        self.scroll = self._customScrollableBlock ? self.customScrollableBlockElementReference.nativeElement : self.scrollInnerElementReference.nativeElement;
        self.sliderVertical = self.sliderVerticalElementReference.nativeElement;
        self.sliderVerticalWrap = self.sliderVerticalWrapElementReference.nativeElement;
        self.sliderHorizontal = self.sliderHorizontalElementReference.nativeElement;
        self.sliderHorizontalWrap = self.sliderHorizontalWrapElementReference.nativeElement;

        self.objectHeight = self.object.offsetHeight;
        self.scrollbarWidth = UxDomHelper.getScrollbarWidth();

        self.hideNativeScrolls();
        self.updateVars();
        self.bindEvents();

        this.cdr.detectChanges();
    }

    private hideNativeScrolls(): void {
        let self = this;

        self.scrollbarWidth = UxDomHelper.getScrollbarWidth();
        self.scroll.style.marginBottom = self.scroll.style.marginBottom ? -self.scrollbarWidth + "px" : "";

        // +1 because of fractional numbers in screen
        if ((self.scrollbarWidth | 0) === self.scrollbarWidth) {
            self.scroll.style.height = `calc(100% + ${self.scrollbarWidth}px)`;
            self.scroll.style.width = `calc(100% + ${self.scrollbarWidth}px)`;
        } else {
            self.scroll.style.height = `calc(100% + ${self.scrollbarWidth + 1}px)`;
            self.scroll.style.width = `calc(100% + ${self.scrollbarWidth + 1}px)`;
        }

    }

    public update(): void {
        this.updateVars();
    }

    public updateVars(): void {
        let self = this;

        if (!self.object) {
            return;
        }

        self.objectHeight = self.object.offsetHeight;
        self.scrollbarWidth = UxDomHelper.getScrollbarWidth();

        self.calculateVerticalScroll();
        self.calculateHorizontalScroll();

        if (self.checkIfEndPosition()) {
            self.isEndPosition = true;
        } else {
            self.isEndPosition = false;
        }
    }

    private checkIfEndPosition(): boolean {
        return this.scrollHeight - this.scroll.scrollTop - this.barHeight <= 1;
    }

    private calculateVerticalScroll() {
        let self = this;

        self.objectHeight = self.barHeight = self.object.offsetHeight;
        self.scrollHeight = self.scroll.scrollHeight;
        self.sliderVerticalHeightFull = self.barHeight * self.barHeight / self.scrollHeight;

        if (self.minVerticalSliderSize && self.sliderVerticalHeightFull < self.minVerticalSliderSize) {
            self.sliderVerticalHeightFull = self.minVerticalSliderSize;
        }

        let verticalWrapperTop = getComputedStyle(self.sliderVerticalWrap).top;
        let verticalMargin = Number(verticalWrapperTop.substring(0, verticalWrapperTop.indexOf("px")));
        self.sliderVertical.style.height = (self.sliderVerticalHeightFull - 2 * verticalMargin) + "px";
        self.sliderVerticalHeight = self.sliderVerticalHeightFull / 2;
        self.edgeBottom = self.barHeight - self.sliderVerticalHeightFull;

        self.countDeltaVertical();

        self.startPointY = 0;
        self.startPositionY = 0;
        self.canDragY = false;
        self.canDragX = false;
        self.scrollRatioY = (self.barHeight - self.sliderVerticalHeightFull) / (Math.ceil(self.scrollHeight / self.barHeight * 2));
        self.scrollScrollTop = self.scroll.scrollTop;

        if (self.scrollScrollTop) {
            self.sliderVertical.style.top = self.scrollScrollTop / self.deltaVertical + "px";
        } else {
            self.sliderVertical.style.top = 0 + "px";
        }
    }

    private calculateHorizontalScroll() {
        let self = this;

        self.scrollWidth = self.scroll.scrollWidth;
        self.barWidth = self.object.offsetWidth;
        self.sliderHorizontalWidthFull = self.barWidth * self.barWidth / self.scrollWidth;

        if (self.minHorizontalSliderSize && self.sliderHorizontalWidthFull < self.minHorizontalSliderSize) {
            self.sliderHorizontalWidthFull = self.minHorizontalSliderSize;
        }

        let horizontalWrapperLeft = getComputedStyle(self.sliderHorizontalWrap).left;
        let horizontalMargin = Number(horizontalWrapperLeft.substring(0, horizontalWrapperLeft.indexOf("px")));
        self.sliderHorizontal.style.width = (self.sliderHorizontalWidthFull - 2 * horizontalMargin) + "px";
        self.sliderHorizontalWidth = self.sliderHorizontalWidthFull / 2;
        self.edgeRight = self.barWidth - self.sliderHorizontalWidthFull;

        self.countDeltaHorizontal();

        self.startPointX = 0;
        self.startPositionX = 0;
        self.scrollScrollLeft = self.scroll.scrollLeft;
        self.scrollRatioX = (self.barWidth - self.sliderHorizontalWidthFull) / (Math.ceil(self.scrollHeight / self.barHeight * 2));

        if (self.scrollScrollLeft) {
            self.sliderHorizontal.style.left = self.scrollScrollLeft / self.deltaHorizontal + "px";
        } else {
            self.sliderHorizontal.style.left = 0 + "px";
        }
    }

    private isHighResolutionScreen(): boolean {
        let windowMatchMedia = window.matchMedia("(min-resolution: 1.1dppx)");
        return windowMatchMedia && windowMatchMedia.matches;
    }

    private getHighResolutionOffset(): number {
        return this.isHighResolutionScreen() ? 2 : 1; //because of fractional numbers in high resolution screens
    }

    private countDeltaVertical(): void {
        let self = this;
        self.deltaVertical = (self.scrollHeight - self.barHeight) / (self.barHeight - self.sliderVerticalHeightFull);
        self._sliderVerticalWrapVisible = (self.scrollHeight - self.barHeight) > this.getHighResolutionOffset();

        if (!self.destroyed) {
            self.cdr.detectChanges();
        }
    }

    private countDeltaHorizontal(): void {
        let self = this;
        self.deltaHorizontal = (self.scrollWidth - self.barWidth) / (self.barWidth - self.sliderHorizontalWidthFull);
        self._sliderHorizontalWrapVisible = (self.scrollWidth - self.barWidth) > this.getHighResolutionOffset();

        if (!self.destroyed) {
            self.cdr.detectChanges();
        }

        if (self.objectHeight === self.scroll.offsetHeight) {
            self.scroll.style.marginBottom = -self.scrollbarWidth + "px";
        }
    }

    private bindEvents(): void {
        let self = this;
        self.zone.runOutsideAngular(() => {
            self.scrollStartYBind = self.scrollStartY.bind(self);
            self.scrollStartXBind = self.scrollStartX.bind(self);
            self.mouseMoveBind = self.mouseMove.bind(self);
            self.mouseUpBind = self.mouseUp.bind(self);
            self.mouseScrollBind = self.mouseScroll.bind(self);
            self.windowResizeBind = self.windowResize.bind(self);
            self.clickVerticalBind = self.clickVertical.bind(self);
            self.clickHorizontalBind = self.clickHorizontal.bind(self);
            self.autoResizeStartBind = self.autoResizeStart.bind(self);
            self.autoResizeEndBind = self.autoResizeEnd.bind(self);
            self.dragOverBind = self.dragOver.bind(self);

            self.sliderVertical.addEventListener(SCROLL_EVENTS.mousedown, self.scrollStartYBind);
            self.sliderVertical.addEventListener(SCROLL_EVENTS.touchstart, self.scrollStartYBind);

            self.sliderHorizontal.addEventListener(SCROLL_EVENTS.mousedown, self.scrollStartXBind);
            self.sliderHorizontal.addEventListener(SCROLL_EVENTS.touchstart, self.scrollStartXBind);

            document.addEventListener(SCROLL_EVENTS.mousemove, self.mouseMoveBind);
            document.addEventListener(SCROLL_EVENTS.touchmove, self.mouseMoveBind);

            document.addEventListener(SCROLL_EVENTS.mouseup, self.mouseUpBind);
            document.addEventListener(SCROLL_EVENTS.touchend, self.mouseUpBind);

            window.addEventListener(SCROLL_EVENTS.resize, self.windowResizeBind);

            self.scroll.addEventListener(SCROLL_EVENTS.scroll, self.mouseScrollBind);

            self.sliderVerticalWrap.addEventListener(SCROLL_EVENTS.mousedown, self.clickVerticalBind);
            self.sliderVerticalWrap.addEventListener(SCROLL_EVENTS.touchstart, self.clickVerticalBind);

            self.sliderHorizontalWrap.addEventListener(SCROLL_EVENTS.mousedown, self.clickHorizontalBind);
            self.sliderHorizontalWrap.addEventListener(SCROLL_EVENTS.touchstart, self.clickHorizontalBind);

            self.object.addEventListener(SCROLL_EVENTS.mouseenter, self.autoResizeStartBind);
            self.object.addEventListener(SCROLL_EVENTS.touchstart, self.autoResizeStartBind);
            self.object.addEventListener(SCROLL_EVENTS.mouseleave, self.autoResizeEndBind);
            self.object.addEventListener(SCROLL_EVENTS.touchend, self.autoResizeEndBind);
            self.object.addEventListener(SCROLL_EVENTS.dragover, self.dragOverBind);
        });
    }

    public ngOnDestroy(): void {
        let self = this;

        self.destroyed = true;

        self.sliderVertical && self.sliderVertical.removeEventListener(SCROLL_EVENTS.mousedown, self.scrollStartYBind);
        self.sliderVertical && self.sliderVertical.removeEventListener(SCROLL_EVENTS.touchstart, self.scrollStartYBind);

        self.sliderHorizontal && self.sliderHorizontal.removeEventListener(SCROLL_EVENTS.mousedown, self.scrollStartXBind);
        self.sliderHorizontal && self.sliderHorizontal.removeEventListener(SCROLL_EVENTS.touchstart, self.scrollStartXBind);

        document.removeEventListener(SCROLL_EVENTS.mousemove, self.mouseMoveBind);
        document.removeEventListener(SCROLL_EVENTS.touchmove, self.mouseMoveBind);

        document.removeEventListener(SCROLL_EVENTS.mouseup, self.mouseUpBind);
        document.removeEventListener(SCROLL_EVENTS.touchend, self.mouseUpBind);

        window.removeEventListener(SCROLL_EVENTS.resize, self.windowResizeBind);

        self.scroll && self.scroll.removeEventListener(SCROLL_EVENTS.scroll, self.mouseScrollBind);

        self.sliderVerticalWrap && self.sliderVerticalWrap.removeEventListener(SCROLL_EVENTS.mousedown, self.clickVerticalBind);
        self.sliderVerticalWrap && self.sliderVerticalWrap.removeEventListener(SCROLL_EVENTS.touchstart, self.clickVerticalBind);

        self.sliderHorizontalWrap && self.sliderHorizontalWrap.removeEventListener(SCROLL_EVENTS.mousedown, self.clickHorizontalBind);
        self.sliderHorizontalWrap && self.sliderHorizontalWrap.removeEventListener(SCROLL_EVENTS.touchstart, self.clickHorizontalBind);

        if (self.object) {
            self.object.removeEventListener(SCROLL_EVENTS.mouseenter, self.autoResizeStartBind);
            self.object.removeEventListener(SCROLL_EVENTS.touchstart, self.autoResizeStartBind);
            self.object.removeEventListener(SCROLL_EVENTS.mouseleave, self.autoResizeEndBind);
            self.object.removeEventListener(SCROLL_EVENTS.touchend, self.autoResizeEndBind);
        }
    }

    private windowResize(): void {
        clearTimeout(this.windowResizeTimeoutID);
        this.windowResizeTimeoutID = window.setTimeout(() => {
            this.hideNativeScrolls();
        }, 300);
    }

    private scrollStartY(event: MouseEvent): void {
        let self = this;

        event.stopPropagation();
        self.canDragY = true;
        self.startPointY = event.pageY;
        self.startPositionY = self.sliderVertical.offsetTop;

        self.turnOffSelection(self.scroll);
    }

    private scrollStartX(event: MouseEvent): void {
        let self = this;

        event.stopPropagation();

        self.canDragX = true;
        self.startPointX = event.pageX;
        self.startPositionX = self.sliderHorizontal.offsetLeft;

        self.turnOffSelection(self.scroll);
    }

    private clickVertical(event: MouseEvent): void {
        let self = this;

        if (self.canDragY) {
            return;
        }

        let pageY = event.pageY,
            offsetTop = event.currentTarget["getBoundingClientRect"]().top,
            diff = pageY - offsetTop - self.sliderVerticalHeight;

        self.scroll.scrollTop = diff * self.deltaVertical;
    }

    private clickHorizontal(event: MouseEvent): void {
        let self = this;

        if (self.canDragX) {
            return;
        }

        let pageX = event.pageX,
            offsetLeft = event.currentTarget["getBoundingClientRect"]().left,
            diff = pageX - offsetLeft - self.sliderHorizontalWidth;

        self.scroll.scrollLeft = diff * self.deltaHorizontal;
    }

    private mouseMove(event: MouseEvent): void {

        let self = this;

        if (!self.canDragY && !self.canDragX) {
            return;
        }

        if (self.canDragY) {

            let cursorDiffY = event.pageY - self.startPointY,
                sliderDiffY = cursorDiffY + self.startPositionY,
                sliderResultY, blockResultY;

            if (cursorDiffY < 0 && Math.abs(cursorDiffY) >= self.startPositionY) {
                sliderResultY = "0";
                blockResultY = 0;
            } else if (cursorDiffY > 0 && cursorDiffY >= self.edgeBottom - self.startPositionY) {
                sliderResultY = self.edgeBottom + "px";
                blockResultY = self.scrollHeight;
            } else {
                sliderResultY = sliderDiffY + "px";
                blockResultY = sliderDiffY * self.deltaVertical;
            }

            self.sliderVertical.style.top = sliderResultY;
            self.scroll.scrollTop = blockResultY;

        } else if (self.canDragX) {

            let cursorDiffX = event.pageX - self.startPointX,
                sliderDiffX = cursorDiffX + self.startPositionX,
                sliderResultX, blockResultX;

            if (cursorDiffX < 0 && Math.abs(cursorDiffX) >= self.startPositionX) {
                sliderResultX = "0";
                blockResultX = 0;
            } else if (cursorDiffX > 0 && cursorDiffX >= self.edgeRight - self.startPositionX) {
                sliderResultX = self.edgeRight + "px";
                blockResultX = self.scrollWidth;
            } else {
                sliderResultX = sliderDiffX + "px";
                blockResultX = sliderDiffX * self.deltaHorizontal;
            }

            self.sliderHorizontal.style.left = sliderResultX;
            self.scroll.scrollLeft = blockResultX;

        }

        event.preventDefault();
    }

    private mouseUp(): void {
        let self = this;

        self.canDragY = false;
        self.canDragX = false;

        self.turnOnSelection(self.scroll);
    }

    private mouseScroll(event: Event): void {
        let self = this;

        if (!self.scrollContentElementReference && !self.customScrollableBlockElementReference) {
            return;
        }

        if (self.checkIfEndPosition()) {
            if (!self.isEndPosition) {
                self.zone.run(() => {
                    self.onScrollEndPositionChange.emit(true);
                });
            }

            self.isEndPosition = true;
        } else {
            if (self.isEndPosition) {
                self.zone.run(() => {
                    self.onScrollEndPositionChange.emit(false);
                });
            }

            self.isEndPosition = false;
        }

        if (self.canDragY) {
            return;
        }

        self.sliderVertical.style.top = self.scroll.scrollTop / self.deltaVertical + "px";
        self.sliderHorizontal.style.left = self.scroll.scrollLeft / self.deltaHorizontal + "px";
    }

    private turnOffSelection(element: HTMLElement): void {
        element.setAttribute("unselectable", "on");
        element.classList.add("_unselectable");
    }

    private turnOnSelection(element: HTMLElement): void {
        element.removeAttribute("unselectable");
        element.classList.remove("_unselectable");
    }

    private autoResizeStart(): void {
        let self = this,
            tempScrollHeight: number,
            tempObjectHeight: number,
            tempScrollWidth: number,
            tempObjectWidth: number;

        self.zone.runOutsideAngular(() => {

            self.autoResizeFlag = true;
            self.timeoutID = window.setTimeout(function resize() {

                tempScrollHeight = self.scroll.scrollHeight;
                tempScrollWidth = self.scroll.scrollWidth;
                tempObjectHeight = self.object.offsetHeight;
                tempObjectWidth = self.object.offsetWidth;

                if (self.object && ((tempScrollHeight !== self.scrollHeight || tempObjectHeight !== self.objectHeight) ||
                    (tempScrollWidth !== self.scrollWidth || tempObjectWidth !== self.barWidth))) {

                    self.zone.run(() => {
                        self.updateVars();
                        self.objectHeight = tempObjectHeight;
                        self.barWidth = tempObjectWidth;

                    });
                }

                if (self.autoResizeFlag) {
                    window.setTimeout(resize, 1000);
                }
            }, 300);

        });
    }

    private autoResizeEnd(): void {
        this.autoResizeFlag = false;
        clearInterval(this.timeoutID);
    }

    private dragOver(event: any): void {
        let self = this;
        event.preventDefault();

        let scrollTop = self.scrollElementReference.nativeElement.getBoundingClientRect().top,
            scrollBottom = scrollTop + self.scrollElementReference.nativeElement.offsetHeight,
            dragPageY = event.pageY;

        // drag cursor is in the top edge
        if (dragPageY < scrollTop + 40 && dragPageY > scrollTop) {
            let diff = self.scroll.scrollTop;
            if (diff > 0) {
                self.throttledScrollToTop();
            }
        }
        // drag cursor is in the bottom edge
        else if (dragPageY > scrollBottom - 40 && dragPageY < scrollBottom) {
            let diff = self.scroll.scrollHeight - self.scroll.scrollTop - self.object.offsetHeight;
            if (diff > 1) {// 1 because of fractional numbers in screen
                self.throttledScrollToBottom();
            }
        }
    }

    private scrollToBottom(): void {
        let diff = this.scrollElementReference.nativeElement.offsetHeight / 30;
        this.scroll.scrollTop += diff;
    }

    private scrollToTop(): void {
        let diff = this.scrollElementReference.nativeElement.offsetHeight / 30;
        this.scroll.scrollTop -= diff;
    }

    private throttle(func: any, wait: any, options?: any): Function {
        let context: any,
            args: any,
            result: any;
        let timeout: number = null;
        let previous = 0;
        options || (options = {});
        let later = function () {
            previous = options.leading === false ? 0 : new Date().getTime();
            timeout = null;
            result = func.apply(context, args);
            context = args = null;
        };
        return function () {
            let now = new Date().getTime();
            if (!previous && options.leading === false) previous = now;
            let remaining = wait - (now - previous);
            context = this;
            args = arguments;
            if (remaining <= 0) {
                clearTimeout(timeout);
                timeout = null;
                previous = now;
                result = func.apply(context, args);
                context = args = null;
            } else if (!timeout && options.trailing !== false) {
                timeout = window.setTimeout(later, remaining);
            }
            return result;
        };
    }

}


/*Helpers*/
export function afterChangeMinVerticalSliderSize(newValue: number, oldValue: number) {
    this.updateVars();
}

export function afterChangeMinHorizontalSliderSize(newValue: number, oldValue: number) {
    this.updateVars();
}
