import {NgModule} from "@angular/core";
import {SharedModule} from "../../shared/shared.module";
import {UxDroppableDirective} from "./droppable.directive";
import {UxDraggableDirective} from "./draggable.directive";

@NgModule({
    imports: [SharedModule],
    exports: [UxDraggableDirective, UxDroppableDirective],
    declarations: [UxDraggableDirective, UxDroppableDirective]
})
export class UxDragDropModule {
}