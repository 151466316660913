<div class="sq-audio-card-list__wrapper">
  <div class="sq-audio-card-list__content"
       [ngClass]="{
        _empty: !_entityCardsMap || _entityCardsMap.size === 0,
        _filled: _entityCardsMap?.size > 0
       }"
  >
    <ng-container *ngIf="loadStatus === 'loading' || loadStatus === 'initial'">
      <div class="sq-audio-card-list__card">
        <div class="sq-audio-card-list__card-empty-content">
          <ux-round-dots-loader [visible]="loadStatus === 'loading' || loadStatus === 'initial'"></ux-round-dots-loader>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="(loadStatus === 'no_data') && (totalCount === 0)">
      <div class="sq-audio-card-list__card">
        <div class="sq-audio-card-list__card-empty-content">
          <div class="sq-audio-card-list__card-empty-content-label">
            No audio found
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="loadStatus === 'loaded' && _entityCardsMap?.size > 0">
      <ux-card-tree [model]="_cardsModel"
                    [selectAllEnabled]="selectAllEnabled"
                    [selectAllCaption]="selectAllCaption"
                    (onChecked)="onCardChecked.emit($event)"
                    (onCheckedAll)="onCardCheckedAll.emit($event)"
                    (onToggle)="_onToggle($event)"
      >
      </ux-card-tree>
    </ng-container>
  </div>
  <div class="sq-audio-card-list__footer"
       *ngIf="pagingEnabled && _pagingVisible">
    <ux-paging [totalCount]="totalCount"
               [pageSize]="pageSize"
               [currentPage]="currentPage"
               [pageSizeOptions]="pageSizeOptions"
               (onPageChange)="onPageChange.emit($event)"
               (onPageSizeChange)="onPageSizeChange.emit($event)"
    >
    </ux-paging>
  </div>
</div>

<ng-template #cardTemplate
             let-node="node">
  <div class="sq-audio-card-list__card">
    <ng-template
      [ngTemplateOutlet]="cardContent"
    >
    </ng-template>
    <ng-template
            *ngIf="node.expanded"
            [ngTemplateOutlet]="cardExpandable"
    >
    </ng-template>
  </div>

  <ng-template #cardContent>
    <div class="sq-audio-card-list__card-content">
      <div class="sq-audio-card-list__card-item _icon"
           [ngClass]="{
            _selectable_row: selectItemEnabled,
            _audio_file: true
            }"
      >
      </div>
      <div class="sq-audio-card-list__card-item _head">
        <div class="sq-audio-card-list__card-row _title">
          {{node.name}}
        </div>
      </div>
      <div class="sq-audio-card-list__card-item">
        <div class="sq-audio-card-list__card-row">
          <div class="_icon _action _download"
               *ngIf="downloadEnabled"
               (tap)="_onDownload(node)"
          >
            <svg enable-background="new 0 0 29.978 29.978" version="1.1" viewBox="0 0 29.978 29.978" xml:space="preserve" xmlns="http://www.w3.org/2000/svg">
              <g fill="#0c6cb0"><path d="m25.462 19.105v6.848h-20.947v-6.848h-4.026v8.861c0 1.111 0.9 2.012 2.016 2.012h24.967c1.115 0 2.016-0.9 2.016-2.012v-8.861h-4.026z"/></g>
              <g fill="#0c6cb0"><path d="m14.62 18.426-5.764-6.965s-0.877-0.828 0.074-0.828 3.248 0 3.248 0 0-0.557 0-1.416v-8.723s-0.129-0.494 0.615-0.494h4.572c0.536 0 0.524 0.416 0.524 0.416v8.742 1.266s1.842 0 2.998 0c1.154 0 0.285 0.867 0.285 0.867s-4.904 6.51-5.588 7.193c-0.492 0.495-0.964-0.058-0.964-0.058z"/></g>
            </svg>
          </div>
          <div class="_icon _action _edit"
               *ngIf="editEnabled"
               (tap)="_onEdit(node)"
          >
            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                 class="_action-icon-edit"
                 width="24" height="24"
                 viewBox="0 0 172 172"
                 style="fill:#000000;"><g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><path d="M0,172v-172h172v172z" fill="none"></path><g fill="#0c6cb0"><path d="M131.96744,14.33333c-1.83376,0 -3.66956,0.69853 -5.06706,2.09961l-12.23372,12.23372l28.66667,28.66667l12.23372,-12.23372c2.80217,-2.80217 2.80217,-7.33911 0,-10.13412l-18.53255,-18.53255c-1.40108,-1.40108 -3.23329,-2.09961 -5.06706,-2.09961zM103.91667,39.41667l-82.41667,82.41667v28.66667h28.66667l82.41667,-82.41667z"></path></g></g></svg>
          </div>
          <div class="_icon _action _delete"
               *ngIf="deleteEnabled"
               (tap)="_onDelete(node)"
          >
            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                 class="_action-icon-delete"
                 width="24" height="24"
                 viewBox="0 0 172 172"
                 style=" fill:#000000;"><g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><path d="M0,172v-172h172v172z" fill="none"></path><g fill="#0c6cb0"><path d="M71.66667,14.33333l-7.16667,7.16667h-35.83333v14.33333h114.66667v-14.33333h-35.83333l-7.16667,-7.16667zM35.83333,50.16667v93.16667c0,7.88333 6.45,14.33333 14.33333,14.33333h71.66667c7.88333,0 14.33333,-6.45 14.33333,-14.33333v-93.16667zM67.43945,71.66667l18.56055,18.56055l18.56055,-18.56055l10.10612,10.10612l-18.56055,18.56055l18.56055,18.56055l-10.10612,10.10612l-18.56055,-18.56055l-18.56055,18.56055l-10.10612,-10.10612l18.56055,-18.56055l-18.56055,-18.56055z"></path></g></g></svg>
          </div>
          <div class="_icon _action"
               *ngIf="expandEnabled"
               [ngClass]="{
                  _expand: !node.expanded,
                  _collapse: node.expanded
                  }"
               (tap)="_onExpand(node)"
          >
            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                 width="24" height="24"
                 viewBox="0 0 172 172"
                 style=" fill:#000000;">
              <g transform="">
                <g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt"
                   stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0"
                   font-family="none" font-weight="none" font-size="none" text-anchor="none"
                   style="mix-blend-mode: normal">
                  <path d="M0,172v-172h172v172z" fill="none"></path>
                  <path
                          d="M12.04,86c0,-40.84698 33.11302,-73.96 73.96,-73.96c40.84698,0 73.96,33.11302 73.96,73.96c0,40.84698 -33.11302,73.96 -73.96,73.96c-40.84698,0 -73.96,-33.11302 -73.96,-73.96zM86,153.08c37.04726,0 67.08,-30.03274 67.08,-67.08c0,-37.04726 -30.03274,-67.08 -67.08,-67.08c-37.04726,0 -67.08,30.03274 -67.08,67.08c0,37.04726 30.03274,67.08 67.08,67.08z"
                          fill="#0c6cb0"></path>
                  <g fill="#0c6cb0">
                    <path
                            d="M56.92305,71.12861l-9.5432,9.5432l38.62015,38.62015l38.62015,-38.62015l-9.5432,-9.5432l-29.07695,29.07695z"></path>
                  </g>
                  <path
                          d="M86,172c-47.49649,0 -86,-38.50351 -86,-86v0c0,-47.49649 38.50351,-86 86,-86v0c47.49649,0 86,38.50351 86,86v0c0,47.49649 -38.50351,86 -86,86z"
                          fill="none"></path>
                  <path
                          d="M86,168.56c-45.59663,0 -82.56,-36.96337 -82.56,-82.56v0c0,-45.59663 36.96337,-82.56 82.56,-82.56v0c45.59663,0 82.56,36.96337 82.56,82.56v0c0,45.59663 -36.96337,82.56 -82.56,82.56z"
                          fill="none"></path>
                  <path
                          d="M86,172c-47.49649,0 -86,-38.50351 -86,-86v0c0,-47.49649 38.50351,-86 86,-86v0c47.49649,0 86,38.50351 86,86v0c0,47.49649 -38.50351,86 -86,86z"
                          fill="none"></path>
                  <path
                          d="M86,165.12c-43.69677,0 -79.12,-35.42323 -79.12,-79.12v0c0,-43.69677 35.42323,-79.12 79.12,-79.12v0c43.69677,0 79.12,35.42323 79.12,79.12v0c0,43.69677 -35.42323,79.12 -79.12,79.12z"
                          fill="none"></path>
                  <path
                          d="M86,172c-47.49649,0 -86,-38.50351 -86,-86v0c0,-47.49649 38.50351,-86 86,-86v0c47.49649,0 86,38.50351 86,86v0c0,47.49649 -38.50351,86 -86,86z"
                          fill="none"></path>
                  <path
                          d="M86,161.68c-41.79691,0 -75.68,-33.88309 -75.68,-75.68v0c0,-41.79691 33.88309,-75.68 75.68,-75.68v0c41.79691,0 75.68,33.88309 75.68,75.68v0c0,41.79691 -33.88309,75.68 -75.68,75.68z"
                          fill="none"></path>
                  <path
                          d="M86,172c-47.49649,0 -86,-38.50351 -86,-86v0c0,-47.49649 38.50351,-86 86,-86v0c47.49649,0 86,38.50351 86,86v0c0,47.49649 -38.50351,86 -86,86z"
                          fill="none"></path>
                  <path
                          d="M86,158.24c-39.89705,0 -72.24,-32.34295 -72.24,-72.24v0c0,-39.89705 32.34295,-72.24 72.24,-72.24h0c39.89705,0 72.24,32.34295 72.24,72.24v0c0,39.89705 -32.34295,72.24 -72.24,72.24z"
                          fill="none"></path>
                  <path
                          d="M86,172c-47.49649,0 -86,-38.50351 -86,-86v0c0,-47.49649 38.50351,-86 86,-86v0c47.49649,0 86,38.50351 86,86v0c0,47.49649 -38.50351,86 -86,86z"
                          fill="none"></path>
                  <path
                          d="M86,161.68c-41.79691,0 -75.68,-33.88309 -75.68,-75.68v0c0,-41.79691 33.88309,-75.68 75.68,-75.68v0c41.79691,0 75.68,33.88309 75.68,75.68v0c0,41.79691 -33.88309,75.68 -75.68,75.68z"
                          fill="none"></path>
                  <path
                          d="M86,172c-47.49649,0 -86,-38.50351 -86,-86v0c0,-47.49649 38.50351,-86 86,-86v0c47.49649,0 86,38.50351 86,86v0c0,47.49649 -38.50351,86 -86,86z"
                          fill="none"></path>
                  <path
                          d="M86,165.12c-43.69677,0 -79.12,-35.42323 -79.12,-79.12v0c0,-43.69677 35.42323,-79.12 79.12,-79.12v0c43.69677,0 79.12,35.42323 79.12,79.12v0c0,43.69677 -35.42323,79.12 -79.12,79.12z"
                          fill="none"></path>
                  <path
                          d="M86,171.14c-47.02152,0 -85.14,-38.11848 -85.14,-85.14v0c0,-47.02152 38.11848,-85.14 85.14,-85.14h0c47.02152,0 85.14,38.11848 85.14,85.14v0c0,47.02152 -38.11848,85.14 -85.14,85.14z"
                          fill="none"></path>
                  <path
                          d="M86,164.26c-43.2218,0 -78.26,-35.0382 -78.26,-78.26v0c0,-43.2218 35.0382,-78.26 78.26,-78.26h0c43.2218,0 78.26,35.0382 78.26,78.26v0c0,43.2218 -35.0382,78.26 -78.26,78.26z"
                          fill="none"></path>
                  <path
                          d="M86,167.7c-45.12166,0 -81.7,-36.57834 -81.7,-81.7v0c0,-45.12166 36.57834,-81.7 81.7,-81.7h0c45.12166,0 81.7,36.57834 81.7,81.7v0c0,45.12166 -36.57834,81.7 -81.7,81.7z"
                          fill="none"></path>
                  <path
                          d="M86,160.82c-41.32194,0 -74.82,-33.49806 -74.82,-74.82v0c0,-41.32194 33.49806,-74.82 74.82,-74.82h0c41.32194,0 74.82,33.49806 74.82,74.82v0c0,41.32194 -33.49806,74.82 -74.82,74.82z"
                          fill="none"></path>
                  <path
                          d="M86,166.84c-44.6467,0 -80.84,-36.1933 -80.84,-80.84v0c0,-44.6467 36.1933,-80.84 80.84,-80.84v0c44.6467,0 80.84,36.1933 80.84,80.84v0c0,44.6467 -36.1933,80.84 -80.84,80.84z"
                          fill="none"></path>
                  <path
                          d="M86,159.96c-40.84698,0 -73.96,-33.11302 -73.96,-73.96v0c0,-40.84698 33.11302,-73.96 73.96,-73.96v0c40.84698,0 73.96,33.11302 73.96,73.96v0c0,40.84698 -33.11302,73.96 -73.96,73.96z"
                          fill="none"></path>
                  <path
                          d="M86,165.98c-44.17173,0 -79.98,-35.80827 -79.98,-79.98v0c0,-44.17173 35.80827,-79.98 79.98,-79.98h0c44.17173,0 79.98,35.80827 79.98,79.98v0c0,44.17173 -35.80827,79.98 -79.98,79.98z"
                          fill="none"></path>
                  <path
                          d="M86,159.1c-40.37202,0 -73.1,-32.72798 -73.1,-73.1v0c0,-40.37202 32.72798,-73.1 73.1,-73.1h0c40.37202,0 73.1,32.72798 73.1,73.1v0c0,40.37202 -32.72798,73.1 -73.1,73.1z"
                          fill="none"></path>
                  <path
                          d="M86,164.26c-43.2218,0 -78.26,-35.0382 -78.26,-78.26v0c0,-43.2218 35.0382,-78.26 78.26,-78.26h0c43.2218,0 78.26,35.0382 78.26,78.26v0c0,43.2218 -35.0382,78.26 -78.26,78.26z"
                          fill="none"></path>
                  <path
                          d="M86,157.38c-39.42209,0 -71.38,-31.95791 -71.38,-71.38v0c0,-39.42209 31.95791,-71.38 71.38,-71.38h0c39.42209,0 71.38,31.95791 71.38,71.38v0c0,39.42209 -31.95791,71.38 -71.38,71.38z"
                          fill="none"></path>
                  <path
                          d="M86,163.4c-42.74684,0 -77.4,-34.65316 -77.4,-77.4v0c0,-42.74684 34.65316,-77.4 77.4,-77.4v0c42.74684,0 77.4,34.65316 77.4,77.4v0c0,42.74684 -34.65316,77.4 -77.4,77.4z"
                          fill="none"></path>
                  <path
                          d="M86,156.52c-38.94712,0 -70.52,-31.57288 -70.52,-70.52v0c0,-38.94712 31.57288,-70.52 70.52,-70.52v0c38.94712,0 70.52,31.57288 70.52,70.52v0c0,38.94712 -31.57288,70.52 -70.52,70.52z"
                          fill="none"></path>
                  <path
                          d="M86,162.54c-42.27187,0 -76.54,-34.26813 -76.54,-76.54v0c0,-42.27187 34.26813,-76.54 76.54,-76.54h0c42.27187,0 76.54,34.26813 76.54,76.54v0c0,42.27187 -34.26813,76.54 -76.54,76.54z"
                          fill="none"></path>
                  <path
                          d="M86,155.66c-38.47216,0 -69.66,-31.18784 -69.66,-69.66v0c0,-38.47216 31.18784,-69.66 69.66,-69.66h0c38.47216,0 69.66,31.18784 69.66,69.66v0c0,38.47216 -31.18784,69.66 -69.66,69.66z"
                          fill="none"></path>
                  <path
                          d="M86,161.68c-41.79691,0 -75.68,-33.88309 -75.68,-75.68v0c0,-41.79691 33.88309,-75.68 75.68,-75.68v0c41.79691,0 75.68,33.88309 75.68,75.68v0c0,41.79691 -33.88309,75.68 -75.68,75.68z"
                          fill="none"></path>
                  <path
                          d="M86,154.8c-37.99719,0 -68.8,-30.80281 -68.8,-68.8v0c0,-37.99719 30.80281,-68.8 68.8,-68.8v0c37.99719,0 68.8,30.80281 68.8,68.8v0c0,37.99719 -30.80281,68.8 -68.8,68.8z"
                          fill="none"></path>
                  <path
                          d="M86,160.82c-41.32194,0 -74.82,-33.49806 -74.82,-74.82v0c0,-41.32194 33.49806,-74.82 74.82,-74.82v0c41.32194,0 74.82,33.49806 74.82,74.82v0c0,41.32194 -33.49806,74.82 -74.82,74.82z"
                          fill="none"></path>
                  <path
                          d="M86,153.94c-37.52223,0 -67.94,-30.41777 -67.94,-67.94v0c0,-37.52223 30.41777,-67.94 67.94,-67.94v0c37.52223,0 67.94,30.41777 67.94,67.94v0c0,37.52223 -30.41777,67.94 -67.94,67.94z"
                          fill="none"></path>
                  <path
                          d="M86,159.96c-40.84698,0 -73.96,-33.11302 -73.96,-73.96v0c0,-40.84698 33.11302,-73.96 73.96,-73.96h0c40.84698,0 73.96,33.11302 73.96,73.96v0c0,40.84698 -33.11302,73.96 -73.96,73.96z"
                          fill="none"></path>
                  <path
                          d="M86,153.08c-37.04726,0 -67.08,-30.03274 -67.08,-67.08v0c0,-37.04726 30.03274,-67.08 67.08,-67.08h0c37.04726,0 67.08,30.03274 67.08,67.08v0c0,37.04726 -30.03274,67.08 -67.08,67.08z"
                          fill="none"></path>
                  <path
                          d="M86,159.1c-40.37202,0 -73.1,-32.72798 -73.1,-73.1v0c0,-40.37202 32.72798,-73.1 73.1,-73.1v0c40.37202,0 73.1,32.72798 73.1,73.1v0c0,40.37202 -32.72798,73.1 -73.1,73.1z"
                          fill="none"></path>
                  <path
                          d="M86,152.22c-36.5723,0 -66.22,-29.6477 -66.22,-66.22v0c0,-36.5723 29.6477,-66.22 66.22,-66.22v0c36.5723,0 66.22,29.6477 66.22,66.22v0c0,36.5723 -29.6477,66.22 -66.22,66.22z"
                          fill="none"></path>
                  <path
                          d="M86,157.38c-39.42209,0 -71.38,-31.95791 -71.38,-71.38v0c0,-39.42209 31.95791,-71.38 71.38,-71.38v0c39.42209,0 71.38,31.95791 71.38,71.38v0c0,39.42209 -31.95791,71.38 -71.38,71.38z"
                          fill="none"></path>
                  <path
                          d="M86,150.5c-35.62237,0 -64.5,-28.87763 -64.5,-64.5v0c0,-35.62237 28.87763,-64.5 64.5,-64.5v0c35.62237,0 64.5,28.87763 64.5,64.5v0c0,35.62237 -28.87763,64.5 -64.5,64.5z"
                          fill="none"></path>
                  <path
                          d="M86,156.52c-38.94712,0 -70.52,-31.57288 -70.52,-70.52v0c0,-38.94712 31.57288,-70.52 70.52,-70.52h0c38.94712,0 70.52,31.57288 70.52,70.52v0c0,38.94712 -31.57288,70.52 -70.52,70.52z"
                          fill="none"></path>
                  <path
                          d="M86,149.64c-35.1474,0 -63.64,-28.4926 -63.64,-63.64v0c0,-35.1474 28.4926,-63.64 63.64,-63.64v0c35.1474,0 63.64,28.4926 63.64,63.64v0c0,35.1474 -28.4926,63.64 -63.64,63.64z"
                          fill="none"></path>
                  <path
                          d="M86,153.94c-37.52223,0 -67.94,-30.41777 -67.94,-67.94v0c0,-37.52223 30.41777,-67.94 67.94,-67.94v0c37.52223,0 67.94,30.41777 67.94,67.94v0c0,37.52223 -30.41777,67.94 -67.94,67.94z"
                          fill="none"></path>
                  <path
                          d="M86,147.06c-33.72251,0 -61.06,-27.33749 -61.06,-61.06v0c0,-33.72251 27.33749,-61.06 61.06,-61.06v0c33.72251,0 61.06,27.33749 61.06,61.06v0c0,33.72251 -27.33749,61.06 -61.06,61.06z"
                          fill="none"></path>
                  <path
                          d="M86,153.08c-37.04726,0 -67.08,-30.03274 -67.08,-67.08v0c0,-37.04726 30.03274,-67.08 67.08,-67.08h0c37.04726,0 67.08,30.03274 67.08,67.08v0c0,37.04726 -30.03274,67.08 -67.08,67.08z"
                          fill="none"></path>
                  <path
                          d="M86,146.2c-33.24754,0 -60.2,-26.95246 -60.2,-60.2v0c0,-33.24754 26.95246,-60.2 60.2,-60.2v0c33.24754,0 60.2,26.95246 60.2,60.2v0c0,33.24754 -26.95246,60.2 -60.2,60.2z"
                          fill="none"></path>
                  <path
                          d="M86,152.22c-36.5723,0 -66.22,-29.6477 -66.22,-66.22v0c0,-36.5723 29.6477,-66.22 66.22,-66.22v0c36.5723,0 66.22,29.6477 66.22,66.22v0c0,36.5723 -29.6477,66.22 -66.22,66.22z"
                          fill="none"></path>
                  <path
                          d="M86,145.34c-32.77258,0 -59.34,-26.56742 -59.34,-59.34v0c0,-32.77258 26.56742,-59.34 59.34,-59.34v0c32.77258,0 59.34,26.56742 59.34,59.34v0c0,32.77258 -26.56742,59.34 -59.34,59.34z"
                          fill="none"></path>
                  <path
                          d="M86,150.5c-35.62237,0 -64.5,-28.87763 -64.5,-64.5v0c0,-35.62237 28.87763,-64.5 64.5,-64.5v0c35.62237,0 64.5,28.87763 64.5,64.5v0c0,35.62237 -28.87763,64.5 -64.5,64.5z"
                          fill="none"></path>
                  <path
                          d="M86,143.62c-31.82265,0 -57.62,-25.79735 -57.62,-57.62v0c0,-31.82265 25.79735,-57.62 57.62,-57.62v0c31.82265,0 57.62,25.79735 57.62,57.62v0c0,31.82265 -25.79735,57.62 -57.62,57.62z"
                          fill="none"></path>
                  <path
                          d="M86,149.64c-35.1474,0 -63.64,-28.4926 -63.64,-63.64v0c0,-35.1474 28.4926,-63.64 63.64,-63.64h0c35.1474,0 63.64,28.4926 63.64,63.64v0c0,35.1474 -28.4926,63.64 -63.64,63.64z"
                          fill="none"></path>
                  <path
                          d="M86,142.76c-31.34768,0 -56.76,-25.41232 -56.76,-56.76v0c0,-31.34768 25.41232,-56.76 56.76,-56.76v0c31.34768,0 56.76,25.41232 56.76,56.76v0c0,31.34768 -25.41232,56.76 -56.76,56.76z"
                          fill="none"></path>
                  <path
                          d="M86,148.78c-34.67244,0 -62.78,-28.10756 -62.78,-62.78v0c0,-34.67244 28.10756,-62.78 62.78,-62.78v0c34.67244,0 62.78,28.10756 62.78,62.78v0c0,34.67244 -28.10756,62.78 -62.78,62.78z"
                          fill="none"></path>
                  <path
                          d="M86,141.9c-30.87272,0 -55.9,-25.02728 -55.9,-55.9v0c0,-30.87272 25.02728,-55.9 55.9,-55.9v0c30.87272,0 55.9,25.02728 55.9,55.9v0c0,30.87272 -25.02728,55.9 -55.9,55.9z"
                          fill="none"></path>
                  <path
                          d="M86,147.92c-34.19747,0 -61.92,-27.72253 -61.92,-61.92v0c0,-34.19747 27.72253,-61.92 61.92,-61.92h0c34.19747,0 61.92,27.72253 61.92,61.92v0c0,34.19747 -27.72253,61.92 -61.92,61.92z"
                          fill="none"></path>
                  <path
                          d="M86,141.04c-30.39775,0 -55.04,-24.64225 -55.04,-55.04v0c0,-30.39775 24.64225,-55.04 55.04,-55.04v0c30.39775,0 55.04,24.64225 55.04,55.04v0c0,30.39775 -24.64225,55.04 -55.04,55.04z"
                          fill="none"></path>
                  <path
                          d="M86,147.06c-33.72251,0 -61.06,-27.33749 -61.06,-61.06v0c0,-33.72251 27.33749,-61.06 61.06,-61.06v0c33.72251,0 61.06,27.33749 61.06,61.06v0c0,33.72251 -27.33749,61.06 -61.06,61.06z"
                          fill="none"></path>
                  <path
                          d="M86,140.18c-29.92279,0 -54.18,-24.25721 -54.18,-54.18v0c0,-29.92279 24.25721,-54.18 54.18,-54.18v0c29.92279,0 54.18,24.25721 54.18,54.18v0c0,29.92279 -24.25721,54.18 -54.18,54.18z"
                          fill="none"></path>
                  <path
                          d="M86,146.2c-33.24754,0 -60.2,-26.95246 -60.2,-60.2v0c0,-33.24754 26.95246,-60.2 60.2,-60.2h0c33.24754,0 60.2,26.95246 60.2,60.2v0c0,33.24754 -26.95246,60.2 -60.2,60.2z"
                          fill="none"></path>
                  <path
                          d="M86,139.32c-29.44782,0 -53.32,-23.87218 -53.32,-53.32v0c0,-29.44782 23.87218,-53.32 53.32,-53.32v0c29.44782,0 53.32,23.87218 53.32,53.32v0c0,29.44782 -23.87218,53.32 -53.32,53.32z"
                          fill="none"></path>
                  <path
                          d="M86,145.34c-32.77258,0 -59.34,-26.56742 -59.34,-59.34v0c0,-32.77258 26.56742,-59.34 59.34,-59.34v0c32.77258,0 59.34,26.56742 59.34,59.34v0c0,32.77258 -26.56742,59.34 -59.34,59.34z"
                          fill="none"></path>
                  <path
                          d="M86,138.46c-28.97286,0 -52.46,-23.48714 -52.46,-52.46v0c0,-28.97286 23.48714,-52.46 52.46,-52.46v0c28.97286,0 52.46,23.48714 52.46,52.46v0c0,28.97286 -23.48714,52.46 -52.46,52.46z"
                          fill="none"></path>
                  <path
                          d="M86,144.48c-32.29761,0 -58.48,-26.18239 -58.48,-58.48v0c0,-32.29761 26.18239,-58.48 58.48,-58.48v0c32.29761,0 58.48,26.18239 58.48,58.48v0c0,32.29761 -26.18239,58.48 -58.48,58.48z"
                          fill="none"></path>
                  <path
                          d="M86,137.6c-28.49789,0 -51.6,-23.10211 -51.6,-51.6v0c0,-28.49789 23.10211,-51.6 51.6,-51.6v0c28.49789,0 51.6,23.10211 51.6,51.6v0c0,28.49789 -23.10211,51.6 -51.6,51.6z"
                          fill="none"></path>
                  <path
                          d="M86,142.76c-31.34768,0 -56.76,-25.41232 -56.76,-56.76v0c0,-31.34768 25.41232,-56.76 56.76,-56.76v0c31.34768,0 56.76,25.41232 56.76,56.76v0c0,31.34768 -25.41232,56.76 -56.76,56.76z"
                          fill="none"></path>
                  <path
                          d="M86,135.88c-27.54796,0 -49.88,-22.33204 -49.88,-49.88v0c0,-27.54796 22.33204,-49.88 49.88,-49.88v0c27.54796,0 49.88,22.33204 49.88,49.88v0c0,27.54796 -22.33204,49.88 -49.88,49.88z"
                          fill="none"></path>
                  <path
                          d="M86,141.9c-30.87272,0 -55.9,-25.02728 -55.9,-55.9v0c0,-30.87272 25.02728,-55.9 55.9,-55.9v0c30.87272,0 55.9,25.02728 55.9,55.9v0c0,30.87272 -25.02728,55.9 -55.9,55.9z"
                          fill="none"></path>
                  <path
                          d="M86,135.02c-27.073,0 -49.02,-21.947 -49.02,-49.02v0c0,-27.073 21.947,-49.02 49.02,-49.02h0c27.073,0 49.02,21.947 49.02,49.02v0c0,27.073 -21.947,49.02 -49.02,49.02z"
                          fill="none"></path>
                  <path
                          d="M86,141.04c-30.39775,0 -55.04,-24.64225 -55.04,-55.04v0c0,-30.39775 24.64225,-55.04 55.04,-55.04v0c30.39775,0 55.04,24.64225 55.04,55.04v0c0,30.39775 -24.64225,55.04 -55.04,55.04z"
                          fill="none"></path>
                  <path
                          d="M86,134.16c-26.59803,0 -48.16,-21.56197 -48.16,-48.16v0c0,-26.59803 21.56197,-48.16 48.16,-48.16v0c26.59803,0 48.16,21.56197 48.16,48.16v0c0,26.59803 -21.56197,48.16 -48.16,48.16z"
                          fill="none"></path>
                  <path
                          d="M86,140.18c-29.92279,0 -54.18,-24.25721 -54.18,-54.18v0c0,-29.92279 24.25721,-54.18 54.18,-54.18v0c29.92279,0 54.18,24.25721 54.18,54.18v0c0,29.92279 -24.25721,54.18 -54.18,54.18z"
                          fill="none"></path>
                  <path
                          d="M86,133.3c-26.12307,0 -47.3,-21.17693 -47.3,-47.3v0c0,-26.12307 21.17693,-47.3 47.3,-47.3h0c26.12307,0 47.3,21.17693 47.3,47.3v0c0,26.12307 -21.17693,47.3 -47.3,47.3z"
                          fill="none"></path>
                  <path
                          d="M86,139.32c-29.44782,0 -53.32,-23.87218 -53.32,-53.32v0c0,-29.44782 23.87218,-53.32 53.32,-53.32v0c29.44782,0 53.32,23.87218 53.32,53.32v0c0,29.44782 -23.87218,53.32 -53.32,53.32z"
                          fill="none"></path>
                  <path
                          d="M86,132.44c-25.6481,0 -46.44,-20.7919 -46.44,-46.44v0c0,-25.6481 20.7919,-46.44 46.44,-46.44v0c25.6481,0 46.44,20.7919 46.44,46.44v0c0,25.6481 -20.7919,46.44 -46.44,46.44z"
                          fill="none"></path>
                  <path
                          d="M86,138.46c-28.97286,0 -52.46,-23.48714 -52.46,-52.46v0c0,-28.97286 23.48714,-52.46 52.46,-52.46v0c28.97286,0 52.46,23.48714 52.46,52.46v0c0,28.97286 -23.48714,52.46 -52.46,52.46z"
                          fill="none"></path>
                  <path
                          d="M86,131.58c-25.17314,0 -45.58,-20.40686 -45.58,-45.58v0c0,-25.17314 20.40686,-45.58 45.58,-45.58h0c25.17314,0 45.58,20.40686 45.58,45.58v0c0,25.17314 -20.40686,45.58 -45.58,45.58z"
                          fill="none"></path>
                  <path
                          d="M86,137.6c-28.49789,0 -51.6,-23.10211 -51.6,-51.6v0c0,-28.49789 23.10211,-51.6 51.6,-51.6v0c28.49789,0 51.6,23.10211 51.6,51.6v0c0,28.49789 -23.10211,51.6 -51.6,51.6z"
                          fill="none"></path>
                  <path
                          d="M86,130.72c-24.69817,0 -44.72,-20.02183 -44.72,-44.72v0c0,-24.69817 20.02183,-44.72 44.72,-44.72v0c24.69817,0 44.72,20.02183 44.72,44.72v0c0,24.69817 -20.02183,44.72 -44.72,44.72z"
                          fill="none"></path>
                  <path
                          d="M86,136.74c-28.02293,0 -50.74,-22.71707 -50.74,-50.74v0c0,-28.02293 22.71707,-50.74 50.74,-50.74v0c28.02293,0 50.74,22.71707 50.74,50.74v0c0,28.02293 -22.71707,50.74 -50.74,50.74z"
                          fill="none"></path>
                  <path
                          d="M86,129.86c-24.22321,0 -43.86,-19.63679 -43.86,-43.86v0c0,-24.22321 19.63679,-43.86 43.86,-43.86h0c24.22321,0 43.86,19.63679 43.86,43.86v0c0,24.22321 -19.63679,43.86 -43.86,43.86z"
                          fill="none"></path>
                  <path
                          d="M86,133.3c-26.12307,0 -47.3,-21.17693 -47.3,-47.3v0c0,-26.12307 21.17693,-47.3 47.3,-47.3h0c26.12307,0 47.3,21.17693 47.3,47.3v0c0,26.12307 -21.17693,47.3 -47.3,47.3z"
                          fill="none"></path>
                  <path
                          d="M86,126.42c-22.32335,0 -40.42,-18.09665 -40.42,-40.42v0c0,-22.32335 18.09665,-40.42 40.42,-40.42v0c22.32335,0 40.42,18.09665 40.42,40.42v0c0,22.32335 -18.09665,40.42 -40.42,40.42z"
                          fill="none"></path>
                  <path
                          d="M86,132.44c-25.6481,0 -46.44,-20.7919 -46.44,-46.44v0c0,-25.6481 20.7919,-46.44 46.44,-46.44v0c25.6481,0 46.44,20.7919 46.44,46.44v0c0,25.6481 -20.7919,46.44 -46.44,46.44z"
                          fill="none"></path>
                  <path
                          d="M86,125.56c-21.84838,0 -39.56,-17.71162 -39.56,-39.56v0c0,-21.84838 17.71162,-39.56 39.56,-39.56v0c21.84838,0 39.56,17.71162 39.56,39.56v0c0,21.84838 -17.71162,39.56 -39.56,39.56z"
                          fill="none"></path>
                  <path
                          d="M86,131.58c-25.17314,0 -45.58,-20.40686 -45.58,-45.58v0c0,-25.17314 20.40686,-45.58 45.58,-45.58h0c25.17314,0 45.58,20.40686 45.58,45.58v0c0,25.17314 -20.40686,45.58 -45.58,45.58z"
                          fill="none"></path>
                  <path
                          d="M86,124.7c-21.37342,0 -38.7,-17.32658 -38.7,-38.7v0c0,-21.37342 17.32658,-38.7 38.7,-38.7v0c21.37342,0 38.7,17.32658 38.7,38.7v0c0,21.37342 -17.32658,38.7 -38.7,38.7z"
                          fill="none"></path>
                  <path
                          d="M86,130.72c-24.69817,0 -44.72,-20.02183 -44.72,-44.72v0c0,-24.69817 20.02183,-44.72 44.72,-44.72v0c24.69817,0 44.72,20.02183 44.72,44.72v0c0,24.69817 -20.02183,44.72 -44.72,44.72z"
                          fill="none"></path>
                  <path
                          d="M86,123.84c-20.89845,0 -37.84,-16.94155 -37.84,-37.84v0c0,-20.89845 16.94155,-37.84 37.84,-37.84v0c20.89845,0 37.84,16.94155 37.84,37.84v0c0,20.89845 -16.94155,37.84 -37.84,37.84z"
                          fill="none"></path>
                  <path
                          d="M86,129c-23.74824,0 -43,-19.25176 -43,-43v0c0,-23.74824 19.25176,-43 43,-43v0c23.74824,0 43,19.25176 43,43v0c0,23.74824 -19.25176,43 -43,43z"
                          fill="none"></path>
                  <path
                          d="M86,122.12c-19.94853,0 -36.12,-16.17147 -36.12,-36.12v0c0,-19.94853 16.17147,-36.12 36.12,-36.12v0c19.94853,0 36.12,16.17147 36.12,36.12v0c0,19.94853 -16.17147,36.12 -36.12,36.12z"
                          fill="none"></path>
                  <path
                          d="M86,129.86c-24.22321,0 -43.86,-19.63679 -43.86,-43.86v0c0,-24.22321 19.63679,-43.86 43.86,-43.86h0c24.22321,0 43.86,19.63679 43.86,43.86v0c0,24.22321 -19.63679,43.86 -43.86,43.86z"
                          fill="none"></path>
                  <path
                          d="M86,122.98c-20.42349,0 -36.98,-16.55651 -36.98,-36.98v0c0,-20.42349 16.55651,-36.98 36.98,-36.98v0c20.42349,0 36.98,16.55651 36.98,36.98v0c0,20.42349 -16.55651,36.98 -36.98,36.98z"
                          fill="none"></path>
                  <path
                          d="M86,132.44c-25.6481,0 -46.44,-20.7919 -46.44,-46.44v0c0,-25.6481 20.7919,-46.44 46.44,-46.44v0c25.6481,0 46.44,20.7919 46.44,46.44v0c0,25.6481 -20.7919,46.44 -46.44,46.44z"
                          fill="none"></path>
                  <path
                          d="M86,125.56c-21.84838,0 -39.56,-17.71162 -39.56,-39.56v0c0,-21.84838 17.71162,-39.56 39.56,-39.56v0c21.84838,0 39.56,17.71162 39.56,39.56v0c0,21.84838 -17.71162,39.56 -39.56,39.56z"
                          fill="none"></path>
                  <path
                          d="M86,134.16c-26.59803,0 -48.16,-21.56197 -48.16,-48.16v0c0,-26.59803 21.56197,-48.16 48.16,-48.16v0c26.59803,0 48.16,21.56197 48.16,48.16v0c0,26.59803 -21.56197,48.16 -48.16,48.16z"
                          fill="none"></path>
                  <path
                          d="M86,127.28c-22.79831,0 -41.28,-18.48169 -41.28,-41.28v0c0,-22.79831 18.48169,-41.28 41.28,-41.28v0c22.79831,0 41.28,18.48169 41.28,41.28v0c0,22.79831 -18.48169,41.28 -41.28,41.28z"
                          fill="none"></path>
                  <path
                          d="M86,135.88c-27.54796,0 -49.88,-22.33204 -49.88,-49.88v0c0,-27.54796 22.33204,-49.88 49.88,-49.88v0c27.54796,0 49.88,22.33204 49.88,49.88v0c0,27.54796 -22.33204,49.88 -49.88,49.88z"
                          fill="none"></path>
                  <path
                          d="M86,129c-23.74824,0 -43,-19.25176 -43,-43v0c0,-23.74824 19.25176,-43 43,-43v0c23.74824,0 43,19.25176 43,43v0c0,23.74824 -19.25176,43 -43,43z"
                          fill="none"></path>
                  <path
                          d="M86,136.74c-28.02293,0 -50.74,-22.71707 -50.74,-50.74v0c0,-28.02293 22.71707,-50.74 50.74,-50.74v0c28.02293,0 50.74,22.71707 50.74,50.74v0c0,28.02293 -22.71707,50.74 -50.74,50.74z"
                          fill="none"></path>
                  <path
                          d="M86,129.86c-24.22321,0 -43.86,-19.63679 -43.86,-43.86v0c0,-24.22321 19.63679,-43.86 43.86,-43.86h0c24.22321,0 43.86,19.63679 43.86,43.86v0c0,24.22321 -19.63679,43.86 -43.86,43.86z"
                          fill="none"></path>
                  <path
                          d="M86,138.46c-28.97286,0 -52.46,-23.48714 -52.46,-52.46v0c0,-28.97286 23.48714,-52.46 52.46,-52.46v0c28.97286,0 52.46,23.48714 52.46,52.46v0c0,28.97286 -23.48714,52.46 -52.46,52.46z"
                          fill="none"></path>
                  <path
                          d="M86,131.58c-25.17314,0 -45.58,-20.40686 -45.58,-45.58v0c0,-25.17314 20.40686,-45.58 45.58,-45.58h0c25.17314,0 45.58,20.40686 45.58,45.58v0c0,25.17314 -20.40686,45.58 -45.58,45.58z"
                          fill="none"></path>
                  <path
                          d="M86,139.32c-29.44782,0 -53.32,-23.87218 -53.32,-53.32v0c0,-29.44782 23.87218,-53.32 53.32,-53.32v0c29.44782,0 53.32,23.87218 53.32,53.32v0c0,29.44782 -23.87218,53.32 -53.32,53.32z"
                          fill="none"></path>
                  <path
                          d="M86,132.44c-25.6481,0 -46.44,-20.7919 -46.44,-46.44v0c0,-25.6481 20.7919,-46.44 46.44,-46.44v0c25.6481,0 46.44,20.7919 46.44,46.44v0c0,25.6481 -20.7919,46.44 -46.44,46.44z"
                          fill="none"></path>
                  <path
                          d="M86,140.18c-29.92279,0 -54.18,-24.25721 -54.18,-54.18v0c0,-29.92279 24.25721,-54.18 54.18,-54.18v0c29.92279,0 54.18,24.25721 54.18,54.18v0c0,29.92279 -24.25721,54.18 -54.18,54.18z"
                          fill="none"></path>
                  <path
                          d="M86,133.3c-26.12307,0 -47.3,-21.17693 -47.3,-47.3v0c0,-26.12307 21.17693,-47.3 47.3,-47.3h0c26.12307,0 47.3,21.17693 47.3,47.3v0c0,26.12307 -21.17693,47.3 -47.3,47.3z"
                          fill="none"></path>
                  <path
                          d="M86,141.9c-30.87272,0 -55.9,-25.02728 -55.9,-55.9v0c0,-30.87272 25.02728,-55.9 55.9,-55.9v0c30.87272,0 55.9,25.02728 55.9,55.9v0c0,30.87272 -25.02728,55.9 -55.9,55.9z"
                          fill="none"></path>
                  <path
                          d="M86,135.02c-27.073,0 -49.02,-21.947 -49.02,-49.02v0c0,-27.073 21.947,-49.02 49.02,-49.02h0c27.073,0 49.02,21.947 49.02,49.02v0c0,27.073 -21.947,49.02 -49.02,49.02z"
                          fill="none"></path>
                  <path
                          d="M86,142.76c-31.34768,0 -56.76,-25.41232 -56.76,-56.76v0c0,-31.34768 25.41232,-56.76 56.76,-56.76v0c31.34768,0 56.76,25.41232 56.76,56.76v0c0,31.34768 -25.41232,56.76 -56.76,56.76z"
                          fill="none"></path>
                  <path
                          d="M86,135.88c-27.54796,0 -49.88,-22.33204 -49.88,-49.88v0c0,-27.54796 22.33204,-49.88 49.88,-49.88v0c27.54796,0 49.88,22.33204 49.88,49.88v0c0,27.54796 -22.33204,49.88 -49.88,49.88z"
                          fill="none"></path>
                  <path
                          d="M86,141.9c-30.87272,0 -55.9,-25.02728 -55.9,-55.9v0c0,-30.87272 25.02728,-55.9 55.9,-55.9v0c30.87272,0 55.9,25.02728 55.9,55.9v0c0,30.87272 -25.02728,55.9 -55.9,55.9z"
                          fill="none"></path>
                  <path
                          d="M86,135.02c-27.073,0 -49.02,-21.947 -49.02,-49.02v0c0,-27.073 21.947,-49.02 49.02,-49.02h0c27.073,0 49.02,21.947 49.02,49.02v0c0,27.073 -21.947,49.02 -49.02,49.02z"
                          fill="none"></path>
                  <path
                          d="M86,141.04c-30.39775,0 -55.04,-24.64225 -55.04,-55.04v0c0,-30.39775 24.64225,-55.04 55.04,-55.04v0c30.39775,0 55.04,24.64225 55.04,55.04v0c0,30.39775 -24.64225,55.04 -55.04,55.04z"
                          fill="none"></path>
                  <path
                          d="M86,134.16c-26.59803,0 -48.16,-21.56197 -48.16,-48.16v0c0,-26.59803 21.56197,-48.16 48.16,-48.16v0c26.59803,0 48.16,21.56197 48.16,48.16v0c0,26.59803 -21.56197,48.16 -48.16,48.16z"
                          fill="none"></path>
                  <path
                          d="M86,141.9c-30.87272,0 -55.9,-25.02728 -55.9,-55.9v0c0,-30.87272 25.02728,-55.9 55.9,-55.9v0c30.87272,0 55.9,25.02728 55.9,55.9v0c0,30.87272 -25.02728,55.9 -55.9,55.9z"
                          fill="none"></path>
                  <path
                          d="M86,135.02c-27.073,0 -49.02,-21.947 -49.02,-49.02v0c0,-27.073 21.947,-49.02 49.02,-49.02h0c27.073,0 49.02,21.947 49.02,49.02v0c0,27.073 -21.947,49.02 -49.02,49.02z"
                          fill="none"></path>
                  <path
                          d="M86,143.62c-31.82265,0 -57.62,-25.79735 -57.62,-57.62v0c0,-31.82265 25.79735,-57.62 57.62,-57.62v0c31.82265,0 57.62,25.79735 57.62,57.62v0c0,31.82265 -25.79735,57.62 -57.62,57.62z"
                          fill="none"></path>
                  <path
                          d="M86,136.74c-28.02293,0 -50.74,-22.71707 -50.74,-50.74v0c0,-28.02293 22.71707,-50.74 50.74,-50.74h0c28.02293,0 50.74,22.71707 50.74,50.74v0c0,28.02293 -22.71707,50.74 -50.74,50.74z"
                          fill="none"></path>
                  <path
                          d="M86,146.2c-33.24754,0 -60.2,-26.95246 -60.2,-60.2v0c0,-33.24754 26.95246,-60.2 60.2,-60.2h0c33.24754,0 60.2,26.95246 60.2,60.2v0c0,33.24754 -26.95246,60.2 -60.2,60.2z"
                          fill="none"></path>
                  <path
                          d="M86,139.32c-29.44782,0 -53.32,-23.87218 -53.32,-53.32v0c0,-29.44782 23.87218,-53.32 53.32,-53.32v0c29.44782,0 53.32,23.87218 53.32,53.32v0c0,29.44782 -23.87218,53.32 -53.32,53.32z"
                          fill="none"></path>
                  <path
                          d="M86,147.06c-33.72251,0 -61.06,-27.33749 -61.06,-61.06v0c0,-33.72251 27.33749,-61.06 61.06,-61.06v0c33.72251,0 61.06,27.33749 61.06,61.06v0c0,33.72251 -27.33749,61.06 -61.06,61.06z"
                          fill="none"></path>
                  <path
                          d="M86,140.18c-29.92279,0 -54.18,-24.25721 -54.18,-54.18v0c0,-29.92279 24.25721,-54.18 54.18,-54.18v0c29.92279,0 54.18,24.25721 54.18,54.18v0c0,29.92279 -24.25721,54.18 -54.18,54.18z"
                          fill="none"></path>
                  <path
                          d="M86,148.78c-34.67244,0 -62.78,-28.10756 -62.78,-62.78v0c0,-34.67244 28.10756,-62.78 62.78,-62.78v0c34.67244,0 62.78,28.10756 62.78,62.78v0c0,34.67244 -28.10756,62.78 -62.78,62.78z"
                          fill="none"></path>
                  <path
                          d="M86,141.9c-30.87272,0 -55.9,-25.02728 -55.9,-55.9v0c0,-30.87272 25.02728,-55.9 55.9,-55.9v0c30.87272,0 55.9,25.02728 55.9,55.9v0c0,30.87272 -25.02728,55.9 -55.9,55.9z"
                          fill="none"></path>
                  <path
                          d="M86,149.64c-35.1474,0 -63.64,-28.4926 -63.64,-63.64v0c0,-35.1474 28.4926,-63.64 63.64,-63.64h0c35.1474,0 63.64,28.4926 63.64,63.64v0c0,35.1474 -28.4926,63.64 -63.64,63.64z"
                          fill="none"></path>
                  <path
                          d="M86,142.76c-31.34768,0 -56.76,-25.41232 -56.76,-56.76v0c0,-31.34768 25.41232,-56.76 56.76,-56.76v0c31.34768,0 56.76,25.41232 56.76,56.76v0c0,31.34768 -25.41232,56.76 -56.76,56.76z"
                          fill="none"></path>
                  <path
                          d="M86,149.64c-35.1474,0 -63.64,-28.4926 -63.64,-63.64v0c0,-35.1474 28.4926,-63.64 63.64,-63.64h0c35.1474,0 63.64,28.4926 63.64,63.64v0c0,35.1474 -28.4926,63.64 -63.64,63.64z"
                          fill="none"></path>
                  <path
                          d="M86,142.76c-31.34768,0 -56.76,-25.41232 -56.76,-56.76v0c0,-31.34768 25.41232,-56.76 56.76,-56.76v0c31.34768,0 56.76,25.41232 56.76,56.76v0c0,31.34768 -25.41232,56.76 -56.76,56.76z"
                          fill="none"></path>
                  <path
                          d="M86,149.64c-35.1474,0 -63.64,-28.4926 -63.64,-63.64v0c0,-35.1474 28.4926,-63.64 63.64,-63.64h0c35.1474,0 63.64,28.4926 63.64,63.64v0c0,35.1474 -28.4926,63.64 -63.64,63.64z"
                          fill="none"></path>
                  <path
                          d="M86,142.76c-31.34768,0 -56.76,-25.41232 -56.76,-56.76v0c0,-31.34768 25.41232,-56.76 56.76,-56.76v0c31.34768,0 56.76,25.41232 56.76,56.76v0c0,31.34768 -25.41232,56.76 -56.76,56.76z"
                          fill="none"></path>
                  <path
                          d="M86,149.64c-35.1474,0 -63.64,-28.4926 -63.64,-63.64v0c0,-35.1474 28.4926,-63.64 63.64,-63.64h0c35.1474,0 63.64,28.4926 63.64,63.64v0c0,35.1474 -28.4926,63.64 -63.64,63.64z"
                          fill="none"></path>
                  <path
                          d="M86,142.76c-31.34768,0 -56.76,-25.41232 -56.76,-56.76v0c0,-31.34768 25.41232,-56.76 56.76,-56.76v0c31.34768,0 56.76,25.41232 56.76,56.76v0c0,31.34768 -25.41232,56.76 -56.76,56.76z"
                          fill="none"></path>
                  <path
                          d="M86,149.64c-35.1474,0 -63.64,-28.4926 -63.64,-63.64v0c0,-35.1474 28.4926,-63.64 63.64,-63.64h0c35.1474,0 63.64,28.4926 63.64,63.64v0c0,35.1474 -28.4926,63.64 -63.64,63.64z"
                          fill="none"></path>
                  <path
                          d="M86,142.76c-31.34768,0 -56.76,-25.41232 -56.76,-56.76v0c0,-31.34768 25.41232,-56.76 56.76,-56.76v0c31.34768,0 56.76,25.41232 56.76,56.76v0c0,31.34768 -25.41232,56.76 -56.76,56.76z"
                          fill="none"></path>
                  <path
                          d="M86,149.64c-35.1474,0 -63.64,-28.4926 -63.64,-63.64v0c0,-35.1474 28.4926,-63.64 63.64,-63.64h0c35.1474,0 63.64,28.4926 63.64,63.64v0c0,35.1474 -28.4926,63.64 -63.64,63.64z"
                          fill="none"></path>
                  <path
                          d="M86,142.76c-31.34768,0 -56.76,-25.41232 -56.76,-56.76v0c0,-31.34768 25.41232,-56.76 56.76,-56.76v0c31.34768,0 56.76,25.41232 56.76,56.76v0c0,31.34768 -25.41232,56.76 -56.76,56.76z"
                          fill="none"></path>
                  <path
                          d="M86,149.64c-35.1474,0 -63.64,-28.4926 -63.64,-63.64v0c0,-35.1474 28.4926,-63.64 63.64,-63.64h0c35.1474,0 63.64,28.4926 63.64,63.64v0c0,35.1474 -28.4926,63.64 -63.64,63.64z"
                          fill="none"></path>
                  <path
                          d="M86,142.76c-31.34768,0 -56.76,-25.41232 -56.76,-56.76v0c0,-31.34768 25.41232,-56.76 56.76,-56.76v0c31.34768,0 56.76,25.41232 56.76,56.76v0c0,31.34768 -25.41232,56.76 -56.76,56.76z"
                          fill="none"></path>
                  <path
                          d="M86,149.64c-35.1474,0 -63.64,-28.4926 -63.64,-63.64v0c0,-35.1474 28.4926,-63.64 63.64,-63.64h0c35.1474,0 63.64,28.4926 63.64,63.64v0c0,35.1474 -28.4926,63.64 -63.64,63.64z"
                          fill="none"></path>
                  <path
                          d="M86,142.76c-31.34768,0 -56.76,-25.41232 -56.76,-56.76v0c0,-31.34768 25.41232,-56.76 56.76,-56.76v0c31.34768,0 56.76,25.41232 56.76,56.76v0c0,31.34768 -25.41232,56.76 -56.76,56.76z"
                          fill="none"></path>
                  <path
                          d="M86,149.64c-35.1474,0 -63.64,-28.4926 -63.64,-63.64v0c0,-35.1474 28.4926,-63.64 63.64,-63.64h0c35.1474,0 63.64,28.4926 63.64,63.64v0c0,35.1474 -28.4926,63.64 -63.64,63.64z"
                          fill="none"></path>
                  <path
                          d="M86,142.76c-31.34768,0 -56.76,-25.41232 -56.76,-56.76v0c0,-31.34768 25.41232,-56.76 56.76,-56.76v0c31.34768,0 56.76,25.41232 56.76,56.76v0c0,31.34768 -25.41232,56.76 -56.76,56.76z"
                          fill="none"></path>
                  <path
                          d="M86,149.64c-35.1474,0 -63.64,-28.4926 -63.64,-63.64v0c0,-35.1474 28.4926,-63.64 63.64,-63.64h0c35.1474,0 63.64,28.4926 63.64,63.64v0c0,35.1474 -28.4926,63.64 -63.64,63.64z"
                          fill="none"></path>
                  <path
                          d="M86,142.76c-31.34768,0 -56.76,-25.41232 -56.76,-56.76v0c0,-31.34768 25.41232,-56.76 56.76,-56.76v0c31.34768,0 56.76,25.41232 56.76,56.76v0c0,31.34768 -25.41232,56.76 -56.76,56.76z"
                          fill="none"></path>
                  <path
                          d="M86,149.64c-35.1474,0 -63.64,-28.4926 -63.64,-63.64v0c0,-35.1474 28.4926,-63.64 63.64,-63.64h0c35.1474,0 63.64,28.4926 63.64,63.64v0c0,35.1474 -28.4926,63.64 -63.64,63.64z"
                          fill="none"></path>
                  <path
                          d="M86,142.76c-31.34768,0 -56.76,-25.41232 -56.76,-56.76v0c0,-31.34768 25.41232,-56.76 56.76,-56.76v0c31.34768,0 56.76,25.41232 56.76,56.76v0c0,31.34768 -25.41232,56.76 -56.76,56.76z"
                          fill="none"></path>
                  <path
                          d="M86,149.64c-35.1474,0 -63.64,-28.4926 -63.64,-63.64v0c0,-35.1474 28.4926,-63.64 63.64,-63.64h0c35.1474,0 63.64,28.4926 63.64,63.64v0c0,35.1474 -28.4926,63.64 -63.64,63.64z"
                          fill="none"></path>
                  <path
                          d="M86,142.76c-31.34768,0 -56.76,-25.41232 -56.76,-56.76v0c0,-31.34768 25.41232,-56.76 56.76,-56.76v0c31.34768,0 56.76,25.41232 56.76,56.76v0c0,31.34768 -25.41232,56.76 -56.76,56.76z"
                          fill="none"></path>
                  <path
                          d="M86,149.64c-35.1474,0 -63.64,-28.4926 -63.64,-63.64v0c0,-35.1474 28.4926,-63.64 63.64,-63.64h0c35.1474,0 63.64,28.4926 63.64,63.64v0c0,35.1474 -28.4926,63.64 -63.64,63.64z"
                          fill="none"></path>
                  <path
                          d="M86,142.76c-31.34768,0 -56.76,-25.41232 -56.76,-56.76v0c0,-31.34768 25.41232,-56.76 56.76,-56.76v0c31.34768,0 56.76,25.41232 56.76,56.76v0c0,31.34768 -25.41232,56.76 -56.76,56.76z"
                          fill="none"></path>
                  <path
                          d="M86,149.64c-35.1474,0 -63.64,-28.4926 -63.64,-63.64v0c0,-35.1474 28.4926,-63.64 63.64,-63.64h0c35.1474,0 63.64,28.4926 63.64,63.64v0c0,35.1474 -28.4926,63.64 -63.64,63.64z"
                          fill="none"></path>
                  <path
                          d="M86,142.76c-31.34768,0 -56.76,-25.41232 -56.76,-56.76v0c0,-31.34768 25.41232,-56.76 56.76,-56.76v0c31.34768,0 56.76,25.41232 56.76,56.76v0c0,31.34768 -25.41232,56.76 -56.76,56.76z"
                          fill="none"></path>
                  <path
                          d="M86,149.64c-35.1474,0 -63.64,-28.4926 -63.64,-63.64v0c0,-35.1474 28.4926,-63.64 63.64,-63.64h0c35.1474,0 63.64,28.4926 63.64,63.64v0c0,35.1474 -28.4926,63.64 -63.64,63.64z"
                          fill="none"></path>
                  <path
                          d="M86,142.76c-31.34768,0 -56.76,-25.41232 -56.76,-56.76v0c0,-31.34768 25.41232,-56.76 56.76,-56.76v0c31.34768,0 56.76,25.41232 56.76,56.76v0c0,31.34768 -25.41232,56.76 -56.76,56.76z"
                          fill="none"></path>
                  <path
                          d="M86,149.64c-35.1474,0 -63.64,-28.4926 -63.64,-63.64v0c0,-35.1474 28.4926,-63.64 63.64,-63.64h0c35.1474,0 63.64,28.4926 63.64,63.64v0c0,35.1474 -28.4926,63.64 -63.64,63.64z"
                          fill="none"></path>
                  <path
                          d="M86,142.76c-31.34768,0 -56.76,-25.41232 -56.76,-56.76v0c0,-31.34768 25.41232,-56.76 56.76,-56.76v0c31.34768,0 56.76,25.41232 56.76,56.76v0c0,31.34768 -25.41232,56.76 -56.76,56.76z"
                          fill="none"></path>
                  <path
                          d="M86,149.64c-35.1474,0 -63.64,-28.4926 -63.64,-63.64v0c0,-35.1474 28.4926,-63.64 63.64,-63.64h0c35.1474,0 63.64,28.4926 63.64,63.64v0c0,35.1474 -28.4926,63.64 -63.64,63.64z"
                          fill="none"></path>
                  <path
                          d="M86,142.76c-31.34768,0 -56.76,-25.41232 -56.76,-56.76v0c0,-31.34768 25.41232,-56.76 56.76,-56.76v0c31.34768,0 56.76,25.41232 56.76,56.76v0c0,31.34768 -25.41232,56.76 -56.76,56.76z"
                          fill="none"></path>
                  <path
                          d="M86,149.64c-35.1474,0 -63.64,-28.4926 -63.64,-63.64v0c0,-35.1474 28.4926,-63.64 63.64,-63.64h0c35.1474,0 63.64,28.4926 63.64,63.64v0c0,35.1474 -28.4926,63.64 -63.64,63.64z"
                          fill="none"></path>
                  <path
                          d="M86,142.76c-31.34768,0 -56.76,-25.41232 -56.76,-56.76v0c0,-31.34768 25.41232,-56.76 56.76,-56.76v0c31.34768,0 56.76,25.41232 56.76,56.76v0c0,31.34768 -25.41232,56.76 -56.76,56.76z"
                          fill="none"></path>
                  <path
                          d="M86,149.64c-35.1474,0 -63.64,-28.4926 -63.64,-63.64v0c0,-35.1474 28.4926,-63.64 63.64,-63.64h0c35.1474,0 63.64,28.4926 63.64,63.64v0c0,35.1474 -28.4926,63.64 -63.64,63.64z"
                          fill="none"></path>
                  <path
                          d="M86,142.76c-31.34768,0 -56.76,-25.41232 -56.76,-56.76v0c0,-31.34768 25.41232,-56.76 56.76,-56.76v0c31.34768,0 56.76,25.41232 56.76,56.76v0c0,31.34768 -25.41232,56.76 -56.76,56.76z"
                          fill="none"></path>
                  <path
                          d="M86,151.36c-36.09733,0 -65.36,-29.26267 -65.36,-65.36v0c0,-36.09733 29.26267,-65.36 65.36,-65.36h0c36.09733,0 65.36,29.26267 65.36,65.36v0c0,36.09733 -29.26267,65.36 -65.36,65.36z"
                          fill="none"></path>
                  <path
                          d="M86,144.48c-32.29761,0 -58.48,-26.18239 -58.48,-58.48v0c0,-32.29761 26.18239,-58.48 58.48,-58.48v0c32.29761,0 58.48,26.18239 58.48,58.48v0c0,32.29761 -26.18239,58.48 -58.48,58.48z"
                          fill="none"></path>
                  <path
                          d="M86,154.8c-37.99719,0 -68.8,-30.80281 -68.8,-68.8v0c0,-37.99719 30.80281,-68.8 68.8,-68.8h0c37.99719,0 68.8,30.80281 68.8,68.8v0c0,37.99719 -30.80281,68.8 -68.8,68.8z"
                          fill="none"></path>
                  <path
                          d="M86,147.92c-34.19747,0 -61.92,-27.72253 -61.92,-61.92v0c0,-34.19747 27.72253,-61.92 61.92,-61.92v0c34.19747,0 61.92,27.72253 61.92,61.92v0c0,34.19747 -27.72253,61.92 -61.92,61.92z"
                          fill="none"></path>
                  <path
                          d="M86,157.38c-39.42209,0 -71.38,-31.95791 -71.38,-71.38v0c0,-39.42209 31.95791,-71.38 71.38,-71.38v0c39.42209,0 71.38,31.95791 71.38,71.38v0c0,39.42209 -31.95791,71.38 -71.38,71.38z"
                          fill="none"></path>
                  <path
                          d="M86,150.5c-35.62237,0 -64.5,-28.87763 -64.5,-64.5v0c0,-35.62237 28.87763,-64.5 64.5,-64.5v0c35.62237,0 64.5,28.87763 64.5,64.5v0c0,35.62237 -28.87763,64.5 -64.5,64.5z"
                          fill="none"></path>
                  <path
                          d="M86,159.96c-40.84698,0 -73.96,-33.11302 -73.96,-73.96v0c0,-40.84698 33.11302,-73.96 73.96,-73.96h0c40.84698,0 73.96,33.11302 73.96,73.96v0c0,40.84698 -33.11302,73.96 -73.96,73.96z"
                          fill="none"></path>
                  <path
                          d="M86,153.08c-37.04726,0 -67.08,-30.03274 -67.08,-67.08v0c0,-37.04726 30.03274,-67.08 67.08,-67.08h0c37.04726,0 67.08,30.03274 67.08,67.08v0c0,37.04726 -30.03274,67.08 -67.08,67.08z"
                          fill="none"></path>
                  <path
                          d="M86,159.96c-40.84698,0 -73.96,-33.11302 -73.96,-73.96v0c0,-40.84698 33.11302,-73.96 73.96,-73.96h0c40.84698,0 73.96,33.11302 73.96,73.96v0c0,40.84698 -33.11302,73.96 -73.96,73.96z"
                          fill="none"></path>
                  <path
                          d="M86,153.08c-37.04726,0 -67.08,-30.03274 -67.08,-67.08v0c0,-37.04726 30.03274,-67.08 67.08,-67.08h0c37.04726,0 67.08,30.03274 67.08,67.08v0c0,37.04726 -30.03274,67.08 -67.08,67.08z"
                          fill="none"></path>
                  <path d="" fill="none"></path>
                  <path
                          d="M86,159.96c-40.84698,0 -73.96,-33.11302 -73.96,-73.96v0c0,-40.84698 33.11302,-73.96 73.96,-73.96v0c40.84698,0 73.96,33.11302 73.96,73.96v0c0,40.84698 -33.11302,73.96 -73.96,73.96z"
                          fill="none"></path>
                  <path
                          d="M86,153.08c-37.04726,0 -67.08,-30.03274 -67.08,-67.08v0c0,-37.04726 30.03274,-67.08 67.08,-67.08v0c37.04726,0 67.08,30.03274 67.08,67.08v0c0,37.04726 -30.03274,67.08 -67.08,67.08z"
                          fill="none"></path>
                  <path
                          d="M86,159.96c-40.84698,0 -73.96,-33.11302 -73.96,-73.96v0c0,-40.84698 33.11302,-73.96 73.96,-73.96v0c40.84698,0 73.96,33.11302 73.96,73.96v0c0,40.84698 -33.11302,73.96 -73.96,73.96z"
                          fill="none"></path>
                  <path
                          d="M86,153.08c-37.04726,0 -67.08,-30.03274 -67.08,-67.08v0c0,-37.04726 30.03274,-67.08 67.08,-67.08v0c37.04726,0 67.08,30.03274 67.08,67.08v0c0,37.04726 -30.03274,67.08 -67.08,67.08z"
                          fill="none"></path>
                </g>
              </g>
            </svg>
          </div>
        </div>
      </div>
    </div>

  </ng-template>
  <ng-template #cardExpandable>
    <div class="sq-audio-card-list__card-expandable">
      <div class="sq-audio-card-list__card-row">
        <div class="sq-audio-card-list__card-item _label">
          Speech text:
        </div>
        <div class="sq-audio-card-list__card-item _text">
          {{node?.originalEntity?.speech_text}}
        </div>
      </div>
    </div>
  </ng-template>

</ng-template>
