import {Injectable, Injector} from "@angular/core";
import {Observable, Subscriber} from "rxjs";
import {ApiPath} from "../../common/services/api.path";
import {ApiService} from "../../common/services/api.service";
import {QueryParam} from "../../common/models/query-param.type";
import {
  DeviceStats24,
  DeviceStats24Map, KpiItem, KpiStats24,
  StatisticsDataEntry, StatisticsDataStyledVisualEntry,
  StatisticsEntity, StatisticsEntityBase,
  StatisticsEntityBaseWithLastValueTimestamp, SummaryStatisticsItem
} from "../../common/models/entity/statistics-entity.model";
import {ChartPeriodModel} from "./model/chart-period.model";
import {ReportRequestParametersModel} from "./model/report-request-parameters.model";
import {StatisticPeriodType} from "../../common/models/statistic-period.type";
import {ModelService} from "../../common/services/model.service";
import {CssService} from "src/app/common/services/css.service";

@Injectable()
export class ReportsService extends ApiService {
  protected readonly baseUrl: string = ApiPath.STATISTICS_PATH;
  protected readonly getStatisticsUrlUnion: string[] = [this.baseUrl + "/", "./json/statistics-union-device.json"];
  //protected readonly getStatisticsUrlUnion: string[] = [this.baseUrl, "./json/statistics-union-device-empty.json"];
  protected readonly getStatisticsUrlSeparated: string[] = [this.baseUrl + "/", "./json/statistics-per-device.json"];
  protected readonly getStatisticsUrlDaysUnion: string[] = [this.baseUrl + "/", "./json/statistics-union-device-days.json"];
  protected readonly getStatisticsUrlDaysSeparated: string[] = [this.baseUrl + "/", "./json/statistics-per-device-days.json"];
  protected readonly getStatisticsUrlMonthsUnion: string[] = [this.baseUrl + "/", "./json/statistics-union-device-month.json"];
  protected readonly getStatisticsUrlMonthsSeparated: string[] = [this.baseUrl + "/", "./json/statistics-per-device-month.json"];

  protected readonly getStatisticsUrlDevices: string[] = [ApiPath.STATISTICS_PHONES_PATH, "./json/statistics-devices-24.json"];
  protected readonly getStatisticsUrlTasks: string[] = [ApiPath.STATISTICS_TASKS_PATH, "./json/statistics-tasks-24.json"];

  private modelService: ModelService;
  private cssService: CssService;

  constructor(protected injector: Injector) {
    super(injector);
    this.modelService = injector.get(ModelService);
    this.cssService = injector.get(CssService);
  }

  public getReportData(startDate: Date, endDate: Date, scale: string, united: boolean, phonesList: string[], testsList: string[]): Observable<StatisticsEntity[]> {
    return this.getReportDataEx({
      startDate: startDate,
      endDate: endDate,
      scale: scale as StatisticPeriodType,
      united: united,
      phonesList: phonesList,
      testsList: testsList
    })
  }

  public getReportDataEx(reportRequestParams: ReportRequestParametersModel): Observable<StatisticsEntity[]> {
    let o = Observable.create((subscriber: Subscriber<StatisticsEntity[]>) => {
      let queryParams: QueryParam[] = [
        {
          key: ApiPath.START_DATE,
          value: reportRequestParams.startDate.getTime()/1000
        },
        {
          key: ApiPath.END_DATE,
          value: reportRequestParams.endDate.getTime()/1000
        },
        {
          key: ApiPath.SCALE,
          value: reportRequestParams.scale
        },
        {
          key: ApiPath.UNITED,
          value: reportRequestParams.united
        }
        ];

      reportRequestParams.phonesList.forEach((device: string) => {
        queryParams.push({
          key: ApiPath.PHONES,
          value: device
        });
      });

      reportRequestParams.testsList.forEach((test: string) => {
        queryParams.push({
          key: ApiPath.TASK_ID,
          value: test
        });
      });

      let url = reportRequestParams.united ? this.getStatisticsUrlUnion : this.getStatisticsUrlSeparated;
      if (reportRequestParams.scale === ChartPeriodModel.PERIOD_DAYS) {
        url = reportRequestParams.united ? this.getStatisticsUrlDaysUnion : this.getStatisticsUrlDaysSeparated;
      }
      else if (reportRequestParams.scale === ChartPeriodModel.PERIOD_MONTHS) {
        url = reportRequestParams.united ? this.getStatisticsUrlMonthsUnion : this.getStatisticsUrlMonthsSeparated;
      }

      let get = this.get_collection(url, {
        queryParams: queryParams
      }).subscribe(async (items: StatisticsEntity[]) => {
        subscriber.next(items);
        subscriber.complete();
      });

      return () => {
        get && get.unsubscribe();
      };
    });
    return o;
  }

  public getDevicesStatistics(reportRequestParams: ReportRequestParametersModel): Observable<DeviceStats24Map> {
    let o = Observable.create((subscriber: Subscriber<DeviceStats24Map>) => {

      let duration = this.intDiv((reportRequestParams.endDate.getTime() - reportRequestParams.startDate.getTime()), 1000);

      let queryParams: QueryParam[] = [
        {
          key: ApiPath.DURATION,
          value: duration
        },
      ];

      if (reportRequestParams.phonesList !== undefined) {
        reportRequestParams.phonesList.forEach((device: string) => {
          queryParams.push({
            key: ApiPath.PHONES,
            value: device
          });
        });
      }
      else if (reportRequestParams.phonesIds !== undefined) {
        reportRequestParams.phonesIds.forEach((deviceID: number) => {
          queryParams.push({
            key: ApiPath.PHONE_ID,
            value: deviceID
          });
        });
      }

      let get = this.get_map(this.getStatisticsUrlDevices, {
        queryParams: queryParams
      }).subscribe(async (items: DeviceStats24Map) => {
        subscriber.next(items);
        subscriber.complete();
      });

      return () => {
        get && get.unsubscribe();
      };
    });
    return o;
  }

  public getTasksStatistics(reportRequestParams: ReportRequestParametersModel): Observable<DeviceStats24Map> {
    let o = Observable.create((subscriber: Subscriber<DeviceStats24Map>) => {

      let duration = this.intDiv((reportRequestParams.endDate.getTime() - reportRequestParams.startDate.getTime()), 1000);

      let queryParams: QueryParam[] = [
        {
          key: ApiPath.DURATION,
          value: duration
        },
      ];

      if (reportRequestParams.testsList !== undefined) {
        reportRequestParams.testsList.forEach((test: string) => {
          queryParams.push({
            key: ApiPath.TASK_ID,
            value: test
          });
        });
      }
      else if (reportRequestParams.testsIds !== undefined) {
        reportRequestParams.testsIds.forEach((testID: number) => {
          queryParams.push({
            key: ApiPath.TASK_ID,
            value: testID
          });
        });
      }

      let get = this.get_map(this.getStatisticsUrlTasks, {
        queryParams: queryParams
      }).subscribe(async (items: DeviceStats24Map) => {
        subscriber.next(items);
        subscriber.complete();
      });

      return () => {
        get && get.unsubscribe();
      };
    });
    return o;
  }

  public convertSummaryStatisticsPerItem(entities: string[], entityStatistics: DeviceStats24Map): Map<string, StatisticsEntityBase<StatisticsDataEntry>> {
    let summaryMap: Map<string, StatisticsEntityBase<StatisticsDataEntry>> = new Map<string, StatisticsEntityBase<StatisticsDataEntry>>();
    if (entityStatistics) {
      for (let statKey of entityStatistics.keys()) {
        let id = statKey;
        let statItem: DeviceStats24 = new Map(Object.entries(entityStatistics.get(statKey)));

        let deleteIdx = entities.indexOf(id);
        if (deleteIdx >= 0) {
          entities.splice(deleteIdx, 1);
        }

        let summaryItem: StatisticsEntityBase<StatisticsDataEntry>;
        summaryItem = summaryMap.get(id);
        if (summaryItem === undefined || summaryItem === null) {
          summaryItem = new StatisticsEntityBase<StatisticsDataEntry>();
          summaryMap.set(id, summaryItem);
        }

        if (statItem.has(KpiItem.kpiMosPvqa)) {
          summaryItem.mos_pvqa = this.setStatisticsDataEntry(summaryItem.mos_pvqa, statItem.get(KpiItem.kpiMosPvqa));
        }

        if (statItem.has(KpiItem.kpiMosAqua)) {
          summaryItem.mos_aqua = this.setStatisticsDataEntry(summaryItem.mos_aqua, statItem.get(KpiItem.kpiMosAqua));
        }

        if (statItem.has(KpiItem.kpiMosNetwork)) {
          summaryItem.mos_network = this.setStatisticsDataEntry(summaryItem.mos_network, statItem.get(KpiItem.kpiMosNetwork));
        }

        if (statItem.has(KpiItem.kpiRfactor)) {
          summaryItem.r_factor = this.setStatisticsDataEntry(summaryItem.r_factor, statItem.get(KpiItem.kpiRfactor));
        }

        if (statItem.has(KpiItem.kpiPercents)) {
          summaryItem.percents_aqua = this.setStatisticsDataEntry(summaryItem.percents_aqua, statItem.get(KpiItem.kpiPercents));
        }
      }

      for (let item of entities) {
        summaryMap.set(item, new StatisticsEntityBase<StatisticsDataEntry>());
      }
    }

    return summaryMap;
  }

  private setStatisticsDataEntry(entry: StatisticsDataEntry, kpiItem: KpiStats24): StatisticsDataEntry {
    entry.average = kpiItem.average;
    entry.min = kpiItem.min;
    entry.min_timestamp = kpiItem.min_probe.endtime*1000;
    entry.min_probe_id = kpiItem.min_probe.id;
    entry.max = kpiItem.max;
    entry.max_timestamp = kpiItem.max_probe.endtime*1000;
    entry.max_probe_id = kpiItem.max_probe.id;
    entry.last = kpiItem.last;
    entry.last_timestamp = kpiItem.last_probe.endtime*1000;
    entry.last_probe_id = kpiItem.last_probe.id;

    return entry;
  }

  public calculateSummaryStatistics(entityStatistics: StatisticsEntity[]): StatisticsEntityBaseWithLastValueTimestamp<StatisticsDataEntry> {
    let summary: StatisticsEntityBaseWithLastValueTimestamp<StatisticsDataEntry> = new StatisticsEntityBaseWithLastValueTimestamp<StatisticsDataEntry>();

    if (entityStatistics) {
      for (let statValue of entityStatistics) {
        this.calculateEntitySummaryStatistics(summary, statValue);
      }

      this.calculateAverageValues(summary);
    }

    return summary;
  }

  public calculateSummaryVisualStatisticsPerItem(summaryMap: Map<string, StatisticsEntityBase<StatisticsDataEntry>>): Map<string, StatisticsEntityBaseWithLastValueTimestamp<StatisticsDataStyledVisualEntry>> {
    let summaryVisualMap: Map<string, StatisticsEntityBaseWithLastValueTimestamp<StatisticsDataStyledVisualEntry>> = new Map<string, StatisticsEntityBaseWithLastValueTimestamp<StatisticsDataStyledVisualEntry>>();
    for (let k of summaryMap.keys()) {
      let summary = summaryMap.get(k);

      let summaryVisual: StatisticsEntityBaseWithLastValueTimestamp<StatisticsDataStyledVisualEntry> =
        this.calculateSummaryVisualStatistics(summary);

      summaryVisualMap.set(k, summaryVisual);
    }
    return summaryVisualMap;
  }

  public calculateSummaryVisualStatistics(summary: StatisticsEntityBaseWithLastValueTimestamp<StatisticsDataEntry>): StatisticsEntityBaseWithLastValueTimestamp<StatisticsDataStyledVisualEntry> {
    let summaryVisual: StatisticsEntityBaseWithLastValueTimestamp<StatisticsDataStyledVisualEntry> =
      new StatisticsEntityBaseWithLastValueTimestamp<StatisticsDataStyledVisualEntry>();
    summaryVisual.lastValueTimestampStart = summary ? summary.lastValueTimestampStart : 0;
    summaryVisual.lastValueTimestampEnd = summary ? summary.lastValueTimestampEnd : 0;

    summaryVisual.mos_pvqa = this.calculateItemVisualStatistics(summary ? summary.mos_pvqa : undefined);
    summaryVisual.mos_aqua = this.calculateItemVisualStatistics(summary ? summary.mos_aqua : undefined);
    summaryVisual.r_factor = this.calculateItemVisualStatistics(summary ? summary.r_factor : undefined);
    summaryVisual.percents_aqua = this.calculateItemVisualStatistics(summary ? summary.percents_aqua : undefined);
    summaryVisual.mos_network = this.calculateItemVisualStatistics(summary ? summary.mos_network : undefined);

    return summaryVisual;
  }

  public calculateItemVisualStatistics(item: StatisticsDataEntry): StatisticsDataStyledVisualEntry {
    let result: StatisticsDataStyledVisualEntry = new StatisticsDataStyledVisualEntry();
    result.Sum = (item && item.Sum) ? item.Sum.toFixed(0) : 'no data';
    result.Count = (item && item.Count) ? item.Count.toFixed(0) : 'no data';
    result.average = (item && item.average) ? item.average.toFixed(2) : 'no data';
    result.min = (item && item.min) ? item.min.toFixed(2) : 'no data';
    result.min_timestamp = (item && item.min_timestamp) ? item.min_timestamp : 0;
    result.min_probe_id = (item && item.min_probe_id) ? item.min_probe_id : '';
    result.max = (item && item.max) ? item.max.toFixed(2) : 'no data';
    result.max_timestamp = (item && item.max_timestamp) ? item.max_timestamp : 0;
    result.max_probe_id = (item && item.max_probe_id) ? item.max_probe_id : '';
    result.last = (item && item.last) ? item.last.toFixed(2) : 'no data';
    result.last_timestamp = (item && item.last_timestamp) ? item.last_timestamp : 0;
    result.last_probe_id = (item && item.last_probe_id) ? item.last_probe_id : '';
    result.avgLevel = this.cssService.getLevel((item) ? item.average : undefined);
    result.minLevel = this.cssService.getLevel((item) ? item.min : undefined);
    result.maxLevel = this.cssService.getLevel((item) ? item.max : undefined);
    result.lastLevel = this.cssService.getLevel((item) ? item.last : undefined);

    return result;
  }

  private calculateEntitySummaryStatistics(entitySummary: StatisticsEntityBaseWithLastValueTimestamp<StatisticsDataEntry>, entityStatistic: StatisticsEntity): void {
    if (entitySummary && entityStatistic) {

      if (entityStatistic.start_timestamp * 1000 > entitySummary.lastValueTimestampStart) {
        entitySummary.lastValueTimestampStart = entityStatistic.start_timestamp * 1000;
        entitySummary.lastValueTimestampEnd = entityStatistic.end_timestamp * 1000;
      }
      let end_timestamp = entityStatistic.end_timestamp * 1000;

      entitySummary.mos_pvqa = this.calculateKpiSummaryStatistics(entitySummary.mos_pvqa, end_timestamp, entityStatistic.mos_pvqa);
      entitySummary.mos_aqua = this.calculateKpiSummaryStatistics(entitySummary.mos_aqua, end_timestamp, entityStatistic.mos_aqua);
      entitySummary.r_factor = this.calculateKpiSummaryStatistics(entitySummary.r_factor, end_timestamp, entityStatistic.r_factor);
      entitySummary.percents_aqua = this.calculateKpiSummaryStatistics(entitySummary.percents_aqua, end_timestamp, entityStatistic.percents_aqua);
      entitySummary.mos_network = this.calculateKpiSummaryStatistics(entitySummary.mos_network, end_timestamp, entityStatistic.mos_network);
    }
  }

  private calculateKpiSummaryStatistics(kpiSummary: StatisticsDataEntry, end_timestamp: number, kpiStatistic: StatisticsDataEntry): StatisticsDataEntry {
    if (kpiStatistic && kpiStatistic.Count > 0) {
      if (!kpiSummary || !kpiSummary.initiated) {
        kpiSummary = <StatisticsDataEntry> this.modelService.deepCopy(kpiStatistic);
        kpiSummary.min_timestamp = end_timestamp;
        kpiSummary.max_timestamp = end_timestamp;
        kpiSummary.initiated = true;
      } else {
        kpiSummary.Sum += kpiStatistic.Sum;
        kpiSummary.Count += kpiStatistic.Count;
        if (kpiStatistic.min < kpiSummary.min) {
          kpiSummary.min = kpiStatistic.min;
          kpiSummary.min_timestamp = end_timestamp;
        }
        if (kpiStatistic.max > kpiSummary.max) {
          kpiSummary.max = kpiStatistic.max;
          kpiSummary.max_timestamp = end_timestamp;
        }
      }
    }
    return kpiSummary;
  }

  private calculateAverageValues(entitySummary: StatisticsEntityBaseWithLastValueTimestamp<StatisticsDataEntry>): void {
    if (entitySummary.mos_pvqa) {
      entitySummary.mos_pvqa.average = entitySummary.mos_pvqa.Count > 0 ? entitySummary.mos_pvqa.Sum / entitySummary.mos_pvqa.Count : undefined;
    }

    if (entitySummary.mos_aqua) {
      entitySummary.mos_aqua.average = entitySummary.mos_aqua.Count > 0 ? entitySummary.mos_aqua.Sum / entitySummary.mos_aqua.Count : undefined;
    }

    if (entitySummary.r_factor) {
      entitySummary.r_factor.average = entitySummary.r_factor.Count > 0 ? entitySummary.r_factor.Sum / entitySummary.r_factor.Count : undefined;
    }

    if (entitySummary.percents_aqua) {
      entitySummary.percents_aqua.average = entitySummary.percents_aqua.Count > 0 ? entitySummary.percents_aqua.Sum / entitySummary.percents_aqua.Count : undefined;
    }

    if (entitySummary.mos_network) {
      entitySummary.mos_network.average = entitySummary.mos_network.Count > 0 ? entitySummary.mos_network.Sum / entitySummary.mos_network.Count : undefined;
    }
  }

  private intDiv(val, div): number {
    return (val - val % div) / div;
  }
}
