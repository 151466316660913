import {NgModule} from "@angular/core";
import {SharedModule} from "../../shared/shared.module";
import {UxNotificationComponent} from "./notification.component";

@NgModule({
    imports: [SharedModule],
    declarations: [UxNotificationComponent],
    exports: [UxNotificationComponent]
})
export class UxNotificationModule {
}