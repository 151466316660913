import {AfterViewInit, Directive, ElementRef, EventEmitter, Input, NgZone, OnDestroy, Output,} from "@angular/core";
import {UxDomHelper} from "../../shared/dom/dom-helper";
import {UxPropertyHandler} from "../../common/decorator/ux-property-handler";
import {UxPropertyValidator} from "../../common/decorator/ux-property-validator";
import {UxPropertyConverter} from "../../common/decorator/ux-property-converter";

export type TooltipPosition = keyof TooltipPositions;
export type TooltipAlign = keyof TooltipAligns;
export type TooltipEvent = keyof TooltipEvents;

export class TooltipPositions {
    right = "right";
    left = "left";
    top = "top";
    bottom = "bottom";
}

const TOOLTIP_POSITIONS = new TooltipPositions();

export class TooltipAligns {
    center = "center";
    left = "left";
    right = "right";
    top = "top";
    bottom = "bottom";
}

const TOOLTIP_ALIGNS = new TooltipAligns();

export class TooltipEvents {
    hover = "hover";
    focus = "focus";
    none = "none";
}

const TOOLTIP_EVENTS = new TooltipEvents();

const CLASS_NAME = {
    tooltip: "ux-tooltip",
    arrow: "ux-tooltip__arrow",
    text: "ux-tooltip__text"
};

interface UxTooltipCoordinates {
    arrowLeft?: number;
    arrowRight?: number;
    arrowTop?: number;
    left: number;
    top: number;
}

@Directive({
    selector: "[uxTooltip]",
    exportAs: "uxTooltip"
})
export class UxTooltipDirective implements AfterViewInit, OnDestroy {

    @UxPropertyHandler({
        afterChange: afterChangeTooltipText
    })
    @Input("uxTooltip")
    public text: string;

    @UxPropertyValidator({
        isValid: isValidPosition,
        message: "Property 'uxTooltipPosition' should be instanceof TooltipPositionType"
    }, "custom")
    @UxPropertyHandler({
        afterChange: afterChangeTooltipPosition
    })
    @Input("uxTooltipPosition")
    public position: TooltipPosition = "top";

    @UxPropertyValidator({
        isValid: isValidAlign,
        message: "Property 'uxTooltipAlign' should be instanceof TooltipAlignType"
    }, "custom")
    @Input("uxTooltipAlign")
    public align: TooltipAlign = "center";

    @UxPropertyConverter("number")
    @Input("uxTooltipMaxWidth")
    public maxWidth: number = 200;

    @UxPropertyHandler({
        afterChange: afterChangeTooltipEvent
    })
    @UxPropertyValidator({
        isValid: isValidTooltipEvent,
        message: "Property 'uxTooltipEvent' should be instanceof TooltipPositionType or Array<TooltipPositionType>"
    }, "custom")
    @Input("uxTooltipEvent")
    public tooltipEvent: TooltipEvent | Array<TooltipEvent> = "hover";

    @Input("uxTooltipAppendTo")
    public appendTo: any = "body";

    @Input("uxTooltipStyleClass")
    public styleClass: string | { [className: string]: boolean };

    @UxPropertyConverter("boolean", false)
    @UxPropertyHandler({
        afterChange: afterChangeDisabled
    })
    @Input("uxTooltipDisabled")
    public disabled: boolean;

    @UxPropertyConverter("boolean", false)
    @UxPropertyHandler({
        beforeChange: beforeChangeVisible,
        afterChange: afterChangeVisible
    })
    @Input("uxTooltipVisible")
    public visible: boolean;

    @Output("uxTooltipToggle")
    public onToggle: EventEmitter<boolean> = new EventEmitter<boolean>();

    /**
     * Use 'none' value for styling by css
     * @type {string} - html color value or 'none'
     */
    @Input("uxTooltipColor")
    public color: string = "#f04d30";

    private viewInititated: boolean = false;

    private tooltipElement: HTMLElement;
    private tooltipArrow: HTMLElement;
    private tooltipTextElement: HTMLElement;
    private trackedEvents: { [key: string]: boolean };
    private eventStates: { [key: string]: boolean };
    private mouseEnterBinded: EventListenerObject;
    private mouseLeaveBinded: EventListenerObject;
    private onFocusBinded: EventListenerObject;
    private onBlurBinded: EventListenerObject;
    private onWindowResizeBinded: EventListenerObject;
    private element: HTMLElement;


    constructor(private targetElement: ElementRef,
                private zone: NgZone) {
        this.element = targetElement.nativeElement;
    }

    public ngAfterViewInit(): void {
        let self = this;

        self.viewInititated = true;

        self.bindEvents();

        if (self.visible) {
            self.show();
            self.onToggle.emit(true);
        }
    }

    public ngOnDestroy(): void {
        let self = this;

        self.removeElement();
        self.unbindEvents();
    }

    private removeElement(): void {
        let self = this;

        if (self.tooltipElement && self.tooltipElement.parentElement) {
            switch (self.appendTo) {
                case "body":
                    document.body.removeChild(self.tooltipElement);
                    break;
                case "target":
                    self.targetElement.nativeElement.removeChild(self.tooltipElement);
                    break;
                default:
                    UxDomHelper.removeChild(self.tooltipElement, self.appendTo);
            }
        }

        self.tooltipElement = null;
    }

    public onMouseEnter(): void {
        let self = this;

        if (self.trackedEvents[TOOLTIP_EVENTS.hover]) {
            self.eventStates[TOOLTIP_EVENTS.hover] = true;
            self.visible = true;
        }
    }

    public onMouseLeave(): void {
        let self = this;

        if (self.trackedEvents[TOOLTIP_EVENTS.hover]) {
            self.eventStates[TOOLTIP_EVENTS.hover] = false;
            self.checkEventStates();
        }
    }

    public onFocus(): void {
        let self = this;

        if (self.trackedEvents[TOOLTIP_EVENTS.focus]) {
            self.eventStates[TOOLTIP_EVENTS.focus] = true;
            self.visible = true;
        }
    }

    public onBlur(): void {
        let self = this;

        if (self.trackedEvents[TOOLTIP_EVENTS.focus]) {
            self.eventStates[TOOLTIP_EVENTS.focus] = false;
            self.checkEventStates();
        }
    }

    public onWindowResize(): void {
        let self = this;

        if (self.trackedEvents[TOOLTIP_EVENTS.focus]) {
            self.updatePosition();
        }
    }

    private updateEventDescriptors(): void {
        let self = this;
        self.trackedEvents = {};
        self.eventStates = {};
        if (typeof self.tooltipEvent === "string") {
            self.trackedEvents[self.tooltipEvent] = true;
        } else if (self.tooltipEvent instanceof Array) {
            self.tooltipEvent.forEach((eventName) => {
                if (eventName in TOOLTIP_EVENTS) {
                    self.trackedEvents[eventName] = true;
                }
            });
        }
    }

    private checkEventStates(): void {
        let self = this;
        for (let eventName in self.eventStates) {
            //noinspection JSUnfilteredForInLoop
            if (self.eventStates[eventName]) {
                return;
            }
        }
        if (Object.keys(self.trackedEvents).length > 0) {
            self.visible = false;
        }
    }

    private show(): void {

        let self = this;

        if (!self.text) {
            self.text = this.targetElement.nativeElement.getAttribute("title");
        }

        if (!self.text || self.disabled) {
            return;
        }

        self.create();
        self.tooltipElement.style.display = "block";
        self.updatePosition();
        UxDomHelper.fadeIn(self.tooltipElement, 250);
        self.tooltipElement.style.zIndex = UxDomHelper.nextZIndex() + "";
    }

    private hide(): void {
        this.removeElement();
    }

    /*TODO Remove this logic in 2.0.0*/
    private create(): void {
        let self = this;

        self.checkTooltipAlign();

        let styleClass = `${CLASS_NAME.tooltip} _${self.position} _align-${self.align}`;

        self.tooltipElement = document.createElement("div");

        if (self.styleClass) {

            if (typeof self.styleClass === "object") {
                for (let className in self.styleClass) {
                    if (self.styleClass[className] === true) {
                        styleClass += ` ${className}`;
                    }
                }
            } else {
                styleClass += " " + self.styleClass;
            }
        }

        self.tooltipElement.className = styleClass;

        self.tooltipArrow = document.createElement("div");
        self.tooltipArrow.className = `${CLASS_NAME.arrow}`;
        self.tooltipElement.appendChild(self.tooltipArrow);

        let tooltipText = document.createElement("div");
        tooltipText.className = `${CLASS_NAME.text}`;
        tooltipText.style.maxWidth = self.maxWidth + "px";

        self.tooltipTextElement = tooltipText;

        if (self.color !== "none") {
            tooltipText.style.backgroundColor = self.color;
            self.tooltipArrow.style[`border${self.position.charAt(0).toUpperCase() + self.position.slice(1)}Color`] = self.color;
        }

        tooltipText.innerHTML = self.text;

        self.tooltipElement.appendChild(tooltipText);
        switch (self.appendTo) {
            case "body":
                document.body.appendChild(self.tooltipElement);
                break;
            case "target":
                document.body.appendChild(self.tooltipElement);
                self.fixateSize();
                document.body.removeChild(self.tooltipElement);
                UxDomHelper.appendChild(self.tooltipElement, self.targetElement.nativeElement);
                break;
            default:
                document.body.appendChild(self.tooltipElement);
                self.fixateSize();
                document.body.removeChild(self.tooltipElement);
                UxDomHelper.appendChild(self.tooltipElement, self.appendTo);
        }
    }

    /******/

    private checkTooltipAlign(): void {
        let self = this;
        switch (self.position) {
            case TOOLTIP_POSITIONS.top:
            case TOOLTIP_POSITIONS.bottom:
                if (self.align === TOOLTIP_ALIGNS.top || self.align === TOOLTIP_ALIGNS.bottom) {
                    self.align = "center";
                }
                break;
            case TOOLTIP_POSITIONS.left:
            case TOOLTIP_POSITIONS.right:
                if (self.align === TOOLTIP_ALIGNS.left || self.align === TOOLTIP_ALIGNS.right) {
                    self.align = "center";
                }
                break;
        }
    }

    private fixateSize(): void {
        let self = this;

        self.tooltipElement.style.display = "block";
        self.tooltipTextElement.style.width = self.tooltipElement.offsetWidth + 1 + "px";
        self.tooltipElement.style.display = undefined;
    }

    private updatePosition(): void {
        let self = this;

        if (!self.visible) {
            return;
        }


        let coordinates: UxTooltipCoordinates = {left: 0, top: 0};

        let targetElement = self.targetElement.nativeElement;
        let targetElementRect = UxDomHelper.getDocumentRelativePosition(targetElement);

        if (self.appendTo === "body") {
            coordinates.left += targetElementRect.left;
            coordinates.top += targetElementRect.top;
        } else if (self.appendTo !== "body" && self.appendTo !== "target") {
            let appendToElement = (<HTMLElement> self.appendTo);
            let appendToElementRect = UxDomHelper.getDocumentRelativePosition(appendToElement);

            coordinates.left += targetElementRect.left - appendToElementRect.left;
            coordinates.top += targetElementRect.top - appendToElementRect.top;
        }


        let tooltipWidth = self.tooltipElement.offsetWidth,
            tooltipHeight = self.tooltipElement.offsetHeight;

        let targetWidth = self.targetElement.nativeElement.offsetWidth,
            targetHeight = self.targetElement.nativeElement.offsetHeight;

        switch (self.position) {
            case TOOLTIP_POSITIONS.top:
                coordinates.top -= tooltipHeight;

                switch (self.align) {
                    case TOOLTIP_ALIGNS.left:
                        // left coordinate remain the same
                        coordinates.left += 0;
                        break;
                    case TOOLTIP_ALIGNS.right:
                        coordinates.left += targetWidth - tooltipWidth;
                        break;
                    case TOOLTIP_ALIGNS.center:
                        coordinates.left += (targetWidth - tooltipWidth) / 2;
                        break;
                }
                self.checkOffsets(coordinates, self.align);
                break;

            case TOOLTIP_POSITIONS.bottom:
                coordinates.top += targetHeight;

                switch (self.align) {
                    case TOOLTIP_ALIGNS.left:
                        // left coordinate remain the same
                        coordinates.left += 0;
                        break;
                    case TOOLTIP_ALIGNS.right:
                        coordinates.left += targetWidth - tooltipWidth;
                        break;
                    case TOOLTIP_ALIGNS.center:
                        coordinates.left += (targetWidth - tooltipWidth) / 2;
                        break;
                }
                self.checkOffsets(coordinates, self.align);

                break;

            case TOOLTIP_POSITIONS.left:
                coordinates.left -= tooltipWidth;

                switch (self.align) {
                    case TOOLTIP_ALIGNS.top:
                        // top coordinate remain the same
                        coordinates.top += 0;
                        break;
                    case TOOLTIP_ALIGNS.bottom:
                        coordinates.top += targetHeight - tooltipHeight;
                        break;
                    case TOOLTIP_ALIGNS.center:
                        coordinates.top += (targetHeight - tooltipHeight) / 2;
                        break;
                }
                break;

            case TOOLTIP_POSITIONS.right:
                coordinates.left += targetWidth;

                switch (self.align) {
                    case TOOLTIP_ALIGNS.top:
                        // top coordinate remain the same
                        coordinates.top += 0;
                        break;
                    case TOOLTIP_ALIGNS.bottom:
                        coordinates.top += targetHeight - tooltipHeight;
                        break;
                    case TOOLTIP_ALIGNS.center:
                        coordinates.top += (targetHeight - tooltipHeight) / 2;
                        break;
                }
                break;
        }


        self.tooltipElement.style.left = coordinates.left + "px";
        self.tooltipElement.style.top = coordinates.top + "px";
        if (coordinates.arrowLeft !== undefined) {
            self.tooltipArrow.style.left = coordinates.arrowLeft + "px";
        }
        if (coordinates.arrowRight !== undefined) {
            self.tooltipArrow.style.right = coordinates.arrowRight + "px";
        }
    }

    /**
     * Search offsets for the tooltip.
     */
    private checkOffsets(coordinates: UxTooltipCoordinates, align: string): void {

        if (!this.isBodyContainer()) {
            return;
        }

        let arrowClientRectWidth = 8;

        let clientSize = UxDomHelper.getClientSize();
        let clientSizeWidth = clientSize.width;

        let tooltipClientRect = UxDomHelper.getDocumentRelativePosition(this.tooltipElement),
            tooltipClientRectWidth = tooltipClientRect.width;

        let leftOffset: number,
            rightOffset: number;

        leftOffset = pageXOffset - coordinates.left;
        rightOffset = (coordinates.left + tooltipClientRectWidth) - (clientSizeWidth + pageXOffset);


        if (rightOffset > 0 || leftOffset > 0) {
            coordinates.left -= rightOffset > 0 ? rightOffset : -leftOffset;

            // arrow
            let arrowLeft: number;

            switch (align) {
                case TOOLTIP_ALIGNS.left:
                    arrowLeft = (arrowClientRectWidth + rightOffset > 0 ? rightOffset : leftOffset);
                    if (arrowLeft > tooltipClientRectWidth - arrowClientRectWidth) {
                        arrowLeft = tooltipClientRectWidth - arrowClientRectWidth;
                    }
                    break;
                case TOOLTIP_ALIGNS.right:
                    if (leftOffset > 0) {
                        let arrowRight = (arrowClientRectWidth + leftOffset);
                        if (arrowRight < arrowClientRectWidth) {
                            arrowRight = arrowClientRectWidth;
                        }
                        coordinates.arrowRight = arrowRight;
                    }
                    break;
                case TOOLTIP_ALIGNS.center:
                    arrowLeft = tooltipClientRectWidth / 2 + (rightOffset > 0 ? rightOffset : -leftOffset);
                    if (arrowLeft > tooltipClientRectWidth - arrowClientRectWidth) {
                        arrowLeft = tooltipClientRectWidth - arrowClientRectWidth;
                    }
                    break;
            }

            coordinates.arrowLeft = arrowLeft;
        }

    }

    private isBodyContainer(): boolean {
        return this.appendTo === "body";
    }

    private bindEvents(): void {
        let self = this;

        self.zone.runOutsideAngular(() => {
            self.mouseEnterBinded = self.onMouseEnter.bind(self);
            self.mouseLeaveBinded = self.onMouseLeave.bind(self);
            self.onFocusBinded = self.onFocus.bind(self);
            self.onBlurBinded = self.onBlur.bind(self);
            self.onWindowResizeBinded = self.onWindowResize.bind(self);

            self.element.addEventListener("mouseenter", self.mouseEnterBinded);
            self.element.addEventListener("mouseleave", self.mouseLeaveBinded);
            self.element.addEventListener("focusin", self.onFocusBinded);
            self.element.addEventListener("focusout", self.onBlurBinded);
            window.addEventListener("resize", self.onWindowResizeBinded);
        });
    }

    private unbindEvents(): void {
        let self = this;

        self.element.removeEventListener("mouseenter", self.mouseEnterBinded);
        self.element.removeEventListener("mouseleave", self.mouseLeaveBinded);
        self.element.removeEventListener("focusin", self.onFocusBinded);
        self.element.removeEventListener("focusout", self.onBlurBinded);
        window.removeEventListener("resize", self.onWindowResizeBinded);
    }
}


/*Helpers*/
export function isValidPosition(value: TooltipPosition): any {
    return TOOLTIP_POSITIONS[value];
}

export function isValidAlign(value: TooltipAlign): any {
    return TOOLTIP_ALIGNS[value];
}

export function afterChangeTooltipText(): void {
    let self = this;

    if (self.viewInititated && self.visible) {
        self.hide();
        self.show();
    }
}

export function afterChangeTooltipPosition(): void {
    let self = this;

    if (self.viewInititated && self.visible) {
        self.hide();
        self.show();
    }
}

export function afterChangeTooltipEvent(): void {
    this.updateEventDescriptors();
}

export function isValidTooltipEvent(value: TooltipEvent | Array<TooltipEvent> = "hover"): any {
    if (typeof value === "string") {
        return TOOLTIP_EVENTS[value];
    } else {
        return value.every((eventName: string) => {
            return TOOLTIP_EVENTS[eventName];
        });
    }
}

export function afterChangeDisabled(value: boolean): void {
    if (value) {
        this.visible = false;
    }
}

export function beforeChangeVisible(value: boolean): boolean {
    if (value && this.disabled) {
        return false;
    }
}

export function afterChangeVisible(newValue: boolean, oldValue: boolean): void {
    let self = this;
    if (newValue && !oldValue && self.viewInititated) {
        self.show();
        self.onToggle.emit(true);
    } else if (!newValue && oldValue && self.viewInititated) {
        self.hide();
        self.onToggle.emit(false);
    }
}
