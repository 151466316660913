<div class="ux-route-tab-panel-header">
    <a *ngFor="let item of items; trackBy: _trackByFn"
       #tabItem
       class="ux-route-tab-panel-header__item taLink {{item.styleClass}}"
       [class._hidden]="item.dropdown"
       routerLink="{{item.url}}"
       routerLinkActive="_selected"
       (tap)="_onTabPanelLinkTap()"
    >
        <div class="ux-route-tab-panel-header__item-inner">
            <span class="ux-route-tab-panel-header__item-title">
                {{item.title}}
            </span>
        </div>
    </a>

    <div #dropdown
         [class._active]="_activeDropdown"
         class="ux-route-tab-panel-header-dropdown"
    >
        <ng-container>
            <div class="ux-route-tab-panel-header-dropdown__icon"
                (tap)="_onDropdownIconClick($event)"
            >
                <ng-container *ngIf="_customDropdownIcon">
                    <ng-content select="customDropdownIcon"></ng-content>
                </ng-container>

                <ng-container *ngIf="!_customDropdownIcon">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="0" height="0"
                        viewBox="0 0 20 20"
                    >
                        <path d="M10.001 7.8a2.2 2.2 0 1 0 0 4.402A2.2 2.2 0 0 0 10 7.8zm-7 0a2.2 2.2 0 1 0 0 4.402A2.2 2.2 0 0 0 3 7.8zm14 0a2.2 2.2 0 1 0 0 4.402A2.2 2.2 0 0 0 17 7.8z"/>
                    </svg>

                </ng-container>
            </div>

            <ul class="ux-route-tab-panel-header-dropdown__list">
                <li class="ux-route-tab-panel-header-dropdown__item"
                    [class._visible]="item.dropdown"
                    *ngFor="let item of items; trackBy: _trackByFn">
                    <a class="ux-route-tab-panel-header-dropdown__link taLink {{item.styleClass}}"
                       routerLink="{{item.url}}"
                       routerLinkActive="_selected"
                       (click)="_onTabPanelLinkTap()"><!-- Click because of #1063 -->
                        {{item.title}}
                    </a>
                </li>
            </ul>
        </ng-container>
    </div>
</div>
