import {NgModule} from "@angular/core";
import {UxRadioGroupFieldComponent} from "./radio-group-field.component";
import {UxRadioFieldModule} from "./radio/radio-field.module"
import {CommonModule} from "@angular/common";
import {SharedModule} from "../../../shared/shared.module";

@NgModule({
    imports: [SharedModule, CommonModule, UxRadioFieldModule],
    exports: [UxRadioGroupFieldComponent],
    declarations: [UxRadioGroupFieldComponent]
})
export class UxRadioGroupFieldModule {
}