import {TemplateRef} from "@angular/core";

export interface UxCardTreeModel {
    children: UxCardTreeNodeModel[];
    template?: TemplateRef<any>;
    controlTemplate?: TemplateRef<any>;
}

export interface UxCardTreeNodeModel {
    id?: string;
    visible?: boolean;
    opened?: boolean;
    selected?: boolean;
    children?: UxCardTreeNodeModel[];
    hasChildren?: boolean;
    manualOperation?: boolean;
}

export class UxCardTreeNodeModelCopier {
  public copy(from: UxCardTreeNodeModel, to: UxCardTreeNodeModel) {
    to.id = from.id;
    to.visible = from.visible;
    to.opened = from.opened;
    to.selected = from.selected;
    to.hasChildren = from.hasChildren;
    to.manualOperation = from.manualOperation;
  }
}
