import {
  Component, EventEmitter, Input, IterableChangeRecord, IterableDiffer, IterableDiffers, KeyValueChangeRecord,
  KeyValueDiffer,
  KeyValueDiffers, OnDestroy,
  OnInit, Output,
} from "@angular/core";
import {Progress} from "../../common/models/progress.model";
import {UxCardTreeModel} from "../../../ux-lib/components/card-tree/card-tree.model";
import {UxPageChangeEvent} from "../../../ux-lib/components/paging/paging.component";
import {CardDataModel} from "./card-data.model";
import {UxCardTreeCheckedEvent, UxCardTreeToggleEvent} from "../../../ux-lib/components/card-tree/card-tree.component";
import {ModelService} from "../../common/services/model.service";
import {Subject} from "rxjs";
import {
  StatisticsDataStyledVisualEntry,
  StatisticsEntityBase
} from "../../common/models/entity/statistics-entity.model";
import {HierarchyEntityModel} from "../../common/models/entity/hierarchy-entity.model";
import {ReportsService} from "../../modules/reports/reports.service";
import {Entity} from "../../common/models/entity/entity.model";
import {AudioEntity} from "../../common/models/entity/audio-entity.model";

@Component({
  template: ""
})
export class CardListComponent<T extends Entity> implements OnInit, OnDestroy {
  // cards model to display in cards tree
  private static ROOT_CARD_ID = '0';
  _cardsModel: UxCardTreeModel;
  _entityCardsMap: Map<string, CardDataModel<T>> = new Map<string, CardDataModel<T>>();

  protected _filteredCardsTree: Map<string, CardDataModel<T>> = new Map<string, CardDataModel<T>>();

  //protected _entityCards: CardDataModel<T>[] = [];
  //protected _filteredCards: CardDataModel<T>[] = [];

  _pagingVisible: boolean = true;
  protected _viewInitiated: boolean = false;

  @Input()
  public pagingEnabled: boolean = true;

  protected _totalCount: number;
  @Input()
  public set totalCount(value: number) {
    this._totalCount = value;
    this.updatePagingVisibility();
  }

  public get totalCount(): number {
    return this._totalCount;
  }

  @Input()
  public pageSize: number;

  protected _pageSizeOptions: number[];
  @Input()
  public set pageSizeOptions(values: number[]) {
    this._pageSizeOptions = values;
    this.updatePagingVisibility();
  }

  public get pageSizeOptions(): number[] {
    return this._pageSizeOptions;
  }

  @Input()
  public currentPage: number;

  protected stopSubscription$ = new Subject<boolean>();

  @Input()
  public loadStatus: Progress = 'initial';

  @Input()
  public cardLoadStatus: Progress = 'loading';

  @Input()
  public selectAllEnabled: boolean = false;

  @Input()
  public selectAllCaption: string;

  protected _selectItemEnabled: boolean = false;
  @Input()
  public set selectItemEnabled(value: boolean) {
    this._selectItemEnabled = value;
    this.updateCardsSelection();
  }

  public get selectItemEnabled() {
    return this._selectItemEnabled;
  }

  protected _entities: T[] = [];
  protected _entitiesMap = new Map<string, T>();
  protected _entitiesCollectionDiffers: IterableDiffer<T>;
  protected _entityDiffersMap = new Map<string, KeyValueDiffer<string, any>>();

  @Input()
  public set entities(values: T[]) {
    this._entities = values;
    if (this._viewInitiated) {
      this.rebuildCards();
    }

    this.initNgCheck();
  }

  public get entities(): T[] {
    return this._entities;
  }

  private _filteredEntities: string[];
  @Input()
  public set filteredEntities(values: string[]) {
    this._filteredEntities = values;
    if (this._viewInitiated) {
      this.filterCards();
    }
  }

  public get filteredEntities(): string[] {
    return this._filteredEntities;
  }


  private _entitySummaryVisualStatistics: Map<number|string, StatisticsEntityBase<StatisticsDataStyledVisualEntry>>;
  @Input()
  public set entitySummaryVisualStatistics(values: Map<number|string, StatisticsEntityBase<StatisticsDataStyledVisualEntry>>) {
    this._entitySummaryVisualStatistics = values;
    this.updateCardsVisualStatistics();
  }

  public get entitySummaryVisualStatistics(): Map<number|string, StatisticsEntityBase<StatisticsDataStyledVisualEntry>> {
    return this._entitySummaryVisualStatistics;
  }

  protected _selectedEntities: string[] = [];
  @Input()
  public set selectedEntities(value: string[]) {
    this._selectedEntities = value || [];
    this.updateCardsSelection();
  }

  public get selectedEntities(): string[] {
    return this._selectedEntities;
  }

  @Input()
  public statisticsEnabled: boolean = false;

  @Input()
  public editEnabled: boolean = true;

  @Input()
  public downloadEnabled: boolean = false;

  @Input()
  public deleteEnabled: boolean = true;

  @Input()
  public unlinkEnabled: boolean = false;

  @Input()
  public chartEnabled: boolean = true;

  @Input()
  public addEnabled: boolean = true;

  @Input()
  public expandEnabled: boolean = true;

  @Input()
  public treeEnabled: boolean = true;

  @Output()
  public onPageChange: EventEmitter<UxPageChangeEvent> = new EventEmitter<UxPageChangeEvent>();

  @Output()
  public onPageSizeChange: EventEmitter<UxPageChangeEvent> = new EventEmitter<UxPageChangeEvent>();

  @Output()
  public onDownloadCard: EventEmitter<CardDataModel<T>> = new EventEmitter<CardDataModel<T>>();

  @Output()
  public onEditCard: EventEmitter<CardDataModel<T>> = new EventEmitter<CardDataModel<T>>();

  @Output()
  public onDeleteCard: EventEmitter<CardDataModel<T>> = new EventEmitter<CardDataModel<T>>();

  @Output()
  public onUnlinkCard: EventEmitter<CardDataModel<T>> = new EventEmitter<CardDataModel<T>>();

  @Output()
  public onChartCard: EventEmitter<CardDataModel<T>> = new EventEmitter<CardDataModel<T>>();

  @Output()
  public onAddFolder: EventEmitter<CardDataModel<T>> = new EventEmitter<CardDataModel<T>>();

  @Output()
  public onAddItem: EventEmitter<CardDataModel<T>> = new EventEmitter<CardDataModel<T>>();

  @Output()
  public onExpandCard: EventEmitter<CardDataModel<T>> = new EventEmitter<CardDataModel<T>>();

  @Output()
  public onExpandFolderCard: EventEmitter<CardDataModel<T>> = new EventEmitter<CardDataModel<T>>();

  @Output()
  public onCardChecked: EventEmitter<UxCardTreeCheckedEvent> = new EventEmitter<UxCardTreeCheckedEvent>();

  @Output()
  public onCardCheckedAll: EventEmitter<UxCardTreeCheckedEvent> = new EventEmitter<UxCardTreeCheckedEvent>();

  @Output()
  public onLoadInitialData: EventEmitter<any> = new EventEmitter<any>();

  public isViewInitiated(): boolean {
    return this._viewInitiated;
  }

  constructor(protected modelService: ModelService,
              protected reportService: ReportsService,
              protected iterableDiffers: IterableDiffers,
              protected keyValueDiffers: KeyValueDiffers) {
  }

  public loadInitialData() {
    this.onLoadInitialData.emit();
  }

  ngOnInit(): void {
    this.initCardModel();
    this.loadInitialData();
    this._viewInitiated = true;
  }

  ngOnDestroy(): void {
    this.stopSubscription$.next(true);
    this.stopSubscription$.complete();
  }

  protected compareCardToEntity(entity: T, card: CardDataModel<T>): boolean {
    return false;
  }

  protected updateEntityCard(entity: T, card: CardDataModel<T>) {
  }

  protected isEntitySelected(entity: T): boolean {
    if (entity !== undefined) {
      return this._selectedEntities.indexOf(entity.getId().toString()) >= 0;
    }
    return false;
  }

  protected createCard(entity: T, selected?: boolean): CardDataModel<T> {
    return null;
  }

  protected copyCard(cardFrom: CardDataModel<T>): CardDataModel<T> {
    return null;
  }

  protected getDefaultCardModel(): UxCardTreeModel {
    return {
      children: []
    }
  }

  protected isCardFolder(card: CardDataModel<T>) {
    return false;
  }

  protected onCardExpanded(enityCard: CardDataModel<T>) {
  }

  private initNgCheck(): void {
    if (this._entities) {
      this._entitiesCollectionDiffers = this.iterableDiffers.find(this._entities).create(this._trackEntityByFn);

      this._entityDiffersMap.clear();
      this._entitiesMap.clear();

      this._entities.forEach((entity: T) => {
        this.initNgCheckForItem(entity);
      })
    }
  }

  private initNgCheckForItem(entity: T) {
    let entityID = entity.getId().toString();
    this._entityDiffersMap.set(entityID, this.keyValueDiffers.find(entity).create());
    this._entitiesMap.set(entityID, entity);
  }

  ngDoCheck() {
    if (this._entityDiffersMap) {
      this._entityDiffersMap.forEach((entityDiffer: KeyValueDiffer<string, any>, key: string) => {
        let entity: T = this._entitiesMap.get(key);
        let entityID = entity.getId().toString();
        let card = this._entityCardsMap.get(entityID);

        const changes = entityDiffer.diff(entity);
        if (changes && this._entityCardsMap) {
          let changed: boolean = false;
          changes.forEachChangedItem((record: KeyValueChangeRecord<string, any>) => {
            changed = true;
          });
          if (changed) {
            this.updateEntityCard(entity, card);

            //update filtered card copy
            card = this._filteredCardsTree.get(entityID);
            this.updateEntityCard(entity, card);
          }
        }
      })
    }

    if (this._entitiesCollectionDiffers) {
      let updateFilters: boolean = false;
      const changes = this._entitiesCollectionDiffers.diff(this._entities);
      if (changes && this._entityCardsMap) {
        changes.forEachRemovedItem((record: IterableChangeRecord<T>) => {
          let entityID: string = record.item.getId().toString();
          let parentID: string;
          if (record.item['parent_id'] !== undefined) {
            parentID = record.item['parent_id'].toString();
          }

          if (parentID !== undefined) {
            let parentCard: CardDataModel<T> = this._entityCardsMap.get(parentID);
            if (parentCard !== undefined) {
              let deleteIndex: number = this.modelService.findInArray(parentCard.children,
                (item: CardDataModel<T>) => {
                  return item.id === entityID;
                });
              parentCard.children.splice(deleteIndex, 1);
              // parentCard.child_count--;
              // if (parentCard.child_count === 0) {
              //   parentCard.hasChildren = false;
              //   parentCard.opened = false;
              // }
            }
          }

          this._entityCardsMap.delete(entityID);
          updateFilters = true;
        });


        changes.forEachAddedItem((record: IterableChangeRecord<T>) => {
          if (!this._entityCardsMap.has(record.item.getId().toString())) {
            this.initNgCheckForItem(record.item);
            this.pushEntityToMap(record.item);
            updateFilters = true;
          }
        });
      }
      if (updateFilters) {
        this.filterCards();
      }
    }
  }

  _onDownload(item: CardDataModel<T>) {
    this.onDownloadCard.emit(item);
  }

  _onEdit(item: CardDataModel<T>) {
    this.onEditCard.emit(item);
  }

  _onDelete(item: CardDataModel<T>) {
    this.onDeleteCard.emit(item);
  }

  _onUnlink(item: CardDataModel<T>) {
    this.onUnlinkCard.emit(item);
  }

  _onChart(item: CardDataModel<T>) {
    this.onChartCard.emit(item);
  }

  _onAddFolder(item: CardDataModel<T>) {
    this.onAddFolder.emit(item);
  }

  _onAddItem(item: CardDataModel<T>) {
    this.onAddItem.emit(item);
  }

  _onToggle(event: UxCardTreeToggleEvent) {
    let card: CardDataModel<T> = event.model;
    this._entityCardsMap.get(card.id).opened = card.opened;
    this.onExpandFolderCard.emit(card);
  }

  _onExpand(card: CardDataModel<T>) {
    card.expanded = !card.expanded;
    this.onCardExpanded(card);
    this.onExpandCard.emit(card);
  }

  protected rebuildCards(): void {
    this._entityCardsMap.clear();

    this._entities.forEach((entity: T) => {
      this.pushEntityToMap(entity);
    });
    this.filterCards();
  }

  protected pushEntityToMap(entity: T) {
    let entityID: string = entity.getId().toString();
    let cardNode: CardDataModel<T>;

    if (this._entityCardsMap.has(entityID)) {
      cardNode = this._entityCardsMap.get(entityID);
      this.updateEntityCard(entity, cardNode);
    }
    else {
      cardNode = this.createCard(entity, this._selectItemEnabled ? this.isEntitySelected(entity) : undefined);
      this._entityCardsMap.set(entityID, cardNode);
    }

    if (this.treeEnabled) {
      let parentID: string;
      if (entity['parent_id'] != undefined) {
        parentID = entity['parent_id'].toString();
      }
      let parentNode: CardDataModel<T>;
      let parentEntity: T;
      if (parentID !== undefined) {
        if (this._entityCardsMap.has(parentID)) {
          parentNode = this._entityCardsMap.get(parentID);
        }
        else {
          parentNode = {
            id: parentID,
            hasChildren: true,
            child_count: 0,
            children: []
          };
          this._entityCardsMap.set(parentID, parentNode);
        }

        let existsIndex: number = this.modelService.findInArray(parentNode.children,
          (item: CardDataModel<T>) => {
            return item.id === entityID;
          });
        if (existsIndex < 0) {
          // if (parentNode.children.length === 0) {
          //   parentNode.child_count = 0;
          // }
          parentNode.hasChildren = true;
          parentNode.children.push(cardNode);
          //parentNode.child_count++;
        }
      }
    }
    this.updateCardVisualStatistics(cardNode);
  }

  protected filterCards(): void {
    let filteredCardsTree: Map<string, CardDataModel<T>> = new Map<string, CardDataModel<T>>();
    if (this.treeEnabled) {
      filteredCardsTree = this.filterTopCards();
      filteredCardsTree = this.filterChildCards(filteredCardsTree);
    }
    else {
      filteredCardsTree = this.filterEntitiesCards();
    }

    this._filteredCardsTree = filteredCardsTree;

    this._cardsModel.children = this._filteredCardsTree.has(CardListComponent.ROOT_CARD_ID) ? this._filteredCardsTree.get(CardListComponent.ROOT_CARD_ID).children : [];
  }

  private filterTopCards(): Map<string, CardDataModel<T>> {
    let filteredCardsTree: Map<string, CardDataModel<T>> = new Map<string, CardDataModel<T>>();
    let filteredEntities: string[] = [];
    let filterEnabled: boolean = (this._filteredEntities !== undefined);
    if (filterEnabled) {
      filteredEntities = Array.from(this._filteredEntities);
    }
    filteredEntities.push(CardListComponent.ROOT_CARD_ID);

    this._entityCardsMap.forEach((card: CardDataModel<T>, key: string) => {
      let copyCard;
      if (this.isCardFolder(card)) {
        copyCard = this.copyCard(card);
        copyCard.children = [];
      }
      else {
        if (filterEnabled) {
          let idx = filteredEntities.indexOf(card.id);
          if (idx >= 0) {
            copyCard = this.copyCard(card);
            filteredEntities.splice(idx, 1);
          }
        }
        else {
          copyCard = this.copyCard(card);
        }
      }
      if (copyCard !== undefined) {
        filteredCardsTree.set(key, copyCard);
      }
    });

    return filteredCardsTree;
  }

  private filterChildCards(filteredCards: Map<string, CardDataModel<T>>): Map<string, CardDataModel<T>> {
    filteredCards.forEach((filteredItem: CardDataModel<T>, key: string) => {
      let originalCard = this._entityCardsMap.get(filteredItem.id);
      if (Array.isArray(originalCard.children)) {
        originalCard.children.forEach((childCard: CardDataModel<T>) => {
          if (filteredCards.has(childCard.id)) {
            filteredItem.children.push(filteredCards.get(childCard.id));
          }
        });
      }
    });
    return filteredCards;
  }

  private filterEntitiesCards(): Map<string, CardDataModel<T>> {
    let filteredCardsTree: Map<string, CardDataModel<T>> = new Map<string, CardDataModel<T>>();
    let filteredEntities: string[] = [];
    let filterEnabled: boolean = (this._filteredEntities !== undefined);
    if (filterEnabled) {
      filteredEntities = Array.from(this._filteredEntities);
    }

    let rootCard: CardDataModel<T> = null;
    if (this._entityCardsMap.has(CardListComponent.ROOT_CARD_ID)) {
      rootCard = this.copyCard(this._entityCardsMap.get(CardListComponent.ROOT_CARD_ID));
      rootCard.children = [];
    }
    else {
      rootCard = {
        id: CardListComponent.ROOT_CARD_ID,
        hasChildren: true,
        child_count: 0,
        children: []
      };
    }
    filteredCardsTree.set(CardListComponent.ROOT_CARD_ID, rootCard);

    this._entityCardsMap.forEach((card: CardDataModel<T>, key: string) => {
      let copyCard;
      if (!this.isCardFolder(card)) {
        if (filterEnabled) {
          let idx = filteredEntities.indexOf(card.id);
          if (idx >= 0) {
            copyCard = this.copyCard(card);
            filteredEntities.splice(idx, 1);
          }
        }
        else {
          copyCard = this.copyCard(card);
        }
      }
      if (copyCard !== undefined) {
        filteredCardsTree.set(key, copyCard);
        rootCard.children.push(copyCard);
      }
    });

    return filteredCardsTree;
  }

  protected updateCardsVisualStatistics() {
    this._entityCardsMap.forEach((card: CardDataModel<T>) => {
      this.updateCardVisualStatistics(card);
    })
  }

  protected updateCardVisualStatistics(card: CardDataModel<T>) {
    let entitySummaryVisible;
    if (this._entitySummaryVisualStatistics) {
      entitySummaryVisible = this._entitySummaryVisualStatistics.get(card.id);
    }
    if (entitySummaryVisible === undefined) {
      entitySummaryVisible = this.reportService.calculateSummaryVisualStatistics(undefined);
    }
    card.entitySummaryVisible = entitySummaryVisible;

    if (this._filteredCardsTree) {
      let filteredCard = this._filteredCardsTree.get(card.id);
      if (filteredCard) {
        filteredCard.entitySummaryVisible = entitySummaryVisible;
      }
    }
  }

  protected updateCardsSelection(): void {
    this._entityCardsMap.forEach((card: CardDataModel<T>) => {
      card.selected = this._selectItemEnabled ? this.isEntitySelected(card.originalEntity) : undefined;
    })
  }

  protected initCardModel(): void {
    if (this._cardsModel === undefined) {
      this._cardsModel = this.getDefaultCardModel();
    }
  }

  protected updatePagingVisibility(): void {
    if (this._totalCount !== undefined && this._pageSizeOptions !== undefined) {
      this._pagingVisible = this.pagingEnabled && (this._totalCount > Math.min(...this._pageSizeOptions));
    }
  }

  public _trackEntityByFn(index: number, item: T): string {
    return "";
  }
}
