import {Component} from "@angular/core";

@Component({
    selector: "ux-reference-field-toolbar",
    template: `<ng-content></ng-content>`,
    host: {
        "[class.ux-reference-field-toolbar]": "true"
    }
})
export class UxReferenceFieldToolbarComponent {

}
