import * as moment from 'moment';

export class DateUtils {

    public static convertToCalendarModel(value: Date): string {
        return DateUtils.convertToDateFormat(value, 'DD.MM.YYYY');
    }

    public static convertToDateFormat(date: number|Date, dateFormat: string): string {
        if (!date) {
            return undefined;
        }

        return moment(date).format(dateFormat);
    }

    public static convertToDate(fromValue: any, dateFormat: string): Date {
        if (!fromValue) {
            return;
        }

        if (typeof(fromValue) === "string") {
            let date = fromValue.trim();

            if (moment(date, dateFormat).isValid()) {
                return moment(date, dateFormat).toDate();
            }
        } else if (typeof(fromValue) === "number") {
            return new Date(fromValue);
        }

        return new Date(fromValue);
    }

    public static compareDateValue(newValue: Date, oldValue: Date, dateFormat: string): boolean {
        let newDateString = newValue && DateUtils.convertToDateFormat(newValue, dateFormat);
        let oldDateString = oldValue && DateUtils.convertToDateFormat(oldValue, dateFormat);

        return newDateString === oldDateString;
    }

    public static getDiffDays(fromDate: number, toDate: number) {
      return moment(toDate).diff(moment(fromDate), 'days');
    }

    public static getDiffWeeks(fromDate: number, toDate: number) {
      return moment(toDate).diff(moment(fromDate), 'weeks');
    }

  public static getDiffYear(fromDate: number, toDate: number) {
    return moment(toDate).diff(moment(fromDate), 'years');
  }
}
