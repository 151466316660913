<div class="sq-results-report-pvqa__header breadcrumbs">
  <ux-breadcrumbs [items]="_breadcrumbs"
                  (onClick)="_onBreadcrumbsClick($event)"
  >
  </ux-breadcrumbs>
</div>

<div class="sq-results-report-pvqa__section"
     [ngSwitch]="_progressStatus"
>
  <ux-round-dots-loader *ngSwitchCase="'loading'"
                        [visible]="true">
  </ux-round-dots-loader>

  <div class="sq-results-report-pvqa__table"
       *ngSwitchCase="'loaded'"
  >
    <ux-table-header class="sq-results-report-pvqa__table-header"
                     [model]="_tableHeaderModel"
    >
    </ux-table-header>
    <ux-table #reportTable
              class="sq-results-report-pvqa__table-body"
              [model]="_model"
              [rowHeight]="40"
              [minVerticalSliderSize]="30"
              [mobile]="false"
    >
    </ux-table>
  </div>

  <div class="sq-results-report-pvqa__section-message"
       *ngSwitchCase="'no_data'"
  >
    No report data loaded
  </div>
</div>
