<sq-overlay-popup [title]="'Link tests to device'"
                  [visible]="_visible"
                  [statusEnable]="false"
                  [contentTemplate]="contentTemplate"
                  [controlsTemplate]="controlsTemplate"
                  (onClose)="_onCloseClick()"
>
</sq-overlay-popup>


<ng-template #contentTemplate>
  <sq-device-new-test-list [device]="_device"
                           (onCardChecked)="_onCardChecked($event)"
  >

  </sq-device-new-test-list>
</ng-template>

<ng-template #controlsTemplate>
  <div class="sq-device-link-new-test-popup__footer-message"
  >
    <ng-container *ngIf="_selectedTests.length === 1 && !_linkErrorMessage">
      {{_selectedTests.length}} test selected
    </ng-container>
    <ng-container *ngIf="_selectedTests.length > 1 && !_linkErrorMessage">
      {{_selectedTests.length}} tests selected
    </ng-container>

    <div class="_error"
         *ngIf="_linkErrorMessage"
    >
      {{_linkErrorMessage}}
    </div>
  </div>
  <div class="sq-device-link-new-test-popup__footer-controls">
    <ux-button (tap)="_onCloseClick()"
               [text]="'Cancel'"
    >
    </ux-button>
    <ux-button (tap)="_onLink()"
               [text]="'Link'"
               [kind]="'primary'"
    >
    </ux-button>
  </div>
</ng-template>
