import {NgModule} from "@angular/core";
import {UxTabPanel} from "./tab-panel.component";
import {UxTabPanelItem} from "./item/tab-panel-item.component";
import {UxTabPanelHeader} from "./header/tab-panel-header.component";
import {CommonModule} from "@angular/common";

const COMPONENTS: any = [
    UxTabPanel,
    UxTabPanelItem,
    UxTabPanelHeader
];

export {TabChangeEvent} from "./tab-panel-event.model"

@NgModule({
    imports: [CommonModule],
    declarations: [COMPONENTS],
    exports: [COMPONENTS]
})
export class UxTabPanelModule {
}