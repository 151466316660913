<div class="sq-audio-full-list__wrapper">
  <div class="sq-audio-full-list__header">
    <ux-toolbar>
      <ux-toolbar-left>
        <ux-attachment #attachmentAudio
                       [dropAreaTooltip]="'Drop your audio or click here to select files for uploading'"
                       [accept]="'audio/*'"
                       [auto]="true"
                       [disabled]="_pageLoadingStatus !== 'loaded' && _pageLoadingStatus !== 'no_data'"
                       [hideList]="true"
                       (onUpload)="_onUploadAudio($event)"
        >
        </ux-attachment>
        <ux-round-dots-loader [visible]="_audioUploading">
        </ux-round-dots-loader>
      </ux-toolbar-left>
    </ux-toolbar>
  </div>
  <div class="sq-audio-full-list__content">
    <sq-audio-card-list #audioCardList
                        [selectAllEnabled]="false"
                        [selectItemEnabled]="false"
                        [loadStatus]="_pageLoadingStatus"
                        [cardLoadStatus]="_audioLoading"
                        [entities]="_audioFiles"
                        [treeEnabled]="false"
                        [pagingEnabled]="true"
                        [statisticsEnabled]="false"
                        [downloadEnabled]="true"
                        [totalCount]="pagingConfig.totalCount"
                        [pageSize]="pagingConfig.pageSize"
                        [pageSizeOptions]="pagingConfig.pageSizeOptions"
                        [currentPage]="pagingConfig.currentPage"
                        (onDownloadCard)="_onDownloadCard($event)"
                        (onEditCard)="_onEditCard($event)"
                        (onDeleteCard)="_onDeleteCard($event)"
                        (onPageChange)="_onPageChange($event)"
                        (onPageSizeChange)="_onPageSizeChange($event)"
    >
    </sq-audio-card-list>
  </div>
</div>

<sq-audio-edit #audioEditPopup
                (onEditComplete)="_onAudioEditComplete($event)"
                (onCancelled)="_onAudioCancel($event)"
>
</sq-audio-edit>

<sq-confirm-popup #audioDeleteConfirmPopup
                   (onCancelled)="_onAudioCancel($event)"
                   (onAccepted)="_onAudioDelete($event)"
                   [acceptCaption]="'Delete'"
                   [cancelCaption]="'Cancel'"
                   [title]="'Delete audio file'"
                   [message]="'Are you really want to delete \'' + _audioDeleteEntity?.name + '\' ?'"
>
</sq-confirm-popup>
