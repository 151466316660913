import {Component, HostBinding, Input, TemplateRef} from "@angular/core";
import {UxPropertyConverter} from "../../common/decorator/ux-property-converter";

@Component({
    selector: "ux-label",
    templateUrl: "./label.component.html",
    host: {"[class.ux-label]": "true"}
})
export class UxLabelComponent {

    @Input()
    public text: string;

    @HostBinding("class._required")
    @UxPropertyConverter("boolean", false)
    @Input()
    public required: boolean;

    @Input()
    @UxPropertyConverter("boolean", false)
    public optional: boolean;

    @Input()
    @UxPropertyConverter("string", "Optional")
    public optionalText: string;

    @Input()
    public template: TemplateRef<any>;

    @Input()
    public mobile: boolean = false;
}
