import {
    AfterContentInit,
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ContentChild,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from "@angular/core";
import {FormGroup, ValidationErrors} from "@angular/forms";
import {TooltipEvent} from "../../tooltip/tooltip.directive";
import {UxAbstractFieldComponent} from "../abstract-field.component";
import {Subscription} from "rxjs";

const STYLE_MODIFIERS = {
    error: "_error",
};

@Component({
    selector: "ux-form-field",
    templateUrl: "form-field.component.html",
    host: {"[class.ux-form-field]": "true"}
})
export class UxFormFieldComponent implements OnInit, AfterViewInit, OnDestroy, AfterContentInit {

    private _value: any;

    @Input()
    public get value(): any {
        return this._value;
    }

    public set value(value: any) {
        this._value = value;

        if (this.initialized) {
            this.cdr.detectChanges();
        }
    }

    @Output()
    public valueChange: EventEmitter<any> = new EventEmitter<any>();

    @Input()
    public controlName: string;

    @Input()
    public tooltipEvent: TooltipEvent | Array<TooltipEvent>;

    @Input()
    public group: FormGroup;

    private _validateOn: "blur" | "default" = "default";

    @Input()
    public set validateOn(type: "blur" | "default") {
        if (type === undefined || type === null) {
            type = "default";
        }

        if (this._validateOn) {
            if (type === "blur") {
                this.subscribeEvents();
            } else {
                this.unsubscribeEvents();
            }
        }

        this._validateOn = type;
    }

    public get validateOn(): "blur" | "default" {
        return this._validateOn;
    }

    @Input()
    public set errors(errors: string[]) {
        if (!errors) {
            this._errorMessages = "";
        } else {
            this._errorMessages = errors.join("<br/>");
        }
    }

    @Output()
    public onErrorsChange: EventEmitter<ValidationErrors> = new EventEmitter<ValidationErrors>();

    /** @internal */
    public _hasError: boolean = false;

    /** @internal */
    public _errorMessages: string;


    @ContentChild("field", /* TODO: add static flag */ { read: ElementRef })
    private field: ElementRef;

    /** @internal */
    @ContentChild("field")
    public _fieldComponent: UxAbstractFieldComponent<any>;

    @ViewChild("tooltip", { static: true })
    private tooltip: ElementRef;

    @Input()
    public validationTooltipContainer: string | ElementRef;

    private subscription: any;
    private blurSubscription: Subscription;
    private focusSubscription: Subscription;

    private initialized: boolean = false;

    constructor(private cdr: ChangeDetectorRef) {
    }

    public ngOnInit(): void {
        this.group.get(this.controlName).setValue(this.value);
    }

    public ngAfterContentInit(): void {
        if (this._validateOn === "blur") {
            this.subscribeEvents();
        }
    }

    private subscribeEvents(): void {
        if (this._fieldComponent) {
            this.focusSubscription = this._fieldComponent.onFocus.subscribe(() => {
                this.checkValid();
            });

            this.blurSubscription = this._fieldComponent.onBlur.subscribe(() => {
                this.checkValid();
            });
        }
    }

    private unsubscribeEvents(): void {
        if (this._fieldComponent) {
            this.focusSubscription = this._fieldComponent.onFocus.subscribe(() => {
                this.checkValid();
            });

            this.blurSubscription = this._fieldComponent.onBlur.subscribe(() => {
                this.checkValid();
            });
        }
    }

    public ngAfterViewInit(): void {
        let self = this,
            markedAsDirty = false;
        self.initialized = true;

        self.subscription = self.group.get(self.controlName).valueChanges.subscribe((data) => {
            let control = self.group.get(self.controlName);

            // If control is pristine and markedAsDirty is 'false', it means that field input changes first time (after form creation
            // or after form reset) and we should mark form group as dirty and validate input
            if (control.pristine && control.untouched && !markedAsDirty) {
                markedAsDirty = true;
                control.markAsTouched();
                control.markAsDirty();
                self.checkValid();
            }
            // If control is pristine and markedAsDirty is 'true', it means that field has been resetted and
            // we should set markedAsDirty to false, to get into first 'if' condition on next change
            else if (control.pristine && control.untouched && markedAsDirty) {
                markedAsDirty = false;
                self._errorMessages = "";
                self._hasError = false;
            } else {
                self.checkValid();
            }

            if (data !== undefined) {
                self.cdr.detectChanges();
            }
        });
    }

    public ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }

        this.unsubscribeEvents();
    }

    public checkValid(): void {
        let self = this;
        if (self.group && self.controlName) {
            let control = self.group.get(self.controlName),
                errors: ValidationErrors = control.errors;

            if (control.pristine && control.untouched) {
                self._errorMessages = "";
                self._hasError = false;
                return;
            }

            if (errors) {
                setTimeout(() => this.onErrorsChange.emit(errors));
            } else {
                self._errorMessages = "";
            }

            self._hasError = control.invalid;
            if (self.field && self.field.nativeElement) {
                if (self._hasError && !(this._fieldComponent.focused && this.validateOn === "blur")) {
                    self.field.nativeElement.classList.add(STYLE_MODIFIERS.error);
                    self.tooltip["visible"] = true;
                } else {
                    self.tooltip["visible"] = false;
                    self.field.nativeElement.classList.remove(STYLE_MODIFIERS.error);
                }
            }
        }
    }

    /** @internal */
    public _onValueChange(value: any) {
        this.valueChange.emit(value);
    }
}
