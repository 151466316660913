<div #fileList
     class="ux-attached-file__files-list-wrapper"
     *ngIf="attachedFile !== undefined && attachedFile.length > 0"
>
    <ul class="ux-attached-file__files-list">
      <li class="ux-attached-file__files-list-item">
        <i class="ux-attached-file__uploaded-file-icon"></i>

        <a class="ux-attached-file__file-name"
           (tap)="_onFileClick(attachedFile)">
          {{attachedFile}}
        </a>

        <div class="ux-attached-file__file-icon-wrapper">
          <i class="ux-attached-file__file-remove-icon" (tap)="_removeFile(attachedFile)"></i>
          <i class="ux-attached-file__download-file-icon" (tap)="_onFileClick(attachedFile)"></i>
        </div>
      </li>
    </ul>
</div>

