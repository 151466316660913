import {CardDataModel, CardDataModelCopier} from "../../../components/card-list/card-data.model";
import {AudioEntity} from "../../../common/models/entity/audio-entity.model";

export interface AudioCardModel extends CardDataModel<AudioEntity> {
}

export class AudioCardModelCopier extends CardDataModelCopier<AudioEntity> {

  public copy(from: AudioCardModel, to: AudioCardModel) {
    super.copy(from, to);
  }
}
