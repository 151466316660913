<i class="ux-search-field__clear"
   (tap)="_onClearIconClick()">

  <ng-content select="svg[customClearIcon]"></ng-content>

  <svg *ngIf="!_customClearIcon"
       width="0" height="0"
       class="ux-search-field__clear-icon"
       viewBox="0 0 20 20">
    <path
      d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/>
  </svg>
</i>

<i class="ux-search-field__icon">
  <ng-content select="svg"></ng-content>

  <svg *ngIf="!_customIcon"
       width="0" height="0"
       viewBox="2 2 16 16">

    <path d="M17.545,15.467l-3.779-3.779c0.57-0.935,0.898-2.035,0.898-3.21c0-3.417-2.961-6.377-6.378-6.377
	                C4.869,2.1,2.1,4.87,2.1,8.287c0,3.416,2.961,6.377,6.377,6.377c1.137,0,2.2-0.309,3.115-0.844l3.799,3.801
	                c0.372,0.371,0.975,0.371,1.346,0l0.943-0.943C18.051,16.307,17.916,15.838,17.545,15.467z M4.004,8.287
	                c0-2.366,1.917-4.283,4.282-4.283c2.366,0,4.474,2.107,4.474,4.474c0,2.365-1.918,4.283-4.283,4.283
	                C6.111,12.76,4.004,10.652,4.004,8.287z"></path>
  </svg>
</i>


<input #searchInput
       class="ux-search-field__input taValue"
       [placeholder]="placeholder"
       [attr.name]="inputName"
       [attr.maxlength]="maxLength"
       [disabled]="disabled"
       [(ngModel)]="viewValue"
       (focus)="_onFocus($event)"
       (blur)="_onBlur($event)"
       (change)="_onSubmitValueChange($event)"
       (keyup)="_onKeyUp($event)"
       (keydown)="_onKeyDown($event)"
/>
