<div class="sq-satisfaction-chart__wrapper">
  <div class="sq-satisfaction-chart__header">
    Users satisfaction (MOS):
  </div>
  <div class="sq-satisfaction-chart__content">
    <div class="sq-satisfaction-chart__content-level">
      <div class="sq-satisfaction-chart__content-level-axe-value">
        5.0
      </div>
      <div class="sq-satisfaction-chart__content-level-legend _level_5">
        <div class="sq-satisfaction-chart__content-level-legend-caption">
          Very Satisfied
        </div>
      </div>
    </div>

    <div class="sq-satisfaction-chart__content-level">
      <div class="sq-satisfaction-chart__content-level-axe-value">
        4.3
      </div>
      <div class="sq-satisfaction-chart__content-level-legend _level_4">
        <div class="sq-satisfaction-chart__content-level-legend-caption">
          Satisfied
        </div>
      </div>
    </div>

    <div class="sq-satisfaction-chart__content-level">
      <div class="sq-satisfaction-chart__content-level-axe-value">
        4.0
      </div>
      <div class="sq-satisfaction-chart__content-level-legend _level_3">
        <div class="sq-satisfaction-chart__content-level-legend-caption">
          Some Users Dissatisfied
        </div>
      </div>
    </div>

    <div class="sq-satisfaction-chart__content-level">
      <div class="sq-satisfaction-chart__content-level-axe-value">
        3.6
      </div>
      <div class="sq-satisfaction-chart__content-level-legend _level_2">
        <div class="sq-satisfaction-chart__content-level-legend-caption">
          Many Users Dissatisfied
        </div>
      </div>
    </div>

    <div class="sq-satisfaction-chart__content-level">
      <div class="sq-satisfaction-chart__content-level-axe-value">
        3.1
      </div>
      <div class="sq-satisfaction-chart__content-level-legend _level_1">
        <div class="sq-satisfaction-chart__content-level-legend-caption">
          Nearly All Users Dissatisfied
        </div>
      </div>
    </div>

    <div class="sq-satisfaction-chart__content-level">
      <div class="sq-satisfaction-chart__content-level-axe-value">
        2.6
      </div>
      <div class="sq-satisfaction-chart__content-level-legend _level_0">
        <div class="sq-satisfaction-chart__content-level-legend-caption">
          Not Recommended
        </div>
      </div>
    </div>

    <div class="sq-satisfaction-chart__content-level">
      <div class="sq-satisfaction-chart__content-level-axe-value">
        1.0
      </div>
      <div class="sq-satisfaction-chart__content-level-legend _level_empty">
        <div class="sq-satisfaction-chart__content-level-legend-caption">
        </div>
      </div>
    </div>
  </div>
</div>
