import {Entity} from "./entity.model";

export class TestResultEntity extends Entity {
  public id?: string;
  public phone_id?: number;
  public phone_name?: string;
  public target?: string;
  public duration?: number;
  public endtime?: number;
  public error?: string;
  public mos_pvqa?: number;
  public mos_aqua?: number;
  public mos_network?: number;
  public r_factor?: number;
  public percents_aqua?: number;
  public report_pvqa?: string;
  public report_aqua?: string;
  public task_id?: number;
  public task_name?: string;
  public audio_id?: number;
  public speech_text?: string;
  public sip_src?: string;
  public sip_dst?: string;
  public sip_callid?: string;
  public packet_loss?: number;
  public packets_total?: number;
  public packets_lost?: number;
  public jitter?: number;
  public rtt?: number;

  public constructor(id?: string) {
    super(id);
  }

  getId(): number | string {
    return this.id;
  }

  getIdField(): string {
    return "id";
  }

  setId(value: number | string) {
    this.id = value.toString();
  }

  public copyFrom(value: TestResultEntity) {
    super.copyFrom(value);

  this.id = value.id;
  this.phone_id = value.phone_id;
  this.phone_name = value.phone_name;
  this.target = value.target;
  this.duration = value.duration;
  this.endtime = value.endtime;
  this.error = value.error;
  this.mos_pvqa = value.mos_pvqa;
  this.mos_aqua = value.mos_aqua;
  this.mos_network = value.mos_network;
  this.r_factor = value.r_factor;
  this.percents_aqua = value.percents_aqua;
  this.report_pvqa = value.report_pvqa;
  this.report_aqua = value.report_aqua;
  this.task_id = value.task_id;
  this.task_name = value.task_name;
  this.audio_id = value.audio_id;
  this.speech_text = value.speech_text;
  this.sip_src = value.sip_src;
  this.sip_dst = value.sip_dst;
  this.sip_callid = value.sip_callid;
  this.packet_loss = value.packet_loss;
  this.packets_total = value.packets_total;
  this.packets_lost = value.packets_lost;
  this.jitter = value.jitter;
  this.rtt = value.rtt;

  }
}
