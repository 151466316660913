<sq-results-list-advanced #resultsListAdvanced
                          [fullView]="false"
                          [resultsData]="_resultsData"
                          [title]="'Available Results:'"
                          [headerRemoveCaption]="'Delete selected'"
                          [pagingTotalCount]="_totalTestsCount"
                          [emptyTableContent]="_emptyTableContent"
                          (onHeaderRemoveButton)="_onRemoveResultsClicked()"
                          (onTableLinkClicked)="_onTableLinkClicked($event)"
                          (onDynamicSortClick)="_onDynamicSortClick($event)"
                          (onTablePageChange)="_onTablePageChange($event)"
                          (onTablePageSizeChange)="_onTablePageSizeChange($event)"
>
</sq-results-list-advanced>


