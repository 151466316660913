import {NgModule} from "@angular/core";
import {UxMaskFieldComponent} from "./mask-field.component";
import {SharedModule} from "../../../shared/shared.module";
import {FormsModule} from "@angular/forms";

@NgModule({
    imports: [SharedModule, FormsModule],
    exports: [UxMaskFieldComponent],
    declarations: [UxMaskFieldComponent]
})
export class UxMaskFieldModule {
}
