import {Injectable, Injector} from "@angular/core";
import {ApiPath} from "../../common/services/api.path";
import {FileItem} from "../../../ux-lib/components/attachment/attachment.component";
import {Observable, Subscriber} from "rxjs";
import {HttpResponse} from "@angular/common/http";
import {ApiService} from "../../common/services/api.service";
import {AudioEntity} from "../../common/models/entity/audio-entity.model";
import {QueryParam} from "../../common/models/query-param.type";
import {
  DeleteOperationResult,
  OperationResult,
  PostOperationResult
} from "../../common/models/result.type";

export interface AttachFileResult extends OperationResult {
  entityID: string;
  fileName: string;
  audio_id?: number;
}

export interface DownloadFileResult extends OperationResult {
  audio_id: number|string;
  blob: Blob;
}

export interface DeleteFileResult extends OperationResult {
  entityID: string;
  fileName: string;
}

@Injectable()
export class AudioService  extends ApiService {
  protected readonly upload_audio: string[] = [ApiPath.UPLOAD_AUDIO_PATH + "/", "./json/device-upload-audio.json"];
  protected readonly list_audio: string[] = [ApiPath.LIST_AUDIO_PATH + "/", "./json/list-audio.json"];
  protected readonly update_audio: string[] = [ApiPath.UPDATE_AUDIO_PATH + "/", "./json/update-audio.json"];
  protected readonly download_audio: string[] = [ApiPath.DOWNLOAD_AUDIO_PATH + "/", "./sample-data/sample.m4a"];
  protected readonly delete_audio: string[] = [ApiPath.DELETE_AUDIO_PATH + "/", "./json/delete-audio.json"];

  constructor(protected injector: Injector) {
    super(injector);
  }

  public uploadAudio(file: FileItem, idField?: string, entityID?: number|string): Observable<AttachFileResult> {
    let formData: FormData = new FormData();

    let entityIDStr: string = undefined;
    if (entityID !== undefined) {
      entityIDStr = entityID.toString();
    }
    if (entityIDStr !== undefined) {
      formData.append(idField, entityIDStr);
    }
    formData.append(ApiPath.AUDIO_NAME, file.name);
    formData.append(ApiPath.FILE, file);

    let o = Observable.create((subscriber: Subscriber<AttachFileResult>) => {
      let get = this.postFile(this.upload_audio, {
        body: formData,
        dataExtracter: (res: HttpResponse<Object>) => this.fileOperationResultDataExtracter(res, entityIDStr, file)
      }).subscribe(async (data: any) => {
        let opResult: AttachFileResult = data as AttachFileResult;
        subscriber.next(opResult);
        subscriber.complete();
      });
      return () => {
        get && get.unsubscribe();
      };
    });
    return o;
  }

  public listAudio(queryParams?: QueryParam[]): Observable<AudioEntity[]> {
    let o = Observable.create((subscriber: Subscriber<AudioEntity[]>) => {
      let get = this.get_collection(this.list_audio, {
        queryParams: queryParams
      }).subscribe(async (items: AudioEntity[]) => {
        let foundAudio: AudioEntity[] = items;
        //emulate filtered response
        if (Array.isArray(queryParams)) {
          let audio_id_param: QueryParam[] = queryParams.filter((item: QueryParam) => {
            return item.key === ApiPath.AUDIO_ID;
          });
          if (audio_id_param.length > 0) {
            foundAudio = items.filter((item: AudioEntity) => {
              return item.audio_id.toString() === audio_id_param[0].value.toString();
            })

          }
        }

        subscriber.next(foundAudio);
        subscriber.complete();
      });
      return () => {
        get && get.unsubscribe();
      };
    });
    return o;
  }

  public updateAudio(audio_id: number|string, audio: AudioEntity): Observable<PostOperationResult<AudioEntity>> {
    let o = Observable.create((subscriber: Subscriber<PostOperationResult<AudioEntity>>) => {
      let post = this.post(this.update_audio, {
        body: JSON.stringify(audio),
        dataExtracter: (data) => this.updateResultExtractData(data, audio)
      }).subscribe(async (res: any) => {

        let result: PostOperationResult<AudioEntity> = res;
        if (result.opResult) {
          result.opEntityID = audio_id;
        }

        subscriber.next(result);
        subscriber.complete();
      });

      return () => {
        post && post.unsubscribe();
      };
    });
    return o;
  }

  public downloadAudio(audioID: number|string): Observable<DownloadFileResult> {
    let queryParams = [
      {
        key: ApiPath.AUDIO_ID,
        value: audioID
      }
    ];

    let o = Observable.create((subscriber: Subscriber<DownloadFileResult>) => {
      let get = this.getFile(this.download_audio, {
        queryParams: queryParams
      }).subscribe(async (blobResponse: Blob) => {

        let result: DownloadFileResult = {
          audio_id: audioID,
          blob: blobResponse
        };

        subscriber.next(result);
        subscriber.complete();
      });

      return () => {
        get && get.unsubscribe();
      };
    });
    return o;
  }

  public deleteAudio(audioID: number|string): Observable<DeleteOperationResult> {
    let queryParams = [
      {
        key: ApiPath.AUDIO_ID,
        value: audioID
      }
    ];

    let o = Observable.create((subscriber: Subscriber<DeleteOperationResult>) => {
      let del = this.delete(this.delete_audio, {
        queryParams: queryParams,
        dataExtracter: (data) => this.operationDeleteResultExtractData(data, audioID)
      }).subscribe(async (res: any) => {

        let result: DeleteOperationResult = res;

        subscriber.next(result);
        subscriber.complete();
      });

      return () => {
        del && del.unsubscribe();
      };
    });
    return o;

  }

  protected operationResultExtractData(res: HttpResponse<Object>, data: AudioEntity): Object {
    let result = {
      opEntity: data
    };

    if (res.body) {
      result["opResult"] = (res.status == 200);
      if (!isNaN(Number(res.body))) {
        result["opEntityID"] = parseInt(res.body.toString());
      }
    }

    Object.defineProperty(result, "__initialResponse", {value: res});
    return result;
  }

  protected updateResultExtractData(res: HttpResponse<Object>, data: AudioEntity): Object {
    let result = {
      opEntity: data
    };

    result["opResult"] = (res.status == 200);

    Object.defineProperty(result, "__initialResponse", {value: res});
    return result;
  }

  protected operationDeleteResultExtractData(res: HttpResponse<Object>, itemID: number|string): Object {
    let result: DeleteOperationResult = {
      opEntityID: itemID,
      opResult: (res.status == 200)
    };

    Object.defineProperty(result, "__initialResponse", {value: res});
    return result;
  }

  protected fileOperationResultDataExtracter(res: HttpResponse<Object>, entityID: string, file: FileItem) {
    let result: AttachFileResult = {
      opResult: (res.status === 200),
      entityID: entityID,
      fileName: file.name
    };

    if (res.body) {
      if (!isNaN(Number(res.body))) {
        result.audio_id = parseInt(res.body.toString());
      }
    }

    Object.defineProperty(result, "__initialResponse", {value: res});
    return result;
  }
}
