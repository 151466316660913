import {Pipe, PipeTransform} from "@angular/core";
import * as moment from "moment";

@Pipe({name: 'timeago'})
export class TimeAgoPipe implements PipeTransform {
  transform(value: any, ...args: any[]): string {
    if (!value || value === 0) {
      return "Never";
    }
    return moment(value*1000).fromNow();
    /*
    let currDateTime = new Date();
    let diff = (currDateTime.getTime() - value*1000)/1000;
    return this.formatSeconds(diff, args);
    */
  }

  private formatSeconds(value: number, args: any[]) {
    return moment(value*1000);
  }

  private formatExact(value: number) {
    let days = Math.floor(value / 3600 / 24),
      hours = Math.floor(value / 3600 % 24),
      mins = Math.floor(value / 60 % 60),
      secs = Math.floor(value % 60);

    return `${days === 0 ? '' : days + (days > 1 ? ' days' : ' day')}` +
      ` ` +
      `${hours === 0 ? '' : hours + (hours > 1 ? ' hours' : ' hour')}` +
      ` ` +
      `${mins === 0 ? '' : mins + (mins > 1 ? ' mins' : ' min')}` +
      ` ` +
      `${secs === 0 ? '' : secs + (secs > 1 ? ' secs' : ' sec')}` +
      ` ago`;

    //return `${hours > 9 ? hours : '0' + hours}:${mins > 9 ? mins : '0' + mins}:${secs > 9 ? secs : '0' + secs}`;
  }
}
