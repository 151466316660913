
import {AbstractComponent} from "./abstract.component";
import {PagingModel} from "../../common/models/paging.model";
import {AppConfigService, DevicesConfig, PagingConfig, TestsConfig} from "../../common/services/app-config.service";
import {Component, Output} from "@angular/core";

@Component({
  template: ''
})
export class ConfigComponent extends AbstractComponent {
  pagingConfig: PagingModel = {
    totalCount: 0,
    currentPage: 1,
    pageSize: 10,
    maxPageSize: 0,
    pageSizeOptions: [10, 20, 50, 100]
  };

  protected devicesConfig: DevicesConfig = {
    defaultStatistics: 'mos_aqua',
    defaultPeriod: 'hour',
    defaultDuration: 24
  };

  protected testsConfig: TestsConfig = {
    defaultStatistics: 'mos_aqua',
    defaultPeriod: 'hour',
    defaultDuration: 24
  };

  constructor(protected configService: AppConfigService) {
    super();
  }

  public loadInitialConfig() {
    this.loadPagingConfig();
    this.loadDevicesConfig();
    this.loadTestsConfig();
  }

  public initComponent() {}

  public destroyComponent() {}

  public loadPagingConfig(): void {
    let paging: PagingConfig = this.configService.paging();
    if (paging) {
      this.pagingConfig.pageSize = paging.pageSize || 10;
      this.pagingConfig.pageSizeOptions = paging.pageSizeOptions || [10, 20, 50, 100];
    }

    for (let opt of this.pagingConfig.pageSizeOptions) {
      if (opt > this.pagingConfig.maxPageSize) {
        this.pagingConfig.maxPageSize = opt;
      }
    }
  }

  public loadDevicesConfig(): void {
    let devsConfig: DevicesConfig = this.configService.devices();
    if (devsConfig) {
      this.devicesConfig.defaultStatistics = devsConfig.defaultStatistics || 'mos_aqua';
      this.devicesConfig.defaultPeriod = devsConfig.defaultPeriod || 'hour';
      this.devicesConfig.defaultDuration = devsConfig.defaultDuration || 24;
    }
  }

  public loadTestsConfig(): void {
    let testsConfig: TestsConfig = this.configService.tests();
    if (testsConfig) {
      this.testsConfig.defaultStatistics = testsConfig.defaultStatistics || 'mos_aqua';
      this.testsConfig.defaultPeriod = testsConfig.defaultPeriod || 'hour';
      this.testsConfig.defaultDuration = testsConfig.defaultDuration || 24;
    }
  }
}
