import {NgModule} from "@angular/core";
import {SharedModule} from "../../shared/shared.module";
import {UxPopoverModule} from "../popover/popover.module";
import {UxConfirmDialogComponent} from "./confirm-dialog.component";
import {UxToolbarModule} from "../toolbar/toolbar.module";
import {UxButtonModule} from "../button/button.module";

@NgModule({
    imports: [SharedModule, UxPopoverModule, UxToolbarModule, UxButtonModule],
    exports: [UxConfirmDialogComponent],
    declarations: [UxConfirmDialogComponent]
})
export class UxConfirmDialogModule {
}