import {Component, Input} from "@angular/core";
import {UxAbstractButtonComponent} from "../abstract-button.component";
import {UxPropertyValidator} from "../../../common/decorator/ux-property-validator";
import {UxPropertyHandler} from "../../../common/decorator/ux-property-handler";

/* deprecated use instead UxNavigationButtonType. todo remove in 2.0 version */
export type NavigationButtonType = keyof NavigationButtonTypes;
export type UxNavigationButtonType = keyof UxNavigationButtonTypes;

/* deprecated use instead UxButtonKind. todo remove in 2.0 version */
export type ButtonKind = keyof ButtonKinds;
export type UxButtonKind = keyof UxButtonKinds;


/* deprecated use instead UxNavigationButtonTypes. todo remove in 2.0 version */
export class NavigationButtonTypes {
    'back' = true;
    'next' = true;
}

export class UxNavigationButtonTypes extends NavigationButtonTypes {
}

const NAVIGATION_BUTTON_TYPES = new NavigationButtonTypes();

/* deprecated use instead UxButtonKinds. todo remove in 2.0 version */
export class ButtonKinds {
    'primary' = true;
    'default' = true;
}
export class UxButtonKinds extends ButtonKinds {
}

const BUTTON_KINDS = new ButtonKinds();

@Component({
    selector: "ux-navigation-button",
    templateUrl: "./navigation-button.component.html",
    host: {"[class.ux-navigation-button]": "true"}
})
export class UxNavigationButtonComponent extends UxAbstractButtonComponent {
    @UxPropertyValidator({
        isValid: isValidType,
        message: "Property should be instanceof NavigationButtonType"
    }, "custom")
    @UxPropertyHandler({
        beforeChange: beforeChangeType
    })
    @Input()
    public type: NavigationButtonType = "back";

    @UxPropertyValidator({
        isValid: isValidKind,
        message: "Property should be instanceof ButtonKind"
    }, "custom")
    @UxPropertyHandler({
        beforeChange: beforeChangeKind
    })
    @Input()
    public kind: ButtonKind = "default";
}

export function beforeChangeType(newValue: string, oldValue: string) {
    oldValue = oldValue || "back";
    newValue = newValue || "back";

    this._setStyleClass(`_type-${oldValue}`, false);
    this._setStyleClass(`_type-${newValue}`, true);
}

export function isValidType(value: string) {
    return NAVIGATION_BUTTON_TYPES[value];
}

export function isValidKind(value: string): void {
    return BUTTON_KINDS[value];
}

export function beforeChangeKind(newValue: string, oldValue: string): void {
    oldValue = oldValue || "default";
    newValue = newValue || "default";
    this._setStyleClass(`_kind-${oldValue}`, false);
    this._setStyleClass(`_kind-${newValue}`, true);
}
