import {NgModule} from "@angular/core";
import {SharedModule} from "../../shared/shared.module";
import {UxWizardNavigationComponent} from "./wizard-navigation.component";
import {WizardStep} from "./wizard-step.model";

export {WizardStep};

@NgModule({
    imports: [SharedModule],
    declarations: [UxWizardNavigationComponent],
    exports: [UxWizardNavigationComponent]
})
export class UxWizardNavigationModule {
}
