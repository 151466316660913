import {ChartService} from "./chart.service";
import {Injectable, Injector} from "@angular/core";
import {ChartDataModel, ChartModel, ChartType, PhoneCharts} from "../model/chart.model";
import {StatisticsDataEntry, StatisticsEntity} from "../../../common/models/entity/statistics-entity.model";
import * as Highcharts from 'highcharts';
import {HighChartModel} from "../../../../ux-lib/components/charts/highchart/model/highchart.model";
import {ChartPeriodModel} from "../model/chart-period.model";
import {DateUtils} from "../../../../ux-lib/components/fields/date/date-utils";
import {SeriesColumnOptions, SeriesLineOptions, XAxisOptions} from "highcharts";
import {StatisticDataType} from "../../../common/models/statistic-data.type";
import {StatisticPeriodType} from "../../../common/models/statistic-period.type";
import {ReportAquaModel, SpectrumPair} from "../../../common/models/entity/report-aqua.model";
import {SeriesHeatmapOptions} from "highcharts";
import {PointOptionsObject} from "highcharts";
const HighchartsMore = require("highcharts/highcharts-more");
HighchartsMore(Highcharts);

class ChartTypeMap extends Map<ChartType, HighChartModel> {}; // map<chart_type, chart_data>
class DataTypeToChartTypeMap extends Map<StatisticDataType, ChartTypeMap> {}; // map<data_type, chart_type_map>
class PhoneChartsMap extends Map<string, DataTypeToChartTypeMap> {};  // map<phone_name, DataTypeToChartTypeMap>

interface HeatmapDataEntry extends PointOptionsObject {
  date: number,
}

@Injectable()
export class HighchartService {

  constructor(protected injector: Injector) {
    Highcharts.setOptions({
      time: {
        useUTC: false
      }
    });
  }

  public generateCharts(chartTypes: Map<StatisticDataType, ChartType[]>, // map<data_type, chart_type[]>
                        mode: StatisticPeriodType, // hour, day, month
                        values: StatisticsEntity[],
                        title?: string): PhoneCharts<HighChartModel>[] {

    let fromTo: number[] = this.findFromTo(values);
    let rangeFrom = fromTo[0];
    let rangeTo = fromTo[1];

    let dataTypes: StatisticDataType[] = this.keysToArray(chartTypes.keys());

    // map<phone_name, map<>>
    let phoneChartsMap: PhoneChartsMap = new PhoneChartsMap();

    values.forEach((value: StatisticsEntity) => {
      let phoneCharts = phoneChartsMap.get(value.phone_id);
      if (phoneCharts === undefined || phoneCharts === null) {
        phoneCharts = new DataTypeToChartTypeMap(); //map<data_type, chart_type_map>
        phoneChartsMap.set(value.phone_id, phoneCharts);
      }

      dataTypes.forEach((dataType: StatisticDataType) => { // mos_pvqa, mos_aqua...
        let dataCharts: ChartTypeMap = phoneCharts.get(dataType);
        if (dataCharts === undefined || dataCharts === null) {
          dataCharts = new ChartTypeMap();
          phoneCharts.set(dataType, dataCharts);
        }

        let chart_types: ChartType[] = chartTypes.get(dataType);
        chart_types.forEach((chartType: ChartType) => { // linear, calendar...
          let chartModel: HighChartModel = dataCharts.get(chartType);
          if (chartModel === undefined || chartModel === null) {
            let fullTitle = undefined;
            if (title) {
              fullTitle = this.getDataTypeTitle(dataType);
            }
            chartModel = this.createChart(chartType, dataType, fullTitle, mode, rangeFrom, rangeTo);
            dataCharts.set(chartType, chartModel);
          }

          this.updateChartSeries(dataType, mode, value, chartModel);
        });
      });
    });

    let phonesCharts: PhoneCharts<HighChartModel>[] = [];
    phoneChartsMap.forEach((phoneCharts: DataTypeToChartTypeMap, phone: string) => {
      let newPhoneCharts: PhoneCharts<HighChartModel> = {
        id: phone,
        phone: phone,
        title: title,
        isEmpty: true,
        chartDataModels: [],
        chartDataModelsMap: new Map<StatisticDataType, ChartDataModel<HighChartModel>>()
      };

      dataTypes.forEach((dataType: StatisticDataType) => { // mos_pvqa, mos_aqua...
        let dataCharts: ChartTypeMap = phoneCharts.get(dataType);
        let chartDataModel: ChartDataModel<HighChartModel> = {
          id: newPhoneCharts.id + "__" + dataType,
          dataType: dataType,
          chartModels: [],
          chartModelsMap: new Map<ChartType, ChartModel<HighChartModel>>()
        };

        let chart_types: ChartType[] = chartTypes.get(dataType);
        chart_types.forEach((chartType: ChartType) => { // linear, calendar...
          let chart: HighChartModel = dataCharts.get(chartType);
          if (chart.seriesEmpty) {
            if (chartType === "linear") {
              chart.subtitle = {
                floating: true,
                text: "No available data",
                verticalAlign: 'middle'
              }
            }
          }
          else {
            if (chartType === "linear") {
              chart.legend.enabled = true;
            }
            if (newPhoneCharts.isEmpty) {
              newPhoneCharts.isEmpty = false;
            }
          }
          let chartModel: ChartModel<HighChartModel> = {
            id: chartDataModel.id + "__" + chartType,
            chartType: chartType,
            chartModel: dataCharts.get(chartType)
          };
          chartDataModel.chartModels.push(chartModel);
          chartDataModel.chartModelsMap.set(chartType, chartModel);
        });

        newPhoneCharts.chartDataModels.push(chartDataModel);
        newPhoneCharts.chartDataModelsMap.set(dataType, chartDataModel);
      });

      phonesCharts.push(newPhoneCharts);
    });

    return phonesCharts;
  }

  public generateSpectrumChart(probeID: number|string, testRunTime: number, aquaReport: ReportAquaModel<number>): ChartModel<HighChartModel> {
    let chartModel: ChartModel<HighChartModel> = {
      id: probeID,
      chartType: 'column',
      chartModel: this.createColumnChart(undefined, testRunTime, testRunTime)
    };

    if (aquaReport.AQuAReport && aquaReport.AQuAReport.SpectrumPairs && aquaReport.AQuAReport.SpectrumPairs.PairsNumber > 0) {
      chartModel.chartModel.seriesEmpty = false;
      chartModel.chartModel.legend.enabled = true;

      let referenceSeries: SeriesColumnOptions = <SeriesColumnOptions>chartModel.chartModel.series[0];
      let testSeries: SeriesColumnOptions = <SeriesColumnOptions>chartModel.chartModel.series[1];
      let xAxis: XAxisOptions = <XAxisOptions>chartModel.chartModel.xAxis;
      let categories = xAxis.categories;

      aquaReport.AQuAReport.SpectrumPairs.Pairs.forEach((item: SpectrumPair<number>, index: number) => {
        categories.push('#' + index);
        referenceSeries.data.push(item.Reference);
        testSeries.data.push(item.Test);
      })
    }

    return chartModel;
  }

  public generatePvqaStatisticsChart(probeID: number|string, testRunTime: number, jsonData: any[]): ChartModel<HighChartModel> {

    let series: SeriesLineOptions[] = [];

    let chartModel: ChartModel<HighChartModel> = {
      id: probeID,
      chartType: 'linear',
      chartModel: this.createLineChart(undefined, testRunTime, testRunTime, series, 'Call Time', 'Value')
    };

    if (jsonData.length > 0) {
      chartModel.chartModel.seriesEmpty = false;
      chartModel.chartModel.legend.enabled = true;
      chartModel.chartModel.tooltip = {
        headerFormat: "<span style='font-size: 10px'>{point.key}</span><br/>",
        shared: true
      };
      let xAxis: XAxisOptions = <XAxisOptions>chartModel.chartModel.xAxis;
      if (!xAxis.labels) {
        xAxis.labels = {};
      }
      xAxis.labels.formatter = function () {
        return Highcharts.dateFormat('%S.%L', this.value - testRunTime*1000) + 's';
      };

      let lastTime;
      let seriesMap: Map<string, SeriesLineOptions> = new Map<string, SeriesLineOptions>();
      jsonData.forEach(jsonRow => {
        let timeOffsetsVal = jsonRow['Time'];
        if (timeOffsetsVal.toString().indexOf(':') >= 0) {
          let timeOffsets = jsonRow['Time'].split(':');
          if (!lastTime) {
            lastTime = (testRunTime + parseFloat(timeOffsets[0].trim())) * 1000;
            this.addSeriesData(lastTime, jsonRow, chartModel.chartModel, seriesMap);
            lastTime = (testRunTime + parseFloat(timeOffsets[1].trim())) * 1000;
            this.addSeriesData(lastTime, jsonRow, chartModel.chartModel, seriesMap);
          }
          else {
            lastTime = (testRunTime + parseFloat(timeOffsets[1].trim())) * 1000;
            this.addSeriesData(lastTime, jsonRow, chartModel.chartModel, seriesMap);
          }
        }
        else {
          lastTime = (testRunTime + parseFloat(timeOffsetsVal)) * 1000;
          this.addSeriesData(lastTime, jsonRow, chartModel.chartModel, seriesMap);
        }
      })
    }

    return chartModel;
  }

  private addSeriesData(timeOffset: number, jsonRow: any, chartModel: HighChartModel, seriesMap: Map<string, SeriesLineOptions>) {
    Object.keys(jsonRow).forEach(key => {
      if (key !== 'Time' && !key.startsWith('field')) {
        let series: SeriesLineOptions = seriesMap.get(key);
        if (series === undefined || series === null) {
          series = {
            type: 'line',  // min values
            name: key,
            data: []
          }
          chartModel.series.push(series);
          seriesMap.set(key, series);
        }
        let xAxis: XAxisOptions = <XAxisOptions>chartModel.xAxis;
        if (!xAxis.tickPositions) {
          xAxis.tickPositions = [];
        }
        if (xAxis.tickPositions.indexOf(timeOffset) < 0) {
          xAxis.tickPositions.push(timeOffset);
        }
        series.data.push([timeOffset, parseFloat(jsonRow[key])]);
      }
    });
  }

  private findFromTo(values: StatisticsEntity[]): number[] {
    let from = new Date().getTime();
    let to = new Date(0).getTime();
    values.forEach((value: StatisticsEntity) => {
      let start_timestamp = value.start_timestamp * 1000;
      let end_timestamp = value.end_timestamp * 1000;
      if (start_timestamp < from) {
        from = start_timestamp;
      }
      if (end_timestamp > to) {
        to = end_timestamp;
      }
    })
    if (from > to) {
      from = to;
    }
    return [from, to];
  }

  private getDataTypeTitle(dataType: StatisticDataType) {
    return this.getDataTypeLabel(dataType) + " distribution";
  }

  private keysToArray<T>(iter: IterableIterator<T>): T[] {
    let result: T[] = [];
    let itRes: IteratorResult<T> = iter.next();
    while (!itRes.done) {
      result.push(itRes.value);
      itRes = iter.next();
    }
    return result;
  }

  private createChart(chartType: ChartType, dataType: StatisticDataType, title: string, mode: StatisticPeriodType, rangeFrom: number, rangeTo: number): HighChartModel {
    let chartModel: HighChartModel = null;
    if (chartType === "linear") {
      chartModel = this.createLineChart(title, rangeFrom, rangeTo, [
        {
          type: 'line',  // min values
          name: 'Worst value',
          color: '#CE0010',
          data: []
        },
        {
          type: 'line',  // average values
          name: 'Average value',
          color: '#FFBA21',
          data: []
        },
        {
          type: 'line',  // max values
          name: 'Best value',
          color: '#219A18',
          data: []
        }
      ]);
    }
    else if (chartType === "calendar") {
      chartModel = this.createHeatMapChart(title, mode, dataType, rangeFrom, rangeTo);
    }
    return chartModel;
  }

  private updateChartSeries(dataType: StatisticDataType, mode: StatisticPeriodType, value: StatisticsEntity, chartModel: HighChartModel): void {
    if (chartModel.chartType === 'linear') {
      this.updateLineChartSeries(dataType, value, chartModel)
    }
    else if (chartModel.chartType === 'calendar') {
      this.updateHeatMapSeries(dataType, mode, value, chartModel);
    }
  }

  private updateLineChartSeries(dataType: StatisticDataType, value: StatisticsEntity, chartModel: HighChartModel): void {
    let minSeries: SeriesLineOptions = <SeriesLineOptions>chartModel.series[0];
    let avgSeries: SeriesLineOptions = <SeriesLineOptions>chartModel.series[1];
    let maxSeries: SeriesLineOptions = <SeriesLineOptions>chartModel.series[2];

    let statisticsEntry: StatisticsDataEntry = value[dataType];
    if (statisticsEntry) {
      if (statisticsEntry.Count > 0) {
        chartModel.seriesEmpty = false;

        minSeries.data.push([value.start_timestamp * 1000, statisticsEntry.min]);
        avgSeries.data.push([value.start_timestamp * 1000, statisticsEntry.average]);
        maxSeries.data.push([value.start_timestamp * 1000, statisticsEntry.max]);
      }
      else {
        minSeries.data.push([value.start_timestamp * 1000, null]);
        avgSeries.data.push([value.start_timestamp * 1000, null]);
        maxSeries.data.push([value.start_timestamp * 1000, null]);
      }
    }
  }

  private updateHeatMapSeries(dataType: StatisticDataType, mode: StatisticPeriodType, value: StatisticsEntity, chartModel: HighChartModel): void {
    let start_timestamp = value.start_timestamp * 1000;
    let statisticsEntry: StatisticsDataEntry = value[dataType];
    let dateTime = new Date(start_timestamp);
    let series: SeriesHeatmapOptions = <SeriesHeatmapOptions>chartModel.series[0];
    let x = 0, y = 0;

    if (mode === ChartPeriodModel.PERIOD_HOURS) {
      // each row - day
      // each column in row - hours from 00 to 23
      if (statisticsEntry && statisticsEntry.Count > 0) {
        chartModel.seriesEmpty = false;
        // 'x', 'y', 'value', 'date', 'description'
        x = dateTime.getHours();
        y = DateUtils.getDiffDays(chartModel.dateFrom, start_timestamp);
      }
    }
    else if (mode === ChartPeriodModel.PERIOD_DAYS) {
      // each row - week
      // each column in row - day of week
      if (statisticsEntry && statisticsEntry.Count > 0) {
        chartModel.seriesEmpty = false;
        // 'x', 'y', 'value', 'date', 'description'
        x = dateTime.getDay();
        y = DateUtils.getDiffWeeks(chartModel.dateFrom, start_timestamp);
      }
    }
    else if (mode === ChartPeriodModel.PERIOD_MONTHS) {
      // each row - year
      // each column in row - month of year
      if (statisticsEntry && statisticsEntry.Count > 0) {
        chartModel.seriesEmpty = false;
        // 'x', 'y', 'value', 'date', 'description'
        x = dateTime.getMonth();
        y = DateUtils.getDiffYear(chartModel.dateFrom, start_timestamp);
      }
    }
    if (statisticsEntry && statisticsEntry.Count > 0) {
      let xAxis: XAxisOptions = <XAxisOptions>(chartModel.xAxis);
      series.data[y * xAxis.categories.length + x] = <HeatmapDataEntry>{
        x: x,
        y: y,
        value: statisticsEntry.average,
        date: start_timestamp,
        description: ''
      }
    }
  }

  public createLineChart(title: string, from: number, to: number, series: SeriesLineOptions[], xAxisTitle?: string, yAxisTitle?: string): HighChartModel {
    return {
      seriesEmpty: true,
      chartType: "linear",
      dateFrom: from,
      dateTo: to,
      chart: {
        type: 'line'
      },
      title: {
        text: title
      },
      xAxis: {
        type: "datetime",
        title: {
          text: xAxisTitle ? xAxisTitle : 'Date'
        },
        crosshair: true
      },
      yAxis: {
        min: 0,
        title: {
          text: yAxisTitle ? yAxisTitle : 'MOS value'
        }
      },
      legend: {
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'middle',
        enabled: false
      },
      plotOptions: {
        series: {
          label: {
            connectorAllowed: false
          }
        }
      },
      series: series
    }
  }

  private createHeatMapChart(title: string, mode: StatisticPeriodType, dataType: StatisticDataType, from: number, to: number): HighChartModel {

    let fromDate = new Date(from);
    let toDate = new Date(to);

    fromDate.setHours(0, 0, 0);
    toDate.setHours(23, 59, 59);
    let xCategories: string[] = [];
    let yCategories: string[] = [];
    if (mode === ChartPeriodModel.PERIOD_HOURS) { // y -day of week/month; x - hours
      for (let h = 0; h < 24; h++) {
        xCategories.push(h.toString());
      }
      let currDate = new Date(fromDate.getTime());
      while (currDate.getTime() <= toDate.getTime()) {
        yCategories.push(
          DateUtils.convertToDateFormat(currDate, "dddd")
          + "<br/>"
          + DateUtils.convertToDateFormat(currDate, "MMMM Do YYYY")
        );
        currDate.setDate(currDate.getDate()+1);
      }
    } else if (mode === ChartPeriodModel.PERIOD_DAYS) { //y - week of month;  x - days
      xCategories = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
      fromDate.setDate(fromDate.getDate() - fromDate.getDay());
      toDate.setDate(toDate.getDate() + (7 - toDate.getDay() - 1));

      let currDate = new Date(fromDate.getTime());
      while (currDate.getTime() <= toDate.getTime()) {
        let weekStart = new Date(currDate.getTime());
        let weekEnd = new Date(currDate.getTime());
        weekEnd.setDate(weekEnd.getDate()+7);

        yCategories.push("Week "
          + DateUtils.convertToDateFormat(weekStart, "ww")
          + "<br/>"
          + DateUtils.convertToDateFormat(weekStart, "DD.MM.YYYY")
          + " - "
          + DateUtils.convertToDateFormat(weekEnd, "DD.MM.YYYY")
        );
        currDate.setDate(currDate.getDate()+7);
      }
    } else if (mode === ChartPeriodModel.PERIOD_MONTHS) { // y - year;  x - month
      xCategories = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
      fromDate.setDate(1);
      fromDate.setMonth(0);
      toDate.setMonth(12);
      toDate.setDate(0); // move to last day of previous month

      let currDate = new Date(fromDate.getTime());
      while (currDate.getTime() <= toDate.getTime()) {
        yCategories.push(DateUtils.convertToDateFormat(currDate, "Y"));
        currDate.setFullYear(currDate.getFullYear() + 1);
      }
    }

    let data: HeatmapDataEntry[] = [];
    let x = 0, y = 0;
    let currDate = new Date(fromDate.getTime());
    while (currDate.getTime() <= toDate.getTime()) {
      if (mode === ChartPeriodModel.PERIOD_HOURS) { // y -day of week/month; x - hours
        x = currDate.getHours();
        y = DateUtils.getDiffDays(fromDate.getTime(), currDate.getTime());

        currDate.setHours(currDate.getHours() + 1);
      }
      else if (mode === ChartPeriodModel.PERIOD_DAYS) { //y - week of month;  x - days
        x = currDate.getDay();
        y = DateUtils.getDiffWeeks(fromDate.getTime(), currDate.getTime());

        currDate.setDate(currDate.getDate()+1);
      }
      else if (mode === ChartPeriodModel.PERIOD_MONTHS) { // y - year;  x - month
        x = currDate.getMonth();
        y = DateUtils.getDiffYear(fromDate.getTime(), currDate.getTime());

        currDate.setMonth(currDate.getMonth() + 1);
      }
      data.push({
        x: x,
        y: y,
        value: null,
        date: currDate.getTime(),
        description: 'There are no data for this period'
      });
    }

    let _self = this;

    return {
      seriesEmpty: true,
      chartType: "calendar",
      dateFrom: fromDate.getTime(),
      dateTo: toDate.getTime(),
      chart: {
        type: 'heatmap',
        marginTop: 40,
        marginBottom: 40,
        plotBorderWidth: 1
      },
      title: {
        text: title
      },
      xAxis: {
        categories: xCategories
      },
      yAxis: {
        title: null,
        reversed: true,
        categories: yCategories
      },
      colorAxis: {
        min: 1.0,
        max: 5.0,
        stops: [
//          [0.0000, '#FFFFFF'], [0.0000, '#FFFFFF'], // 1.0 - 1.0
          [0.0000, '#CE0010'], [0.4000, '#CE0010'], // 1.0 - 2.6
          [0.4000, '#DE2429'], [0.5250, '#DE2429'], // 2.6 - 3.1
          [0.5250, '#F75521'], [0.6500, '#F75521'], // 3.1 - 3.6
          [0.6500, '#FFBA21'], [0.7500, '#FFBA21'], // 3.6 - 4.0
          [0.7500, '#84C339'], [0.8250, '#84C339'], // 4.0 - 4.3
          [0.8250, '#219A18'], [1.0000, '#219A18'], // 4.3 - 5.0
        ],
      },
      legend: {
        align: 'right',
        layout: 'vertical',
        margin: 0,
        verticalAlign: 'top',
        y: 23,
        symbolHeight: 320
      },
      plotOptions: {
        series: {
        },
        heatmap: {
        }
      },
      tooltip: {
        formatter: function () {
          let point = this.point;
          if (point['value'] === null) {
            return "No available data";
          }
          return _self.getDataTypeLabel(dataType) + " value: " + point['value'].toFixed(2)
            + "<br/>"
            + _self.getSatisfaction(point['value'])
            ;
        }
      },
      series: [
        {
          type: 'heatmap',
          name: 'Distribution',
          borderWidth: 1,
          borderColor: "#FFFFFF",
          keys: ['x', 'y', 'value', 'date', 'description'],
          data: data
        }
      ]
    }
  }

  public createColumnChart(title: string, dateFrom: number, dateTo: number): HighChartModel {
    return {
      seriesEmpty: true,
      chartType: "column",
      dateFrom: dateFrom,
      dateTo: dateTo,
      chart: {
        type: 'column'
      },
      title: {
        text: title
      },
      xAxis: {
        categories: [],
        crosshair: true
      },
      yAxis: {
        min: 0,
        max: 100
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
        '<td style="padding:0"><b>{point.y:.1f}</b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true
      },
      legend: {
        layout: 'horizontal',
        align: 'center',
        verticalAlign: 'top',
        enabled: false
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0
        }
      },
      series: [
        {
          type: 'column',
          name: 'Reference',
          color: '#00911e',
          data: []
        },
        {
          type: 'column',
          name: 'Test',
          color: '#0000a9',
          data: []
        }
      ]
    }
  }

  private getDataTypeLabel(dataType: StatisticDataType): string {
    switch (dataType) {
      case "mos_pvqa":
        return "MOS PVQA";
      case "mos_aqua":
        return "MOS AQUA";
      case "r_factor":
        return "Sevana R-factor";
      case "percents_aqua":
        return "Sevana Percentage";
      case "mos_network":
        return "MOS Network";
    }
    return "";
  }

  private getSatisfaction(value: number): string {
    if (value > 4.3) {
      return "Very Satisfied";
    }
    else if (value > 4.0) {
      return "Satisfied";
    }
    else if (value > 3.6) {
      return "Some Users Dissatisfied";
    }
    else if (value > 3.1) {
      return "Many Users Dissatisfied";
    }
    else if (value > 2.6) {
      return "Nearly All Users Dissatisfied";
    }
    else {
      return "Not Recommended";
    }
  }
}
