import {NgModule} from "@angular/core";

import {SharedModule} from "../../shared/shared.module";
import {UxScrollComponent} from "./scroll.component";

@NgModule({
    imports: [SharedModule],
    declarations: [UxScrollComponent],
    exports: [UxScrollComponent]
})
export class UxScrollModule {
}
