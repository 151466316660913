import {Injectable, Injector} from "@angular/core";
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpResponse} from "@angular/common/http";
import {SimpleMap} from "../models/simple-map.model";
import {Observable} from "rxjs";
import {catchError, delay, map} from "rxjs/operators";
import {ErrorHandlerService, HandleError} from "./error-handler.service";
import {AppConfigService, RoutesConfig} from "./app-config.service";
import {WindowService} from "./window.service";
import {UrlBuilder} from "../utils/url-builder";
import {ApiRequestParams} from "../models/api-request-params.type";
import {QueryParam} from "../models/query-param.type";

@Injectable()
export class ApiService {
  private DELAY_TIMOUT: number = 0;
  protected appConfigService: AppConfigService;
  protected http: HttpClient;
  protected errorHandler: ErrorHandlerService;
  private handleError: HandleError;
  private windowService: WindowService;
  protected debug = window.localStorage.getItem("debug") === "1";

  constructor(protected injector: Injector) {
    this.appConfigService = injector.get(AppConfigService);
    this.http = this.injector.get(HttpClient);
    this.errorHandler = this.injector.get(ErrorHandlerService);
    this.handleError = this.errorHandler.createHandleError('ApiService');
    this.windowService = this.injector.get(WindowService);
  }

  protected get(urls: string[],
                apiRequestParams?: ApiRequestParams) {
    apiRequestParams = apiRequestParams || {};

    let headers: HttpHeaders = this.createApiHttpHeaders(apiRequestParams.headerParams);
    let httpRequestParams: HttpParams = this.convertSimpleMapToHttpParams(apiRequestParams.requestParams);
    let resolvedUrl = this.resolveUrl(urls, apiRequestParams.pathParams, apiRequestParams.queryParams);

    return this.http.get(
      resolvedUrl,
      {
        headers: headers,
        observe: "response",
        params: httpRequestParams,
      })
      .pipe(
        delay(this.DELAY_TIMOUT),
        map(apiRequestParams.dataExtracter ? apiRequestParams.dataExtracter : (data) => this.extractData(data))
      )
      .pipe(
        catchError(this.handleError("get", resolvedUrl, []))
      );
  }

  protected get_collection(urls: string[],
                           apiRequestParams?: ApiRequestParams,
                           getResource?: boolean) {
    apiRequestParams = apiRequestParams || {};

    let headers: HttpHeaders = getResource ? this.createResourceHttpHeaders(apiRequestParams.headerParams) : this.createApiHttpHeaders(apiRequestParams.headerParams);
    let httpRequestParams: HttpParams = this.convertSimpleMapToHttpParams(apiRequestParams.requestParams);
    let resolvedUrl = this.resolveUrl(urls, apiRequestParams.pathParams, apiRequestParams.queryParams);

    return this.http.get(
      resolvedUrl,
      {
        headers: headers,
        observe: "response",
        params: httpRequestParams,
      })
      .pipe(
        delay(this.DELAY_TIMOUT),
        map(apiRequestParams.dataExtracter ? apiRequestParams.dataExtracter : (data) => this.extractCollectionData(data))
      )
      .pipe(
        catchError(this.handleError("get", resolvedUrl, []))
      );
  }

  protected get_map(urls: string[],
                           apiRequestParams?: ApiRequestParams) {
    apiRequestParams = apiRequestParams || {};

    let headers: HttpHeaders = this.createApiHttpHeaders(apiRequestParams.headerParams);
    let httpRequestParams: HttpParams = this.convertSimpleMapToHttpParams(apiRequestParams.requestParams);
    let resolvedUrl = this.resolveUrl(urls, apiRequestParams.pathParams, apiRequestParams.queryParams);

    return this.http.get(
      resolvedUrl,
      {
        headers: headers,
        observe: "response",
        params: httpRequestParams,
      })
      .pipe(
        delay(this.DELAY_TIMOUT),
        map(apiRequestParams.dataExtracter ? apiRequestParams.dataExtracter : (data) => this.extractMappedData(data))
      )
      .pipe(
        catchError(this.handleError("get", resolvedUrl, []))
      );
  }

  protected get_string(urls: string[],
                       apiRequestParams?: ApiRequestParams): Observable<HttpResponse<string>> {
    apiRequestParams = apiRequestParams || {};

    let headers: HttpHeaders = this.createApiHttpHeaders(apiRequestParams.headerParams);
    let httpRequestParams: HttpParams = this.convertSimpleMapToHttpParams(apiRequestParams.requestParams);
    let resolvedUrl = this.resolveUrl(urls, apiRequestParams.pathParams, apiRequestParams.queryParams);

    return this.http.get(
      resolvedUrl,
      {
        headers: headers,
        params: httpRequestParams,
        observe: "response",
        responseType: "text"
      })
      .pipe(
        delay(this.DELAY_TIMOUT),
        map(apiRequestParams.dataExtracter ? apiRequestParams.dataExtracter : (data) => this.extractData(data))
      )
      .pipe(
        catchError(this.handleError("get", resolvedUrl, []))
      );
  }

  protected getFile(urls: string[],
                    apiRequestParams?: ApiRequestParams): Observable<Blob> {
    apiRequestParams = apiRequestParams || {};

    let headers: HttpHeaders = this.createApiHttpHeaders(apiRequestParams.headerParams);
    let httpRequestParams: HttpParams = this.convertSimpleMapToHttpParams(apiRequestParams.requestParams);
    let resolvedUrl = this.resolveUrl(urls, apiRequestParams.pathParams, apiRequestParams.queryParams);

    return this.http.get(
      resolvedUrl,
      {
        headers: headers,
        observe: "response",
        params: httpRequestParams,
        responseType: "blob"
      })
      .pipe(
        delay(this.DELAY_TIMOUT),
        map(apiRequestParams.dataExtracter ? apiRequestParams.dataExtracter : (data) => this.extractBlobData(data))
      )
      .pipe(
        catchError(this.handleError("getFile", resolvedUrl, []))
      );
  }

  protected put(urls: string[],
                apiRequestParams?: ApiRequestParams): Observable<HttpResponse<string>> {
    if (this.debug) {
      return this.get(urls, apiRequestParams);
    }

    let headers: HttpHeaders = this.createApiHttpHeaders(apiRequestParams.headerParams);
    let httpRequestParams: HttpParams = this.convertSimpleMapToHttpParams(apiRequestParams.requestParams);
    let resolvedUrl = this.resolveUrl(urls, apiRequestParams.pathParams, apiRequestParams.queryParams);
    return this.http.put(resolvedUrl,
      apiRequestParams.body,
      {
        headers: headers,
        params: httpRequestParams,
        observe: "response",
        responseType: "text"
      })
      .pipe(
        delay(this.DELAY_TIMOUT),
        map(apiRequestParams.dataExtracter ? apiRequestParams.dataExtracter : (data) => this.extractData(data))
      )
      .pipe(
        catchError(this.handleError("put", resolvedUrl, []))
      );
  }

  protected post(urls: string[],
                apiRequestParams?: ApiRequestParams): Observable<HttpResponse<string>> {
    if (this.debug) {
      return this.get(urls, apiRequestParams);
    }

    let headers: HttpHeaders = this.createApiHttpHeaders(apiRequestParams.headerParams);
    let httpRequestParams: HttpParams = this.convertSimpleMapToHttpParams(apiRequestParams.requestParams);
    let resolvedUrl = this.resolveUrl(urls, apiRequestParams.pathParams, apiRequestParams.queryParams);
    return this.http.post(resolvedUrl,
      apiRequestParams.body,
      {
        headers: headers,
        observe: "response",
        params: httpRequestParams,
        responseType: "text"
      })
      .pipe(
        delay(this.DELAY_TIMOUT),
        map(apiRequestParams.dataExtracter ? apiRequestParams.dataExtracter : (data) => this.extractData(data))
      )
      .pipe(
        catchError(this.handleError("post", resolvedUrl, []))
      );
  }

  protected postFile(urls: string[],
                     apiRequestParams?: ApiRequestParams): Observable<HttpResponse<string>> {

    if (this.debug) {
      return this.get(urls, apiRequestParams);
    }

    let headers: HttpHeaders = this.createApiHttpHeaders(apiRequestParams.headerParams);
    let httpRequestParams: HttpParams = this.convertSimpleMapToHttpParams(apiRequestParams.requestParams);
    let resolvedUrl = this.resolveUrl(urls, apiRequestParams.pathParams, apiRequestParams.queryParams);
    return this.http.post(resolvedUrl,
      apiRequestParams.body,
      {
        headers: headers,
        params: httpRequestParams,
        observe: "response",
        reportProgress: true,
        responseType: "text"
      })
      .pipe(
        delay(this.DELAY_TIMOUT),
        map(apiRequestParams.dataExtracter ? apiRequestParams.dataExtracter : (data) => this.extractData(data))
      )
      .pipe(
        catchError(this.handleError("postFile", resolvedUrl, []))
      );
  }

  protected delete(urls: string[],
                   apiRequestParams?: ApiRequestParams): Observable<HttpResponse<string>> {
    if (this.debug) {
      return this.get(urls, apiRequestParams);
    }

    let headers: HttpHeaders = this.createApiHttpHeaders(apiRequestParams.headerParams);
    let httpRequestParams: HttpParams = this.convertSimpleMapToHttpParams(apiRequestParams.requestParams);
    let resolvedUrl = this.resolveUrl(urls, apiRequestParams.pathParams, apiRequestParams.queryParams);
    return this.http.delete(resolvedUrl,
      {
        headers: headers,
        params: httpRequestParams,
        observe: "response",
        responseType: "text"
      })
      .pipe(
        delay(this.DELAY_TIMOUT),
        map(apiRequestParams.dataExtracter ? apiRequestParams.dataExtracter : (data) => this.extractData(data))
      )
      .pipe(
        catchError(this.handleError("delete", resolvedUrl, []))
      );
  }

  protected createApiHttpHeaders(headerParams?: SimpleMap<string>): HttpHeaders {
    let headers: HttpHeaders = new HttpHeaders(headerParams);
    let routes: RoutesConfig = this.appConfigService.routes();
    if (routes && routes.proxy && routes.api) {
      headers = headers.append("Target-URL", routes.api);
    }
    return headers;
  }

  protected createResourceHttpHeaders(headerParams?: SimpleMap<string>): HttpHeaders {
    let headers: HttpHeaders = new HttpHeaders(headerParams);
    let routes: RoutesConfig = this.appConfigService.routes();
    if (routes && routes.proxy && routes.server) {
      headers = headers.append("Target-URL", routes.server);
    }
    return headers;
  }

  private convertSimpleMapToHttpParams(requestParams?: SimpleMap<any>): HttpParams {
    let httpParams: HttpParams = new HttpParams();
    if (requestParams) {
      Object.keys(requestParams).forEach((paramName: string) => {
        httpParams.append(paramName, requestParams[paramName]);
      });
    }
    return httpParams;
  }

  protected resolveUrl(urls: string[], pathParams?: SimpleMap<any | null>, queryParams?: QueryParam[], getResource?: boolean): string {
    let url;
    if ((getResource || this.debug) && urls[1]) {
      url = urls[1];
    }
    else {
      let routes: RoutesConfig = this.appConfigService.routes();
      if (routes && routes.proxy) {
        url = routes.proxy +  urls[0];
      }
      else if (routes && routes.api) {
        url = routes.api +  urls[0];
      }
      else {
        url = this.windowService.getFullHostname() + urls[0];
      }
      url = new UrlBuilder(url)
        .pathParams(pathParams)
        .queryParams(queryParams)
        .build();
    }
    return url;
  }

  //noinspection JSMethodCanBeStatic
  protected extractData(res: HttpResponse<Object>): Object {
    let body = {};
    if (res.status >= 200 && res.status < 300) {
      if (res.body && res.body instanceof Object) {
        body = res.body;
      }
      else {
        body = {
        }
      }
      body = res.body || {};
      //this.connectionStatusService.setHttpSuccessStatus(true);
    } else if (res.status === 412) {
      body = res.body || {};
      //this.connectionStatusService.setHttpSuccessStatus(true);
    } else {
      if (res.status) { // 304, 500
        //this.connectionStatusService.setHttpSuccessStatus(true);
      } else {
        //this.connectionStatusService.setHttpSuccessStatus(false);
      }
    }

    Object.defineProperty(body, "__initialResponse", {value: res});

    return body;
  }

  //noinspection JSMethodCanBeStatic
  protected extractBlobData(res: HttpResponse<Blob>): Object {
    let body = {};
    if (res.status >= 200 && res.status < 300) {
      if (res.body && res.body instanceof Object) {
        body = res.body;
      }
      else {
        body = {
        }
      }
      body = res.body || {};
      body["opResult"] = (res.status == 200);
      //this.connectionStatusService.setHttpSuccessStatus(true);
    } else if (res.status === 412) {
      body = res.body || {};
      //this.connectionStatusService.setHttpSuccessStatus(true);
    } else {
      if (res.status) { // 304, 500
        //this.connectionStatusService.setHttpSuccessStatus(true);
      } else {
        //this.connectionStatusService.setHttpSuccessStatus(false);
      }
    }

    Object.defineProperty(body, "__initialResponse", {value: res});

    return body;
  }

  protected extractCollectionData(res: HttpResponse<Object>): Object {
    let body = [];
    if (res.status === 200) {
      if (res.body && res.body instanceof Object) {
        body = res.body as [];
      }
      else if (res.body === null || (res.body && (typeof res.body === "string"))) {
        body = [];
      }
    }
    Object.defineProperty(body, "__initialResponse", {value: res});
    return body;
  }

  protected extractMappedData(res: HttpResponse<Object>): Object {
    let body = new Map();
    if (res.status === 200) {
      if (res.body && res.body instanceof Object) {
        Object.keys(res.body).forEach(key => {
          body.set(key, res.body[key]);
        });
      }
      else if (res.body === null || (res.body && (typeof res.body === "string"))) {
        body = new Map();
      }
    }
    Object.defineProperty(body, "__initialResponse", {value: res});
    return body;
  }

  /*
  //noinspection JSMethodCanBeStatic
  protected handleError(error: HttpErrorResponse): Observable<Object> {
    //TODO: handle errors
    return this.errorHandler.handleError(error);
  }
  */
}
