<div class="sq-results-report-aqua__header breadcrumbs">
  <ux-breadcrumbs [items]="_breadcrumbs"
                  (onClick)="_onBreadcrumbsClick($event)"
  >
  </ux-breadcrumbs>
</div>

<div class="sq-results-report-aqua__section"
     [ngSwitch]="_progressStatus"
>
  <ux-round-dots-loader *ngSwitchCase="'loading'"
                        [visible]="true">
  </ux-round-dots-loader>

  <div class="sq-results-report-aqua__table qualityResultsTable"
       *ngSwitchCase="'loaded'"
  >
    <ux-table-header class="sq-results-report-aqua__table-header"
                     [model]="_tableHeaders.qualityResults"
    >
    </ux-table-header>
    <ux-table #qualityResultsTable
              class="sq-results-report-aqua__table-body"
              [model]="_tableModels.qualityResults"
              [rowHeight]="40"
              [minVerticalSliderSize]="30"
              [mobile]="false"
    >
    </ux-table>
  </div>

  <div class="sq-results-report-aqua__table reasonsTable"
       *ngSwitchCase="'loaded'"
  >
    <ux-table-header class="sq-results-report-aqua__table-header"
                     [model]="_tableHeaders.reasons"
    >
    </ux-table-header>
    <ux-table #reasonsTable
              class="sq-results-report-aqua__table-body"
              [model]="_tableModels.reasons"
              [rowHeight]="40"
              [minVerticalSliderSize]="30"
              [mobile]="false"
    >
    </ux-table>
  </div>

  <div class="sq-results-report-aqua__table valuesTable"
       *ngSwitchCase="'loaded'"
  >
    <ux-table-header class="sq-results-report-aqua__table-header"
                     [model]="_tableHeaders.values"
    >
    </ux-table-header>
    <ux-table #valuesTable
              class="sq-results-report-aqua__table-body"
              [model]="_tableModels.values"
              [rowHeight]="40"
              [minVerticalSliderSize]="30"
              [mobile]="false"
    >
    </ux-table>
  </div>

  <div class="sq-results-report-aqua__table spectrumTable"
       *ngSwitchCase="'loaded'"
  >
    <ux-table-header class="sq-results-report-aqua__table-header"
                     [model]="_tableHeaders.spectrum"
    >
    </ux-table-header>
    <ux-table #spectrumTable
              class="sq-results-report-aqua__table-body"
              [model]="_tableModels.spectrum"
              [rowHeight]="40"
              [minVerticalSliderSize]="30"
              [mobile]="false"
    >
    </ux-table>
  </div>

  <div class="sq-results-report-aqua__section-message"
       *ngSwitchCase="'no_data'"
  >
    No report data loaded
  </div>
</div>
