import {
  Component, IterableDiffers, KeyValueDiffers, QueryList, TemplateRef,
  ViewChild, ViewChildren
} from "@angular/core";
import {AppConfigService, DevicesConfig} from "../../../common/services/app-config.service";
import {UxCardTreeModel} from "../../../../ux-lib/components/card-tree/card-tree.model";
import {DeviceCardModel, DeviceCardModelCopier} from "../model/device-card.model";
import {CardListComponent} from "../../../components/card-list/card-list.component";
import {DeviceEntity} from "../../../common/models/entity/device-entity.model";
import {ReportsService} from "../../reports/reports.service";
import {ModelService} from "../../../common/services/model.service";
import {DeviceLinkedTestListComponent} from "./device-linked-test-list.component";
import {CardDataModel} from "../../../components/card-list/card-data.model";

@Component({
  selector: "sq-devices-card-list",
  templateUrl: "devices-card-list.component.html",
  host: {"[class.sq-devices-card-list]": "true"}
})
export class DevicesCardListComponent extends CardListComponent<DeviceEntity> {

  protected devicesConfig: DevicesConfig = {
    defaultStatistics: 'mos_aqua',
    defaultPeriod: 'hour',
    defaultDuration: 24
  };

  @ViewChild("cardTemplate", { static: true })
  private cardTemplate: TemplateRef<any>;

  @ViewChildren(DeviceLinkedTestListComponent)
  private linkedLists: QueryList<DeviceLinkedTestListComponent>;

  constructor(protected modelService: ModelService,
              protected reportService: ReportsService,
              protected configService: AppConfigService,
              protected iterableDiffers: IterableDiffers,
              protected keyValueDiffers: KeyValueDiffers) {
    super(modelService,
      reportService,
      iterableDiffers,
      keyValueDiffers);

    this.loadDevicesConfig();
  }

  public updateLinkedList(card: DeviceCardModel): void {
    this.linkedLists.forEach((linkedList: DeviceLinkedTestListComponent) => {
      if (linkedList.device.id === card.id) {
        linkedList.refresh();
      }
    })
  }

  protected loadDevicesConfig(): void {
    let devsConfig: DevicesConfig = this.configService.devices();
    if (devsConfig) {
      this.devicesConfig.defaultStatistics = devsConfig.defaultStatistics || 'mos_aqua';
      this.devicesConfig.defaultPeriod = devsConfig.defaultPeriod || 'hour';
      this.devicesConfig.defaultDuration = devsConfig.defaultDuration || 24;
    }
  }

  protected compareCardToEntity(entity: DeviceEntity, card: DeviceCardModel): boolean {
    return (card.originalEntity.id === entity.id);
  }

  protected updateEntityCard(entity: DeviceEntity, card: DeviceCardModel) {
    card.id = entity.id.toString();
    card.parent_id = entity.parent_id.toString();
    card.hasChildren = entity.type === 'folder' ? entity.children_count > 0 : false;
    card.child_count = entity.type === 'folder' ? entity.children_count : 0;
    card.opened = (card.hasChildren ? (card.opened === undefined ? false : card.opened) : false);
    card.name = entity.instance;
    card.number = entity.number;
    card.type = entity.type;
    card.lastTime = entity.last_timestamp;
  }

  protected createCard(device: DeviceEntity, selected?: boolean): DeviceCardModel {
    return <DeviceCardModel> {
      id: device.id.toString(),
      parent_id: device.parent_id.toString(),
      visible: true,
      hasChildren: device.type === 'folder' ? device.children_count > 0 : false,
      opened: false,
      child_count: device.type === 'folder' ? device.children_count : 0,
      children: device.type === 'folder' ? [] : undefined,
      name: device.instance,
      type: device.type,
      number: device.number,
      lastTime: device.last_timestamp,
      originalEntity: device,
      selected: device.type === 'folder' ? undefined : selected,
      selectable: device.type === 'folder' ? false : (selected === undefined ? false : true),
      expanded: false,
      statisticsInProgress: false,
      entityStatistics: [],
      entitySummary: undefined,
      defaultStatistics: this.devicesConfig.defaultStatistics,
      defaultPeriod: this.devicesConfig.defaultPeriod,
      defaultDuration: this.devicesConfig.defaultDuration
    }
  }

  protected copyCard(cardFrom: DeviceCardModel): DeviceCardModel {
    let card: DeviceCardModel = {};
    let copier = new DeviceCardModelCopier();
    copier.copy(cardFrom, card);
    return card;
  }

  protected getDefaultCardModel(): UxCardTreeModel {
    return {
      template: this.cardTemplate,
      children: []
    }
  }

  protected isCardFolder(card: DeviceCardModel) {
    return card.hasChildren || (card.originalEntity && card.originalEntity.type === 'folder');
  }


  public _trackEntityByFn(index: number, item: DeviceEntity): string {
    return item && item.id.toString();
  }
}
