import {NgModule} from "@angular/core";
import {SharedModule} from "../../../shared/shared.module";
import {UxSwitchSelectorFieldComponent} from "./switch-selector-field.component";
import {UxDropdownFieldModule} from "../dropdown/dropdown-field.module";

@NgModule({
    imports: [
      SharedModule,
      UxDropdownFieldModule
    ],
    exports: [UxSwitchSelectorFieldComponent],
    declarations: [UxSwitchSelectorFieldComponent]
})
export class UxSwitchSelectorFieldModule {
}
