import {AfterViewInit, Directive, ElementRef, EventEmitter, Input, NgZone, Output} from "@angular/core";
import {UxDomHelper} from "../../shared/dom/dom-helper";
import {UxPropertyConverter} from "../../common/decorator/ux-property-converter";


export interface UxSlideToggleEvent {
    element: HTMLElement;
    timePassedPercentage?: number;
    elementHeight: number;
    slideToggle: boolean;
}


@Directive({
    selector: "[uxSlideToggle]"
})
export class UxSlideToggleDirective implements AfterViewInit {
    public element: HTMLElement;

    private toggled: boolean = true;
    private toggleStateOnAnimationStart: boolean = true;
    private isAnimating: boolean = false;
    private requestAnimationFrameId: number;
    private direction: string = "up";
    private height: number;
    private currentStyleHeight: number;
    private initOverflowStyle: string | any;
    private initDisplayStyle: string | any;
    private initHeightStyle: string | any;
    private wrapper: HTMLElement;

    @UxPropertyConverter("number", 200)
    @Input()
    public uxSlideToggleDuration: number = 200;

    @Input("uxSlideToggle")
    public set slideToggle(value: boolean) {
        let self = this;

        self.toggled = value;

        if (!self.isAnimating && self.element) {
            self.runAnimation();
        }
    }

    public get slideToggle(): boolean {
        return this.toggled;
    }

    @Output() public onSlideStart = new EventEmitter<UxSlideToggleEvent>();
    @Output() public onSlideEnd = new EventEmitter<UxSlideToggleEvent>();
    @Output() public onSlideTick = new EventEmitter<UxSlideToggleEvent>();

    constructor(private elementRef: ElementRef,
                private zone: NgZone) {
    }

    ngAfterViewInit(): void {
        let self = this,
            elementInitComputedStyle;

        self.element = self.elementRef.nativeElement;

        elementInitComputedStyle = getComputedStyle(self.element);

        self.initOverflowStyle = elementInitComputedStyle.overflow;
        self.initDisplayStyle = elementInitComputedStyle.display;

        if (!self.toggled) {
            self.getInitialHeight();
            self.updateElHeight();
            self.element.style.display = "none";
            self.setInitialHeight();
        }
    }

    private runAnimation(): void {
        let self = this;

        self.direction = self.toggled ? "down" : "up";

        if ((UxDomHelper.checkIfIE() || UxDomHelper.checkIfEdge()) && self.direction === "down") {
            self.createWrapper();
        }

        self.element.style.display = self.initDisplayStyle;
        self.getInitialHeight();
        self.updateElHeight();
        self.setInitialHeight();

        self.isAnimating = true;

        self.toggleStateOnAnimationStart = self.toggled;

        self.onSlideStart.emit({
            element: self.element,
            elementHeight: self.element.offsetHeight,
            slideToggle: self.toggled
        });

        self.zone.runOutsideAngular(() => {
            self.element.style.overflow = "hidden";
            self.animate();
        });
    }

    private animate(): void {
        let self = this,
            start;

        self.requestAnimationFrameId = requestAnimationFrame(function animate(time): void {

            if (!start) {
                start = time;
            }

            let timePassed = time - start;

            if (timePassed > self.uxSlideToggleDuration) {
                timePassed = self.uxSlideToggleDuration;
            }

            self.tick(timePassed);

            if (timePassed < self.uxSlideToggleDuration &&
                self.direction === "up" && self.currentStyleHeight > 0 ||
                self.direction === "down" && self.currentStyleHeight < self.height
            ) {
                self.requestAnimationFrameId = requestAnimationFrame(animate);
            } else {
                self.onAnimationEnd();
            }
        });
    }

    private tick(timePassed: number): void {
        let self = this,
            timePassedPercentage: number = Math.ceil(Math.abs(timePassed) / self.uxSlideToggleDuration * 100),
            currentTimePassedPercentage = self.direction === "up" ? 100 - timePassedPercentage : timePassedPercentage,
            elementHeight = self.height * currentTimePassedPercentage / 100;

        self.currentStyleHeight = elementHeight;
        self.element.style.height = self.currentStyleHeight + "px";

        if (self.wrapper) {
            self.removeWrapper();
        }

        self.onSlideTick.emit({
            element: self.element,
            elementHeight,
            timePassedPercentage,
            slideToggle: self.toggled
        });
    }

    private onAnimationEnd(): void {
        let self = this;

        window.cancelAnimationFrame(self.requestAnimationFrameId);

        if (self.toggleStateOnAnimationStart !== self.toggled) {
            requestAnimationFrame(self.runAnimation.bind(self));
        } else {

            if (self.toggled) {
                self.element.style.display = self.initDisplayStyle;
            } else {
                self.element.style.display = "none";
            }

            self.element.style.overflow = self.initOverflowStyle;

            self.setInitialHeight();

            self.isAnimating = false;
        }

        self.onSlideEnd.emit({
            element: self.element,
            elementHeight: self.element.offsetHeight,
            slideToggle: self.toggled
        });
    }

    private updateElHeight(): void {
        let self = this,
            elementInitComputedStyle = getComputedStyle(self.element),
            initVisibilityParametersMap = {},
            visibilityParametersMap = {
                height: "auto"
            };

        for (let key in visibilityParametersMap) {
            initVisibilityParametersMap[key] = elementInitComputedStyle[key];
            self.element.style[key] = visibilityParametersMap[key];
        }

        self.height = self.element.offsetHeight;

        for (let key in initVisibilityParametersMap) {
            self.element.style[key] = initVisibilityParametersMap[key];
        }
    }

    private setInitialHeight(): void {
        let self = this,
            stylesString = self.element.getAttribute("style");
            stylesString = stylesString.replace( /height:.+?;/i, self.initHeightStyle);

        self.element.setAttribute("style", stylesString);
    }

    private getInitialHeight(): void {
        let self = this;

        if (self.isAnimating) {
            return;
        }

        let styles = self.element.getAttribute("style");

        if (styles && styles.indexOf("height") >= 0) {
            let result = styles.match( /height:(.+?;)/i );
            self.initHeightStyle = result && result[1] && "height:" + result[1].trim();
        } else {
            self.initHeightStyle = "";
        }
    }

    private createWrapper(): void {
        let self = this;

        let wrapper = document.createElement("div");
        wrapper.style.overflow = "hidden";
        wrapper.style.height = "0";

        self.element.parentElement.insertBefore(wrapper, self.element);
        wrapper.appendChild(self.element);

        self.wrapper = wrapper;
    }

    private removeWrapper(): void {
        let self = this;
        self.wrapper.parentElement.insertBefore(self.element, self.wrapper);
        UxDomHelper.removeChildFromParent(self.wrapper);
        self.wrapper = null;
    }
}
