<div class="sq-results-list-advanced-table">
  <ux-table-header class="sq-results-list-advanced-table__header"
                   [model]="_tableHeaderModel"
                   [tableHeaderControls]="resultsTableHeaderControls"
  >
    <ng-template #resultsTableHeaderControls>
      <div class="sq-results-list-advanced-actions">
        <div class="sq-results-list-advanced-actions__item">
          <div class="sq-results-list-advanced-actions__item-button _add">
            <ux-button *ngIf="headerAddCaption"
                       [text]="headerAddCaption"
                       [icon]="'plus-black'"
                       [type]="'button'"
                       [kind]="'default'"
                       [disabled]="false"
                       [locked]="false"
                       (tap)="_onAddClicked()"
            >
            </ux-button>
          </div>
          <div class="sq-results-list-advanced-actions__item-button _remove">
            <ux-button *ngIf="headerRemoveCaption"
                       [text]="headerRemoveCaption"
                       [type]="'button'"
                       [kind]="'default'"
                       [disabled]="resultsAdvancedTable?.tableData?.checkedRows.length == 0"
                       [locked]="false"
                       (tap)="_onRemoveClicked()"
            >
            </ux-button>
          </div>
        </div>
        <div class="sq-results-list-advanced-actions__item"
             *ngIf="enableSearch"
        >
          <div class="sq-results-list-advanced-actions__item-search">
            <ux-search-field ([value])="_searchString"
                             (valueChange)="_onSearchValueChange($event)"
                             [placeholder]="'Search'"
                             [inputName]="'testSearch'"
                             [disabled]="false"
                             [fillParentWidth]="false"
                             [searchMinSymbols]="0"
                             [searchDelay]="500"
            >
            </ux-search-field>
          </div>
        </div>
      </div>
    </ng-template>
  </ux-table-header>
  <ux-table #resultsAdvancedTable
            [model]="_model"
            [rowHeight]="40"
            [minVerticalSliderSize]="30"
            [mobile]="false"
            (onLinkClick)="_onTableLinkClicked($event)"
            (onRowsChecked)="_onTableRowsChecked($event)"
            [ngClass]="{_fullView: fullView}"
            [dynamicSortEnabled]="true"
            (onDynamicSortClick)="_onDynamicSortClick($event)"
  >
  </ux-table>
  <ux-paging *ngIf="pagingTotalCount > 0"
             [totalCount]="pagingTotalCount"
             [pageSize]="paging.pageSize"
             [currentPage]="paging.currentPage"
             [pageSizeOptions]="paging.pageSizeOptions"
             (onPageChange)="paging.currentPage=$event.page; onTablePageChange.emit($event)"
             (onPageSizeChange)="paging.pageSize=$event.items; onTablePageSizeChange.emit($event)"
  >
  </ux-paging>

  <div class="sq-results-list-advanced-table-footer">
    <div class="sq-results-list-advanced-table-footer-item _cancel"
         *ngIf="footerCancelCaption"
    >
      <ux-button [text]="footerCancelCaption"
                 [type]="'button'"
                 [kind]="'default'"
                 [locked]="false"
                 (tap)="_onCancelClicked()"
      >
      </ux-button>
    </div>
    <div class="sq-results-list-advanced-table-footer-item _ok"
         *ngIf="footerActionCaption"
    >
      <ux-button [text]="footerActionCaption"
                 [type]="'button'"
                 [kind]="'default'"
                 [disabled]="resultsAdvancedTable?.tableData?.checkedRows.length == 0"
                 [locked]="false"
                 (tap)="_onOkClicked()"
      >
      </ux-button>
    </div>
  </div>
</div>
<ux-confirm-dialog #resultsAdvancedConfirmDialog
                   [visible]="false"
                   [attachPosition]="'view center'"
>
  {{_confirmationText}}
</ux-confirm-dialog>

