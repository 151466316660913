/**
 * @author makuo817
 * @since 01.01.1970 00:00
 */
import {NgModule} from "@angular/core";
import {SharedModule} from "../../shared/shared.module";
import {UxAttachedFileComponent} from "./attached-file.component";

@NgModule({
    declarations: [UxAttachedFileComponent],
    exports: [UxAttachedFileComponent],
    imports: [
        SharedModule
    ]
})
export class UxAttachedFileModule {
}
