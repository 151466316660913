import {Injectable} from "@angular/core";
import {
  ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot,
  UrlTree
} from "@angular/router";
import {Observable, Subscriber, Subscription} from "rxjs";
import {LoginService} from "./modules/login/login.service";

@Injectable()
export class SevanaQualtestAuthGuard implements CanActivate, CanActivateChild {

  constructor(protected router: Router,
              protected loginService: LoginService) {

  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.doCanActivate(route, state);
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.doCanActivate(childRoute, state);
  }

  private doCanActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return Observable.create((subscriber: Subscriber<boolean | UrlTree>) => {
      let isAuthenticatedSubscription: Subscription;

      isAuthenticatedSubscription = this.loginService
        .isAuthenticated$()
        .subscribe((isAuthorized: boolean) => {
          if (isAuthorized) {
            subscriber.next(true);
            subscriber.complete();
          }
          else {
            subscriber.next(this.router.createUrlTree(["/login"]));
            subscriber.complete();
          }
        });

      return () => {
        isAuthenticatedSubscription && isAuthenticatedSubscription.unsubscribe();
      };
    });
  }
}
