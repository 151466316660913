import {Subscription} from "rxjs";
import {DeviceEntity} from "../../../common/models/entity/device-entity.model";
import {Progress} from "../../../common/models/progress.model";
import {TestEntity} from "../../../common/models/entity/test-entity.model";
import {CardDataModel, CardDataModelCopier} from "../../../components/card-list/card-data.model";
import {UxCardTreeNodeModelCopier} from "../../../../ux-lib/components/card-tree/card-tree.model";

export interface DeviceCardModel extends CardDataModel<DeviceEntity> {
  type?: string;
  number?: string;
  lastTime?: number;

  linkedTestsSubscription$?: Subscription;
  unlinkTestsSubscription$?: Subscription;
  linkedTests?: TestEntity[];
  linkedTestsLoading?: Progress;
}


export class DeviceCardModelCopier extends CardDataModelCopier<DeviceEntity> {

  public copy(from: DeviceCardModel, to: DeviceCardModel) {
    super.copy(from, to);

    to.type = from.type;
    to.number = from.number;
    to.lastTime = from.lastTime;
    to.linkedTestsSubscription$ = from.linkedTestsSubscription$;
    to.unlinkTestsSubscription$ = from.unlinkTestsSubscription$;
    to.linkedTests = from.linkedTests;
    to.linkedTestsLoading = from.linkedTestsLoading;
  }
}
