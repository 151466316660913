import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, Output} from "@angular/core";
import {TestResultEntity} from "../../../common/models/entity/test-result-entity.model";
import {AQuAValueItem, ReportAquaModel} from "../../../common/models/entity/report-aqua.model";
import {CssService} from "../../../common/services/css.service";
import {ChartModel} from "../model/chart.model";
import {HighChartModel} from "../../../../ux-lib/components/charts/highchart/model/highchart.model";
import {HighchartService} from "../charts-services/highchart.service";
import * as csvtojson from 'csvtojson';
import {PvqaReportModel} from "../../../common/models/entity/report-pvqa.model";
import {PvqaTableModel} from "../../../common/models/entity/pvqa-table.model";
import {forkJoin, Subject, Subscription} from "rxjs";
import {AudioEntity} from "../../../common/models/entity/audio-entity.model";
import {Progress} from "../../../common/models/progress.model";
import {takeUntil, tap} from "rxjs/operators";
import {AudioService, DownloadFileResult} from "../../audio/audio.service";
import {ApiPath} from "../../../common/services/api.path";

class ControlProgress {
  subscription$: Subscription;
  progress: Progress = 'initial';
}

@Component({
  selector: "sq-testcase-chart",
  templateUrl: "testcase-chart.component.html",
  host: {"[class.sq-testcase-chart]": "true"}
})
export class TestcaseChartComponent implements OnDestroy {

  private _result: TestResultEntity;
  _aquaReport: ReportAquaModel<number>;
  _aquaReportVisual: ReportAquaModel<string>;
  _pvqaReport: PvqaReportModel<number>;
  _pvqaReportVisual: PvqaReportModel<string>;
  _spectrumChart: ChartModel<HighChartModel>;
  _pvqaStatChart: ChartModel<HighChartModel>;

  _pvqaTableData: PvqaTableModel;

  private stopSubscription$ = new Subject<boolean>();

  //private audioTag: HTMLMediaElement = null;
  private audioSupported: boolean = undefined;
  audioEntity: AudioEntity;
  audioInfoProgress: ControlProgress = new ControlProgress();
  audioPlayProgress: ControlProgress = new ControlProgress();
  audioDownloadProgress: ControlProgress = new ControlProgress();

  @Input()
  public audioTag: HTMLMediaElement;

  @Input()
  public playAudioID: number|string;

  @Input()
  public set result(value: TestResultEntity) {
    this._result = value;
    this.loadAudioData();
    this.parseAquaReport();
    this.parsePvqaReport();
  }

  @Output()
  public onPlayAudioID: EventEmitter<number|string> = new EventEmitter<number|string>();

  public get result(): TestResultEntity {
    return this._result;
  }

  constructor(protected cssStyle: CssService,
              private chartService: HighchartService,
              private audioService: AudioService) {
  }


  ngOnDestroy(): void {
    this.stopSubscription$.next(true);
    this.stopSubscription$.complete();
  }

  private loadAudioData() {
    let queryParams = [
      {
        key: ApiPath.AUDIO_ID,
        value: this.result.audio_id
      }
    ];

    this.audioInfoProgress.progress = 'loading';
    this.audioInfoProgress.subscription$ && this.audioInfoProgress.subscription$.unsubscribe();
    this.audioInfoProgress.subscription$ = this.audioService
        .listAudio(queryParams)
        .pipe(
            takeUntil(this.stopSubscription$),
            tap((data: AudioEntity[]) => {

              if (data.length > 0) {
                this.audioInfoProgress.progress = 'loaded';
                this.audioEntity = data[0];
              }
              else {
                this.audioInfoProgress.progress = 'no_data';
              }
            })
        )
        .subscribe();
  }

  _playAudio() {
    if (this.result.audio_id > 0) {
      if (this.playAudioID > 0) { // played, need to stop
        this.audioTag.pause();
        this.audioTag.currentTime = 0;
        this.playAudioID = undefined;
        this.onPlayAudioID.emit(this.playAudioID);
      }
      else { // not played, need to play

        if (this.result['audioBlob'] === undefined) {

          this.audioPlayProgress.progress = 'loading';
          this.audioPlayProgress.subscription$ && this.audioPlayProgress.subscription$.unsubscribe();
          this.audioPlayProgress.subscription$ = this.audioService
              .downloadAudio(this.result.audio_id)
              .pipe(
                  takeUntil(this.stopSubscription$),
                  tap((data: DownloadFileResult) => {
                    if (data.blob.size > 0) {
                      this.audioPlayProgress.progress = 'loaded';

                      this.result['audioBlob'] = data.blob;
                      this._playData();
                    }
                    else {
                      this.audioPlayProgress.progress = 'no_data';
                    }
                  })
              )
              .subscribe();
        }
        else {
          this._playData();
        }
      }
    }
  }

  private _playData() {
    if (this.result['audioBlob'] !== undefined) {

      this.playAudioID = this.result.audio_id;
      this.onPlayAudioID.emit(this.playAudioID);

      let playUrl = window.URL.createObjectURL(this.result['audioBlob']);
      this.audioTag.src = playUrl;
      this.audioTag.play();
    }
  }

  _downloadAudio() {
    if (this.result.audio_id > 0) {
      if (this.result['audioBlob'] === undefined) {

        this.audioDownloadProgress.progress = 'loading';
        this.audioDownloadProgress.subscription$ && this.audioDownloadProgress.subscription$.unsubscribe();
        this.audioDownloadProgress.subscription$ = this.audioService
            .downloadAudio(this.result.audio_id)
            .pipe(
                takeUntil(this.stopSubscription$),
                tap((data: DownloadFileResult) => {
                  if (data.blob.size > 0) {
                    this.audioDownloadProgress.progress = 'loaded';

                    this.result['audioBlob'] = data.blob;
                    this._downloadData();
                  }
                  else {
                    this.audioDownloadProgress.progress = 'no_data';
                  }
                })
            )
            .subscribe();
      }
      else {
        this._downloadData()
      }
    }
  }

  private _downloadData() {
    if (this.result['audioBlob'] !== undefined) {

      let downloadURL = window.URL.createObjectURL(this.result['audioBlob']);
      let link = document.createElement('a');
      link.href = downloadURL;
      link.download = this.audioEntity.name;
      link.click();
    }
  }

  private parseAquaReport() {
    if (this._result.report_aqua && this._result.report_aqua.length > 0) {
      let parsedData = JSON.parse(this._result.report_aqua);
      if (parsedData && parsedData["AQuAReport"]) {
        this._aquaReport = <ReportAquaModel<number>>parsedData;
        this._aquaReport = this.checkForUp(this._aquaReport);
        this._aquaReportVisual = this.convertAquaReport(this._aquaReport);
        this._aquaReportVisual.AQuAReport.QualityResults.mosStyle = this.cssStyle.getLevel(this._aquaReport.AQuAReport.QualityResults.MOS);

        this._spectrumChart = this.chartService.generateSpectrumChart(this._result.id, this._result.endtime, this._aquaReport);
      }
    }
    else {
      this._aquaReport = undefined;
    }
  }

  private parsePvqaReport() {
    if (this._result.report_pvqa && this._result.report_pvqa.length > 0) {
      this._pvqaReport = {
        mos_pvqa: this._result.mos_pvqa,
        endtime: this._result.endtime
      }
      this._pvqaReportVisual = this.convertPvqaReport(this._pvqaReport);
      this._pvqaReportVisual.mosStyle = this.cssStyle.getLevel(this._pvqaReport.mos_pvqa);
      this._pvqaReportVisual.endtime = this._result.endtime;

      if (this._result.report_pvqa && this._result.report_pvqa.length > 0) {
        try {
          let parsedData = JSON.parse(this._result.report_pvqa);
          let jsonData = this.array2ObjArray(parsedData);
          if (Array.isArray(jsonData) && jsonData.length > 0) {
            this._pvqaStatChart = this.chartService.generatePvqaStatisticsChart(this._result.id, this._result.endtime, jsonData);
            this._pvqaTableData = this.jsonToPvqaTableModel(jsonData);
          }
        }
        catch (error) {
          csvtojson({
            delimiter: ";"
          })
              .fromString(this._result.report_pvqa)
              .then((jsonData) => {
                if (Array.isArray(jsonData) && jsonData.length > 0) {
                  this._pvqaStatChart = this.chartService.generatePvqaStatisticsChart(this._result.id, this._result.endtime, jsonData);
                  this._pvqaTableData = this.jsonToPvqaTableModel(jsonData);
                }
              });
        }
      } else {
        this._pvqaReport = undefined;
      }
    }
  }

  private array2ObjArray(data: any[]) {
    let arrOut = [];
    if (data.length > 0) {
      let head: any[] = data[0];
      for (let i = 1; i < data.length; i++) {
        let row: any[] = data[i];
        let obj = {};
        for (let j = 0; j < head.length; j++) {
          obj[head[j]] = row[j];
        }
        arrOut.push(obj);
      }
    }
    return arrOut;
  }

  private jsonToPvqaTableModel(jsonData: any[]): PvqaTableModel {
    let pvqaTableData: PvqaTableModel = new PvqaTableModel();
    if (jsonData.length > 0) {
      let headPrepared: boolean = false;

      jsonData.forEach(jsonRow => {
        if (!headPrepared) {
          Object.keys(jsonRow).forEach(key => {
            if (!key.startsWith('field')) {
              pvqaTableData.headRow.push(key);
            }
            else {
              pvqaTableData.headRow.push("Result");
            }
          });
          headPrepared = true;
        }

        let row: Map<string, string> = new Map<string, string>();
        Object.keys(jsonRow).forEach(key => {

          if (!key.startsWith('field')) {
            row.set(key, jsonRow[key]);
          }
          else {
            row.set("Result", jsonRow[key]);
          }
        });
        pvqaTableData.dataRows.push(row);
      })
    }
    return pvqaTableData;
  }

  private checkForUp(original: ReportAquaModel<number>) {
    if (original && original.AQuAReport && original.AQuAReport.ValuesArray) {
      for (let key in original.AQuAReport.ValuesArray) {
        let value: AQuAValueItem<number> = original.AQuAReport.ValuesArray[key];
        if (typeof value.DegradedValue === 'number') {
          if (value.DegradedValue > value.SourceValue) {
            value.up = true;
          }
          else if (value.DegradedValue < value.SourceValue) {
            value.up = false;
          }
        }
      }
    }
    return original;
  }

  private convertAquaReport(original: ReportAquaModel<number>): ReportAquaModel<string> {
    let converted: ReportAquaModel<string> = this.convertField(original);
    return converted;
  }

  private convertPvqaReport(original: PvqaReportModel<number>): PvqaReportModel<string> {
    let converted: PvqaReportModel<string> = this.convertField(original);
    return converted;
  }

  private convertField(value: any): any {
    if (value === undefined || value === null) {
      return value;
    }
    else if (typeof value === 'string') {
      return value;
    }
    else if (typeof value === 'number') {
      if (Number.isInteger(value)) {
        return value.toFixed(0);
      }
      else {
        return value.toFixed(2);
      }
    }
    else if (Array.isArray(value)) {
      let convertedValue = [];
      for (let item of value) {
        convertedValue.push(this.convertField(item));
      }
      return convertedValue;
    }
    else if (typeof value === 'object') {
      let convertedValue = {};
      for (let key in value) {
        convertedValue[key] = this.convertField(value[key]);
      }
      return convertedValue;
    }

    return value;
  }
}
