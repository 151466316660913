import {NgModule} from "@angular/core";
import {SharedModule} from "../../shared/shared.module";
import {UxEditableDirective} from "./editable.directive";
import {UxTextFieldComponent} from "./../fields/text/text-field.component";

@NgModule({
    imports: [SharedModule],
    exports: [UxEditableDirective],
    declarations: [UxEditableDirective],
    entryComponents: [UxTextFieldComponent]
})
export class UxEditableModule {
}

