/**
 * To register custom converter:
 * 1) Add converter name to <b>UxPropertyConverterName</b> type
 * 2) Register converter instance of <b>PropertyConverter</b> in <b>PropertyConverterStorage</b>, see PropertyConverterStorage.register in this file
 * 3) Usage:
 *
 * class Test {
 *     @UxPropertyConverter("boolean")
 *     value:any = "false"; //will be converted to false typeof boolean
 * }
 * let test = new Test();
 * test.value = "true";
 * console.log(`value = ${test.value}`); //should be true typeof boolean
 *
 *
 */
import {UxPropertyDecoratorBuilder} from "./ux-property.decorator";

/**
 * Decorator to set property handler.
 * beforeSet - will be triggered any time before set value in the property
 * afterSet - will be triggered any time after set value in the property
 * beforeChange - will be triggered before set value in the property, if it is not equal to the old value
 * afterChange - will be triggered after set value in the property, if it is not equal to the old value
 *
 * Usage:
 *
 * class Test {
 *      @UxPropertyHandler({
 *          beforeChange: function(newValue:number) {
 *              let accepted = newValue !== 5;
 *              console.log(`Try value change to=${newValue}, accepted=${accepted}`);
 *              return accepted;
 *          }
 *      })
 *      value:any = 0; //correct value
 * }
 *
 * let test = new Test();
 * test.value = 5; //handler works
 * test.value = 5; //handler will not works
 * test.value = 3; //handler works
 *
 *
 * @param handlers
 * @returns {{(any, string, PropertyDescriptor): void}}
 * @constructor
 */
export function UxPropertyHandler<T>(handlers: {
    beforeSet?: {(newValue: T, oldValue: T): boolean | void},
    afterSet?: {(newValue: T, oldValue: T): void},
    beforeChange?: {(newValue: T, oldValue: T): boolean | void},
    afterChange?: {(newValue: T, oldValue: T): void}
}) {

    let builder = Object.create(UxPropertyDecoratorBuilder.prototype);
    builder.constructor = UxPropertyDecoratorBuilder;

    return builder
        .setBeforeValueSet(function (newValue: T, oldValue: T, className: string, propertyName: string, target: any) {
            if (handlers && handlers.beforeSet) {
                let isAccepted = handlers.beforeSet.apply(this, [newValue, oldValue]);
                newValue = isAccepted === undefined || isAccepted === true ? newValue : oldValue;
            }
            return newValue;
        })
        .setAfterValueSet(function (newValue: T, oldValue: T, className: string, propertyName: string, target: any) {
            if (handlers && handlers.afterSet) {
                handlers.afterSet.apply(this, [newValue, oldValue]);
            }
            return newValue;
        })
        .setBeforeValueChange(function (newValue: T, oldValue: T, className: string, propertyName: string, target: any) {
            if (handlers && handlers.beforeChange) {
                let isAccepted = handlers.beforeChange.apply(this, [newValue, oldValue]);
                newValue = isAccepted === undefined || isAccepted === true ? newValue : oldValue;
            }
            return newValue;
        })
        .setAfterValueChange(function (newValue: T, oldValue: T, className: string, propertyName: string, target: any) {
            if (handlers && handlers.afterChange) {
                handlers.afterChange.apply(this, [newValue, oldValue]);
            }
            return newValue;
        })
        // .setDebug(true)
        .setDecoratorName("UxPropertyHandler")
        .getDecorator();
}
