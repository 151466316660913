import {NgModule} from "@angular/core";
import {SharedModule} from "../../shared/shared.module";
import {UxOverlayComponent} from "./overlay.component";
import {UxFadeToggleModule} from "../fade-toggle/fade-toggle.module";

@NgModule({
    imports: [
        SharedModule,
        UxFadeToggleModule
    ],
    exports: [UxOverlayComponent],
    declarations: [UxOverlayComponent]
})
export class UxOverlayModule {
}