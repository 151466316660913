import {Injectable} from "@angular/core";
import {HttpErrorResponse} from "@angular/common/http";
import {Observable, of, throwError} from "rxjs";
import {NotificationService} from "./notification.service";
import {NotificationItem} from "../models/notification-item";

export type HandleError =
  <T> (operation?: string, url?: string, result?: T) => (error: HttpErrorResponse) => Observable<T>;

@Injectable()
export class ErrorHandlerService {

  constructor(private notificationService: NotificationService) {
  }

  /** Create curried handleError function that already knows the service name */
  createHandleError = (serviceName = '') =>
      <T>(operation = 'operation', url = 'url', result = {} as T) => this.handleError(serviceName, operation, url, result);

  /**
   * Returns a function that handles Http operation failures.
   * This error handler lets the app continue to run as if no error occurred.
   * @param serviceName = name of the data service that attempted the operation
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  handleError<T> (serviceName = '', operation = 'operation', url = 'url', result = {} as T) {

    return (error: HttpErrorResponse): Observable<T> => {
      console.error(error);

      this.notificationService.pushNotification(this.getNotification(operation, url, error));

      return of(result);
    };
  }

  private getNotification(operation: string, url: string, error: HttpErrorResponse): NotificationItem {

    return {
      type: "error",
      caption: "HTTP error",
      content: `Fail to ${operation} '${url}': ${error.error}: ${error.message}`
    };
  }
}
