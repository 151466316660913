import {NgModule} from "@angular/core";
import {SharedModule} from "../../shared/shared.module";
import {UxTableComponent} from "./table.component";
import {UxScrollModule} from "../scroll/scroll.module";
import {UxCheckboxFieldModule} from "../fields/checkbox/checkbox-field.module";
import {UxTableHeaderComponent} from "./header/table-header.component";
import {UxTableRowComponent} from "./row/table-row.component";

@NgModule({
    imports: [SharedModule, UxCheckboxFieldModule, UxScrollModule],
    exports: [UxTableComponent, UxTableHeaderComponent],
    declarations: [UxTableComponent, UxTableHeaderComponent, UxTableRowComponent]
})
export class UxTableModule {}
