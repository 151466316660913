import {NgModule} from "@angular/core";
import {OverlayPopupComponent} from "./overlay-popup.component";
import {UxOverlayModule} from "../../../ux-lib/components/overlay/overlay.module";
import {CommonModule} from "@angular/common";
import {UxButtonModule} from "../../../ux-lib/components/button/button.module";

@NgModule({
  imports: [
    CommonModule,
    UxOverlayModule,
    UxButtonModule
  ],
  declarations: [
    OverlayPopupComponent
  ],
  exports: [
    OverlayPopupComponent
  ]
})
export class OverlayPopupModule {
}
