import {
  AfterContentInit,
  AfterViewInit,
  ChangeDetectorRef,
  Component, ElementRef, QueryList,
  Renderer2,
  TemplateRef,
  ViewChild, ViewChildren
} from "@angular/core";
import {
  UxRangeDateFieldModel,
  UxRangeDateFieldPlaceholderModel, UxRangeDateFieldViewEventModel
} from "../../../../ux-lib/components/fields/date/range/range-date-field.model";
import {DevicesService} from "../../devices/devices.service";
import {forkJoin, Subject, Subscription} from "rxjs";
import {AppConfigService, ReportConfig} from "../../../common/services/app-config.service";
import {TestsService} from "../../tests/tests.service";
import {ActivatedRoute, ParamMap, Router} from "@angular/router";
import {StatisticPeriod, StatisticPeriodType} from "../../../common/models/statistic-period.type";
import * as moment from "moment";
import {Progress} from "../../../common/models/progress.model";
import {ReportRequestParametersModel} from "../model/report-request-parameters.model";
import {ReportsService} from "../reports.service";
import {takeUntil, tap} from "rxjs/operators";
import {
  StatisticsDataEntry, StatisticsDataStyledVisualEntry,
  StatisticsEntity,
  StatisticsEntityBaseWithLastValueTimestamp
} from "../../../common/models/entity/statistics-entity.model";
import {ChartEntity, ChartType, PhoneCharts} from "../model/chart.model";
import {HighChartModel} from "../../../../ux-lib/components/charts/highchart/model/highchart.model";
import {StatisticDataType} from "../../../common/models/statistic-data.type";
import {HighchartService} from "../charts-services/highchart.service";
import {ResultsService} from "../../results/results.service";
import {TestResultEntity} from "../../../common/models/entity/test-result-entity.model";
import {ConfigComponent} from "../../../components/base/config.component";
import {UxInlineNgStyles, UxTable, UxTableColumn, UxTableRow} from "../../../../ux-lib/components/table/table.model";
import {UxPageChangeEvent} from "../../../../ux-lib/components/paging/paging.component";
import {DeviceEntity} from "../../../common/models/entity/device-entity.model";
import {TestEntity} from "../../../common/models/entity/test-entity.model";
import {ModelService} from "../../../common/services/model.service";
import {TestcaseChartComponent} from "./testcase-chart.component";
import {AudioEntity} from "../../../common/models/entity/audio-entity.model";
import {ApiPath} from "../../../common/services/api.path";
import {AudioService, DownloadFileResult} from "../../audio/audio.service";
import {UxTableComponent} from "../../../../ux-lib/components/table/table.component";

@Component({
  selector: "sq-report-view",
  templateUrl: "report-view.component.html",
  host: {"[class.sq-report-view]": "true"}
})
export class ReportViewComponent extends ConfigComponent implements AfterViewInit {
  _reportProgress: Progress = 'no_data';
  _resultsProgress: Progress = 'no_data';
  _resultProgress: Progress = 'no_data';

  private _reportStatistics: StatisticsEntity[]
  private _results: TestResultEntity[] = [];
  private _devicesMap: Map<number|string, DeviceEntity> = new Map<number|string, DeviceEntity>();
  private _testsMap: Map<number|string, TestEntity> = new Map<number|string, TestEntity>();
  _selectedTestResult: TestResultEntity;

  _summary: StatisticsEntityBaseWithLastValueTimestamp<StatisticsDataEntry>;
  _summaryVisual: StatisticsEntityBaseWithLastValueTimestamp<StatisticsDataStyledVisualEntry>;
  _pvqaCharts: PhoneCharts<HighChartModel>[];
  _aquaCharts: PhoneCharts<HighChartModel>[];
  _rFactorCharts: PhoneCharts<HighChartModel>[];
  _percentsCharts: PhoneCharts<HighChartModel>[];
  _mosNetworkCharts: PhoneCharts<HighChartModel>[];

  _reportsSettings: ReportConfig = {};

  _selectedPeriod: StatisticPeriodType;
  _selectedRange: UxRangeDateFieldModel = {
    from: new Date(),
    to: new Date()
  };
  private _selectedViewRange: UxRangeDateFieldViewEventModel;
  _rangePlaceHolder: UxRangeDateFieldPlaceholderModel = {
    from: "Start:",
    to: "Finish:"
  };
  _endAvailableDate = new Date();
  _rangeError: string = "";
  private _reportAllowed: boolean = false;

  private _selectedDevices: string[] = [];
  private _selectedTests: string[] = [];

  private _probeID: string;
  _resultsPagingVisible: boolean = true;
  _resultsTableDataModel: UxTable = {
    header: {
      rows: [
        {
          styleClass: "_header",
          columns: [
            {
              id: "Device",
              value: "Device",
              styleClass: "_header",
              contentModel: {
                "id": "name"
              }
            },
            {
              id: "Test",
              value: "Test",
              styleClass: "_header",
              contentModel: {
                "id": "name"
              }
            },
            {
              id: "Target",
              value: "Target",
              styleClass: "_header",
              contentModel: {
                "id": "name"
              },
            },
            {
              id: "Time",
              value: "Time",
              styleClass: "_header",
              contentModel: {
                "id": "name"
              },
            },
            {
              id: "Duration",
              value: "Duration",
              styleClass: "_header",
              contentModel: {
                "id": "name"
              }
            },
            {
              id: "AQUA",
              value: "AQUA",
              styleClass: "_header",
              contentModel: {
                "id": "name"
              }
            },
            {
              id: "PVQA",
              value: "PVQA",
              styleClass: "_header",
              contentModel: {
                "id": "name"
              }
            },
            {
              id: "MOS Network",
              value: "MOS Network",
              styleClass: "_header",
              contentModel: {
                "id": "name"
              }
            },
            {
              id: "Sevana R-factor",
              value: "R-Factor",
              styleClass: "_header",
              contentModel: {
                "id": "name"
              }
            },
            {
              id: "Sevana Percentage",
              value: "Sevana Percentage",
              styleClass: "_header",
              contentModel: {
                "id": "name"
              }
            },
            {
              id: "functions",
              value: "",
              styleClass: "_header"
            }
          ]
        }
      ]
    },
    body: {rows: []}
  };

  private stopSubscription$ = new Subject<boolean>();
  private forkSubscription$: Subscription;
  private statisticsSubscription$?: Subscription;
  private resultsSubscription$?: Subscription;
  private resultSubscription$?: Subscription;

  @ViewChild("tableEmptyContent", { static: true })
  tableEmptyContent: TemplateRef<any>;

  @ViewChild("timeColumn", { static: true })
  timeColumn: TemplateRef<any>;

  @ViewChild("durationColumn", { static: true })
  durationColumn: TemplateRef<any>;

  @ViewChild("functionsColumn", { static: true })
  functionsColumn: TemplateRef<any>;

  @ViewChild("testCaseChart")
  testCaseChart: TestcaseChartComponent;

  private testResultsTable: UxTableComponent;
  @ViewChild("testResultsTable") set content(content: UxTableComponent) {
    if (content) { // initially setter gets called with undefined
      this.testResultsTable = content;
      this.configureTestResultsTable();
    }
  }

  audioTag: HTMLMediaElement = null;
  private audioSupported: boolean = undefined;
  playAudioID: number|string;
  private audioMap: Map<number|string, AudioEntity> = new Map();

  constructor(private reportsService: ReportsService,
              private resultsService: ResultsService,
              private chartService: HighchartService,
              private devicesService: DevicesService,
              private testsService: TestsService,
              private modelService: ModelService,
              private audioService: AudioService,
              private cdr: ChangeDetectorRef,
              private route: ActivatedRoute,
              private router: Router,
              protected configService: AppConfigService,
              private _renderer: Renderer2) {
    super(configService);
  }

  public initComponent() {
    super.initComponent();
    this._resultsTableDataModel.emptyTableContent = this.tableEmptyContent;

    if (null === this.audioTag && this.audioSupported === undefined) {
      this.audioTag = document.createElement('audio');
      this.audioSupported = !!(this.audioTag.canPlayType);

      if (this.audioSupported) {
        document.getElementById("sq-report-view__wrapper_id").appendChild(this.audioTag);

        let self = this;
        this.audioTag.addEventListener('ended', (event) => {
          self.playAudioID = undefined;
//          self.testCaseChart.setPlayAudioID(self.playAudioID);
        });
      }
    }
  }

  private configureTestResultsTable() {
    let fixedWidth = this.calculateFixedWidth();

    this._resultsTableDataModel.header.rows[0].columns.forEach((col: UxTableColumn) => {
      this.updateColumn(col, fixedWidth);
    });
    this._resultsTableDataModel.body.rows.forEach((row: UxTableRow) => {
      row.columns.forEach((col: UxTableColumn) => {
        this.updateColumn(col, fixedWidth);
      });
    })

    this.testResultsTable.updateTableData();
    this.cdr.detectChanges();

    //this.testResultsTable.nativeElement;
  }

  private calculateFixedWidth(): number {
    let fixedWidth = 788;

    if (this.configService.report().mos_pvqa === false) {
      fixedWidth -= 110;
    }

    if (this.configService.report().mos_aqua === false) {
      fixedWidth -= 110;
    }

    if (this.configService.report().mos_network === false) {
      fixedWidth -= 110;
    }

    if (this.configService.report().r_factor === false) {
      fixedWidth -= 110;
    }

    if (this.configService.report().percents_aqua === false) {
      fixedWidth -= 110;
    }

    return fixedWidth;
  }

  private updateColumn(col: UxTableColumn, fixedWidth: number) {
    if (col.id === "Device" || col.id === "Test" || col.id === "Target" || col.id === "Time") {
      col.inlineStyles = {
        'flex': `0 1 calc((100% - ${fixedWidth}px)/4`,
        'min-width': `calc((100% - ${fixedWidth}px)/4`,
        'width': `calc((100% - ${fixedWidth}px)/4`
      }
    }
    if ( (col.id === "AQUA" && this.configService.report().mos_aqua === false) ||
        (col.id === "PVQA" && this.configService.report().mos_pvqa === false) ||
        (col.id === "MOS Network" && this.configService.report().mos_network === false) ||
        (col.id === "Sevana R-factor" && this.configService.report().r_factor === false) ||
        (col.id === "Sevana Percentage" && this.configService.report().percents_aqua === false)
    ) {
      col.inlineStyles = {
        'flex': `0 0 0`,
        'min-width': `0`,
        'width': `0`
      }
    }
  }


  ngAfterViewInit(): void {
    //this.configureTestResultsTable();
  }

  _onPlayAudio(result: TestResultEntity) {
    if (result && result.audio_id > 0) {
      let audioID = result.audio_id;
      if (this.playAudioID === audioID) { // playing this. Need to stop
        this.audioTag.pause();
        this.audioTag.currentTime = 0;
        this.playAudioID = undefined;
        //this.testCaseChart.setPlayAudioID(this.playAudioID);

      } else { // play new
        if (result['audioBlob'] === undefined) {
          let queryParams = [
            {
              key: ApiPath.AUDIO_ID,
              value: audioID
            }
          ];

          result['playDownloadProgress'] = true;
          result['playDownloadSubscription$'] && result['playDownloadSubscription$'].unsubscribe();
          result['playDownloadSubscription$'] = forkJoin([
            this.audioService.listAudio(queryParams),
            this.audioService.downloadAudio(audioID)
          ])
              .pipe(
                  takeUntil(this.stopSubscription$),
                  tap((data: [AudioEntity[], DownloadFileResult]) => {
                    result['playDownloadProgress'] = false;

                    let audio: AudioEntity[] = data[0];
                    if (audio.length > 0) {
                      result['audioName'] = audio[0].name;
                      result['audioBlob'] = data[1].blob;
                      this._playData(result);
                    }
                  })
              )
              .subscribe();
        }
        else {
          this._playData(result);
        }
      }
    }
  }

  _playData(result: TestResultEntity) {
    if (result['audioBlob'] !== undefined) {
      this.playAudioID = result.audio_id;
      //this.testCaseChart.setPlayAudioID(this.playAudioID);

      let playUrl = window.URL.createObjectURL(result['audioBlob']);
      this.audioTag.src = playUrl;
      this.audioTag.play();
    }
  }

  _onDownloadAudio(result: TestResultEntity) {
    if (result && result.audio_id > 0) {
      let audioID = result.audio_id;

      if (result['audioBlob'] === undefined) {
        let queryParams = [
          {
            key: ApiPath.AUDIO_ID,
            value: audioID
          }
        ];

        result['downloadSubscription$'] && result['downloadSubscription$'].unsubscribe();
        result['downloadProgress'] = true;
        result['downloadSubscription$'] = forkJoin([
          this.audioService.listAudio(queryParams),
          this.audioService.downloadAudio(audioID)
        ])
            .pipe(
                takeUntil(this.stopSubscription$),
                tap((data: [AudioEntity[], DownloadFileResult]) => {
                  result['downloadProgress'] = false;

                  let audio: AudioEntity[] = data[0];
                  if (audio.length > 0) {
                    result['audioName'] = audio[0].name;
                    result['audioBlob'] = data[1].blob;
                    this._downloadData(result);
                  }
                })
            )
            .subscribe();
      }
      else {
        this._downloadData(result);
      }
    }
  }

  private _downloadData(result: TestResultEntity) {
    if (result['audioBlob'] !== undefined) {
      let audioName = result['audioName'];
      let downloadURL = window.URL.createObjectURL(result['audioBlob']);
      let link = document.createElement('a');
      link.href = downloadURL;
      link.download = audioName;
      link.click();
    }
  }

  onTestCaseChartPlayAudio(audioID: string|number) {
    this.playAudioID = audioID;
  }

  public loadInitialData() {
    super.loadInitialData();
    this.loadQueryParams();
  }

  public destroyComponent() {
    super.destroyComponent();

    this.stopSubscription$.next(true);
    this.stopSubscription$.complete();
  }

  private loadQueryParams() {
    let reportsSettings: ReportConfig = this.configService.report();
    this._reportsSettings.periods = (reportsSettings.periods === undefined ? 1000 : reportsSettings.periods);
    this._reportsSettings.defaultDuration = (reportsSettings.defaultDuration === undefined ? 24 : reportsSettings.defaultDuration);
    this._reportsSettings.mos_pvqa = (reportsSettings.mos_pvqa === undefined ? true : reportsSettings.mos_pvqa);
    this._reportsSettings.mos_aqua = (reportsSettings.mos_aqua === undefined ? true : reportsSettings.mos_aqua);
    this._reportsSettings.r_factor = (reportsSettings.r_factor === undefined ? true : reportsSettings.r_factor);
    this._reportsSettings.percents_aqua = (reportsSettings.percents_aqua === undefined ? true : reportsSettings.percents_aqua);
    this._reportsSettings.mos_network = (reportsSettings.mos_network === undefined ? true : reportsSettings.mos_network);

    this.route
      .queryParamMap
      .subscribe((params: ParamMap) => {
        let probeValue = params.get('probe');

        let periodValue = params.get('period');
        let devicesValue = params.getAll('devices') || [];
        let testsValue = params.getAll('tests') || [];
        let fromValue = params.get('from');
        let toValue = params.get('to');

        if (probeValue && probeValue != null) {
          this._probeID = probeValue;
          this.loadTestResult(this._probeID);
        }
        else {
          let period: moment.unitOfTime.DurationConstructor = 'h';
          if (periodValue === undefined || periodValue === null) {
            periodValue = StatisticPeriod.PERIOD_HOURS;
          }
          this._selectedPeriod = <StatisticPeriodType>periodValue;

          if (this._selectedPeriod === 'hour') {
            period = 'h';
          }
          else if (this._selectedPeriod === 'day') {
            period = 'd';
          }
          else if (this._selectedPeriod === 'month') {
            period = 'M';
          }

          if (toValue === undefined || toValue === null) {
            toValue = new Date().getTime().toString();
          }
          if (fromValue === undefined || fromValue === null) {
            let dateFrom: Date = moment(parseInt(toValue)).subtract(this._reportsSettings.defaultDuration, period).toDate();
            fromValue = dateFrom.getTime().toString();
          }

          this._selectedRange.from = new Date(parseInt(fromValue));
          this._selectedRange.to = new Date(parseInt(toValue));

          this._selectedDevices = devicesValue;
          this._selectedTests = testsValue;

          this.loadReportData();
        }
      })
  }

  private loadTestResult(probeID: string) {
    this._resultProgress = 'loading';

    this.resultSubscription$ && this.resultSubscription$.unsubscribe();
    this.resultSubscription$ = this.resultsService
      .getEntityById(probeID)
      .pipe(
        takeUntil(this.stopSubscription$),
        tap((result: TestResultEntity) => {
          this._selectedTestResult = result;
          this._resultProgress = this._selectedTestResult ? 'loaded' : 'no_data';
        })
      )
      .subscribe();
  }

  private loadReportData() {
    this._reportProgress = 'loading';

    let reportParams: ReportRequestParametersModel = {};
    reportParams.startDate = this._selectedRange.from;
    reportParams.endDate = this._selectedRange.to;
    reportParams.scale = this._selectedPeriod;
    reportParams.united = true;
    reportParams.phonesList = this._selectedDevices;
    reportParams.testsList = this._selectedTests;

    this.statisticsSubscription$ && this.statisticsSubscription$.unsubscribe();
    this.statisticsSubscription$ = this.reportsService
      .getReportDataEx(reportParams)
      .pipe(
        takeUntil(this.stopSubscription$),
        tap((statistics: StatisticsEntity[]) => {
          this._reportProgress = 'loaded';

          if (statistics) {
            this._reportStatistics = statistics;
          } else {
            this._reportStatistics = [];
          }

          this._summary = this.reportsService.calculateSummaryStatistics(this._reportStatistics);
          this._summaryVisual = this.reportsService.calculateSummaryVisualStatistics(this._summary);

          this.createAquaCharts();
          this.createPvqaCharts();
          this.createRFactorCharts();
          this.createPercentsCharts();
          this.createMosNetworkCharts();

          this.loadTestResults(this.pagingConfig.currentPage, this.pagingConfig.pageSize);
        })
      )
      .subscribe();
  }

  private loadTestResults(currentPage: number, pageSize: number) {
    this._resultsTableDataModel.body.rows = [];
    this._resultsProgress = 'loading';
    this.forkSubscription$ && this.forkSubscription$.unsubscribe();
    this.forkSubscription$ =  forkJoin([
      this.resultsService.getResults(
        this._selectedRange.from,
        this._selectedRange.to,
        this._selectedTests,
        this._selectedDevices
      ),
      this.devicesService.getEntityList(),
      this.testsService.getEntityList()
    ])
      .pipe(
        takeUntil(this.stopSubscription$),
        tap((data: [TestResultEntity[], DeviceEntity[], TestEntity[]]) => {
          let testResults: TestResultEntity[] = data[0];
          this._devicesMap = this.modelService.listToMap(data[1], "id");
          this._testsMap = this.modelService.listToMap(data[2], "id");

          if (testResults) {
            this.pagingConfig.totalCount = testResults.length;
            this._resultsPagingVisible = this.pagingConfig.totalCount > Math.min(...this.pagingConfig.pageSizeOptions);

            let startIndex = (currentPage - 1) * pageSize;
            let endIndex = startIndex + pageSize;
            this._results = testResults.slice(startIndex, endIndex > testResults.length ? testResults.length : endIndex);
          }
          else {
            this.pagingConfig.totalCount = 0;
            this._resultsPagingVisible = false;
          }

          let fixedWidth = this.calculateFixedWidth();
          this._resultsTableDataModel.body.rows =
            this._results
              .map((result: TestResultEntity) => {
                return this.getTableRow(result, fixedWidth);
              });
          this._resultsProgress = 'loaded';
        })
      )
      .subscribe();

    if (this.testResultsTable) {
      this.testResultsTable.update();
    }
    this.cdr.detectChanges();
  }

  private getTableRow(result: TestResultEntity, fixedWidth: number): UxTableRow {

    let inlineStyles: UxInlineNgStyles = {
      'flex': `0 1 calc((100% - ${fixedWidth}px)/4`,
      'min-width': `calc((100% - ${fixedWidth}px)/4`,
      'width': `calc((100% - ${fixedWidth}px)/4`
    };
    let inlineStylesHidden: UxInlineNgStyles = {
      'flex': `0 0 0`,
      'min-width': `0`,
      'width': `0`
    };

    let columns: UxTableColumn[]  = [
      {
        // "Device"
        value: this._devicesMap.has(result.phone_id) ? this._devicesMap.get(result.phone_id).instance : result.phone_name,
        inlineStyles: inlineStyles
      },
      {
        // "Test"
        value: this._testsMap.has(result.task_id) ? this._testsMap.get(result.task_id).name : result.task_name,
        inlineStyles: inlineStyles
      },
      {
        // "Target"
        value: result.target,
        inlineStyles: inlineStyles
      },
      {
        // "Time"
        type: 'content',
        value: this.timeColumn,
        contentModel: {
          endtime: result.endtime * 1000
        },
        inlineStyles: inlineStyles
      },
      {
        // "Duration"
        type: 'content',
        value: this.durationColumn,
        contentModel: {
          duration: result.duration
        }
      },
      {
        // "AQUA"
        value: result.mos_aqua === 0 ? result.mos_aqua : result.mos_aqua.toFixed(3),
        inlineStyles: this.configService.report().mos_aqua === false ? inlineStylesHidden : undefined
      },
      {
        // "PVQA"
        value: result.mos_pvqa === 0 ? result.mos_pvqa : result.mos_pvqa.toFixed(3),
        inlineStyles: this.configService.report().mos_pvqa === false ? inlineStylesHidden : undefined
      },
      {
        // "MOS Network"
        value: result.mos_network === 0 ? result.mos_network : result.mos_network.toFixed(3),
        inlineStyles: this.configService.report().mos_network === false ? inlineStylesHidden : undefined
      },
      {
        // "R-Factor"
        value: result.r_factor,
        inlineStyles: this.configService.report().r_factor === false ? inlineStylesHidden : undefined
      },
      {
        // "Percentage"
        value: result.percents_aqua ? result.percents_aqua : 0,
        inlineStyles: this.configService.report().percents_aqua === false ? inlineStylesHidden : undefined
      },
      {
        // "functions"
        type: 'content',
        value: this.functionsColumn,
        contentModel: {
          result: result
        }
      }
    ];

    return {
      id: result.id,
      columns: columns
    };
  }

  private createAquaCharts() {
    let chartTypes: Map<StatisticDataType, ChartType[]> = new Map();
    chartTypes.set("mos_aqua", ["linear", "calendar"]);

    this._aquaCharts = this.chartService
      .generateCharts(chartTypes,
                      this._selectedPeriod,
                      this._reportStatistics);
  }

  private createPvqaCharts() {
    let chartTypes: Map<StatisticDataType, ChartType[]> = new Map();
    chartTypes.set("mos_pvqa", ["linear", "calendar"]);

    this._pvqaCharts = this.chartService
      .generateCharts(chartTypes,
                      this._selectedPeriod,
                      this._reportStatistics);
  }

  private createRFactorCharts() {
    let chartTypes: Map<StatisticDataType, ChartType[]> = new Map();
    chartTypes.set("r_factor", ["linear", "calendar"]);

    this._rFactorCharts = this.chartService
      .generateCharts(chartTypes,
                      this._selectedPeriod,
                      this._reportStatistics);
  }

  private createPercentsCharts() {
    let chartTypes: Map<StatisticDataType, ChartType[]> = new Map();
    chartTypes.set("percents_aqua", ["linear", "calendar"]);

    this._percentsCharts = this.chartService
        .generateCharts(chartTypes,
            this._selectedPeriod,
            this._reportStatistics);
  }

  private createMosNetworkCharts() {
    let chartTypes: Map<StatisticDataType, ChartType[]> = new Map();
    chartTypes.set("mos_network", ["linear", "calendar"]);

    this._mosNetworkCharts = this.chartService
      .generateCharts(chartTypes,
                      this._selectedPeriod,
                      this._reportStatistics);
  }

  _onCalendarVisibleChange(bVisible: boolean) {
    if (!bVisible && this.validatePeriodAndRange(this._selectedRange)) {
      let period: moment.unitOfTime.DurationConstructor = 'h';
      if (this._selectedPeriod === 'hour') {
        period = 'h';
      }
      else if (this._selectedPeriod === 'day') {
        period = 'd';
      }
      else if (this._selectedPeriod === 'month') {
        period = 'M';
      }
      let dateFrom: Date = this._selectedRange.from;
      let dateTo: Date = this._selectedRange.to;
      dateTo.setHours(23, 59, 59, 999);
      let currTime = new Date();
      if (dateTo.getTime() > currTime.getTime()) {
        dateTo.setTime(currTime.getTime());
      }

      this.router.navigate(['/reports/view'], {
        queryParams: {
          period: this._selectedPeriod,
          from: dateFrom.getTime(),
          to: dateTo.getTime(),
          devices: this._selectedDevices,
          tests: this._selectedTests
        }
      });
    }
  }

  _onPageChange(pageEvent: UxPageChangeEvent) {
    if (this.viewInitiated) {
      this.loadTestResults(pageEvent.page, pageEvent.items);
    }
  }

  _onPageSizeChange(pageEvent: UxPageChangeEvent) {
    if (this.viewInitiated) {
      this.loadTestResults(pageEvent.page, pageEvent.items);
    }
  }

  _onChartIcon(result: TestResultEntity) {
    this._selectedTestResult = result;
  }

  private validatePeriodAndRange(value: UxRangeDateFieldModel): boolean {
    this._rangeError = "";
    let allowed = this._reportAllowed;

    if (!value) {
      this._rangeError = "Please chose correct period of report";
      allowed = false;
    }
    else if (!value.from) {
      this._rangeError = "Please chose report period start date";
      allowed = false;
    }
    else if (!value.to) {
      this._rangeError = "Please chose report period end date";
      allowed = false;
    }
    else if (value && value.from && value.to) {
      let fromDate = new Date(value.from);
      let toDate = new Date(value.to);
      fromDate.setHours(0, 0, 0);
      toDate.setHours(23, 59, 59);

      let currDate = new Date();
      if (toDate.getTime() > currDate.getTime()) {
        toDate = currDate;
      }

      let fromTime = fromDate.getTime();
      let toTime = toDate.getTime();

      let maxPeriods = this.configService.report().periods;
      let diff = toTime - fromTime;

      if (this._selectedPeriod === StatisticPeriod.PERIOD_HOURS) {
        let hours = (diff / 1000) / 3600;
        if (hours > maxPeriods) {
          this._rangeError = `Selected period contains more than ${maxPeriods} hours`;
        }
      }
      else if (this._selectedPeriod === StatisticPeriod.PERIOD_DAYS) {
        let days = ((diff / 1000) / 3600) / 24;
        if (days > maxPeriods) {
          this._rangeError = `Selected period contains more than ${maxPeriods} days`;
        }
      }
      else if (this._selectedPeriod === StatisticPeriod.PERIOD_MONTHS) {
        let months = (toDate.getFullYear() - fromDate.getFullYear()) * 12;
        months -= fromDate.getMonth() + 1;
        months += toDate.getMonth();
        if (months > maxPeriods) {
          this._rangeError = `Selected period contains more than ${maxPeriods} months`;
        }
      }

      allowed = (this._rangeError.length === 0);
    }
    else {
      allowed = false;
    }

    if (this._reportAllowed != allowed) {
      this._reportAllowed = allowed;
    }

    return this._reportAllowed;
  }

  /** @internal */
  public _trackByChartEntity(index: number, item: ChartEntity): number|string {
    return item && item.id;
  }
}
