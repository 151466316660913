import {NgModule} from "@angular/core";
import {UxAccordionComponent} from "./accordion.component";
import {UxAccordionItemComponent} from "./item/accordion-item.component";
import {SharedModule} from "../../shared/shared.module";
import {UxAccordionItemHeaderComponent} from "./item/accordion-item-header.component";
import {UxSlideToggleModule} from "../slide-toggle/slide-toggle.module";

const COMPONENTS = [
    UxAccordionComponent,
    UxAccordionItemComponent,
    UxAccordionItemHeaderComponent
];

@NgModule({
    imports: [SharedModule, UxSlideToggleModule],
    exports: COMPONENTS,
    declarations: COMPONENTS
})
export class UxAccordionModule {
}
