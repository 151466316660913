import {Component, Input, OnInit, Output, EventEmitter} from "@angular/core";
import {WizardStep} from "./wizard-step.model";
import {UxPropertyHandler} from "../../common/decorator/ux-property-handler";

@Component({
    selector: "ux-wizard-navigation",
    templateUrl: "./wizard-navigation.component.html",
    host: { "[class.ux-wizard-navigation]": "true" }
})
export class UxWizardNavigationComponent {

    @UxPropertyHandler({
        afterChange: afterChangeSteps
    })
    @Input()
    public steps: Array<WizardStep> = [];

    @UxPropertyHandler({
        afterChange: afterChangeStepIndex
    })
    @Input()
    public stepIndex: number;

    @Input()
    public mobile: boolean = false;


    @Output()
    public onStepChange: EventEmitter<number> = new EventEmitter<number>();

    public get activeStep(): WizardStep {
        let self = this;
        return self.stepIndex < self.steps.length ? self.steps[self.stepIndex] : null;
    }

    public hasNext(): boolean {
        return this.steps.length > this.stepIndex + 1;
    }

    public hasPrevious(): boolean {
        return this.stepIndex > 0;
    }

    public next(): void {
        this.stepIndex++;
    }

    public previous(): void {
        this.stepIndex--;
    }

    private switchStep(stepIndex: number): void {
        let self = this;
        if (self.steps && self.steps.length > 0) {
            if (typeof self.steps[stepIndex] === "undefined") {
                console.error(`Incorrect step index: ${stepIndex}. Step is not defined.`);
                return;
            }
            self.steps.forEach((step, index) => {
                step.isActive = (index == stepIndex);
                step.isPassed = (index < stepIndex);
            });
            self.onStepChange.emit(stepIndex);
        }
    }

    /** @internal */
    public _trackByFn(index: number, item: WizardStep): string {
        return item && item.id;
    }
}


/*Helpers*/
export function afterChangeSteps() {
    let self = this,
        currentStep = self.stepIndex,
        currentSteps = self.steps;
    if (currentStep === undefined && currentSteps !== undefined && currentSteps.length > 0) {
        self.stepIndex = 0;
    } else if (currentStep !== undefined && currentSteps !== undefined && currentSteps.length > 0) {
        self.switchStep(currentStep);
    }
}

export function afterChangeStepIndex(value: number) {
    this.switchStep(value);
}
