import {NgModule} from "@angular/core";
import {UxToolbarComponent} from "./toolbar.component";
import {SharedModule} from "../../shared/shared.module";
import {UxToolbarRightComponent} from "./parts/toolbar-right.component";
import {UxToolbarLeftComponent} from "./parts/toolbar-left.component";

@NgModule({
    imports: [SharedModule],
    exports: [UxToolbarComponent, UxToolbarRightComponent, UxToolbarLeftComponent],
    declarations: [UxToolbarComponent, UxToolbarRightComponent, UxToolbarLeftComponent]
})
export class UxToolbarModule {
}