import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    TemplateRef,
    ViewChild
} from "@angular/core";
import {UxMultipleFieldItemModel} from "./multiple-field-item.model";
import {UxAbstractFieldComponent} from "../abstract-field.component";
import {NG_VALUE_ACCESSOR} from "@angular/forms";
import {UxPropertyConverter} from "../../../common/decorator/ux-property-converter";


@Component({
    selector: "ux-multiple-field",
    templateUrl: "multiple-field.component.html",
    host: {"[class.ux-multiple-field]": "true"},
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: UxMultipleFieldComponent,
            multi: true
        }
    ]
})
export class UxMultipleFieldComponent extends UxAbstractFieldComponent<UxMultipleFieldItemModel[]> {

    @UxPropertyConverter("string", "caption")
    @Input()
    public itemCaptionKey: string;

    @Input()
    public valueItemTemplate: TemplateRef<{ item: UxMultipleFieldItemModel }>;


    @Input()
    public popoverContainer: ElementRef;

    @Input()
    public popoverContentTemplate: TemplateRef<any>;

    @Input()
    public popoverVisible: boolean = false;


    @Input()
    public items: UxMultipleFieldItemModel[];

    @Input()
    public showSelectAll: boolean = false;

    @Input()
    public selectAllCaption: string = "Select All";

    @Output()
    public popoverVisibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Output()
    public onUnselect: EventEmitter<UxMultipleFieldItemModel> = new EventEmitter<UxMultipleFieldItemModel>();


    /** @internal */
    @ViewChild("addButton", { read: ElementRef, static: true })
    public _addButton: ElementRef;

    constructor() {
        super();
    }

    /** @internal */
    public _onAddButtonClick(): void {
        this.popoverVisible = !this.popoverVisible;
        this.popoverVisibleChange.emit(this.popoverVisible);
    }

    /** @internal */
    public _onKeyDown(event: KeyboardEvent): void {
        if (event.which === 32) { //space
            event.preventDefault();
            this._onAddButtonClick();
        }
    }

    protected getDefaultValue(): UxMultipleFieldItemModel[] {
        return null;
    }

    protected getValueConverter(): { (value: any): UxMultipleFieldItemModel[] } {
        return function (value: any): UxMultipleFieldItemModel[] {
            return value;
        };
    }

    /** @internal */
    public _trackByFn(index: number, item: UxMultipleFieldItemModel): string {
        return item && item.id;
    }

    /**
     * Remove item from value.
     *
     * @internal
     */
    public _removeValueItem(item: UxMultipleFieldItemModel): void {
        this.value = this.value.filter(valueItem => valueItem !== item);
        this.onUnselect.emit(item);
    }

    /** @internal */
    public _changePopoverVisible(event: boolean): void {
        this.popoverVisibleChange.emit(event);
    }
}
