import {Injectable, Injector} from "@angular/core";
import {Observable, of, Subscriber} from "rxjs";
import {ApiService} from "../../common/services/api.service";
import {TokenModel} from "./model/token.model";
import {ApiPath} from "../../common/services/api.path";
import {QueryParam} from "../../common/models/query-param.type";
import {CryptoService} from "./crypto.service";
import {NgZone} from "@angular/core";
import {Router} from "@angular/router";

@Injectable()
export class LoginService extends ApiService {
  protected readonly baseUrl: string = ApiPath.AUTH_PATH;
  protected readonly authUrl: string[] = [this.baseUrl + "/", "./json/auth.json"];

  private actualToken: TokenModel;

  private cryptoService: CryptoService;
  private router: Router;

  constructor(protected injector: Injector,
              private zone: NgZone) {
    super(injector);
    this.cryptoService = this.injector.get(CryptoService);
    this.router = this.injector.get(Router);

    this.zone.runOutsideAngular(() => {
      window.addEventListener("storage", (storageEvent: StorageEvent) => {
        if (storageEvent.key === "token") {
          this.zone.run(() => {
            if (storageEvent.newValue) {
              this.actualToken = JSON.parse(storageEvent.newValue);

              if (this.router && this.router.isActive("login", false)) {
                this.router.navigate([""]);
              }
            } else {
              this.logout();
            }
          });
        }
      });
    });
  }

  public isAuthenticated$(): Observable<boolean> {
    const authToken: TokenModel = this.getToken();
    const currentDate = Date.now();
    if (authToken && authToken.session_token) {
      if (currentDate < authToken.expiresIn) {
        return of(true);
      }
    }
    this.logout();
    return of(false);
  }

  public authorize(login: string, password: string): Observable<TokenModel> {
    let queryParams: QueryParam[] = [
      {
        key: ApiPath.USERNAME,
        value: login
      },
      {
        key: ApiPath.PASSHASH,
        value: this.cryptoService.encrypt(password)
      }
    ];


    let o = Observable.create((subscriber: Subscriber<TokenModel>) => {
      let get = this.get(this.authUrl, {
        queryParams: queryParams
      }).subscribe(async (token: TokenModel) => {

        //TODO: remove on authorization implementation ready
        let authToken = token;
        if (!authToken.session_token && !authToken.errormsg) {
          authToken = {
            session_token: "3d79f77c-5b2f-46d1-b7ad-74de01787d42",
            lifetime: 86400,
            role: "admin",
            errormsg: ""
          }
        }

        authToken.user = login;
        authToken.expiresIn = 0;
        if (authToken.session_token) {
          authToken.expiresIn = Date.now() + authToken.lifetime*1000;
        }

        this.addToken(authToken);

        subscriber.next(token);
        subscriber.complete();
      });
      return () => {
        get && get.unsubscribe();
      };
    });
    return o;
  }

  public logout() {
    if (this.getToken()) {
      this.removeToken();
    }
  }

  private addToken(token: TokenModel) {
    this.actualToken = token;
    localStorage.setItem("token", JSON.stringify(token));
  }

  public getToken(): TokenModel {
    if (this.actualToken === undefined || this.actualToken === null || this.isEmpty(this.actualToken.session_token)) {
      this.actualToken = JSON.parse(localStorage.getItem("token"));
    }
    return this.actualToken;
  }

  public removeToken() {
    localStorage.removeItem("token");
    this.actualToken = null;
  }

  private isEmpty(value: string): boolean {
    return value === undefined || value === null || value === "";
  }
}
