import {
    AfterViewInit,
    Compiler,
    ComponentFactoryResolver,
    Directive,
    ElementRef,
    EmbeddedViewRef,
    EventEmitter,
    HostBinding,
    Input,
    NgZone,
    OnDestroy,
    Output,
    Renderer2,
    TemplateRef,
    ViewContainerRef
} from "@angular/core";
import {UxDomHelper} from "../../shared/dom/dom-helper";
import {UxDropdownFieldComponent, UxDropdownListItem} from "../fields/dropdown/dropdown-field.component";


export interface UxEditableDropDownDirectiveModel {
    maxWidth?: number;
    minWidth?: number;
    popoverStyleClass?: string;
    customData?: any;
    appearenceTimeout?: number;
}



@Directive({
    selector: "[UxEditableDropDown]",
    exportAs: "UxEditableDropDown"
})
export class UxEditableDropDownDirective implements AfterViewInit, OnDestroy {

    private _uxEditableDropDown: UxDropdownListItem;
    private _uxEditableDropDownItems: UxDropdownListItem[];

    private defaultModel: UxEditableDropDownDirectiveModel = {minWidth: 200};

    @Input("UxEditableDropDown")
    public set UxEditableDropDown(value: UxDropdownListItem) {
        this._uxEditableDropDown = value;

        if (this.dropdownFieldComponent) {
            this.dropdownFieldComponent.value = value;
        }
    }

    public get UxEditableDropDown(): UxDropdownListItem {
        return this._uxEditableDropDown;
    }

    @Input("UxEditableDropDownItems")
    public set UxEditableDropDownItems(values: UxDropdownListItem[]) {
      this._uxEditableDropDownItems = values;

      if (this.dropdownFieldComponent) {
        this.dropdownFieldComponent.items = values;
      }
    }

    public get UxEditableDropDownItems(): UxDropdownListItem[] {
      return this._uxEditableDropDownItems;
    }

    @Input("UxEditableDropDownContainer")
    public set UxEditableDropDownContainer(value: string | Element) {
        if (value) {
            this._uxEditableDropDownContainer = value;
        }
    };

    public get UxEditableDropDownContainer(): string | Element {
        return this._uxEditableDropDownContainer;
    };

    private _uxEditableDropDownContainer: string | Element = "body";

    @Input("UxEditableDropDownModel")
    public set model(value: UxEditableDropDownDirectiveModel) {
        if (value && value.popoverStyleClass !== this._model.popoverStyleClass && this.currentPopup) {
            this._model.popoverStyleClass && this.currentPopup.classList.remove(this._model.popoverStyleClass);
            value.popoverStyleClass && this.currentPopup.classList.add(value.popoverStyleClass);
        }
        this._model = Object.assign(this.defaultModel, value);
    }

    public get model(): UxEditableDropDownDirectiveModel {
        return this._model;
    }

    @Input("UxEditableDropDownTemplate")
    public template: TemplateRef<any>;

    @Input("UxEditableDropDownConfirmButton")
    public hasConfirmButton: boolean = false;


    @Output()
    public UxEditableDropDownChange: EventEmitter<UxDropdownListItem> = new EventEmitter<UxDropdownListItem>();

    @Output()
    public onEditToggle: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Output()
    public onEditSubmit: EventEmitter<void> = new EventEmitter<void>();

    @Output()
    public onEditCancel: EventEmitter<void> = new EventEmitter<void>();


    private _model: UxEditableDropDownDirectiveModel = this.defaultModel;
    private currentPopup: any;
    private hostElement: Element;
    private hostElementParameters: ClientRect;
    private dropdownFieldComponent: UxDropdownFieldComponent;
    private contentEmbeddedView: EmbeddedViewRef<any>;
    private timeoutId: number;


    @HostBinding("class._ux-editable-dropdown")
    private hostClass = true;


    constructor(private elementReference: ElementRef,
                private zone: NgZone,
                private renderer: Renderer2,
                private viewContainerRef: ViewContainerRef,
                private componentFactoryResolver: ComponentFactoryResolver) {
    }


    public ngAfterViewInit(): void {
        this.zone.runOutsideAngular(() => {
            this.hostElement = this.elementReference.nativeElement;
            this.createDownIcon();
            this.bindEvents();
        });
    }

    private bindEvents(): void {
        this.hostElement.addEventListener("click", this.onClick);
    }

    private onClick = (event: Event) => {
        event.stopPropagation();

        this.appendPopup();
        this.setPopupParams(this.currentPopup);
        this.currentPopup.classList.add("_visible");

        if (this.dropdownFieldComponent) {
          this.dropdownFieldComponent.items = this.UxEditableDropDownItems;
          this.dropdownFieldComponent.value = this.UxEditableDropDown;
        }

        this.startEditing();
    };

    private onPopupKeyUp = (event: KeyboardEvent) => {
        event.stopPropagation();

        if (event.which === 13) { // Enter
            this.zone.run(() => {
                this.submit();
            });
        } else if (event.which === 27) { // Escape
            this.onEscClose(event);
        }
    };

    private onCancelButtonClick = (event: Event) => {
        event.stopPropagation();

        this.cancel();
    };

    private onOkButtonClick = (event: Event) => {
        event.stopPropagation();

        this.submit();
    };

    private clickOutsideCb = (e: Event) => {
        if (this.currentPopup && !this.currentPopup.contains(e.target as Node)) {

            this.zone.run(() => {
                this.submit();
            });
        }
    };


    private scrollOutsideCb = (e: Event) => {
        if (this.currentPopup && !this.currentPopup.contains(e.target as Node)) {

            this.zone.run(() => {
                this.submit();
            });
        }
    };

    private onEscClose(event: KeyboardEvent): void {
        event.stopPropagation();

        this.zone.run(() => {
            this.cancel();
        });
    }

    private submit(): void {
        this.setValue();

        this.hidePopup(true);
        this.onEditSubmit.emit();
    }

    private cancel(): void {
        this.hidePopup(true);
        this.onEditCancel.emit();
    }

    private startEditing(): void {
        this.currentPopup.classList.add("_edit");
        this.currentPopup.classList.add("_visible");
        this.hostElement.classList.add("ux-editable-dropdown-invisible-host");

        this.zone.run(() => {
            window.clearTimeout(this.timeoutId);
            this.timeoutId = window.setTimeout(() => {
                this.timeoutId = null;
                if (this.dropdownFieldComponent) {
                    this.dropdownFieldComponent.focus();
                }

                this.onEditToggle.emit(true);
            }, 100);
        });
    }

    private endEditing(fireEvent: boolean): void {
        if (this.currentPopup) {
            this.currentPopup.classList.remove("_visible");
            this.currentPopup.classList.remove("_edit");
        }
        this.hostElement.classList.remove("ux-editable-dropdown-invisible-host");

        if (fireEvent) {
            this.zone.run(() => {
                this.onEditToggle.emit(false);
            });
        }
    }

    private setValue(): void {
        if (this.dropdownFieldComponent) {
            this.UxEditableDropDown = this.dropdownFieldComponent.value;

            this.zone.run(() => {
                this.UxEditableDropDownChange.emit(this.UxEditableDropDown);
            });
        }
    }

    private hidePopup(fireEvent: boolean = false): void {
        this.endEditing(fireEvent);
        this.destroy();
    }

    private destroy(): void {
        this.contentEmbeddedView && this.contentEmbeddedView.destroy();

        if (this.currentPopup) {
            this.currentPopup.removeEventListener("keyup", this.onPopupKeyUp);

            if (this.currentPopup.parentNode) {
                UxDomHelper.removeChildFromParent(this.currentPopup);
            }
            this.currentPopup = undefined;
        }

        document.removeEventListener("click", this.clickOutsideCb, true);
        window.removeEventListener("scroll", this.scrollOutsideCb, true);
    }

    public ngOnDestroy(): void {
        this.destroy();

        if (this.hostElement) {
            this.hostElement.removeEventListener("click", this.onClick);
        }

        window.clearTimeout(this.timeoutId);
    }

    private createPopup(): void {
        this.createPopupElement();

        this.zone.run(() => {
            this.initPopupContent();
        });
    }

    private initPopupContent(): void {
        if (this.template) {

            this.contentEmbeddedView = this.viewContainerRef.createEmbeddedView(this.template, {model: this.model.customData});
            this.contentEmbeddedView.detectChanges();

            for (let node of this.contentEmbeddedView.rootNodes) {
                if (node) {
                    this.currentPopup.appendChild(node);
                }
            }


        } else {
            //default template

            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(UxDropdownFieldComponent);
            const embeddedView = this.viewContainerRef.createComponent(componentFactory);

            this.dropdownFieldComponent = embeddedView.instance as UxDropdownFieldComponent;
            this.dropdownFieldComponent.value = this._uxEditableDropDown;

            this.renderer.appendChild(this.currentPopup, embeddedView.location.nativeElement);
        }

        if (this.hasConfirmButton) {
            this.createButtons();
        }
    }

    private setPopupParams(currentPopup): void {
        let popupStyle = currentPopup.style;

        this.hostElementParameters = this.getHostElementParameters();
        popupStyle.top = this.hostElementParameters.top + "px";
        popupStyle.left = this.hostElementParameters.left + "px";
        popupStyle.width = this.getPopupWidth() + "px";
    }

    private appendPopup(): void {
        this.hidePopup();

        this.createPopup();

        if (typeof this._uxEditableDropDownContainer === "string") {
            document.querySelector(this._uxEditableDropDownContainer).appendChild(this.currentPopup);
        } else {
            this._uxEditableDropDownContainer.appendChild(this.currentPopup);
        }

        this.zone.runOutsideAngular(() => {
            document.addEventListener("click", this.clickOutsideCb, true);
            window.addEventListener("scroll", this.scrollOutsideCb, true);
            this.currentPopup.addEventListener("keyup", this.onPopupKeyUp);
        });
    }


    private getHostElementParameters(): ClientRect {
        let params = UxDomHelper.getDocumentRelativePosition(this.hostElement);

        if (this.UxEditableDropDownContainer !== document.body &&
            this.UxEditableDropDownContainer !== "body") {
            params.top = 0;
            params.left = 0;
        }

        return params;
    }

    private getPopupWidth(): number {
        let width: number = this.hostElementParameters.width,
            maxWidth = this._model.maxWidth,
            minWidth = this._model.minWidth;

        if (maxWidth && this.hostElementParameters.width > maxWidth) {
            width = maxWidth;
        }

        if (minWidth && this.hostElementParameters.width < minWidth) {
            width = minWidth;
        }

        return width;
    }

    private createPopupElement(): void {
        this.currentPopup = this.renderer.createElement("div");
        this.renderer.setAttribute(this.currentPopup, "id", "UxEditableDropDownDirective2");
        this.currentPopup.className = this._model.popoverStyleClass ? "ux-editable-dropdown " + this._model.popoverStyleClass : "ux-editable-dropdown";
    }

    private createDownIcon(): void {
        this.hostElement.insertAdjacentHTML("afterbegin",
            `<div class='ux-editable-dropdown__down'>
            <svg viewBox="0 0 20 20" class="ux-editable-dropdown__down-icon">
                <path d="M13.418 7.859a.695.695 0 0 1 .978 0 .68.68 0 0 1 0 .969l-3.908 3.83a.697.697 0 0 1-.979 0l-3.908-3.83a.68.68 0 0 1 0-.969.695.695 0 0 1 .978 0L10 11l3.418-3.141z"/>
            </svg>
        </div>`);
    }

    private createButtons(): void {
        //ok
        const okButton = this.renderer.createElement("div");
        this.renderer.addClass(okButton, "ux-editable-dropdown__button");
        this.renderer.addClass(okButton, "_ok");

        const okButtonSVGElement = this.renderer.createElement("svg", "svg");
        this.renderer.addClass(okButtonSVGElement, "ux-editable-dropdown__button-icon");
        this.renderer.setAttribute(okButtonSVGElement, "viewBox", "0 0 20 20");
        okButtonSVGElement.innerHTML = '<path d="M8.294,16.998c-0.435,0-0.847-0.203-1.111-0.553L3.61,11.724c-0.465-0.613-0.344-1.486,0.27-1.951c0.615-0.467,1.488-0.344,1.953,0.27l2.351,3.104l5.911-9.492c0.407-0.652,1.267-0.852,1.921-0.445c0.653,0.406,0.854,1.266,0.446,1.92L9.478,16.34c-0.242,0.391-0.661,0.635-1.12,0.656C8.336,16.998,8.316,16.998,8.294,16.998z"></path>';

        this.renderer.appendChild(okButton, okButtonSVGElement);
        this.renderer.appendChild(this.currentPopup, okButton);
        okButton.addEventListener("click", this.onOkButtonClick);


        //cancel
        const cancelButton = this.renderer.createElement("div");
        this.renderer.addClass(cancelButton, "ux-editable-dropdown__button");
        this.renderer.addClass(cancelButton, "_cancel");

        const cancelButtonSVGElement = this.renderer.createElement("svg", "svg");
        this.renderer.addClass(cancelButtonSVGElement, "ux-editable-dropdown__button-icon");
        this.renderer.setAttribute(cancelButtonSVGElement, "viewBox", "0 0 20 20");
        cancelButtonSVGElement.innerHTML = '<path d="M14.348,14.849c-0.469,0.469-1.229,0.469-1.697,0L10,11.819l-2.651,3.029c-0.469,0.469-1.229,0.469-1.697,0c-0.469-0.469-0.469-1.229,0-1.697l2.758-3.15L5.651,6.849c-0.469-0.469-0.469-1.228,0-1.697c0.469-0.469,1.228-0.469,1.697,0L10,8.183l2.651-3.031c0.469-0.469,1.228-0.469,1.697,0c0.469,0.469,0.469,1.229,0,1.697l-2.758,3.152l2.758,3.15C14.817,13.62,14.817,14.38,14.348,14.849z"></path>';

        this.renderer.appendChild(cancelButton, cancelButtonSVGElement);
        this.renderer.appendChild(this.currentPopup, cancelButton);
        cancelButton.addEventListener("click", this.onCancelButtonClick);
    }

}

