import {Component, ElementRef, Input, OnDestroy, ViewChild, ChangeDetectorRef} from "@angular/core";
import {UxPropertyConverter} from "../../common/decorator/ux-property-converter";
import {UxPropertyHandler} from "../../common/decorator/ux-property-handler";

@Component({
    selector: "ux-overlay",
    templateUrl: "./overlay.component.html",
    host: {
        "[class.ux-overlay]": "true"
    }
})
export class UxOverlayComponent implements OnDestroy {

    @Input()
    @UxPropertyConverter("number", 200)
    @UxPropertyHandler({
        afterChange: afterChangeDebounce
    })
    public debounce: number;

    private debounceTimeout: number;

    @Input()
    public zIndex: number = 1000;

    @UxPropertyConverter("boolean", false)
    private _visible: boolean;

    private visibleTemp: boolean = false;

    @Input()
    public set visible(visible: boolean) {
        let self = this;
        if (self.visibleTemp !== visible) {
            self.visibleTemp = visible;
            self.stopTimeout();
            if (self.visibleTemp && self.debounce > 0) {
                self.createTimeout(self.debounce);
            } else {
                self._visible = self.visibleTemp;
            }
        }
    }

    public get visible(): boolean {
        return this._visible;
    }

    @Input()
    public mobile: boolean = false;


    @ViewChild("inner", { static: true })
    public innerElementRef: ElementRef;

    constructor(private cdr: ChangeDetectorRef) {
    }

    public ngOnDestroy(): void {
        this.stopTimeout();
    }

    private stopTimeout(): void {
        if (this.debounceTimeout) {
            clearTimeout(this.debounceTimeout);
            this.debounceTimeout = null;
        }
    }

    private createTimeout(debounce: number): void {
        let self = this;
        self.stopTimeout();
        self.debounceTimeout = window.setTimeout(
            () => {
                self._visible = true;
                self.debounceTimeout = null;
                // For onPush strategy
                self.cdr.detectChanges();
            },
            debounce
        );
    }
}

/* Helpers */
export function afterChangeDebounce(newValue: number) {
    let self = this;
    if (self.visibleTemp && self.debounce > 0) {
        self.createTimeout(newValue);
    }
}
