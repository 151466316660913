import {
  Component,
  Input
} from "@angular/core";
import {DeviceEntity} from "../../../common/models/entity/device-entity.model";
import {Subject, Subscription} from "rxjs";
import {takeUntil, tap} from "rxjs/operators";
import {NotificationService} from "../../../common/services/notification.service";
import {LinkOperationResult, TestsService} from "../../tests/tests.service";
import {PopupComponent} from "../../../components/base/popup.component";

@Component({
  selector: "sq-device-link-new-test-popup",
  templateUrl: "device-link-new-test-popup.component.html",
  host: {"[class.sq-device-link-new-test-popup]": "true"}
})
export class DeviceLinkNewTestPopupComponent extends PopupComponent<DeviceEntity> {
  _selectedTests: string[] = [];
  _linkErrorMessage: string;

  private stopSubscription$ = new Subject<boolean>();
  private linkTestsSubscription$: Subscription;

  _device: DeviceEntity;
  @Input()
  public set device(value: DeviceEntity) {
    this._device = value;
  }

  public get device(): DeviceEntity {
    return this._device;
  }

  constructor(private notificationService: NotificationService,
              private testsService: TestsService) {
    super();
  }

  public destroyComponent() {
    this.stopSubscription$.next(true);
    this.stopSubscription$.complete();
  }

  protected _close() {
    this.device = undefined;
    this._selectedTests = [];
    super._close();
  }

  _onLink(event: MouseEvent): void {
    this._linkErrorMessage = undefined;
    this.linkTestsToDevice();
  }

  _onCardChecked(selectedTests: string[]) {
    this._selectedTests = selectedTests;
    this._linkErrorMessage = undefined;
  }

  private linkTestsToDevice() {
    if (this._selectedTests.length > 0) {
      let testID = this._selectedTests.pop();
      let deviceID = this._device.id;

      this.linkTestsSubscription$ && this.linkTestsSubscription$.unsubscribe();
      this.linkTestsSubscription$ = this.testsService.linkTestToDevice(testID, deviceID)
        .pipe(
          takeUntil(this.stopSubscription$),
          tap((result: LinkOperationResult) => {
            if (result.opResult) {
              this.linkTestsToDevice();
            }
            else {
              this._linkErrorMessage = `Fail to link test ${result.testID} to device ${result.deviceID}: error: ${result['__initialResponse'].status}`;
              this.notificationService.pushNotification({
                type: "error",
                caption: "Link error",
                content: this._linkErrorMessage
              });
            }
          })
        )
        .subscribe();
    }
    else {
      this.hide()
    }
  }
}
