import {Component} from "@angular/core";
import {UxAbstractPeriodicLoaderComponent} from "../abstract-periodic-loader.component";

@Component({
    selector: "ux-round-dots-loader",
    templateUrl: "./round-dots-loader.component.html",
    host: {"[class.ux-round-dots-loader]": "true"}
})
export class UxRoundDotsLoaderComponent extends UxAbstractPeriodicLoaderComponent {
}
