import {NgModule} from "@angular/core";
import {UxTooltipDirective} from "./tooltip.directive";
import {CommonModule} from "@angular/common";

@NgModule({
    imports: [CommonModule],
    exports: [UxTooltipDirective],
    declarations: [UxTooltipDirective]
})
export class UxTooltipModule {
}