import {NgModule} from "@angular/core";
import {UxDropdownFieldComponent} from "./dropdown-field.component";
import {CommonModule} from "@angular/common";
import {SharedModule} from "../../../shared/shared.module";
import {UxFieldSuggestionPopupModule} from "../suggestion-popup/field-suggestion-popup.module";
import {FormsModule} from "@angular/forms";

@NgModule({
    imports: [SharedModule,
        CommonModule,
        FormsModule,
        UxFieldSuggestionPopupModule
    ],
    exports: [UxDropdownFieldComponent],
    declarations: [UxDropdownFieldComponent]
})
export class UxDropdownFieldModule {
}
