import {NgModule} from "@angular/core";
import {UxDateFieldComponent} from "./date-field.component";
import {SharedModule} from "../../../shared/shared.module";
import {FormsModule} from "@angular/forms";
import {UxPopoverModule} from "../../popover/popover.module";

@NgModule({
    imports: [SharedModule, FormsModule, UxPopoverModule],
    exports: [UxDateFieldComponent],
    declarations: [UxDateFieldComponent]
})
export class UxDateFieldModule {
}