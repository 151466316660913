import {
    Component,
    ElementRef,
    HostBinding,
    Input,
    TemplateRef,
    SimpleChanges,
    EventEmitter,
    Output
} from "@angular/core";

@Component({
    selector: 'ux-tab-panel-item',
    templateUrl: "./tab-panel-item.component.html",
    host: {"[class.ux-tab-panel-item]": "true"}
})
export class UxTabPanelItem {
    @Input()
    public title: string;

    @Input()
    public tooltip: string;

    /* Only for internal usage! */
    @Output()
    public onChanges: EventEmitter<SimpleChanges> = new EventEmitter<SimpleChanges>();

    /** @internal */
    @HostBinding('class._selected')
    @Input()
    public selected: boolean = false;

    @HostBinding("class._disabled")
    @Input()
    public disabled: boolean = false;

    @Input()
    public headerStyleClass: string;

    @Input()
    public template: TemplateRef<any>;

    constructor(public elementRef: ElementRef) {
    }

    public ngOnChanges(changes: SimpleChanges): void {
        this.onChanges.emit(changes);
    }
}
