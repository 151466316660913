import {UxCardTreeNodeModel, UxCardTreeNodeModelCopier} from "../../../ux-lib/components/card-tree/card-tree.model";
import {Subscription} from "rxjs";
import {
  StatisticsDataEntry, StatisticsDataStyledVisualEntry, StatisticsEntity,
  StatisticsEntityBase
} from "../../common/models/entity/statistics-entity.model";
import {StatisticDataType} from "../../common/models/statistic-data.type";
import {StatisticPeriodType} from "../../common/models/statistic-period.type";

export interface CardDataModel<E> extends UxCardTreeNodeModel {
  parent_id?: string;
  child_count?: number;
  expanded?: boolean;
  selectable?: boolean;

  name?: string;

  originalEntity?: E;

  statisticsInProgress?: boolean;
  statisticsSubscription$?: Subscription;

  entityStatistics?: StatisticsEntity[];
  entitySummary?: StatisticsEntityBase<StatisticsDataEntry>;
  entitySummaryVisible?: StatisticsEntityBase<StatisticsDataStyledVisualEntry>;

  defaultStatistics?: StatisticDataType;
  defaultPeriod?: StatisticPeriodType;
  defaultDuration?: number;

  lastValueTimestampStart?: number;
  lastValueTimestampEnd?: number;
}

export class CardDataModelCopier<E> extends UxCardTreeNodeModelCopier {
  public copy(from: CardDataModel<E>, to: CardDataModel<E>) {
    super.copy(from, to);

    to.parent_id = from.parent_id;
    to.child_count = from.child_count;
    to.expanded = from.expanded;
    to.selectable = from.selectable;
    to.name = from.name;
    to.originalEntity = from.originalEntity;
    to.statisticsInProgress = from.statisticsInProgress;
    to.statisticsSubscription$ = from.statisticsSubscription$;
    to.entityStatistics = from.entityStatistics;
    to.entitySummary = from.entitySummary;
    to.entitySummaryVisible = from.entitySummaryVisible;
    to.defaultStatistics = from.defaultStatistics;
    to.defaultPeriod = from.defaultPeriod;
    to.defaultDuration = from.defaultDuration;
    to.lastValueTimestampStart = from.lastValueTimestampStart;
    to.lastValueTimestampEnd = from.lastValueTimestampEnd;
  }
}

