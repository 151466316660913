import {Component, Input} from "@angular/core";
import {
  StatisticsDataEntry, StatisticsDataStyledVisualEntry,
  StatisticsEntityBase
} from "../../../common/models/entity/statistics-entity.model";

@Component({
  selector: "sq-summary-chart",
  templateUrl: "summary-chart.component.html",
  host: {"[class.sq-summary-chart]": "true"}
})
export class SummaryChartComponent {

  @Input()
  public entitySummary: StatisticsEntityBase<StatisticsDataEntry>;

  @Input()
  public entitySummaryVisible: StatisticsEntityBase<StatisticsDataStyledVisualEntry>;

  @Input()
  public mos_aqua: boolean

  @Input()
  public mos_pvqa: boolean

  @Input()
  public r_factor: boolean

  @Input()
  public percents_aqua: boolean

  @Input()
  public mos_network: boolean


  constructor() {
  }
}
