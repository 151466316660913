import {Subscription} from "rxjs";
import {TestEntity} from "../../../common/models/entity/test-entity.model";
import {CardDataModel, CardDataModelCopier} from "../../../components/card-list/card-data.model";
import {DeviceEntity} from "../../../common/models/entity/device-entity.model";
import {DeviceCardModel} from "../../devices/model/device-card.model";

export interface TestCardModel extends CardDataModel<TestEntity> {
  command?: string;
  target?: string;
  cron?: string;

  lastValueSubscription$?: Subscription;
}

export class TestCardModelCopier extends CardDataModelCopier<TestEntity> {

  public copy(from: TestCardModel, to: TestCardModel) {
    super.copy(from, to);

    to.command = from.command;
    to.target = from.target;
    to.cron = from.cron;
    to.lastValueSubscription$ = from.lastValueSubscription$;
  }
}
