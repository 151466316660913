<ng-container *ngIf="_items && _items.length > 0; else emptyBlock">
  <ux-checkbox-group-field *ngIf="showSelectAll"
                           #showSelectAllElement
                           class="ux-multiple-selector-field-popover-content__checkbox-group"
                           (tap)="_onSelectAllValueChange($event)"
                           [(value)]="selectedAllItems"
                           [items]="selectAllItems"
                           [itemLabelKey]="itemCaptionKey">
  </ux-checkbox-group-field>
  <ux-scroll class="_full-height">
    <ux-checkbox-group-field #focusedElement
                             class="ux-multiple-selector-field-popover-content__checkbox-group"
                             [(value)]="value"
                             (valueChange)="valueChange.emit($event)"
                             [items]="_items"
                             [itemLabelKey]="itemCaptionKey">
    </ux-checkbox-group-field>
  </ux-scroll>
</ng-container>

<ng-template #emptyBlock>
    <div *ngIf="emptyMessage"
         class="ux-multiple-selector-field-popover-content__empty-message">
        {{emptyMessage}}
    </div>
</ng-template>
