<sq-tests-card-list [selectAllEnabled]="false"
                    [selectItemEnabled]="false"
                    [loadStatus]="_pageLoadingStatus"
                    [cardLoadStatus]="_testsStatisticsLoading"
                    [entities]="_tests"
                    [entitySummaryVisualStatistics]="_testsSummaryVisualStatistics"
                    [statisticsEnabled]="true"
                    [addEnabled]="false"
                    [editEnabled]="false"
                    [unlinkEnabled]="true"
                    [deleteEnabled]="false"
                    [expandEnabled]="false"
                    [pagingEnabled]="false"
                    [treeEnabled]="false"
                    (onUnlinkCard)="_onUnlinkCard($event)"
                    (onChartCard)="_onChartCard($event)"
                    (onPageChange)="_onPageChange($event)"
                    (onPageSizeChange)="_onPageSizeChange($event)"
>
</sq-tests-card-list>

<sq-confirm-popup #unlinkTestConfirmPopup
                   (onCancelled)="_onUnlinkCancel($event)"
                   (onAccepted)="_onUnlinkAccept($event)"
                   [acceptCaption]="'Unlink'"
                   [cancelCaption]="'Cancel'"
                   [title]="'Unlink test'"
                   [message]="'Are you really want to unlink test \'' + _unlinkTestName + '\' ?'"
>
</sq-confirm-popup>
