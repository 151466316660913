
import {StatisticPeriodType} from "../../../common/models/statistic-period.type";

export class ChartPeriodModel {

  public static readonly PERIOD_HOURS: StatisticPeriodType = 'hour';
  public static readonly PERIOD_DAYS: StatisticPeriodType = 'day';
  public static readonly PERIOD_MONTHS: StatisticPeriodType = 'month';

}
