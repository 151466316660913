import {NgModule} from "@angular/core";
import {UxRangeDateFieldComponent} from "./range-date-field.component";
import {SharedModule} from "../../../../shared/shared.module";
import {UxPopoverModule} from "../../../popover/popover.module";
import {FormsModule} from "@angular/forms";

@NgModule({
    imports: [SharedModule, FormsModule, UxPopoverModule],
    exports: [UxRangeDateFieldComponent],
    declarations: [UxRangeDateFieldComponent]
})
export class UxRangeDateFieldModule {}
