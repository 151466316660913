import {NgModule} from "@angular/core";
import {SharedModule} from "../../../shared/shared.module";
import {FormsModule} from "@angular/forms";
import {UxReferenceFieldComponent} from "./reference-field.component";
import {UxRoundDotsLoaderModule} from "../../loader/round-dots-loader/round-dots-loader.module";
import {UxFieldSuggestionPopupModule} from "../suggestion-popup/field-suggestion-popup.module";
import {UxScrollModule} from "../../scroll/scroll.module";
import {UxReferenceFieldToolbarComponent} from "./toolbar/reference-field-toolbar.component";
import {UxPopoverModule} from "../../popover/popover.module";
import {UxMenuModule} from "../../menu/menu.module";
import {UxButtonModule} from "../../button/button.module";

const COMPONENTS = [
    UxReferenceFieldComponent,
    UxReferenceFieldToolbarComponent];

@NgModule({
    imports: [
        SharedModule,
        UxRoundDotsLoaderModule,
        UxFieldSuggestionPopupModule,
        UxScrollModule,
        UxPopoverModule,
        UxMenuModule,
        UxButtonModule,
        FormsModule
    ],
    exports: COMPONENTS,
    declarations: COMPONENTS
})
export class UxReferenceFieldModule {
}
