<div class="ux-multiple-field__value-item"
     *ngFor="let valueItem of value; trackBy: _trackByFn; last as isLast;"
     [ngClass]="{_last: isLast}">

    <ng-template [ngTemplateOutlet]="valueItemTemplate || defaultValueItemTemplate"
                 [ngTemplateOutletContext]="{item: valueItem}"
    ></ng-template>

    <i class="ux-multiple-field__value-item-remove"
       [ngClass]="{_disabled:disabled}"
       (tap)="!disabled && _removeValueItem(valueItem)"
    >
        <svg viewBox="0 0 20 20"
             class="ux-multiple-field__value-item-remove-icon">
            <path
                d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/>
        </svg>
    </i>
</div>

<ux-button #addButton
           class="ux-multiple-field__button"
           [disabled]="disabled"
           (onClick)="_onAddButtonClick()"
           (keydown)="_onKeyDown($event)"
>
    <svg #customIcon
         viewBox="0 0 20 20"
         class="ux-multiple-field__button-icon">
        <path d="M16,10c0,0.553-0.048,1-0.601,1H11v4.399C11,15.951,10.553,16,10,16c-0.553,0-1-0.049-1-0.601V11H4.601
		C4.049,11,4,10.553,4,10c0-0.553,0.049-1,0.601-1H9V4.601C9,4.048,9.447,4,10,4c0.553,0,1,0.048,1,0.601V9h4.399
		C15.952,9,16,9.447,16,10z"></path>
    </svg>
</ux-button>

<ng-template #defaultValueItemTemplate let-item="item">
    <span class="ux-multiple-field__value-item-caption {{item.styleClass}}"
          title="{{item[itemCaptionKey]}}">
        {{item[itemCaptionKey]}}
    </span>
</ng-template>


<ux-popover class="ux-multiple-field__popover _white _no-padding"
            [attachToEl]="_addButton?.nativeElement"
            [attachPosition]="'bottom center'"
            [(visible)]="popoverVisible"
            (visibleChange)="_changePopoverVisible($event)"
            [closable]="false"
            [closeOnOutsideClick]="true"
            [containerEl]="popoverContainer"
            [template]="popoverContentTemplate ? popoverContentTemplate : defaultPopoverContentTemplate">
</ux-popover>


<ng-template #defaultPopoverContentTemplate>
    <ux-multiple-selector-field-popover-content
        [items]="items"
        [(value)]="value"
        [showSelectAll]="showSelectAll"
        [selectAllCaption]="selectAllCaption"
        [itemCaptionKey]="itemCaptionKey">
    </ux-multiple-selector-field-popover-content>
</ng-template>
