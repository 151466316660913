<sq-overlay-popup [title]="_creatingMode ? 'Create new device' : 'Device details'"
                  [visible]="_visible"
                  [contentTemplate]="contentTemplate"
                  [controlsTemplate]="controlsTemplate"
                  [statusEnable]="_operationErrorText"
                  [statusTemplate]="statusTemplate"
                  (onClose)="_onCloseClick()"
>

</sq-overlay-popup>


<ng-template #contentTemplate>
  <form novalidate
        #deviceEditForm
        class="sq-device-edit__form"
        [formGroup]="_formGroup">
    <ux-tab-panel [selectedTabIndex]="_selectedTabIndex"
                  (onChange)="onTabChange($event)"
    >
      <ux-tab-panel-item [title]="'Device data'">
        <ux-label [text]="'Name'"
                  [required]="true"
        >
          <ux-text-field #deviceName
                         formControlName="deviceName"
                         [(value)]="_deviceModel.instance"
                         (onValueChange)="_onDeviceNameChange($event)"
                         [placeholder]="'Enter device name'"
                         [uxTooltip]="!deviceName.focused && !_formGroup.controls.deviceName.untouched && _getErrorText('deviceName')"
                         uxTooltipAppendTo="target"
                         [ngClass]="{
                          '_error': !deviceName.focused && !_formGroup.controls.deviceName.untouched && _getErrorText('deviceName')
                       }"
          >
          </ux-text-field>
        </ux-label>

        <ux-label [text]="'Type'"
                  [required]="true"
        >
          <ux-dropdown-field #deviceType
                             formControlName="deviceType"
                             [items]=_typesItemsModel
                             [(value)]="_typesItemsValue"
                             (onValueChange)="_onDeviceTypeChange($event)"
                             [itemLabelKey]="'label'"
                             [placeholder]="'Select device type'"
                             [opened]="false"
                             [mobile]="false"
          >
          </ux-dropdown-field>
        </ux-label>

        <ux-label [text]="_deviceNumberLabel"
                  [required]="true"
        >
          <ux-text-field #deviceNumber
                         formControlName="deviceNumber"
                         [value]="_deviceModel.number"
                         (onValueChange)="_onDeviceNumberChange($event)"
                         [placeholder]="'Enter ' + _deviceNumberLabel"
                         [uxTooltip]="!deviceNumber.focused && !_formGroup.controls.deviceNumber.untouched && _getErrorText('deviceNumber')"
                         uxTooltipAppendTo="target"
                         [ngClass]="{
                          '_error': !deviceNumber.focused && !_formGroup.controls.deviceNumber.untouched && _getErrorText('deviceNumber')
                       }"
          >
          </ux-text-field>
        </ux-label>

        <ux-label [text]="'Direction'"
                  [required]="true">
          <ux-radio-group-field #directionRadio
                                formControlName="deviceDirection"
                                [(value)]="_deviceDirectionValue"
                                [items]="_deviceDirectionItems"
                                [itemLabelKey]="'label'"
          >
          </ux-radio-group-field>
        </ux-label>

        <ux-label [text]="'Audio'">
          <ux-reference-field #audioReference
                              [disabled]="_deviceDirectionValue?.name !== 'answerer'"
                              [(value)]="_selectedAudio"
                              [items]="_availableAudio"
                              [showDropdownButton]="true"
                              [multiple]="false"
                              [opened]="false"
                              [optionsPopoverVisible]="false"
                              [onlyExistingItems]="true"
                              [excludeCharacters]="''"
                              [isLoading]="false"
                              [delay]="300"
                              [placeholder]="'Select file'"
                              [itemCaptionKey]="'caption'"
                              [emptyMessage]="'Nothing found'"
                              [maxListHeight]="'200px'"
                              [listStyleClass]="''"
                              [listContainer]="''"
          >
          </ux-reference-field>
        </ux-label>
      </ux-tab-panel-item>
      <ux-tab-panel-item [title]="'SIP configuration'"
                         [disabled]="_typesItemsValue?.value !== 'sip'"
      >

        <ux-label [text]="'SIP domain'">
          <ux-text-field #sipDomain
                         [value]="_attrValuesMap.get('sip_domain')"
                         (onValueChange)="_onSipAttrChanged('sip_domain', $event)"
                         [placeholder]="'Enter SIP domain address'"
          >
          </ux-text-field>
        </ux-label>

        <ux-label [text]="'SIP username'">
          <ux-text-field #sipUsername
                         [value]="_attrValuesMap.get('sip_username')"
                         (onValueChange)="_onSipAttrChanged('sip_username', $event)"
                         [placeholder]="'Enter SIP username'"
          >
          </ux-text-field>
        </ux-label>

        <ux-label [text]="'SIP password'">
          <ux-text-field #sipPassword
                         [value]="_attrValuesMap.get('sip_password')"
                         (onValueChange)="_onSipAttrChanged('sip_password', $event)"
                         [placeholder]="'Enter SIP password'"
          >
          </ux-text-field>
        </ux-label>

        <ux-label [text]="'STUN server'">
          <ux-text-field #stunServer
                         [value]="_attrValuesMap.get('stun_server')"
                         (onValueChange)="_onSipAttrChanged('stun_server', $event)"
                         [placeholder]="'Enter STUN server'"
          >
          </ux-text-field>
        </ux-label>

        <ux-label [text]="'Transport '"
                  [required]="true"
        >
          <ux-dropdown-field #sipTransport
                             [items]=_sipTransportItemsModel
                             [(value)]="_sipTransportItemsValue"
                             (onValueChange)="_onSipAttrChanged('transport', $event)"
                             [itemLabelKey]="'label'"
                             [placeholder]="'Select device type'"
                             [opened]="false"
                             [mobile]="false"
          >
          </ux-dropdown-field>
        </ux-label>
      </ux-tab-panel-item>
      <ux-tab-panel-item [title]="'Advanced'">
        <ux-label [text]="'Attributes'">
          <ux-memo-field #jsonAttributes
                         [(value)]="_jsonAttributes"
                         (onSubmitValueChange)="_onAttributesChanged($event)"
                         [placeholder]="'Enter JSON attributes parameter'">
          </ux-memo-field>
        </ux-label>
      </ux-tab-panel-item>
    </ux-tab-panel>
  </form>
</ng-template>

<ng-template #controlsTemplate>
  <ux-button *ngIf="!creatingMode"
             [disabled]="_deviceDirectionValue?.name !== 'caller'"
             (tap)="_onLinkedTest()"
             [text]="'Linked tests'"
  >
  </ux-button>
  <ux-button (tap)="_onEditConfirm()"
             [text]="creatingMode ? 'Create' : 'Update'"
             [kind]="'primary'"
             [disabled]="!_formGroup.valid"
  >
  </ux-button>
</ng-template>


<ng-template #statusTemplate>
  <ux-label [text]="_operationErrorText"
            [ngClass]="'_error'"
  >
  </ux-label>
</ng-template>

<sq-device-linked-test #linkedTestsPopup>
</sq-device-linked-test>
