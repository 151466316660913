import {Directive, EventEmitter, HostListener, Input, Output} from "@angular/core";

@Directive({
    selector: "[uxDraggable]",
    exportAs: "uxDraggable",
    host: {
        "[draggable]": "true"
    }
})
export class UxDraggableDirective {
    @Input("uxDraggable")
    public scope: string;

    @Input()
    public dragEffect: string;

    @Input()
    public dragHandle: string;

    @Output()
    public onDragStart: EventEmitter<any> = new EventEmitter<any>();

    @Output()
    public onDragEnd: EventEmitter<any> = new EventEmitter<any>();

    @Output()
    public onDrag: EventEmitter<any> = new EventEmitter<any>();

    private handle: HTMLElement;

    @HostListener("dragstart", ["$event"])
    private dragStart(event: any): void {
        if (this.allowDrag()) {
            if (this.dragEffect) {
                event.dataTransfer.effectAllowed = this.dragEffect;
            }
            event.dataTransfer.setData("text", this.scope);

            this.onDragStart.emit(event);
        } else {
            event.preventDefault();
        }
    }

    @HostListener("dragend", ["$event"])
    private dragEnd(event: any): void {
        this.onDragEnd.emit(event);
    }

    @HostListener("mouseover", ["$event"])
    private mouseover(event: MouseEvent): void {
        this.handle = event.target as HTMLElement;
    }

    @HostListener("mouseleave")
    private mouseleave(): void {
        this.handle = null;
    }

    private allowDrag(): boolean {
        if (this.dragHandle && this.handle) {
            return this.handle.matches(this.dragHandle);
        } else {
            return true;
        }
    }
}
