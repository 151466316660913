import {
    AfterViewInit,
    Component,
    ContentChild,
    ElementRef,
    HostBinding,
    Input,
    OnDestroy,
    QueryList,
    ViewChild,
    ViewChildren
} from "@angular/core";
import {UxDomHelper} from "../../shared/dom/dom-helper";


export interface UxRouteTabItem {
    id?: string;
    title: string;
    url: string;
    styleClass?: string;
}


let resizeEndId;


@Component({
    selector: "ux-route-tab-panel",
    templateUrl: "./route-tab-panel.component.html",
    host: {"[class.ux-route-tab-panel]": "true"}
})
export class UxRouteTabPanelComponent implements AfterViewInit, OnDestroy {

    @Input()
    public items: UxRouteTabItem[] = [];

    @HostBinding("class._mobile")
    @Input()
    public mobile: boolean = false;


    @ViewChild("dropdown", { static: true })
    private dropdownRef: ElementRef;

    @ViewChildren("tabItem")
    private tabItems: QueryList<ElementRef>;

    /** @internal */
    public _activeDropdown: boolean = false;

    /** @internal */
    public _customDropdownIcon: boolean = false;

    @ContentChild("customDropdownIcon", /* TODO: add static flag */ {static: true})
    private set customDropdownIcon(iconElement: ElementRef) {
        this._customDropdownIcon = !!iconElement;
    }


    private element: HTMLElement;
    private dropdown: HTMLElement;
    private tabItemsWidth: number = 0;
    private tabItemsArr = [];
    private initDropdownElementIndex: number = 0;
    private lastDropdownElementIndex: number = 0;
    private resizeEndId: number = 0;
    private requestAnimationFrameUpdateDropdownId: number = 0;
    private requestAnimationFrameUpdateDropdownNextId: number = 0;


    constructor(private elementRef: ElementRef) {
    }

    public ngAfterViewInit(): void {
        this.element = this.elementRef.nativeElement;

        this.updateDropdown();
        this.updateTabWidth(this.tabItems);
    }

    public ngOnDestroy(): void {
        this.removeListeners();
        clearTimeout(this.resizeEndId);
        cancelAnimationFrame(this.requestAnimationFrameUpdateDropdownId);
        cancelAnimationFrame(this.requestAnimationFrameUpdateDropdownNextId);
    }

    public update(): void {
        this.onResize();
    }

    private updateDropdown(): void {
        this.dropdown = this.dropdownRef.nativeElement;

        this.tabItems.changes.subscribe((queryList: QueryList<ElementRef>) => this.updateTabWidth(queryList));

        window.addEventListener("resize", this.onResize);
    }

    private updateTabWidth(queryList: QueryList<ElementRef>): void {
        if (!queryList) {
            return;
        }

        this.tabItemsWidth = 0;
        this.tabItemsArr = [];

        let list: ElementRef[] = queryList.toArray();

        list.forEach((itemRef: ElementRef, index: number) => {
            let node = itemRef.nativeElement,
                w = UxDomHelper.getOuterWidth(node);

            this.tabItemsArr.push({
                node,
                w
            });

            this.tabItemsWidth += w;
        });

        this.initDropdownElementIndex = this.lastDropdownElementIndex = list.length - 1;

        this.onResize();
    }

    /** @internal */
    public _onDropdownIconClick(event: any): void {
        this._activeDropdown = !this._activeDropdown;

        if (this._activeDropdown) {
            document.addEventListener("click", this.onClickOutside);
        }
    }

    /** @internal */
    public _onTabPanelLinkTap(): void {
        this.closeDropdown();
    }

    private onClickOutside = (e: Event) => {
        const host = this.element;

        if (!host.contains(e.target as Node)) {
            this.closeDropdown();
        }
    };

    private closeDropdown(): void {
        this._activeDropdown = false;
        document.removeEventListener("click", this.onClickOutside);
    }

    private removeListeners(): void {
        window.removeEventListener("resize", this.onResize);
        document.removeEventListener("click", this.onClickOutside);
    }

    private onResize = () => {
        clearTimeout(this.resizeEndId);
        this.resizeEndId = null;
        this.resizeEndId = window.setTimeout(() => {

            this.updateDropdownElements();

        }, 100);
    };

    private updateDropdownElements(): void {
        let offsetWidth = this.element.offsetWidth - 50; // 50 - offset for dots visibility

        if (offsetWidth < this.tabItemsWidth) {

            if (!this.items[this.lastDropdownElementIndex]) {
                return;
            }

            this.items[this.lastDropdownElementIndex]["dropdown"] = true;
            this.tabItemsWidth -= this.tabItemsArr[this.lastDropdownElementIndex].w;
            --this.lastDropdownElementIndex;

            this.requestAnimationFrameUpdateDropdownId = window.requestAnimationFrame(() => {
                this.updateDropdownElements();
            });

            this.updateDropdownElement();

        } else if (
            this.lastDropdownElementIndex + 1 <= this.initDropdownElementIndex &&
            offsetWidth > this.tabItemsWidth + this.tabItemsArr[this.lastDropdownElementIndex + 1].w
        ) {

            this.tabItemsWidth += this.tabItemsArr[this.lastDropdownElementIndex + 1].w;
            this.items[this.lastDropdownElementIndex + 1]["dropdown"] = false;
            ++this.lastDropdownElementIndex;

            this.requestAnimationFrameUpdateDropdownNextId = window.requestAnimationFrame(() => {
                this.updateDropdownElements();
            });

            this.updateDropdownElement();
        }
    }

    private updateDropdownElement(): void {
        if (!this.dropdown) {
            return;
        }

        if (this.lastDropdownElementIndex !== this.initDropdownElementIndex) {
            this.dropdown.classList.add("_visible");
        } else {
            this.dropdown.classList.remove("_visible");
        }

        if (this.lastDropdownElementIndex < 0) {
            this.dropdown.classList.add("_right");
        } else {
            this.dropdown.classList.remove("_right");
        }
    }

    /** @internal */
    public _trackByFn(index: number, item: UxRouteTabItem): string {
        return item && item.id;
    }
}


