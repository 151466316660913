import {NgModule} from "@angular/core";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {UxFormFieldComponent} from "./form-field.component";
import {SharedModule} from "../../../shared/shared.module";
import {UxTooltipModule} from "../../tooltip/tooltip.module";

@NgModule({
    imports: [
        SharedModule,
        FormsModule,
        ReactiveFormsModule,
        UxTooltipModule],
    exports: [UxFormFieldComponent],
    declarations: [UxFormFieldComponent]
})
export class UxFormFieldModule {
}