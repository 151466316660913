import {
  Component,
  Input,
  ViewChild
} from "@angular/core";
import {DeviceEntity} from "../../../common/models/entity/device-entity.model";
import {DeviceLinkNewTestPopupComponent} from "./device-link-new-test-popup.component";
import {PopupComponent} from "../../../components/base/popup.component";
import {DeviceLinkedTestListComponent} from "./device-linked-test-list.component";

@Component({
  selector: "sq-device-linked-test",
  templateUrl: "device-linked-test.component.html",
  host: {"[class.sq-device-linked-test]": "true"}
})
export class DeviceLinkedTestComponent extends PopupComponent<DeviceEntity> {

  @ViewChild("linkedTestsList")
  linkedTestsList: DeviceLinkedTestListComponent;

  @ViewChild("linkNewTestsPopup", { static: true })
  linkNewTestsPopup: DeviceLinkNewTestPopupComponent;

  _device: DeviceEntity;
  @Input()
  public set device(value: DeviceEntity) {
    this._device = value;
  }

  public get device(): DeviceEntity {
    return this._device;
  }

  constructor() {
    super();
  }

  _onClose(): void {
    this._device = undefined;
    super._onCloseClick();
  }

  _onLinkNew(): void {
    this.linkNewTestsPopup.device = this.device;
    this.linkNewTestsPopup.show();
  }

  _onLinkNewClosed() {
    this.linkedTestsList.refresh();
  }
}
