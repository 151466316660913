import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {ConfigComponent} from "./base/config.component";
import {PagedComponent} from "./base/paged.component";
import {PopupComponent} from "./base/popup.component";
import {ConfirmPopupComponent} from "./confirm-popup/confirm-popup.component";
import {UxOverlayModule} from "../../ux-lib/components/overlay/overlay.module";
import {UxButtonModule} from "../../ux-lib/components/button/button.module";

@NgModule({
  imports: [
    CommonModule,
    UxOverlayModule,
    UxButtonModule
  ],
  declarations: [
    ConfigComponent,
    PagedComponent,
    PopupComponent,
    ConfirmPopupComponent
  ],
  exports: [
    ConfigComponent,
    PagedComponent,
    PopupComponent,
    ConfirmPopupComponent
  ]
})
export class SharedModule {
}
