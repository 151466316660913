import {Injectable} from "@angular/core";
import {SHA256} from "crypto-js";

@Injectable()
export class CryptoService {

  encrypt(message: string): any {
    return SHA256(message);
  }
}
