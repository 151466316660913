import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ContentChild,
    ElementRef,
    EventEmitter,
    HostBinding,
    Input,
    OnInit,
    Output,
    QueryList,
    TemplateRef,
    ViewChild,
    ViewChildren
} from "@angular/core";
import {UxAbstractFieldComponent} from "../abstract-field.component";
import {UxFieldSuggestionPopupComponent} from "../suggestion-popup/field-suggestion-popup.component";
import {NG_VALUE_ACCESSOR} from "@angular/forms";
import {UxPropertyConverter} from "../../../common/decorator/ux-property-converter";
import {UxPropertyHandler} from "../../../common/decorator/ux-property-handler";

/* deprecated use instead UxDropdownListItem. todo remove in 2.0 version */
export interface DropdownListItem {
    id?: string;
    label?: string;
    disabled?: boolean;
    value?: any;
}

export interface UxDropdownListItem extends DropdownListItem {
}

@Component({
    selector: "ux-dropdown-field",
    templateUrl: "./dropdown-field.component.html",
    host: {"[class.ux-dropdown-field]": "true"},
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: UxDropdownFieldComponent,
            multi: true
        }
    ]
})
export class UxDropdownFieldComponent extends UxAbstractFieldComponent<UxDropdownListItem> implements AfterViewInit, OnInit {
    @HostBinding("class.taDropdownList")
    public taDropdownListHostClass: boolean = true;

    @Input()
    public items: UxDropdownListItem[] = [];

    @UxPropertyConverter("string", "label")
    @Input()
    public itemLabelKey: string;

    @UxPropertyConverter("string", "200px")
    @Input()
    public maxListHeight: string;

    @Input()
    public autofocus: boolean = false;

    @Input()
    public placeholder: string = "";

    @UxPropertyHandler({
        afterChange: afterChangeListContainer
    })
    @Input()
    public listContainer: ElementRef | string;

    @Input()
    public listStyleClass: string;

    @Input()
    public itemTemplate: TemplateRef<{ item: UxDropdownListItem }>;

    @Input()
    public valueTemplate: TemplateRef<{ item: UxDropdownListItem }>;


    private _emptyContent: TemplateRef<any> | string = "No items";

    @Input()
    public set emptyContent(value: TemplateRef<any> | string) {
        this._isTemplateString = typeof value === "string";
        this._emptyContent = value;
    }

    public get emptyContent(): TemplateRef<any> | string {
        return this._emptyContent;
    }

    /** @internal */
    public _isTemplateString: boolean = true;

    @HostBinding("class._opened")
    /** @internal */
    public _listVisible: boolean = false;

    @Output()
    public openedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Output()
    public onPopupClick: EventEmitter<Event> = new EventEmitter<Event>();


    @ViewChild("dropdownLabel")
    public _dropdownLabelRef: ElementRef;

    @ViewChild("suggestionPopup")
    private suggestionPopup: UxFieldSuggestionPopupComponent;

    @ViewChild("list")
    private listElement: ElementRef;

    @ViewChildren("items")
    private itemsList: QueryList<ElementRef>;

    @ViewChild("input")
    private inputElement: ElementRef;

    @ContentChild("customIcon", /* TODO: add static flag */ {static: true})
    private set customIcon(iconElement: ElementRef) {
        this._customIcon = !!iconElement;
    }

    // TODO Deprecated. Remove in 3.0
    @ContentChild("noInput")
    private set noInput(noInputElement: ElementRef) {
        this._noInput = !!noInputElement;
    }

    private initedView: boolean = false;

    @Input()
    public set opened(value: boolean) {
        let self = this;
        if (!self.mobile && value !== self.opened) {
            self._listVisible = value;
            self.openedChange.emit(value);

            if (value && self.initedView) {
                self._scrollPopupToSelectedItem();
            }
        }
    }

    public get opened(): boolean {
        return this._listVisible;
    }

    /** @internal */
    public _customIcon: boolean = false;

    // TODO Deprecated. Remove in 3.0
    /** @internal */
    public _noInput: boolean = false;

    private hotSearchValue: string = "";
    private hotSearchTimer: number;

    constructor(/** @internal */ public _elementRef: ElementRef, private cdr: ChangeDetectorRef) {
        super();
    }

    public ngOnInit(): void {
        this.listContainer = this.listContainer ? this.listContainer : this._elementRef;
    }

    public ngAfterViewInit(): void {
        if (this.autofocus) {
            this.focus();
        }

        this.initedView = true;
        this.cdr.detectChanges();
    }

    public focus(): void {
        setTimeout(() => {
            this.inputElement.nativeElement.focus();
        }, 0);
    }

    /** @internal */
    public _onPopupClick(event: Event): void {
        this.onPopupClick.emit(event);
    }

    /** @internal */
    public _onBlur(event: Event): void {
        let self = this;
        let relatedTarget = (event as FocusEvent).relatedTarget,
            listElement = self.listElement && self.listElement.nativeElement;

        if ((!self.mobile && (!relatedTarget
            || relatedTarget
            && !listElement.contains(relatedTarget)
            && !self._dropdownLabelRef.nativeElement.contains(relatedTarget)
            && !(self._elementRef.nativeElement.querySelector(".ux-scroll") && self._elementRef.nativeElement.querySelector(".ux-scroll").contains(relatedTarget)
            ))
            || (self.mobile && !self._dropdownLabelRef.nativeElement.contains(relatedTarget)))
        ) {
            let changed = this.focused;
            self.focused = false;
            if (changed) {
                self.onBlur.emit(event);
            }
        } else {
            self.inputElement.nativeElement.focus();
        }
    }

    /** @internal */
    public _onItemClick(item: UxDropdownListItem, event: MouseEvent): void {
        let self = this;

        if (item.disabled) {
            return;
        }

        self.selectItem(item);
        self.opened = false;
    }

    /** @internal */
    public _onOpen(): void {
        if (this.itemsList) {
            this.itemsList.forEach((item: ElementRef) => {
                let element = item.nativeElement.querySelector(".ux-dropdown-field__item-value");

                if (element.offsetWidth < element.scrollWidth) {
                    element.setAttribute("title", element.innerText);
                } else {
                    element.removeAttribute("title");
                }
            });
        }
    }

    /** @internal */
    public _onLabelClick(event: MouseEvent): void {
        let self = this;
        if (self.disabled) {
            return;
        }

        self.inputElement.nativeElement.focus();
        if (!self.mobile) {
            self.opened = !self.opened;
        }
    }

    /** @internal */
    public _scrollPopupToSelectedItem(index?: number): void {
        let self = this;

        if (index < self.items.length && index >= 0) {
            self.suggestionPopup.scrollToElement(self.itemsList.toArray()[index].nativeElement);
        }

        if (index === undefined) {
            setTimeout(() => {
                let currentIndex = self.findItemIndex(self.value, self.items);

                if (currentIndex > -1) {
                    self.suggestionPopup.scrollToElement(self.itemsList.toArray()[self.findItemIndex(self.value, self.items)].nativeElement);
                }
            });
        }
    }

    /** @internal */
    public _onKeyDown(event: KeyboardEvent): void {
        let self = this;
        let selectedItemIndex = self.value ? self.findItemIndex(self.value, self.items) : -1;
        switch (event.which) {
            // down
            case 40:
                if (!self.opened && event.altKey) {
                    self.opened = true;
                }
                else {
                    if (selectedItemIndex !== -1) {
                        let nextItemIndex = selectedItemIndex + 1;
                        if (nextItemIndex !== (self.items.length)) {
                            self.value = self.items[nextItemIndex];
                            self.selectItem(self.value);
                            self._scrollPopupToSelectedItem(nextItemIndex);
                        }
                    }
                    else if (self.items) {
                        self.value = self.items[0];
                    }
                }
                event.preventDefault();
                break;

            // up
            case 38:
                if (selectedItemIndex > 0) {
                    let prevItemIndex = selectedItemIndex - 1;
                    self.value = self.items[prevItemIndex];
                    self.selectItem(self.value);
                    self._scrollPopupToSelectedItem(prevItemIndex);
                }
                event.preventDefault();
                break;

            // space
            case 32:
                self.opened = !self.opened;
                event.preventDefault();
                break;

            // enter
            case 13:
                self.opened = false;
                event.preventDefault();
                break;

            // escape and tab
            case 27:
            case 9:
                self.opened = false;
                break;
        }

        if (event.which > 40) {// search symbols in the list
            self.searchItemByHotKey(event);
        }
    }

    private searchItemByHotKey(event: KeyboardEvent): void {
        let self = this;

        self.hotSearchValue = (self.hotSearchTimer ? self.hotSearchValue : "") + event.key;

        self.hotSearchTimer && window.clearTimeout(self.hotSearchTimer);
        self.hotSearchTimer = window.setTimeout(() => {
            self.hotSearchTimer = undefined;
        }, 1000);

        self.items && self.items.some((item, index) => {
            let result = item[self.itemLabelKey] && item[self.itemLabelKey].toLowerCase().startsWith(self.hotSearchValue.toLowerCase());
            result && self._scrollPopupToSelectedItem(index);
            return result;
        });
    }

    protected getDefaultValue(): UxDropdownListItem {
        return undefined;
    }

    protected getValueConverter(): { (value: any): any } {
        return (value: any): Object => {
            if (typeof value === "object") {
                return value;
            }
            try {
                return JSON.parse(value);
            } catch (error) {
                throw new Error(`Dropdown: unable to parse value ${value}`);
            }
        };
    }

    private selectItem(item: UxDropdownListItem): void {
        this.value = item;
    }

    /** @internal */
    public _modelChange(value: any): void {
        let self = this,
            item;
        self.items && self.items.some((itemModel) => {
            let result = itemModel.value === value;
            if (result) {
                item = itemModel;
            }
            return result;
        });

        item && self.selectItem(item);
    }

    private findItemIndex(value: UxDropdownListItem, items: UxDropdownListItem[]): number {
        return items.indexOf(value);
    }

    /** @internal */
    public _trackByFn(index: number, item: UxDropdownListItem): string {
        return item && item.id;
    }
}

/* Helpers */
export function afterChangeListContainer(newValue: ElementRef | string, oldValue: ElementRef | string): void {
    if (!newValue) { // Handle empty string
        this.listContainer = this._elementRef;
    }
}
