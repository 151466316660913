import { EventEmitter, HostBinding, Input, Output, Directive } from "@angular/core";
import {ControlValueAccessor} from "@angular/forms";
import {UxPropertyConverter} from "../../common/decorator/ux-property-converter";
import {UxPropertyHandler} from "../../common/decorator/ux-property-handler";

@Directive()
export abstract class UxAbstractFieldComponent<T> implements ControlValueAccessor {

  @HostBinding("class._disabled")
  @UxPropertyConverter("boolean", false)
  @Input()
  public disabled: boolean;

  @UxPropertyConverter("custom", undefined, {
    convert: convertValue
  })
  @UxPropertyHandler({
    afterChange: afterChangeValue
  })
  @Input()
  public value: T;

  @Input()
  public maxLength: number;

  @HostBinding("class._mobile")
  @Input()
  public mobile: boolean = false;

  @Output()
  public onValueChange = new EventEmitter<UxValueChangeEvent<T>>();

  @Output()
  public valueChange = new EventEmitter<any>();

  @Output()
  public onBlur = new EventEmitter<Event>();

  @Output()
  public onFocus = new EventEmitter<Event>();

  @HostBinding('class._focused')
  public focused: boolean = false;

  public propagateChange: Function;
  public propagateTouch: Function;

  public registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.propagateTouch = fn;
  }

  public writeValue(value: any): void {
    this.value = value;
  }

  /**
   * Function will be used for null or undefined value. Should return not null or undefined value.
   */
  protected abstract getDefaultValue(): T;

  /**
   * Function will be used to convert input value for correct value
   */
  protected abstract getValueConverter(): { (value: any): T };

  /** @internal */
  public _onBlur(event: Event): void {
    let changed = this.focused;
    this.focused = false;
    if (changed) {
      this.onBlur.emit(event);
    }
  }

  /** @internal */
  public _onFocus(event: Event): void {
    let changed = !this.focused;
    this.focused = true;
    if (changed) {
      this.onFocus.emit(event);
    }
  }

  /** @internal */
  public _onValueChange(event: UxValueChangeEvent<T>): void {
    if (event.oldValue !== event.newValue) {
      this.onValueChange.emit(event);
      this.valueChange.emit(event.newValue);
      this.propagateChange && this.propagateChange(event.newValue);
      this.propagateChange && this.propagateTouch(event.newValue);
    }
  }
}

/*Helpers*/
export interface UxValueChangeEvent<T> {
  oldValue: T;
  newValue: T;
}

export function convertValue(value: any) {
  if (value === undefined || value === null) {
    return this.getDefaultValue();
  } else {
    return this.getValueConverter().apply(this, [value]);
  }
}

export function afterChangeValue<T>(newValue: T, oldValue: T) {
  this._onValueChange({oldValue: oldValue, newValue: newValue});
}
