<div class="sevana-qualtest-inner">
  <ux-header [links]="_menuItems"
             *ngIf="_authorized"
  >
    <div class="sevana-qualtest-inner-header">
      <div class="sevana-qualtest-inner-header-item _username">
        Hello, {{_username}}
      </div>
      <div class="sevana-qualtest-inner-header-item _logout"
           (tap)="_logout()"
      >
        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
             width="24" height="24"
             viewBox="0 0 172 172"
             style=" fill:#000000;"><g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><path d="M0,172v-172h172v172z" fill="none"></path><g fill="#ffffff"><path d="M2.6875,-0.1075c-1.65281,0.37625 -2.82187,1.85438 -2.795,3.5475v165.12c0.01344,1.94844 1.59906,3.53406 3.5475,3.5475h103.2c1.94844,-0.01344 3.53406,-1.59906 3.5475,-3.5475v-34.4h-7.095v30.8525h-96.105v-158.025h96.105v30.8525h7.095v-34.4c-0.01344,-1.94844 -1.59906,-3.53406 -3.5475,-3.5475h-103.2c-0.25531,-0.02687 -0.49719,-0.02687 -0.7525,0zM133.8375,51.9225c-1.29,0.22844 -2.32469,1.16906 -2.6875,2.41875c-0.36281,1.26313 0.01344,2.60688 0.9675,3.49375l24.6175,24.725h-101.695c-0.1075,0 -0.215,0 -0.3225,0c-1.89469,0.09406 -3.37281,1.70656 -3.27875,3.60125c0.09406,1.89469 1.70656,3.37281 3.60125,3.27875h101.695l-24.6175,24.725c-1.02125,0.83313 -1.49156,2.16344 -1.19594,3.45344c0.29562,1.27656 1.30344,2.28438 2.58,2.58c1.29,0.29563 2.62031,-0.17469 3.45344,-1.19594l30.6375,-30.53l2.365,-2.4725l-2.365,-2.4725l-30.6375,-30.53c-0.71219,-0.76594 -1.74687,-1.15562 -2.795,-1.075c-0.1075,0 -0.215,0 -0.3225,0z"></path></g></g></svg>
      </div>
    </div>
  </ux-header>
  <ux-notification *ngIf="_notification"
                   [type]="_notification?.type"
                   [caption]="_notification?.caption"
                   [content]="_notification?.content"
                   [timeout]="_notification?.timeout"
                   [closable]="_notification?.closable"
                   [visible]="_notification?.visible"
                   (onClose)="_onNotificationClose($event)"
  >
  </ux-notification>

  <div class="sevana-qualtest-inner-content"
       [ngClass]="{_login: !_authorized}"
  >
    <router-outlet></router-outlet>
  </div>
</div>
