
import {AppConfigService} from "../../common/services/app-config.service";
import {Component} from "@angular/core";
import {ConfigComponent} from "./config.component";
import {Progress} from "../../common/models/progress.model";
import {UxPageChangeEvent} from "../../../ux-lib/components/paging/paging.component";
import {CountResult} from "../../common/models/result.type";

@Component({
  template: ''
})
export class PagedComponent extends ConfigComponent {
  _pageLoadingStatus: Progress = 'loading';

  constructor(protected configService: AppConfigService) {
    super(configService);
  }

  public loadInitialData() {
    super.loadInitialData();

    this.loadPageData(this.pagingConfig.currentPage, this.pagingConfig.pageSize)
  }

  public refresh(): void {
    this.loadPageData(this.pagingConfig.currentPage, this.pagingConfig.pageSize);
  }

  public loadPageData(currentPage: number, pageSize: number): void {
  }

  _onPageChange(pageEvent: UxPageChangeEvent) {
    if (this.viewInitiated) {
      if (this.pagingConfig.currentPage != pageEvent.page) {
        this.pagingConfig.currentPage = pageEvent.page;
        this.loadPageData(pageEvent.page, pageEvent.items);
      }
    }
  }

  _onPageSizeChange(pageEvent: UxPageChangeEvent) {
    if (this.viewInitiated) {
      if (this.pagingConfig.pageSize != pageEvent.items) {
        this.pagingConfig.pageSize = pageEvent.items;
        this.loadPageData(pageEvent.page, pageEvent.items);
      }
    }
  }

  protected getCountResult(resultID: number, results: CountResult[]): number|null {
    for (let res of results) {
      if (res.parent_id === resultID) {
        return res.count;
      }
    }
    return null;
  }
}
