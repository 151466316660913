import {Component, ViewChild} from "@angular/core";
import {AppConfigService} from "../../../common/services/app-config.service";
import {Subject, Subscription} from "rxjs";
import {DevicesService} from "../devices.service";
import {takeUntil, tap} from "rxjs/operators";
import {QueryParam} from "../../../common/models/query-param.type";
import {ApiPath} from "../../../common/services/api.path";
import {DeviceEntity, UpdateDeviceEntity} from "../../../common/models/entity/device-entity.model";
import {DeviceCardModel} from "../model/device-card.model";
import {ModelService} from "../../../common/services/model.service";
import {NotificationService} from "../../../common/services/notification.service";
import {DeviceEditComponent} from "./device-edit.component";
import {PagedComponent} from "../../../components/base/paged.component";
import {ConfirmPopupComponent} from "../../../components/confirm-popup/confirm-popup.component";
import {Router} from "@angular/router";
import * as moment from "moment";
import {ReportsService} from "../../reports/reports.service";
import {ReportRequestParametersModel} from "../../reports/model/report-request-parameters.model";
import {
  DeviceStats24,
  DeviceStats24Map,
  StatisticsDataEntry, StatisticsDataStyledVisualEntry, StatisticsEntity,
  StatisticsEntityBase, SummaryStatisticsItem
} from "../../../common/models/entity/statistics-entity.model";
import {Progress} from "../../../common/models/progress.model";
import {DevicesCardListComponent} from "./devices-card-list.component";
import {DeviceFolderEditComponent} from "./device-folder-edit.component";
import {CountResult, DeleteOperationResult} from "../../../common/models/result.type";

@Component({
  selector: "sq-devices-full-list",
  templateUrl: "devices-full-list.component.html",
  host: {"[class.sq-devices-full-list]": "true"}
})
export class DevicesFullListComponent extends PagedComponent {

  _deviceSearchValue: string;
  _devices: DeviceEntity[] = [];
  _devicesMap: Map<number|string, DeviceEntity> = new Map<number, DeviceEntity>();
  _filteredDevices: string[];

  _deviceStatisticsLoading: Progress = 'loading';
  private _devicesFullStatistics: DeviceStats24Map = new Map();
  private _devicesSummaryStatistics: Map<string, StatisticsEntityBase<StatisticsDataEntry>> = new Map();
  _devicesSummaryVisualStatistics: Map<string, StatisticsEntityBase<StatisticsDataStyledVisualEntry>> = new Map();

  _deviceDeleteEntity: DeviceEntity;

  private stopSubscription$ = new Subject<boolean>();
  private devicesCountSubscription$: Subscription;
  private foldersSizeSubscription$: Subscription;
  private devicesListSubscription$: Subscription;
  private devicesStatisticsSubscription$: Subscription;
  private deviceDeleteSubscription$: Subscription;

  @ViewChild("deviceCardList", { static: true })
  deviceCardList: DevicesCardListComponent;

  @ViewChild("deviceEditPopup", { static: true })
  deviceEditPopup: DeviceEditComponent;

  @ViewChild("deviceFolderEditPopup", { static: true })
  folderEditPopup: DeviceFolderEditComponent;

  deviceEditCard: DeviceCardModel;
  folderEditCard: DeviceCardModel;

  @ViewChild("deviceDeleteConfirmPopup", { static: true })
  deviceDeleteConfirmPopup: ConfirmPopupComponent<DeviceEntity>;

  constructor(protected configService: AppConfigService,
              private devicesService: DevicesService,
              private reportsService: ReportsService,
              private modelService: ModelService,
              private notificationService: NotificationService,
              private router: Router) {
    super(configService)
  }

  public loadPageData(currentPage: number, pageSize: number): void {
    this.retrieveDevicesList(currentPage, pageSize, false);
  }

  public destroyComponent() {
    this.stopSubscription$.next(true);
    this.stopSubscription$.complete();
  }

  _onSearchValueChange(value: string) {
    this.filterDevices();
  }

  private filterDevices() {
    if (this._devices) {
      this._filteredDevices = this._devices.filter((value: DeviceEntity) => {
        if (this._deviceSearchValue) {
          return (value.type === "folder") ||
            value.instance.indexOf(this._deviceSearchValue) >= 0 ||
            value.number.indexOf(this._deviceSearchValue) >= 0;
        }
        else {
          return true;
        }
      })
        .map((entity: DeviceEntity) => {
          return entity.id.toString();
        });
    }
    else {
      this._filteredDevices = [];
    }
  }

  _onAddNewFolderClicked(card: DeviceCardModel): void {
    this.folderEditPopup.creatingMode = true;
    if (card) {
      this.folderEditPopup.parentEntity = card.originalEntity;
    }
    else {
      this.folderEditPopup.parentEntity = undefined;
    }
    this.folderEditPopup.show();
  }

  _onEditFolder(card: DeviceCardModel) {
    this.folderEditCard = card;
    this.folderEditPopup.creatingMode = false;
    this.folderEditPopup.deviceModel = card.originalEntity;
    this.folderEditPopup.show();
  }

  _onAddNewClicked(card: DeviceCardModel): void {
    this.deviceEditPopup.creatingMode = true;
    if (card) {
      this.deviceEditPopup.parentEntity = card.originalEntity;
    }
    else {
      this.deviceEditPopup.parentEntity = undefined;
    }
    this.deviceEditPopup.show();
  }

  _onEditCard(deviceCard: DeviceCardModel) {
    if (deviceCard.originalEntity.type === 'folder') {
      this._onEditFolder(deviceCard);
    }
    else {
      this.deviceEditCard = deviceCard;
      this.deviceEditPopup.creatingMode = false;
      this.deviceEditPopup.deviceModel = deviceCard.originalEntity;
      this.deviceEditPopup.show();
    }
  }

  _onDeleteCard(deviceCard: DeviceCardModel) {
    this._deviceDeleteEntity = deviceCard.originalEntity;
    this.deviceDeleteConfirmPopup.userData = deviceCard.originalEntity;
    this.deviceDeleteConfirmPopup.show();
  }

  _onChartCard(deviceCard: DeviceCardModel) {
    let period: moment.unitOfTime.DurationConstructor = 'h';
    if (this.devicesConfig.defaultPeriod === 'hour') {
      period = 'h';
    }
    else if (this.devicesConfig.defaultPeriod === 'day') {
      period = 'd';
    }
    else if (this.devicesConfig.defaultPeriod === 'month') {
      period = 'M';
    }
    let dateFrom: Date = moment().subtract(this.devicesConfig.defaultDuration, period).toDate();
    let dateTo: Date = new Date();

    this.router.navigate(['/reports/view'], {
      queryParams: {
        period: this.devicesConfig.defaultPeriod,
        from: dateFrom.getTime(),
        to: dateTo.getTime(),
        devices: [deviceCard.originalEntity.instance],
        tests: []
      }
    });
  }

  _onExpandFolderCard(deviceCard: DeviceCardModel) {
    let queryParams: QueryParam[] = [
      {
        key: ApiPath.PARENT_ID,
        value: deviceCard.originalEntity.id
      }
    ];
    deviceCard.originalEntity.progress = 'loading';

    if (deviceCard.opened) {
      deviceCard.statisticsSubscription$ && deviceCard.statisticsSubscription$.unsubscribe();
      deviceCard.statisticsSubscription$ = this.devicesService
        .getEntityList(queryParams)
        .pipe(
          takeUntil(this.stopSubscription$),
          tap((devices: DeviceEntity[]) => {
            this._devicesMap.get(deviceCard.originalEntity.id).children_count = devices.length;

            deviceCard.originalEntity.progress = 'loaded';
            this.mergeDevices(devices, true);
            this.filterDevices();

            let folderIds: (number|string)[] = [];
            let deviceInstances: Map<string, string> = new Map<string, string>();
            devices.forEach((device: DeviceEntity) => {
              if (device.type === 'folder') {
                folderIds.push(device.id);
              }
              else {
                deviceInstances.set(device.id.toString(), device.instance);
              }
            });

            this.retrieveFoldersSize(folderIds);
            this.retrieveDevicesStatistics(deviceCard.originalEntity.id, deviceInstances);
          })
        )
        .subscribe();
    }
    else {
      this.foldersSizeSubscription$ && this.foldersSizeSubscription$.unsubscribe();
      this.foldersSizeSubscription$ = this.devicesService
          .getEntitiesCountPerParent(queryParams)
          .pipe(
              takeUntil(this.stopSubscription$),
              tap((results: CountResult[]) => {
                for (let res of results) {
                  if (this._devicesMap.has(res.parent_id)) {
                    let device = this._devicesMap.get(res.parent_id);
                    device.progress = 'loaded';
                    device.children_count = res.count;
                  }
                }
              })
          )
          .subscribe();
    }
  }

  _onDeviceCreateComplete(deviceData: DeviceEntity) {
    this._devices.push(deviceData);
    this._devicesMap.set(deviceData.id, deviceData);
    let filteredDevices = this._filteredDevices.concat(deviceData.id.toString());
    this._filteredDevices = filteredDevices;

    if (this._devicesMap.has(deviceData.parent_id)) {
      let parentEntity = this._devicesMap.get(deviceData.parent_id);
      if (parentEntity.children_count === undefined || parentEntity.children_count === null) {
        parentEntity.children_count = 1;
      }
      else {
        parentEntity.children_count++;
      }
    }

    let deviceStat: Map<string, StatisticsEntityBase<StatisticsDataEntry>> = this.reportsService.convertSummaryStatisticsPerItem([deviceData.id.toString()], new Map());
    let deviceVisualStat: Map<string, StatisticsEntityBase<StatisticsDataStyledVisualEntry>> = this.reportsService.calculateSummaryVisualStatisticsPerItem(deviceStat);

    deviceStat.forEach((value: StatisticsEntityBase<StatisticsDataEntry>, key: string) => {
      this._devicesSummaryStatistics.set(key, value);
    });
    this._devicesSummaryVisualStatistics.forEach((value: StatisticsEntityBase<StatisticsDataStyledVisualEntry>, key: string) => {
      deviceVisualStat.set(key, value);
    });
    this._devicesSummaryVisualStatistics = deviceVisualStat;
  }

  _onDeviceEditComplete(deviceData: UpdateDeviceEntity) {
    if (this._devicesMap.has(deviceData.entityID)) {
      this._devicesMap.get(deviceData.entityID).copyFrom(deviceData.value);
    }
  }

  _onDevicePopupClose(deviceEntity: DeviceEntity) {
    if (this.deviceEditCard !== undefined) {
      this.deviceCardList.updateLinkedList(this.deviceEditCard);

      this.deviceEditCard = undefined;
    }
  }

  _onFolderPopupClose(deviceEntity: DeviceEntity) {
    if (this.folderEditCard !== undefined) {
      this.folderEditCard = undefined;
    }
  }

  _onDeviceCancel(deviceData: DeviceEntity) {
    this._deviceDeleteEntity = undefined;
  }

  _onDeviceDelete(deviceData: DeviceEntity) {
    let deviceID = deviceData.id;
    let deviceName = deviceData.instance;
    let parentID = deviceData.parent_id;

    this.deviceDeleteSubscription$ && this.deviceDeleteSubscription$.unsubscribe();
    this.deviceDeleteSubscription$ = this.devicesService
      .deleteEntity(deviceID)
      .pipe(
        takeUntil(this.stopSubscription$),
        tap((data: DeleteOperationResult) => {
          if (data.opResult) {
            //delete entity
            let deletedIndex: number = this.modelService.findInArray(this._devices,
              (item: DeviceEntity) => {
              return (item.id === deviceID);
            });
            if (deletedIndex >= 0) {
              this._devices.splice(deletedIndex, 1);
            }

            if (this._devicesMap.has(parentID)) {
              let parentEntity = this._devicesMap.get(parentID);
              if (parentEntity.children_count === undefined || parentEntity.children_count === null) {
                parentEntity.children_count = 0;
              }
              else {
                parentEntity.children_count--;
              }
            }

          } else {
            this.notificationService.pushNotification({
              type: "error",
              caption: "Deletion error",
              content: `Fail to delete device [{deviceID}]: '{deviceName}'`
            })
          }
        })
      )
      .subscribe();

    this._deviceDeleteEntity = undefined;
  }

  private retrieveDevicesList(currentPage: number, pageSize: number, merge: boolean): void {
    this._pageLoadingStatus = 'loading';
    let queryParams: QueryParam[] = [
      {
        key: ApiPath.PARENT_ID,
        value: 0
      }
    ];

    this.devicesCountSubscription$ && this.devicesCountSubscription$.unsubscribe();
    this.devicesCountSubscription$ = this.devicesService
      .getEntitiesCountPerParent(queryParams)
      .pipe(
        takeUntil(this.stopSubscription$),
        tap((results: CountResult[]) => {
          let resCount = this.getCountResult(0, results);
          if (resCount !== null) {
            this.pagingConfig.totalCount = resCount;
            this.retrieveDevicesListPage(currentPage, pageSize, merge);
          }
          else {
            this.pagingConfig.totalCount = 0;
            this._pageLoadingStatus = 'no_data';
          }
        })
      )
      .subscribe();
  }

  private retrieveDevicesListPage(currentPage: number, pageSize: number, merge: boolean): void {
    this._pageLoadingStatus = 'loading';
    let queryParams: QueryParam[] = [
      {
        key: ApiPath.PARENT_ID,
        value: 0
      },
      {
        key: ApiPath.OFFSET,
        value: (currentPage-1) * pageSize
      },
      {
        key: ApiPath.LIMIT,
        value: pageSize
      }
    ];

    this.devicesListSubscription$ && this.devicesListSubscription$.unsubscribe();
    this.devicesListSubscription$ = this.devicesService
      .getEntityList(queryParams)
      .pipe(
        takeUntil(this.stopSubscription$),
        tap((devices: DeviceEntity[]) => {
          this._pageLoadingStatus = 'loaded';

          this.mergeDevices(devices, merge);
          this.filterDevices();

          let folderIds: (number|string)[] = [];
          let deviceInstances: Map<string, string> = new Map<string, string>();
          devices.forEach((device: DeviceEntity) => {
            if (device.type === 'folder') {
              folderIds.push(device.id);
            }
            else {
              deviceInstances.set(device.id.toString(), device.instance);
            }
          })

          this.retrieveFoldersSize(folderIds);
          this.retrieveDevicesStatistics(0, deviceInstances);
        })
      )
      .subscribe();
  }

  private mergeDevices(devices: DeviceEntity[], merge: boolean) {
    if (!merge) {
      this._devices.length = 0;
      this._devicesMap.clear();
    }

    for (let device of devices) {
      if (this._devicesMap.has(device.id)) {
        this._devicesMap.get(device.id).copyFrom(device);
      }
      else {
        let d = new DeviceEntity(device.parent_id, device.id);
        d.copyFrom(device);
        this._devices.push(d);
        this._devicesMap.set(device.id, d);
      }
    }
  }

  private retrieveFoldersSize(folders: (number|string)[]) {
    if (folders && folders.length > 0) {
      for (let id of folders) {
        if (this._devicesMap.has(id)) {
          this._devicesMap.get(id).progress = 'loading';
        }
      }

      let queryParams: QueryParam[] = [];
      folders.forEach((id: number|string) => {
        queryParams.push({
          key: ApiPath.PARENT_ID,
          value: id
        });
      });

      this.foldersSizeSubscription$ && this.foldersSizeSubscription$.unsubscribe();
      this.foldersSizeSubscription$ = this.devicesService
        .getEntitiesCountPerParent(queryParams)
        .pipe(
          takeUntil(this.stopSubscription$),
          tap((results: CountResult[]) => {
            for (let res of results) {
              if (this._devicesMap.has(res.parent_id)) {
                let device = this._devicesMap.get(res.parent_id);
                device.progress = 'loaded';
                device.children_count = res.count;
              }
            }
          })
        )
        .subscribe();
    }
  }

  private retrieveDevicesStatistics(folderID: number|string, deviceInstances: Map<string, string>) {
    if (deviceInstances && deviceInstances.size > 0) {
      this._deviceStatisticsLoading = 'loading';

      let period: moment.unitOfTime.DurationConstructor = 'h';
      if (this.devicesConfig.defaultPeriod === 'hour') {
        period = 'h';
      }
      else if (this.devicesConfig.defaultPeriod === 'day') {
        period = 'd';
      }
      else if (this.devicesConfig.defaultPeriod === 'month') {
        period = 'M';
      }
      let dateFrom: Date = moment().subtract(this.devicesConfig.defaultDuration, period).toDate();
      let dateTo: Date = new Date();
      let phonesIDsStr: string[] = Array.from(deviceInstances.keys());
      let phonesIDs: number[] = phonesIDsStr.map((value: string) => {
        return parseInt(value);
      });

      deviceInstances.forEach((value: string, key: string) => {
        this._devicesMap.get(parseInt(key)).progress = 'loading';
      });

      let reportParams: ReportRequestParametersModel = {
        startDate: dateFrom,
        endDate: dateTo,
        scale: this.devicesConfig.defaultPeriod,
        united: false,
        phonesIds: phonesIDs,
        testsIds: []
      };

      this.devicesStatisticsSubscription$ && this.devicesStatisticsSubscription$.unsubscribe();
      this.devicesStatisticsSubscription$ = this.reportsService
        .getDevicesStatistics(reportParams)
        .pipe(
          takeUntil(this.stopSubscription$),
          tap((statistics: DeviceStats24Map) => {
            if (statistics) {
              for (let id of statistics.keys()) {
                this._devicesFullStatistics.set(id, statistics.get(id));
              }
            }

            this._devicesSummaryStatistics = this.reportsService.convertSummaryStatisticsPerItem(phonesIDsStr, this._devicesFullStatistics);
            this._devicesSummaryVisualStatistics = this.reportsService.calculateSummaryVisualStatisticsPerItem(this._devicesSummaryStatistics);

            deviceInstances.forEach((value: string, key: string) => {
              this._devicesMap.get(parseInt(key)).progress = 'loaded';
            });
            this._deviceStatisticsLoading = 'loaded';
          })
        )
        .subscribe();
    }
  }
}
