<label class="ux-radio-field__label"
       (focus)="_onFocus($event)"
       (blur)="_onBlur($event)"
       (change)="_toggleState()">


    <input type="radio"
           class="ux-radio-field__input"
           [attr.id]="name ? name + '-item-' + index : null"
           [attr.name]="name"
           [checked]="value"
           [disabled]="disabled">
    <div class="ux-radio-field__icon"></div>
    <ng-template [ngTemplateOutlet]="template || defaultTemplate"
                 [ngTemplateOutletContext]="{item: item}">
    </ng-template>
</label>

<ng-template #defaultTemplate let-item="item">
    <span class="ux-radio-field__text">{{label}}</span>
</ng-template>
