<span class="ux-mask-field__prefix" *ngIf="prefix">{{prefix}}</span>
<span class="ux-mask-field__wrapper">
    <span *ngIf="showMask" class="ux-mask-field__mask">
        <i class="ux-mask-field__mask-valid" [innerHTML]="_stringToHtml(viewValue)">
        </i>
        <span class="ux-mask-field__mask-text" [innerHTML]="_cutMask()">
        </span>
    </span>
    <input #maskInput
           [class]="'ux-mask-field__input'"
           [placeholder]="placeholder"
           [attr.name]="inputName"
           [disabled]="disabled"
           [(ngModel)]="viewValue"
           (focus)="_onFocus($event)"
           (blur)="_onBlur($event)"
           (change)="_onValueChange($event); _onSubmitValueChange($event);"
           (input)="_onInput($event)"
           (keydown)="_onKeyDown($event)"
    />
</span>
<span class="ux-mask-field__suffix" *ngIf="suffix">{{suffix}}</span>
