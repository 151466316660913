<sq-overlay-popup [title]="_creatingMode ? 'Create new test' : 'Test details'"
                  [visible]="_visible"
                  [contentTemplate]="contentTemplate"
                  [controlsTemplate]="controlsTemplate"
                  [statusEnable]="_operationErrorText"
                  [statusTemplate]="statusTemplate"
                  (onClose)="_onCloseClick()"
>

</sq-overlay-popup>

<ng-template #contentTemplate>
  <form novalidate
        #testEditForm
        class="sq-test-edit__form"
        [formGroup]="_formGroup">

    <ux-tab-panel [selectedTabIndex]="_selectedTabIndex"
                  (onChange)="onTabChange($event)"
    >
      <ux-tab-panel-item [title]="'Test configuration'">
        <ux-label [text]="'Name'"
                  [required]="true"
        >
          <ux-text-field #testName
                         formControlName="testName"
                         [(value)]="_testModel.name"
                         (onValueChange)="_onTestNameChange($event)"
                         [placeholder]="'Enter test name'"
                         [uxTooltip]="!testName.focused && !_formGroup.controls.testName.untouched && _getErrorText('testName')"
                         uxTooltipAppendTo="target"
                         [ngClass]="{
                              '_error': !testName.focused && !_formGroup.controls.testName.untouched && _getErrorText('testName')
                           }"
          >
          </ux-text-field>
        </ux-label>

        <ux-label [text]="'Target'"
                  [required]="true"
        >
          <ux-reference-field #testTarget
                              formControlName="testTarget"
                              [inputName]="'testTarget'"
                              [(value)]="_selectedTarget"
                              [items]="_availableTargets"
                              [showDropdownButton]="true"
                              [multiple]="false"
                              [opened]="false"
                              [optionsPopoverVisible]="false"
                              [onlyExistingItems]="true"
                              [excludeCharacters]="''"
                              [isLoading]="false"
                              [delay]="300"
                              [placeholder]="'Select target device'"
                              [itemCaptionKey]="'caption'"
                              [emptyMessage]="'Nothing found'"
                              [maxListHeight]="'200px'"
                              [listStyleClass]="''"
                              [listContainer]="''"
                              [ngClass]="{
                                  '_error': !testTarget.focused && !_formGroup.controls.testTarget.untouched && _getErrorText('testTarget')
                                }"
                              [mobile]="false">
          </ux-reference-field>
        </ux-label>
        <ux-label [text]="'CRON'"
                  [required]="true"
        >
          <ux-text-field #cronCommand
                         formControlName="cronCommand"
                         [(value)]="_testModel.schedule"
                         [placeholder]="'Enter number'"
                         [uxTooltip]="!cronCommand.focused && !_formGroup.controls.cronCommand.untouched && _getErrorText('cronCommand')"
                         uxTooltipAppendTo="target"
                         [ngClass]="{
                              '_error': !cronCommand.focused && !_formGroup.controls.cronCommand.untouched && _getErrorText('cronCommand')
                           }"
          >
          </ux-text-field>
        </ux-label>
      </ux-tab-panel-item>
      <ux-tab-panel-item [title]="'Advanced'">
        <ux-label [text]="'Attributes'">
          <ux-memo-field #jsonAttributes
                         [(value)]="_jsonAttributes"
                         [placeholder]="'Enter JSON attributes parameter'">
          </ux-memo-field>
        </ux-label>
      </ux-tab-panel-item>
    </ux-tab-panel>
  </form>
</ng-template>

<ng-template #controlsTemplate>
  <ux-button (tap)="_onEditConfirm()"
             [text]="creatingMode ? 'Create' : 'Update'"
             [kind]="'primary'"
             [disabled]="!_formGroup.valid"
  >
  </ux-button>
</ng-template>

<ng-template #statusTemplate>
  <ux-label [text]="_operationErrorText"
            [ngClass]="'_error'"
  >
  </ux-label>
</ng-template>
