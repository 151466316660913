
import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {ResultsListComponent} from "./components/results-list.component";
import {ResultsRoutingModule} from "./results-routing.module";
import {ResultsService} from "./results.service";
import {ResultsListAdvancedComponent} from "./components/results-list-advanced.component";
import {UxTextFieldModule} from "../../../ux-lib/components/fields/text/text-field.module";
import {UxLabelModule} from "../../../ux-lib/components/label/label.module";
import {UxTableModule} from "../../../ux-lib/components/table/table.module";
import {UxSearchFieldModule} from "../../../ux-lib/components/fields/search/search-field.module";
import {FormsModule} from "@angular/forms";
import {UxButtonModule} from "../../../ux-lib/components/button/button.module";
import {UxOverlayModule} from "../../../ux-lib/components/overlay/overlay.module";
import {UxPopoverModule} from "../../../ux-lib/components/popover/popover.module";
import {UxConfirmDialogModule} from "../../../ux-lib/components/confirm-dialog/confirm-dialog.module";
import {UxPagingModule} from "../../../ux-lib/components/paging/paging.module";
import {ResultsReportPvqaComponent} from "./components/results-report-pvqa.component";
import {ResultsReportAquaComponent} from "./components/results-report-aqua.component";
import {CsvToJsonService} from "../../common/services/csv-to-json.service";
import {UxBreadcrumbsModule} from "../../../ux-lib/components/breadcrumbs/breadcrumbs.module";
import {UxRoundDotsLoaderModule} from "../../../ux-lib/components/loader/round-dots-loader/round-dots-loader.module";
import {SharedModule} from "../../components/shared.module";
import {ResultsFullListComponent} from "./components/results-full-list.component";
import {UxTooltipModule} from "../../../ux-lib/components/tooltip/tooltip.module";
import {PipesModule} from "../../common/pipes/pipes.module";
import {SevanaQualtestAuthGuard} from "../../sevana-qualtest-auth.guard";

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    ResultsRoutingModule,
    UxButtonModule,
    UxSearchFieldModule,
    UxTableModule,
    UxLabelModule,
    UxTextFieldModule,
    UxOverlayModule,
    UxPopoverModule,
    UxConfirmDialogModule,
    UxPagingModule,
    UxBreadcrumbsModule,
    UxRoundDotsLoaderModule,
    UxTooltipModule,
    PipesModule
  ],
  declarations: [
    ResultsListComponent,
    ResultsListAdvancedComponent,
    ResultsReportPvqaComponent,
    ResultsReportAquaComponent,
    ResultsFullListComponent
  ],
  entryComponents: [
    ResultsListComponent,
    ResultsFullListComponent
  ],
  exports: [
    ResultsListAdvancedComponent
  ],
  providers: [
    SevanaQualtestAuthGuard,
    ResultsService,
    CsvToJsonService
  ]
})
export class ResultsModule {

}
