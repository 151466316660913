import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output
} from "@angular/core";
import {DeviceEntity} from "../../../common/models/entity/device-entity.model";
import {forkJoin, Subject, Subscription} from "rxjs";
import {takeUntil, tap} from "rxjs/operators";
import {ModelService} from "../../../common/services/model.service";
import {NotificationService} from "../../../common/services/notification.service";
import {CssService} from "../../../common/services/css.service";
import {TestsService} from "../../tests/tests.service";
import {AppConfigService} from "../../../common/services/app-config.service";
import {ReportsService} from "../../reports/reports.service";
import {TestEntity} from "../../../common/models/entity/test-entity.model";
import {UxCardTreeCheckedEvent} from "../../../../ux-lib/components/card-tree/card-tree.component";
import {PagedComponent} from "../../../components/base/paged.component";
import {QueryParam} from "../../../common/models/query-param.type";
import {ApiPath} from "../../../common/services/api.path";
import {CountResult} from "../../../common/models/result.type";
import {TestCardModel} from "../../tests/model/test-card.model";

@Component({
  selector: "sq-device-new-test-list",
  templateUrl: "device-new-test-list.component.html",
  host: {"[class.sq-device-new-test-list]": "true"}
})
export class DeviceNewTestListComponent extends PagedComponent {
  _tests: TestEntity[] = [];
  private _testsMap: Map<number|string, TestEntity> = new Map<number|string, TestEntity>();
  _selectedTests: string[] = [];
  private _linkedTests: string[] = [];

  private stopSubscription$ = new Subject<boolean>();
  private forkSubscription$: Subscription;
  private foldersSizeSubscription$: Subscription;

  private _device: DeviceEntity;
  @Input()
  public set device(value: DeviceEntity) {
    this._device = value;

    this.resetSelectedTests();
    if (this.viewInitiated) {
      this.refresh();
    }
  }

  public get device(): DeviceEntity {
    return this._device;
  }

  @Output()
  public onCardChecked: EventEmitter<string[]> = new EventEmitter<string[]>();

  constructor(private notificationService: NotificationService,
              private cdr: ChangeDetectorRef,
              private testsService: TestsService,
              reportsService: ReportsService,
              modelService: ModelService,
              cssService: CssService,
              configService: AppConfigService) {
    super(
      configService
    )
  }

  public destroyComponent() {
    this.stopSubscription$.next(true);
    this.stopSubscription$.complete();
  }

  _onCardChecked(event: UxCardTreeCheckedEvent) {
    this._selectedTests = this._selectedTests.filter((value: string) => value !== event.model.id);
    if (event.model.selected) {
      this._selectedTests.push(event.model.id);
    }
    this.onCardChecked.emit(this._selectedTests);
  }

  private resetSelectedTests(): void {
    this._selectedTests = [];
  }

  public loadPageData(currentPage: number, pageSize: number): void {
    if (this._device) {
      this._pageLoadingStatus = 'loading';

      let queryParams: QueryParam[] = [
        {
          key: ApiPath.PARENT_ID,
          value: 0
        }
      ];

      let deviceID = this._device.id;
      this.forkSubscription$ && this.forkSubscription$.unsubscribe();
      this.forkSubscription$ = forkJoin(
        this.testsService.getEntitiesCountPerParent(queryParams),
        this.testsService.getEntityList(queryParams),
        this.testsService.getLinkedTests(deviceID),
      )
        .pipe(
          takeUntil(this.stopSubscription$),
          tap((data: [CountResult[], TestEntity[], TestEntity[]]) => {
            let resCount = this.getCountResult(0, data[0]);
            if (resCount !== null) {
              this.pagingConfig.totalCount = resCount;
              this._linkedTests = data[2].map(item => item.id.toString());
              this.mergeTests(this._linkedTests, data[1], false);

              let folderIds: (number|string)[] = data[1].filter((item: TestEntity) => {return item.command === 'folder';})
                .map((item: TestEntity) => item.id);

              this.retrieveFoldersSize(folderIds);

              this._pageLoadingStatus = 'loaded';
            }
            else {
              this.pagingConfig.totalCount = 0;
              this._linkedTests = [];
              this._tests = [];
              this._testsMap.clear();
              this._pageLoadingStatus = 'no_data';
            }

            this.cdr.detectChanges();
          })
        )
        .subscribe();
    }
  }

  private mergeTests(linkedTestsIds: string[], tests: TestEntity[], merge: boolean) {

    if (!merge) {
      this._tests.length = 0;
      this._testsMap.clear();
    }

    let filteredTests = tests.filter(item => linkedTestsIds.indexOf(item.id.toString()) < 0);

    for (let test of filteredTests) {
      if (this._testsMap.has(test.id)) {
        this._testsMap.get(test.id).copyFrom(test);
      }
      else {
        let d = new TestEntity(test.parent_id, test.id);
        d.copyFrom(test);
        this._tests.push(d);
        this._testsMap.set(test.id, d);
      }
    }
  }

  _onExpandFolderCard(testCard: TestCardModel) {
    if (!testCard.expanded) {
      let queryParams: QueryParam[] = [
        {
          key: ApiPath.PARENT_ID,
          value: testCard.originalEntity.id
        }
      ];

      testCard.originalEntity.progress = 'loading';
      testCard.statisticsSubscription$ && testCard.statisticsSubscription$.unsubscribe();
      testCard.statisticsSubscription$ = this.testsService
        .getEntityList(queryParams)
        .pipe(
          takeUntil(this.stopSubscription$),
          tap((tests: TestEntity[]) => {
            testCard.originalEntity.progress = 'loaded';
            this.mergeTests(this._linkedTests, tests, true);

            let folderIds: (number|string)[] = tests
              .filter((item: TestEntity) => {return item.command === 'folder';})
              .map((item: TestEntity) => item.id);

            this.retrieveFoldersSize(folderIds);
          })
        )
        .subscribe();
    }
  }

  private retrieveFoldersSize(folders: (number|string)[]) {
    if (folders && folders.length > 0) {
      for (let id of folders) {
        if (this._testsMap.has(id)) {
          this._testsMap.get(id).progress = 'loading';
        }
      }

      let queryParams: QueryParam[] = [];
      folders.forEach((id: number|string) => {
        queryParams.push({
          key: ApiPath.PARENT_ID,
          value: id
        });
      });

      this.foldersSizeSubscription$ && this.foldersSizeSubscription$.unsubscribe();
      this.foldersSizeSubscription$ = this.testsService
        .getEntitiesCountPerParent(queryParams)
        .pipe(
          takeUntil(this.stopSubscription$),
          tap((results: CountResult[]) => {
            for (let res of results) {
              if (this._testsMap.has(res.parent_id)) {
                let device = this._testsMap.get(res.parent_id);
                device.progress = 'loaded';
                device.children_count = res.count;
              }
            }
          })
        )
        .subscribe();
    }
  }
}
