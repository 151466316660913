import {NgModule} from "@angular/core";
import {SharedModule} from "../../shared/shared.module";
import {UxLabelComponent} from "./label.component";

@NgModule({
    imports: [SharedModule],
    exports: [UxLabelComponent],
    declarations: [UxLabelComponent],
})
export class UxLabelModule {

}