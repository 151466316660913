import {Entity} from "./entity.model";
import {Progress} from "../progress.model";

export class AudioEntity extends Entity {
  public audio_id?: number|string;
  public name?: string;
  public kind?: number;
  public speech_text?: string;
  public Audio?: any;

  public progress?: Progress;


  public constructor(audio_id?: number|string) {
    super(audio_id);
  }

  public getId(): number|string {
    return this.audio_id;
  }

  public setId(value: number|string) {
    this.audio_id = value;
  }

  public getIdField(): string {
    return "audio_id";
  }

  public copyFrom(value: AudioEntity) {
    this.audio_id = value.audio_id;
    this.name = value.name;
    this.kind = value.kind;
    this.speech_text = value.speech_text;
    this.Audio = value.Audio;

    this.progress = value.progress;
  }
}

export interface UpdateAudioEntity {
  entityID: number|string;
  value: AudioEntity;
}
