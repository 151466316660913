<div *ngIf="visible" class="ux-round-dots-loader__inner">
    <div class="ux-round-dots-loader__spinner-item-wrapper">
        <div class="ux-round-dots-loader__spinner-item" [style.background]="color ? color : ''"></div>
    </div>
    <div class="ux-round-dots-loader__spinner-item-wrapper">
        <div class="ux-round-dots-loader__spinner-item" [style.background]="color ? color : ''"></div>
    </div>
    <div class="ux-round-dots-loader__spinner-item-wrapper">
        <div class="ux-round-dots-loader__spinner-item" [style.background]="color ? color : ''"></div>
    </div>
    <div class="ux-round-dots-loader__spinner-item-wrapper">
        <div class="ux-round-dots-loader__spinner-item" [style.background]="color ? color : ''"></div>
    </div>
    <div class="ux-round-dots-loader__spinner-item-wrapper">
        <div class="ux-round-dots-loader__spinner-item" [style.background]="color ? color : ''"></div>
    </div>
    <div class="ux-round-dots-loader__spinner-item-wrapper">
        <div class="ux-round-dots-loader__spinner-item" [style.background]="color ? color : ''"></div>
    </div>
    <div class="ux-round-dots-loader__spinner-item-wrapper">
        <div class="ux-round-dots-loader__spinner-item" [style.background]="color ? color : ''"></div>
    </div>
    <div class="ux-round-dots-loader__spinner-item-wrapper">
        <div class="ux-round-dots-loader__spinner-item" [style.background]="color ? color : ''"></div>
    </div>
</div>