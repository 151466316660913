import {Component, Input, TemplateRef} from "@angular/core";
import {UxAbstractFieldComponent, UxValueChangeEvent} from "../abstract-field.component";
import {UxRadioItemModel} from "./radio/radio-field.component";
import {NG_VALUE_ACCESSOR} from "@angular/forms";
import {UxPropertyConverter} from "../../../common/decorator/ux-property-converter";

@Component({
    selector: "ux-radio-group-field",
    templateUrl: "./radio-group-field.component.html",
    host: {
        "[class.ux-radio-group-field]": "true",
        "[attr.name]": "name"
    },
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: UxRadioGroupFieldComponent,
            multi: true
        }
    ]
})
export class UxRadioGroupFieldComponent extends UxAbstractFieldComponent<UxRadioItemModel> {
    @Input()
    public name: string;

    @Input()
    public items: Array<UxRadioItemModel>;

    @UxPropertyConverter("string", "label")
    @Input()
    public itemLabelKey: string;

    @Input()
    public template: TemplateRef<any>;

    constructor() {
        super();
    }

    protected getDefaultValue(): UxRadioItemModel {
        return undefined;
    }

    protected getValueConverter(): (value: any) => UxRadioItemModel {
        return (value: any): Object => {
            if (typeof value === "object") {
                return value;
            }
            try {
                return JSON.parse(value);
            } catch (error) {
                throw new Error(`Radio group: unable to parse value ${value}`);
            }
        };
    }

    /* @internal */
    public _onValueSwitch(event: UxValueChangeEvent<boolean>, item: UxRadioItemModel): void {
        if (!this.disabled && event.newValue === true) {
            this.value = item;
        }
    }

    /** @internal */
    public _trackByFn(index: number, item: UxRadioItemModel): string {
        return item && item.id;
    }
}
