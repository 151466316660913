import {Component, ViewChild} from "@angular/core";
import {AppConfigService} from "../../../common/services/app-config.service";
import {Subject, Subscription} from "rxjs";
import {takeUntil, tap} from "rxjs/operators";
import {QueryParam} from "../../../common/models/query-param.type";
import {ApiPath} from "../../../common/services/api.path";

import {ModelService} from "../../../common/services/model.service";
import {NotificationService} from "../../../common/services/notification.service";
import {TestEntity, UpdateTestEntity} from "../../../common/models/entity/test-entity.model";
import {TestCardModel} from "../model/test-card.model";
import {TestsService} from "../tests.service";
import {PagedComponent} from "../../../components/base/paged.component";
import {TestEditComponent} from "./test-edit.component";
import {ConfirmPopupComponent} from "../../../components/confirm-popup/confirm-popup.component";
import * as moment from "moment";
import {Router} from "@angular/router";
import {Progress} from "../../../common/models/progress.model";
import {
  DeviceStats24Map,
  StatisticsDataEntry, StatisticsDataStyledVisualEntry,
  StatisticsEntityBase, SummaryStatisticsItem
} from "../../../common/models/entity/statistics-entity.model";
import {ReportRequestParametersModel} from "../../reports/model/report-request-parameters.model";
import {ReportsService} from "../../reports/reports.service";
import {DeviceEntity} from "../../../common/models/entity/device-entity.model";
import {TestFolderEditComponent} from "./test-folder-edit.component";
import {DeviceCardModel} from "../../devices/model/device-card.model";
import {CountResult, DeleteOperationResult} from "../../../common/models/result.type";

@Component({
  selector: "sq-tests-full-list",
  templateUrl: "tests-full-list.component.html",
  host: {"[class.sq-tests-full-list]": "true"}
})
export class TestsFullListComponent extends PagedComponent {

  _testSearchValue: string;
  _tests: TestEntity[] = [];
  private _testsMap: Map<number, TestEntity> = new Map<number, TestEntity>();
  _filteredTests: string[];

  _testsStatisticsLoading: Progress = 'loading';
  private _testsFullStatistics: DeviceStats24Map = new Map();
  private _testsSummaryStatistics: Map<string, StatisticsEntityBase<StatisticsDataEntry>> = new Map();
  _testsSummaryVisualStatistics: Map<string, StatisticsEntityBase<StatisticsDataStyledVisualEntry>> = new Map();

  _testDeleteEntity: TestEntity;

  private stopSubscription$ = new Subject<boolean>();
  private testsCountSubscription$: Subscription;
  private foldersSizeSubscription$: Subscription;
  private testsListSubscription$: Subscription;
  private testsStatisticsSubscription$: Subscription;
  private testDeleteSubscription$: Subscription;

  @ViewChild("testEditPopup", { static: true })
  testEditPopup: TestEditComponent;

  @ViewChild("testFolderEditPopup", { static: true })
  folderEditPopup: TestFolderEditComponent;

  @ViewChild("testDeleteConfirmPopup", { static: true })
  testDeleteConfirmPopup: ConfirmPopupComponent<TestEntity>;

  private testEditCard: TestCardModel;
  private folderEditCard: TestCardModel;

  constructor(private notificationService: NotificationService,
              private testsService: TestsService,
              private reportsService: ReportsService,
              private modelService: ModelService,
              protected configService: AppConfigService,
              private router: Router) {
    super(configService);
  }

  public loadPageData(currentPage: number, pageSize: number): void {
    this.retrieveTestsList(currentPage, pageSize, false);
  }

  public destroyComponent() {
    this.stopSubscription$.next(true);
    this.stopSubscription$.complete();
  }

  _onSearchValueChange(value: string) {
    this.filterTests();
  }

  private filterTests() {
    if (this._tests) {
      this._filteredTests = this._tests.filter((value: TestEntity) => {
        if (this._testSearchValue) {
          return (value.command === "folder") ||
            value.name.indexOf(this._testSearchValue) >= 0 ||
            value.target.indexOf(this._testSearchValue) >= 0;
        }
        else {
          return true;
        }
      })
        .map((entity: TestEntity) => {
          return entity.id.toString();
        });
    }
    else {
      this._filteredTests = [];
    }
  }

  _onAddNewFolderClicked(card: TestCardModel): void {
    this.folderEditPopup.creatingMode = true;
    if (card) {
      this.folderEditPopup.parentEntity = card.originalEntity;
    }
    else {
      this.folderEditPopup.parentEntity = undefined;
    }
    this.folderEditPopup.show();
  }

  _onEditFolder(card: TestCardModel) {
    this.folderEditCard = card;
    this.folderEditPopup.creatingMode = false;
    this.folderEditPopup.testModel = card.originalEntity;
    this.folderEditPopup.show();
  }

  _onAddNewClicked(card: DeviceCardModel): void {
    this.testEditPopup.creatingMode = true;
    if (card) {
      this.testEditPopup.parentEntity = card.originalEntity;
    }
    else {
      this.testEditPopup.parentEntity = undefined;
    }
    this.testEditPopup.show();
  }

  _onEditCard(testCard: TestCardModel) {
    if (testCard.command === 'folder') {
      this._onEditFolder(testCard);
    }
    else {
      this.testEditCard = testCard;
      this.testEditPopup.creatingMode = false;
      this.testEditPopup.testModel = testCard.originalEntity;
      this.testEditPopup.show();
    }
  }

  _onDeleteCard(testCard: TestCardModel) {
    this._testDeleteEntity = testCard.originalEntity;
    this.testDeleteConfirmPopup.userData = testCard.originalEntity;
    this.testDeleteConfirmPopup.show();
  }

  _onChartCard(testCard: TestCardModel) {
    let period: moment.unitOfTime.DurationConstructor = 'h';
    if (this.testsConfig.defaultPeriod === 'hour') {
      period = 'h';
    }
    else if (this.testsConfig.defaultPeriod === 'day') {
      period = 'd';
    }
    else if (this.testsConfig.defaultPeriod === 'month') {
      period = 'M';
    }
    let dateFrom: Date = moment().subtract(this.testsConfig.defaultDuration, period).toDate();
    let dateTo: Date = new Date();

    this.router.navigate(['/reports/view'], {
      queryParams: {
        period: this.testsConfig.defaultPeriod,
        from: dateFrom.getTime(),
        to: dateTo.getTime(),
        devices: [],
        tests: [testCard.originalEntity.name]
      }
    });
  }

  _onExpandFolderCard(testCard: TestCardModel) {
    let queryParams: QueryParam[] = [
      {
        key: ApiPath.PARENT_ID,
        value: testCard.originalEntity.id
      }
    ];
    testCard.originalEntity.progress = 'loading';

    if (testCard.opened) {
      testCard.statisticsSubscription$ && testCard.statisticsSubscription$.unsubscribe();
      testCard.statisticsSubscription$ = this.testsService
        .getEntityList(queryParams)
        .pipe(
          takeUntil(this.stopSubscription$),
          tap((tests: TestEntity[]) => {
            this._testsMap.get(parseInt(testCard.originalEntity.id.toString())).children_count = tests.length;

            testCard.originalEntity.progress = 'loaded';
            this.mergeTests(tests, true);
            this.filterTests();

            let folderIds: number[] = [];
            let testsInstances: Map<string, string> = new Map<string, string>();
            tests.forEach((test: TestEntity) => {
              if (test.command === 'folder') {
                folderIds.push(parseInt(test.id.toString()));
              }
              else {
                testsInstances.set(test.id.toString(), test.name);
              }
            })

            this.retrieveFoldersSize(folderIds);
            this.retrieveTestsStatistics(testCard.originalEntity.id, testsInstances);
          })
        )
        .subscribe();
    }
    else {
      this.foldersSizeSubscription$ && this.foldersSizeSubscription$.unsubscribe();
      this.foldersSizeSubscription$ = this.testsService
          .getEntitiesCountPerParent(queryParams)
          .pipe(
              takeUntil(this.stopSubscription$),
              tap((results: CountResult[]) => {
                for (let res of results) {
                  if (this._testsMap.has(res.parent_id)) {
                    let device = this._testsMap.get(res.parent_id);
                    device.progress = 'loaded';
                    device.children_count = res.count;
                  }
                }
              })
          )
          .subscribe();
    }
  }

  _onTestCreateComplete(testData: TestEntity) {
    this._tests.push(testData);
    this._testsMap.set(parseInt(testData.id.toString()), testData);
    let filteredTests = this._filteredTests.concat(testData.id.toString());
    this._filteredTests = filteredTests;

    let p_id: number = parseInt(testData.parent_id.toString());
    if (this._testsMap.has(p_id)) {
      let parentEntity = this._testsMap.get(p_id);
      if (parentEntity.children_count === undefined || parentEntity.children_count === null) {
        parentEntity.children_count = 1;
      }
      else {
        parentEntity.children_count++;
      }
    }

    let testStat: Map<string, StatisticsEntityBase<StatisticsDataEntry>> = this.reportsService.convertSummaryStatisticsPerItem([testData.id.toString()], new Map());
    let testVisualStat: Map<string, StatisticsEntityBase<StatisticsDataStyledVisualEntry>> = this.reportsService.calculateSummaryVisualStatisticsPerItem(testStat);

    testStat.forEach((value: StatisticsEntityBase<StatisticsDataEntry>, key: string) => {
      this._testsSummaryStatistics.set(key, value);
    });
    this._testsSummaryVisualStatistics.forEach((value: StatisticsEntityBase<StatisticsDataStyledVisualEntry>, key: string) => {
      testVisualStat.set(key, value);
    });
    this._testsSummaryVisualStatistics = testVisualStat;
  }

  _onTestEditComplete(testData: UpdateTestEntity) {
    let id: number = parseInt(testData.entityID.toString());
    if (this._testsMap.has(id)) {
      this._testsMap.get(id).copyFrom(testData.value);
    }
  }

  _onFolderPopupClose(testEntity: TestEntity) {
    if (this.folderEditCard !== undefined) {
      this.folderEditCard = undefined;
    }
  }

  _onTestCancel() {
    this._testDeleteEntity = undefined;
  }

  _onTestDelete(testData: TestEntity) {
    let testID = testData.id;
    let testName = testData.name;
    let parentID: number = parseInt(testData.parent_id.toString());

    this.testDeleteSubscription$ && this.testDeleteSubscription$.unsubscribe();
    this.testDeleteSubscription$ = this.testsService
      .deleteEntity(testID)
      .pipe(
        takeUntil(this.stopSubscription$),
        tap((data: DeleteOperationResult) => {
          if (data.opResult) {
            //delete entity
            let deletedIndex: number = this.modelService.findInArray(this._tests,
              (item: TestEntity) => {
                return (item.id === testID);
              });
            if (deletedIndex >= 0) {
              this._tests.splice(deletedIndex, 1);
            }

            if (this._testsMap.has(parentID)) {
              let parentEntity = this._testsMap.get(parentID);
              if (parentEntity.children_count === undefined || parentEntity.children_count === null) {
                parentEntity.children_count = 0;
              }
              else {
                parentEntity.children_count--;
              }
            }

          } else {
            this.notificationService.pushNotification({
              type: "error",
              caption: "Deletion error",
              content: `Fail to delete test [{testID}]: {testName}`
            })
          }
        })
      )
      .subscribe();

    this._testDeleteEntity = undefined;
  }

  _onProbeStatistics(probeID: string) {
    this.router.navigate(['/reports/view'], {
      queryParams: {
        probe: probeID
      }
    });
  }

  _onExpandCard(testCard: TestCardModel) {
  }

  protected retrieveTestsList(currentPage: number, pageSize: number, merge: boolean): void {
    this._pageLoadingStatus = 'loading';
    let queryParams: QueryParam[] = [
      {
        key: ApiPath.PARENT_ID,
        value: 0
      }
    ];

    this.testsCountSubscription$ && this.testsCountSubscription$.unsubscribe();
    this.testsCountSubscription$ = this.testsService
      .getEntitiesCountPerParent(queryParams)
      .pipe(
        takeUntil(this.stopSubscription$),
        tap((results: CountResult[]) => {
          // search for parent_id === 0
          let resCount = this.getCountResult(0, results);
          if (resCount !== null) {
            this.pagingConfig.totalCount = resCount;
            this.retrieveTestsListPage(currentPage, pageSize, merge);
          }
          else {
            this.pagingConfig.totalCount = 0;
            this._pageLoadingStatus = 'no_data';
          }
        })
      )
      .subscribe();
  }

  protected retrieveTestsListPage(currentPage: number, pageSize: number, merge: boolean): void {
    this._pageLoadingStatus = 'loading';
    let queryParams: QueryParam[] = [
      {
        key: ApiPath.PARENT_ID,
        value: 0
      },
      {
        key: ApiPath.OFFSET,
        value: (currentPage-1) * pageSize
      },
      {
        key: ApiPath.LIMIT,
        value: pageSize
      }
    ];

    this.testsListSubscription$ && this.testsListSubscription$.unsubscribe();
    this.testsListSubscription$ = this.testsService
      .getEntityList(queryParams)
      .pipe(
        takeUntil(this.stopSubscription$),
        tap((tests: TestEntity[]) => {
          this._pageLoadingStatus = 'loaded';

          this.mergeTests(tests, merge);
          this.filterTests();

          let folderIds: number[] = [];
          let testsInstances: Map<string, string> = new Map<string, string>();
          tests.forEach((test: TestEntity) => {
            if (test.command === 'folder') {
              folderIds.push(parseInt(test.id.toString()));
            }
            else {
              testsInstances.set(test.id.toString(), test.name);
            }
          })

          this.retrieveFoldersSize(folderIds);
          this.retrieveTestsStatistics(0, testsInstances);
        })
      )
      .subscribe();
  }

  private mergeTests(tests: TestEntity[], merge: boolean) {
    if (!merge) {
      this._tests.length = 0;
      this._testsMap.clear();
    }

    for (let test of tests) {
      let id: number = parseInt(test.id.toString());
      if (this._testsMap.has(id)) {
        this._testsMap.get(id).copyFrom(test);
      }
      else {
        let d = new TestEntity(test.parent_id, test.id);
        d.copyFrom(test);
        this._tests.push(d);
        this._testsMap.set(id, d);
      }
    }
  }

  private retrieveFoldersSize(folders: (number)[]) {
    if (folders && folders.length > 0) {
      for (let id of folders) {
        if (this._testsMap.has(id)) {
          this._testsMap.get(id).progress = 'loading';
        }
      }

      let queryParams: QueryParam[] = [];
      folders.forEach((id: number|string) => {
        queryParams.push({
          key: ApiPath.PARENT_ID,
          value: id
        });
      });

      this.foldersSizeSubscription$ && this.foldersSizeSubscription$.unsubscribe();
      this.foldersSizeSubscription$ = this.testsService
        .getEntitiesCountPerParent(queryParams)
        .pipe(
          takeUntil(this.stopSubscription$),
          tap((results: CountResult[]) => {
            for (let res of results) {
              if (this._testsMap.has(res.parent_id)) {
                let device = this._testsMap.get(res.parent_id);
                device.progress = 'loaded';
                device.children_count = res.count;
              }
            }
          })
        )
        .subscribe();
    }
  }

  private retrieveTestsStatistics(folderID: string|number, testsInstances: Map<string, string>) {
    if (testsInstances && testsInstances.size > 0) {
      this._testsStatisticsLoading = 'loading';

      let period: moment.unitOfTime.DurationConstructor = 'h';
      if (this.testsConfig.defaultPeriod === 'hour') {
        period = 'h';
      } else if (this.testsConfig.defaultPeriod === 'day') {
        period = 'd';
      } else if (this.testsConfig.defaultPeriod === 'month') {
        period = 'M';
      }
      let dateFrom: Date = moment().subtract(this.testsConfig.defaultDuration, period).toDate();
      let dateTo: Date = new Date();
      let testsIdsStr: string[] = Array.from(testsInstances.keys());
      let testsIds: number[] = testsIdsStr.map((value: string) => {
        return parseInt(value);
      });

      testsInstances.forEach((value: string, key: string) => {
        this._testsMap.get(parseInt(key)).progress = 'loading';
      });

      let reportParams: ReportRequestParametersModel = {
        startDate: dateFrom,
        endDate: dateTo,
        scale: this.testsConfig.defaultPeriod,
        united: false,
        phonesIds: [],
        testsIds: testsIds
      };

      this.testsStatisticsSubscription$ && this.testsStatisticsSubscription$.unsubscribe();
      this.testsStatisticsSubscription$ = this.reportsService
        .getTasksStatistics(reportParams)
        .pipe(
          takeUntil(this.stopSubscription$),
          tap((statistics: DeviceStats24Map) => {
            if (statistics) {
              for (let id of statistics.keys()) {
                this._testsFullStatistics.set(id, statistics.get(id));
              }
            }

            this._testsSummaryStatistics = this.reportsService.convertSummaryStatisticsPerItem(testsIdsStr, this._testsFullStatistics);
            this._testsSummaryVisualStatistics = this.reportsService.calculateSummaryVisualStatisticsPerItem(this._testsSummaryStatistics);

            testsInstances.forEach((value: string, key: string) => {
              this._testsMap.get(parseInt(key)).progress = 'loaded';
            });
            this._testsStatisticsLoading = 'loaded';
          })
        )
        .subscribe();
    }
  }
}
