<div class="sq-devices-full-list__wrapper">
  <div class="sq-devices-full-list__header">
    <ux-toolbar>
      <ux-toolbar-left>
        <ux-search-field [(value)]="_deviceSearchValue"
                         (valueChange)="_onSearchValueChange($event)"
                         [placeholder]="'Search'"
                         [inputName]="'deviceSearch'"
                         [disabled]="false"
                         [fillParentWidth]="false"
                         [searchMinSymbols]="0"
                         [searchDelay]="500"
        >
        </ux-search-field>
      </ux-toolbar-left>
      <ux-toolbar-right>
        <ux-button [text]="'Add New Folder...'"
                   [icon]="'add-folder'"
                   [type]="'button'"
                   [kind]="'default'"
                   [disabled]="false"
                   [locked]="false"
                   (tap)="_onAddNewFolderClicked(undefined)"
        >
        </ux-button>
        <ux-button [text]="'Add New Device...'"
                   [icon]="'add-device'"
                   [type]="'button'"
                   [kind]="'default'"
                   [disabled]="false"
                   [locked]="false"
                   (tap)="_onAddNewClicked(undefined)"
        >
        </ux-button>
      </ux-toolbar-right>
    </ux-toolbar>
  </div>
  <div class="sq-devices-full-list__content">
    <sq-devices-card-list #deviceCardList
                          [selectAllEnabled]="false"
                          [selectItemEnabled]="false"
                          [loadStatus]="_pageLoadingStatus"
                          [cardLoadStatus]="_deviceStatisticsLoading"
                          [entities]="_devices"
                          [filteredEntities]="_filteredDevices"
                          [entitySummaryVisualStatistics]="_devicesSummaryVisualStatistics"
                          [pagingEnabled]="true"
                          [statisticsEnabled]="true"
                          [totalCount]="pagingConfig.totalCount"
                          [pageSize]="pagingConfig.pageSize"
                          [pageSizeOptions]="pagingConfig.pageSizeOptions"
                          [currentPage]="pagingConfig.currentPage"
                          (onEditCard)="_onEditCard($event)"
                          (onDeleteCard)="_onDeleteCard($event)"
                          (onChartCard)="_onChartCard($event)"
                          (onExpandFolderCard)="_onExpandFolderCard($event)"
                          (onAddFolder)="_onAddNewFolderClicked($event)"
                          (onAddItem)="_onAddNewClicked($event)"
                          (onPageChange)="_onPageChange($event)"
                          (onPageSizeChange)="_onPageSizeChange($event)"
    >
    </sq-devices-card-list>
  </div>
</div>

<sq-device-edit #deviceEditPopup
                (onCreateComplete)="_onDeviceCreateComplete($event)"
                (onEditComplete)="_onDeviceEditComplete($event)"
                (onCancelled)="_onDeviceCancel($event)"
                (onClose)="_onDevicePopupClose($event)"
>
</sq-device-edit>

<sq-device-folder-edit #deviceFolderEditPopup
                       (onCreateComplete)="_onDeviceCreateComplete($event)"
                       (onEditComplete)="_onDeviceEditComplete($event)"
                       (onCancelled)="_onDeviceCancel($event)"
                       (onClose)="_onFolderPopupClose($event)"
>
</sq-device-folder-edit>

<sq-confirm-popup #deviceDeleteConfirmPopup
                   (onCancelled)="_onDeviceCancel($event)"
                   (onAccepted)="_onDeviceDelete($event)"
                   [acceptCaption]="'Delete'"
                   [cancelCaption]="'Cancel'"
                   [title]="'Delete device'"
                   [message]="'Are you really want to delete ' + (_deviceDeleteEntity?.type === 'folder' ? 'folder': 'device') +' \'' + _deviceDeleteEntity?.instance + '\' ?'"
>
</sq-confirm-popup>
