import {Component, Input} from "@angular/core";

@Component({
    selector: "ux-router-link",
    templateUrl: "router-link.component.html",
    host: {"[class.ux-router-link]": "true"}
})
export class UxRouterLinkComponent {

    @Input()
    public link: string;

}