import {AfterViewInit, Component, ContentChild, ElementRef, Input, OnDestroy, ViewChild, Output, EventEmitter} from "@angular/core";
import {UxAbstractViewValueFieldComponent} from "../abstract-view-value-field.component";
import {MomentCalendar} from "./moment-calendar";
import {DateUtils} from "./date-utils";
import {UxPopoverComponent} from "../../popover/popover.component";
import * as moment from "moment";
import {UxValueChangeEvent} from "../abstract-field.component";
import {NG_VALUE_ACCESSOR} from "@angular/forms";
import {UxPropertyHandler} from "../../../common/decorator/ux-property-handler";
import {UxPropertyConverter} from "../../../common/decorator/ux-property-converter";

const DEFAULT_DATE_FORMAT: string = moment.localeData().longDateFormat("L");
const MOBILE_DATE_FORMAT: string = "YYYY-MM-DD";
const INVALID_DATE: string = "Invalid date";

@Component({
    selector: "ux-date-field",
    templateUrl: "date-field.component.html",
    host: {"[class.ux-date-field]": "true"},
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: UxDateFieldComponent,
            multi: true
        }
    ]
})
export class UxDateFieldComponent extends UxAbstractViewValueFieldComponent<Date, string> implements AfterViewInit, OnDestroy {
    // viewValueChange: any, because of UxAbstractViewValueFieldComponent<Date, string>
    @Output()
    public viewValueChange: any = new EventEmitter<string>();

    @Input()
    @UxPropertyHandler({
        afterChange: afterChangeViewValue
    })
    public viewValue: string;

    @Input()
    @UxPropertyHandler({
        afterChange: updateMobileDateBoundaries
    })
    public beginAvailableDate: Date | string;

    @Input()
    public autofocus: boolean = false;

    @Input()
    @UxPropertyHandler({
        afterChange: updateMobileDateBoundaries
    })
    public endAvailableDate: Date | string;

    @UxPropertyHandler({
        afterChange: afterChangeDateFormat
    })
    @UxPropertyConverter("custom", DEFAULT_DATE_FORMAT, {
        convert: convertDateFormat
    })
    private _dateFormat: string;

    @Input()
    public set dateFormat(value: string) {
        if (value) {
            this._dateFormat = value;
        }
    }

    public get dateFormat(): string {
        if (this.mobile) {
            return MOBILE_DATE_FORMAT;
        } else {
            return this._dateFormat;
        }
    }

    @Input()
    public attachToBody: boolean = false;

    @Input()
    public mobile: boolean = false;

    @Input()
    public set invalidDateMessage(value: string) {
        this._invalidDateMessage = value ? value : INVALID_DATE;
    };

    public get invalidDateMessage(): string {
        return this._invalidDateMessage;
    };

    private _invalidDateMessage = INVALID_DATE;

    /** @internal */
    public _visibleCalendar: boolean = false;

    @ContentChild("customIcon", /* TODO: add static flag */ {static: true})
    private set customIcon(iconElement: ElementRef) {
        this._customIcon = !!iconElement;
    }

    /** @internal */
    public _customIcon: boolean = false;

    @ViewChild("calendar", { static: true })
    private calendar: ElementRef;

    @ViewChild("contentWrapper")
    private contentWrapperElement: ElementRef;

    @ViewChild("popover", { static: true })
    private popover: UxPopoverComponent;

    @ViewChild("input")
    private inputElement: ElementRef;

    @ViewChild("mobileInput")
    private mobileInputElement: ElementRef;

    @ViewChild("calendarIcon")
    private iconWrapperElement: ElementRef;

    private eventType: string = ("ontouchend" in document.documentElement) ? "touchend" : "mousedown";
    private calendarApi: MomentCalendar;
    private calendarInited: boolean = false;
    private element: HTMLElement;

    constructor(
        private elRef: ElementRef
    ) {
        super();
        this.element = elRef.nativeElement;
    }

    public ngAfterViewInit(): void {
        if (this.autofocus) {
            this.focus();
        }
    }

    /** @internal */
    public _onValueChange(event: UxValueChangeEvent<any>): void {
        if (!DateUtils.compareDateValue(event.newValue, event.oldValue, this.dateFormat)) {
            let self = this,
                newValue: string = event.newValue as any;

            self.onValueChange.emit(event);
            self.valueChange.emit(newValue);

            // user input mode
            if (self.focused && !self._visibleCalendar) {
                self.viewValueChange.emit(this.viewValue);
            } else {
                self.viewValue = DateUtils.convertToDateFormat(self.value, self.dateFormat);
            }

            self.propagateChange && self.propagateChange(newValue);
            self.propagateChange && self.propagateTouch(newValue);
        }
    }

    /** @internal */
    public _onViewValueChange(event: UxValueChangeEvent<any>): void {
            if (event.oldValue !== event.newValue) {
                if (event.newValue === INVALID_DATE &&
                    this.invalidDateMessage &&
                    event.newValue !== this.invalidDateMessage) {
                    this.viewValue = this.invalidDateMessage;
                }

                this.viewValueChange.emit(this.viewValue);
                this.value = this.getValueConverter().apply(this, [this.viewValue]);
            }
        }

    /** @internal */
    public _onSubmitValueChange(event: Event): void {
        this.value = this.getValueConverter().apply(this, [this.viewValue]);

        let toDate = DateUtils.convertToDateFormat(this.value, this.dateFormat);

        this.viewValue = toDate === INVALID_DATE ? this.invalidDateMessage : toDate;

        this.onSubmitValueChange.emit({
            value: this.value,
            originalEvent: event
        });
    }

    public ngOnDestroy(): void {
        window.removeEventListener("scroll", this.onWindowScroll);
        window.removeEventListener("resize", this.onWindowResize);
        document.removeEventListener(this.eventType, this.onDocumentClick);
        document.removeEventListener("mousedown", this.onDocumentClick);
    }

    public focus() {
        setTimeout(() => {
            if (this.mobile) {
                this.mobileInputElement.nativeElement.focus();
            } else {
                this.inputElement.nativeElement.focus();
            }
        }, 0);
    }

    /** @internal */
    public _mobileDateBoundaries: {min: string, max: string} = {min: "", max: ""};

    /** @internal */
    public _onBlur(event: Event): void {
        let self = this;
        if (!(event as FocusEvent).relatedTarget
            || (event as FocusEvent).relatedTarget
            && self.iconWrapperElement
            && !(<HTMLElement>(event as FocusEvent).relatedTarget === self.iconWrapperElement.nativeElement)
            && !self.contentWrapperElement.nativeElement.contains((event as FocusEvent).relatedTarget as Node)
        ) {
            self.focused = false;
            self.onBlur.emit(event);
        } else {
            requestAnimationFrame(() => {
                self.inputElement && self.inputElement.nativeElement.focus();
            });
        }
    }

    protected getDefaultValue(): Date {
        return undefined;
    }

    protected getValueConverter(): {(value: any): Date} {
        let self = this;
        return function (fromValue: string): any {
            return DateUtils.convertToDate(fromValue, self.dateFormat);
        };
    }

    protected getViewValueConverter(): {(value: Date): any} {
        let self = this;

        return function (fromValue: Date): any {
            return DateUtils.convertToDateFormat(fromValue, self.dateFormat);
        };
    }

    /** @internal */
    public _calendarIconClick(event: Event): void {
        let self = this;

        self.popover.attachToEl = self.iconWrapperElement.nativeElement;
        self.popover.containerEl = self.attachToBody ? document.body : self.iconWrapperElement.nativeElement;
        self.popover.disablePositionUpdateOnScroll = !self.attachToBody;

        if (!self.disabled) {
            if (self.calendar && !self.calendarInited) {
                self.calendarInited = true;
                self.calendarApi = self.createMomentCalendar();
            }

            if (!self._visibleCalendar) {
                // init moment calendar with new date
                self.initCalendarWithNewDate();
            }

            self.bindEvents();

            self._visibleCalendar = !self._visibleCalendar;

            if (!self._visibleCalendar) {
                self.inputElement.nativeElement.focus();
                self.onFocus.emit(event);
            } else {
                self.element.classList.add("_focused");
            }
        }
    }

    private mobileDate(date: Date | string): string {
        if (typeof date === "string") {
            let dateTmp = DateUtils.convertToDate(date, this._dateFormat);
            return DateUtils.convertToDateFormat(dateTmp, MOBILE_DATE_FORMAT);
        } else {
            return DateUtils.convertToDateFormat(date, MOBILE_DATE_FORMAT);
        }
    }

    private createMomentCalendar(): MomentCalendar {
        let self = this;
        return new MomentCalendar(self.calendar.nativeElement, {
            clickableDays: true,
            controlsPanel: true,
            onDayClick: function (elem: Element, date: Date) {
                self.value = date;
                self._visibleCalendar = false;
            }
        });
    }

    private onWindowScroll = (e: Event) => {
        this._visibleCalendar = false;
        window.removeEventListener("scroll", this.onWindowScroll);
    }

    private onWindowResize = (e: Event) => {
        this._visibleCalendar = false;
        window.removeEventListener("resize", this.onWindowResize);
    }

    private onDocumentClick = (e: Event) => {
        if (!(<HTMLElement>e.target).classList.contains("defaultInputCalendarWrapper")
            && !this.contentWrapperElement.nativeElement.contains(e.target)
            && !this.calendar.nativeElement.contains(e.target)
        ) {
            this._visibleCalendar = false;
            this.element.classList.remove("_focused");
            document.removeEventListener(this.eventType, this.onDocumentClick);
            document.removeEventListener("mousedown", this.onDocumentClick);
        }
    }

    private bindEvents(): void {
        let self = this;

        window.addEventListener("scroll", this.onWindowScroll);
        window.addEventListener("resize", this.onWindowResize);

        if (!self._visibleCalendar) {
            document.addEventListener(self.eventType, this.onDocumentClick);
        }

        if (self.eventType === "touchend") {
            document.addEventListener("mousedown", this.onDocumentClick);
        }
    }

    private initCalendarWithNewDate(): void {
        let self = this;
        let year, month, setClassToDate, dateValue;

        dateValue = self.value && self.value.toDateString() !== INVALID_DATE ? self.value : new Date();

        year = dateValue.getFullYear();
        month = dateValue.getMonth();

        if (self.value) {
            setClassToDate = {[DateUtils.convertToCalendarModel(dateValue)]: "_current"};
        }

        self.calendarApi && self.calendarApi.refresh({
            setClassToDate: setClassToDate,
            month: month,
            year: year,
            beginAvailableDate: DateUtils.convertToDate(self.beginAvailableDate, self.dateFormat),
            endAvailableDate: DateUtils.convertToDate(self.endAvailableDate, self.dateFormat),
        });
    }
}


/*Helpers*/
export function convertDateFormat(value: any) {
    return value ? value : DEFAULT_DATE_FORMAT;
}

export function afterChangeDateFormat(newValue: string, oldValue: string) {
    let self = this;

    self.dateFormat = newValue;
    self.viewValue = DateUtils.convertToDateFormat(self.value, self.dateFormat);
}

export function updateMobileDateBoundaries() {
    let self = this;

    self._mobileDateBoundaries = {
        min: self.mobileDate(self.beginAvailableDate),
        max: self.mobileDate(self.endAvailableDate),
    };
}


/* Helpers */
export function afterChangeViewValue(newValue: string, oldValue: string): void {
    if (newValue !== oldValue) {
        this._onViewValueChange({oldValue: oldValue, newValue: newValue});
    }
}
