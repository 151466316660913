import {NgModule} from "@angular/core";
import {UxBreadcrumbsComponent} from "./breadcrumbs.component";
import {SharedModule} from "../../shared/shared.module";

export * from "./breadcrumbs.component";

@NgModule({
  imports: [SharedModule],
  exports: [UxBreadcrumbsComponent],
  declarations: [UxBreadcrumbsComponent],
})
export class UxBreadcrumbsModule {

}
