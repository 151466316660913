import {NgModule} from "@angular/core";
import {SharedModule} from "../../shared/shared.module";
import {UxReferenceFieldComponent} from "../fields/reference/reference-field.component";
import {UxEditableReferenceDirective} from "./editable-reference.directive";

@NgModule({
    imports: [SharedModule],
    exports: [UxEditableReferenceDirective],
    declarations: [UxEditableReferenceDirective],
    entryComponents: [UxReferenceFieldComponent]
})
export class UxEditableReferenceModule {
}

