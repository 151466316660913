<div class="sq-testcase-chart__wrapper">

  <div id="sq-testcase-chart__card_id" class="sq-testcase-chart__card">
    <div class="sq-testcase-chart__card-content">
      <table>
        <tbody>
          <tr>
            <td class="_label">Test time</td>
            <td class="_value">{{(result.endtime*1000) | date : 'yyyy/MM/dd HH:mm:ss'}}</td>
          </tr>
          <tr>
            <td class="_label">Result ID</td>
            <td class="_value">{{result.id}}</td>
          </tr>
          <tr>
            <td class="_label">Device</td>
            <td class="_value">{{result.phone_name}}</td>
          </tr>
          <tr>
            <td class="_label">Test</td>
            <td class="_value">{{result.task_name}}</td>
          </tr>
          <tr>
            <td class="_label">Audio</td>
            <td *ngIf="result.audio_id === 0" class="_value">Not available</td>
            <td *ngIf="result.audio_id !== 0" class="_value">
              <div class="audio-label _progress"
                   *ngIf="audioInfoProgress.progress !== 'loaded' && audioInfoProgress.progress !== 'no_data'"
              >
                <ux-round-dots-loader [visible]="audioInfoProgress.progress !== 'loaded' && audioInfoProgress.progress !== 'no_data'">
                </ux-round-dots-loader>
              </div>
              <ng-container *ngIf="audioInfoProgress.progress === 'loaded' || audioInfoProgress.progress === 'no_data'">
                <div class="audio-label">
                  <div class="audio-label _name">{{audioEntity?.name}}</div>
                  <div class="audio-label _icon _play"
                       (tap)="_playAudio()"
                  >
                    <!-- play button -->
                    <svg version="1.1"
                         xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                         x="0px" y="0px"
                         width="20" height="20"
                         viewBox="0 0 142.448 142.448"
                         style="enable-background:new 0 0 142.448 142.448;"
                         xml:space="preserve"
                         *ngIf="!playAudioID"
                    >
                      <g>
                        <path style="fill:#0c6cb0;" d="M142.411,68.9C141.216,31.48,110.968,1.233,73.549,0.038c-20.361-0.646-39.41,7.104-53.488,21.639 C6.527,35.65-0.584,54.071,0.038,73.549c1.194,37.419,31.442,67.667,68.861,68.861c0.779,0.025,1.551,0.037,2.325,0.037 c19.454,0,37.624-7.698,51.163-21.676C135.921,106.799,143.033,88.377,142.411,68.9z M111.613,110.336 c-10.688,11.035-25.032,17.112-40.389,17.112c-0.614,0-1.228-0.01-1.847-0.029c-29.532-0.943-53.404-24.815-54.348-54.348 c-0.491-15.382,5.122-29.928,15.806-40.958c10.688-11.035,25.032-17.112,40.389-17.112c0.614,0,1.228,0.01,1.847,0.029 c29.532,0.943,53.404,24.815,54.348,54.348C127.91,84.76,122.296,99.306,111.613,110.336z"/>
                        <path style="fill:#0c6cb0;" d="M94.585,67.086L63.001,44.44c-3.369-2.416-8.059-0.008-8.059,4.138v45.293 c0,4.146,4.69,6.554,8.059,4.138l31.583-22.647C97.418,73.331,97.418,69.118,94.585,67.086z"/>
                      </g>
                    </svg>

                    <!-- stop button -->
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                         x="0px" y="0px"
                         width="20" height="20"
                         viewBox="0 0 330 330"
                         style="enable-background:new 0 0 330 330;"
                         xml:space="preserve"
                         *ngIf="playAudioID"
                    >
                      <g>
                        <path style="fill:#0c6cb0;" d="M225.75,89.25h-121.5c-8.284,0-15,6.716-15,15v121.5c0,8.284,6.716,15,15,15h121.5c8.284,0,15-6.716,15-15 v-121.5C240.75,95.966,234.034,89.25,225.75,89.25z"/>
                        <path style="fill:#0c6cb0;" d="M165,0C74.019,0,0,74.019,0,165s74.019,165,165,165s165-74.019,165-165S255.981,0,165,0z M165,300 c-74.439,0-135-60.561-135-135S90.561,30,165,30s135,60.561,135,135S239.439,300,165,300z"/>
                      </g>
                    </svg>
                    <ux-round-dots-loader *ngIf="audioPlayProgress.progress === 'loading'"
                                          [visible]="audioPlayProgress.progress === 'loading'">
                    </ux-round-dots-loader>
                  </div>

                  <div class="audio-label _icon _download"
                       (tap)="_downloadAudio()"
                  >
                    <svg xml:space="preserve" xmlns="http://www.w3.org/2000/svg" version="1.1"
                         width="20" height="20"
                         enable-background="new 0 0 29.978 29.978"
                         viewBox="0 0 29.978 29.978">
                      <g fill="#0c6cb0"><path d="m25.462 19.105v6.848h-20.947v-6.848h-4.026v8.861c0 1.111 0.9 2.012 2.016 2.012h24.967c1.115 0 2.016-0.9 2.016-2.012v-8.861h-4.026z"/></g>
                                    <g fill="#0c6cb0"><path d="m14.62 18.426-5.764-6.965s-0.877-0.828 0.074-0.828 3.248 0 3.248 0 0-0.557 0-1.416v-8.723s-0.129-0.494 0.615-0.494h4.572c0.536 0 0.524 0.416 0.524 0.416v8.742 1.266s1.842 0 2.998 0c1.154 0 0.285 0.867 0.285 0.867s-4.904 6.51-5.588 7.193c-0.492 0.495-0.964-0.058-0.964-0.058z"/></g>
                    </svg>
                    <ux-round-dots-loader *ngIf="audioDownloadProgress.progress === 'loading'"
                                          [visible]="audioDownloadProgress.progress === 'loading'">
                    </ux-round-dots-loader>
                  </div>
                </div>
              </ng-container>
            </td>
          </tr>
          <tr>
            <td class="_label">Speech Text</td>
            <td class="_value">{{result?.speech_text}}</td>
          </tr>
<!--          <tr>
            <td class="_label">SIP source</td>
            <td class="_value">{{result.sip_src}}</td>
          </tr>
          <tr>
            <td class="_label">SIP destination</td>
            <td class="_value">{{result.sip_dst}}</td>
          </tr>
          <tr>
            <td class="_label">SIP callid</td>
            <td class="_value">{{result.sip_callid}}</td>
          </tr>
          <tr>
            <td class="_label">Packet loss</td>
            <td class="_value">{{result.packet_loss}}</td>
          </tr>
          <tr>
            <td class="_label">Packets total</td>
            <td class="_value">{{result.packets_total}}</td>
          </tr>
          <tr>
            <td class="_label">Packets lost</td>
            <td class="_value">{{result.packets_lost}}</td>
          </tr>
          <tr>
            <td class="_label">Jitter</td>
            <td class="_value">{{result.jitter}}</td>
          </tr>
          <tr>
            <td class="_label">RTT</td>
            <td class="_value">{{result.rtt}}</td>
          </tr> -->
        </tbody>
      </table>
    </div>
  </div>

  <div class="sq-testcase-chart__card"
       *ngIf="_aquaReport"
  >
    <div class="sq-testcase-chart__card-head _title">
      AQuA results
    </div>
    <div class="sq-testcase-chart__card-content">
      <table>
        <tbody>
          <tr>
            <td class="_label">MOS</td>
            <td class="_value">
              <div class="_row">
                <div class="_level" [ngClass]="_aquaReportVisual?.AQuAReport?.QualityResults.mosStyle"></div>
                {{_aquaReportVisual?.AQuAReport?.QualityResults.MOS + ' (' + _aquaReportVisual?.AQuAReport?.QualityResults.Percent + '%)'}}
              </div>
            </td>
          </tr>
          <ng-container *ngIf="_aquaReportVisual?.AQuAReport?.Reasons?.length > 0">
            <tr *ngFor="let reason of _aquaReportVisual?.AQuAReport?.Reasons; let idx=index">
              <td *ngIf="idx === 0"
                  class="_label _top"
                  [attr.rowspan]="_aquaReportVisual?.AQuAReport?.Reasons?.length">
                Reasons
              </td>
              <td class="_value">
                {{reason}}
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
    <div class="sq-testcase-chart__card-content">
      <table class="_stripped">
        <thead>
          <tr>
            <td>Value</td>
            <td>Original</td>
            <td>Degraded</td>
            <td></td>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let aquaValue of _aquaReportVisual?.AQuAReport?.ValuesArray | keyvalue">
            <td class="_label">{{aquaValue.key}}</td>
            <td class="_value">{{aquaValue.value.SourceValue + (aquaValue.value.Units ? ' ' + aquaValue.value.Units : '')}}</td>
            <td class="_value">
                {{aquaValue.value.DegradedValue + (aquaValue.value.Units ? ' ' + aquaValue.value.Units : '')}}
            </td>
            <td class="_sign">
              <div *ngIf="aquaValue.value.up === true"
                   class="_icon"
              >
                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                     width="16" height="16"
                     viewBox="0 0 172 172"
                     style=" fill:#000000;"><g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><path d="M0,172v-172h172v172z" fill="none"></path><g><path d="M157.66667,86c0,39.57433 -32.09233,71.66667 -71.66667,71.66667c-39.57433,0 -71.66667,-32.09233 -71.66667,-71.66667c0,-39.57433 32.09233,-71.66667 71.66667,-71.66667c39.57433,0 71.66667,32.09233 71.66667,71.66667z" fill="#10a74f"></path><path d="M125.78217,92.80117l-11.1155,11.1155l-29.03217,-27.95358l-28.30117,27.95358l-11.1155,-11.1155l39.41667,-39.05117z" fill="#ffffff"></path></g></g></svg>
              </div>
              <div *ngIf="aquaValue.value.up === false"
                   class="_icon"
              >
                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                     width="16" height="16"
                     viewBox="0 0 172 172"
                     style=" fill:#000000;"><g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><path d="M0,172v-172h172v172z" fill="none"></path><g><path d="M157.66667,86c0,39.57433 -32.09233,71.66667 -71.66667,71.66667c-39.57433,0 -71.66667,-32.09233 -71.66667,-71.66667c0,-39.57433 32.09233,-71.66667 71.66667,-71.66667c39.57433,0 71.66667,32.09233 71.66667,71.66667z" fill="#e74c3c"></path><path d="M125.78217,79.19883l-11.1155,-11.1155l-29.03217,28.66667l-28.30117,-28.66667l-11.1155,11.1155l39.41667,39.41667z" fill="#ffffff"></path></g></g></svg>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="sq-testcase-chart__card-content">
      <div class="sq-testcase-chart__card-content-title">
        Spectrum
      </div>
      <div class="sq-testcase-chart__card-content-chart">
        <ux-highchart [model]="_spectrumChart?.chartModel">
        </ux-highchart>
      </div>
    </div>
  </div>

  <div class="sq-testcase-chart__card"
       *ngIf="_pvqaReport"
  >
    <div class="sq-testcase-chart__card-head _title">
      PVQA results
    </div>
    <div class="sq-testcase-chart__card-content">
      <table>
        <tbody>
          <tr>
            <td class="_label">MOS</td>
            <td class="_value">
              <div class="_row">
                <div class="_level" [ngClass]="_pvqaReportVisual?.mosStyle"></div>
                {{_pvqaReportVisual?.mos_pvqa}}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="sq-testcase-chart__card-content">
      <div class="sq-testcase-chart__card-content-chart">
        <ux-highchart [model]="_pvqaStatChart?.chartModel">
        </ux-highchart>
      </div>
    </div>
    <div class="sq-testcase-chart__card-content"
         *ngIf="_pvqaTableData">
      <table class="_stripped">
        <thead>
        <tr>
          <td *ngFor="let columnName of _pvqaTableData.headRow">
            {{columnName}}
          </td>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let tableRow of _pvqaTableData.dataRows">
          <td class="_value"
              *ngFor="let columnName of _pvqaTableData.headRow">
            {{tableRow.get(columnName)}}
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
