import {Injectable, Injector} from "@angular/core";
import {ApiPath} from "../../common/services/api.path";
import {TestResultEntity} from "../../common/models/entity/test-result-entity.model";
import {EntityService} from "../../common/services/entity.service";
import {Observable, Subscriber} from "rxjs";
import {HttpResponse} from "@angular/common/http";
import {QueryParam} from "../../common/models/query-param.type";
import {DeviceEntity} from "../../common/models/entity/device-entity.model";

@Injectable()
export class ResultsService extends EntityService<TestResultEntity> {
  protected readonly baseUrl: string = ApiPath.PROBES_PATH;
  protected readonly entityID: string = ApiPath.PROBE_ID;
  protected readonly entityUrl: string[] = [this.baseUrl + "/", "./json/probe-by-id.json"];
  protected readonly entityCountUrl: string[] = [this.baseUrl + ApiPath.COUNT_PATH + "/", "./json/probes-count.json"];
  protected readonly entityCountPerParentUrl: string[] = [this.baseUrl + ApiPath.COUNT_PATH + "/", "./json/probes-count.json"];
  protected readonly entityListUrl: string[] = [this.baseUrl + "/", "./json/probes-list-2.json"];
  protected readonly entityCreateUrl: string[] = [this.baseUrl + ApiPath.PROBE_ID_PATH + "/", "./json/probe-by-id.json"];
  protected readonly entityUpdateUrl: string[] = [this.baseUrl + "/", "./json/probe-create.json"];
  protected readonly entityDeleteUrl: string[] = [this.baseUrl + "/", "./json/probe-create.json"];
  protected readonly entityListByDeviceUrl: string[] = [this.baseUrl + "/", "./json/probes-list-2.json"];
  protected readonly entityListByTestUrl: string[] = [this.baseUrl + "/", "./json/probes-list-2.json"];

  constructor(protected injector: Injector) {
    super(injector);
  }

  protected findInArray(items: TestResultEntity[], id: string): number {
    return this.modelService.findInArray(items, (el: TestResultEntity) => {
      return el.id === id;
    });
  }

  protected convertToObjects(entities: TestResultEntity[]): TestResultEntity[] {
    if (entities !== undefined && entities !== null) {
      return entities.map((value: TestResultEntity) => {
        let entity = new TestResultEntity(value.id);
        entity.copyFrom(value);
        return entity;
      });
    }
    return entities;
  }

  public getResultsByDeviceID(deviceID: string): Observable<TestResultEntity[]> {
    let o = Observable.create((subscriber: Subscriber<TestResultEntity[]>) => {
      let queryParams = [{
        key: ApiPath.PHONE_ID,
        value: deviceID
      }];

      let get = this.get_collection(this.entityListByDeviceUrl, {
        queryParams: queryParams
      }).subscribe(async (items: TestResultEntity[]) => {
          let converted: TestResultEntity[] = this.convertToObjects(items);

          subscriber.next(converted);
          subscriber.complete();
        });

      return () => {
        get && get.unsubscribe();
      };
    });
    return o;
  }

  public getResultsByTestID(testID: string) {
    let o = Observable.create((subscriber: Subscriber<TestResultEntity[]>) => {
      let queryParams = [{
        key: ApiPath.TASK_ID,
        value: testID
      }];

      let get = this.get_collection(this.entityListByTestUrl, {
        queryParams: queryParams
      }).subscribe(async (items: TestResultEntity[]) => {
          let converted: TestResultEntity[] = this.convertToObjects(items);

          subscriber.next(converted);
          subscriber.complete();
        });

      return () => {
        get && get.unsubscribe();
      };
    });
    return o;
  }

  public getResults(startDate: Date, endDate: Date, testsList: string[], phonesList: string[]): Observable<TestResultEntity[]> {
    let o = Observable.create((subscriber: Subscriber<TestResultEntity[]>) => {
      let queryParams: QueryParam[] = [
        {
          key: ApiPath.START_DATE,
          value: startDate.getTime()/1000
        },
        {
          key: ApiPath.END_DATE,
          value: endDate.getTime()/1000
        }
      ];

      testsList.forEach((testID: string) => {
        queryParams.push({
          key: ApiPath.TASK_ID,
          value: testID
        });
      })

      phonesList.forEach((device: string) => {
        queryParams.push({
          key: ApiPath.PHONE_ID,
          value: device
        });
      })

      let get = this.get_collection(this.entityListUrl, {
        queryParams: queryParams
      }).subscribe(async (items: TestResultEntity[]) => {
        let converted: TestResultEntity[] = this.convertToObjects(items);

        subscriber.next(converted);
        subscriber.complete();
      });

      return () => {
        get && get.unsubscribe();
      };
    });
    return o;
  }
}
