
import {AppConfigService} from "../../common/services/app-config.service";
import {Component, EventEmitter, Input} from "@angular/core";
import {UxPageChangeEvent} from "../../../ux-lib/components/paging/paging.component";
import {AbstractComponent} from "./abstract.component";
import {Output} from "@angular/core";

@Component({
  template: ''
})
export class PopupComponent<T> extends AbstractComponent {
  _visible: boolean;

  @Output()
  public onClose: EventEmitter<T> = new EventEmitter<T>();

  @Output()
  public onCancelled: EventEmitter<T> = new EventEmitter<T>();

  constructor() {
    super();
  }

  public loadInitialConfig() {}

  public initComponent() {}

  public componentInitiated() {}

  public destroyComponent() {}

  public getUserData(): T {
    return undefined;
  }

  public show(): void {
    this._visible = true;
  }

  public hide(): void {
    this._close();
  }

  _onCloseClick(): void {
    this._onCancel();
  }

  _onCancel(): void {
    this.onCancelled.emit(this.getUserData());
    this._close();
  }

  protected _close(): void {
    this._visible = false;
    this.onClose.emit(this.getUserData());
  }
}
