import {NgModule} from "@angular/core";
import {FormsModule} from "@angular/forms";
import {SharedModule} from "../../../shared/shared.module";
import {UxSearchFieldComponent} from "./search-field.component";

@NgModule({
  imports: [SharedModule, FormsModule],
  exports: [UxSearchFieldComponent],
  declarations: [UxSearchFieldComponent]
})
export class UxSearchFieldModule {
}
