/**
 * To register custom validator:
 * 1) Add validator name to <b>UxPropertyValidatorName</b> type
 * 2) Register validator instance of <b>PropertyValidator</b> in <b>PropertyValidatorStorage</b>, see PropertyValidatorStorage.register in this file
 * 3) Usage:
 *
 * class Test {
 *     @UxPropertyValidator("required", "boolean")
 *     value:any = true; //correct value
 * }
 * let test = new Test();
 * test.value = false; //correct value
 * test.value = null; //throw runtime error 'Property value is required'
 * test.value = undefined; //throw runtime error 'Property value is required'
 * test.value = 5; throw runtime error 'Property value should be boolean'
 *
 *
 */
import {UxPropertyDecoratorBuilder} from "./ux-property.decorator";

/**
 * 1) Add validator name
 */
export type UxPropertyValidatorName = "required" | "custom" | "boolean" | "string" | "stringNotEmpty";

/**
 * Decorator to validate runtime property set
 *
 * Usage:
 *
 * class Test {
 *     @UxPropertyValidator("required", "boolean")
 *     value:any = true; //correct value
 * }
 * let test = new Test();
 * test.value = false; //correct value
 * test.value = null; //throw runtime error 'Property value is required'
 * test.value = undefined; //throw runtime error 'Property value is required'
 * test.value = 5; throw runtime error 'Property value should be boolean'
 *
 * @param settings
 * @param names
 * @returns {{(any, string, PropertyDescriptor): void}}
 * @constructor
 */
export function UxPropertyValidator<T>(settings? : {}, ...names:UxPropertyValidatorName[]) {

    let builder = Object.create(UxPropertyDecoratorBuilder.prototype);
    builder.constructor = UxPropertyDecoratorBuilder;

    return builder
        .setBeforeValueChange(function (newValue:T, oldValue:T, className:string, propertyName:string) {
            if (names) {
                for (let name of names) {
                    if (name) {
                        let validator = PropertyValidatorStorage.get(name);
                        if (validator) {
                            if ((!validator.allowNullOrUndefined && (newValue === null || newValue === undefined)) || !validator.isValid.apply(this, [newValue, settings])) {
                                let message = `ERROR to validate new property value: ${settings && settings['message'] ? settings['message'] : validator.message}: className=${className}, property=${propertyName}, oldValue=${oldValue} typeof ${typeof oldValue}, newValue=${newValue} typeof ${typeof newValue}`;
                                //todo reflect
                                //let mode:PropertyValidatorMode = Reflect.getOwnMetadata(VALIDATOR_MODE_METADATA_KEY, target, propertyName) || PropertyValidatorMode.THROW_ERROR;
                                //switch (mode) {
                                //    case PropertyValidatorMode.LOG_MESSAGE_IGNORE_VALUE:
                                //    {
                                //        console.log(message);
                                //        return oldValue;
                                //    } /*break;*/
                                //    case PropertyValidatorMode.THROW_ERROR:
                                //    default:
                                //    {
                                //        throw new Error(message);
                                //    }
                                //}
                                throw new Error(message);
                            }
                        } else {
                            console.log(`Property validator is not registered: validator=${name}, class=${className}, property=${propertyName}`);
                        }
                    }
                }
            }
            return newValue;
        })
        // .setDebug(true)
        .setDecoratorName("UxPropertyValidator")
        .getDecorator();
}

interface PropertyValidator {
    isValid: (value:any, settings?: {}) => boolean;
    message: string;
    name: UxPropertyValidatorName;
    allowNullOrUndefined?: boolean;
}

class PropertyValidatorStorage {
    private static validators:{[key:string]:PropertyValidator} = {};

    private constructor() {
    }

    public static register(...validators:PropertyValidator[]) {
        if (validators) {
            for (let validator of validators) {
                if (validator) {
                    if (!PropertyValidatorStorage.validators[validator.name]) {
                        PropertyValidatorStorage.validators[validator.name] = validator;
                    } else {
                        throw new Error(`Validator for name '${validator.name}' is already registered in storage. Can't register new validator for the same name.`);
                    }
                }
            }
        }
    }

    public static get(name:UxPropertyValidatorName):PropertyValidator {
        return PropertyValidatorStorage.validators[name];
    }
}

/**
 * 2) Register validator instance
 */
PropertyValidatorStorage.register(
    {
        isValid(value:any):boolean {
            //should not be executed never because of nullOrUndefined = false
            return value !== null && value !== undefined;
        },
        message: "Property value is required",
        name: "required",
        allowNullOrUndefined: false
    },
    {
        isValid(value:any, settings: {isValid: (value:any) => boolean}):boolean {
            return settings && settings.isValid && settings.isValid(value);
        },
        message: "Property value is invalid by custom validator",
        name: "custom",
        allowNullOrUndefined: true
    },
    {
        isValid(value:any):boolean {
            return typeof value === "boolean";
        },
        message: "Property value should be boolean",
        name: "boolean",
        allowNullOrUndefined: true
    },
    {
        isValid(value:any):boolean {
            return typeof value === "string";
        },
        message: "Property value should be string",
        name: "string",
        allowNullOrUndefined: true
    },
    {
        isValid(value:any):boolean {
            return typeof value === "string" && value.trim() !== "";
        },
        message: "Property value should not be empty",
        name: "stringNotEmpty",
        allowNullOrUndefined: false
    }
);
