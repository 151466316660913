<ux-popover [class]="'ux-confirm-dialog__popover'"
            [ngClass]="popoverStyleClass"
            [caption]="caption"
            [(visible)]="visible"
            [attachPosition]="attachPosition"
            [container]="_popupContainerString"
            [containerEl]="_popupContainerElement"
            [closable]="closable"
            (onClose)="_onAccept(false)">
    <ng-content></ng-content>
    <ux-toolbar [class]="'ux-confirm-dialog__toolbar'">
        <ux-toolbar-right [class]="'ux-confirm-dialog__toolbar-right'">
            <ux-button class="ux-confirm-dialog__toolbar-item _cancel"
                       [text]="cancelButtonText"
                       (onClick)="_onAccept(false)"></ux-button>
            <ux-button class="ux-confirm-dialog__toolbar-item _ok"
                       [text]="okButtonText"
                       [kind]="'primary'"
                       (onClick)="_onAccept(true)"></ux-button>
        </ux-toolbar-right>
    </ux-toolbar>
</ux-popover>
