import {NgModule} from "@angular/core";
import {SharedModule} from "../../../shared/shared.module";
import {UxRoundDotsLoaderComponent} from "./round-dots-loader.component";

@NgModule({
    imports: [SharedModule],
    exports: [UxRoundDotsLoaderComponent],
    declarations: [UxRoundDotsLoaderComponent]
})
export class UxRoundDotsLoaderModule {
}
