import {Component, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output, TemplateRef} from "@angular/core";
import {UxPropertyConverter} from "../../../ux-lib/common/decorator/ux-property-converter";
import {UxPropertyHandler} from "../../../ux-lib/common/decorator/ux-property-handler";

@Component({
  selector: "sq-overlay-popup",
  templateUrl: "overlay-popup.component.html",
  host: {"[class.sq-overlay-popup]": "true"}
})
export class OverlayPopupComponent implements OnInit, OnDestroy {

  @HostBinding("class._visible")
  @UxPropertyConverter("boolean", "false")
  @UxPropertyHandler({
    afterChange: afterChangeVisible
  })
  @Input()
  public visible: boolean;

  @Output()
  public visibleChange = new EventEmitter<boolean>();

  @Input()
  public title: string;

  @Input()
  public footerEnable: boolean = true;

  @Input()
  public statusEnable: boolean = true;

  @Input()
  public contentTemplate: TemplateRef<any>;

  @Input()
  public controlsTemplate: TemplateRef<any>;

  @Input()
  public statusTemplate: TemplateRef<any>;

  @Output()
  public onClose: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() {
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  _onCloseClick(event: MouseEvent): void {
    this.onClose.emit(false);
  }
}

export function afterChangeVisible(value: boolean) {
  this.visibleChange.emit(value);
}
