import {Directive, EventEmitter, HostListener, Input, Output, ElementRef, NgZone, OnInit} from "@angular/core";

@Directive({
    selector: "[uxDroppable]",
    exportAs: "uxDroppable"
})
export class UxDroppableDirective implements OnInit {
    @Input("uxDroppable")
    public scope: string | string[];

    @Input()
    public dropEffect: string;

    @Output()
    public onDragEnter: EventEmitter<any> = new EventEmitter<any>();

    @Output()
    public onDragLeave: EventEmitter<any> = new EventEmitter<any>();

    @Output()
    public onDrop: EventEmitter<any> = new EventEmitter<any>();

    @Output()
    public onDragOver: EventEmitter<any> = new EventEmitter<any>();


    constructor(private hostElement: ElementRef, private zone: NgZone) {
    }

    public ngOnInit() {
        this.zone.runOutsideAngular(() => {
            this.hostElement.nativeElement.addEventListener("dragover", (event: Event) => {
                event.preventDefault();
            });
        });
    }


    @HostListener("drop", ["$event"])
    private drop(event: any): void {
        if (this.allowDrop(event)) {
            event.preventDefault();
            this.onDrop.emit(event);
        }
    }

    @HostListener("dragenter", ["$event"])
    private dragEnter(event: any): void {
        event.preventDefault();

        if (this.dropEffect) {
            event.dataTransfer.dropEffect = this.dropEffect;
        }

        this.onDragEnter.emit(event);
    }

    @HostListener("dragleave", ["$event"])
    private dragLeave(event: any): void {
        event.preventDefault();

        this.onDragLeave.emit(event);
    }

    private allowDrop(event: any): boolean {
        let dragScope = event.dataTransfer.getData("text");

        if (dragScope === null) {  //fix for IE
            dragScope = "";
        }

        if (typeof (this.scope) == "string" && dragScope == this.scope) {
            return true;
        }
        else if (this.scope instanceof Array) {
            for (let j = 0; j < this.scope.length; j++) {
                if (dragScope == this.scope[j]) {
                    return true;
                }
            }
        }
        return false;
    }
}
