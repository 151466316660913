import {Component, Input, TemplateRef} from "@angular/core";
import {UxAbstractBooleanFieldComponent} from "../../abstract-boolean-field.component";

@Component({
    selector: "ux-radio-field",
    templateUrl: "./radio-field.component.html",
    host: {"[class.ux-radio-field]": "true"}
})
export class UxRadioFieldComponent extends UxAbstractBooleanFieldComponent {

    @Input()
    public name: string;

    @Input()
    public index: string;

    @Input()
    public item: UxRadioItemModel;

    @Input()
    public template: TemplateRef<any>;

    /** @internal */
    public _toggleState(): void {
        super._toggleState.apply(this, ["boolean"]);
    }
}


export interface UxRadioItemModel {
    id?: string;
    label?: string;
    name?: string;
    disabled?: boolean;
}
