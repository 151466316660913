
import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {DevicesService} from "./devices.service";
import {DevicesRoutingModule} from "./devices-routing.module";
import {UxButtonModule} from "../../../ux-lib/components/button/button.module";
import {UxSearchFieldModule} from "../../../ux-lib/components/fields/search/search-field.module";
import {UxTableModule} from "../../../ux-lib/components/table/table.module";
import {UxLabelModule} from "../../../ux-lib/components/label/label.module";
import {UxTextFieldModule} from "../../../ux-lib/components/fields/text/text-field.module";
import {TestsModule} from "../tests/tests.module";
import {UxBreadcrumbsModule} from "../../../ux-lib/components/breadcrumbs/breadcrumbs.module";
import {UxOverlayModule} from "../../../ux-lib/components/overlay/overlay.module";
import {UxPopoverModule} from "../../../ux-lib/components/popover/popover.module";
import {UxFormFieldModule} from "../../../ux-lib/components/fields/form/form-field.module";
import {UxEditableModule} from "../../../ux-lib/components/editable/editable.module";
import {UxToolbarModule} from "../../../ux-lib/components/toolbar/toolbar.module";
import {UxTooltipModule} from "../../../ux-lib/components/tooltip/tooltip.module";
import {ModelService} from "../../common/services/model.service";
import {UxConfirmDialogModule} from "../../../ux-lib/components/confirm-dialog/confirm-dialog.module";
import {NotificationService} from "../../common/services/notification.service";
import {TestsService} from "../tests/tests.service";
import {ValidatorService} from "../../common/services/validator.service";
import {UxPagingModule} from "../../../ux-lib/components/paging/paging.module";
import {UxDropdownFieldModule} from "../../../ux-lib/components/fields/dropdown/dropdown-field.module";
import {PipesModule} from "../../common/pipes/pipes.module";
import {DictionaryService} from "../../common/services/dictionary.service";
import {UxEditableDropDownModule} from "../../../ux-lib/components/editable-dropdown/editable-dropdown.module";
import {UxMenuModule} from "../../../ux-lib/components/menu/menu.module";
import {DevicesCardListComponent} from "./components/devices-card-list.component";
import {DevicesFullListComponent} from "./components/devices-full-list.component";
import {UxCardTreeModule} from "../../../ux-lib/components/card-tree/card-tree.module";
import {UxRoundDotsLoaderModule} from "../../../ux-lib/components/loader/round-dots-loader/round-dots-loader.module";
import {CssService} from "../../common/services/css.service";
import {DeviceEditComponent} from "./components/device-edit.component";
import {OverlayPopupModule} from "../../components/overlay-popup/overlay-popup.module";
import {DeviceLinkedTestComponent} from "./components/device-linked-test.component";
import {SharedModule} from "../../components/shared.module";
import {DeviceLinkNewTestPopupComponent} from "./components/device-link-new-test-popup.component";
import {DeviceNewTestListComponent} from "./components/device-new-test-list.component";
import {DeviceLinkedTestListComponent} from "./components/device-linked-test-list.component";
import {CardListModule} from "../../components/card-list/card-list.module";
import {SevanaQualtestAuthGuard} from "../../sevana-qualtest-auth.guard";
import {ReportsService} from "../reports/reports.service";
import {DeviceFolderEditComponent} from "./components/device-folder-edit.component";
import {UxRadioGroupFieldModule} from "../../../ux-lib/components/fields/radio-group/radio-group-field.module";
import {UxRadioFieldModule} from "../../../ux-lib/components/fields/radio-group/radio/radio-field.module";
import {UxCheckboxFieldModule} from "../../../ux-lib/components/fields/checkbox/checkbox-field.module";
import {UxAttachmentModule} from "../../../ux-lib/components/attachment/attachment.module";
import {UxTabPanelModule} from "../../../ux-lib/components/tab-panel/tab-panel.module";
import {AudioService} from "../audio/audio.service";
import {UxReferenceFieldModule} from "../../../ux-lib/components/fields/reference/reference-field.module";
import {UxMemoFieldModule} from "../../../ux-lib/components/fields/memo/memo-field.module";
import {UxMaskFieldModule} from "../../../ux-lib/components/fields/mask/mask-field.module";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        SharedModule,
        ReactiveFormsModule,
        DevicesRoutingModule,
        UxButtonModule,
        UxSearchFieldModule,
        UxTableModule,
        UxLabelModule,
        UxTextFieldModule,
        UxMemoFieldModule,
        UxDropdownFieldModule,
        UxBreadcrumbsModule,
        UxOverlayModule,
        UxPopoverModule,
        UxFormFieldModule,
        UxEditableModule,
        UxEditableDropDownModule,
        UxToolbarModule,
        UxTooltipModule,
        UxConfirmDialogModule,
        UxPagingModule,
        UxMenuModule,
        UxCardTreeModule,
        UxRoundDotsLoaderModule,
        OverlayPopupModule,
        TestsModule,
        PipesModule,
        CardListModule,
        UxRadioGroupFieldModule,
        UxRadioFieldModule,
        UxCheckboxFieldModule,
        UxAttachmentModule,
        UxTabPanelModule,
        UxReferenceFieldModule,
        UxMaskFieldModule
    ],
  declarations: [
    DevicesFullListComponent,
    DevicesCardListComponent,
    DeviceEditComponent,
    DeviceFolderEditComponent,
    DeviceLinkedTestComponent,
    DeviceNewTestListComponent,
    DeviceLinkNewTestPopupComponent,
    DeviceLinkedTestListComponent
  ],
  entryComponents: [
    DevicesFullListComponent
  ],
  exports: [
    DevicesCardListComponent
  ],
  providers: [
    SevanaQualtestAuthGuard,
    AudioService,
    DevicesService,
    TestsService,
    NotificationService,
    ModelService,
    ReportsService,
    CssService,
    ValidatorService,
    DictionaryService
  ]
})
export class DevicesModule {

}
