import {UpdateEntityModel} from "./update-entity.model";
import {HierarchyEntityModel} from "./hierarchy-entity.model";
import {DeviceEntity} from "./device-entity.model";

export class TestEntity extends HierarchyEntityModel {
  public name?: string;
  public target?: string;
  public command?: string;
  public schedule?: string;
  public audio_id?: number;
  public attributes?: string;
  public target_id?: number|string;

  public constructor(parent_id?: number|string, id?: number|string) {
    super(parent_id, id);
  }

  public copyFrom(value: TestEntity) {
    super.copyFrom(value);
    this.name = value.name;
    this.target = value.target;
    this.command = value.command;
    this.schedule = value.schedule;
    this.audio_id = value.audio_id;
    this.attributes = value.attributes;
    this.target_id = value.target_id;
  }
}

export interface UpdateTestEntity extends UpdateEntityModel<TestEntity> {
}
