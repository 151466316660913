import {
  Component, EventEmitter, Input, IterableDiffers, KeyValueDiffers, Output,
  TemplateRef,
  ViewChild
} from "@angular/core";
import {AppConfigService, TestsConfig} from "../../../common/services/app-config.service";
import {UxCardTreeModel} from "../../../../ux-lib/components/card-tree/card-tree.model";
import {TestCardModel, TestCardModelCopier} from "../model/test-card.model";
import {TestEntity} from "../../../common/models/entity/test-entity.model";
import {ReportsService} from "../../reports/reports.service";
import {ModelService} from "../../../common/services/model.service";
import {CssService} from "../../../common/services/css.service";
import {ResultsService} from "../../results/results.service";
import {CardListComponent} from "../../../components/card-list/card-list.component";
import {DeviceCardModel} from "../../devices/model/device-card.model";

@Component({
  selector: "sq-tests-card-list",
  templateUrl: "tests-card-list.component.html",
  host: {"[class.sq-tests-card-list]": "true"}
})
export class TestsCardListComponent extends CardListComponent<TestEntity> {

  protected testsConfig: TestsConfig = {
    defaultStatistics: 'mos_aqua',
    defaultPeriod: 'hour',
    defaultDuration: 24
  };

  @ViewChild("cardTemplate", { static: true })
  private cardTemplate: TemplateRef<any>;

  private _activeDeviceID: string;
  @Input()
  public set activeDeviceID(value: string) {
    this._activeDeviceID = value;
  }

  public get activeDeviceID(): string {
    return this._activeDeviceID;
  }

  @Output()
  public onProbeStatistics: EventEmitter<string> = new EventEmitter<string>();

  constructor(private resultsService: ResultsService,
              protected reportsService: ReportsService,
              protected modelService: ModelService,
              protected reportService: ReportsService,
              protected cssService: CssService,
              protected configService: AppConfigService,
              protected iterableDiffers: IterableDiffers,
              protected keyValueDiffers: KeyValueDiffers) {
    super(modelService,
      reportService,
      iterableDiffers,
      keyValueDiffers);

    this.loadTestsConfig();
  }

  public loadTestsConfig(): void {
    let testsConfig: TestsConfig = this.configService.tests();
    if (testsConfig) {
      this.testsConfig.defaultStatistics = testsConfig.defaultStatistics || 'mos_aqua';
      this.testsConfig.defaultPeriod = testsConfig.defaultPeriod || 'hour';
      this.testsConfig.defaultDuration = testsConfig.defaultDuration || 24;
    }
  }

  protected compareCardToEntity(entity: TestEntity, card: TestCardModel): boolean {
    return (card.originalEntity.id === entity.id);
  }

  protected updateEntityCard(entity: TestEntity, card: TestCardModel) {
    card.id = entity.id.toString();
    card.parent_id = entity.parent_id.toString();
    card.hasChildren = entity.command === 'folder' ? entity.children_count > 0 : false;
    card.child_count = entity.command === 'folder' ? entity.children_count : 0;
    card.opened = (card.hasChildren ? (card.opened === undefined ? false : card.opened) : false);
    card.name = entity.name;
    card.target = entity.target;
    card.command = entity.command;
    card.cron = entity.schedule;
  }

  protected createCard(test: TestEntity, selected?: boolean): TestCardModel {
    return <TestCardModel> {
      id: test.id.toString(),
      parent_id: test.parent_id.toString(),
      visible: true,
      hasChildren: test.command === 'folder' ? test.children_count > 0 : false,
      opened: false,
      child_count: test.command === 'folder' ? test.children_count : 0,
      children: test.command === 'folder' ? [] : undefined,
      name: test.name,
      selected: test.command === 'folder' ? undefined : selected,
      command: test.command,
      target: test.target,
      cron: test.schedule,
      originalEntity: test,
      expanded: false,
      statisticsInProgress: false,
      entityStatistics: [],
      entitySummary: undefined,
      defaultStatistics: this.testsConfig.defaultStatistics,
      defaultPeriod: this.testsConfig.defaultPeriod,
      defaultDuration: this.testsConfig.defaultDuration
    }
  }

  protected copyCard(cardFrom: TestCardModel): TestCardModel {
    let card: TestCardModel = {};
    let copier = new TestCardModelCopier();
    copier.copy(cardFrom, card);
    return card;
  }

  protected getDefaultCardModel(): UxCardTreeModel {
    return {
      template: this.cardTemplate,
      children: []
    }
  }

  protected isCardFolder(card: TestCardModel) {
    return card.hasChildren || (card.originalEntity && card.originalEntity.command === 'folder');
  }

  public _trackEntityByFn(index: number, item: TestEntity): string {
    return item && item.id.toString();
  }
}

