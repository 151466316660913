<div class="sq-results-full-list-table" id="tableContainer">
  <ux-table #resultsAdvancedTable
            [model]="_tableDataModel"
            [rowHeight]="40"
            [minVerticalSliderSize]="30"
            [mobile]="false"
  >
  </ux-table>
  <div class="sq-results-full-list-table__footer">
    <ux-paging *ngIf="pagingConfig.totalCount > pagingConfig.maxPageSize"
               [totalCount]="pagingConfig.totalCount"
               [pageSize]="pagingConfig.pageSize"
               [currentPage]="pagingConfig.currentPage"
               [pageSizeOptions]="pagingConfig.pageSizeOptions"
               (onPageChange)="_onPageChange($event)"
               (onPageSizeChange)="_onPageSizeChange($event)"
    >
    </ux-paging>
  </div>
</div>

<ng-template #tableEmptyContent>
  <ng-container *ngIf="_pageLoadingStatus === 'loading'">
    <ux-round-dots-loader [visible]="_pageLoadingStatus === 'loading'">
    </ux-round-dots-loader>
  </ng-container>
  <ng-container *ngIf="_pageLoadingStatus != 'loading'">
    No results found
  </ng-container>
</ng-template>

<ng-template #testColumn
             let-contentModel="contentModel"
>
  <div [uxTooltip]="contentModel.tooltip"
       [uxTooltipAlign]="'left'"
       [uxTooltipPosition]="'right'"
       [uxTooltipColor]="'#4d7d98'"
  >
    {{contentModel.testName}}
  </div>
</ng-template>

<ng-template #timeColumn
             let-contentModel="contentModel"
>
  {{contentModel.endtime | date : 'yyyy/MM/dd HH:mm:ss'}}
</ng-template>

<ng-template #durationColumn
             let-contentModel="contentModel"
>
  {{contentModel.duration | duration}}
</ng-template>

<ng-template #functionsColumn
             let-contentModel="contentModel"
>
  <div class="sq-results-full-list-table__functions">
    <div class="sq-results-full-list-table__function _icon _play"
         *ngIf="!!(contentModel.result.audio_id) && !(contentModel.result.playDownloadProgress)"
         (tap)="_onPlayAudio(contentModel.result)"
    >
      <svg version="1.1"
           xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
           x="0px" y="0px"
           width="20" height="20"
           viewBox="0 0 142.448 142.448"
           style="enable-background:new 0 0 142.448 142.448;"
           xml:space="preserve"
           *ngIf="playAudio !== contentModel.result.audio_id"
      >
        <g>
          <path style="fill:#0c6cb0;" d="M142.411,68.9C141.216,31.48,110.968,1.233,73.549,0.038c-20.361-0.646-39.41,7.104-53.488,21.639 C6.527,35.65-0.584,54.071,0.038,73.549c1.194,37.419,31.442,67.667,68.861,68.861c0.779,0.025,1.551,0.037,2.325,0.037 c19.454,0,37.624-7.698,51.163-21.676C135.921,106.799,143.033,88.377,142.411,68.9z M111.613,110.336 c-10.688,11.035-25.032,17.112-40.389,17.112c-0.614,0-1.228-0.01-1.847-0.029c-29.532-0.943-53.404-24.815-54.348-54.348 c-0.491-15.382,5.122-29.928,15.806-40.958c10.688-11.035,25.032-17.112,40.389-17.112c0.614,0,1.228,0.01,1.847,0.029 c29.532,0.943,53.404,24.815,54.348,54.348C127.91,84.76,122.296,99.306,111.613,110.336z"/>
          <path style="fill:#0c6cb0;" d="M94.585,67.086L63.001,44.44c-3.369-2.416-8.059-0.008-8.059,4.138v45.293 c0,4.146,4.69,6.554,8.059,4.138l31.583-22.647C97.418,73.331,97.418,69.118,94.585,67.086z"/>
        </g>
      </svg>
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
           x="0px" y="0px"
           width="20" height="20"
           viewBox="0 0 330 330"
           style="enable-background:new 0 0 330 330;"
           xml:space="preserve"
           *ngIf="playAudio === contentModel.result.audio_id"
      >
        <g>
          <path style="fill:#0c6cb0;" d="M225.75,89.25h-121.5c-8.284,0-15,6.716-15,15v121.5c0,8.284,6.716,15,15,15h121.5c8.284,0,15-6.716,15-15 v-121.5C240.75,95.966,234.034,89.25,225.75,89.25z"/>
          <path style="fill:#0c6cb0;" d="M165,0C74.019,0,0,74.019,0,165s74.019,165,165,165s165-74.019,165-165S255.981,0,165,0z M165,300 c-74.439,0-135-60.561-135-135S90.561,30,165,30s135,60.561,135,135S239.439,300,165,300z"/>
        </g>
      </svg>
    </div>
    <div class="sq-results-full-list-table__function _icon _progress"
         *ngIf="!!(contentModel.result.audio_id) && !!(contentModel.result.playDownloadProgress)"
    >
      <ux-round-dots-loader [visible]="!!(contentModel.result.playDownloadProgress)"></ux-round-dots-loader>
    </div>
    <div class="sq-results-full-list-table__function _icon _empty"
         *ngIf="!(contentModel.result.audio_id)"
    >
    </div>
    <div class="sq-results-full-list-table__function _icon _download"
         *ngIf="!!(contentModel.result.audio_id) && !(contentModel.result.downloadProgress)"
         (tap)="_onDownloadAudio(contentModel.result)"
    >
      <svg xml:space="preserve" xmlns="http://www.w3.org/2000/svg" version="1.1"
           width="20" height="20"
           enable-background="new 0 0 29.978 29.978"
           viewBox="0 0 29.978 29.978">
        <g fill="#0c6cb0"><path d="m25.462 19.105v6.848h-20.947v-6.848h-4.026v8.861c0 1.111 0.9 2.012 2.016 2.012h24.967c1.115 0 2.016-0.9 2.016-2.012v-8.861h-4.026z"/></g>
        <g fill="#0c6cb0"><path d="m14.62 18.426-5.764-6.965s-0.877-0.828 0.074-0.828 3.248 0 3.248 0 0-0.557 0-1.416v-8.723s-0.129-0.494 0.615-0.494h4.572c0.536 0 0.524 0.416 0.524 0.416v8.742 1.266s1.842 0 2.998 0c1.154 0 0.285 0.867 0.285 0.867s-4.904 6.51-5.588 7.193c-0.492 0.495-0.964-0.058-0.964-0.058z"/></g>
      </svg>
    </div>
    <div class="sq-results-full-list-table__function _icon _progress"
         *ngIf="!!(contentModel.result.audio_id) && !!(contentModel.result.downloadProgress)"
    >
      <ux-round-dots-loader [visible]="!!(contentModel.result.downloadProgress)"></ux-round-dots-loader>
    </div>
    <div class="sq-results-full-list-table__function _icon _empty"
         *ngIf="!(contentModel.result.audio_id)"
    >
    </div>
    <div class="sq-results-full-list-table__function _icon _chart"
         *ngIf="!contentModel.result.error"
         (tap)="_onChartIcon(contentModel.result)"
    >
      <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
           class="_action-icon-chart"
           width="20" height="20"
           viewBox="0 0 172 172"
           style=" fill:#000000;">
        <g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter"
           stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none"
           font-size="none" text-anchor="none" style="mix-blend-mode: normal">
          <path d="M0,172v-172h172v172z" fill="none"></path>
          <g fill="#b1b1b1">
            <path
              d="M68.08333,78.83333h35.83333v71.66667h-35.83333zM21.5,43h35.83333v107.5h-35.83333zM114.66667,21.5h35.83333v129h-35.83333z"></path>
          </g>
        </g>
      </svg>
    </div>
    <div class="sq-results-full-list-table__function _icon _error"
         *ngIf="contentModel.result.error"
         [uxTooltip]="contentModel.result.error"
    >
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
           x="0px" y="0px"
           width="20" height="20"
           viewBox="0 0 512 512" xml:space="preserve">
        <path style="fill:#FF6B6B;" d="M471.929,480.979H40.074c-30.883,0-50.135-33.478-34.552-60.175L221.449,50.871 c15.427-26.43,53.649-26.478,69.104,0L506.48,420.805C522.049,447.477,502.84,480.979,471.929,480.979z"/>
        <path style="fill:#EE5253;" d="M250.776,67.988L34.849,437.922c-2.361,4.046,0.532,9.099,5.225,9.099h431.855 c4.684,0,7.591-5.046,5.225-9.099L261.226,67.988C258.889,63.984,253.127,63.96,250.776,67.988z"/>
        <g>
          <path style="fill:#E4EAF8;" d="M256.109,358.131c9.98,0,18.411,8.305,18.411,18.134c0,9.7-8.431,17.895-18.411,17.895 c-10.271,0-18.627-8.028-18.627-17.895C237.482,366.435,246.012,358.131,256.109,358.131z"/>
          <path style="fill:#E4EAF8;" d="M256.109,330.134c-10.33,0-17.544-5.003-17.544-12.166V216.821c0-5.99,7.05-12.404,17.544-12.404 c9.461,0,17.761,5.797,17.761,12.404v101.147C273.87,325.017,266.401,330.134,256.109,330.134z"/>
        </g>
        <path style="fill:#EE5253;" d="M26.127,420.805L242.054,50.871c5.464-9.361,14.148-15.875,24.25-18.5 c-16.842-4.409-35.352,2.238-44.855,18.52L5.522,420.805c-15.569,26.671,3.641,60.175,34.552,60.175h20.605 C29.794,480.979,10.545,447.499,26.127,420.805z"/>
        <path style="fill:#E24951;" d="M55.454,437.922l210.85-361.235l-5.078-8.699c-2.341-4.012-8.103-4.02-10.45,0L34.849,437.922 c-2.362,4.046,0.532,9.099,5.225,9.099h20.605C55.994,447.022,53.088,441.976,55.454,437.922z"/>
        <g>
          <path style="fill:#D8DCE5;" d="M258.087,376.265c0-6.13,3.319-11.665,8.265-14.965c-2.95-1.991-6.486-3.169-10.242-3.169 c-10.097,0-18.627,8.305-18.627,18.134c0,9.868,8.356,17.896,18.627,17.896c3.726,0,7.234-1.144,10.17-3.08 C261.339,387.859,258.087,382.421,258.087,376.265z"/>
          <path style="fill:#D8DCE5;" d="M259.17,317.968V216.821c0-3.696,2.69-7.548,7.21-9.96c-2.945-1.518-6.511-2.444-10.271-2.444 c-10.494,0-17.544,6.414-17.544,12.404v101.147c0,7.163,7.214,12.166,17.544,12.166c3.934,0,7.449-0.752,10.299-2.069 C261.906,325.954,259.17,322.372,259.17,317.968z"/>
        </g>
      </svg>
    </div>
    <div class="sq-results-full-list-table__function _icon _delete"
         (tap)="_onDeleteIcon(contentModel.result)"
    >
      <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
           class="_action-icon-delete"
           width="20" height="20"
           viewBox="0 0 172 172"
           style=" fill:#000000;">
        <g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter"
           stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none"
           font-size="none" text-anchor="none" style="mix-blend-mode: normal">
          <path d="M0,172v-172h172v172z" fill="none"></path>
          <g fill="#b1b1b1">
            <path
                    d="M71.66667,14.33333l-7.16667,7.16667h-35.83333v14.33333h114.66667v-14.33333h-35.83333l-7.16667,-7.16667zM35.83333,50.16667v93.16667c0,7.88333 6.45,14.33333 14.33333,14.33333h71.66667c7.88333,0 14.33333,-6.45 14.33333,-14.33333v-93.16667zM67.43945,71.66667l18.56055,18.56055l18.56055,-18.56055l10.10612,10.10612l-18.56055,18.56055l18.56055,18.56055l-10.10612,10.10612l-18.56055,-18.56055l-18.56055,18.56055l-10.10612,-10.10612l18.56055,-18.56055l-18.56055,-18.56055z"></path>
          </g>
        </g>
      </svg>
    </div>
  </div>
</ng-template>

<sq-confirm-popup #resultDeleteConfirmPopup
                  (onCancelled)="_onDeleteCancel($event)"
                  (onAccepted)="_onResultDelete($event)"
                  [acceptCaption]="'Delete'"
                  [cancelCaption]="'Cancel'"
                  [title]="'Delete test result'"
                  [message]="'Are you really want to delete test call result \'' + _resultDeleteID + '\' ?'"
>
</sq-confirm-popup>

