<input #passwordInput
       class="ux-password-field__input taValue"
       [type]="type"
       [placeholder]="placeholder"
       [attr.name]="inputName"
       [attr.maxlength]="maxLength"
       [disabled]="disabled"
       [(ngModel)]="value"
       (focus)="_onFocus($event)"
       (blur)="_onBlur($event)"
       (change)="_onSubmitValueChange($event)"
       (keydown)="_onKeyDown($event)"
       (keyup.enter)="_onEnter($event)"
/>
<span *ngIf="showEyeIcon"
      class="ux-password-field__icon-container"
      (mousedown)="!disabled && _onUnmaskValue()">

    <i *ngIf="_customIcon" class="ux-password-field__icon">
        <ng-content select="svg"></ng-content>
    </i>
    <i *ngIf="!_customIcon" class="ux-password-field__icon">
        <svg width="0" height="0" #customIcon
             version="1.1" xmlns="http://www.w3.org/2000/svg"
             viewBox="0 0 24 16">
            <path d="M 12 0C 2.96898 0 0 8.18849 0 8.18849C 0 8.18849 2.96898 16.377 12 16.377C 21.0296 16.377 24 8.18849
                 24 8.18849C 24 8.18849 21.0296 0 12 0ZM 12 14.0397C 8.62076 14.0397 5.88159 11.4204 5.88159 8.18849C 5.88159
                 4.95655 8.62076 2.3346 12 2.3346C 15.3792 2.3346 18.117 4.95655 18.117 8.18849C 18.117 11.4204 15.3792 14.0397
                 12 14.0397ZM 12 8.18849C 11.4398 7.58123 12.9126 5.26222 12 5.26222C 10.3097 5.26222 8.94011 6.5732 8.94011
                 8.18849C 8.94011 9.80377 10.3097 11.1148 12 11.1148C 13.6889 11.1148 15.0599 9.80377 15.0599 8.18849C 15.0599
                 7.44537 12.4763 8.70337 12 8.18849Z"></path>
        </svg>
    </i>
</span>
