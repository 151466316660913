import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    Input,
    NgZone,
    OnDestroy,
    OnInit,
    Output,
    TemplateRef,
    ViewChild
} from "@angular/core";
import {
  UxSortTypes,
  UxTable, UxTableCheckEvent,
  UxTableColumn,
  UxTableColumnEvent, UxTableColumnSortEvent,
  UxTableData,
  UxTableFilterEvent,
  UxTableLinkEvent,
  UxTablePinEvent,
  UxTableRow
} from "./table.model";
import {UxScrollComponent} from "../scroll/scroll.component";


const SORT_TYPE_ASC: UxSortTypes = "asc",
    SORT_TYPE_DESC: UxSortTypes = "desc",
    SORT_TYPE_DEFAULT: UxSortTypes = "default";

let timerId;


@Component({
    selector: "ux-table",
    templateUrl: "./table.component.html"
})

export class UxTableComponent implements OnInit, AfterViewInit, OnDestroy {
    @HostBinding("class.ux-table")
    @HostBinding("class.taTable")
    private hostClass: boolean = true;

    @ViewChild("uxTableBodyShadow")
    private uxTableBodyShadow: ElementRef;

    private viewInited: boolean;

    @Output() onColumnClick = new EventEmitter<UxTableColumnEvent>();
    @Output() onLinkClick = new EventEmitter<UxTableLinkEvent>();
    @Output() onPinClick = new EventEmitter<UxTablePinEvent>();
    @Output() onFilterClick = new EventEmitter<UxTableFilterEvent>();
    @Output() onYScrollEnd = new EventEmitter<number>();
    @Output() onRowsChecked = new EventEmitter<UxTableCheckEvent>();

    @Input()
    public set model(value: UxTable) {
        let self = this;

        if (value) {
            self._sortedTable = value;
            self.update();
        }
    }

    @Input()
    public rowHeight: number = 40;

    @Input()
    public minVerticalSliderSize: number = 30;

    @Input()
    public mobile: boolean = false;

    @Input()
    public customSortFunction: (a: UxTableRow, b: UxTableRow, columnIndex: number, sortType: UxSortTypes) => number;

    @Input()
    public dynamicSortEnabled: boolean = false;

    @Output()
    onDynamicSortClick = new EventEmitter<UxTableColumnSortEvent>();

    public tableData: UxTableData;

    /** @internal */
    public _sortedTable: UxTable = {};

    /** @internal */
    public _partiallySelectedRows: boolean = false;

    @ViewChild("scroll", { static: true })
    /** @internal */
    public _uxScroll: UxScrollComponent;

    @ViewChild("tableBody", { static: true })
    /** @internal */
    public _tableBody: ElementRef;

    /** @internal */
    public _scrolledElement;

    /** @internal */
    public _tableEmptyContentString: string;

    /** @internal */
    public _tableEmptyContentTemplate: TemplateRef<{ model: UxTable }>;

    /** @internal */
    public _trackByFn(index: number, item: UxTableRow): string | number {
        return item && item.id;
    }

    public update(): void {
        let self = this;

        self.setInitRowIndexes();
        self.tableData = self.getCurrentTableData();
        self.checkForPartialRowsSelection();
        self.sortTable();
        self.setTableEmptyContent();

        self.updateScroll();
    }

    public updateScroll(): void {
        if (this.viewInited && this._sortedTable.body && this._sortedTable.body.rows instanceof Array) {

            setTimeout(() => {
                this._uxScroll.updateVars();
            }, 0);
        }
    }

    public updateTableData(): void {
        this.tableData = this.getCurrentTableData();
    }

    public clearCheckedRows(): void {
        let self = this;

        self.clearTableData("checkedRows");
        self.tableData.checkedRows = [];
        self.checkForPartialRowsSelection();
        self.clearHeaderCheckbox();
    }

    public clearPinnedRows(): void {
        this.clearTableData("pinnedRows");
        this.tableData.pinnedRows = [];
    }

    public sortTableColumn(columnIndex: number, sortOrder: UxSortTypes) {
      this.resetRowSorting();
      if (sortOrder !== true) {
        this.sortColumn(columnIndex, sortOrder);
      }
      this.resetHeaderSort(columnIndex, sortOrder);
    }

    public resetHeaderSort(columnIndex?: number, sortOrder?: UxSortTypes) {
      if (this._sortedTable
        && this._sortedTable.header
        && this._sortedTable.header.rows instanceof Array
      ) {
        this._sortedTable.header.rows.forEach((row: UxTableRow) => {
          if (row.columns instanceof Array) {
            row.columns.forEach((column: UxTableColumn, index: number) => {
              if (column.sort !== undefined) {
                column.sort = true;
                if (columnIndex !== undefined && index === columnIndex) {
                  column.sort = sortOrder;
                }
              }
            });
          }
        });
      }
    }

    /** @internal */
    public _onScrollEndPosition(event: boolean): void {
        if (event) {
            this.onYScrollEnd.emit(this._sortedTable.body.rows.length);
        }

        let element = this.uxTableBodyShadow && this.uxTableBodyShadow.nativeElement;

        if (element) {
            if (event) {
                element.classList.remove("_visible");
            } else {
                element.classList.add("_visible");
            }
        }
    }

    /** @internal */
    public _onColumnClick(event: UxTableColumnEvent): void {
        let self = this,
            {row, column, columnIndex, originalEvent, header} = event;

        if (!row.unclickable) {
            row.selected = !row.selected;
        }

        self.handleSelection(column, columnIndex, row, originalEvent, header);

        self.sortTableByColumn(column, columnIndex);

        self.onColumnClick.emit({
            column,
            columnIndex,
            row,
            originalEvent,
            header
        });
    }

    /** @internal */
    public _onLinkClick(event: UxTableLinkEvent): void {
        let {originalEvent, column, columnIndex, row, header} = event;

        this.onLinkClick.emit({
            column, columnIndex: columnIndex, row, originalEvent, header
        });

        originalEvent.stopPropagation();
    }

    /** @internal */
    public _onPinClick(event: UxTablePinEvent): void {
        let self = this,
            {column, columnIndex, row, originalEvent, header} = event;

        column.pin = !column.pin;

        if (self._sortedTable.body &&
            self._sortedTable.body.rows) {

            if (column.pin) {
                self.tableData.pinnedRows.push(row);
            } else {
                self.tableData.pinnedRows.splice(self.tableData.pinnedRows.indexOf(row), 1);
            }
        }

        let pinnedRows = self.tableData.pinnedRows;

        self.onPinClick.emit({
            column, columnIndex, row, pinnedRows, originalEvent, header
        });

        originalEvent.stopPropagation();
    }

    /** @internal */
    public _onFilterClick(event: UxTableFilterEvent): void {
        let self = this,
            {column, columnIndex, row, originalEvent, header} = event;

        self.onFilterClick.emit({
            column, columnIndex, row, originalEvent, header
        });

        originalEvent.stopPropagation();
    }


    private sortTable(): void {
        let self = this;

        if (self._sortedTable
            && self._sortedTable.header
            && self._sortedTable.header.rows instanceof Array
        ) {
            self._sortedTable.header.rows.forEach((row: UxTableRow) => {
                if (row.columns instanceof Array) {
                    row.columns.forEach((column: UxTableColumn, index: number) => {
                        if (column.sort) {
                            self.sortColumn(index, column.sort);
                        }
                    });
                }
            });
        }
    }

    private sortTableByColumn(column: UxTableColumn, columnIndex: number): void {
        let self = this;
        let sortOrder: UxSortTypes;

        if (column.sort === true) {
          sortOrder = SORT_TYPE_ASC;
        } else if (column.sort === SORT_TYPE_ASC) {
          sortOrder = SORT_TYPE_DESC;
        } else if (column.sort === SORT_TYPE_DESC) {
          sortOrder = true;
        }

        if (this.dynamicSortEnabled) {
          this.onDynamicSortClick.emit({
            columnIndex: columnIndex,
            sortOrder: sortOrder
          })
        } else {
          self.sortTableColumn(columnIndex, sortOrder);
        }
    }

    private sortFunction(a: UxTableRow, b: UxTableRow, columnIndex: number, sortType: UxSortTypes): number {
        let colA = a.columns[columnIndex],
            colB = b.columns[columnIndex];

        if (colA && colB) {

            if (colA.value < colB.value) {
                return sortType === SORT_TYPE_ASC ? -1 : 1;
            } else if (colA.value > colB.value) {
                return sortType === SORT_TYPE_ASC ? 1 : -1;
            }

            return 0;
        }
    }

    private sortColumn(columnIndex: number, sortType: UxSortTypes): void {

        let self = this;

        if (!self._sortedTable
            || !self._sortedTable.body
            || (sortType !== SORT_TYPE_ASC && sortType !== SORT_TYPE_DESC)) {
            return;
        }

        let body = self._sortedTable.body;

        if (body) {
            let rows = [].concat(body.rows);

            if (rows instanceof Array) {
                rows.sort((a: any, b: any) => {
                    if (typeof self.customSortFunction === "function") {
                        return self.customSortFunction(a, b, columnIndex, sortType);
                    } else {
                        return self.sortFunction(a, b, columnIndex, sortType);
                    }
                });
            }

            body.rows = rows;
        }
    }

    private clearTableData(type: "checkedRows" | "pinnedRows"): void {
        let self = this,
            currentType = type,
            typedTableData = this.tableData[type];

        if (self._sortedTable
            && self._sortedTable.body
            && self._sortedTable.body.rows instanceof Array
            && typedTableData instanceof Array
            && typedTableData.length
        ) {
            typedTableData.forEach((row: UxTableRow) => {

                if (currentType === "checkedRows"
                    && row.columns instanceof Array &&
                    row.columns[0].type === "checkbox") {
                    row.columns[0].value = false;
                    row.selected = false;
                }

                if (currentType === "pinnedRows"
                    && row.columns instanceof Array) {
                    row.columns.forEach((column: UxTableColumn) => {
                        if (column.pin) {
                            column.pin = false;
                        }
                    });
                }
            });
        }
    }

    private clearHeaderCheckbox(): void {
        let self = this;

        if (self._sortedTable
            && self._sortedTable.header
            && self._sortedTable.header.rows instanceof Array
        ) {
            self._sortedTable.header.rows.forEach((row: UxTableRow) => {
                if (row.columns instanceof Array &&
                    row.columns[0].type === "checkbox") {
                    row.columns[0].value = false;
                    row.selected = false;
                }
            });
        }
    }

    private setInitRowIndexes(): void {
        let body = this._sortedTable.body;

        if (this._sortedTable.body) {
            if (body.rows instanceof Array) {
                body.rows.forEach((row: UxTableRow, index: number) => {
                    if (row.initIndex === undefined) {
                        row.initIndex = index;
                    }

                    if (!row.id) {
                        row.id = `${index}${Date.now()}`;
                    }
                });


            }
        }
    }

    private handleSelection(column: UxTableColumn, columnIndex: number, row: UxTableRow, originalEvent: Event, header: boolean): void {
        let self = this;
        if (!self.viewInited) {
            return;
        }

        //change checkbox value
        if (row.columns instanceof Array && row.columns[0].type === "checkbox") {
            let checkEvent: UxTableCheckEvent = {
              uncheckedRows: [],
              checkedRows: []
            };

            if (header && column.type === "checkbox") {
                column.value = !column.value;
              checkEvent = self.changeAllCheckboxesState(column.value);
            } else if (!header) {
                row.columns[0].value = row.selected;

                if (row.selected) {
                    checkEvent.checkedRows.push(row);
                    self.tableData.checkedRows.push(row);
                } else {
                    checkEvent.uncheckedRows.push(row);
                    let index = self.tableData.checkedRows.indexOf(row);
                    self.tableData.checkedRows.splice(index, 1);
                }
            }

            this.onRowsChecked.emit(checkEvent);
        }

        self.checkForPartialRowsSelection();
    }

    private checkForPartialRowsSelection(): void {

        let self = this;

        if (self._sortedTable
            && self._sortedTable.body
            && self._sortedTable.body.rows instanceof Array
            && self.tableData
            && self.tableData.checkedRows instanceof Array
        ) {

            if (self.tableData.checkedRows.length === 0 ||
                self._sortedTable.body.rows.length === self.tableData.checkedRows.length) {
                self._partiallySelectedRows = false;
            } else {
                self._partiallySelectedRows = true;
            }

        }
    }

    private changeAllCheckboxesState(defaultValue: any): UxTableCheckEvent {
        let checkEvent: UxTableCheckEvent = {
          uncheckedRows: [],
          checkedRows: []
        };

        this._partiallySelectedRows = false;

        let body = this._sortedTable.body,
            array: UxTableRow[] = [];

        if (body) {
            let rows = body.rows;

            if (rows instanceof Array) {
                rows.forEach((row: UxTableRow) => {
                    if (row.columns instanceof Array && row.columns[0].type === "checkbox") {
                        row.columns[0].value = defaultValue;

                        if (row.selected && !defaultValue) {
                          checkEvent.uncheckedRows.push(row);
                        }
                        else if (!row.selected && defaultValue) {
                          checkEvent.checkedRows.push(row);
                        }

                        row.selected = defaultValue;

                        if (defaultValue) {
                            array.push(row);
                        }
                    }
                });
            }

            this.tableData.checkedRows = array;
        }
        return checkEvent;
    }

    private resetRowSorting(): void {
        let body = this._sortedTable.body;

        if (body) {
            let rows = [].concat(body.rows);

            if (rows instanceof Array) {
                rows.sort((a: any, b: any) => {
                    let indexA = a.initIndex,
                        indexB = b.initIndex;

                    return indexA - indexB;
                });
            }

            body.rows = rows;
        }
    }

    private getCurrentTableData(): UxTableData {
        let self = this,
            info = {
                checkedRows: <UxTableRow[]> [],
                pinnedRows: <UxTableRow[]> []
            };

        if (self._sortedTable &&
            self._sortedTable.body &&
            Array.isArray(self._sortedTable.body.rows)) {

            self._sortedTable.body.rows.forEach((row: UxTableRow) => {

                if (row.columns && Array.isArray(row.columns)) {
                    row.columns.forEach((column: UxTableColumn) => {

                        if (column.type === "checkbox") {
                            if (column.value) {
                                info.checkedRows.push(row);
                                row.selected = true;
                            } else {
                                row.selected = false;
                            }
                        }

                        if (column.pin) {
                            info.pinnedRows.push(row);
                        }

                    });
                }

            });

        }

        return info;
    }


    constructor(private cdr: ChangeDetectorRef, private zone: NgZone) {
    }

    public ngOnInit(): void {
    }

    public ngAfterViewInit(): void {
        let self = this;

        self.viewInited = true;
        self._scrolledElement = self._uxScroll.scroll;
        this.cdr.detectChanges();

        requestAnimationFrame(() => {
            self._uxScroll.updateVars();
        });

        self.zone.runOutsideAngular(() => {
            self._uxScroll.scroll.addEventListener("scroll", self.onScroll);
        });
    }

    public ngOnDestroy(): void {
        if (this._uxScroll && this._uxScroll.scroll) {
            this._uxScroll.scroll.removeEventListener("scroll", this.onScroll);
        }
    }

    private onScroll = () => {
        clearTimeout(timerId);
        timerId = null;

        let tableBody = this._tableBody.nativeElement;

        if (!tableBody.classList.contains("ux-table__disable-pointer-events")) {
            tableBody.classList.add("ux-table__disable-pointer-events");
        }

        timerId = setTimeout(() => {
            tableBody.classList.remove("ux-table__disable-pointer-events");
        }, 100);
    }

    private setTableEmptyContent(): void {
        if (this._sortedTable && this._sortedTable.emptyTableContent) {
            let content = this._sortedTable.emptyTableContent;

            if (typeof content === "string") {
                this._tableEmptyContentString = content;
                this._tableEmptyContentTemplate = null;
            } else {
                this._tableEmptyContentTemplate = content;
                this._tableEmptyContentString = null;
            }
        }
    }
}
