import {NgModule} from "@angular/core";
import {SharedModule} from "../../shared/shared.module";
import {UxEditableDropDownDirective} from "./editable-dropdown.directive";
import {UxDropdownFieldComponent} from "../fields/dropdown/dropdown-field.component";

@NgModule({
    imports: [SharedModule],
    exports: [UxEditableDropDownDirective],
    declarations: [UxEditableDropDownDirective],
    entryComponents: [UxDropdownFieldComponent]
})
export class UxEditableDropDownModule {
}

