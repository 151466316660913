import {
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    HostListener,
    Input,
    OnChanges,
    Output,
    QueryList,
    SimpleChanges,
    ViewChild,
    ViewChildren,
    TemplateRef,
    ChangeDetectorRef
} from "@angular/core";
import {UxTabPanelItem} from "../item/tab-panel-item.component";
import {UxTabChangeEvent} from "../tab-panel-event.model";
import {UxPropertyHandler} from "../../../common/decorator/ux-property-handler";

@Component({
    selector: "ux-tab-panel-header",
    templateUrl: "./tab-panel-header.component.html",
    host: {"[class.ux-tab-panel-header]": "true"}
})
export class UxTabPanelHeader implements OnChanges {

    @UxPropertyHandler({
        afterChange: function (value: boolean) {
            if (value && this._tabs && this._tabs.length) {
                window.setTimeout(() => {
                    this.checkConstrained();
                    this.recalculatePosition();
                });
            } else {
                this._tabsPanOffset = 0;
            }
        }
    })
    @HostBinding("class._mobile")
    @Input()
    public mobile: boolean = false;

    @Input()
    public itemTemplate: TemplateRef<any>;

    @Output()
    public onChange: EventEmitter<UxTabChangeEvent> = new EventEmitter<UxTabChangeEvent>();


    /** @internal */
    public _tabs: UxTabPanelItem[] = [];

    /* only for internal use*/
    @Input("tabs")
    public set tabs(items: UxTabPanelItem[]) {
        this._tabs = items;
        this.initTabs();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes["tabs"]) {
            this.initTabs();
        }
    }

    /** @internal */
    public _tabsPanOffset: number = 0;

    @ViewChild("inner", { static: true })
    private headerElementInner: ElementRef;

    @ViewChildren("tabHeaderItem")
    private tabHeaderItems: QueryList<ElementRef>;

    private readonly OFFSET: number = 20;
    private isConstrained: boolean = false;
    private tabsStartPosition: number = 0;

    constructor(
        public cdr: ChangeDetectorRef,
        private elementRef: ElementRef
    ) {
    }

    private initTabs(): void {
        if (this._tabs && this._tabs.length && this.mobile) {
            window.setTimeout(() => {
                this.checkConstrained();
            });
        }
    }

    /** @internal */
    public _clickTab(event: Event, tab: UxTabPanelItem, index: number): void {
        if (!tab.disabled) {
            this.selectNewTab(index, tab, event);
        }
        event.preventDefault();
    }

    /* only for internal use */
    @Input()
    public get selectedTab(): UxTabPanelItem {
        let selectedTabIndex = this.selectedTabIndex;
        if (selectedTabIndex >= 0) {
            return this._tabs[selectedTabIndex];
        }
        return null;
    }

    /* only for internal use */
    @Input()
    public get selectedTabIndex(): number {
        for (let i = 0; i < this._tabs.length; i++) {
            if (this._tabs[i].selected) {
                return i;
            }
        }
        return null;
    }

    /* only for internal use */
    public set selectedTabIndex(index: number) {
        if (index < this._tabs.length) {
            this.selectNewTab(index, this._tabs[index]);
        } else {
            console.error("index is incorrect. Count of tabs is " + this._tabs.length);
            //throw new Error("index is incorrect. Count of tabs is " + this._tabs.length);
        }
    }

    private selectNewTab(index: number, newTab: UxTabPanelItem, event?: Event): void {
        if (!newTab.selected) {

            this.onChange.emit({originalEvent: event, index: index, selectedTab: newTab});

            this.mobile && window.setTimeout(() => {
                this.recalculatePosition();
            });
        }
    }

    @HostListener("panmove", ["$event"])
    private onPanMove(event: any): void {
        if (this.mobile) {
            // this.checkConstrained();
            if (!this.isConstrained) {
                return;
            }

            let sum = this.tabsStartPosition + event.deltaX;
            if (sum > 0) {
                sum = 0;
            }

            let maxWidth = this.elementRef.nativeElement.offsetWidth - this.headerElementInner.nativeElement.offsetWidth - 2 * this.OFFSET;
            if (sum < maxWidth) {
                sum = maxWidth;
            }

            this._tabsPanOffset = sum;
        }
    }

    @HostListener("panstart")
    private onPanStart(): void {
        if (this.mobile) {
            this.tabsStartPosition = this._tabsPanOffset;
        }
    }

    private checkConstrained(): void {
        this.isConstrained = this.headerElementInner.nativeElement.offsetWidth + this.OFFSET * 2 > this.elementRef.nativeElement.offsetWidth;

        if (!this.isConstrained) {
            this._tabsPanOffset = 0;
        }
    }

    private getCurrentTabRect(): any {
        if (this.tabHeaderItems) {
            let tabHeaderItemElements = this.tabHeaderItems.toArray(),
                selectedTabNativeEl = tabHeaderItemElements[this.selectedTabIndex].nativeElement;

            if (selectedTabNativeEl) {
                return selectedTabNativeEl.getBoundingClientRect();
            }
        }

        return null;
    }

    private recalculatePosition(): void {
        if (!this.isConstrained) {
            return;
        }

        let currentTabRect = this.getCurrentTabRect();

        if (!currentTabRect) {
            return;
        }

        this._tabsPanOffset -= (currentTabRect.left + currentTabRect.width / 2 - document.body.clientWidth / 2);

        if (this._tabsPanOffset > 0) {
            this._tabsPanOffset = 0;
            return;
        }
    }
}
