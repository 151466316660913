import {
  Component, IterableDiffers, KeyValueDiffers, TemplateRef,
  ViewChild
} from "@angular/core";
import {AppConfigService} from "../../../common/services/app-config.service";
import {UxCardTreeModel} from "../../../../ux-lib/components/card-tree/card-tree.model";
import {CardListComponent} from "../../../components/card-list/card-list.component";
import {ReportsService} from "../../reports/reports.service";
import {ModelService} from "../../../common/services/model.service";
import {AudioEntity} from "../../../common/models/entity/audio-entity.model";
import {AudioCardModel, AudioCardModelCopier} from "../model/audio-card.model";

@Component({
  selector: "sq-audio-card-list",
  templateUrl: "audio-card-list.component.html",
  host: {"[class.sq-audio-card-list]": "true"}
})
export class AudioCardListComponent extends CardListComponent<AudioEntity> {

  @ViewChild("cardTemplate", { static: true })
  private cardTemplate: TemplateRef<any>;

  constructor(protected modelService: ModelService,
              protected reportService: ReportsService,
              protected configService: AppConfigService,
              protected iterableDiffers: IterableDiffers,
              protected keyValueDiffers: KeyValueDiffers) {
    super(modelService,
      reportService,
      iterableDiffers,
      keyValueDiffers);
  }

  protected compareCardToEntity(entity: AudioEntity, card: AudioCardModel): boolean {
    return (card.originalEntity.getId() === entity.getId());
  }

  protected updateEntityCard(entity: AudioEntity, card: AudioCardModel) {
    card.id = entity.getId().toString();
    card.hasChildren = false;
    card.child_count = 0;
    card.name = entity.name;
  }

  protected createCard(entity: AudioEntity, selected?: boolean): AudioCardModel {
    return <AudioCardModel> {
      id: entity.getId().toString(),
      visible: true,
      hasChildren: false,
      child_count: 0,
      children: undefined,
      name: entity.name,
      originalEntity: entity,
      selected: selected,
      expanded: false,
      statisticsInProgress: false,
      entityStatistics: [],
      entitySummary: undefined
    }
  }

  protected copyCard(cardFrom: AudioCardModel): AudioCardModel {
    let card: AudioCardModel = {};
    let copier = new AudioCardModelCopier();
    copier.copy(cardFrom, card);
    return card;
  }

  protected getDefaultCardModel(): UxCardTreeModel {
    return {
      template: this.cardTemplate,
      children: []
    }
  }

  protected isCardFolder(card: AudioCardModel) {
    return false;
  }


  public _trackEntityByFn(index: number, item: AudioEntity): string {
    return item && item.getId().toString();
  }
}
