import {AfterViewInit, Component, OnDestroy} from "@angular/core";
import {
  UxBreadcrumbItemModel,
  UxBreadcrumbsClickEvent
} from "../../../../ux-lib/components/breadcrumbs/breadcrumbs.component";
import {Progress} from "../../../common/models/progress.model";
import {Subscription} from "rxjs";
import {TestResultEntity} from "../../../common/models/entity/test-result-entity.model";
import {ResultsService} from "../results.service";
import {ActivatedRoute, Router} from "@angular/router";
import {UxTableRow} from "../../../../ux-lib/components/table/table.model";

@Component({
  selector: "sq-results-report-aqua",
  templateUrl: "results-report-aqua.component.html",
  host: {"[class.sq-results-report-aqua]": "true"}
})
export class ResultsReportAquaComponent implements AfterViewInit, OnDestroy {

  _breadcrumbs: UxBreadcrumbItemModel[] = [
    {label: "All reports", url: "/results/list"}
  ];

  _tableHeaders = {
    qualityResults: {
      title: "AQuA Report Quality Results"
    },
    reasons: {
      title: "AQuA Report reasons"
    },
    values: {
      title: "AQuA Report values"
    },
    spectrum: {
      title: "AQuA Report Spectrum"
    }
  };

  _tableModels = {
    qualityResults: {
      header: {
        rows: [
          {
            styleClass: "_header",
            columns: [
              {
                value: "##",
                styleClass: "_header",
                sort: true,
                resizable: false
              },
              {
                value: "Parameter",
                styleClass: "_header",
                sort: true,
                resizable: false
              },
              {
                value: "Value",
                styleClass: "_header",
                sort: true,
                resizable: false
              }
            ]
          }
          ]
      },
      body: {
        rows: []
      }
    },
    reasons: {
      header: {
        rows: [
          {
            styleClass: "_header",
            columns: [
              {
                value: "##",
                styleClass: "_header",
                sort: true,
                resizable: false
              },
              {
                value: "Reason",
                styleClass: "_header",
                resizable: false
              }
            ]
          }
        ]
      },
      body: {
        rows: []
      }
    },
    values: {
      header: {
        rows: [
          {
            styleClass: "_header",
            columns: [
              {
                value: "##",
                styleClass: "_header",
                sort: true,
                resizable: false
              },
              {
                value: "Value",
                styleClass: "_header",
                sort: true,
                resizable: false
              },
              {
                value: "Source Value",
                styleClass: "_header",
                sort: true,
                resizable: false
              },
              {
                value: "Degraded Value",
                styleClass: "_header",
                sort: true,
                resizable: false
              },
              {
                value: "Units",
                styleClass: "_header",
                sort: true,
                resizable: false
              }
            ]
          }
        ]
      },
      body: {
        rows: []
      }
    },
    spectrum: {
      header: {
        rows: [
          {
            styleClass: "_header",
            columns: [
              {
                value: "##",
                styleClass: "_header",
                sort: true,
                resizable: false
              },
              {
                value: "Reference",
                styleClass: "_header",
                sort: true,
                resizable: false
              },
              {
                value: "Test",
                styleClass: "_header",
                sort: true,
                resizable: false
              }
            ]
          }
        ]
      },
      body: {
        rows: []
      }
    }
  };

  _progressStatus: Progress = 'loading';

  private routeSubscription: Subscription;
  private resultSubscription: Subscription;

  private resultID: string;
  private resultData: TestResultEntity;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private resultsService: ResultsService) {
  }

  ngAfterViewInit(): void {
    this.routeSubscription && this.routeSubscription.unsubscribe();
    this.routeSubscription = this.route.params.subscribe(params => {
      this.resultID = params.id;
      this.updateReport();
    });
  }

  ngOnDestroy(): void {
    this.routeSubscription && this.routeSubscription.unsubscribe();
    this.resultSubscription && this.resultSubscription.unsubscribe();
  }

  private updateReport() {
    this.resultSubscription && this.resultSubscription.unsubscribe();
    this.resultSubscription = this.resultsService.getEntityById(this.resultID)
      .subscribe((value: TestResultEntity) => {
        if (value === undefined) {
          this._progressStatus = 'no_data';
        } else {
          this.resultData = value;
          this._progressStatus = this.parseAquaReport(this.resultData.report_aqua);
        }
      });
  }

  private parseAquaReport(data: string): Progress {
    let parsedData = JSON.parse(data);
    if (parsedData && parsedData["AQuAReport"]) {
      let aquaReport = parsedData["AQuAReport"];
      this.createQualityTableData(aquaReport);
      this.createReasonsTableData(aquaReport);
      this.createValuesTableData(aquaReport);
      this.createSpectrumTableData(aquaReport);

      return 'loaded';
    }
    return 'no_data';
  }

  private createQualityTableData(parsedObject: Object) {
    if (parsedObject && parsedObject["QualityResults"]) {
      let qualityResults = parsedObject["QualityResults"];

      Object.keys(qualityResults).forEach((key: string, index: number) => {
        let row: UxTableRow;
        row = {
          columns: [
            {value: index},
            {value: key},
            {value: qualityResults[key]}
          ]
        };

        this._tableModels.qualityResults.body.rows.push(row);
      });
    }
  }

  private createReasonsTableData(parsedObject: Object) {
    if (parsedObject && parsedObject["Reasons"]) {
      let reasons = parsedObject["Reasons"];

      reasons.forEach((value: Object, index: number) => {
        let row: UxTableRow;
        row = {
          columns: [
            {value: index},
            {value: value}
          ]
        };

        this._tableModels.reasons.body.rows.push(row);
      });
    }
  }

  private createValuesTableData(parsedObject: Object) {
    if (parsedObject && parsedObject["ValuesArray"]) {
      let valuesArray = parsedObject["ValuesArray"];

      Object.keys(valuesArray).forEach((key: string, index: number) => {
        let value = valuesArray[key];
        let row: UxTableRow;
        row = {
          columns: [
            {value: index},
            {value: key},
            {value: value["SourceValue"]},
            {value: value["DegradedValue"]},
            {value: value["Units"]},
          ]
        };

        this._tableModels.values.body.rows.push(row);
      });
    }
  }

  private createSpectrumTableData(parsedObject: Object) {
    if (parsedObject && parsedObject["SpectrumPairs"]) {
      let spectrumPairs = parsedObject["SpectrumPairs"];
      let pairs = spectrumPairs["Pairs"];

      if (pairs && Array.isArray(pairs)) {
        pairs.forEach((item: Object, index: number) => {
          let row: UxTableRow;
          row = {
            columns: [
              {value: index},
              {value: item["Reference"]},
              {value: item["Test"]}
            ]
          };

          this._tableModels.spectrum.body.rows.push(row);
        });
      }
    }
  }

  _onBreadcrumbsClick(event: UxBreadcrumbsClickEvent) {
    this.router.navigate([event.item.url]);
  }
}
