import {Component} from "@angular/core";

@Component({
  selector: "sq-satisfaction-chart",
  templateUrl: "satisfaction-chart.component.html",
  host: {"[class.sq-satisfaction-chart]": "true"}
})
export class SatisfactionChartComponent {

  constructor() {

  }
}
