<ng-container *ngIf="!mobile">

    <div class="ux-dropdown-field__input-wrapper">
        <input #input
               class="ux-dropdown-field__input"
               type="text"
               readonly
               (focus)="_onFocus($event)"
               (blur)="_onBlur($event)"
               (keydown)="_onKeyDown($event)"
               [disabled]="disabled">
    </div>

    <label #dropdownLabel
           (tap)="_onLabelClick($event)"
           (doubletap)="_onLabelClick($event)"
           [ngClass]="{'ux-dropdown-field__label':true,'ux-dropdown-field__label_empty': !(value && value[itemLabelKey])}"
           (blur)="_onBlur($event)"
           tabindex="-1">
        <span *ngIf="value" class="ux-dropdown-field__label-text taValue">

            <ng-template [ngTemplateOutlet]="valueTemplate || defaultValueTemplate"
                         [ngTemplateOutletContext]="{item: value}"
            ></ng-template>

        </span>
        <span *ngIf="!value && placeholder" class="ux-dropdown-field__label-placeholder">
            {{placeholder}}
        </span>
        <i *ngIf="_customIcon"
           class="ux-dropdown-field__icon"
           [ngClass]="{_input: !_noInput}">
            <ng-content select="svg"></ng-content>
        </i>
        <i *ngIf="!_customIcon"
           class="ux-dropdown-field__icon"
           [ngClass]="{_input: !_noInput}">
            <svg #customIcon
                 width="0" height="0"
                 viewBox="0 0 20 20">
                <path d="M13.418 7.859a.695.695 0 0 1 .978 0 .68.68 0 0 1 0 .969l-3.908 3.83a.697.697 0 0 1-.979 0l-3.908-3.83a.68.68 0 0 1 0-.969.695.695 0 0 1 .978 0L10 11l3.418-3.141z"/>
            </svg>
        </i>
    </label>

    <ux-field-suggestion-popup #suggestionPopup
                               [styleClass]="listStyleClass"
                               (onClick)="_onPopupClick($event)"
                               [maxListHeight]="maxListHeight"
                               [popupContainer]="listContainer"
                               attachPosition="bottom left"
                               [attachPositionsSequence]="['bottom left', 'bottom right', 'top left', 'top right','right','left']"
                               [disablePositionUpdateOnScroll]="listContainer === _elementRef"
                               [attachTo]="_dropdownLabelRef"
                               (onOpen)="_onOpen()"
                               [(opened)]="opened">
        <ng-container *ngIf="items && items.length > 0; else emptyMessage">
            <ul class="ux-dropdown-field__items" #list>
                <li *ngFor="let item of items; let i=index; trackBy: _trackByFn;" #items
                    [ngClass]="{
                    'ux-dropdown-field__item': true,
                    'taOption': true,
                    '_disabled': item.disabled,
                    'ux-dropdown-field__item_highlight': value === item,
                    'ux-dropdown-field__item_empty': !item[itemLabelKey] || item[itemLabelKey].length === 0
                }"
                    (tap)="_onItemClick(item, $event)">
                    <span class="ux-dropdown-field__item-value taValue"
                          [ngClass]="{
                            '_nowrap': !itemTemplate
                          }"
                    >

                        <ng-template [ngTemplateOutlet]="itemTemplate || defaultItemTemplate"
                                     [ngTemplateOutletContext]="{item: item}"
                        ></ng-template>

                    </span>
                </li>
            </ul>
        </ng-container>

        <ng-template #emptyMessage>

            <div class="ux-dropdown-field__empty-message" #list>

                <div class="ux-dropdown-field__empty-message-inner"
                     [innerHTML]="emptyContent"
                     *ngIf="_isTemplateString;">
                </div>

                <div class="ux-dropdown-field__empty-message-inner"
                     *ngIf="!_isTemplateString;">
                    <ng-template [ngTemplateOutlet]="_emptyContent"></ng-template>
                </div>

            </div>

        </ng-template>

    </ux-field-suggestion-popup>
</ng-container>


<label #dropdownLabel
       *ngIf="mobile"
       (tap)="_onLabelClick($event)"
       (blur)="_onBlur($event)"
       class="ux-dropdown-field__label"
       tabindex="-1">

    <select #input
            class="ux-dropdown-field__select"
            [ngModel]="value?.value"
            (ngModelChange)="_modelChange($event)"
            (focus)="_onFocus($event)"
            (blur)="_onBlur($event)"
            [disabled]="disabled"
        >
        <option *ngFor="let item of items"
                class="ux-dropdown-field__select-option taOption"
                [value]="item.value">
            {{item[itemLabelKey]}}
        </option>
    </select>
</label>


<ng-template #defaultItemTemplate let-item="item">
    {{item[itemLabelKey]}}
</ng-template>

<ng-template #defaultValueTemplate let-item="item">
    {{item && item[itemLabelKey]}}
</ng-template>
