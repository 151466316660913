import {Component, ViewChild, ElementRef, AfterViewInit, NgZone, OnDestroy, Input, HostBinding, ChangeDetectionStrategy} from "@angular/core";
import {UxAbstractViewValueFieldComponent} from "../abstract-view-value-field.component";
import {UxValueChangeEvent} from "../abstract-field.component";
import * as Hammer from "hammerjs";
import {UxDomHelper} from "../../../shared/dom/dom-helper";
import {NG_VALUE_ACCESSOR} from "@angular/forms";

@Component({
    selector: "ux-memo-field",
    templateUrl: "memo-field.component.html",
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: UxMemoFieldComponent,
            multi: true
        }
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UxMemoFieldComponent extends UxAbstractViewValueFieldComponent<string, string> implements AfterViewInit, OnDestroy {

    @Input()
    public autofocus: boolean = false;

    @HostBinding("class.ux-memo-field")
    private hostClass: boolean = true;

    @ViewChild("draggableArea", { static: true })
    private draggableArea: ElementRef;

    @ViewChild("customScrollableBlock", { static: true })
    private customScrollableBlock: ElementRef;

    private customScrollableParentBlock: HTMLElement;
    private customScrollableParentBlockWidth: number;
    private customScrollableParentBlockMinWidth: number;
    private customScrollableParentBlockHeight: number;
    private customScrollableParentBlockMinHeight: number;
    private scrollBarWidth: number = 0;
    private isFirstDrag: boolean = true;
    private focusTimeoutID: number;

    private hammerManager: HammerManager;

    protected getDefaultValue(): string {
        return "";
    }

    protected getValueConverter(): { (value: string): string } {
        return (value) => value;
    }

    /** @internal */
    public _onValueChange(event: UxValueChangeEvent<string>): void {
        if (event.oldValue !== event.newValue && this.onValueChange) {
            let newValue: string = event.newValue as any;

            this.onValueChange.emit(event);
            this.valueChange.emit(newValue);
            this.propagateChange && this.propagateChange(newValue);
            this.propagateChange && this.propagateTouch(newValue);
        }
    }

    /** @internal */
    public _onSubmitValueChange(event: Event): void {
        this.onSubmitValueChange.emit({
            value: this.value,
            originalEvent: event
        });
    }

    constructor(private zone: NgZone) {
        super();
    }

    public ngAfterViewInit(): void {
        let self = this;

        self.zone.runOutsideAngular(() => {
            const element = self.draggableArea.nativeElement;

            self.customScrollableParentBlock = self.customScrollableBlock.nativeElement.parentNode;
            self.scrollBarWidth = UxDomHelper.getScrollbarWidth();

            self.customScrollableParentBlockMinWidth = self.customScrollableParentBlock.offsetWidth;
            self.customScrollableParentBlockMinHeight = self.customScrollableParentBlock.offsetHeight;

            self.hammerManager = new Hammer.Manager(element);
            self.hammerManager.add(new Hammer.Pan({direction: Hammer.DIRECTION_ALL, threshold: 0}));
            self.hammerManager.on("pan", self.handleDrag);
        });

        if (this.autofocus) {
            this.focus();
        }
    }

    public focus(): void {
        clearTimeout(this.focusTimeoutID);
        this.focusTimeoutID = window.setTimeout(() => {
            this.focusTimeoutID = null;
            this.customScrollableBlock.nativeElement.focus();
        }, 0);
    }

    private handleDrag = (event: HammerInput): void => {

        event.preventDefault();

        if (this.isFirstDrag) {
            this.setSizeParams();
            this.isFirstDrag = false;
        }

        if (this.disabled) {
            return;
        }

        let x = this.customScrollableParentBlockWidth + event.deltaX,
            y = this.customScrollableParentBlockHeight + event.deltaY;

        this.customScrollableParentBlock.style.width = x + "px";
        this.customScrollableParentBlock.style.height = y + "px";

        if (event.isFinal) {

            if (x > this.customScrollableParentBlockMinWidth) {
                this.customScrollableParentBlockWidth = x;
            } else {
                this.customScrollableParentBlockWidth = this.customScrollableParentBlockMinWidth;
                this.customScrollableParentBlock.style.width = this.customScrollableParentBlockMinWidth + "px";
            }

            if (x > this.customScrollableParentBlockMinHeight) {
                this.customScrollableParentBlockHeight = y;
            } else {
                this.customScrollableParentBlockHeight = this.customScrollableParentBlockMinHeight;
                this.customScrollableParentBlock.style.height = this.customScrollableParentBlockMinHeight + "px";
            }

            this.isFirstDrag = true;
        }
    };

    private setSizeParams(): void {
        this.customScrollableParentBlockWidth = this.customScrollableParentBlock.offsetWidth;
        this.customScrollableParentBlockHeight = this.customScrollableParentBlock.offsetHeight;
    }

    public ngOnDestroy(): void {
        if (this.hammerManager) {
            this.hammerManager.off("pan", this.handleDrag);
            this.hammerManager.destroy();
        }
        clearTimeout(this.focusTimeoutID);
    }
}
