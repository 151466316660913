import {Component, Input} from "@angular/core";
import {UxPropertyValidator} from "../../../common/decorator/ux-property-validator";

/* deprecated use instead UxButtonIcon. todo remove in 2.0 version */
export type ButtonIcon = keyof ButtonIcons;
export type UxButtonIcon = keyof UxButtonIcons;

/* deprecated use instead UxButtonIcons. todo remove in 2.0 version */
export class ButtonIcons {
    'none' = true;
    'search' = true;
    'plus' = true;
    'close' = true;
    'location' = true;
    'bell' = true;
    'active-bell' = true;
    'language' = true;
    'user' = true;
}

export class UxButtonIcons extends ButtonIcons {
}

const BUTTON_ICONS = new ButtonIcons();

@Component({
    selector: "ux-header-button",
    templateUrl: "header-button.component.html",
    host: {"[class.ux-header-button]": "true"}
})
export class UxHeaderButtonComponent {

    /** @internal */
    public _styleClasses = {};

    @UxPropertyValidator({
        isValid: isValidIcon,
        message: "Property should be instanceof ButtonIcon"
    }, "custom")

    @Input()
    public icon: ButtonIcon = "none";

    /** @internal */
    public _setStyleClass(styleClass: string, addOrRemove: boolean = true): void {
        let self = this;
        self._styleClasses[styleClass] = addOrRemove;
        //reassign reference for angular change detection
        self._styleClasses = Object.assign({}, self._styleClasses);
    }
}

/* Helpers*/
export function isValidIcon(value: string) {
    return value === undefined || BUTTON_ICONS[value];
}
