import {Component} from "@angular/core";
import {UxAbstractPeriodicLoaderComponent} from "../abstract-periodic-loader.component";

@Component({
    selector: "ux-linear-dots-loader",
    templateUrl: "./linear-dots-loader.component.html",
    host: {"[class.ux-linear-dots-loader]": "true"}
})
export class UxLinearDotsLoaderComponent extends UxAbstractPeriodicLoaderComponent {
}
