import {
  AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, Output,
  ViewChild
} from "@angular/core";
import {UxTableHeaderModel} from "../../../../ux-lib/components/table/header/table-header.model";
import {
  UxSortTypes,
  UxTable, UxTableCheckEvent, UxTableColumn, UxTableColumnSortEvent, UxTableLinkEvent,
  UxTableRow
} from "../../../../ux-lib/components/table/table.model";
import {Router} from "@angular/router";
import {Subscription} from "rxjs";
import {ResultsService} from "../results.service";
import {TestResultEntity} from "../../../common/models/entity/test-result-entity.model";
import {ModelService} from "../../../common/services/model.service";
import {UxConfirmDialogComponent} from "../../../../ux-lib/components/confirm-dialog/confirm-dialog.component";
import {NotificationService} from "../../../common/services/notification.service";
import {UxTableComponent} from "../../../../ux-lib/components/table/table.component";
import {UxPageChangeEvent} from "../../../../ux-lib/components/paging/paging.component";
import {PagingModel} from "../../../common/models/paging.model";
import {AppConfigService} from "../../../common/services/app-config.service";
import {DeleteOperationResult} from "../../../common/models/result.type";

export interface ResultColumnSortEvent {
  columnIndex: number;
  columnName: string;
  sortOrder: UxSortTypes;
}

@Component({
  selector: "sq-results-list-advanced",
  templateUrl: "results-list-advanced.component.html",
  host: {"[class.sq-results-list-advanced]": "true"}
})
export class ResultsListAdvancedComponent implements AfterViewInit, OnDestroy {

  private _title: string = "";

  @Input()
  set title(value:string) {
    this._title = value;
    this._tableHeaderModel.title = value;
  }

  get title(): string {
    return this._title;
  }

  private _resultsData: TestResultEntity[] = [];
  @Input()
  set resultsData(results: TestResultEntity[]) {
    this._resultsData = results;
    this.filterResults();
  }

  get testsData(): TestResultEntity[] {
    return this._resultsData;
  }

  private _emptyTableContent: string;
  @Input()
  set emptyTableContent(value: string) {
    this._emptyTableContent = value;
    this._model.emptyTableContent = value;
    this.resultsAdvancedTable.update();
  }
  get emptyTableContent(): string {
    return this._emptyTableContent;
  }

  @Input()
  public headerAddCaption: string;

  @Input()
  public headerRemoveCaption: string;

  @Input()
  public footerActionCaption: string;

  @Input()
  public footerCancelCaption: string;

  @Input()
  public enableSearch: boolean = true;

  private _fullView: boolean = true;
  @Input()
  set fullView(value: boolean) {
    this._fullView = value;
    this.updateTableView();
  }

  get fullView(): boolean {
    return this._fullView;
  }

  @Output()
  public onHeaderAddButton: EventEmitter<void> = new EventEmitter();

  @Output()
  public onHeaderRemoveButton: EventEmitter<void> = new EventEmitter();

  @Output()
  public onFooterActionButton: EventEmitter<void> = new EventEmitter();

  @Output()
  public onFooterCancelButton: EventEmitter<void> = new EventEmitter();

  @Output()
  public onTableLinkClicked: EventEmitter<UxTableLinkEvent> = new EventEmitter<UxTableLinkEvent>();

  @Output()
  onDynamicSortClick = new EventEmitter<ResultColumnSortEvent>();

  @Input()
  public pagingTotalCount: number;

  @Output()
  public onTablePageChange: EventEmitter<UxPageChangeEvent> = new EventEmitter<UxPageChangeEvent>();

  @Output()
  public onTablePageSizeChange: EventEmitter<UxPageChangeEvent> = new EventEmitter<UxPageChangeEvent>();

  _tableHeaderModel: UxTableHeaderModel = {
    title: this.title
  };

  _model: UxTable = {
    header: {
      rows: []
    },
    body: {rows: []},
    emptyTableContent: `No results found`
  };

  _selectedResults: (number|string)[] = [];
  public lastSortEvent: ResultColumnSortEvent;

  public paging: PagingModel = {
    pageSize: 10,
    currentPage: 1,
    pageSizeOptions: [10, 20, 50, 100]
  };

  _searchString: string = "";

  @ViewChild("resultsAdvancedTable", { static: true })
  resultsAdvancedTable: UxTableComponent;

  @ViewChild("resultsAdvancedConfirmDialog", { static: true })
  resultsAdvancedConfirmDialog: UxConfirmDialogComponent;
  _confirmationText: string = "";

  private confirmDialogSubscription: Subscription;
  private resultsDeleteSubscription: Subscription;

  constructor(private resultsService: ResultsService,
              private notificationService: NotificationService,
              private modelService: ModelService,
              private configService: AppConfigService,
              private router: Router,
              private cdr: ChangeDetectorRef) {

    this.paging.pageSize = this.configService.paging().pageSize;
    this.paging.pageSizeOptions = this.configService.paging().pageSizeOptions;
  }

  ngAfterViewInit(): void {
  }

  ngOnDestroy(): void {
    this.confirmDialogSubscription && this.confirmDialogSubscription.unsubscribe();
    this.resultsDeleteSubscription && this.resultsDeleteSubscription.unsubscribe();
  }

  public resetHeader(column: ResultColumnSortEvent) {
    this.resultsAdvancedTable.resetHeaderSort(column ? column.columnIndex : undefined, column ? column.sortOrder : undefined);
  }

  public deleteSelectedResults() {
    let toDelete: string[] = this.getCheckedResults();

    if (toDelete.length > 0) {
      if (toDelete.length > 1) {
        this.resultsAdvancedConfirmDialog.caption = "Delete results";
        this._confirmationText = "Are you sure you want to delete selected test results?"
      }
      else {
        this.resultsAdvancedConfirmDialog.caption = "Delete result";
        this._confirmationText = "Are you sure you want to delete selected test result?"
      }
      this.resultsAdvancedConfirmDialog.visible = true;

      this.confirmDialogSubscription && this.confirmDialogSubscription.unsubscribe();
      this.confirmDialogSubscription = this.resultsAdvancedConfirmDialog.getAccept().subscribe((accepted: boolean) => {
        if (accepted) {
          this.deleteResults(toDelete);
        }
      });
    }
  }

  private deleteResults(deleteIds: string[]) {
    if (deleteIds.length > 0) {
      let resultID = deleteIds.pop();
      this.resultsDeleteSubscription && this.resultsDeleteSubscription.unsubscribe();
      this.resultsDeleteSubscription = this.resultsService.deleteEntity(resultID)
        .subscribe((data: DeleteOperationResult) => {
          if (data.opResult) {
            this.deleteResultItem(resultID);
            this.deleteResults(deleteIds);
          }
          else {
            this.notificationService.pushNotification({
              type: "error",
              caption: "Deletion error",
              content: `Fail to delete test result ${resultID}`
            })
          }
        });
    }
  }

  public getCheckedResults(): string[] {
    return this.resultsAdvancedTable.tableData.checkedRows.map((row: UxTableRow) => {
      return row.id;
    }) as string[];
  }

  public addResult(result: TestResultEntity): void {
    this._model.body.rows.push(this.getTableRow(result));
  }

  public deleteResultItem(resultID: string) {
    let deletedIndex: number = this.modelService.findInArray(this._model.body.rows, (item: UxTableRow) => {
      return (item.id === resultID);
    });
    if (deletedIndex >= 0) {
      this._model.body.rows.splice(deletedIndex, 1);
    }
    deletedIndex = this._selectedResults.indexOf(resultID);
    if (deletedIndex >= 0) {
      this._selectedResults.splice(deletedIndex, 1);
    }
  }

  private updateTableBody() {
    this._model.body.rows =
      this._resultsData.map((test: TestResultEntity) => {
        return this.getTableRow(test);
      });
  }

  _onTableLinkClicked(eventData: UxTableLinkEvent) {
    this.onTableLinkClicked.emit(eventData);
  }

  _onTableRowsChecked(checkEvent: UxTableCheckEvent) {
    let checkedRows: string[] = checkEvent.checkedRows.map((row: UxTableRow) => {return row.id.toString();});
    let uncheckedRows: string[] = checkEvent.uncheckedRows.map((row: UxTableRow) => {return row.id.toString();});

    this._selectedResults = this._selectedResults.filter((value: string) => {
      let found = uncheckedRows.indexOf(value);
      if (found >= 0) {
        uncheckedRows.splice(found, 1);
      }
      return found === -1;
    });

    this._selectedResults = this._selectedResults.concat(checkedRows);
  }

  _onDynamicSortClick(event: UxTableColumnSortEvent) {
    let column;
    switch (event.columnIndex) {
      case 4:
        column = "duration";
        break;
      case 5:
        column = "time";
        break;
      case 6:
        column = "mos_pvqa";
        break;
      case 7:
        column = "mos_aqua";
        break;
    }
    if (column) {
      this.lastSortEvent = {
        columnIndex: event.columnIndex,
        columnName: column,
        sortOrder: event.sortOrder
      };

      this.onDynamicSortClick.emit(this.lastSortEvent);
    } else {
      delete this.lastSortEvent;
      this.resultsAdvancedTable.sortTableColumn(event.columnIndex, event.sortOrder);
    }
  }

  _onAddClicked() {
    this.onHeaderAddButton.emit();
  }

  _onRemoveClicked() {
    this.onHeaderRemoveButton.emit();
  }

  _onCancelClicked() {
    this.onFooterCancelButton.emit();
  }

  _onOkClicked() {
    this.onFooterActionButton.emit();
  }

  _onSearchValueChange(value: string) {
    this._searchString = value;
    this.filterResults();
  }

  private filterResults() {
    this._model.body.rows =
      this._resultsData
        .filter((result: TestResultEntity) => {
          return (this._searchString.length === 0) ||
            (result.phone_name && result.phone_name.indexOf(this._searchString) >= 0) ||
            (result.target && result.target.indexOf(this._searchString) >= 0) ||
            (result.task_name && result.task_name.indexOf(this._searchString) >= 0)
        })
        .map((result: TestResultEntity) => {
          return this.getTableRow(result);
        });
  }

  private getTableRow(result: TestResultEntity): UxTableRow {
    let columns: UxTableColumn[]  = [
      {
        type: "checkbox",
        value: this._selectedResults.indexOf(result.id) >= 0,
      },
      {
        value: result.phone_name
      },
      {
        value: result.target
      },
      {
        value: result.task_name
      },
      {
        value: this.modelService.formatSeconds(result.duration)
      },
      {
        value: result.endtime > 0 ? new Date(result.endtime*1000).toLocaleTimeString() : ""
        //value: result.endtime > 0 ? new Date(result.endtime*1000).toLocaleString() : ""
      },
      {
        value: result.mos_pvqa === 0 ? result.mos_pvqa : result.mos_pvqa.toFixed(3)
      },
      {
        value: result.mos_aqua === 0 ? result.mos_aqua : result.mos_aqua.toFixed(3)
      },
      {
        type: "link",
        value: (result.report_pvqa === undefined) ? "" : "View report",
        url: "/results/report/pvqa/" + result.id
      },
      {
        type: "link",
        value: this.isEmpty(result.report_aqua) ? "" : "View report",
        url: "/results/report/aqua/" + result.id
      },
    ];
    if (this._fullView) {
      //
    }

    return {
      id: result.id,
      columns: columns
    };
  }

  private isEmpty(value: any) {
    return value === undefined || value === null;
  }

  private updateTableView(): void {
    this.updateHeader();
    this.updateTableBody();
  }

  private updateHeader(): void {
    this._model.header.rows = [
      {
        styleClass: "_header",
        columns: [
          {
            type: "checkbox",
            value: false,
            styleClass: "_header"
          },
          {
            value: "Device",
            styleClass: "_header",
            sort: true,
            resizable: true,
            contentModel: {
              "id": "name"
            }
          },
          {
            value: "Target",
            styleClass: "_header",
            sort: true,
            resizable: true,
            contentModel: {
              "id": "name"
            },
          },
          {
            value: "Test",
            styleClass: "_header",
            sort: true,
            resizable: true,
            contentModel: {
              "id": "name"
            }
          },
          {
            value: "Duration",
            styleClass: "_header",
            sort: true,
            resizable: true,
            contentModel: {
              "id": "name"
            }
          },
          {
            value: "End time",
            styleClass: "_header",
            sort: true,
            resizable: true,
            contentModel: {
              "id": "name"
            },
          },
          {
            value: "Mos (pvqa)",
            styleClass: "_header",
            sort: true,
            resizable: true,
            contentModel: {
              "id": "name"
            }
          },
          {
            value: "Mos (aqua)",
            styleClass: "_header",
            sort: true,
            resizable: true,
            contentModel: {
              "id": "name"
            }
          },
          {
            value: "Report (pvqa)",
            styleClass: "_header",
            resizable: true,
            contentModel: {
              "id": "name"
            }
          },
          {
            value: "Report (aqua)",
            styleClass: "_header",
            contentModel: {
              "id": "name"
            }
          }
        ]
      }
    ];

    if (this._fullView) {
    }
  }
}
