/**
 * To register custom converter:
 * 1) Add converter name to <b>UxPropertyConverterName</b> type
 * 2) Register converter instance of <b>PropertyConverter</b> in <b>PropertyConverterStorage</b>, see PropertyConverterStorage.register in this file
 * 3) Usage:
 *
 * class Test {
 *     @UxPropertyConverter("boolean")
 *     value:any = "false"; //will be converted to false typeof boolean
 * }
 * let test = new Test();
 * test.value = "true";
 * console.log(`value = ${test.value}`); //should be true typeof boolean
 *
 *
 */
import {UxPropertyDecoratorBuilder} from "./ux-property.decorator";

/**
 * 1) Add converter name
 */
export type UxPropertyConverterName = "custom" | "boolean" | "booleanOrUndefined" | "number" | "string" | "string[]";

/**
 * Decorator for class properties to convert set value
 * Usage:
 *
 * class Test {
 *     @UxPropertyConverter("boolean")
 *     value:any = "false"; //will be converted to false typeof boolean
 * }
 * let test = new Test();
 * test.value = "true";
 * console.log(`value = ${test.value}`); //should be true typeof boolean
 *
 * @param name - converter name
 * @param nullOrUndefinedValue - value will be used when set property to null or undefined
 * @param convertSettings - settings to convert
 * @returns {{(any, string, PropertyDescriptor): void}}
 * @constructor
 */
export function UxPropertyConverter<T>(name: UxPropertyConverterName, nullOrUndefinedValue?: T, convertSettings?: {}) {
    let withNullOrUndefinedValue = arguments.length >= 2;

    let builder = Object.create(UxPropertyDecoratorBuilder.prototype);
    builder.constructor = UxPropertyDecoratorBuilder;

    return builder
        .setBeforeValueChange(function (newValue: T, oldValue: T, className: string, propertyName: string) {
            if (name) {
                let converter = PropertyConverterStorage.get(name);
                if (converter) {
                    if ((newValue !== null && newValue !== undefined) || !withNullOrUndefinedValue) {
                        try {
                            newValue = converter.convert.apply(this, [newValue, convertSettings]);
                        } catch (e) {
                            throw new Error(`Error to convert property value: ${e.message ? e.message : e}: converter=${converter.name}, class=${className}, property=${propertyName}, newValue=${newValue} typeof ${typeof newValue}`);
                        }
                    }
                    return (newValue === null || newValue === undefined) && withNullOrUndefinedValue ? nullOrUndefinedValue : newValue;
                } else {
                    console.log(`Property value converter is not registered: converter=${converter.name}, class=${className}, property=${propertyName}`);
                }
            }
            return newValue;
        })
        .setNullOrUndefinedValue(nullOrUndefinedValue)
        // .setDebug(true)
        .setDecoratorName("UxPropertyConverter")
        .getDecorator();
};

interface PropertyConverter {
    //TODO make proper generics
    convert: (fromValue: any, settings?: {}) => any;
    name: UxPropertyConverterName;
}

class PropertyConverterStorage {
    private static converters: { [key: string]: PropertyConverter } = {};

    private constructor() {
    }

    public static register(...converters: PropertyConverter[]) {
        if (converters) {
            for (let converter of converters) {
                if (converter) {
                    if (!PropertyConverterStorage.converters[converter.name]) {
                        PropertyConverterStorage.converters[converter.name] = converter;
                    } else {
                        throw new Error(`Converter for name '${converter.name}' is already registered in storage. Can't register new converter for the same name.`);
                    }
                }
            }
        }
    }

    public static get(name: UxPropertyConverterName): PropertyConverter {
        return PropertyConverterStorage.converters[name];
    }
}

/**
 * 2) Register converter instance
 */
PropertyConverterStorage.register(
    {
        convert: function (fromValue: any, settings?: { convert?: (item: any) => any }): boolean {
            return settings && settings.convert ? settings.convert.apply(this, [fromValue]) : fromValue;
        },
        name: "custom"
    },
    {
        convert: convertToBoolean,
        name: "boolean"
    },
    {
        convert: convertToBooleanOrUndefined,
        name: "booleanOrUndefined"
    },
    {
        convert: convertToNumber,
        name: "number"
    },
    {
        convert: convertToString,
        name: "string"
    },
    {
        convert: convertToStringArray,
        name: "string[]"
    }
);

export function convertToBooleanOrUndefined(fromValue: any): boolean {
    if (typeof(fromValue) === "string") {
        fromValue = fromValue.trim().toLowerCase();
    }
    switch (fromValue) {
        case true:
        case "true":
        case 1:
        case "1":
        case "on":
        case "yes":
            return true;
        case "undefined":
        case "null":
        case null:
        case undefined:
            return undefined;
        default:
            return false;
    }
}

export function convertToBoolean(fromValue: any): boolean {
    if (typeof(fromValue) === "string") {
        fromValue = fromValue.trim().toLowerCase();
    }
    switch (fromValue) {
        case true:
        case "true":
        case 1:
        case "1":
        case "on":
        case "yes":
            return true;
        default:
            return false;
    }
}

export function convertToNumber(fromValue: any): number {
    if (typeof(fromValue) === "string") {
        fromValue = fromValue.trim().toLowerCase();

        if (fromValue === "") {
            fromValue = undefined;

            return fromValue;
        }
    }
    fromValue = +fromValue;
    if (isNaN(fromValue)) {
        fromValue = undefined;
    }
    return fromValue;
}

export function convertToStringArray(fromValue: any, settings?: { map?: (item: string) => string }): string[] {
    let toValue: string[];
    if (typeof fromValue === "string") {
        fromValue = (fromValue as String).split(/[, ]/);
    }
    if (fromValue) {
        let mapFn = settings && typeof settings.map === "function" ? settings.map : undefined;
        toValue = [];
        for (let value of fromValue) {
            if (value && (value = ("" + value).trim())) {
                toValue.push(mapFn ? mapFn(value) : value);
            }
        }
    }
    return toValue;
}

export function convertToString(fromValue: any): string {
    return "" + fromValue;
}

export function convertToDate(fromValue: any): Date {
    if (fromValue && typeof fromValue === "string") {
        let date = Date.parse(fromValue);
        if (!isNaN(date)) {
            return new Date(date);
        } else {
            let timeRxp = new RegExp("^([0-1]?[0-9]|[2][0-3]):([0-5]?[0-9])\:?([0-5]?[0-9])?$");
            let result = timeRxp.exec(fromValue);
            if (result) {
                let date = new Date();
                date.setHours(parseInt(result[1]));
                date.setMinutes(parseInt(result[2]));
                date.setSeconds(result[3] ? parseInt(result[3]) : 0);
                return date;
            }

        }
    }
    if (fromValue instanceof Date) {
        return fromValue;
    }
    return new Date();
}
