
import {QueryParam} from "../models/query-param.type";

export class UrlBuilder {
  private pathParamsData: Map<string, string> = new Map<string, string>();
  private queryParamsData: QueryParam[] = [];

  constructor(private urlTemplate: string) {
  }

  public pathParam(key: string, value: any | null): UrlBuilder {
    this.pathParamsData.set(key, value == null ? "null" : value.toString());
    return this;
  }

  public pathParams(value: any): UrlBuilder {
    if (value) {
      for (const key in value) {
        if (value.hasOwnProperty(key)) {
          this.pathParam(key, value[key]);
        }
      }
    }
    return this;
  }

  public queryParams(value: QueryParam[]): UrlBuilder {
    this.queryParamsData = [];
    if (value) {
      this.queryParamsData = this.queryParamsData.concat(value);
    }
    return this;
  }

  public url(url: string): UrlBuilder {
    this.urlTemplate = url;
    return this;
  }

  public build(): string {
    let url = this.urlTemplate;
    if (this.pathParamsData.size > 0) {
      this.pathParamsData.forEach((value: string, key: string) => {
        url = url.replace(key, this.convertValue(value));
      });
    }
    if (this.queryParamsData.length > 0) {
      let isFirst = true;
      this.queryParamsData.forEach((param: QueryParam) => {
        const prefix = isFirst ? "?" : "&";
        isFirst = false;
        url += prefix + param.key + "=" + this.convertValue(param.value);
      });
    }
    return url;
  }

  private convertValue(value: any | null): string {
    if (value == null) {
      return "null";
    } else {
      return encodeURIComponent(value.toString());
    }
  }
}
