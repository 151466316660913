import { HostBinding, Input, OnDestroy, Directive } from "@angular/core";
import {UxPropertyConverter} from "../../common/decorator/ux-property-converter";
import {UxPropertyHandler} from "../../common/decorator/ux-property-handler";

@Directive()
export abstract class UxAbstractPeriodicLoaderComponent implements OnDestroy {
    @Input()
    @UxPropertyConverter("number", 200)
    @UxPropertyHandler({
        afterChange: afterChangeDebounce
    })
    public debounce: number;

    private _debounceTimeout: number = 0;

    @HostBinding("class._visible")
    @UxPropertyConverter("boolean", false)
    @UxPropertyHandler({
        afterChange: afterVisibilityChange
    })
    private _visible: boolean;

    private visibleTemp: boolean = false;

    @Input()
    set visible(visible: boolean) {
        let self = this;
        if (self.visibleTemp !== visible) {
            self.visibleTemp = visible;
            self.stopTimeout();
            if (self.visibleTemp && self.debounce > 0) {
                self.createTimeout(self.debounce);
            } else {
                self._visible = self.visibleTemp;
            }
        }
    }

    get visible(): boolean {
        return this._visible;
    }

    @Input()
    @UxPropertyConverter("string", undefined)
    public color: boolean;

    @Input()
    public mobile: boolean = false;

    public ngOnDestroy(): void {
        this.stopTimeout();
    }

    private stopTimeout(): void {
        if (this._debounceTimeout) {
            clearTimeout(this._debounceTimeout);
            this._debounceTimeout = null;
        }
    }

    private createTimeout(debounce: number): void {
        let self = this;
        self.stopTimeout();
        self._debounceTimeout = window.setTimeout(
            () => {
                self._visible = true;
                self._debounceTimeout = null;
            },
            debounce
        );
    }

    protected afterVisibilityChange(): void {}
}

/* Helpers */
export function afterVisibilityChange(): void {
    this.afterVisibilityChange();
}

export function afterChangeDebounce(newValue: number) {
    let self = this;
    if (self.visibleTemp && self.debounce > 0) {
        self.createTimeout(newValue);
    }
}
