import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output
} from "@angular/core";
import {FormBuilder, FormGroup, ValidationErrors, Validators} from "@angular/forms";
import {DictionaryService} from "../../../common/services/dictionary.service";
import {Subject, Subscription} from "rxjs";
import {DictionaryType} from "../../../common/models/entity/dictionary-type.model";
import {takeUntil, tap} from "rxjs/operators";
import {ModelService} from "../../../common/services/model.service";
import {NotificationService} from "../../../common/services/notification.service";
import {TestsService} from "../tests.service";
import {TestEntity, UpdateTestEntity} from "../../../common/models/entity/test-entity.model";
import {ReferenceSelectorItem} from "../../../../ux-lib/components/fields/reference/reference-item.model";
import {DevicesService} from "../../devices/devices.service";
import {DeviceEntity} from "../../../common/models/entity/device-entity.model";
import {PopupComponent} from "../../../components/base/popup.component";
import {UxValueChangeEvent} from "../../../../ux-lib/components/fields/abstract-field.component";
import {AudioService} from "../../audio/audio.service";
import {PostOperationResult, PutOperationResult} from "../../../common/models/result.type";
import {UxRadioItemModel} from "../../../../ux-lib/components/fields/radio-group/radio/radio-field.component";
import {UxTabChangeEvent} from "../../../../ux-lib/components/tab-panel/tab-panel-event.model";

const TEST_DEFAULT_CRON: string = "0 0 * ? * *";

@Component({
  selector: "sq-test-edit",
  templateUrl: "test-edit.component.html",
  host: {"[class.sq-test-edit]": "true"}
})
export class TestEditComponent extends PopupComponent<TestEntity> {

  _formGroup: FormGroup;
  _selectedTabIndex = 0;
  private _availableTestsTypes: DictionaryType[] = [];
  _selectedTarget: ReferenceSelectorItem[];
  _availableTargets: ReferenceSelectorItem[] = [];
  private _availableTargetDevices: DeviceEntity[] = [];
  private _creatingTarget: ReferenceSelectorItem = {};
  _operationErrorText: string = "";

  _jsonAttributes: string;

  private stopSubscription$ = new Subject<boolean>();
  private dictionarySubscription$: Subscription;
  private testCreateSubscription$: Subscription;
  private testUpdateSubscription$: Subscription;
  private devicesSubscription$: Subscription;

  _creatingMode: boolean = false;
  @Input()
  public set creatingMode(value: boolean) {
    this._creatingMode = value;
    if (this._creatingMode) {
      this.testModel = undefined;
    }
  }

  public get creatingMode(): boolean {
    return this._creatingMode;
  }

  private _testID: string|number;
  _testModel: TestEntity = new TestEntity();
  @Input()
  public set testModel(value: TestEntity) {
    if (value) {
      this._testID = value.id;
      this._testModel.copyFrom(value);
      let item: ReferenceSelectorItem = this._availableTargets
        .find((value: ReferenceSelectorItem) => {
          return value.id === this._testModel.target_id.toString();
        });
      if (item !== undefined) {
        this._selectedTarget = [item];
      }
      else {
        this._selectedTarget = undefined;
      }

      this._selectedTabIndex = 0;

      this._formGroup.patchValue({
        "testName": value.name,
        "testTarget": this._selectedTarget,
        "cronCommand": value.schedule,
      });
    }
    else {
      this._testModel = new TestEntity();
      this.resetFormData();
    }
  }

  public get testModel(): TestEntity {
    return this._testModel;
  }

  @Input()
  public parentEntity: TestEntity;

  @Output()
  public onCreateComplete: EventEmitter<TestEntity> = new EventEmitter<TestEntity>();

  @Output()
  public onEditComplete: EventEmitter<UpdateTestEntity> = new EventEmitter<UpdateTestEntity>();

  constructor(private testsService: TestsService,
              private devicesService: DevicesService,
              private modelService: ModelService,
              private notificationService: NotificationService,
              private dictionaryService: DictionaryService,
              private formBuilder: FormBuilder,
              private cdRef: ChangeDetectorRef) {
    super();
  }

  public initComponent() {
    this.initForm();
    this.updateTestsTargets();
    this.cdRef.detectChanges();
  }

  public destroyComponent() {
    this.stopSubscription$.next(true);
    this.stopSubscription$.complete();
  }

  private initForm(): void {
    this._selectedTabIndex = 0;
    this._formGroup = this.formBuilder.group({
      "testName": ["", Validators.compose([Validators.required])],
      "testTarget": ["", Validators.compose([Validators.required])],
      "cronCommand": [TEST_DEFAULT_CRON, Validators.compose([Validators.required])]
    });
  }

  private updateTestsTargets() {
    this.devicesSubscription$ && this.devicesSubscription$.unsubscribe();
    this.devicesSubscription$ = this.devicesService
      .getEntityList()
      .pipe(
        takeUntil(this.stopSubscription$),
        tap((values: DeviceEntity[]) => {
          this._availableTargetDevices = values
            .filter((value: DeviceEntity) => {
              return this.extractDeviceTypeDirection(value.type) === 'answerer';
            });

          this._availableTargets = this._availableTargetDevices
            .map((value: DeviceEntity) => {
            return {
              id: value.id.toString(),
              caption: value.instance,
              type:'token'
            };
          });
        })
      )
      .subscribe();
  }

  private extractDeviceTypeDirection(value: string): string {
    let devDirection = 'caller';

    if (value.indexOf('/') >= 0) {
      devDirection = value.split('/')[1];
    }

    return devDirection;
  }

  _onTestNameChange(event: UxValueChangeEvent<string>) {
    if (event && event.newValue) {
      this._testModel.name = event.newValue;
    }
  }

  _onCreateNewTargetValue(newValue: string) {
    let self=  this;
    self._creatingTarget.id = "{test-create-target-555ec657-aa2d-436c-913f-ff5507f6acff}";
    self._creatingTarget.caption = newValue;
    self._creatingTarget.type = "token";

    this._selectedTarget = [self._creatingTarget];
    let found = this._availableTargets.find((item: ReferenceSelectorItem) => item.id === self._creatingTarget.id);
    if (found === undefined) {
      this._availableTargets.push(self._creatingTarget);
    }
  }

  public _getErrorText(controlName: string): string {
    let errorText = "",
      control = this._formGroup.controls[controlName];

    if (control.errors) {
      errorText = "!";
      let errors: ValidationErrors = control.errors;

      if (controlName === "testName") {
        if (errors["required"] !== undefined) {
          errorText = "Test name is required";
        }
      } else if (controlName === "testTarget") {
        if (errors["required"] !== undefined) {
          errorText = "Test target is required";
        }
      } else if (controlName === "cronCommand") {
        if (errors["required"] !== undefined) {
          errorText = "Cron command is required";
        }
      }
    }
    return errorText;
  }

  _onEditConfirm() {
    let targetDevice: DeviceEntity = this.getTargetDevice(this._selectedTarget && this._selectedTarget[0] && this._selectedTarget[0].id);
    this._testModel.target = targetDevice && targetDevice.number;
    this._testModel.target_id = targetDevice && targetDevice.id;

    this._testModel.attributes = this._jsonAttributes;

    if (this.creatingMode) {
      this._testModel.command = "call";

      if (this.parentEntity) {
        this._testModel.parent_id = this.parentEntity.id;
      }
      else {
        this._testModel.parent_id = 0;
      }

      this.testCreateSubscription$ && this.testCreateSubscription$.unsubscribe();
      this.testCreateSubscription$ = this.testsService
        .createEntity(this._testModel)
        .pipe(
          takeUntil(this.stopSubscription$),
          tap((data: PostOperationResult<TestEntity>) => {
            if (data.opResult) {
              let createdTest = new TestEntity(data.opEntity.parent_id, data.opEntity.id);
              createdTest.copyFrom(data.opEntity);
              this.onCreateComplete.emit(createdTest);
              this._close();
            } else {
              this._operationErrorText = "Failed o create test";
            }
          })
        )
        .subscribe()
    }
    else {

      this.testUpdateSubscription$ && this.testUpdateSubscription$.unsubscribe();
      this.testUpdateSubscription$ = this.testsService
        .updateEntityById(this._testID, this._testModel)
        .pipe(
          takeUntil(this.stopSubscription$),
          tap((data: PutOperationResult<TestEntity>) => {
            if (data.opResult) {
              let updateTest: UpdateTestEntity = {
                entityID: this._testID,
                value: this._testModel
              }
              this.onEditComplete.emit(updateTest);
              this._close();
            }
            else {
              this._operationErrorText = "Failed o update test information";
            }
          })
        )
        .subscribe();
    }
  }

  private getTargetDevice(deviceID: number|string): DeviceEntity {
    if (deviceID === undefined || deviceID === null) {
      return undefined;
    }

    return this.modelService.findEntityInArray(this._availableTargetDevices, (device: DeviceEntity) => {
      return device.id.toString() === deviceID.toString();
    });
  }

  protected _close() {
    this.resetFormData();
    super._close();
  }

  private resetFormData() {
    this._selectedTabIndex = 0;
    this._formGroup.updateValueAndValidity();
    this._formGroup.reset();
    this._formGroup.markAsUntouched();
    this._formGroup.patchValue({
      "cronCommand": TEST_DEFAULT_CRON
    });
  }


  private createNewReference(newValue: string) {
    let self=  this;
    self._creatingTarget.id = "{test-create-target-555ec657-aa2d-436c-913f-ff5507f6acff}";
    self._creatingTarget.caption = newValue;
    self._creatingTarget.type = "token";

    this._selectedTarget = [self._creatingTarget];
    let found = this._availableTargets.find((item: ReferenceSelectorItem) => item.id === self._creatingTarget.id);
    if (found === undefined) {
      this._availableTargets.push(self._creatingTarget);
    }
  }

  onTabChange(event: UxTabChangeEvent): void {
    this._selectedTabIndex = event.index;
  }
}
