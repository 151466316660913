import {
    AfterViewInit,
    Compiler,
    ComponentFactoryResolver,
    Directive,
    ElementRef,
    EmbeddedViewRef,
    EventEmitter,
    HostBinding,
    Input,
    NgZone,
    OnDestroy,
    Output,
    Renderer2,
    TemplateRef,
    ViewContainerRef
} from "@angular/core";
import {UxDomHelper} from "../../shared/dom/dom-helper";
import {UxTextFieldComponent} from "../fields/text/text-field.component";


export interface UxEditableDirectiveModel {
    maxWidth?: number;
    minWidth?: number;
    popoverStyleClass?: string;
    customData?: any;
    appearenceTimeout?: number;
}



@Directive({
    selector: "[uxEditable]",
    exportAs: "uxEditable"
})
export class UxEditableDirective implements AfterViewInit, OnDestroy {

    private _uxEditable: string;

    private defaultModel: UxEditableDirectiveModel = {minWidth: 200};

    @Input("uxEditable")
    public set uxEditable(value: string) {
        this._uxEditable = value;

        if (this.textFieldComponent) {
            this.textFieldComponent.value = value;
        }
    }

    public get uxEditable(): string {
        return this._uxEditable;
    }

    @Input("uxEditableContainer")
    public set uxEditableContainer(value: string | Element) {
        if (value) {
            this._uxEditableContainer = value;
        }
    };

    public get uxEditableContainer(): string | Element {
        return this._uxEditableContainer;
    };

    private _uxEditableContainer: string | Element = "body";

    @Input("uxEditableModel")
    public set model(value: UxEditableDirectiveModel) {
        if (value && value.popoverStyleClass !== this._model.popoverStyleClass && this.currentPopup) {
            this._model.popoverStyleClass && this.currentPopup.classList.remove(this._model.popoverStyleClass);
            value.popoverStyleClass && this.currentPopup.classList.add(value.popoverStyleClass);
        }
        this._model = Object.assign(this.defaultModel, value);
    }

    public get model(): UxEditableDirectiveModel {
        return this._model;
    }

    @Input("uxEditableTemplate")
    public template: TemplateRef<any>;

    @Input("uxEditableConfirmButton")
    public hasConfirmButton: boolean = false;


    @Output()
    public uxEditableChange: EventEmitter<string> = new EventEmitter<any>();

    @Output()
    public onEditToggle: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Output()
    public onEditSubmit: EventEmitter<void> = new EventEmitter<void>();

    @Output()
    public onEditCancel: EventEmitter<void> = new EventEmitter<void>();


    private _model: UxEditableDirectiveModel = this.defaultModel;
    private currentPopup: any;
    private hostElement: Element;
    private hostElementParameters: ClientRect;
    private textFieldComponent: UxTextFieldComponent;
    private contentEmbeddedView: EmbeddedViewRef<any>;
    private timeoutId: number;


    @HostBinding("class._ux-editable")
    private hostClass = true;


    constructor(private elementReference: ElementRef,
                private zone: NgZone,
                private renderer: Renderer2,
                private viewContainerRef: ViewContainerRef,
                private componentFactoryResolver: ComponentFactoryResolver) {
    }


    public ngAfterViewInit(): void {
        this.zone.runOutsideAngular(() => {
            this.hostElement = this.elementReference.nativeElement;
            this.createPen();
            this.bindEvents();
        });
    }

    private bindEvents(): void {
        this.hostElement.addEventListener("click", this.onClick);
    }

    private onClick = (event: Event) => {
        event.stopPropagation();

        this.appendPopup();
        this.setPopupParams(this.currentPopup);
        this.currentPopup.classList.add("_visible");

        if (this.textFieldComponent) {
            this.textFieldComponent.value = this.uxEditable;
        }

        this.startEditing();
    };

    private onPopupKeyUp = (event: KeyboardEvent) => {
        event.stopPropagation();

        if (event.which === 13) { // Enter
            this.zone.run(() => {
                this.submit();
            });
        } else if (event.which === 27) { // Escape
            this.onEscClose(event);
        }
    };

    private onCancelButtonClick = (event: Event) => {
        event.stopPropagation();

        this.cancel();
    };

    private onOkButtonClick = (event: Event) => {
        event.stopPropagation();

        this.submit();
    };

    private clickOutsideCb = (e: Event) => {
        if (this.currentPopup && !this.currentPopup.contains(e.target as Node)) {

            this.zone.run(() => {
                this.submit();
            });
        }
    };


    private scrollOutsideCb = (e: Event) => {
        if (this.currentPopup && !this.currentPopup.contains(e.target as Node)) {

            this.zone.run(() => {
                this.submit();
            });
        }
    };

    private onEscClose(event: KeyboardEvent): void {
        event.stopPropagation();

        this.zone.run(() => {
            this.cancel();
        });
    }

    private submit(): void {
        this.setValue();

        this.hidePopup(true);
        this.onEditSubmit.emit();
    }

    private cancel(): void {
        this.hidePopup(true);
        this.onEditCancel.emit();
    }

    private startEditing(): void {
        this.currentPopup.classList.add("_edit");
        this.currentPopup.classList.add("_visible");
        this.hostElement.classList.add("ux-editable-invisible-host");

        this.zone.run(() => {
            window.clearTimeout(this.timeoutId);
            this.timeoutId = window.setTimeout(() => {
                this.timeoutId = null;
                if (this.textFieldComponent) {
                    this.textFieldComponent.focus();
                }

                this.onEditToggle.emit(true);
            }, 100);
        });
    }

    private endEditing(fireEvent: boolean): void {
        if (this.currentPopup) {
            this.currentPopup.classList.remove("_visible");
            this.currentPopup.classList.remove("_edit");
        }
        this.hostElement.classList.remove("ux-editable-invisible-host");

        if (fireEvent) {
            this.zone.run(() => {
                this.onEditToggle.emit(false);
            });
        }
    }

    private setValue(): void {
        if (this.textFieldComponent) {
            this.uxEditable = this.textFieldComponent.value;

            this.zone.run(() => {
                this.uxEditableChange.emit(this.uxEditable);
            });
        }
    }

    private hidePopup(fireEvent: boolean = false): void {
        this.endEditing(fireEvent);
        this.destroy();
    }

    private destroy(): void {
        this.contentEmbeddedView && this.contentEmbeddedView.destroy();

        if (this.currentPopup) {
            this.currentPopup.removeEventListener("keyup", this.onPopupKeyUp);

            if (this.currentPopup.parentNode) {
                UxDomHelper.removeChildFromParent(this.currentPopup);
            }
            this.currentPopup = undefined;
        }

        document.removeEventListener("click", this.clickOutsideCb, true);
        window.removeEventListener("scroll", this.scrollOutsideCb, true);
    }

    public ngOnDestroy(): void {
        this.destroy();

        if (this.hostElement) {
            this.hostElement.removeEventListener("click", this.onClick);
        }

        window.clearTimeout(this.timeoutId);
    }

    private createPopup(): void {
        this.createPopupElement();

        this.zone.run(() => {
            this.initPopupContent();
        });
    }

    private initPopupContent(): void {
        if (this.template) {

            this.contentEmbeddedView = this.viewContainerRef.createEmbeddedView(this.template, {model: this.model.customData});
            this.contentEmbeddedView.detectChanges();

            for (let node of this.contentEmbeddedView.rootNodes) {
                if (node) {
                    this.currentPopup.appendChild(node);
                }
            }


        } else {
            //default template

            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(UxTextFieldComponent);
            const embeddedView = this.viewContainerRef.createComponent(componentFactory);

            this.textFieldComponent = embeddedView.instance as UxTextFieldComponent;
            this.textFieldComponent.value = this._uxEditable;

            this.renderer.appendChild(this.currentPopup, embeddedView.location.nativeElement);
        }

        if (this.hasConfirmButton) {
            this.createButtons();
        }
    }

    private setPopupParams(currentPopup): void {
        let popupStyle = currentPopup.style;

        this.hostElementParameters = this.getHostElementParameters();
        popupStyle.top = this.hostElementParameters.top + "px";
        popupStyle.left = this.hostElementParameters.left + "px";
        popupStyle.width = this.getPopupWidth() + "px";
    }

    private appendPopup(): void {
        this.hidePopup();

        this.createPopup();

        if (typeof this._uxEditableContainer === "string") {
            document.querySelector(this._uxEditableContainer).appendChild(this.currentPopup);
        } else {
            this._uxEditableContainer.appendChild(this.currentPopup);
        }

        this.zone.runOutsideAngular(() => {
            document.addEventListener("click", this.clickOutsideCb, true);
            window.addEventListener("scroll", this.scrollOutsideCb, true);
            this.currentPopup.addEventListener("keyup", this.onPopupKeyUp);
        });
    }


    private getHostElementParameters(): ClientRect {
        let params = UxDomHelper.getDocumentRelativePosition(this.hostElement);

        if (this.uxEditableContainer !== document.body &&
            this.uxEditableContainer !== "body") {
            params.top = 0;
            params.left = 0;
        }

        return params;
    }

    private getPopupWidth(): number {
        let width: number = this.hostElementParameters.width,
            maxWidth = this._model.maxWidth,
            minWidth = this._model.minWidth;

        if (maxWidth && this.hostElementParameters.width > maxWidth) {
            width = maxWidth;
        }

        if (minWidth && this.hostElementParameters.width < minWidth) {
            width = minWidth;
        }

        return width;
    }

    private createPopupElement(): void {
        this.currentPopup = this.renderer.createElement("div");
        this.renderer.setAttribute(this.currentPopup, "id", "uxEditableDirective2");
        this.currentPopup.className = this._model.popoverStyleClass ? "ux-editable " + this._model.popoverStyleClass : "ux-editable";
    }

    private createPen(): void {
        this.hostElement.insertAdjacentHTML("afterbegin",
            `<div class='ux-editable__pen'>
            <svg viewBox="0 0 20 20" class="ux-editable__pen-icon">
                <path d="M17.561 2.439c-1.442-1.443-2.525-1.227-2.525-1.227L8.984 7.264 2.21 14.037 1.2 18.799l4.763-1.01 6.774-6.771 6.052-6.052c-.001 0 .216-1.083-1.228-2.527zM5.68 17.217l-1.624.35a3.71 3.71 0 0 0-.69-.932 3.742 3.742 0 0 0-.932-.691l.35-1.623.47-.469s.883.018 1.881 1.016c.997.996 1.016 1.881 1.016 1.881l-.471.468z"/>
            </svg>
        </div>`);
    }

    private createButtons(): void {
        //ok
        const okButton = this.renderer.createElement("div");
        this.renderer.addClass(okButton, "ux-editable__button");
        this.renderer.addClass(okButton, "_ok");

        const okButtonSVGElement = this.renderer.createElement("svg", "svg");
        this.renderer.addClass(okButtonSVGElement, "ux-editable__button-icon");
        this.renderer.setAttribute(okButtonSVGElement, "viewBox", "0 0 20 20");
        okButtonSVGElement.innerHTML = '<path d="M8.294,16.998c-0.435,0-0.847-0.203-1.111-0.553L3.61,11.724c-0.465-0.613-0.344-1.486,0.27-1.951c0.615-0.467,1.488-0.344,1.953,0.27l2.351,3.104l5.911-9.492c0.407-0.652,1.267-0.852,1.921-0.445c0.653,0.406,0.854,1.266,0.446,1.92L9.478,16.34c-0.242,0.391-0.661,0.635-1.12,0.656C8.336,16.998,8.316,16.998,8.294,16.998z"></path>';

        this.renderer.appendChild(okButton, okButtonSVGElement);
        this.renderer.appendChild(this.currentPopup, okButton);
        okButton.addEventListener("click", this.onOkButtonClick);


        //cancel
        const cancelButton = this.renderer.createElement("div");
        this.renderer.addClass(cancelButton, "ux-editable__button");
        this.renderer.addClass(cancelButton, "_cancel");

        const cancelButtonSVGElement = this.renderer.createElement("svg", "svg");
        this.renderer.addClass(cancelButtonSVGElement, "ux-editable__button-icon");
        this.renderer.setAttribute(cancelButtonSVGElement, "viewBox", "0 0 20 20");
        cancelButtonSVGElement.innerHTML = '<path d="M14.348,14.849c-0.469,0.469-1.229,0.469-1.697,0L10,11.819l-2.651,3.029c-0.469,0.469-1.229,0.469-1.697,0c-0.469-0.469-0.469-1.229,0-1.697l2.758-3.15L5.651,6.849c-0.469-0.469-0.469-1.228,0-1.697c0.469-0.469,1.228-0.469,1.697,0L10,8.183l2.651-3.031c0.469-0.469,1.228-0.469,1.697,0c0.469,0.469,0.469,1.229,0,1.697l-2.758,3.152l2.758,3.15C14.817,13.62,14.817,14.38,14.348,14.849z"></path>';

        this.renderer.appendChild(cancelButton, cancelButtonSVGElement);
        this.renderer.appendChild(this.currentPopup, cancelButton);
        cancelButton.addEventListener("click", this.onCancelButtonClick);
    }

}

