
import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {UxWizardStep} from "../../../../ux-lib/components/wizard-navigation/wizard-step.model";
import {PagedComponent} from "../../../components/base/paged.component";
import {AppConfigService} from "../../../common/services/app-config.service";
import {DeviceEntity} from "../../../common/models/entity/device-entity.model";
import {TestEntity} from "../../../common/models/entity/test-entity.model";
import {Subject, Subscription} from "rxjs";
import {takeUntil, tap} from "rxjs/operators";
import {TestsService} from "../../tests/tests.service";
import {DevicesService} from "../../devices/devices.service";
import {ApiPath} from "../../../common/services/api.path";
import {QueryParam} from "../../../common/models/query-param.type";
import {UxCardTreeCheckedEvent} from "../../../../ux-lib/components/card-tree/card-tree.component";
import {DevicesCardListComponent} from "../../devices/components/devices-card-list.component";
import {TestsCardListComponent} from "../../tests/components/tests-card-list.component";
import {Progress} from "../../../common/models/progress.model";
import {UxSwitchItemModel} from "../../../../ux-lib/components/fields/switch-selector/switch-item.model";
import {StatisticPeriod} from "../../../common/models/statistic-period.type";
import {UxDropdownListItem} from "../../../../ux-lib/components/fields/dropdown/dropdown-field.component";
import {
  UxRangeDateFieldModel,
  UxRangeDateFieldPlaceholderModel, UxRangeDateFieldViewEventModel
} from "../../../../ux-lib/components/fields/date/range/range-date-field.model";
import {Router} from "@angular/router";
import {PagingModel} from "../../../common/models/paging.model";
import {ModelService} from "../../../common/services/model.service";
import {UxPageChangeEvent} from "../../../../ux-lib/components/paging/paging.component";
import {CountResult} from "../../../common/models/result.type";
import {DeviceCardModel} from "../../devices/model/device-card.model";
import {TestCardModel} from "../../tests/model/test-card.model";

@Component({
  selector: "sq-report-wizard",
  templateUrl: "report-wizard.component.html",
  host: {"[class.sq-report-wizard]": "true"}
})
export class ReportWizardComponent extends PagedComponent{
  devicesPagingConfig: PagingModel;
  testsPagingConfig: PagingModel;

  wizardSteps: UxWizardStep[] = [
    {
      id: 'select-devices',
      name: 'Choose devices'
    },
    {
      id: 'select-tests',
      name: 'Choose tests'
    },
    {
      id: 'configure',
      name: 'configure'
    },
  ];

  _periodItemsModel: UxSwitchItemModel[] = [
    {
      id: StatisticPeriod.PERIOD_HOURS,
      label: "Hours",
      name: StatisticPeriod.PERIOD_HOURS,
      value: StatisticPeriod.PERIOD_HOURS,
      disabled: false
    },
    {
      id: StatisticPeriod.PERIOD_DAYS,
      label: "Days",
      name: StatisticPeriod.PERIOD_DAYS,
      value: StatisticPeriod.PERIOD_DAYS,
      disabled: false
    },
    {
      id: StatisticPeriod.PERIOD_MONTHS,
      label: "Months",
      name: StatisticPeriod.PERIOD_MONTHS,
      value: StatisticPeriod.PERIOD_MONTHS,
      disabled: false
    }
  ];

  _selectedPeriod: UxDropdownListItem = this._periodItemsModel[0];

  _selectedRange: UxRangeDateFieldModel = {
    from: new Date(),
    to: new Date()
  };
  _selectedViewRange: UxRangeDateFieldViewEventModel;
  _rangePlaceHolder: UxRangeDateFieldPlaceholderModel = {
    from: "mm/dd/yyyy",
    to: "mm/dd/yyyy"
  };
  _endAvailableDate = new Date();
  _rangeError: string = "";

  _stepIndex: number = 0;
  _devices: DeviceEntity[] = [];
  private _devicesMap: Map<number|string, DeviceEntity> = new Map<number, DeviceEntity>();
  _devicesLoadingStatus: Progress = 'loading';
  _selectedDevices: string[] = [];

  _tests: TestEntity[] = [];
  private _testsMap: Map<number|string, TestEntity> = new Map<number, TestEntity>();
  _testsLoadingStatus: Progress = 'loading';
  _selectedTests: string[] = [];

  private _reportAllowed: boolean = false;

  private stopSubscription$ = new Subject<boolean>();
  private devicesCountSubscription$: Subscription;
  private devicesListSubscription$: Subscription;
  private foldersSizeSubscription$: Subscription;
  private testsCountSubscription$: Subscription;
  private testsListSubscription$: Subscription;

  @ViewChild("devicesCardList")
  private devicesCardList: DevicesCardListComponent;

  @ViewChild("testsCardList")
  private testsCardList: TestsCardListComponent;

  constructor(protected configService: AppConfigService,
              private modelService: ModelService,
              private devicesService: DevicesService,
              private testsService: TestsService,
              private router: Router,
              private cdRef: ChangeDetectorRef) {
    super(configService);

    this._selectedViewRange = {
      from: this.formatDate(this._selectedRange.from),
      to: this.formatDate(this._selectedRange.to)
    }
  }

  private formatDate(d: Date): string {
    let month = d.getMonth()+1;
    return (month < 10 ? "0" + month : month) +
        "/" +
        (d.getDate() < 10 ? "0" + d.getDate() : d.getDate()) +
        "/" +
        d.getFullYear();
  }

  public loadInitialData() {
    this.devicesPagingConfig = this.modelService.deepCopy(this.pagingConfig);
    this.testsPagingConfig = this.modelService.deepCopy(this.pagingConfig);

    super.loadInitialData();
  }

  public destroyComponent() {
    this.stopSubscription$.next(true);
    this.stopSubscription$.complete();
  }

  _onExpandDeviceFolderCard(deviceCard: DeviceCardModel) {
    if (!deviceCard.expanded) {
      let queryParams: QueryParam[] = [
        {
          key: ApiPath.PARENT_ID,
          value: deviceCard.originalEntity.id
        }
      ];

      deviceCard.originalEntity.progress = 'loading';
      deviceCard.statisticsSubscription$ && deviceCard.statisticsSubscription$.unsubscribe();
      deviceCard.statisticsSubscription$ = this.devicesService
        .getEntityList(queryParams)
        .pipe(
          takeUntil(this.stopSubscription$),
          tap((devices: DeviceEntity[]) => {
            this._devicesMap.get(deviceCard.originalEntity.id).children_count = devices.length;

            deviceCard.originalEntity.progress = 'loaded';
            this.mergeDevices(devices, true);

            let folderIds: (number|string)[] = [];
            let deviceInstances: Map<number|string, string> = new Map<number|string, string>();
            devices.forEach((device: DeviceEntity) => {
              if (device.type === 'folder') {
                folderIds.push(device.id);
              }
              else {
                deviceInstances.set(device.id, device.instance);
              }
            })

            this.retrieveDeviceFoldersSize(folderIds);
          })
        )
        .subscribe();
    }
  }

  _onExpandTestFolderCard(testCard: TestCardModel) {
    if (!testCard.expanded) {
      let queryParams: QueryParam[] = [
        {
          key: ApiPath.PARENT_ID,
          value: testCard.originalEntity.id
        }
      ];

      testCard.originalEntity.progress = 'loading';
      testCard.statisticsSubscription$ && testCard.statisticsSubscription$.unsubscribe();
      testCard.statisticsSubscription$ = this.testsService
        .getEntityList(queryParams)
        .pipe(
          takeUntil(this.stopSubscription$),
          tap((tests: TestEntity[]) => {
            this._testsMap.get(testCard.originalEntity.id).children_count = tests.length;

            testCard.originalEntity.progress = 'loaded';
            this.mergeTests(tests, true);

            let folderIds: (number|string)[] = [];
            let testInstances: Map<number|string, string> = new Map<number|string, string>();
            tests.forEach((test: TestEntity) => {
              if (test.command === 'folder') {
                folderIds.push(test.id);
              }
              else {
                testInstances.set(test.id, test.name);
              }
            })

            this.retrieveTestFoldersSize(folderIds);
          })
        )
        .subscribe();
    }
  }

  _onLoadDevicesInitialData() {
    this.loadDevicesPage(this.pagingConfig.currentPage, this.pagingConfig.pageSize, false);
  }

  _onLoadTestInitialData() {
    this.loadTestsPage(this.pagingConfig.currentPage, this.pagingConfig.pageSize, false);
  }

  _onPageChange(pageEvent: UxPageChangeEvent) {
    if (this.viewInitiated) {
      if (this._stepIndex === 0) {
        if (this.devicesPagingConfig.currentPage != pageEvent.page) {
          this.devicesPagingConfig.currentPage = pageEvent.page;
          this.loadDevicesPage(pageEvent.page, pageEvent.items, false);
        }
      }
      else if (this._stepIndex === 1) {
        if (this.testsPagingConfig.currentPage != pageEvent.page) {
          this.testsPagingConfig.currentPage = pageEvent.page;
          this.loadTestsPage(pageEvent.page, pageEvent.items, false);
        }
      }
    }
  }

  _onPageSizeChange(pageEvent: UxPageChangeEvent) {
    if (this.viewInitiated) {
      if (this._stepIndex === 0) {
        if (this.devicesPagingConfig.pageSize != pageEvent.items) {
          this.devicesPagingConfig.pageSize = pageEvent.items;
          this.loadDevicesPage(pageEvent.page, pageEvent.items, false);
        }
      }
      else if (this._stepIndex === 1) {
        if (this.testsPagingConfig.pageSize != pageEvent.items) {
          this.testsPagingConfig.pageSize = pageEvent.items;
          this.loadTestsPage(pageEvent.page, pageEvent.items, false);
        }
      }
    }
  }

  public loadPageData(currentPage: number, pageSize: number): void {
    if (this._stepIndex === 0) {
      this.loadDevicesPage(currentPage, pageSize, false);
    }
    else if (this._stepIndex === 1) {
      this.loadTestsPage(currentPage, pageSize, false);
    }
  }

  private loadDevicesPage(currentPage: number, pageSize: number, merge: boolean): void {
    this._devicesLoadingStatus = 'loading';
    let queryParams: QueryParam[] = [
      {
        key: ApiPath.PARENT_ID,
        value: 0
      }
    ];

    this.devicesCountSubscription$ && this.devicesCountSubscription$.unsubscribe();
    this.devicesCountSubscription$ = this.devicesService
      .getEntitiesCountPerParent(queryParams)
      .pipe(
        takeUntil(this.stopSubscription$),
        tap((results: CountResult[]) => {
          let resCount = this.getCountResult(0, results);
          if (resCount !== null) {
            this.pagingConfig.totalCount = resCount;
            this.retrieveDevicesListPage(currentPage, pageSize, merge);
          }
          else {
            this.pagingConfig.totalCount = 0;
            this._pageLoadingStatus = 'no_data';
          }
        })
      )
      .subscribe();
    this.cdRef.detectChanges();
  }

  private retrieveDevicesListPage(currentPage: number, pageSize: number, merge: boolean): void {
    this._devicesLoadingStatus = 'loading';
    let queryParams: QueryParam[] = [
      {
        key: ApiPath.PARENT_ID,
        value: 0
      },
      {
        key: ApiPath.OFFSET,
        value: (currentPage-1) * pageSize
      },
      {
        key: ApiPath.LIMIT,
        value: pageSize
      }
    ];

    this.devicesListSubscription$ && this.devicesListSubscription$.unsubscribe();
    this.devicesListSubscription$ = this.devicesService
      .getEntityList(queryParams)
      .pipe(
        takeUntil(this.stopSubscription$),
        tap((devices: DeviceEntity[]) => {
          this._devicesLoadingStatus = 'loaded';

          this.mergeDevices(devices, merge);

          let folderIds: (number|string)[] = devices.filter((device: DeviceEntity) => {return device.type === 'folder';})
            .map((device: DeviceEntity) => {return device.id})

          this.retrieveDeviceFoldersSize(folderIds);
        })
      )
      .subscribe();
  }

  private mergeDevices(devices: DeviceEntity[], merge: boolean) {
    if (!merge) {
      this._devices.length = 0;
      this._devicesMap.clear();
    }

    for (let device of devices) {
      if (this._devicesMap.has(device.id)) {
        this._devicesMap.get(device.id).copyFrom(device);
      }
      else {
        let d = new DeviceEntity(device.parent_id, device.id);
        d.copyFrom(device);
        this._devices.push(d);
        this._devicesMap.set(device.id, d);
      }
    }
  }

  private mergeTests(tests: TestEntity[], merge: boolean) {
    if (!merge) {
      this._tests.length = 0;
      this._testsMap.clear();
    }

    for (let test of tests) {
      if (this._testsMap.has(test.id)) {
        this._testsMap.get(test.id).copyFrom(test);
      }
      else {
        let d = new TestEntity(test.parent_id, test.id);
        d.copyFrom(test);
        this._tests.push(d);
        this._testsMap.set(test.id, d);
      }
    }
  }

  private retrieveDeviceFoldersSize(folders: (number|string)[]) {
    if (folders && folders.length > 0) {
      for (let id of folders) {
        if (this._devicesMap.has(id)) {
          this._devicesMap.get(id).progress = 'loading';
        }
      }

      let queryParams: QueryParam[] = [];
      folders.forEach((id: number|string) => {
        queryParams.push({
          key: ApiPath.PARENT_ID,
          value: id
        });
      });

      this.foldersSizeSubscription$ && this.foldersSizeSubscription$.unsubscribe();
      this.foldersSizeSubscription$ = this.devicesService
        .getEntitiesCountPerParent(queryParams)
        .pipe(
          takeUntil(this.stopSubscription$),
          tap((results: CountResult[]) => {
            for (let res of results) {
              if (this._devicesMap.has(res.parent_id)) {
                let device = this._devicesMap.get(res.parent_id);
                device.progress = 'loaded';
                device.children_count = res.count;
              }
            }
          })
        )
        .subscribe();
    }
  }

  private retrieveTestFoldersSize(folders: (number|string)[]) {
    if (folders && folders.length > 0) {
      for (let id of folders) {
        if (this._testsMap.has(id)) {
          this._testsMap.get(id).progress = 'loading';
        }
      }

      let queryParams: QueryParam[] = [];
      folders.forEach((id: number|string) => {
        queryParams.push({
          key: ApiPath.PARENT_ID,
          value: id
        });
      });

      this.foldersSizeSubscription$ && this.foldersSizeSubscription$.unsubscribe();
      this.foldersSizeSubscription$ = this.testsService
        .getEntitiesCountPerParent(queryParams)
        .pipe(
          takeUntil(this.stopSubscription$),
          tap((results: CountResult[]) => {
            for (let res of results) {
              if (this._testsMap.has(res.parent_id)) {
                let test = this._testsMap.get(res.parent_id);
                test.progress = 'loaded';
                test.children_count = res.count;
              }
            }
          })
        )
        .subscribe();
    }
  }

  private loadTestsPage(currentPage: number, pageSize: number, merge: boolean): void {
    this._testsLoadingStatus = 'loading';
    let queryParams: QueryParam[] = [
      {
        key: ApiPath.PARENT_ID,
        value: 0
      }
    ];
    this.testsCountSubscription$ && this.testsCountSubscription$.unsubscribe();
    this.testsCountSubscription$ = this.testsService
      .getEntitiesCountPerParent(queryParams)
      .pipe(
        takeUntil(this.stopSubscription$),
        tap((results: CountResult[]) => {
          let resCount = this.getCountResult(0, results);
          if (resCount !== null) {
            this.pagingConfig.totalCount = resCount;
            this.retrieveTestsListPage(currentPage, pageSize, merge);
          }
          else {
            this.pagingConfig.totalCount = 0;
            this._pageLoadingStatus = 'no_data';
          }
        })
      )
      .subscribe();
    this.cdRef.detectChanges();
  }

  protected retrieveTestsListPage(currentPage: number, pageSize: number, merge: boolean): void {
    this._testsLoadingStatus = 'loading';
    let queryParams: QueryParam[] = [
      {
        key: ApiPath.PARENT_ID,
        value: 0
      },
      {
        key: ApiPath.OFFSET,
        value: (currentPage-1) * pageSize
      },
      {
        key: ApiPath.LIMIT,
        value: pageSize
      }
    ];

    this.testsListSubscription$ && this.testsListSubscription$.unsubscribe();
    this.testsListSubscription$ = this.testsService
      .getEntityList(queryParams)
      .pipe(
        takeUntil(this.stopSubscription$),
        tap((tests: TestEntity[]) => {
          this._testsLoadingStatus = 'loaded';
          this.mergeTests(tests, merge);

          let folderIds: (number|string)[] = tests.filter((test: TestEntity) => {return test.command === 'folder';})
            .map((test: TestEntity) => {return test.id})

          this.retrieveTestFoldersSize(folderIds);
        })
      )
      .subscribe();
  }

  _onCardChecked(event: UxCardTreeCheckedEvent) {
    if (this._stepIndex === 0) { // select devices page
      this._selectedDevices = this._selectedDevices.filter((value: string) => value !== event.model.id);
      if (event.model.selected) {
        this._selectedDevices.push(event.model.id);
      }
    }
    else if (this._stepIndex === 1) { // select tests page
      this._selectedTests = this._selectedTests.filter((value: string) => value !== event.model.id);
      if (event.model.selected) {
        this._selectedTests.push(event.model.id);
      }
    }
  }

  _onCardCheckedAll(event: UxCardTreeCheckedEvent) {
    if (this._stepIndex === 0) { // select devices page
      this._selectedDevices = this._devices.map((device: DeviceEntity) => device.id.toString());
    }
    else if (this._stepIndex === 1) { // select tests page
      this._selectedTests = this._tests.map((test: TestEntity) => test.id.toString());
    }
  }

  _onStepChange(index: number) {
    if (this._stepIndex !== index) {
      this._stepIndex = index;
    }
  }

  _onNextClick(hasNext: boolean) {
    if (hasNext) {
      this._stepIndex++;
    }
  }

  _onBackClick() {
    this._stepIndex--;
  }

  _onPeriodSelected(value: UxDropdownListItem) {
    if (this._selectedPeriod !== value) {
      this._selectedPeriod = value;
    }

    this.validatePeriodAndRange();
  }

  _onCalendarVisibleChange(bVisible: boolean) {
    this.validatePeriodAndRange();
  }

  _onRangeSelected(value: UxRangeDateFieldModel): void {
    if (this._selectedRange !== value) {
      this._selectedRange = value;
    }
    this.validatePeriodAndRange();
  }

  private validatePeriodAndRange() {
    this._rangeError = "";
    let allowed = this._reportAllowed;

    if (this._selectedRange && this._selectedRange.from && this._selectedRange.to) {
      let fromDate = new Date(this._selectedRange.from);
      let toDate = new Date(this._selectedRange.to);
      fromDate.setHours(0, 0, 0);
      toDate.setHours(23, 59, 59);

      let currDate = new Date();
      if (toDate.getTime() > currDate.getTime()) {
        toDate = currDate;
      }

      let fromTime = fromDate.getTime();
      let toTime = toDate.getTime();

      let maxPeriods = this.configService.report().periods;
      let diff = toTime - fromTime;

      if (this._selectedPeriod.id === StatisticPeriod.PERIOD_HOURS) {
        let hours = (diff / 1000) / 3600;
        if (hours > maxPeriods) {
          this._rangeError = `Selected period contains more than ${maxPeriods} hours`;
        }
      }
      else if (this._selectedPeriod.id === StatisticPeriod.PERIOD_DAYS) {
        let days = ((diff / 1000) / 3600) / 24;
        if (days > maxPeriods) {
          this._rangeError = `Selected period contains more than ${maxPeriods} days`;
        }
      }
      else if (this._selectedPeriod.id === StatisticPeriod.PERIOD_MONTHS) {
        let months = (toDate.getFullYear() - fromDate.getFullYear()) * 12;
        months -= fromDate.getMonth() + 1;
        months += toDate.getMonth();
        if (months > maxPeriods) {
          this._rangeError = `Selected period contains more than ${maxPeriods} months`;
        }
      }

      allowed = (this._rangeError.length === 0);
    }
    else {
      allowed = false;
    }

    if (this._reportAllowed != allowed) {
      this._reportAllowed = allowed;
    }

  }

  _onGetReport() {
    let dateFrom: Date = this._selectedRange.from;
    let dateTo: Date = this._selectedRange.to;
    dateTo.setHours(23, 59, 59, 999);
    let currTime = new Date();
    if (dateTo.getTime() > currTime.getTime()) {
      dateTo.setTime(currTime.getTime());
    }

    this.router.navigate(['/reports/view'], {
      queryParams: {
        period: this._selectedPeriod.id,
        from: this._selectedRange.from.getTime(),
        to: this._selectedRange.to.getTime(),
        devices: this._selectedDevices,
        tests: this._selectedTests
      }
    });
  }
}
