<div class="sq-summary-chart__wrapper">
  <table>
    <tr>
      <td></td>
      <td class="sq-summary-chart__statistic _title">Best</td>
      <td class="sq-summary-chart__statistic _title">Avg</td>
      <td class="sq-summary-chart__statistic _title">Worst</td>
    </tr>
    <tr *ngIf="entitySummaryVisible?.mos_aqua && mos_aqua">
      <td class="sq-summary-chart__statistic _label">AQUA</td>
      <td class="sq-summary-chart__statistic _value"
          [ngClass]="entitySummaryVisible?.mos_aqua?.maxLevel">
        {{entitySummaryVisible?.mos_aqua?.max}}
      </td>
      <td class="sq-summary-chart__statistic _value"
          [ngClass]="entitySummaryVisible?.mos_aqua?.avgLevel">
        {{entitySummaryVisible?.mos_aqua?.average}}
      </td>
      <td class="sq-summary-chart__statistic _value"
          [ngClass]="entitySummaryVisible?.mos_aqua?.minLevel">
        {{entitySummaryVisible?.mos_aqua?.min}}
      </td>
    </tr>
    <tr *ngIf="entitySummaryVisible?.mos_pvqa && mos_pvqa">
      <td class="sq-summary-chart__statistic _label">PVQA</td>
      <td class="sq-summary-chart__statistic _value"
          [ngClass]="entitySummaryVisible?.mos_pvqa?.maxLevel">
        {{entitySummaryVisible?.mos_pvqa?.max}}
      </td>
      <td class="sq-summary-chart__statistic _value"
          [ngClass]="entitySummaryVisible?.mos_pvqa?.avgLevel">
        {{entitySummaryVisible?.mos_pvqa?.average}}
      </td>
      <td class="sq-summary-chart__statistic _value"
          [ngClass]="entitySummaryVisible?.mos_pvqa?.minLevel">
        {{entitySummaryVisible?.mos_pvqa?.min}}
      </td>
    </tr>
    <tr *ngIf="entitySummaryVisible?.mos_network && mos_network">
      <td class="sq-summary-chart__statistic _label">MOS Network</td>
      <td class="sq-summary-chart__statistic _value"
          [ngClass]="entitySummaryVisible?.mos_network?.maxLevel">
        {{entitySummaryVisible?.mos_network?.max}}
      </td>
      <td class="sq-summary-chart__statistic _value"
          [ngClass]="entitySummaryVisible?.mos_network?.avgLevel">
        {{entitySummaryVisible?.mos_network?.average}}
      </td>
      <td class="sq-summary-chart__statistic _value"
          [ngClass]="entitySummaryVisible?.mos_network?.minLevel">
        {{entitySummaryVisible?.mos_network?.min}}
      </td>
    </tr>
    <tr *ngIf="entitySummaryVisible?.r_factor && r_factor">
      <td class="sq-summary-chart__statistic _label">Sevana R-factor</td>
      <td class="sq-summary-chart__statistic _value"
          [ngClass]="entitySummaryVisible?.r_factor?.maxLevel">
        {{entitySummaryVisible?.r_factor?.max}}
      </td>
      <td class="sq-summary-chart__statistic _value"
          [ngClass]="entitySummaryVisible?.r_factor?.avgLevel">
        {{entitySummaryVisible?.r_factor?.average}}
      </td>
      <td class="sq-summary-chart__statistic _value"
          [ngClass]="entitySummaryVisible?.r_factor?.minLevel">
        {{entitySummaryVisible?.r_factor?.min}}
      </td>
    </tr>
    <tr *ngIf="entitySummaryVisible?.percents_aqua && percents_aqua">
      <td class="sq-summary-chart__statistic _label">Sevana Percentage</td>
      <td class="sq-summary-chart__statistic _value"
          [ngClass]="entitySummaryVisible?.percents_aqua?.maxLevel">
        {{entitySummaryVisible?.percents_aqua?.max}}
      </td>
      <td class="sq-summary-chart__statistic _value"
          [ngClass]="entitySummaryVisible?.percents_aqua?.avgLevel">
        {{entitySummaryVisible?.percents_aqua?.average}}
      </td>
      <td class="sq-summary-chart__statistic _value"
          [ngClass]="entitySummaryVisible?.percents_aqua?.minLevel">
        {{entitySummaryVisible?.percents_aqua?.min}}
      </td>
    </tr>
  </table>
</div>
