import {Component, ElementRef, EventEmitter, HostBinding, Input, Output} from "@angular/core";
import {UxPropertyConverter} from "../../common/decorator/ux-property-converter";
import {UxPropertyHandler} from "../../common/decorator/ux-property-handler";
import {UxValueChangeEvent} from "../fields/abstract-field.component";

const NUMBER_OF_PAGE_LINKS: number = 5;

const DEFAULT_INFO_MESSAGE = {
    items: "items,",
    shown: "shown,",
    perPage: "per page"
};

@Component({
    selector: "ux-paging",
    templateUrl: "./paging.component.html",
    host: {
        "[class.ux-paging]": "true"
    }
})
export class UxPagingComponent {

    @UxPropertyConverter("number")
    @UxPropertyHandler({
        afterChange: afterTotalCountChange
    })
    @Input()
    public totalCount: number;

    @UxPropertyConverter("number", 20)
    @UxPropertyHandler({
        afterChange: afterPageSizeChange
    })
    @Input()
    public pageSize: number;

    @UxPropertyConverter("number", 1)
    @UxPropertyHandler({
        afterChange: afterPageChange
    })
    @Input()
    public currentPage: number;

    @UxPropertyConverter("custom", [{label: 20}, {label: 50}, {label: 100}, {label: 200}], {
        convert: convertPageSizeOptions
    })
    @Input()
    public pageSizeOptions: Array<{ label: number }>;

    @UxPropertyConverter("boolean", false)
    @HostBinding("class._disabled")
    @Input()
    public disabled: boolean;

    @UxPropertyConverter("boolean", false)
    @Input()
    public isFilter: boolean;

    @UxPropertyConverter("custom", DEFAULT_INFO_MESSAGE, {
        convert: convertInfoMessage
    })
    @UxPropertyHandler({
        afterChange: afterInfoMessageChange
    })
    @Input()
    public infoMessage: { items: string, shown: string, perPage: string };

    @Input()
    public mobile: boolean = false;

    @Input()
    public listContainer: ElementRef | string;

    @Input()
    public listStyleClass: string = "";


    @Output()
    public onPageChange: EventEmitter<UxPageChangeEvent> = new EventEmitter<UxPageChangeEvent>();

    @Output()
    public onPageSizeChange: EventEmitter<UxPageChangeEvent> = new EventEmitter<UxPageChangeEvent>();

    @Output()
    public onTotalCountChange: EventEmitter<UxPageChangeEvent> = new EventEmitter<UxPageChangeEvent>();

    /** @internal */
    public _amountOfPages: number = 0;

    /** @internal */
    public _firstItem: number = 0;

    /** @internal */
    public _pageLinksArray: Array<number> = [];

    /** @internal */
    public _pageSizeValue: { label: number };

    /** @internal */
    public _info: string = "";

    private inited: boolean = false;

    public ngOnInit(): void {
        this.inited = true;
        this.updatePageSize(false);
        this.updatePage(false);
        this.updateInfo();
    }

    /** @internal */
    public _onFirstPage(): void {
        this.currentPage = 1;
    }

    /** @internal */
    public _onPrevPage(): void {
        if (this.currentPage > 1) {
            this.currentPage--;
        }
    }

    /** @internal */
    public _onNextPage(): void {
        if (this.currentPage < this._amountOfPages) {
            this.currentPage++;
        }
    }

    /** @internal */
    public _onLastPage(): void {
        this.currentPage = this._amountOfPages;
    }

    /** @internal */
    public _onPageByIndex(index: number): void {
        if (index > 0 && index <= this._amountOfPages) {
            this.currentPage = index;
        }
    }

    /** @internal */
    public _onItemsPerPageChange(event: UxValueChangeEvent<{ label?: string }>): void {
        if (event.newValue && +event.newValue.label > 0) {
            this.pageSize = +event.newValue.label;
        }
    }

    private getPageSizeValue(): { label: number } {
        const pageSizeIndexInOptions: number = this.getPageSizeOptionIndex(this.pageSize);
        return this.pageSizeOptions[pageSizeIndexInOptions];
    }

    private getPageSizeOptionIndex(value: number): number {
        return this.pageSizeOptions.reduce((
            indexOption: number,
            elem: { label: number },
            index: number) => (

            value === elem.label ? index : indexOption

        ), 0);
    }

    private updateInfo(): void {
        if (this.inited) {
            this._info = this.totalCount + " " + this.infoMessage.items
                + " " + (this.totalCount > 0 ? this._firstItem + 1 + "-" : "")
                + (this._firstItem + this.pageSize - 1 > this.totalCount ? this.totalCount : this._firstItem + this.pageSize)
                + " " + this.infoMessage.shown;
        }
    }

    private updatePageSize(updateInfo: boolean = true): void {
        let self = this;
        if (!self.totalCount) {
            return;
        }
        self._amountOfPages = Math.ceil(self.totalCount / self.pageSize);
        self._firstItem = Math.floor(self._firstItem / self.pageSize) * self.pageSize;
        self.currentPage = Math.floor(self._firstItem / self.pageSize) + 1;
        self._pageLinksArray = makePageLinksArray(self.currentPage, self._amountOfPages);
        self._pageSizeValue = self.getPageSizeValue();

        if (updateInfo) {
            self.updateInfo();
        }
        self.onPageSizeChange.emit({
                first: self._firstItem,
                items: self.pageSize,
                page: self.currentPage,
                pageCount: self._amountOfPages,
                totalCount: self.totalCount,
                originalEvent: event
            } as UxPageChangeEvent
        );
    }

    private updatePage(updateInfo: boolean = true): void {
        let self = this;
        if (!self.pageSize) {
            return;
        }
        if (self.currentPage < 1 && self._amountOfPages > 0) { // validation
            self.currentPage = 1;
            return;
        }
        if (self.currentPage > self._amountOfPages) {
            self.currentPage = self._amountOfPages || 1;
            return;
        }
        self._pageLinksArray = makePageLinksArray(self.currentPage, self._amountOfPages);
        self._firstItem = (self.currentPage - 1) * self.pageSize;

        if (updateInfo) {
            self.updateInfo();
        }
        self.onPageChange.emit({
                first: self._firstItem,
                items: self.pageSize,
                page: self.currentPage,
                pageCount: self._amountOfPages,
                totalCount: self.totalCount,
                originalEvent: event
            } as UxPageChangeEvent
        );
    }

    /** @internal */
    public _trackByFn(index: number, item: number): number {
        return item;
    }
}

export interface UxPageChangeEvent {
    first: number; // Index of first item
    items: number; // Number of items to display in new page
    page: number; // Index of the new page
    pageCount: number; // Total number of pages
    totalCount: number; // Total number of items in table
    originalEvent: Event; // Original event
}

/*Helpers*/
export function convertInfoMessage(item: any) {
    if (item.hasOwnProperty("items") && item.hasOwnProperty("shown") && item.hasOwnProperty("perPage")) {
        return item;
    } else {
        return DEFAULT_INFO_MESSAGE;
    }
}

export function convertPageSizeOptions(item: any) {
    if (item instanceof Array) {
        return item.map((value) => {
            return {label: +value}
        }) as Array<{ label: number }>;
    } else {
        return [];
    }
}

export function afterTotalCountChange(): void {
    let self = this;

    if (!self.pageSize) {
        return;
    }

    if (self.totalCount > 0) {
        self._amountOfPages = Math.ceil(self.totalCount / self.pageSize);
        if (self.isFilter) {
            self._firstItem = 0;
            self.currentPage = Math.floor(self._firstItem / self.pageSize) + 1;
        }
        if (self.totalCount - 1 < self._firstItem) {
            self._firstItem = self.totalCount - 1;
            self.currentPage = Math.floor(self._firstItem / self.pageSize) + 1;
        }
        self._pageLinksArray = makePageLinksArray(self.currentPage, self._amountOfPages);
    }

    if (this.inited) {
        self.updateInfo();
        self.onTotalCountChange.emit({
                first: self._firstItem,
                items: self.pageSize,
                page: self.currentPage,
                pageCount: self._amountOfPages,
                totalCount: self.totalCount,
                originalEvent: event
            } as UxPageChangeEvent
        );
    }
}

export function afterPageSizeChange(): void {
    if (this.inited) {
        this.updatePageSize();
    }
}

export function afterPageChange(): void {
    if (this.inited) {
        this.updatePage();
    }
}

export function makePageLinksArray(currentPage: number, amountOfPages: number): Array<number> {
    let result = [];
    let start: number, stop: number;
    let offset = Math.floor(NUMBER_OF_PAGE_LINKS / 2);

    if (currentPage - offset <= 0) {
        start = 1;
        stop = NUMBER_OF_PAGE_LINKS;
    } else if (currentPage + offset > amountOfPages) {
        start = amountOfPages - NUMBER_OF_PAGE_LINKS + 1;
        stop = amountOfPages;
    } else {
        start = currentPage - offset;
        stop = currentPage + offset;
    }

    for (let i = start; i <= stop; i++) {
        if (i > 0 && i <= amountOfPages) {
            result.push(i);
        }
    }

    return result;
}

export function afterInfoMessageChange(): void {
    if (this.inited) {
        this.updateInfo();
    }
}
