import {AfterContentInit, Component, ContentChildren, EventEmitter, Input, Output, QueryList} from "@angular/core";
import {UxAccordionItemComponent} from "./item/accordion-item.component";
import {Subscription} from "rxjs";
import {UxPropertyConverter} from "../../common/decorator/ux-property-converter";
import {UxPropertyHandler} from "../../common/decorator/ux-property-handler";

@Component({
    selector: "ux-accordion",
    template: `<ng-content></ng-content>`,
    host: {"[class.ux-accordion]": "true"}
})
export class UxAccordionComponent implements AfterContentInit {

    private subscriptions: Subscription[] = [];

    @UxPropertyConverter("boolean", false)
    @Input()
    public multiple: boolean;

    @Input()
    public mobile: boolean = false;

    @Output()
    public onItemToggle: EventEmitter<UxAccordionToggleEvent> = new EventEmitter<UxAccordionToggleEvent>();

    @ContentChildren(UxAccordionItemComponent)
    @UxPropertyHandler({
        afterChange: afterItemsListChange
    })
    private itemsList: QueryList<UxAccordionItemComponent>;

    private items: UxAccordionItemComponent[] = [];

    public ngAfterContentInit(): void {

    }

    private toggleItem(event: any): void {
        /* 'original' flag indicates that event was emitted by item itself.
         * This check is needed to prevent cyclic onToggle emitting.
         */
        if (!event.original) {
            return;
        }

        let self = this;
        let sourceItem = event.item;

        sourceItem.opened = !sourceItem.opened;

        this.emitItemToggleEvent(event.originalEvent, sourceItem, sourceItem.opened);
        if (!self.multiple && sourceItem.opened) {
            self.items.forEach((item: any) => {
                if (item !== sourceItem && item.opened) {
                    item.opened = false;
                    item.onToggle.emit({originalEvent: event.originalEvent, item: item, isOpened: false});
                    self.emitItemToggleEvent(event.originalEvent, item, false);
                }
            });
        }
    }

    private emitItemToggleEvent(event: any, item: UxAccordionItemComponent, isOpened: boolean): void {
        let self = this;
        let index = self.items.indexOf(item);
        self.onItemToggle.emit({originalEvent: event, index: index, item: item, opened: isOpened});
    }

    ngOnDestroy() {
        this.clearSubscriptions();
    }

    private clearSubscriptions() {
        this.subscriptions.forEach((item: Subscription) => {
            item.unsubscribe();
        });
    }
}

export interface UxAccordionToggleEvent {
    originalEvent: any;
    index: number;
    item: UxAccordionItemComponent;
    opened: boolean;
    original?: boolean;
}


/*Helpers*/
export function afterItemsListChange() {
    let self = this;

    this.itemsList.changes.subscribe(() => {
        self.items = self.itemsList.toArray();

        self.clearSubscriptions();

        if (self.items && self.items.length) {
            self.items.forEach((item: UxAccordionItemComponent) => {
                let sub = item.onToggle.subscribe((event: any) => self.toggleItem(event));
                self.subscriptions.push(sub);
            });
        }
    });
}
