<div #wrapper
     class="ux-reference-field__wrapper taReferenceSelector"
     [ngClass]="{'_show-dropdown': showDropdownButton}">

    <ng-template [ngTemplateOutlet]="valuesTemplate"></ng-template>

    <ng-template [ngTemplateOutlet]="inputTemplate"></ng-template>


    <button class="ux-reference-field__dropdown-button taButton"
            [ngClass]="{'_disabled': disabled, '_show-dropdown': showDropdownButton}"
            [type]="'button'"
            [disabled]="disabled"
            (tap)="!disabled && _onDropdownClick($event)">
        <i *ngIf="_customIcon" class="ux-reference-field__icon">
            <ng-content select="svg"></ng-content>
        </i>
        <i *ngIf="!_customIcon" class="ux-reference-field__icon">
            <svg width="0" height="0"
                 viewBox="0 0 20 20">
                <path
                    d="M13.418 7.859a.695.695 0 0 1 .978 0 .68.68 0 0 1 0 .969l-3.908 3.83a.697.697 0 0 1-.979 0l-3.908-3.83a.68.68 0 0 1 0-.969.695.695 0 0 1 .978 0L10 11l3.418-3.141z"/>
            </svg>
        </i>
    </button>

    <svg class="ux-reference-field__search-icon"
         width="0" height="0"
         viewBox="0 0 20 20">
        <path
            d="M17.545 15.467l-3.779-3.779a6.15 6.15 0 0 0 .898-3.21c0-3.417-2.961-6.377-6.378-6.377A6.185 6.185 0 0 0 2.1 8.287c0 3.416 2.961 6.377 6.377 6.377a6.15 6.15 0 0 0 3.115-.844l3.799 3.801a.953.953 0 0 0 1.346 0l.943-.943c.371-.371.236-.84-.135-1.211zM4.004 8.287a4.282 4.282 0 0 1 4.282-4.283c2.366 0 4.474 2.107 4.474 4.474a4.284 4.284 0 0 1-4.283 4.283c-2.366-.001-4.473-2.109-4.473-4.474z"/>
    </svg>

</div>



<ng-template #inputTemplate>
    <div *ngIf="!mobile"
         class="ux-reference-field__input-wrapper">
        <input #inputElement
               class="ux-reference-field__input taValue"
               [(ngModel)]="viewValue"
               [placeholder]="placeholder"
               [attr.name]="inputName"
               [disabled]="disabled"
               (focus)="_onFocus($event)"
               (blur)="_onBlur($event)"
               (input)="_onInput($event)"
               (keyup)="_onInputKeyUp($event)"
               (keydown)="_onInputKeyDown($event)"
               type="text"
        />
        <ng-template *ngIf="valueTemplate"
                     [ngTemplateOutlet]="valueTemplate"
                     [ngTemplateOutletContext]="{item: singleValue}"
        ></ng-template>
        <div class="ux-reference-field__loader-wrapper">
            <ux-round-dots-loader [visible]="isLoading"></ux-round-dots-loader>
        </div>
    </div>
</ng-template>



<ng-template #valuesTemplate>

    <div *ngIf="multiple && value && value.length > 0"
         class="ux-reference-field__values">
        <div class="ux-reference-field__values-wrapper">
            <ux-scroll class="_full-width">

                <ng-template [ngTemplateOutlet]="valuesListTemplate"
                             [ngTemplateOutletContext]="{values: value}"
                ></ng-template>

            </ux-scroll>
        </div>
    </div>

    <label *ngIf="!multiple && mobile"
           (tap)="_onLabelClick()"
           [ngClass]="{'ux-reference-field__label':true,'ux-reference-field__label_empty': !(value && value[0] && value[0][itemCaptionKey])}"
           (blur)="_onBlur($event)"
           tabindex="-1">
            <span class="ux-reference-field__label-text taValue">
                {{value && value[0] && value[0][itemCaptionKey]}}
            </span>
    </label>
</ng-template>



<ng-template #defaultValueItemTemplate let-item="item">
    <span class="ux-reference-field__value-caption taValue {{item.styleClass}}"
          title="{{item[itemCaptionKey]}}"
          (tap)="onSelectedItemClick.emit(item)">
        {{item[itemCaptionKey]}}
    </span>
</ng-template>



<ng-template #defaultItemTemplate let-item="item">
    {{item[itemCaptionKey]}}
</ng-template>



<ux-field-suggestion-popup #suggestionPopup
                           [styleClass]="listStyleClass"
                           (onClick)="_onPopupClick($event)"
                           [maxListHeight]="mobile ? 'auto' : maxListHeight"
                           [popupContainer]="listContainer"
                           [disablePositionUpdateOnScroll]="listContainer === elementRef"
                           [attachTo]="_referenceWrapper"
                           [(opened)]="opened"
                           [transitionType]="mobile ? 'right-to-left' : 'slide'"
                           [mobile]="mobile"
                           (openedChange)="_onOpenPopover($event)">

    <ng-template *ngIf="opened"
                 nonScrollableBlock
                 [ngTemplateOutlet]="mobile ? defaultMobileToolbarTemplate : toolbarTemplate">
    </ng-template>

    <div #suggestionPopupContent
         class="ux-reference-field__suggestions-popup">
        <ng-template *ngIf="opened"
                     [ngTemplateOutlet]="viewItems && viewItems.length > 0 ? suggestionsList : emptyBlock">
        </ng-template>
    </div>
</ux-field-suggestion-popup>



<ng-template #suggestionsList>
    <ul class="ux-reference-field__suggestions-list">
        <li *ngFor="let item of viewItems; let i = index; trackBy: _trackByFn"
            #items
            (tap)="_onSelect(item, $event)"
            (mouseover)="_onItemHover(i)"
            class="ux-reference-field__suggestion-item"
            [ngClass]="{'_highlighted': !mobile && i === _highlightItemIndex, '_selected': item.selected}">

            <ng-template [ngTemplateOutlet]="itemTemplate || defaultItemTemplate"
                         [ngTemplateOutletContext]="{item: item}"></ng-template>

            <i *ngIf="mobile && multiple && !item.selected && !disabled"
               class="ux-reference-field__suggestion-item-add"
               (tap)="_addValueItem(item, $event)"
            >
                <svg viewBox="0 0 20 20"
                     width="0" height="0"
                     class="ux-reference-field__suggestion-item-add-icon">
                    <path d="M16,10c0,0.553-0.048,1-0.601,1H11v4.399C11,15.951,10.553,16,10,16c-0.553,0-1-0.049-1-0.601V11H4.601
		C4.049,11,4,10.553,4,10c0-0.553,0.049-1,0.601-1H9V4.601C9,4.048,9.447,4,10,4c0.553,0,1,0.048,1,0.601V9h4.399
		C15.952,9,16,9.447,16,10z"></path>
                </svg>
            </i>

        </li>
    </ul>
</ng-template>



<ng-template #emptyBlock>
    <div *ngIf="!(multiple && !onlyExistingItems) && emptyMessage"
         class="ux-reference-field__empty-message">
        <svg viewBox="0 0 20 20" width="0" height="0"
             class="ux-reference-field__empty-message-icon">
            <path
                d="M10 .4C4.697.4.399 4.698.399 10A9.6 9.6 0 0 0 10 19.601c5.301 0 9.6-4.298 9.6-9.601 0-5.302-4.299-9.6-9.6-9.6zM2.399 10a7.6 7.6 0 0 1 12.417-5.877L4.122 14.817A7.568 7.568 0 0 1 2.399 10zm7.6 7.599a7.56 7.56 0 0 1-4.815-1.722L15.878 5.184a7.6 7.6 0 0 1-5.879 12.415z"/>
        </svg>
        <div class="ux-reference-field__empty-message-text">
            {{emptyMessage}}
        </div>
    </div>

    <div *ngIf="!onlyExistingItems && createNewMessage"
         class="ux-reference-field__empty-message _enter">
        <svg viewBox="0 0 20 20" width="0" height="0"
             class="ux-reference-field__empty-message-icon">
            <path d="M19 9v7h-3v-6H6v3L1 8.5 6 4v3h11c1.104 0 2 .897 2 2z"/>
        </svg>
        <div class="ux-reference-field__empty-message-text">
            {{createNewMessage}}
        </div>
    </div>
</ng-template>



<ux-popover class="ux-reference-field__options-popover _white _no-padding _no-arrow"
            [attachToEl]="_optionsPopoverAttachToEl"
            [attachPosition]="'bottom right'"
            [attachPositionsSequence]="['top right']"
            [visible]="optionsPopoverVisible"
            (visibleChange)="_optionsPopoverVisibleChange($event)"
            [closable]="false"
            [closeOnOutsideClick]="true"
            [containerEl]="mobile ? _body : listContainer['nativeElement']"
            [disablePositionUpdateOnScroll]="listContainer === elementRef"
            transitionType="slide"
            [targetIndent]="4"
            (tap)="$event.stopPropagation()">
    <ng-template *ngIf="optionsPopoverVisible"
                 [ngTemplateOutlet]="valueItemOptionsTemplate ? valueItemOptionsTemplate : defaultValueItemOptionsTemplate"
                 [ngTemplateOutletContext]="{item: _clickedValueItem}">
    </ng-template>
</ux-popover>



<ng-template #defaultValueItemOptionsTemplate let-item="item">
    <ux-menu [items]="item?.menuItems"
             (onItemSelect)="_onValueItemOptionSelect($event, item)"
             (tap)="mobile && $event.stopPropagation()"
    ></ux-menu>
</ng-template>



<ng-template #defaultMobileToolbarTemplate>
    <div class="ux-reference-field__popover-toolbar">
        <ux-button [text]="mobileCancelButtonText"
                   (onClick)="_onCancelButtonClick()"></ux-button>
        <span class="ux-reference-field__popover-toolbar-text">{{mobileCaptionText}}</span>
        <ux-button *ngIf="multiple"
                   [text]="mobileOkButtonText"
                   [kind]="'primary'"
                   (onClick)="_onOkButtonClick()"></ux-button>
    </div>

    <div *ngIf="multiple"
         class="ux-reference-field__popover-values">

        <ng-template [ngTemplateOutlet]="valuesListTemplate"
                     [ngTemplateOutletContext]="{values: _tempValues}"
        ></ng-template>

    </div>

    <div class="ux-reference-field__popover-input-wrapper">
        <input #inputElement
               class="ux-reference-field__popover-input taValue"
               [(ngModel)]="viewValue"
               [placeholder]="placeholder"
               [disabled]="disabled"
               (focus)="_onFocus($event)"
               (blur)="_onBlur($event)"
               (keyup)="_onInputKeyUp($event)"
               (keydown)="_onInputKeyDown($event)"
               type="text"
        />
        <svg width="0" height="0"
             class="ux-reference-field__popover-input-cross"
             viewBox="0 0 20 20"
             (tap)="_onInputCrossClick()">
            <path d="M14.348,14.849c-0.469,0.469-1.229,0.469-1.697,0L10,11.819l-2.651,3.029c-0.469,0.469-1.229,0.469-1.697,0
            c-0.469-0.469-0.469-1.229,0-1.697l2.758-3.15L5.651,6.849c-0.469-0.469-0.469-1.228,0-1.697c0.469-0.469,1.228-0.469,1.697,0
            L10,8.183l2.651-3.031c0.469-0.469,1.228-0.469,1.697,0c0.469,0.469,0.469,1.229,0,1.697l-2.758,3.152l2.758,3.15
            C14.817,13.62,14.817,14.38,14.348,14.849z"/>
        </svg>
    </div>
</ng-template>



<ng-template #valuesListTemplate let-values="values">
    <div class="ux-reference-field__values-list">
        <span #valueItemWrapper
              *ngFor="let valueItem of values; trackBy: _trackByFn"
              class="ux-reference-field__value-item-wrapper"
              [ngClass]="{'_token': valueItem.type === 'token',
                          '_opened-options': optionsPopoverVisible && valueItem === _clickedValueItem,
                          '_disabled': disabled}"
              (tap)="mobile && _toggleValueItemOptions(valueItem, $event, valueItemWrapper)">

            <span *ngIf="valueItem" class="ux-reference-field__value-item">

                <ng-template [ngTemplateOutlet]="valueItemTemplate || defaultValueItemTemplate"
                             [ngTemplateOutletContext]="{item: valueItem}"
                ></ng-template>

                <i *ngIf="valueItem.type !== 'token'"
                   class="ux-reference-field__value-item-remove"
                   [ngClass]="{_disabled: disabled}"
                   (tap)="!disabled && removeValueItem(valueItem)"
                >
                    <svg viewBox="0 0 20 20"
                         width="0" height="0"
                         class="ux-reference-field__value-item-remove-icon">
                        <path
                            d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/>
                    </svg>
                </i>

                <i *ngIf="valueItem.type === 'token'"
                   class="ux-reference-field__value-item-arrow"
                   [ngClass]="{ _disabled : disabled }"
                   (tap)="_toggleValueItemOptions(valueItem, $event, valueItemWrapper)"
                   (click)="!optionsPopoverVisible && $event.stopPropagation()"
                >
                    <svg viewBox="0 0 20 20"
                         width="0" height="0"
                         class="ux-reference-field__value-item-arrow-icon">
                        <path
                            d="M13.418 7.859a.695.695 0 0 1 .978 0 .68.68 0 0 1 0 .969l-3.908 3.83a.697.697 0 0 1-.979 0l-3.908-3.83a.68.68 0 0 1 0-.969.695.695 0 0 1 .978 0L10 11l3.418-3.141z"/>
                    </svg>
                </i>

              </span>
        </span>
    </div>
</ng-template>
