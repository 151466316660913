import {AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild} from "@angular/core";
import {UxAbstractViewValueFieldComponent} from "../abstract-view-value-field.component";
import {UxValueChangeEvent} from "../abstract-field.component";
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: "ux-text-field",
    templateUrl: "text-field.component.html",
    host: {"[class.ux-text-field]": "true"},
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: UxTextFieldComponent,
            multi: true
        }
    ]
})
export class UxTextFieldComponent extends UxAbstractViewValueFieldComponent<string, string> implements AfterViewInit {

    @Input()
    public autofocus: boolean = false;

    @Output()
    public onEnter: EventEmitter<KeyboardEvent> = new EventEmitter<KeyboardEvent>();

    @ViewChild("uxInput", { static: true })
    protected uxInput: ElementRef;

    public ngAfterViewInit(): void {
        if (this.autofocus) {
            this.focus();
        }
    }

    public focus(): void {
        setTimeout(() => {
            this.uxInput.nativeElement.focus();
        }, 0);
    }

    protected getDefaultValue(): string {
        return "";
    }

    protected getValueConverter(): { (value: string): string } {
        return (value) => value;
    }

    /**
     * Will be called after value is changed.
     *
     * @internal
     */
    public _onValueChange(event: UxValueChangeEvent<string>): void {
        if (event.oldValue !== event.newValue && this.onValueChange) {
            let newValue: string = event.newValue as any;

            this.onValueChange.emit(event);
            this.valueChange.emit(newValue);
            this.propagateChange && this.propagateChange(newValue);
            this.propagateChange && this.propagateTouch(newValue);
        }
    }

    public _onEnter(event: KeyboardEvent): void {
      if (this.onEnter) {
        this.onEnter.emit(event);
      }
    }

    /**
     * Will be called to submit value by 'enter' key press or 'blur' event. View value will be propagated to value.
     * @private
     * @internal
     */
    public _onSubmitValueChange(event: Event): void {
        this.onSubmitValueChange.emit({
            value: this.value,
            originalEvent: event
        });
    }
}
