<ng-content select="ux-toolbar-left"></ng-content>

<!-- TODO Remove in 2.0.0 -->
<div class="ux-toolbar__left">
    <ng-content select="[ux-toolbar-left]"></ng-content>
</div>
<!--------------------->

<!-- TODO Remove in 2.0.0 -->
<div class="ux-toolbar__right">
    <ng-content select="[ux-toolbar-right]"></ng-content>
</div>
<!--------------------->

<ng-content select="ux-toolbar-right"></ng-content>