import {Component} from "@angular/core";

@Component({
    selector: "ux-header-group",
    template: "<ng-content></ng-content>",
    host: {"[class.ux-header-group]": "true"}
})
export class UxHeaderGroupComponent {

}
