
import {EventEmitter, Injectable} from "@angular/core";
import {NotificationItem} from "../models/notification-item";

@Injectable()
export class NotificationService {
  notifications: NotificationItem[] = [];

  private notificationsUpdated: EventEmitter<void> = new EventEmitter<void>();

  constructor() {
  }

  pushNotification(notification: NotificationItem) {
    notification.type = notification.type || "info";
    notification.caption = notification.caption || "";
    notification.content = notification.content || "";
    notification.timeout = (notification.timeout === undefined || notification.timeout === null) ? 0 : notification.timeout;
    notification.closable = (notification.closable === undefined || notification.closable === null) ? true : notification.closable;
    notification.visible = (notification.visible === undefined || notification.visible === null) ? true : notification.visible;

    this.notifications.push(notification);

    this.notificationsUpdated.emit();
  }

  getNotification(): NotificationItem {
    return this.notifications[this.notifications.length-1];
  }

  popNotification(): NotificationItem {
    return this.notifications.pop();
  }

  getUpdates(): EventEmitter<void> {
    return this.notificationsUpdated;
  }
}
