<div
    class="ux-table__header {{_sortedTable?.header?.styleClass}} {{_uxScroll._sliderVerticalWrapVisible ? '_scroll' : null}}"
    *ngIf="_sortedTable?.header"
>
    <ux-table-row
        (onColumnClick)="_onColumnClick($event)"
        (onFilterClick)="_onFilterClick($event)"
        (onLinkClick)="_onLinkClick($event)"
        (onPinClick)="_onPinClick($event)"
        class="ux-table__row taHeaderRow {{row?.styleClass}}"
        *ngFor="let row of _sortedTable?.header?.rows; trackBy: _trackByFn"
        [ngClass]="{
                                  '_selected': row?.selected
                              }"
        [ngStyle]="row?.inlineStyles"
        [row]="row"
        [header]="true"
    ></ux-table-row>
</div>

<ux-scroll
    (onScrollEndPositionChange)="_onScrollEndPosition($event)"
    [minVerticalSliderSize]="minVerticalSliderSize"
    [showSliders]="true"
    #scroll
    [ngClass]="{
            'ux-table__ux-scroll': true,
             '_scroll': _uxScroll._sliderVerticalWrapVisible
        }"
>
    <div
        class="ux-table__body {{_sortedTable?.body?.styleClass}} {{_uxScroll._sliderVerticalWrapVisible ? '_scroll' : null}}"
        #tableBody
    >

        <ng-template [ngTemplateOutlet]="defaultContentTemplate"></ng-template>

        <ng-container *ngIf="!_sortedTable?.body?.rows?.length && !!_sortedTable.emptyTableContent">
            <div class="ux-table__empty-content"
                 *ngIf="_tableEmptyContentString"
                 [innerHTML]="_tableEmptyContentString">
            </div>

            <div class="ux-table__empty-content"
                 *ngIf="_tableEmptyContentTemplate">
                <ng-template
                    [ngTemplateOutlet]="_tableEmptyContentTemplate"
                    [ngTemplateOutletContext]="{model: _sortedTable}"
                >
                </ng-template>
            </div>

        </ng-container>
    </div>
</ux-scroll>

<div #uxTableBodyShadow
     class="ux-table__body-shadow _visible"
     *ngIf="_uxScroll._sliderVerticalWrapVisible">
</div>

<ng-template #defaultContentTemplate>
    <ux-table-row *ngFor="let row of _sortedTable?.body?.rows; trackBy: _trackByFn"
                  class="ux-table__row taRow {{row?.styleClass}}"
                  (onColumnClick)="_onColumnClick($event)"
                  (onFilterClick)="_onFilterClick($event)"
                  (onLinkClick)="_onLinkClick($event)"
                  (onPinClick)="_onPinClick($event)"
                  [ngClass]="{ 'taItem _selected': row?.selected }"
                  [ngStyle]="row?.inlineStyles"
                  [row]="row"
    ></ux-table-row>
</ng-template>
