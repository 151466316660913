import {Component, Input} from "@angular/core";
import {NG_VALUE_ACCESSOR} from "@angular/forms";
import {UxAbstractFieldComponent} from "../abstract-field.component";
import {UxDropdownListItem} from "../dropdown/dropdown-field.component";
import {UxSwitchItemModel} from "./switch-item.model";

@Component({
    selector: "ux-switch-selector-field",
    templateUrl: "./switch-selector-field.component.html",
    host: {"[class.ux-switch-selector-field]": "true"},
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: UxSwitchSelectorFieldComponent,
            multi: true
        }
    ]
})
export class UxSwitchSelectorFieldComponent extends UxAbstractFieldComponent<UxSwitchItemModel> {
  @Input()
  public compact: boolean;

  @Input()
  public name: string;

  @Input()
  public items: UxSwitchItemModel[];

  @Input()
  public placeholder: string;

  _onButtonClicked(item: UxSwitchItemModel) {
    this.value = item;
  }

  protected getValueConverter(): (value: any) => UxSwitchItemModel {
    return (value: any): Object => {
      if (typeof value === "object") {
        return value;
      }
      try {
        return JSON.parse(value);
      } catch (error) {
        throw new Error(`switch-selector-field: unable to parse value ${value}`);
      }
    };
  }

  protected getDefaultValue(): UxSwitchItemModel {
    return undefined;
  }

  /** @internal */
  public _trackByFn(index: number, item: UxSwitchItemModel): string {
    return item && item.id;
  }
}
