import {NgModule} from "@angular/core";
import {SharedModule} from "../../../shared/shared.module";
import {UxLinearDotsLoaderComponent} from "./linear-dots-loader.component";

@NgModule({
    imports: [SharedModule],
    exports: [UxLinearDotsLoaderComponent],
    declarations: [UxLinearDotsLoaderComponent]
})
export class UxLinearDotsLoaderModule {

}
