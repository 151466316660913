<div
    *ngIf="transitionType !== 'slide'"
    class="ux-popover__inner taPopup"
    [uxFadeToggle]="visible"
    (onFadeEnd)="_onAnimationEnd()"
    (onFadeStart)="_onAnimationStart()"
    [attr.tabindex]="closeOnEsc ? '0' : null"
    [ngClass]="{
        '_no-caption': caption === '' || !caption,
        '_mobile' : mobile,
        '_top': positionStyleModifier === 'top',
        '_left': positionStyleModifier === 'left',
        '_right': positionStyleModifier === 'right',
        '_bottom': positionStyleModifier === 'bottom',
        '_bottom-left': positionAndAlignStyleModifier === 'bottom-left',
        '_bottom-center': positionAndAlignStyleModifier === 'bottom-center',
        '_bottom-right': positionAndAlignStyleModifier === 'bottom-right',
        '_top-left': positionAndAlignStyleModifier === 'top-left',
        '_top-center': positionAndAlignStyleModifier === 'top-center',
        '_top-right': positionAndAlignStyleModifier === 'top-right',
        '_left-top': positionAndAlignStyleModifier === 'left-top',
        '_left-center': positionAndAlignStyleModifier === 'left-center',
        '_left-bottom': positionAndAlignStyleModifier === 'left-bottom',
        '_right-top': positionAndAlignStyleModifier === 'right-top',
        '_right-center': positionAndAlignStyleModifier === 'right-center',
        '_right-bottom': positionAndAlignStyleModifier === 'right-bottom'
    }"
>

    <ng-template [ngTemplateOutlet]="popoverInnerTemplate"></ng-template>

</div>

<div
    *ngIf="transitionType === 'slide'"
    class="ux-popover__inner taPopup"
    [uxSlideToggle]="visible"
    [uxSlideToggleDuration]="80"
    (onSlideEnd)="_onAnimationEnd()"
    (onSlideStart)="_onAnimationStart()"
    [attr.tabindex]="closeOnEsc ? '0' : null"
    [ngClass]="{'_no-caption': caption === '' || !caption}"
>

    <ng-template [ngTemplateOutlet]="popoverInnerTemplate"></ng-template>

</div>

<ng-template #popoverInnerTemplate>

    <div #arrowEl class="ux-popover__arrow"></div>

    <ng-container
        *ngIf="_contentVisible"
    >

        <div class="ux-popover__header">

            <div *ngIf="closable"
                 class="ux-popover__close-icon taButton"
                 (tap)="_onCloseClick($event)"
            >
                <svg class="ux-popover__close-icon-svg"
                     width="0" height="0"
                     viewBox="0 0 20 20">
                    <path d="M14.348,14.849c-0.469,0.469-1.229,0.469-1.697,0L10,11.819l-2.651,3.029c-0.469,0.469-1.229,0.469-1.697,0
            c-0.469-0.469-0.469-1.229,0-1.697l2.758-3.15L5.651,6.849c-0.469-0.469-0.469-1.228,0-1.697c0.469-0.469,1.228-0.469,1.697,0
            L10,8.183l2.651-3.031c0.469-0.469,1.228-0.469,1.697,0c0.469,0.469,0.469,1.229,0,1.697l-2.758,3.152l2.758,3.15
            C14.817,13.62,14.817,14.38,14.348,14.849z"/>
                </svg>
            </div>

            <ng-container *ngIf="(caption || _customHeader) && !headerTemplate">
                <div class="ux-popover__header-inner taLabel">
                    {{_customHeader ? "" : caption}}
                    <ng-content *ngIf="_customHeader" select="ux-content-header"></ng-content>
                </div>
            </ng-container>

            <ng-template *ngIf="headerTemplate"
                         [ngTemplateOutlet]="headerTemplate"
                         [ngTemplateOutletContext]="{model: customData}">
            </ng-template>
        </div>

        <div class="ux-popover__content">
            <ng-container *ngIf="!template">
                <ng-content></ng-content>
            </ng-container>

            <ng-template *ngIf="template"
                         [ngTemplateOutlet]="template"
                         [ngTemplateOutletContext]="{model: customData}">
            </ng-template>
        </div>

    </ng-container>

</ng-template>
