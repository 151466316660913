export type SipConfigType = 'sip_domain'
  | 'sip_username'
  | 'sip_password'
  | 'stun_server'
  | 'transport';

export class SipConfigField {

  public static readonly SIP_DOMAIN: SipConfigType    = 'sip_domain';
  public static readonly SIP_USERNAME: SipConfigType  = 'sip_username';
  public static readonly SIP_PASSWORD: SipConfigType  = 'sip_password';
  public static readonly STUN_SERVER: SipConfigType   = 'stun_server';
  public static readonly TRANSPORT: SipConfigType   = 'transport';
}
