<div *ngIf="caption" class="ux-menu__caption">{{caption}}</div>
<div class="ux-menu__list-wrapper">
    <div class="ux-menu__list-content">
        <ux-scroll class="_full-height">
            <ul class="ux-menu__list">

                <li *ngFor="let item of items; trackBy: _trackByFn"
                    class="ux-menu__item"
                    [ngClass]="{'_active': item.selected, '_disabled': item.disabled}"
                    (tap)="_itemClick($event, item)">

                    <ng-template [ngTemplateOutlet]="template || defaultTemplate"
                                 [ngTemplateOutletContext]="{item: item}">
                    </ng-template>

                    <div *ngIf="item.closeable"
                         [ngClass]="{'ux-menu__item-close': true}"
                         (tap)="_onItemClose($event, item)">
                        <svg width="0" height="0" version="1.1" xmlns="http://www.w3.org/2000/svg"
                             viewBox="0 0 20 20">
                            <path d="M3.389,7.113L4.49,18.021C4.551,18.482,6.777,19.998,10,20c3.225-0.002,5.451-1.518,5.511-1.979l1.102-10.908
		                        C14.929,8.055,12.412,8.5,10,8.5C7.59,8.5,5.072,8.055,3.389,7.113z M13.168,1.51l-0.859-0.951C11.977,0.086,11.617,0,10.916,0
		                        H9.085c-0.7,0-1.061,0.086-1.392,0.559L6.834,1.51C4.264,1.959,2.4,3.15,2.4,4.029v0.17C2.4,5.746,5.803,7,10,7
		                        c4.198,0,7.601-1.254,7.601-2.801v-0.17C17.601,3.15,15.738,1.959,13.168,1.51z M12.07,4.34L11,3H9L7.932,4.34h-1.7
		                        c0,0,1.862-2.221,2.111-2.522C8.533,1.588,8.727,1.5,8.979,1.5h2.043c0.253,0,0.447,0.088,0.637,0.318
		                        c0.248,0.301,2.111,2.522,2.111,2.522H12.07z"></path>
                        </svg>
                    </div>
                </li>
            </ul>
        </ux-scroll>
    </div>
</div>


<ng-template #defaultTemplate let-item="item">
  <div *ngIf="item.iconClass"
       [ngClass]="{'ux-menu__item-icon':true}"
       [class]="item.iconClass"></div>
  <div class="ux-menu__item-caption-wrapper">
    <span class="ux-menu__item-caption" *ngIf="item.caption" title="{{item.caption}}">{{item.caption}}</span>
    <span class="ux-menu__item-description" *ngIf="item.description">{{item.description}}</span>
  </div>
</ng-template>
