import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ContentChild,
    ElementRef,
    EventEmitter,
    HostBinding,
    HostListener,
    Input,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    TemplateRef,
    ViewChild,
    ViewChildren
} from "@angular/core";
import {ReferenceSelectorItem, UxReferenceSelectorItem} from "./reference-item.model";
import {UxValueChangeEvent} from "../abstract-field.component";
import {UxAbstractViewValueFieldComponent} from "../abstract-view-value-field.component";
import {UxFieldSuggestionPopupComponent} from "../suggestion-popup/field-suggestion-popup.component";
import {MenuItemChangeEvent} from "../../menu/model/menu-item-change-event.model";
import {NG_VALUE_ACCESSOR} from "@angular/forms";
import {UxPropertyConverter} from "../../../common/decorator/ux-property-converter";
import {UxPropertyHandler} from "../../../common/decorator/ux-property-handler";

@Component({
    selector: "ux-reference-field",
    templateUrl: "./reference-field.component.html",
    host: {
        "[class.ux-reference-field]": "true"
    },
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: UxReferenceFieldComponent,
            multi: true
        }
    ]
})
export class UxReferenceFieldComponent extends UxAbstractViewValueFieldComponent<ReferenceSelectorItem[], string> implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild("wrapper", { static: true })
    /** @internal */
    public _referenceWrapper: ElementRef;

    @UxPropertyConverter("boolean", true)
    @Input()
    public showDropdownButton: boolean;

    @HostBinding("class._multiple")
    @UxPropertyConverter("boolean", false)
    @UxPropertyHandler({
        afterChange: afterChangeMultiple
    })
    @Input()
    public multiple: boolean;

    @HostBinding("class._loading")
    @UxPropertyConverter("boolean", false)
    @Input()
    public isLoading: boolean;

    @UxPropertyConverter("boolean", false)
    @Input()
    public onlyExistingItems: boolean;

    @Input()
    public autofocus: boolean = false;

    @UxPropertyConverter("number", 300)
    @Input()
    public delay: number;

    @UxPropertyConverter("string", "caption")
    @Input()
    public itemCaptionKey: string;

    @UxPropertyConverter("string", "Nothing Found")
    @Input()
    public emptyMessage: string;

    @Input()
    public createNewMessage: string = "Press Enter to Create";

    @Input()
    public maxListHeight: string = "200px";

    @UxPropertyHandler({
        afterChange: afterChangeListContainer
    })
    @Input()
    public listContainer: ElementRef | string;

    @Input()
    public listStyleClass: string;

    @Input()
    public itemTemplate: TemplateRef<{ item: ReferenceSelectorItem }>;

    @Input()
    public valueItemTemplate: TemplateRef<{ item: ReferenceSelectorItem }>;

    @Input()
    public valueTemplate: TemplateRef<{ item: ReferenceSelectorItem; }>;

    @Input()
    public valueItemOptionsTemplate: TemplateRef<{ item: ReferenceSelectorItem }>;

    @Input()
    public toolbarTemplate: TemplateRef<any>;

    @Input()
    public optionsPopoverVisible: boolean = false;

    @Input()
    public mobileCancelButtonText: string = "Cancel";

    @Input()
    public mobileOkButtonText: string = "Done";

    @Input()
    public mobileCaptionText: string = "";

    @Input()
    public excludeCharacters: string = "";

    @Output()
    public onSelect: EventEmitter<ReferenceSelectorItem> = new EventEmitter<ReferenceSelectorItem>();

    @Output()
    public onDropdownClick: EventEmitter<Event> = new EventEmitter<Event>();

    @Output()
    public onSelectedItemClick: EventEmitter<ReferenceSelectorItem> = new EventEmitter<ReferenceSelectorItem>();

    @Output()
    public onPopupClick: EventEmitter<Event> = new EventEmitter<Event>();

    @Output()
    public onSearchValueChange: EventEmitter<string> = new EventEmitter<string>();

    @Output()
    public onCreateNewValue: EventEmitter<string> = new EventEmitter<string>();

    @Output()
    public onUnselect: EventEmitter<ReferenceSelectorItem> = new EventEmitter<ReferenceSelectorItem>();

    @Output()
    public openedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Output()
    public optionsPopoverVisibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Output()
    public onValueItemOptionSelect: EventEmitter<{ event: MenuItemChangeEvent, item: ReferenceSelectorItem }> = new EventEmitter<{ event: MenuItemChangeEvent, item: ReferenceSelectorItem }>();

    public searchFilterFunction: Function =
        function (item: ReferenceSelectorItem, itemCaptionKey: string, searchValue: string = ""): boolean {
            return item[itemCaptionKey].toLowerCase().indexOf(searchValue.trim().toLowerCase()) >= 0;
        };

    @UxPropertyHandler({
        afterChange: updateViewItems
    })
    @Input()
    public get items(): ReferenceSelectorItem[] {
        return this._items;
    }

    public set items(values: ReferenceSelectorItem[]) {
        let self = this;
        self._items = values;

        self.clearItemsSelection();
        self.resetItemsSelection(self.value);
    }

    @Input()
    public set opened(value: boolean) {
        let self = this;
        if (value !== self.opened) {
            self._showItems = value;
            self.openedChange.emit(value);
        }
    }

    public get opened(): boolean {
        return this._showItems;
    }

    /**
     * Usable for single reference. Return first element of values array.
     *
     * @return {ReferenceSelectorItem}
     */
    @Input()
    public get singleValue(): ReferenceSelectorItem {
        return this.value ? this.value[0] : null;
    }

    /**
     * Usable for single reference. Function set to value array with one element inside.
     *
     * @param value
     */
    public set singleValue(value: ReferenceSelectorItem) {
        this.value = value ? [value] : null;
    }


    /** @internal */
    public _customIcon: boolean = false;

    /** @internal */
    public _body: HTMLElement;

    /** @internal */
    public _items: ReferenceSelectorItem[];

    @HostBinding("class._opened")
    /** @internal */
    public _showItems: boolean = false;

    /** @internal */
    public _highlightItemIndex: number = -1;

    /** @internal */
    public _optionsPopoverAttachToEl: Element;

    /** @internal */
    public _clickedValueItem: ReferenceSelectorItem;

    /** @internal */
    public _tempValues: ReferenceSelectorItem[] = [];

    @ViewChild("inputElement")
    private inputElement: ElementRef;

    @ViewChild("suggestionPopupContent", { static: true })
    private suggestionPopupContent: ElementRef;

    @ViewChild("suggestionPopup", { static: true })
    private suggestionPopup: UxFieldSuggestionPopupComponent;

    @ViewChildren("items")
    private itemsList: QueryList<ElementRef>;

    @ContentChild("customIcon", /* TODO: add static flag */ {static: true})
    private set customIcon(iconElement: ElementRef) {
        this._customIcon = !!iconElement;
    }

    @HostBinding("class._input-visible")
    private inputVisible: boolean = true;

    private timerId: number;
    private oldInputValue: string;
    private submittingValue: boolean;
    private lastBlurEvent: Event;
    private focusLost: boolean = false;
    private requestAnimationFrameId: number;
    private blurRequestAnimationFrameId: number;
    private focusTimerId: number;

    constructor(/** @internal */ public elementRef: ElementRef, private cdr: ChangeDetectorRef) {
        super();
    }

    public ngOnInit(): void {
        this.listContainer = this.listContainer ? this.listContainer : this.elementRef;
    }

    public ngAfterViewInit(): void {
        this._body = document.body;

        if (this.autofocus) {
            this.focus();
        }
    }

    public ngOnDestroy(): void {
        cancelAnimationFrame(this.requestAnimationFrameId);
        cancelAnimationFrame(this.blurRequestAnimationFrameId);
        clearTimeout(this.timerId);
        clearTimeout(this.focusTimerId);
    }

    public focus(): void {
        clearTimeout(this.focusTimerId);
        this.focusTimerId = window.setTimeout(() => {
            this.focusTimerId = null;
            this.inputElement.nativeElement.focus();
        }, 0);
    }

    /** @internal */
    public viewItems: ReferenceSelectorItem[];

    public updateViewItems(): void {
        let self = this;

        let selectedElement = self._highlightItemIndex >= 0 ? self.viewItems[self._highlightItemIndex] : null;
        let selectedId = selectedElement ? selectedElement.id : null;

        self.viewItems = self.viewValue && self.items ?
            self.items.filter((item: ReferenceSelectorItem) => {
                    if (item && item[self.itemCaptionKey]) {
                        return self.searchFilterFunction(item, self.itemCaptionKey, self.viewValue);
                    }
                }
            ) : self.items;

        // Save selection of element
        self._highlightItemIndex = -1;
        if (selectedId && self.viewItems.length) {
            for (let i = 0; i < self.viewItems.length; i++) {
                if (selectedId === self.viewItems[i].id) {
                    self._highlightItemIndex = i;
                    cancelAnimationFrame(self.requestAnimationFrameId);
                    self.requestAnimationFrameId = requestAnimationFrame(() => {
                        self.requestAnimationFrameId = null;
                        self._scrollPopupToSelectedItem(self._highlightItemIndex);
                        self.cdr.detectChanges();
                    });
                    break;
                }
            }
        }
    }

    /** @internal */
    public _onViewValueChange(event: UxValueChangeEvent<ReferenceSelectorItem[]>): void {
        super._onViewValueChange(event);
        this.updateViewItems();
    }

    /**
     * Will be called on dropdown button click.
     *
     * @internal
     */
    public _onDropdownClick(event: Event): void {
        let self = this;

        if (self.opened) {// old. popover was opened
            self.onDropdownClick.emit(event);
            self.opened = false;

        } else {
            // open and focus the field, then open the popover when field height is changed
            self.onFieldClick(event);

            window.setTimeout(() => {
                self.onDropdownClick.emit(event);
                self.opened = true;
                self.viewItems = self.items;
                self.cdr.detectChanges();
            }, 0);
        }
    }

    /** @internal */
    public _onPopupClick(event: Event): void {
        this.onPopupClick.emit(event);
    }

    /**
     * Remove item from value.
     */
    public removeValueItem(item: ReferenceSelectorItem): void {
        let self = this;
        if (!self.mobile || (self.mobile && !self.opened)) {
            self.value = self.value.filter(valueItem => valueItem !== item);
            self.onUnselect.emit(item);
        } else {
            item.selected = false;
            self._tempValues = self._tempValues.filter(valueItem => valueItem !== item);
        }
    }

    /**
     * Add item to view value.
     *
     * @internal
     */
    public _addValueItem(item: ReferenceSelectorItem, event: any): void {
        let self = this;
        event.stopPropagation();
        if (!self.mobile) {
            self.addOrSetValue(item);
            self.onSelect.emit(item);
        } else {
            item.selected = true;
            self._tempValues.push(item);

            window.setTimeout(() => {
                self.inputElement.nativeElement.focus();
            });
        }
    }

    /** @internal */
    public _onFocus(event: Event): void {
        let self = this;

        super._onFocus(event);

        self.inputVisible = true;

        if (!this.mobile) {
            setTimeout(() => {
                document.addEventListener("click", self.onClickOut);
            }, 0);
        }
    }

    /** @internal */
    public _onBlur(event: Event): void {
        this.lastBlurEvent = event; // Will fire onBlurEvent if fieldFocusLost will trigger it
        if (this.focusLost) {
            this.focusLost = false;
            super._onBlur(event);
        }
    }

    protected getDefaultValue(): ReferenceSelectorItem[] {
        return null;
    }

    protected getViewValueConverter(): { (value: ReferenceSelectorItem[]): any } {
        return (fromValue: ReferenceSelectorItem[]): any =>
            fromValue && !this.multiple && fromValue.length > 0 ? fromValue[0][this.itemCaptionKey] : null;
    }

    protected getValueConverter(): { (value: any): ReferenceSelectorItem[] } {
        return function (value: any): ReferenceSelectorItem[] {
            return value;
        };
    }

    private onClickOut = (e: Event) => {
        const self = this,
            target = <HTMLElement>e.target,
            containsClass = containsClasses(["ux-scroll", "ux-scroll__slider-vertical", "ux-scroll__slider-horizontal"], target);

        if (
            !(containsClass ||
                self._referenceWrapper.nativeElement.contains(target))
        ) {
            self.fieldFocusLost();
        } else {
            e.stopPropagation();
        }
    };

    @HostListener("tap")
    private onFieldClick(event: Event): void {
        let self = this;
        if (!self.disabled) {
            if (!self.mobile) {
                self.inputVisible = true;
                self.inputElement.nativeElement.focus();
            } else {
                self.showItems();
            }
        }
    }

    private addOrSetValue(item: ReferenceSelectorItem): void {
        if (this.multiple) {
            this.addValue(item);
        } else {
            this.setValue(item);
        }
    }

    private hideItems(): void {
        this._highlightItemIndex = -1;
        this.opened = false;
    }

    private showItems(): void {
        if (this.elementRef.nativeElement) {
            this.opened = true;
        }
    }

    private fieldFocusLost(): void {
        let self = this;

        self.hideItems();

        if (this.value && this.value.length > 0) {
            self.inputVisible = false;
        }

        if (self.multiple) {
            self.addValue(self.items ? self.items.find(item => item[self.itemCaptionKey] === self.viewValue) : null);
            self.viewValue = "";
        } else {
            let caption = self.value && self.value[0] ? self.value[0][self.itemCaptionKey] : null;
            if (self.viewValue !== undefined && self.viewValue !== caption) {

                let newItem = this.items ? this.items.find(item => item[this.itemCaptionKey] === this.viewValue) : null;

                if (!this.onlyExistingItems && !newItem) {
                    this.createNewItem(this.viewValue, true);

                } else {
                    this.setValue(newItem);
                }
            }
        }

        self.focusLost = true;
        self._onBlur(self.lastBlurEvent);
        document.removeEventListener("click", self.onClickOut);
    }

    private setValue(item: ReferenceSelectorItem): void {
        this.value = item ? [item] : [];
    }

    private setInputValue(newInputValue: string): void {
        let self = this;
        if (self.viewValue !== newInputValue) {
            self.viewValue = newInputValue;
            self.onSearchValueChange.emit(newInputValue);
            self.oldInputValue = self.viewValue;
        }
    }

    private addValue(item: ReferenceSelectorItem): void {
        let self = this;
        if (item) {
            if (!self.value || self.value.length === 0 || !self.value.find(valueItem => valueItem === item)) {
                let newValue = Object.assign([], self.value);
                newValue.push(item);
                // reassign reference for angular change detection
                self.value = newValue;
            }
            self.setInputValue(null);
        } else if (self.onlyExistingItems) {
            self.setInputValue(null);
        }
    }

    /** @internal */
    public _onValueChange(event: UxValueChangeEvent<ReferenceSelectorItem[]>): void {
        let self = this;
        let newViewValue = self.getViewValueConverter().apply(self, [self.value]);

        if (self.multiple || self.onlyExistingItems || self.viewValue !== newViewValue) {
            self.setInputValue(newViewValue);
        }

        if (event.oldValue !== event.newValue && self.onValueChange) {
            self.onValueChange.emit(event);
            self.valueChange.emit(event.newValue);
        }

        if (!self.value || self.value.length === 0) {
            self.inputVisible = true;
        } else {
            if (!self.focused) {
                self.inputVisible = false;
            }
        }

        self.resetItemsSelection(event.newValue, event.oldValue);

        this.propagateChange && this.propagateChange(this.value);
        this.propagateChange && this.propagateTouch(this.value);
    }

    /** @internal */
    public _onItemHover(index: number): void {
        this._highlightItemIndex = index;
    }

    /** @internal */
    public _onInputKeyUp(event: KeyboardEvent): void {
        let self = this;
        if (self.viewValue !== self.oldInputValue) {
            /*enter*/
            if (event.which === 13 && self.submittingValue) {
                self.onSearchValueChange.emit(self.viewValue);
                self.submittingValue = false;
            } else {
                clearTimeout(self.timerId);
                self.timerId = window.setTimeout(() => {
                    self.onSearchValueChange.emit(self.viewValue);
                    if (self.viewValue && !this.opened) {
                        self.opened = !self.opened;
                    }
                    clearTimeout(self.timerId);
                }, self.delay);
            }
        } else {
          if (event.which === 13 && self.submittingValue && self.opened) {
            self.onCreateNewValue.emit(self.viewValue);
            self.submittingValue = false;
            clearTimeout(self.timerId);
            self.timerId = window.setTimeout(() => {
              if (self.viewValue && this.opened) {
                self.opened = !self.opened;
              }
              clearTimeout(self.timerId);
            }, self.delay);
          }
        }
        self.oldInputValue = self.viewValue;
    }

    /** @internal */
    public _onInput(event: Event): void {
        let exclude = this.excludeCharacters;
        if (exclude && exclude.length) {
            for (let i = 0; i < exclude.length; i++) {
                this.viewValue = this.viewValue.split(exclude[i]).join("");
            }
        }
    }

    /** @internal */
    public _onSelect(item: ReferenceSelectorItem, event: any): void {
        let self = this;

        if (self.mobile && self.multiple) {

            if (item.selected) {
                self.removeValueItem(item);
            } else {
                self.viewValue = "";
                self._addValueItem(item, event);
            }

            return;
        }

        self.addOrSetValue(item);
        self.hideItems();
        self.onSelect.emit(item);
    }

    /** @internal */
    public _clearInput(): void {
        this.setInputValue(null);
    }

    /** @internal */
    public _scrollPopupToSelectedItem(index: number): void {
        if (index < this._items.length && index >= 0) {
            this.suggestionPopup.scrollToElement(this.itemsList.toArray()[index].nativeElement);
        }
    }

    private submitOrAddViewValue(): void {
        let self = this;
        if ((self.multiple && !self.onlyExistingItems) && !self.isLoading) {
            let items = self.getItemsByValue(self.viewValue);
            if (items && items.length) {
                self.addOrSetValue(items[0]);
                self.hideItems();
            } else {
                if (self.viewValue && self.viewValue.trim().length) { // no items
                    self.createNewItem(self.viewValue);
                    self.hideItems();
                }
            }
        } else {
            self.submittingValue = true;
        }
    }

    /** @internal */
    public _onInputKeyDown(event: KeyboardEvent): void {
        let self = this;
        if (self.opened) {
            switch (event.which) {
                // down
                case 40:
                    if (self._highlightItemIndex >= 0) {
                        if (self._highlightItemIndex + 1 < self.viewItems.length) {
                            self._highlightItemIndex = self._highlightItemIndex + 1;
                            self._scrollPopupToSelectedItem(self._highlightItemIndex);
                        }
                    } else {
                        this._highlightItemIndex = 0;
                    }
                    event.preventDefault();
                    break;

                // up
                case 38:
                    if (self._highlightItemIndex > 0) {
                        self._highlightItemIndex = self._highlightItemIndex - 1;
                        self._scrollPopupToSelectedItem(self._highlightItemIndex);
                    }
                    event.preventDefault();
                    break;

                // enter
                case 13:
                    //select highlighted item
                    if (self._highlightItemIndex >= 0) {
                        self.addOrSetValue(self.viewItems[self._highlightItemIndex]);
                        self.hideItems();
                    } else {
                        self.submitOrAddViewValue();
                    }
                    event.preventDefault();
                    break;

                // space
                case 32:
                    if (self.excludeCharacters.indexOf(" ") >= 0) {
                        self.submitOrAddViewValue();
                        event.preventDefault();
                    }
                    break;

                // escape
                case 27:
                    if (self.multiple) {
                        self.viewValue = "";
                    }
                    self.hideItems();
                    event.preventDefault();
                    break;

                // tab
                case 9:
                    if (self._highlightItemIndex >= 0) {
                        self.addOrSetValue(self._items[self._highlightItemIndex]);
                    }
                    self.hideItems();
                    self.fieldFocusLost();
                    break;
            }
        } else {
            switch (event.which) {
                // down
                case 40:
                    self.showItems();
                    break;

                // tab
                case 9:
                    self.fieldFocusLost();
                    break;
            }
        }
        switch (event.which) {
            // backspace
            case 8:
                if (self.multiple && !self.viewValue && self.value.length > 0) {
                    self.removeValueItem(self.value[self.value.length - 1]);
                }
                break;
        }
        super._onKeyDown(event);
    }

    /** @internal */
    public _toggleValueItemOptions(item: ReferenceSelectorItem, event: any, attachToEl: Element): void {
        let self = this;
        if (self.disabled || item.type !== "token") {
            return;
        }

        event.stopPropagation();
        self._clickedValueItem = item;
        self._optionsPopoverAttachToEl = attachToEl;
        self.optionsPopoverVisible = true;
        self.optionsPopoverVisibleChange.emit(self.optionsPopoverVisible);
    }

    /** @internal */
    public _optionsPopoverVisibleChange(event: boolean): void {
        if (this.optionsPopoverVisible !== event) {
            this.optionsPopoverVisible = event;
            this.optionsPopoverVisibleChange.emit(this.optionsPopoverVisible);
        }
    }

    /** @internal */
    public _onValueItemOptionSelect(event: MenuItemChangeEvent, item: ReferenceSelectorItem): void {
        event.item.selected = false;
        event.originalEvent.stopPropagation();
        this.optionsPopoverVisible = false;
        this.onValueItemOptionSelect.emit({event: event, item: item});
    }

    /** @internal */
    public _onInputCrossClick(): void {
        this._clearInput();
    }

    /** only for mobile
     * @internal */
    public _onCancelButtonClick(): void {
        let self = this;
        self.opened = false;

        if (self.multiple) {
            self.resetItemsSelection(self.value, self._tempValues);
        }
    }

    /** only for mobile
     * @internal */
    public _onOkButtonClick(): void {
        let self = this;
        if (self.multiple) {
            self.value = self._tempValues;
        }
        self.opened = false;
    }

    /** only for mobile
     * @internal */
    public _onLabelClick(): void {
        if (this.disabled) {
            return;
        }

        this.opened = true;
    }

    /** only for mobile
     * @internal */
    public _onOpenPopover(opened: boolean): void {
        let self = this;
        if (self.mobile) {
            if (opened) {
                if (self.multiple) {
                    self._tempValues = Object.assign([], self.value);
                } else {
                    let newViewValue = self.getViewValueConverter().apply(self, [self.value]);
                    if (self.viewValue !== newViewValue) {
                        self.viewValue = newViewValue;
                    }
                }

                self.viewItems = self.items;
                self.focus();
            } else {
                cancelAnimationFrame(self.blurRequestAnimationFrameId);
                self.blurRequestAnimationFrameId = requestAnimationFrame(() => {
                    self.blurRequestAnimationFrameId = null;
                    self.focusLost = true;
                    self._onBlur(self.lastBlurEvent);
                    self.cdr.detectChanges();
                });
            }
        }
    }

    private resetItemsSelection(newValues: ReferenceSelectorItem[], oldValues: ReferenceSelectorItem[] = null): void {
        if (Array.isArray(oldValues)) {
            oldValues.forEach(item => item.selected = false);
        }

        if (Array.isArray(newValues)) {
            newValues.forEach(item => item.selected = true);
        }
    }

    private clearItemsSelection(): void {
        if (Array.isArray(this.items)) {
            this.items.forEach(item => {
                if (item.selected) {
                    item.selected = false;
                }
            });
        }
    }

    private createNewItem(value: string, isNewArray: boolean = false): void {
        if (value && value.trim().length) {
            let item: ReferenceSelectorItem = {};
            item[this.itemCaptionKey] = value;

            let newValue = isNewArray ? [] : Object.assign([], this.value);
            newValue.push(item);
            // reassign reference for angular change detection
            this.value = newValue;

            if (this.items) {
                this.items.push(item);
            } else {
                this.items = [item];
            }
        }
    }


    private compareItemFunction: Function =
        function (item: ReferenceSelectorItem, itemCaptionKey: string, searchValue: string): boolean {
            if (searchValue && searchValue.trim().length) {
                return item[itemCaptionKey].toLowerCase() === searchValue.trim().toLowerCase();
            }

            return false;
        };

    private getItemsByValue(searchString: string): UxReferenceSelectorItem[] {
        if (!(this.viewItems && this.viewItems.length)) {
            return [];
        }

        return this.viewItems.filter((item: UxReferenceSelectorItem) => {
                if (item && item[this.itemCaptionKey]) {
                    return this.compareItemFunction(item, this.itemCaptionKey, searchString);
                }
            }
        );
    }

    /** @internal */
    public _trackByFn(index: number, item: ReferenceSelectorItem): string {
        return item && item.id;
    }
}

/* Helpers */
export function afterChangeMultiple(newValue: boolean, oldValue: boolean): void {
    if (newValue) {
        this._clearInput();
    }
}

export function updateViewItems(): void {
    this.updateViewItems();
}

function containsClasses(arr: string[], target: Element): boolean {
    return arr.some((item) => target.classList.contains(item));
}

export function afterChangeListContainer(newValue: ElementRef | string, oldValue: ElementRef | string): void {
    if (!newValue) { // Handle empty string
        this.listContainer = this.elementRef;
    }
}
