<div *ngIf="closable"
     class="ux-notification__icon-wrapper"
     (tap)="_onCloseClick($event)">
    <svg class="ux-notification__icon"
         width="0" height="0"
         viewBox="0 0 12 12">
        <path d="M11.2176,10.849 C10.6552527,11.3175916 9.74354726,11.3175916 9.1812,10.849 L6,7.819 L2.8188,10.848 C2.45603509,11.1552684 1.92337865,11.2767925 1.42338559,11.16636 C0.923392529,11.0559274 0.532887113,10.7305062 0.400368046,10.3138453 C0.267848978,9.89718446 0.413677902,9.45330409 0.7824,9.151 L4.092,6.001 L0.7812,2.849 C0.412477902,2.54669591 0.266648978,2.10281554 0.399168046,1.68615466 C0.531687113,1.26949377 0.922192529,0.944072594 1.42218559,0.833640038 C1.92217865,0.723207482 2.45483509,0.844731585 2.8176,1.152 L6,4.183 L9.1812,1.152 C9.54396491,0.844731585 10.0766213,0.723207482 10.5766144,0.833640038 C11.0766075,0.944072594 11.4671129,1.26949377 11.599632,1.68615466 C11.732151,2.10281554 11.5863221,2.54669591 11.2176,2.849 L7.908,6.001 L11.2176,9.151 C11.4879989,9.37608191 11.6399328,9.6815089 11.6399328,10 C11.6399328,10.3184911 11.4879989,10.6239181 11.2176,10.849 Z"></path>
    </svg>
</div>

<div *ngIf="caption"
     class="ux-notification__caption"
     [innerHTML]="caption">
</div>

<div *ngIf="!template" 
     class="ux-notification__content" 
     [innerHtml]="content">
</div>

<div *ngIf="template"
     class="ux-notification__content">
    <ng-template [ngTemplateOutlet]="template"
                 [ngTemplateOutletContext]="{model: customData}">
    </ng-template>
</div>
