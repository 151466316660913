import {NgModule} from "@angular/core";
import {Routes, RouterModule} from "@angular/router";
import {DevicesFullListComponent} from "./components/devices-full-list.component";
import {SevanaQualtestAuthGuard} from "../../sevana-qualtest-auth.guard";

const routes: Routes = [
  {
    path: 'devices',
    canActivate: [SevanaQualtestAuthGuard],
    canActivateChild: [SevanaQualtestAuthGuard],
    children: [
      {path: '', redirectTo: 'list', pathMatch: 'full'},
      {path: 'list', component: DevicesFullListComponent, canActivate: [SevanaQualtestAuthGuard]}
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DevicesRoutingModule {
}
