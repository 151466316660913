import {Component, Renderer2, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
    UxTableColumn, UxTableColumnEvent, UxTableFilterEvent, UxTableLinkEvent, UxTablePinEvent,
    UxTableRow
} from "../table.model";
import {UxTableHeaderModel} from "../header/table-header.model";
import * as $ from 'jquery';


@Component({
    selector: 'ux-table-row',
    templateUrl: 'table-row.component.html'
})

export class UxTableRowComponent {

    private readonly MIN_WIDTH = 100;

    @Output() onColumnClick = new EventEmitter<UxTableColumnEvent>();
    @Output() onLinkClick = new EventEmitter<UxTableLinkEvent>();
    @Output() onPinClick = new EventEmitter<UxTablePinEvent>();
    @Output() onFilterClick = new EventEmitter<UxTableFilterEvent>();

    @Input()
    public row: UxTableRow;

    @Input()
    public header: boolean = false;

    private trackData: TrackData;

    constructor(public renderer: Renderer2) {
    }

    /** @internal */
    public _onColumnClick(column: UxTableColumn, columnIndex: number, row: UxTableRow, originalEvent: any, header: boolean): void {
        let self = this;

        self.onColumnClick.emit({
            column,
            columnIndex,
            row,
            originalEvent,
            header
        });
    }

    /** @internal */
    public _onLinkClick(column: UxTableColumn, columnIndex: number, row: UxTableRow, originalEvent: Event): void {
        let self = this;

        originalEvent.stopPropagation();
        originalEvent.preventDefault();

        self.onLinkClick.emit({
            column, columnIndex, row, originalEvent, header: self.header
        });
    }

    /** @internal */
    public _onPinClick(column: UxTableColumn, columnIndex: number, row: UxTableRow, originalEvent: Event): void {
        let self = this;

        self.onPinClick.emit({
            column, columnIndex, row, pinnedRows: null, originalEvent, header: self.header
        });

        originalEvent.stopPropagation();
    }

    /** @internal */
    public _onFilterClick(column: UxTableColumn, columnIndex: number, row: UxTableRow, originalEvent: Event): void {
        let self = this;

        self.onFilterClick.emit({
            column, columnIndex, row, originalEvent, header: self.header
        });

        originalEvent.stopPropagation();
    }

    /** @internal */
    public _trackByFn(index: number, item: UxTableColumn): string {
        return item && item.id;
    }

    private _onMD(event: MouseEvent) {
      this.trackData = new TrackData(event);
      this.initResizableColumns();
    }

    private initResizableColumns() {
      this.renderer.listen('body', 'mousemove', (event) => {
        if(this.trackData.pressed) {
          let headCell = $(this.trackData.start).parent();
          let nextCell = headCell.next(".ux-table__column");

          let headRow = headCell.parent();
          let headRowWidth = headRow.width(); // 100%

          let headCellFlexBasis = headCell.css("flex-basis").toString();
          headCellFlexBasis = headCellFlexBasis.substr(0, headCellFlexBasis.length-1);

          let nextHeadCellFlexBasis = nextCell.css("flex-basis").toString();
          nextHeadCellFlexBasis = nextHeadCellFlexBasis.substr(0, nextHeadCellFlexBasis.length-1);

          let bothWidthPercent = parseFloat(headCellFlexBasis) + parseFloat(nextHeadCellFlexBasis);
          let bothWidth = headRowWidth * bothWidthPercent / 100;

          let headCellMinWidth = headCell.css("min-width").toString();
          let headCellWidth = this.trackData.startWidth + (event.x - this.trackData.startX);
          if (headCellWidth < this.MIN_WIDTH) {
            headCellWidth = this.MIN_WIDTH;
          }

          let nextHeadNewWidth = bothWidth - headCellWidth;
          if (nextHeadNewWidth < this.MIN_WIDTH) {
            nextHeadNewWidth = this.MIN_WIDTH;
            headCellWidth = bothWidth - nextHeadNewWidth;
          }
          let headCellWidthPercent = (headCellWidth) * 100 / headRowWidth;
          let nextHeadCellWidthPercent = bothWidthPercent - headCellWidthPercent;

          let index = headCell.index() + 1;
          $(`.ux-table__column:nth-of-type(${index})`).css({
            'flex': `0 1 ${headCellWidthPercent}%`,
            'min-width': `${headCellWidthPercent}%`
          });

          $(`.ux-table__column:nth-of-type(${index+1})`).css({
            'flex': `0 1 ${nextHeadCellWidthPercent}%`,
            'min-width': `${nextHeadCellWidthPercent}%`
          });
        }
      });
      this.renderer.listen('body', 'mouseup', (event) => {
        if(this.trackData.pressed) {
          this.trackData.pressed = false;
        }
      });
    }
}

class TrackData {
  start: EventTarget;
  pressed: boolean;
  startX: number;
  startWidth: number;

  constructor(event: MouseEvent) {
    this.start = event.target;
    this.pressed = true;
    this.startX = event.x;
    this.startWidth = $(this.start).parent().width();
  }
}
