import {NgModule} from "@angular/core";
import {SharedModule} from "../../../shared/shared.module";
import {UxFieldSuggestionPopupComponent} from "./field-suggestion-popup.component";
import {UxScrollModule} from "../../scroll/scroll.module";
import {UxPopoverModule} from "../../popover/popover.module";

/** Component for internal usage. */
@NgModule({
    imports: [
        SharedModule,
        UxScrollModule,
        UxPopoverModule
    ],
    exports: [UxFieldSuggestionPopupComponent],
    declarations: [UxFieldSuggestionPopupComponent]
})
export class UxFieldSuggestionPopupModule {
}
