<ux-tab-panel-header #innerHeader
                     *ngIf="!externalHeader"
                     [ngClass]="headerStyleClass"
                     [tabs]="_tabs"
                     [itemTemplate]="headerItemTemplate"
                     (onChange)="_handleChange($event)"
></ux-tab-panel-header>
<div class="ux-tab-panel__content">
    <ng-content></ng-content>
</div>

