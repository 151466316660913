import {AfterViewInit, Component, OnDestroy} from "@angular/core";
import {
  UxBreadcrumbItemModel,
  UxBreadcrumbsClickEvent
} from "../../../../ux-lib/components/breadcrumbs/breadcrumbs.component";
import {ActivatedRoute, Router} from "@angular/router";
import {Subscription} from "rxjs";
import {ResultsService} from "../results.service";
import {TestResultEntity} from "../../../common/models/entity/test-result-entity.model";
import {CsvToJsonService} from "../../../common/services/csv-to-json.service";
import {UxTable, UxTableBody, UxTableHeader, UxTableRow} from "../../../../ux-lib/components/table/table.model";
import {UxTableHeaderModel} from "../../../../ux-lib/components/table/header/table-header.model";
import {Progress} from "../../../common/models/progress.model";


@Component({
  selector: "sq-results-report-pvqa",
  templateUrl: "results-report-pvqa.component.html",
  host: {"[class.sq-results-report-pvqa]": "true"}
})
export class ResultsReportPvqaComponent implements AfterViewInit, OnDestroy {

  _breadcrumbs: UxBreadcrumbItemModel[] = [
    {label: "All reports", url: "/results/list"}
  ];

  _tableHeaderModel: UxTableHeaderModel = {
    title: "View PVQA Report"
  };

  _progressStatus: Progress = 'loading';
  _model: UxTable;

  private routeSubscription: Subscription;
  private resultSubscription: Subscription;
  private parseSubscription: Subscription;

  private resultID: string;
  private resultData: TestResultEntity;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private resultsService: ResultsService,
              private csv2json: CsvToJsonService) {
  }

  ngAfterViewInit(): void {
    this.routeSubscription && this.routeSubscription.unsubscribe();
    this.routeSubscription = this.route.params.subscribe(params => {
      this.resultID = params.id;
      this.updateReport();
    });
  }

  ngOnDestroy(): void {
    this.routeSubscription && this.routeSubscription.unsubscribe();
    this.resultSubscription && this.resultSubscription.unsubscribe();
    this.parseSubscription && this.parseSubscription.unsubscribe();
  }

  private updateReport() {
    this.resultSubscription && this.resultSubscription.unsubscribe();
    this.resultSubscription = this.resultsService.getEntityById(this.resultID)
      .subscribe((value: TestResultEntity) => {
        if (value === undefined) {
          this._progressStatus = 'no_data';
        } else {
          this.resultData = value;
          this._progressStatus = this.parsePvqaReport(this.resultData.report_pvqa);
        }
      });
  }

  private parsePvqaReport(value: string): Progress {
    if (value !== undefined && value !== null && value.length > 0) {
      this.parseSubscription && this.parseSubscription.unsubscribe();
      this.parseSubscription = this.csv2json.csv2json(value)
        .subscribe(jsonData => {
        this._model = this.updateTableModel(jsonData);
        this._progressStatus = this._model ? 'loaded' : 'no_data';
      });
      return 'loading';
    }
    return 'no_data';
  }

  private updateTableModel(jsonData: any): UxTable  {
    let model: UxTable;
    if (Array.isArray(jsonData)) {
      if (jsonData.length > 0) {
        let item = jsonData[0];
        if (Object.keys(item).length > 0) {
          model = {
            header: this.objectFieldsToTableHeader(item),
            body: this.jsonToTableBody(jsonData),
            emptyTableContent: "No reports data found"
          };
        }
      }
    }
    return model;
  }

  private objectFieldsToTableHeader(value: Object): UxTableHeader {
    let header: UxTableHeader;
    Object.keys(value).forEach(key => {
      if (header === undefined) {
        header = {
          rows: [
            {
              styleClass: "_header",
              columns: []
            }
          ]
        }
      }

      header.rows[0].columns.push({
        value: (key.startsWith('field') ? "" : key),
        styleClass: "_header",
        sort: true,
        resizable: false
      });
    });
    if (header) {
      header.rows[0].columns[header.rows[0].columns.length-1].resizable = false;
    }
    return header;
  }

  private jsonToTableBody(jsonObject: Object[]): UxTableBody {
    let body: UxTableBody;
    body = {
      rows: []
    }
    jsonObject.forEach(item => {
      body.rows.push(this.objectToTableRow(item));
    });
    return body;
  }

  private objectToTableRow(value: Object): UxTableRow {
    let row: UxTableRow;
    row = {
      columns: []
    };
    Object.keys(value).forEach(key => {
      row.columns.push({
        value: value[key]
      });
    });
    return row;
  }

  _onBreadcrumbsClick(event: UxBreadcrumbsClickEvent) {
    this.router.navigate([event.item.url]);
  }
}
