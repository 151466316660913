import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {SharedModule} from "../../../shared/shared.module";
import {UxCheckboxFieldModule} from "../checkbox/checkbox-field.module";
import {UxCheckboxGroupFieldComponent} from "./checkbox-group-field.component";

@NgModule({
    imports: [SharedModule, CommonModule, UxCheckboxFieldModule],
    exports: [UxCheckboxGroupFieldComponent],
    declarations: [UxCheckboxGroupFieldComponent]
})
export class UxCheckboxGroupFieldModule {
}