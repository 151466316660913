<ux-label class="ux-multiple-text-field-popover-content__label"
          [text]="newValueText">
    <ux-text-field #focusedElement
                   class="ux-multiple-text-field-popover-content__field"
                   [(value)]="_value"
    ></ux-text-field>
</ux-label>

<ux-toolbar class="ux-multiple-text-field-popover-content__toolbar">
    <ux-toolbar-left>
        <ux-button class="ux-multiple-text-field-popover-content__button _save"
                   [text]="saveButtonText"
                   [kind]="'primary'"
                   (onClick)="_onSaveClick()"
        ></ux-button>
        <ux-button class="ux-multiple-text-field-popover-content__button _cancel"
                   [text]="cancelButtonText"
                   (onClick)="_onCancelClick()"
        ></ux-button>
    </ux-toolbar-left>
</ux-toolbar>
