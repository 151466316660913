<div class="sq-tests-full-list__wrapper">
  <div class="sq-tests-full-list__header">
    <ux-toolbar>
      <ux-toolbar-left>
        <ux-search-field [(value)]="_testSearchValue"
                         (valueChange)="_onSearchValueChange($event)"
                         [placeholder]="'Search'"
                         [inputName]="'testSearch'"
                         [fillParentWidth]="false"
                         [searchMinSymbols]="0"
                         [searchDelay]="500"
        >
        </ux-search-field>
      </ux-toolbar-left>
      <ux-toolbar-right>
        <ux-button [text]="'Add New Folder...'"
                   [icon]="'add-folder'"
                   [type]="'button'"
                   [kind]="'default'"
                   [disabled]="false"
                   [locked]="false"
                   (tap)="_onAddNewFolderClicked(undefined)"
        >
        </ux-button>
        <ux-button [text]="'Add New Test...'"
                   [icon]="'add-task'"
                   [type]="'button'"
                   [kind]="'default'"
                   [disabled]="false"
                   [locked]="false"
                   (tap)="_onAddNewClicked(undefined)"
        >
        </ux-button>
      </ux-toolbar-right>
    </ux-toolbar>
  </div>
  <div class="sq-tests-full-list__content">
    <sq-tests-card-list [selectAllEnabled]="false"
                        [selectItemEnabled]="false"
                        [loadStatus]="_pageLoadingStatus"
                        [cardLoadStatus]="_testsStatisticsLoading"
                        [entities]="_tests"
                        [filteredEntities]="_filteredTests"
                        [entitySummaryVisualStatistics]="_testsSummaryVisualStatistics"
                        [pagingEnabled]="true"
                        [statisticsEnabled]="true"
                        [totalCount]="pagingConfig.totalCount"
                        [pageSize]="pagingConfig.pageSize"
                        [pageSizeOptions]="pagingConfig.pageSizeOptions"
                        [currentPage]="pagingConfig.currentPage"
                        (onEditCard)="_onEditCard($event)"
                        (onDeleteCard)="_onDeleteCard($event)"
                        (onChartCard)="_onChartCard($event)"
                        (onExpandFolderCard)="_onExpandFolderCard($event)"
                        (onAddFolder)="_onAddNewFolderClicked($event)"
                        (onAddItem)="_onAddNewClicked($event)"
                        (onPageChange)="_onPageChange($event)"
                        (onPageSizeChange)="_onPageSizeChange($event)"
                        (onProbeStatistics)="_onProbeStatistics($event)"
    >
    </sq-tests-card-list>
  </div>
</div>

<sq-test-edit #testEditPopup
              (onCreateComplete)="_onTestCreateComplete($event)"
              (onEditComplete)="_onTestEditComplete($event)"
              (onCancelled)="_onTestCancel()"
>
</sq-test-edit>

<sq-test-folder-edit #testFolderEditPopup
                     (onCreateComplete)="_onTestCreateComplete($event)"
                     (onEditComplete)="_onTestEditComplete($event)"
                     (onCancelled)="_onTestCancel()"
                     (onClose)="_onFolderPopupClose($event)">
</sq-test-folder-edit>

<sq-confirm-popup #testDeleteConfirmPopup
                 (onCancelled)="_onTestCancel()"
                 (onAccepted)="_onTestDelete($event)"
                 [acceptCaption]="'Delete'"
                 [cancelCaption]="'Cancel'"
                 [title]="'Delete test'"
                 [message]="'Are you really want to delete ' + (_testDeleteEntity?.command === 'folder' ? 'folder': 'device') +' \'' + _testDeleteEntity?.name + '\' ?'"
>
</sq-confirm-popup>

