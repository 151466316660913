/**
 * Decorator for class properties.
 *
 */
export class UxPropertyDecoratorBuilder<T> {
    private beforeValueSetFn: (newValue: T, oldValue: T, className: string, propertyName: string, target: any) => T;
    private afterValueSetFn: (newValue: T, oldValue: T, className: string, propertyName: string, target: any) => void;

    private beforeValueChangeFn: (newValue: T, oldValue: T, className: string, propertyName: string, target: any) => T;
    private afterValueChangeFn: (newValue: T, oldValue: T, className: string, propertyName: string, target: any) => void;

    private nullOrUndefinedValue: T;
    private decoratorName: string = "UxPropertyDecorator";
    private debug: boolean = false;

    constructor() {
    }


    public valueComparatorFn(newValue: T, oldValue: T): boolean {
        return newValue === oldValue;
    }

    /**
     *
     * @param beforeValueSetFn - function that will be called before set the property value
     * @returns {UxPropertyDecoratorBuilder}
     */
    public setBeforeValueSet(beforeValueSetFn: (newValue: T, oldValue: T, className: string, propertyName: string, target: any) => T) {
        this.beforeValueSetFn = beforeValueSetFn;
        return this;
    }

    /**
     * @param afterValueSetFn - function that will be called after set the property value
     * @returns {UxPropertyDecoratorBuilder}
     */
    public setAfterValueSet(afterValueSetFn: (newValue: T, oldValue: T, className: string, propertyName: string, target: any) => void) {
        this.afterValueSetFn = afterValueSetFn;
        return this;
    }

    /**
     *
     * @param beforeValueChangeFn - function that will be called before set the new property value in case newValue !== oldValue
     * @returns {UxPropertyDecoratorBuilder}
     */
    public setBeforeValueChange(beforeValueChangeFn: (newValue: T, oldValue: T, className: string, propertyName: string, target: any) => T) {
        this.beforeValueChangeFn = beforeValueChangeFn;
        return this;
    }

    /**
     * @param afterValueChangeFn - function that will be called after set the new property value in case newValue !== oldValue
     * @returns {UxPropertyDecoratorBuilder}
     */
    public setAfterValueChange(afterValueChangeFn: (newValue: T, oldValue: T, className: string, propertyName: string, target: any) => void) {
        this.afterValueChangeFn = afterValueChangeFn;
        return this;
    }

    /**
     * @param valueComparatorFn - function that will be used to compare new and old value. Should return true value if values are equals
     * @returns {UxPropertyDecoratorBuilder}
     */
    public setValueComparator(valueComparatorFn: (newValue: T, oldValue: T) => boolean) {
        this.valueComparatorFn = valueComparatorFn;
        return this;
    }

    /**
     *
     * @param nullOrUndefinedValue - object property value that will be returned if current value == null | undefined
     * @returns {UxPropertyDecoratorBuilder}
     */
    public setNullOrUndefinedValue(nullOrUndefinedValue: T) {
        this.nullOrUndefinedValue = nullOrUndefinedValue;
        return this;
    }

    /**
     *
     * @param debug - true for debugging
     * @returns {UxPropertyDecoratorBuilder}
     */
    public setDebug(debug = false) {
        this.debug = debug;
        return this;
    }

    /**
     * @param decoratorName - decorator name for debugging
     * @returns {UxPropertyDecoratorBuilder}
     */
    public setDecoratorName(decoratorName = "UxPropertyDecorator") {
        this.decoratorName = decoratorName;
        return this;
    }

    /**
     * Method to return decorator function
     *
     * @returns {(target:any, propertyName:string, descriptor:PropertyDescriptor)=>undefined}
     */
    public getDecorator(): { (target: any, propertyName: string, descriptor?: PropertyDescriptor): void } {
        let self: UxPropertyDecoratorBuilder<T> = this;
        return function (target: any, propertyName: string, descriptor: PropertyDescriptor) {
            let constructor = target.constructor;
            let className: string = constructor.name;
            let makeSetter = function (getOldValueFn: { (context: any): T }, setNewValueFn: { (context: any, newValue: T): void }) {
                return function (newValue: T, oldValue?: T): void {
                    oldValue = arguments.length >= 2 ? oldValue : getOldValueFn(this);
                    if ((newValue === null || newValue === undefined) && self.nullOrUndefinedValue !== undefined) {
                        if (self.debug) {
                            console.log(`${self.decoratorName} SET value: class=${className}, property=${propertyName}, use nullOrUndefinedValue=${self.nullOrUndefinedValue} typeof ${typeof self.nullOrUndefinedValue} instead of newValue=${newValue}`);
                        }
                        newValue = self.nullOrUndefinedValue;
                    }
                    if (self.beforeValueSetFn) {
                        newValue = self.beforeValueSetFn.apply(this, [newValue, oldValue, className, propertyName, target]);
                    }
                    if (!self.valueComparatorFn.apply(this, [newValue, oldValue])) {
                        if (self.beforeValueChangeFn) {
                            newValue = self.beforeValueChangeFn.apply(this, [newValue, oldValue, className, propertyName, target]);
                        }
                        if (!self.valueComparatorFn.apply(this, [newValue, oldValue])) {
                            if (self.debug) {
                                console.log(`${self.decoratorName} SET value: class=${className}, property=${propertyName}, oldValue=${oldValue} typeof ${typeof oldValue}, newValue=${newValue} typeof ${typeof newValue}`);
                            }
                            setNewValueFn(this, newValue);
                            if (self.afterValueChangeFn) {
                                newValue = self.afterValueChangeFn.apply(this, [newValue, oldValue, className, propertyName, target]);
                            }
                        }
                    }
                    if (self.afterValueSetFn) {
                        self.afterValueSetFn.apply(this, [newValue, oldValue, className, propertyName, target]);
                    }
                };
            };
            let makeGetter = function (getValueFn: { (context: any): T }, setInitialValueFn: { (context: any, newValue: T, oldValue?: T): void }): { (): T } {
                return function () {
                    let value = getValueFn(this);
                    if ((value === undefined || value === null) && self.nullOrUndefinedValue !== undefined) { //property is not initialized
                        if (self.debug) {
                            console.log(`${self.decoratorName} SET initial value: class=${className}, property=${propertyName}, initialValue=${self.nullOrUndefinedValue} typeof ${typeof self.nullOrUndefinedValue}`);
                        }
                        setInitialValueFn(this, self.nullOrUndefinedValue, value);
                        value = getValueFn(this);
                        // if ((value === undefined || value === null)) {
                        //     throw new Error(`${self.decoratorName}: can't set initial value: class=${className}, property=${propertyName}, value=${value} typeof ${typeof value}`);
                        // }
                    }
                    if (self.debug) {
                        console.log(`${self.decoratorName} GET value: class=${className}, property=${propertyName}, value=${value} typeof ${typeof value}`);
                    }
                    return value;
                };
            };
            descriptor = descriptor || Object.getOwnPropertyDescriptor(target, propertyName);
            if (!descriptor) {
                let propertyValueKey = `__${self.decoratorName}_${propertyName}_value`;
                let getter = function (context: any) {
                    if (context) {
                        return context[propertyValueKey];
                    }
                };
                let setter = function (context: any, newValue: T, oldValue?: T) {
                    if (context) {
                        context[propertyValueKey] = newValue;
                    }
                };
                descriptor = {
                    configurable: true,
                    enumerable: true,
                    get: makeGetter(getter, setter),
                    set: makeSetter(getter, setter)
                }
            } else {
                let set = descriptor.set;
                let get = descriptor.get;
                if (!set) {
                    throw new Error(`Can't decorate property without setter: class=${className}, property=${propertyName}`);
                }
                descriptor.set = makeSetter(
                    (context: any) => get.apply(context),
                    (context: any, newValue: T) => set.apply(context, [newValue])
                );
                if (!get) {
                    throw new Error(`Can't decorate property without getter: class=${className}, property=${propertyName}`);
                }
                descriptor.get = makeGetter(
                    (context: any) => get.apply(context),
                    (context: any, newValue: T, oldValue?: T) => set.apply(context, [newValue, oldValue])
                );
            }
            Object.defineProperty(target, propertyName, descriptor);
        }
    }
}
