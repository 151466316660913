import {NgModule} from "@angular/core";
import {SharedModule} from "../../../shared/shared.module";
import {UxNavigationButtonComponent} from "./navigation-button.component";

@NgModule({
    imports: [SharedModule],
    exports: [UxNavigationButtonComponent],
    declarations: [UxNavigationButtonComponent,]
})
export class UxNavigationButtonModule {
}