import {UpdateEntityModel} from "./update-entity.model";
import {HierarchyEntityModel} from "./hierarchy-entity.model";

export class DeviceEntity extends HierarchyEntityModel {
  public instance?: string;
  public number?: string;
  public type?: string;
  public last_timestamp?: number;
  public attributes?: string;
  public audio_id: number;


  public constructor(parent_id?: number|string, id?: number|string) {
    super(parent_id, id);
  }

  public copyFrom(value: DeviceEntity) {
    super.copyFrom(value);
    this.parent_id = value.parent_id;
    this.instance = value.instance;
    this.number = value.number;
    this.type = value.type;
    this.last_timestamp = value.last_timestamp;
    this.attributes = value.attributes;
    this.audio_id = value.audio_id;
  }
}

export interface UpdateDeviceEntity extends UpdateEntityModel<DeviceEntity> {
}
