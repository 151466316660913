<sq-overlay-popup [title]="'Audio file details'"
                  [visible]="_visible"
                  [contentTemplate]="contentTemplate"
                  [controlsTemplate]="controlsTemplate"
                  [statusEnable]="_operationErrorText"
                  [statusTemplate]="statusTemplate"
                  (onClose)="_onCloseClick()"
>

</sq-overlay-popup>


<ng-template #contentTemplate>
  <form novalidate
        #audioEditForm
        class="sq-audio-edit__form"
        [formGroup]="_formGroup">
    <ux-label [text]="'Name'"
              [required]="true"
    >
      <ux-text-field #audioName
                     formControlName="audioName"
                     [(value)]="_audioModel.name"
                     [placeholder]="'Enter folder name'"
                     [uxTooltip]="!audioName.focused && !_formGroup.controls.audioName.untouched && _getErrorText('audioName')"
                     uxTooltipAppendTo="target"
                     [ngClass]="{
                          '_error': !audioName.focused && !_formGroup.controls.audioName.untouched && _getErrorText('audioName')
                       }"
      >
      </ux-text-field>
    </ux-label>
  </form>
</ng-template>

<ng-template #controlsTemplate>
  <ux-button (tap)="_onEditConfirm()"
             [text]="'Update'"
             [kind]="'primary'"
             [disabled]="!_formGroup.valid"
  >
  </ux-button>
</ng-template>


<ng-template #statusTemplate>
  <ux-label [text]="_operationErrorText"
            [ngClass]="'_error'"
  >
  </ux-label>
</ng-template>
