import {AfterViewInit, Directive, ElementRef, EventEmitter, Input, NgZone, Output} from "@angular/core";
import {UxPropertyConverter} from "../../common/decorator/ux-property-converter";


export interface UxFadeToggleEvent {
    element: HTMLElement;
    timePassedPercentage?: number;
    elementOpacity: number;
    fadeToggle: boolean;
}


@Directive({
    selector: "[uxFadeToggle]"
})
export class UxFadeToggleDirective implements AfterViewInit {
    public element: HTMLElement;

    private toggled: boolean = true;
    private toggleStateOnAnimationStart: boolean = true;
    private isAnimating: boolean = false;
    private requestAnimationFrameId: number;
    private direction: string = "out";
    private opacity: number;
    private currentStyleOpacity: number;

    @UxPropertyConverter("number", 200)
    @Input()
    public uxFadeToggleDuration: number = 200;

    @Input("uxFadeToggle")
    public set fadeToggle(value: boolean) {
        let self = this;

        self.toggled = value;

        if (!self.isAnimating && self.element) {
            self.runAnimation();
        }
    }

    public get fadeToggle(): boolean {
        return this.toggled;
    }

    @Output() public onFadeStart = new EventEmitter<UxFadeToggleEvent>();
    @Output() public onFadeEnd = new EventEmitter<UxFadeToggleEvent>();
    @Output() public onFadeTick = new EventEmitter<UxFadeToggleEvent>();

    constructor(private elementRef: ElementRef,
                private zone: NgZone) {
    }

    ngAfterViewInit(): void {
        let self = this;

        self.element = self.elementRef.nativeElement;

        if (!self.toggled) {
            self.updateElOpacity();
            self.element.style.display = "none";
        }
    }

    private removeDisplayStyle(): void {
        let self = this,
            styles = self.element.getAttribute("style");

        if (styles && styles.indexOf("display") >= 0) {
            let result = styles.replace(/display:(.+?;)/i, "");
            self.element.setAttribute("style", result);
        }
    }

    private runAnimation() {
        let self = this;

        self.removeDisplayStyle();

        self.toggleStateOnAnimationStart = self.toggled;
        self.isAnimating = true;
        self.direction = self.toggled ? "in" : "out";
        self.updateElOpacity();

        self.onFadeStart.emit({
            element: self.element,
            elementOpacity: +getComputedStyle(self.element).opacity,
            fadeToggle: self.toggled
        });

        self.zone.runOutsideAngular(() => {
            self.animate();
        });
    }

    private animate(): void {
        let self = this,
            start;

        self.requestAnimationFrameId = requestAnimationFrame(function animate(time): void {
            if (!start) {
                start = time;
            }

            let timePassed = time - start;


            if (timePassed > self.uxFadeToggleDuration) {
                timePassed = self.uxFadeToggleDuration;
            }

            self.tick(timePassed);

            if (timePassed < self.uxFadeToggleDuration &&
                self.direction === "out" && self.currentStyleOpacity > 0 ||
                self.direction === "in" && self.currentStyleOpacity < 1
            ) {
                self.requestAnimationFrameId = requestAnimationFrame(animate);
            }
        });
    }

    private tick(timePassed: number): void {
        let self = this,
            timePassedPercentage: number = Math.ceil(Math.abs(timePassed) / self.uxFadeToggleDuration * 100),
            currentTimePassedPercentage = self.direction === "out" ? 100 - timePassedPercentage : timePassedPercentage,
            elementOpacity = currentTimePassedPercentage / 100;

        self.currentStyleOpacity = elementOpacity;
        self.element.style.opacity = self.currentStyleOpacity + "";

        self.onFadeTick.emit({
            element: self.element,
            elementOpacity,
            timePassedPercentage,
            fadeToggle: self.toggled
        });

        if (timePassedPercentage === 100) {
            self.onAnimationEnd();
        }
    }

    private onAnimationEnd(): void {

        let self = this;

        window.cancelAnimationFrame(self.requestAnimationFrameId);

        if (self.toggleStateOnAnimationStart !== self.toggled) {
            requestAnimationFrame(self.runAnimation.bind(self));
        } else {

            if (self.toggled) {
                self.removeDisplayStyle();
            } else {
                self.element.style.display = "none";
            }

            self.isAnimating = false;
        }

        this.zone.run(() => {
            self.onFadeEnd.emit({
                element: self.element,
                elementOpacity: 1,
                fadeToggle: self.toggled
            });
        });
    }

    private updateElOpacity(): void {
        this.opacity = +getComputedStyle(this.element).opacity;
    }

}
