<ul class="ux-radio-group-field__items">
    <li class="ux-radio-group-field__item" *ngFor="let item of items; let i=index; trackBy: _trackByFn">
        <ux-radio-field [label]="item[itemLabelKey]"
                        [name]="item.name"
                        [index]="i"
                        [value]="value && (value[itemLabelKey] === item[itemLabelKey])"
                        [disabled]="disabled || item.disabled"
                        [item]="item"
                        [template]="template"
                        (onValueChange)="_onValueSwitch($event, item)">
        </ux-radio-field>
    </li>
</ul>
