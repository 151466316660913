import {NgModule} from "@angular/core";
import {SharedModule} from "../../shared/shared.module";
import {UxPopoverComponent} from "./popover.component";
import {UxFadeToggleModule} from "../fade-toggle/fade-toggle.module";
import {UxCommonModule} from "../common/common.module";
import {UxSlideToggleModule} from "../slide-toggle/slide-toggle.module";

const COMPONENTS = [
    UxPopoverComponent
];

@NgModule({
    imports: [
        SharedModule,
        UxFadeToggleModule,
        UxSlideToggleModule,
        UxCommonModule
    ],
    exports: COMPONENTS,
    declarations: COMPONENTS
})
export class UxPopoverModule {
}
