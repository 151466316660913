
import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {SharedModule} from "../../components/shared.module";
import {AudioRoutingModule} from "./audio-routing.module";
import {SevanaQualtestAuthGuard} from "../../sevana-qualtest-auth.guard";
import {AudioService} from "./audio.service";
import {AudioFullListComponent} from "./components/audio-full-list.component";
import {UxToolbarModule} from "../../../ux-lib/components/toolbar/toolbar.module";
import {UxAttachmentModule} from "../../../ux-lib/components/attachment/attachment.module";
import {AudioEditComponent} from "./components/audio-edit.component";
import {AudioCardListComponent} from "./components/audio-card-list.component";
import {UxRoundDotsLoaderModule} from "../../../ux-lib/components/loader/round-dots-loader/round-dots-loader.module";
import {UxCardTreeModule} from "../../../ux-lib/components/card-tree/card-tree.module";
import {UxPagingModule} from "../../../ux-lib/components/paging/paging.module";
import {PipesModule} from "../../common/pipes/pipes.module";
import {OverlayPopupModule} from "../../components/overlay-popup/overlay-popup.module";
import {UxLabelModule} from "../../../ux-lib/components/label/label.module";
import {UxTextFieldModule} from "../../../ux-lib/components/fields/text/text-field.module";
import {UxButtonModule} from "../../../ux-lib/components/button/button.module";
import {UxTooltipModule} from "../../../ux-lib/components/tooltip/tooltip.module";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    ReactiveFormsModule,
    AudioRoutingModule,
    UxToolbarModule,
    UxAttachmentModule,
    UxRoundDotsLoaderModule,
    UxCardTreeModule,
    UxPagingModule,
    PipesModule,
    OverlayPopupModule,
    UxLabelModule,
    UxTextFieldModule,
    UxButtonModule,
    UxTooltipModule
  ],
  declarations: [
    AudioFullListComponent,
    AudioCardListComponent,
    AudioEditComponent
  ],
  entryComponents: [
    AudioFullListComponent
  ],
  exports: [
  ],
  providers: [
    SevanaQualtestAuthGuard,
    AudioService
  ]
})
export class AudioModule {
}
