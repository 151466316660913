<div #inner
     class="ux-tab-panel-header__inner"
     [ngStyle]="{transform: 'translate3d(' + _tabsPanOffset + 'px, 0, 0)'}">

    <div #tabHeaderItem
         *ngFor="let tab of _tabs; let i = index"
         class="ux-tab-panel-header__item taTab {{tab.headerStyleClass}}"
         [ngClass]="{'_selected': tab.selected, '_disabled': tab.disabled}"
         title="{{tab.tooltip}}"
         (tap)="_clickTab($event,tab,i)">

        <div class="ux-tab-panel-header__item-inner">

            <ng-template [ngTemplateOutlet]="itemTemplate || defaultItemTemplate"
                         [ngTemplateOutletContext]="{item: tab}">
            </ng-template>

        </div>
    </div>
</div>


<ng-template #defaultItemTemplate let-item="item">
    <span class="ux-tab-panel-header__item-title">{{item.title}}</span>
</ng-template>
