import { EventEmitter, Input, Output, Directive } from "@angular/core";
import {UxPropertyHandler} from "../../common/decorator/ux-property-handler";
import {UxPropertyConverter} from "../../common/decorator/ux-property-converter";

const STYLE_MODIFIERS = {
  noText: "_no-text",
  disabled: "_disabled",
  locked: "_locked"
};

@Directive()
export class UxAbstractButtonComponent {
  /** @internal */
  public _styleClasses = {};

  @UxPropertyHandler({
    beforeChange: beforeChangeText
  })
  @Input()
  public text: string = "";

  @UxPropertyConverter("boolean", false)
  @UxPropertyHandler({
    beforeChange: beforeChangeDisabled
  })
  @Input()
  public disabled: boolean;

  @UxPropertyConverter("boolean", false)
  @UxPropertyHandler({
    beforeChange: beforeChangeLocked
  })
  @Input()
  public locked: boolean;

  @Input()
  public mobile: boolean = false;


  @Output()
  public onClick: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();


  /** @internal */
  public _onClick(event: MouseEvent): void {
    if (this.disabled || this.locked) {
      event.stopPropagation();
      return;
    }
    this.onClick.emit(event);
  }

  /** @internal */
  public _setStyleClass(styleClass: string, addOrRemove: boolean = true): void {
    let self = this;
    self._styleClasses[styleClass] = addOrRemove;
    //reassign reference for angular change detection
    self._styleClasses = Object.assign({}, self._styleClasses);
  }
}

/*Helpers*/
export function beforeChangeText(newValue: string): void {
  this._setStyleClass(STYLE_MODIFIERS.noText, !newValue);
}

export function beforeChangeDisabled(newValue: boolean): void {
  this._setStyleClass(STYLE_MODIFIERS.disabled, newValue);
}

export function beforeChangeLocked(newValue: boolean): void {
  this._setStyleClass(STYLE_MODIFIERS.locked, newValue);
}
