import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {SharedModule} from "../shared.module";
import {UxCardTreeModule} from "../../../ux-lib/components/card-tree/card-tree.module";
import {CardListComponent} from "./card-list.component";

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    UxCardTreeModule
  ],
  declarations: [
    CardListComponent
  ],
  exports: [
    CardListComponent
  ],
  providers: [
  ]
})
export class CardListModule {
}
