import * as moment from "moment";
import {uxThrottle} from "../../../shared/dom/dom-helper";
import {UxRangeDateFieldModel} from "./range/range-date-field.model";

const STYLE_MODIFIERS = {
    current: "_current",
    clicked: "_clicked",
    disabled: "_disabled",
    calendarDay: "calendar__day",
};

const initMomentLocal = moment()["_locale"];

export class MomentCalendar {
    private static defaultLocaleWeekdays: string[] = initMomentLocal.weekdays();
    private static defaultLocaleWeekdaysShort: string[] = initMomentLocal.weekdaysShort();
    private static defaultLocaleMonths: string[] = initMomentLocal.months();
    private static defaultLocaleMonthsShort: string[] = initMomentLocal.monthsShort();

    private eventType: string;
    private yearsElWrapper: Element;
    private monthsElWrapper: Element;
    private calendarClickBinded: any;
    private monthsEl: Element;
    private clickedDays: string[] = [];
    private currentMonthNumber: number;
    private currentYearNumber: number;
    private initYearNumber: number;
    private initMonthNumber: number;
    private initDayNumber: number;
    private options: Options;
    private el: Element;
    private defaults: {};
    private initOptions: {};
    private yearsEl: Element;
    private calendarEl: HTMLElement;
    private currentDayElement: HTMLElement;
    private calendarPanelDatesElement: HTMLElement;
    private calendarWeekHeaderElement: HTMLElement;
    private dayElements: HTMLElement[];
    private calendarPanelMonthElement: HTMLElement;
    private calendarPanelYearElement: HTMLElement;
    private calendarPanelCurrentInfoElement: HTMLElement;
    private calendarPanelCurrentInfoFromElement: HTMLElement;
    private calendarPanelCurrentInfoText: string;
    private calendarPanelCurrentInfoFromText: string;
    private calendarRangeElements: Array<any>;
    private calendarMonthElement: HTMLElement;
    private calendarMonthElementHeight: number;
    private dayElementHeight: number;
    private beginAvailableDate: Date;
    private endAvailableDate: Date;

    constructor(element: HTMLElement, initOptions: Options = {}) {
        let self = this;
        self.createMainTemplate(element);

        self.eventType = ("ontouchend" in document.documentElement) ? "touchend" : "mousedown";
        self.calendarEl = element;
        self.el = element.querySelector(".calendar-body");
        self.initOptions = initOptions;
        self.defaults = {};
        self.options = Object.assign(self.defaults, self.initOptions);

        self.updateLocal();

        self.initDayNumber = self.getInitDayNumber();
        self.initMonthNumber = self.currentMonthNumber = self.getInitMonthNumber();
        self.initYearNumber = self.currentYearNumber = self.getInitYearNumber();

        self.init();
    }

    public refresh(newOptions: Options = {}, customRefresh: boolean = false): void {
        let self = this;
        self.options = Object.assign(self.options, newOptions);
        self.beginAvailableDate = newOptions.beginAvailableDate || null;
        self.endAvailableDate = newOptions.endAvailableDate || null;
        self.currentMonthNumber = typeof newOptions.month === "number" ? newOptions.month : self.currentMonthNumber;
        self.currentYearNumber = newOptions.year || self.currentYearNumber;

        self.appendToMainContainer(self.createMonth(self.createCurrentMonthsModel()), customRefresh);

        self.hide(self.yearsElWrapper);
        self.hide(self.monthsElWrapper);
        self.show(self.el);
        self.setClickedDays([]);

        if (self.options.range) {
            self.updateRangeInfo(customRefresh);
        }
    }

    private updateRangeInfo(customRefresh: boolean = false): void {
        if (!this.options.range.from && !this.options.range.to) {
            this.updateCurrentInfo(this.options.range.selectDateRangeText || "Select date range", !!this.options.range.selectDateRangeText);
        }

        if (this.options.range.from && this.dayElements) {
            this.calendarRangeElements = this.dayElements.filter((dayElement) => dayElement.classList.contains("_range"));

            if (this.calendarRangeElements.length) {
                let targetData = this.calendarRangeElements[0].dataset;

                this.calendarPanelCurrentInfoFromElement.innerHTML = `${targetData.dayOfWeek.slice(0, 3)}, ${targetData.monthName.slice(0, 3)} ${targetData.dayNumber} -`;

                if (+this.options.range.from === +this.options.range.to) {
                    this.updateCurrentInfo(`${targetData.dayOfWeek.slice(0, 3)}, ${targetData.monthName.slice(0, 3)} ${targetData.dayNumber}`, true);
                }

                if (this.calendarRangeElements[1]) {
                    let currentTargetData = this.calendarRangeElements[1].dataset;

                    this.updateCurrentInfo(`${currentTargetData.dayOfWeek.slice(0, 3)}, ${currentTargetData.monthName.slice(0, 3)}, ${currentTargetData.dayNumber}`, true);
                }
            } else if (customRefresh) {
                if (this.calendarPanelCurrentInfoFromText) {
                    this.calendarPanelCurrentInfoFromElement.innerHTML = this.calendarPanelCurrentInfoFromText;
                }

                if (this.calendarPanelCurrentInfoText) {
                    this.updateCurrentInfo(this.calendarPanelCurrentInfoText, true);
                }
            }
        }

        if (this.options.range.to && !this.options.range.from && this.dayElements) {
            let currentTarget = this.dayElements.filter((dayElement) => dayElement.classList.contains("_range"));

            if (currentTarget.length) {
                let currentTargetData = currentTarget[0].dataset;
                this.updateCurrentInfo(`${currentTargetData.dayOfWeek.slice(0, 3)}, ${currentTargetData.monthName.slice(0, 3)}, ${currentTargetData.dayNumber}`, true);
            }
        }
    }

    private updateCurrentInfo(html: string, select: boolean): void {
        if (this.calendarPanelCurrentInfoElement) {
            this.calendarPanelCurrentInfoElement.innerHTML = html;

            if (html && html.length > 0 && select) {
                this.calendarPanelCurrentInfoElement.classList.remove("_no-select");
            } else {
                this.calendarPanelCurrentInfoElement.classList.add("_no-select");
            }
        }
    }

    private updateCurrentInfoToCurrentDay(): void {
        if (this.currentDayElement) {
            let targetData = this.currentDayElement["dataset"];

            if (targetData && targetData.dayOfWeek) {
                this.updateCurrentInfo(`
                    ${targetData.dayOfWeek.slice(0, 3)}, ${targetData.monthName.slice(0, 3)} ${targetData.dayNumber}
                `, true);
            }
        }
    }

    private createMainTemplate(element: HTMLElement): void {
        element.insertAdjacentHTML("afterbegin", `
            <div class="calendar-body">
            </div>
        `);
    }

    private init(): void {
        let self = this;
        self.bindEvents();
    }

    private makeMonthsHtml(): void {
        let i = 0;
        MomentCalendar.defaultLocaleMonthsShort.forEach((month) => {
            this.monthsEl.insertAdjacentHTML("beforeend", `
                <div class="calendar-months__month" data-month="${i++}">${month}</div>
            `);
        });
    }

    private setCurrentYearClass(): void {
        let years = this.yearsEl.querySelectorAll(".calendar-years__year");
        Array.prototype.forEach.call(years, (year: Element) => {
            let dataYear = +year.getAttribute("data-year");

            if (dataYear === this.currentYearNumber) {
                year.classList.add(STYLE_MODIFIERS.current);
            } else {
                year.classList.remove(STYLE_MODIFIERS.current);
            }
        });
    }

    private setCurrentMonthClass(): void {
        let months = this.monthsEl.querySelectorAll(".calendar-months__month");
        Array.prototype.forEach.call(months, (month: Element) => {
            let dataMonth = +month.getAttribute("data-month");

            if (dataMonth === this.currentMonthNumber) {
                month.classList.add(STYLE_MODIFIERS.current);
            } else {
                month.classList.remove(STYLE_MODIFIERS.current);
            }
        });
    }

    private clearCurrentMonthClass(): void {
        let months = this.monthsEl.querySelectorAll(".calendar-months__month");
        Array.prototype.forEach.call(months, (month: Element) => {
            month.classList.remove(STYLE_MODIFIERS.current);
        });
    }

    private makeYearsHtml(): void {
        let yearsBefore = 100,
            yearsAfter = 100,
            c = 0, k = 0,
            self = this;

        self.yearsEl.insertAdjacentHTML("beforeend", `
            <div class="calendar-years__year" data-year="${self.getCurrentYearNumber()}">${self.getCurrentYearNumber()}</div> 
       `);

        while (k++ < yearsBefore) {
            self.yearsEl.insertAdjacentHTML("afterbegin", `
                <div class="calendar-years__year" data-year="${self.getCurrentYearNumber() - k}">${self.getCurrentYearNumber() - k}</div> 
           `);
        }

        while (c++ < yearsAfter) {
            self.yearsEl.insertAdjacentHTML("beforeend", `
                <div class="calendar-years__year" data-year="${self.getCurrentYearNumber() + c}">${self.getCurrentYearNumber() + c}</div> 
           `);
        }

        this.yearsElWrapper.insertAdjacentHTML("afterbegin", `
            <div class="calendar-years__year-arrow-next">
                <svg viewBox="0 0 1792 1792" class="calendar-years__year-arrow-next-icon">
                    <path class="calendar-years__year-arrow-next-icon-path"
                        d="M1395 1184q0 13-10 23l-50 50q-10 10-23 10t-23-10l-393-393-393 393q-10 10-23 10t-23-10l-50-50q-10-10-10-23t10-23l466-466q10-10 23-10t23 10l466 466q10 10 10 23z"/>
                </svg>
            </div>
            <div class="calendar-years__year-arrow-prev">
                <svg viewBox="0 0 1792 1792" class="calendar-years__year-arrow-prev-icon">
                    <path class="calendar-years__year-arrow-prev-icon-path"
                        d="M1395 1184q0 13-10 23l-50 50q-10 10-23 10t-23-10l-393-393-393 393q-10 10-23 10t-23-10l-50-50q-10-10-10-23t10-23l466-466q10-10 23-10t23 10l466 466q10 10 10 23z"/>
                </svg>
            </div>
        `);
    }

    private bindEvents(): void {
        let self = this;

        self.calendarClickBinded = self.calendarClick.bind(self);

        if (self.eventType === "touchend") {
            self.calendarEl.addEventListener("mousedown", self.calendarClickBinded);
        }

        self.calendarEl.addEventListener("mouseover", self.onMouseOver);
        self.calendarEl.addEventListener("mouseout", self.onMouseOut);

        self.calendarEl.addEventListener(self.eventType, self.calendarClickBinded);
    }

    private onMouseOver = (event): void => {
        if (event.target.classList.contains("calendar__day") && !event.target.classList.contains("_disabled")) {
            let targetData = event.target.dataset;

            if (targetData && targetData.dayOfWeek) {
                this.updateCurrentInfo(`
                    ${targetData.dayOfWeek.slice(0, 3)}, ${targetData.monthName.slice(0, 3)} ${targetData.dayNumber}
                `, true);
            }
        }
    }

    private onMouseOut = (event): void => {
        if (event.target.classList.contains("calendar__day") &&
            !event.target.classList.contains("_disabled")) {

            if (this.currentDayElement && !event.target.classList.contains("_current")) {
                let targetData = this.currentDayElement["dataset"];

                if (targetData && targetData.dayOfWeek) {
                    this.updateCurrentInfo(`
                    ${targetData.dayOfWeek.slice(0, 3)}, ${targetData.monthName.slice(0, 3)} ${targetData.dayNumber}
                `, true);
                }
            }

            if (this.options.range) {
                if (!this.options.range.from && !this.options.range.to) {
                    this.updateCurrentInfo(`${this.options.range.selectDateRangeText || "Select date range"}`, !!this.options.range.selectDateRangeText);
                } else if (this.options.range.from && !this.options.range.to) {
                    this.updateCurrentInfo("", false);
                } else if (this.options.range.from && this.options.range.to) {
                    let targetData = this.calendarRangeElements[1];

                    if (targetData && targetData.dayOfWeek) {
                        this.updateCurrentInfo(`
                    ${targetData.dayOfWeek.slice(0, 3)}, ${targetData.monthName.slice(0, 3)} ${targetData.dayNumber}
                `, true);
                    }
                }
            }
        }
    }

    private updateLocal(): void {

        let local = moment()["_locale"];

        if (!Array.isArray(local.weekdays()) ||
            !Array.isArray(local.weekdaysShort()) ||
            !Array.isArray(local.months()) ||
            !Array.isArray(local.monthsShort())
        ) {
            moment.locale("en");
        }

        MomentCalendar.defaultLocaleWeekdays = moment()["_locale"].weekdays();
        MomentCalendar.defaultLocaleWeekdaysShort = moment()["_locale"].weekdaysShort();
        MomentCalendar.defaultLocaleMonths = moment()["_locale"].months();
        MomentCalendar.defaultLocaleMonthsShort = moment()["_locale"].monthsShort();
    }

    private calendarClick(e: MouseEvent): void {
        let target = <HTMLElement>e.target,
            targetClassList = target.classList,
            date = target.getAttribute("data-date"),
            self = this;

        e.preventDefault();
        e.stopPropagation();

        if (targetClassList.contains(STYLE_MODIFIERS.calendarDay) && typeof self.options.onDayClick === "function") {
            self.onDayClick(target, date);
        }

        if (targetClassList.contains("calendar__panel-month")) {
            if (self.options.range) {
                self.saveInfoPanelData();
            }

            self.show(self.monthsElWrapper);
            self.hide(self.calendarMonthElement);
            self.hide(self.calendarWeekHeaderElement);
            self.hide(self.yearsElWrapper);
            self.hide(self.calendarPanelMonthElement);
            self.setCurrentMonthClass();
        }

        if (targetClassList.contains("calendar__panel-year")) {
            if (self.options.range) {
                self.saveInfoPanelData();
            }

            self.show(self.yearsElWrapper);
            self.hide(self.monthsElWrapper);
            self.hide(self.calendarMonthElement);
            self.hide(self.calendarPanelDatesElement);
            self.hide(self.calendarWeekHeaderElement);
            self.setCurrentYearClass();

            let currentYear: any = this.yearsElWrapper.querySelector(".calendar-years__year._current");

            if (currentYear) {
                currentYear.parentNode.scrollTop = currentYear.offsetTop - currentYear.parentNode.offsetTop - currentYear.offsetHeight * 3 + 23;
            }
        }

        if (targetClassList.contains("calendar-years__year")) {
            self.currentYearNumber = self.options.year = +target.getAttribute("data-year");
            self.calendarPanelYearElement.innerHTML = `${self.currentYearNumber}`;
            self.hide(self.yearsElWrapper);
            self.hide(self.calendarPanelMonthElement);
            self.show(self.monthsElWrapper);
            self.show(self.calendarPanelYearElement);
            self.show(self.calendarPanelDatesElement);
        }

        if (targetClassList.contains("calendar-months__month")) {
            self.hide(self.monthsElWrapper);
            self.show(self.calendarPanelDatesElement);
            self.show(self.calendarWeekHeaderElement);
            self.show(self.el);
            self.currentMonthNumber = self.options.month = +target.getAttribute("data-month");
            self.refresh(self.options, true);
        }

        self.updateCurrentInfoToCurrentDay();
    }

    private isContainClass(classList: DOMTokenList, styles: string[]): boolean {
        for (let i = 0; i <= styles.length; i++) {
            let result = classList.contains(styles[i]);
            if (result) {
                return true;
            }
        }

        return false;
    }

    private saveInfoPanelData(): void {
        this.calendarPanelCurrentInfoText = this.calendarPanelCurrentInfoElement.innerHTML;
        this.calendarPanelCurrentInfoFromText = this.calendarPanelCurrentInfoFromElement.innerHTML;
    }

    private onDayClick(target: HTMLElement, date: string): void {
        let day = target,
            self = this;

        if (!date) {
            return;
        }

        if (!day.classList.contains(STYLE_MODIFIERS.disabled)) {
            if (self.options.clickableOnlyUpcomingDays && !self.isUpcomingDate(date)) {
                return;
            }

            if (day.classList.contains(STYLE_MODIFIERS.clicked)) {
                day.classList.remove(STYLE_MODIFIERS.clicked);
                self.clickedDays.splice(self.clickedDays.indexOf(date), 1);
            } else {
                day.classList.add(STYLE_MODIFIERS.clicked);
                self.clickedDays.push(date);
            }

            let arr = date.split(".");
            self.options.onDayClick(day, new Date(+arr[2], +arr[1] - 1, +arr[0]));
            self.changeDayStyles(date);
        }
    }

    private createCurrentMonthsModel(): Date[] {
        const scrollableMonthsNumber = 10;

        let monthsModel = [];

        for (let i = 0; i < scrollableMonthsNumber; i++) {
            this.goToPrevMonthModel();
        }

        for (let i = 0; i < scrollableMonthsNumber * 2; i++) {
            this.goToNextMonthModel();
            monthsModel.push(...this.createMonthModel());
        }

        for (let i = 0; i < scrollableMonthsNumber; i++) {
            this.goToPrevMonthModel();
        }

        return monthsModel;
    }

    private goToNextMonthModel(): void {
        ++this.currentMonthNumber;

        if (this.getCurrentMoment().get("year") !== this.currentYearNumber) {
            ++this.currentYearNumber;
            this.currentMonthNumber = 0;
        }
    }

    private goToPrevMonthModel(): void {
        --this.currentMonthNumber;

        if (this.getCurrentMoment().get("year") !== this.currentYearNumber) {
            --this.currentYearNumber;
            this.currentMonthNumber = 11;
        }
    }

    private createMonthModel(): Date[] {
        let startOfMonth = this.getCurrentMoment().startOf("month"),
            endOfMonth = this.getCurrentMoment().endOf("month"),
            days = [],
            day = startOfMonth;

        while (day <= endOfMonth) {
            days.push(day.toDate());
            day = day.clone().add(1, "d");
        }

        return days;
    }

    private getCurrentMoment(): moment.Moment {
        return moment().year(this.currentYearNumber).month(this.currentMonthNumber);
    }

    private createMonth(month: Date[]): HTMLElement {
        let self = this,
            monthHtml = self.createMonthHtml(),
            weekHtml = self.createWeekHtml();

        month.forEach((day: Date, index: number) => {
                let momentDay = moment(day),
                    numberOfDayOfMonth = momentDay.format("DD"),
                    dayOfWeek = momentDay.format("dddd"),
                    numberOfDayOfWeek: any = momentDay.format("e"),
                    dataDate = {
                        numberOfDayOfMonth,
                        currentMonthNumber: momentDay.format("M"),
                        currentYearNumber: momentDay.format("YYYY")
                    },
                    modifier;

                // put empty cells in case of start week not in Sunday
                if (!index && +numberOfDayOfWeek) {
                    while (numberOfDayOfWeek--) {
                        weekHtml.appendChild(self.createDayHtml());
                    }
                }

                if (weekHtml.querySelectorAll("." + STYLE_MODIFIERS.calendarDay).length === 7) { // if Sunday of new week

                    if (weekHtml.innerHTML) {
                        monthHtml.appendChild(weekHtml);
                    }

                    weekHtml = self.createWeekHtml();
                }

                if (+numberOfDayOfMonth === self.initDayNumber &&
                    (+dataDate.currentMonthNumber - 1) === self.initMonthNumber &&
                    +dataDate.currentYearNumber === self.initYearNumber &&
                    self.options.setClassToDate === undefined) {
                    modifier = STYLE_MODIFIERS.current;
                } else if (+dataDate.currentYearNumber < self.initYearNumber ||
                    (+dataDate.currentMonthNumber - 1) < self.initMonthNumber ||
                    ((+dataDate.currentMonthNumber - 1) === self.initMonthNumber &&
                        +numberOfDayOfMonth < self.initDayNumber)
                ) {
                    modifier = "_passed";
                } else {
                    modifier = null;
                }

                weekHtml.appendChild(self.createDayHtml(dayOfWeek, numberOfDayOfMonth, modifier, dataDate, !self.dayInAvailableRange(day)));
            }
        );

        monthHtml.appendChild(weekHtml);

        let daysInMonth = monthHtml.querySelectorAll("." + STYLE_MODIFIERS.calendarDay);

        if (self.options.setClassToDate) {
            self.setClassToDate(self.options.setClassToDate, daysInMonth);
        }

        if (self.clickedDays.length) {
            self.setClassToDate(self.convertArrToObj(self.clickedDays, STYLE_MODIFIERS.clicked), daysInMonth);
        }

        return monthHtml;
    }

    private dayInAvailableRange(day: Date): boolean {
        let beginDate = this.beginAvailableDate;
        let endDate = this.endAvailableDate;
        return !((beginDate && day < beginDate) || (endDate && day > endDate));
    }

    private setClassToDate(classesObj: {}, days: NodeList): void {
        let arrOfDates: string[] = Object.keys(classesObj),
            daysArr: HTMLElement[] = [].slice.call(days);

        arrOfDates.forEach((dataDate) => {
            daysArr.forEach((day) => {
                if (day.getAttribute("data-date") === dataDate) {
                    day.classList.add(classesObj[dataDate]);
                }
            });
        });
    }

    private createDayHtml(dayOfWeek: string = "",
                          html = "",
                          classModifier: string = "",
                          dataDate: {
                              numberOfDayOfMonth: string,
                              currentMonthNumber: string,
                              currentYearNumber: string
                          } = null,
                          disabled: boolean = false): HTMLElement {
        let day = document.createElement("div");

        day.classList.add(STYLE_MODIFIERS.calendarDay);

        if (classModifier) {
            day.classList.add(classModifier);
        }

        if (disabled) {
            day.classList.add(STYLE_MODIFIERS.disabled);
        }

        if (dataDate) {
            // -1 because moment().month() = current month - 1
            let momentMonth = moment().month(+dataDate["currentMonthNumber"] - 1);

            day.setAttribute("data-date",
                `${dataDate["numberOfDayOfMonth"]}.${momentMonth.format("MM")}.${dataDate["currentYearNumber"]}`);

            day.setAttribute("data-day-number", `${dataDate["numberOfDayOfMonth"]}`);
            day.setAttribute("data-day-of-week", `${dayOfWeek}`);
            day.setAttribute("data-month-name", `${momentMonth.format("MMMM")}`);
            day.setAttribute("data-month-number", `${momentMonth.format("MM")}`);
            day.setAttribute("data-year", `${dataDate["currentYearNumber"]}`);
        }

        day.innerHTML = html ? +parseFloat(html) + "" : "";

        return day;
    }

    private createWeekHtml(): HTMLElement {
        let week = document.createElement("div");

        week.classList.add("calendar__week");

        return week;
    }

    private createWeekWithDaysHtml(): HTMLElement {
        let week = document.createElement("div");

        week.classList.add("calendar__week-header");

        this.prepareDefaultLocaleWeekdaysShort().forEach((dayName) => {
            let block = document.createElement("div");

            block.innerHTML = dayName;

            block.classList.add("calendar__day-header");

            week.appendChild(block);
        });

        return week;
    }

    private prepareDefaultLocaleWeekdaysShort(): string[] {
        let firstDayOfTheWeek = moment()["_locale"].firstDayOfWeek(),
            arr = MomentCalendar.defaultLocaleWeekdaysShort.slice() || [];

        arr.splice(0, firstDayOfTheWeek);

        for (let i = 0; i < firstDayOfTheWeek; i++) {
            arr.push(MomentCalendar.defaultLocaleWeekdaysShort[i]);
        }

        return arr;
    }

    private createMonthHtml(): HTMLElement {
        let month = document.createElement("div");

        month.classList.add("calendar__month");

        return month;
    }

    private createCalendarWrapperHtml(htmlElement: HTMLElement): HTMLElement {
        let wrapper = document.createElement("div");

        wrapper.classList.add("calendar");
        wrapper.classList.add("jsMomentCalendar");

        if (this.options.controlsPanel) {
            wrapper.appendChild(this.createControlsPanelHtml());
        }

        if (htmlElement) {
            wrapper.appendChild(htmlElement);
        }

        wrapper.insertAdjacentHTML("beforeend", `
            <div class="calendar-months-wrapper">
                <div class="calendar-months"></div>
            </div>
            <div class="calendar-years-wrapper">
                <div class="calendar-years"></div>
            </div>
        `);

        return wrapper;
    }

    private createControlsPanelHtml(): Element {
        let panel = document.createElement("div");

        panel.classList.add("calendar__panel");

        panel.insertAdjacentHTML("beforeend", `
                    <div class="calendar__panel-current">
                        <div class="calendar__panel-current-info-from"></div>
                        <div class="calendar__panel-current-info"></div>
                    </div>
                    <div class="calendar__panel-dates">
                        <div class="calendar__panel-month">
                            ${this.getCurrentMonthName()}
                        </div>
                        <div class="calendar__panel-year">
                            ${this.getCurrentYearNumber()}
                        </div>
                    </div>
                `);

        panel.insertAdjacentElement("beforeend", this.createWeekWithDaysHtml());

        return panel;
    }

    private appendToMainContainer(monthHtml: HTMLElement, customRefresh: boolean = false): void {
        this.el.innerHTML = "";
        this.el.appendChild(this.createCalendarWrapperHtml(monthHtml));

        let currentMonthNumber = this.currentMonthNumber + 1 < 10 ? "0" + (this.currentMonthNumber + 1) : "" + (this.currentMonthNumber + 1);

        this.calendarMonthElement = this.el.querySelector(".calendar__month");

        this.dayElements = [].slice.call(this.calendarMonthElement.querySelectorAll(".calendar__day"));

        this.calendarMonthElement.addEventListener("scroll", uxThrottle(this.onDaysScroll, 200));
        this.monthsElWrapper = this.el.querySelector(".calendar-months-wrapper");
        this.monthsEl = this.el.querySelector(".calendar-months");
        this.yearsElWrapper = this.el.querySelector(".calendar-years-wrapper");
        this.yearsEl = this.el.querySelector(".calendar-years");
        this.calendarRangeElements = [].slice.call(this.el.querySelectorAll(".calendar__day._range"));
        this.currentDayElement = this.el.querySelector(`._current`);
        this.calendarPanelDatesElement = this.el.querySelector(`.calendar__panel-dates`);
        this.calendarWeekHeaderElement = this.el.querySelector(`.calendar__week-header`);
        this.calendarPanelMonthElement = this.el.querySelector(`.calendar__panel-month`);
        this.calendarPanelYearElement = this.el.querySelector(`.calendar__panel-year`);
        this.calendarPanelCurrentInfoElement = this.el.querySelector(`.calendar__panel-current-info`);
        this.calendarPanelCurrentInfoFromElement = this.el.querySelector(`.calendar__panel-current-info-from`);

        // If open calendar from refresh with range state scroll to selected range
        let rangeElement = customRefresh ? null : this.el.querySelector(".calendar__day._range"),
            scrollToElement = rangeElement ? rangeElement : this.el.querySelector(`[data-month-number="${currentMonthNumber}"][data-year="${this.currentYearNumber}"]`),
            calendarMonthRect,
            scrollToElementRect;

        this.makeMonthsHtml();
        this.makeYearsHtml();

        requestAnimationFrame(() => {
            calendarMonthRect = this.calendarMonthElement.getBoundingClientRect();

            scrollToElementRect = scrollToElement.getBoundingClientRect();
            this.calendarMonthElementHeight = this.calendarMonthElement.offsetHeight;
            this.dayElementHeight = this.dayElements[10].offsetHeight;
            this.calendarMonthElement.scrollTop = scrollToElementRect.top - calendarMonthRect.top + pageYOffset;

            this.updateCurrentInfoToCurrentDay();
        });
    }

    private onDaysScroll = (): void => {
        const daysInWeekNumber = 7;

        const daysInMothBlock = Math.floor(this.calendarMonthElementHeight / this.dayElementHeight * daysInWeekNumber),
            scrolledDays = Math.floor(this.calendarMonthElement.scrollTop / this.dayElementHeight * daysInWeekNumber);

        let firstDayOfVisibleMonth, firstDayOfVisibleMonthIndex;

        this.dayElements.forEach((day) => day.classList.remove("_visible-month"));

        /* Find first days of visible month */
        for (let i = scrolledDays - daysInWeekNumber * 2; i < scrolledDays + daysInMothBlock; i++) {
            let day = this.dayElements[i];

            if (day && day.dataset.dayNumber === "01") {
                firstDayOfVisibleMonth = day;
                firstDayOfVisibleMonthIndex = i;
                break;
            }
        }

        for (let i = firstDayOfVisibleMonthIndex; i < scrolledDays + daysInMothBlock; i++) {
            let day = this.dayElements[i];

            if (day && day.dataset) {
                if (day.dataset.monthNumber === firstDayOfVisibleMonth.dataset.monthNumber) {
                    day.classList.add("_visible-month");
                } else {
                    break;
                }
            }
        }

        let targetData = firstDayOfVisibleMonth.dataset;

        if (targetData && targetData.monthName) {
            this.calendarPanelMonthElement.innerHTML = targetData.monthName;
            this.calendarPanelYearElement.innerHTML = targetData.year;
        }
    }

    private getInitDayNumber(): number {
        return moment().date();
    }

    private getInitMonthNumber(): number {
        return moment().month();
    }

    private getInitYearNumber(): number {
        return moment().year();
    }

    private getCurrentYearNumber(): number {
        return this.currentYearNumber;
    }

    private getCurrentMonthName(): string {
        return moment().month(this.currentMonthNumber).format("MMMM");
    }

    private convertArrToObj(arr: string[], val: string): {} {
        let obj = {};

        arr.forEach((item) => {
            obj[item] = val;
        });

        return obj;
    }

    private setClickedDays(arr: string[]): void {
        this.clickedDays = arr;
    }

    private isUpcomingDate(date: string): boolean {
        if (!date) {
            return;
        }

        let dateArr = date.split(".");

        if (+dateArr[2] > this.initYearNumber) {
            return true;
        } else if (+dateArr[2] === this.initYearNumber) {

            if (+dateArr[1] > this.initMonthNumber) {
                return true;
            } else if (+dateArr[1] === this.initMonthNumber) {

                return +dateArr[0] > this.initDayNumber;

            } else {
                return false;
            }

        } else {
            return false;
        }
    }

    private show(el: Element, displayType?: string): void {
        (<HTMLElement>el).style.display = displayType || "block";
    }

    private hide(el: Element): void {
        (<HTMLElement>el).style.display = "none";
    }

    private changeDayStyles(date: string): void {
        if (date) {
            this.dayElements.forEach((day: Element) => {
                if (day.getAttribute("data-date") !== date) {
                    day.classList.remove(STYLE_MODIFIERS.clicked);
                }
            });
        }
    }
}

export interface Options {
    onDayClick?: Function;
    onPrevButtonClick?: Function;
    onNextButtonClick?: Function;
    clickableOnlyUpcomingDays?: boolean;
    setClassToDate?: {};
    controlsPanel?: boolean;
    clickableDays?: boolean;
    month?: number;
    year?: number;
    beginAvailableDate?: Date;
    endAvailableDate?: Date;
    range?: UxRangeDateFieldModel;
}
