<div [ngClass]="{
        _mobile: _mobile,
        'ux-scroll__custom-scrollable-block': _customScrollableBlock,
        'ux-scroll__inner': !_customScrollableBlock
      }"
     #scrollInner>
    <div class="ux-scroll__content"
         #scrollContent
         *ngIf="!_customScrollableBlock"
    >
        <ng-content></ng-content>
    </div>
    <ng-content select="[scrollableBlock]"
                *ngIf="_customScrollableBlock"
    ></ng-content>
</div>

<div class="ux-scroll__slider-vertical-wrap"
     [ngClass]="{ _transparent: showSliders }"
     [style.display]="_sliderVerticalWrapVisible ? 'block' : 'none'"
     #scrollSliderVerticalWrap>
    <div class="ux-scroll__slider-vertical" #scrollSliderVertical></div>
</div>

<div class="ux-scroll__slider-horizontal-wrap"
     [ngClass]="{ _transparent: showSliders }"
     [style.display]="_sliderHorizontalWrapVisible ? 'block' : 'none'"
     #scrollSliderHorizontalWrap>
    <div class="ux-scroll__slider-horizontal" #scrollSliderHorizontal></div>
</div>
