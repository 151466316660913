import {
    ChangeDetectorRef,
    Component,
    ContentChild,
    ElementRef,
    Input,
    TemplateRef,
} from "@angular/core";
import {UxAbstractFieldComponent, UxValueChangeEvent} from "../abstract-field.component";
import {NG_VALUE_ACCESSOR} from "@angular/forms";


export interface UxCheckboxItemModel {
    id?: string;
    label?: string;
    disabled?: boolean;
    styleClass?: string;
    name?: string;
    value?: any;
    inputElementId?: string;
}


@Component({
    selector: "ux-checkbox-group-field",
    templateUrl: "./checkbox-group-field.component.html",
    host: {
        "[class.ux-checkbox-group-field]": "true"
    },
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: UxCheckboxGroupFieldComponent,
            multi: true
        }
    ]
})
export class UxCheckboxGroupFieldComponent extends UxAbstractFieldComponent<Array<UxCheckboxItemModel>> {

    @Input()
    public name: string;

    @Input()
    public template: TemplateRef<{
        disabled: boolean;
        label: string;
        value: boolean,
        name: string,
        inputElementId: string
    }>;

    @Input()
    public items: Array<UxCheckboxItemModel>;

    @Input()
    public itemLabelKey: string = "label";

    @ContentChild('customIcon', /* TODO: add static flag */ {static: true})
    private set customIcon(iconElement: ElementRef) {
        this._customIcon = !!iconElement;
    }

    /** @internal */
    public _customIcon: boolean = false;

    constructor(private cdr: ChangeDetectorRef) {
        super();
    }

    protected getDefaultValue(): Array<UxCheckboxItemModel> {
        return [];
    }

    protected getValueConverter(): { (value: any): Array<UxCheckboxItemModel> } {
        return (value: any): Array<UxCheckboxItemModel> => {
            if (Array.isArray(value)) {
                return value;
            }
        };
    }

    /* @internal */
    public _onValueSwitch(event: UxValueChangeEvent<boolean>, item: UxCheckboxItemModel): void {
        let self = this;
        if (!self.disabled) {
            if (event.newValue) {
                if (self._getItemIndex(item) === -1) {
                    self.checkValue();

                    // reassign reference to emit valueChange and onValueChange. Also references on oldValue and newValue will be different.
                    let newValue = [...self.value];
                    newValue.push(item);
                    self.value = newValue;
                }
            } else {
                let index = self._getItemIndex(item);
                if (index >= 0) {
                    self.checkValue();

                    // reassign reference to emit valueChange and onValueChange. Also references on oldValue and newValue will be different.
                    let newValue = [...self.value];
                    newValue.splice(index, 1);
                    self.value = newValue;
                }
            }
        }
    }

    private checkValue(): void {
        if (!Array.isArray(this.value)) {
            this.value = [];
        }
    }

    /* @internal */
    public _getItemIndex(item: UxCheckboxItemModel): number {
        if (this.value) {
            for (let i = 0; i < this.value.length; i++) {
                let value = this.value[i];
                if (value === item) {
                    return i;
                }
            }
        }

        return -1;
    }

    /** @internal */
    public _trackByFn(index: number, item: UxCheckboxItemModel): string {
        return item && item.id;
    }
}

