import {AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild} from "@angular/core";
import {UxMultipleFieldItemModel} from "../../../multiple-field-item.model";
import {UxPropertyConverter} from "../../../../../../common/decorator/ux-property-converter";
import {UxPropertyHandler} from "../../../../../../common/decorator/ux-property-handler";
import {UxValueChangeEvent} from "../../../../abstract-field.component";
import {UxCheckboxItemModel} from "../../../../checkbox-group/checkbox-group-field.component";

@Component({
    selector: "ux-multiple-selector-field-popover-content",
    templateUrl: "multiple-selector-field-popover-content.component.html",
    host: {"[class.ux-multiple-selector-field-popover-content]": "true"},
})
export class UxMultipleSelectorFieldPopoverContentComponent implements AfterViewInit {

    @Input()
    public get items(): UxMultipleFieldItemModel[] {
        return this._items;
    }

    public set items(values: UxMultipleFieldItemModel[]) {
        let self = this;
        self._items = values;
        this.updateSelectedAllItem();
    }

    /** @internal */
    public _items: UxMultipleFieldItemModel[];

    @UxPropertyConverter("string", "No items")
    @Input()
    public emptyMessage: string;

    @Input()
    public value: UxMultipleFieldItemModel[];

    @UxPropertyConverter("string", "caption")
    @Input()
    public itemCaptionKey: string;

    @Input()
    public showSelectAll: boolean = false;

    private _selectAllCaption: string;
    @Input()
    set selectAllCaption(value: string) {
      this._selectAllCaption = value;
      this.updateSelectedAllItem();
    }

    get selectAllCaption(): string {
      return this._selectAllCaption;
    }

    private selectedAllItems: UxMultipleFieldItemModel[] = [];
    private selectAllItems: UxMultipleFieldItemModel[] = [];

    @Output()
    public valueChange: EventEmitter<UxMultipleFieldItemModel[]> = new EventEmitter<UxMultipleFieldItemModel[]>();

    @ViewChild("focusedElement", { read: ElementRef })
    private focusedElement: ElementRef;

    public ngAfterViewInit(): void {
        this.focus();
    }

    private focus(): void {
        let element = this.focusedElement && this.focusedElement.nativeElement.querySelector(".ux-checkbox-field__input");
        window.setTimeout(() => {   //after popover is shown
            element && (element.focus());
        });
    }

    private updateSelectedAllItem(): void {
      let selected: boolean = !this._items.some((item: UxMultipleFieldItemModel) => !item.selected);
      this.selectAllItems = [{
        id: "select_all_item",
        caption: this._selectAllCaption,
        selected: selected
      }];
      this.selectedAllItems = [];
      if (selected) {
        this.selectedAllItems.push(this.selectAllItems[0]);
      }
    }

    _onSelectAllValueChange(value: UxCheckboxItemModel[]) {
      let selectedAll: boolean = this.selectedAllItems.length === 0; // event come before change

      this._items.forEach((item: UxMultipleFieldItemModel) => {
        item.selected = selectedAll;
      });

      let newValue = [];
      if (selectedAll) {
        this._items.forEach((item: UxMultipleFieldItemModel) => {
          newValue.push(item);
        });
      }
      this.value = newValue;

      this.valueChange.emit(this.value);
    }

    _onItemValueChange(values: UxMultipleFieldItemModel[]) {
      this.valueChange.emit(values);
      //Promise.resolve(null).then(() => {

        this.selectedAllItems = [];
        if (values.length === this._items.length) {
          this.selectedAllItems.push(this.selectAllItems[0]);
        }
      //});
    }
}
