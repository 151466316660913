import {Component} from "@angular/core";

/* deprecated use instead headerTemplate. todo remove after 1.0.38 version */
@Component({
    selector: "ux-accordion-item-header",
    template: `<ng-content></ng-content>`,
    host: {"[class.ux-accordion-item-header]": "true"}
})
export class UxAccordionItemHeaderComponent {

}
