import {Pipe, PipeTransform} from "@angular/core";

@Pipe({name: 'duration'})
export class TimeDurationPipe implements PipeTransform {
  transform(value: any): string {
    if (!value) {
      return "";
    }
    return this.formatSeconds(parseInt(value));
  }

  private formatSeconds(value: number) {

    let hours = this.intDiv(value, 3600) % 24,
      mins = this.intDiv(value, 60) % 60,
      secs = value % 60;

    return `${hours > 9 ? hours : '0' + hours}:${mins > 9 ? mins : '0' + mins}:${secs > 9 ? secs : '0' + secs}`;
  }

  private intDiv(val, div): number {
    return (val - val % div) / div;
  }
}
