import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import {SevanaQualtestAuthGuard} from "./sevana-qualtest-auth.guard";
import {LoginComponent} from "./modules/login/components/login.component";

const routes: Routes = [
  {
    path: "",
    redirectTo: "devices",
    pathMatch: "full",
    canActivateChild: [SevanaQualtestAuthGuard],
  },
  {path: "login", component: LoginComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class SevanaQualtestRoutingModule { }
