import {AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild} from "@angular/core";
import {UxScrollComponent} from "../../scroll/scroll.component";
import {AttachPosition, TransitionType} from "../../popover/popover.component";
import {UxDomHelper} from "../../../shared/dom/dom-helper";
import {UxPropertyConverter} from "../../../common/decorator/ux-property-converter";
import {UxObjectUtilsService} from "../../utils/object-utils.service";

@Component({
    selector: "ux-field-suggestion-popup",
    templateUrl: "./field-suggestion-popup.component.html",
    host: {"[class.ux-field-suggestion-popup]": "true"},
    providers: [UxObjectUtilsService]
})
export class UxFieldSuggestionPopupComponent implements AfterViewInit {

    @ViewChild("popover", { read: ElementRef, static: true })
    private popoverRef: ElementRef;

    @UxPropertyConverter("string", "200px")
    @Input()
    public maxListHeight: string;

    @Input()
    public get popupContainer(): ElementRef | string {
        if (typeof this.popupContainer === "string") {
            return this._popupContainerString;
        } else {
            return this._popupContainerElement;
        }
    }

    public set popupContainer(value: ElementRef | string) {
        if (typeof value === "string") {
            this._popupContainerElement = null;
            this._popupContainerString = value;
        } else {
            this._popupContainerString = null;
            this._popupContainerElement = value;
        }
    }

    /** @internal */
    public _popupContainerElement: ElementRef;

    /** @internal */
    public _popupContainerString: string;

    @Input()
    public get attachTo(): ElementRef | string {
        if (this._attachToString) {
            return this._attachToString;
        } else {
            return this._attachToElement;
        }
    }

    public set attachTo(value: ElementRef | string) {
        if (typeof value === "string") {
            this._attachToString = value;
            this._attachToElement = null;
        } else {
            this._attachToElement = value;
            this._attachToString = null;
        }
    }


    /** @internal */
    public _attachToElement: ElementRef;

    /** @internal */
    public _attachToString: string;

    @Input()
    public autoWidth: boolean = true;

    @Input()
    public minWidth: string;

    private _opened: boolean = false;

    private viewInited: boolean = false;

    @Input()
    public set opened(value: boolean) {
        let self = this;

        if (value !== self._opened) {
            self._opened = value;
            self.openedChange.emit(value);

            if (self.viewInited && value) {

                let elementWidth = self.elementRef.nativeElement.offsetWidth;

                // calculate min width of popover
                if (!this.minWidth || self.minWidth.endsWith("px")) {
                    let minWidth = this.minWidth ? self.minWidth.slice(0, -2) : 0;
                    this.popoverRef.nativeElement.style.minWidth = (minWidth < elementWidth ? elementWidth : minWidth) + "px";
                } else {
                    this.popoverRef.nativeElement.style.minWidth = self.minWidth;
                }

                self.popoverRef.nativeElement.style.width = self.autoWidth ? "auto" : (self.mobile ? "" : (elementWidth + "px"));

                let scrollbarWidth = UxDomHelper.getScrollbarWidth();
                self.scrollRef.scroll.style.marginRight = -scrollbarWidth + "px";
            }
        }
    }

    public get opened(): boolean {
        return this._opened;
    }

    @Input()
    public styleClass: string;

    @Input()
    public attachPosition: string = "auto";

    @Input()
    public attachPositionsSequence: AttachPosition[] = [
        "bottom",
        "bottom left",
        "bottom right",
        "top",
        "top left",
        "top right",
        "right",
        "right top",
        "right bottom",
        "left",
        "left top",
        "left bottom"
    ];

    @Input()
    public mobile: boolean = false;

    @Input()
    public transitionType: TransitionType = "slide";

    @Input()
    public disablePositionUpdateOnScroll: boolean = false;

    @Output()
    public openedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Output()
    public onClick: EventEmitter<Event> = new EventEmitter<Event>();

    @Output()
    public onOpen: EventEmitter<boolean> = new EventEmitter<boolean>();

    @ViewChild("scroll", { static: true })
    private scrollRef: UxScrollComponent;

    @ViewChild("scroll", { read: ElementRef, static: true })
    private scrollElementRef: ElementRef;

    /** @internal */
    public _onSuggestionPopupClick(event: Event): void {
        this.onClick.emit(event);
    }

    public scrollToElement(element: HTMLElement): void {
        this.scrollRef.scroll.scrollTop = element.offsetTop;
    }

    public getPopupNativeElement(): HTMLDivElement {
        return this.popoverRef.nativeElement;
    }

    constructor(private elementRef: ElementRef) {
    }

    public ngAfterViewInit(): void {
        this.viewInited = true;
    }
}
